import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { v4 as guidGenerator } from "uuid";
import { useTheme } from "@emotion/react";

const FormInputLabelStyles = {
  shrink: true,
};

const StepHeadingStyle = {
  alignSelf: "left",
  marginBottom: 2,
  marginTop: 8,
};

const h2Style = {
  marginBottom: "24px",
};

const DistributorFormHeader = ({ activeStep, addNewDistributor }) => {
  const { register, handleSubmit, reset } = useForm({ mode: "all" });
  const theme = useTheme();
  const regularScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleNewDistributor = ({ name }) => {
    addNewDistributor({ name, id: guidGenerator() });
    reset();
  };

  const renderHeader = () => {
    if (activeStep === 0) {
      return (
        <Grid container direction={"column"}>
          <Grid item sx={{ marginTop: 6 }}>
            <Typography variant="body1">
              Enter a vendor name into the input field below to begin:
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifySelf: "center",
              marginBottom: 6,
              marginTop: 3,
            }}
          >
            <form onSubmit={handleSubmit(handleNewDistributor)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  label={null}
                  autoComplete="off"
                  placeholder="Enter a vendor name"
                  InputLabelProps={FormInputLabelStyles}
                  InputProps={{
                    sx: {
                      width: regularScreen ? "400px" : "200px",
                    },
                  }}
                  sx={{
                    "& legend": { display: "none" },
                    backgroundColor: (theme) => theme.palette.pure.white,
                    width: regularScreen ? "400px" : "200px",
                    borderRadius: 1,
                    input: {
                      backgroundColor: (theme) => theme.palette.pure.white,
                      marginTop: -1,
                      borderRadius: 1,
                    },
                  }}
                  {...register("name", { required: true })}
                />
                <Button
                  sx={{
                    width: regularScreen ? "180px" : "120px",
                    height: "34px",
                    marginLeft: 2,
                    marginBottom: 1,
                  }}
                  type="submit"
                  variant="outlined"
                >
                  <Typography>Create New</Typography>
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      );
    }
    if (activeStep === 1) {
      return (
        <Box sx={StepHeadingStyle}>
          <Typography variant="h3" sx={h2Style}>
            Set Order Days{" "}
          </Typography>
        </Box>
      );
    }
    if (activeStep === 2) {
      return (
        <Box sx={StepHeadingStyle}>
          <Typography variant="h3" sx={h2Style}>
            Order Cut Off Times{" "}
          </Typography>
        </Box>
      );
    }
    if (activeStep === 3) {
      return (
        <Box sx={StepHeadingStyle}>
          <Typography variant="h3" sx={h2Style}>
            Delivery Arrival Days{" "}
          </Typography>
        </Box>
      );
    }
    if (activeStep === 4) {
      return (
        <Box sx={StepHeadingStyle}>
          <Typography variant="h3" sx={h2Style}>
            Review{" "}
          </Typography>
        </Box>
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {renderHeader()}
    </Box>
  );
};

export default DistributorFormHeader;
