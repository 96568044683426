import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

const LogoImage = {
  borderRadius: "100%",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[300],
  height: {
    xs: "48px",
    md: "60px",
  },
  width: {
    xs: "48px",
    md: "60px",
  },
  cursor: "pointer",
  margin: "auto",
};

const renderImage = (imageSrc, ImageComponent, id) => {
  if (imageSrc) {
    return (
      <Box
        component="img"
        sx={{
          width: "100%",
          objectFit: "scale-down",
          aspectRatio: 1 / 1,
        }}
        src={imageSrc}
        id={id}
      />
    );
  }
  if (ImageComponent) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <ImageComponent sx={LogoImage} />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: (theme) => theme.palette.terrain[100],
      }}
    >
      <HomeOutlinedIcon
        sx={{
          fontSize: "120px",
          color: (theme) => theme.palette.terrain[400],
        }}
      />
    </Box>
  );
};

const EstablishmentOption = ({
  imageSrc,
  ImageComponent,
  establishmentName,
  id,
  onClick,
}) => {
  return (
    <Grid
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 4,
        width: "100%",
        height: "256px",
        border: "3px solid",
        overflow: "hidden",
        borderColor: (theme) => theme.palette.terrain[300],
        "&:hover": {
          backgroundColor: (theme) => theme.palette.terrain[300],
        },
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "192px",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          onClick={onClick}
          sx={{
            overflow: "hidden",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {renderImage(imageSrc, ImageComponent, id, onClick)}
        </Box>
      </Box>
      <Grid
        item
        sx={{
          height: "64px",
          maxHeight: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            textTransform: "none",
            fontSize: {
              xs: 14,
              sm: 16,
            },
            color: (theme) => theme.palette.primary[800],
          }}
          variant="h5"
        >
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              maxHeight: "64px",
            }}
          >
            {establishmentName}
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

EstablishmentOption.propTypes = {};

export default EstablishmentOption;
