import { Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import ExpenseGroupReport from "./ExpenseGroupReport";
import ReportType from "./ReportType";
import ExpenseSubgroupReport from "./ExpenseSubgroupReport";
import ProductPriceChanges from "./ProductPriceChanges";
import TopSpendingReport from "./TopSpendingReport";
import NonItemizedExpenseReport from "./NonItemizedExpenseReport";
import InvoiceTotalsByVendor from "./InvoiceTotalsByVendor";
import ExpenseGroupOrSubgroupTotalsByDate from "./ExpenseGroupOrSubgroupTotalsByDate";
import ItemizedInvoiceByDate from "./ItemizedInvoiceByDate";
import useEstablishmentExpenseGroups from "../../../../hooks/useEstablishmentExpenseGroups";

const topLevelReportTypeOptions = [
  { label: "Expenses by Expense Group & Vendors Over Time", value: 0 },
  { label: "Expenses by Expense Subgroup & Vendors Over Time", value: 1 },
  { label: "Product Price Changes Over Time", value: 2 },
  { label: "Top Spending", value: 3 },
  { label: "Non-itemized Expense Group or Subgroup", value: 4 },
  { label: "Invoice Totals By Vendor", value: 5 },
  { label: "Expense Group or Subgroup Totals by Date", value: 6 },
  { label: "Itemized Invoice by Date", value: 7 },
];

const InvoiceReport = ({
  establishmentSettings,
  establishmentId,
  setAlertSuccess,
  setErrorMessage,
  vendors,
  products,
  unitsOfMeasurement,
}) => {
  const [reportType, setReportType] = useState(0);

  const { isLoading: isLoadingExpenseGroups, data: expenseGroupsData } =
    useEstablishmentExpenseGroups(establishmentId);

  if (isLoadingExpenseGroups) {
    return (
      <Box
        sx={{
          height: 250,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <ReportType
          reportType={reportType}
          setReportType={setReportType}
          reportTypeOptions={topLevelReportTypeOptions}
        />
      </Box>
      {reportType === 0 && (
        <ExpenseGroupReport
          expenseGroups={expenseGroupsData?.expenseGroups}
          setAlertSuccess={setAlertSuccess}
          setErrorMessage={setErrorMessage}
          establishmentId={establishmentId}
          establishmentTimezone={
            establishmentSettings?.inventorySettings.timeZone
          }
        />
      )}
      {reportType === 1 && (
        <ExpenseSubgroupReport
          expenseGroups={expenseGroupsData?.expenseGroups}
          setAlertSuccess={setAlertSuccess}
          setErrorMessage={setErrorMessage}
          establishmentId={establishmentId}
          establishmentTimezone={
            establishmentSettings?.inventorySettings.timeZone
          }
        />
      )}
      {reportType === 2 && (
        <ProductPriceChanges
          setAlertSuccess={setAlertSuccess}
          setErrorMessage={setErrorMessage}
          establishmentId={establishmentId}
          establishmentTimezone={
            establishmentSettings?.inventorySettings.timeZone
          }
          products={products}
          unitsOfMeasurement={unitsOfMeasurement}
        />
      )}
      {reportType === 3 && (
        <TopSpendingReport
          setAlertSuccess={setAlertSuccess}
          setErrorMessage={setErrorMessage}
          establishmentId={establishmentId}
          establishmentTimezone={
            establishmentSettings?.inventorySettings.timeZone
          }
        />
      )}
      {reportType === 4 && (
        <NonItemizedExpenseReport
          setAlertSuccess={setAlertSuccess}
          setErrorMessage={setErrorMessage}
          establishmentId={establishmentId}
          establishmentTimezone={
            establishmentSettings?.inventorySettings.timeZone
          }
          vendors={vendors}
          expenseGroups={expenseGroupsData?.expenseGroups}
        />
      )}
      {reportType === 5 && (
        <InvoiceTotalsByVendor
          setAlertSuccess={setAlertSuccess}
          setErrorMessage={setErrorMessage}
          establishmentId={establishmentId}
          establishmentTimezone={
            establishmentSettings?.inventorySettings.timeZone
          }
          vendors={vendors}
        />
      )}
      {reportType === 6 && (
        <ExpenseGroupOrSubgroupTotalsByDate
          setAlertSuccess={setAlertSuccess}
          setErrorMessage={setErrorMessage}
          establishmentId={establishmentId}
          establishmentTimezone={
            establishmentSettings?.inventorySettings.timeZone
          }
          expenseGroups={expenseGroupsData?.expenseGroups}
        />
      )}
      {reportType === 7 && (
        <ItemizedInvoiceByDate
          setAlertSuccess={setAlertSuccess}
          setErrorMessage={setErrorMessage}
          establishmentId={establishmentId}
          establishmentTimezone={
            establishmentSettings?.inventorySettings.timeZone
          }
          expenseGroups={expenseGroupsData?.expenseGroups}
          vendors={vendors}
        />
      )}
    </Box>
  );
};

export default InvoiceReport;
