import { createTheme } from "@mui/material";
import createPalette from "./palette";
import createTypography from "./typograpghy";

const configureTheme = (config) => {
  let theme = createTheme({
    palette: createPalette(config),
    spacing: 4,
  });

  theme = createTheme(theme, {
    breakpoints: {
      xs: 0,
      sm: 400,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1680,
    },
    // shadows: ["none"],
    typography: createTypography(theme),
    components: {
      MuiListItem: {
        defaultProps: {
          disableripple: "true",
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
          outlined: {
            color: theme.palette.pure.black,
            border: "2px solid",
            borderColor: theme.palette.primary[800],
            boxShadow: "none",
            backgroundColor: theme.palette.pure.white,
            "&:hover": {
              backgroundColor: theme.palette.terrain[100],
              boxShadow: "none",
              border: "2px solid",
            },
          },
          contained: {
            background: theme.palette.primary[800],
            color: theme.palette.pure.white,
            boxShadow: "none",
            "&:hover": {
              background: theme.palette.primary[400],
              boxShadow: "none",
            },
          },
          text: {
            border: "none",
            "&:hover": {
              background: theme.palette.terrain[100],
              boxShadow: "none",
            },
          },
        },
      },

      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: theme.palette.terrain["01"],
            color: theme.palette.pure.white,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: `${theme.palette.terrain["50"]} !important`,
          },
          root: {
            backgroundColor: `${theme.palette.terrain["50"]} !important`,
          },
          paperAnchorDockedLeft: {
            backgroundColor: `${theme.palette.terrain["50"]} !important`,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            // backgroundColor: theme.palette.pure.white,
            backgroundColor: theme.palette.terrain[50],
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            ":hover": {
              backgroundColor: theme.palette.terrain[500],
              color: theme.palette.pure.white,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          input: {
            background: `${theme.palette.pure.white} !important`,
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            color: theme.palette.pure.black,
            borderColor: theme.palette.primary[800],
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: "8px",
            "&:hover": {
              borderColor: theme.palette.primary[800],
              backgroundColor: theme.palette.terrain[100],
              boxShadow: "none",
            },
          },
        },
      },
    },
  });

  return theme;
};

let theme = configureTheme();

export { theme, configureTheme };
