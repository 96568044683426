import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import RecentlyUpdatedRow from "./RecentlyUpdatedRow";

const tableHeadStyle = {
  paddingTop: 1,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const ReportingHistoryTable = ({ recentUpdates, staffData }) => {
  const [staffMemberMap, setStaffMemberMap] = useState({});

  useEffect(() => {
    const newMap = {};

    staffData.forEach((x) => {
      newMap[x.userId] = `${x.firstName ? startCase(x.firstName) : ""} ${
        x.lastName ? startCase(x.lastName) : ""
      }`;
    });

    setStaffMemberMap(newMap);
  }, [staffData]);

  return (
    <TableContainer
      sx={{
        display: "flex",
        width: "100%",
        overflow: "scroll !important",
        borderRadius: 2,
        border: "1px solid",
        height: "100%",
        borderColor: (theme) => theme.palette.terrain[400],
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="customized table">
        <TableHead sx={{ height: "24px" }}>
          <TableRow sx={{ height: 24 }}>
            <TableCell sx={[tableHeadStyle, { width: "500px" }]}>
              Updated Content
            </TableCell>
            <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
              Type
            </TableCell>
            <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
              Updated By{" "}
            </TableCell>
            <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
              Time
            </TableCell>
            <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
              Date
            </TableCell>
            <TableCell sx={[tableHeadStyle, { width: "48px" }]}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recentUpdates.map((x) => (
            <RecentlyUpdatedRow
              key={x.id}
              changeSet={x}
              staffMemberMap={staffMemberMap}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportingHistoryTable;
