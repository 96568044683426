import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const Vendors = ({
  isUsingVendorFilter,
  toggleIsUsingVendorFilter,
  vendorFilters,
  setVendorFilters,
  vendors,
}) => {
  const handleToggleVendorFilter = () => {
    toggleIsUsingVendorFilter(!isUsingVendorFilter);
  };

  const handleVendorChange = (_, newValue) => {
    setVendorFilters(newValue);
    toggleIsUsingVendorFilter(true);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox
          sx={{ marginLeft: -3 }}
          checked={isUsingVendorFilter}
          onChange={handleToggleVendorFilter}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Filter Vendors
        </Typography>
      </Box>
      {isUsingVendorFilter ? (
        <Box sx={{ paddingRight: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "72px",
              width: "35%",
              borderRadius: 2,
              backgroundColor: (theme) => theme.palette.terrain[200],
              border: "1px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              padding: 2,
              marginTop: 2,
            }}
          >
            <Autocomplete
              size="small"
              multiple
              fullWidth
              options={vendors}
              getOptionLabel={(option) => option.name}
              disableCloseOnSelect
              renderTags={() => null}
              value={vendorFilters}
              onChange={handleVendorChange}
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
                "& .MuiAutocomplete-option": {
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              }}
              renderOption={(props, option) => (
                <ListItem
                  sx={{
                    "&:hover": {
                      color: (theme) => theme.palette.pure.white,
                    },
                  }}
                  key={option.id}
                  {...props}
                >
                  <Checkbox
                    sx={{ color: (theme) => theme.palette.primary[800] }}
                    checked={vendorFilters.some(
                      (item) => item.id === option.id
                    )}
                    onChange={() => {}}
                  />
                  <ListItemText primary={option.name} />
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={
                    vendorFilters?.length > 0
                      ? `${vendorFilters.length} Vendor(s) Selected`
                      : "No Vendors Selected"
                  }
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    style: { cursor: "pointer" },
                  }} // Disable typing
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    width: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    cursor: "pointer",
                  }}
                />
              )}
            />
          </Box>
          {isUsingVendorFilter ? (
            <Box sx={{ marginTop: 2 }}>
              {vendorFilters.map((v) => (
                <Chip
                  key={v.id}
                  label={v.name}
                  onDelete={() => {
                    setVendorFilters(
                      vendorFilters.filter((item) => item.id !== v.id)
                    );
                  }}
                  sx={{ marginRight: 1, marginBottom: 1 }}
                />
              ))}
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default Vendors;
