import React, { useEffect, useState } from "react";
import { modalBoxStyle } from "./styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Grid,
  CircularProgress,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const centerItems = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

const MenuManagementModal = ({
  onSave,
  modalOpen,
  toggleModalOpen,
  existingMenu,
}) => {
  const [menuTitle, setMenuTitle] = useState("");
  const [menuType, setMenuType] = useState("");
  const [menuActive, toggleMenuActive] = useState(true);
  const [showTitleError, toggleShowTitleError] = useState(false);
  const [showTypeError, toggleShowTypeError] = useState(false);
  const [loading, toggleLoading] = useState(false);

  useEffect(() => {
    if (existingMenu) {
      setMenuTitle(existingMenu.name);
      setMenuType(existingMenu.type);
      toggleMenuActive(existingMenu.active);
    }
  }, [existingMenu]);

  const closeModal = () => {
    toggleShowTitleError(false);
    toggleShowTypeError(false);
    toggleModalOpen();
  };

  const handleCreateMenu = async (e) => {
    e.preventDefault();
    let validationError = false;
    if (!menuTitle) {
      toggleShowTitleError(true);
      validationError = true;
    }

    if (!menuType) {
      toggleShowTypeError(true);
      validationError = true;
    }

    if (validationError) {
      return;
    }
    toggleLoading(true);
    await onSave({ name: menuTitle, active: menuActive, type: menuType });
  };

  return (
    <Modal open={modalOpen} onClose={closeModal} showTypeError={showTypeError}>
      <Box
        onSubmit={handleCreateMenu}
        component="form"
        sx={[modalBoxStyle, { width: "400px !important" }]}
      >
        <Box>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 6,
              }}
            >
              <Grid item>
                <Typography variant="h4">
                  {existingMenu ? "Edit Menu" : "Create New Menu"}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={{ backgroundColor: "transparent" }}
                  onClick={closeModal}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ marginBottom: 6, width: "100%" }}>
            <TextField
              size="small"
              label="Menu Title&#42;"
              id="title"
              value={menuTitle}
              fullWidth
              onChange={(e) => setMenuTitle(e.target.value)}
              helperText={showTitleError ? "This field is mandatory" : ""}
              error={showTitleError}
            />
          </Grid>

          <Grid item>
            <FormControl fullWidth error={showTypeError}>
              <InputLabel size="small" id="menu-type-label">
                Menu Type&#42;
              </InputLabel>
              <Select
                size="small"
                label="Menu Type&#42;"
                labelId="menu-type-label"
                id="menu-type"
                value={menuType}
                fullWidth
                onChange={(e) => setMenuType(e.target.value)}
              >
                <MenuItem value="general">General Menu</MenuItem>
                <MenuItem value="cocktail">Cocktail Menu</MenuItem>
                <MenuItem value="wine">Wine Menu</MenuItem>
                <MenuItem value="beer">Beer Menu</MenuItem>
                <MenuItem value="coffee">Coffee Menu</MenuItem>
                <MenuItem value="unknown">Unknown</MenuItem>
              </Select>
              {showTypeError && (
                <FormHelperText>This field is mandatory</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item>
            <Box
              sx={{
                // width: "360px",
                height: "40px",
                backgroundColor: (theme) => theme.palette.secondary[50],
                borderRadius: 2,
              }}
            >
              <Grid container direction="row">
                <Grid item>
                  <Switch
                    color="secondary"
                    checked={menuActive}
                    onClick={() => toggleMenuActive(!menuActive)}
                  />
                </Grid>
                <Grid item sx={{ alignItems: "center", display: "flex" }}>
                  {" "}
                  <Typography variant="subtitle2">
                    {menuActive
                      ? "Menu Status: Active"
                      : "Menu Status: Inactive"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sx={[centerItems, { marginTop: 6 }]}>
            <Button
              variant="contained"
              type="submit"
              sx={{ marginLeft: "1rem", borderRadius: 2, width: "240px" }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography variant="smallButton">Confirm</Typography>
              )}
            </Button>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

MenuManagementModal.propTypes = {};

export default MenuManagementModal;
