import {
  Alert,
  Grid,
  IconButton,
  ToggleButton,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Collapse,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import CreateAreaModal from "../../../components/Modals/CreateAreaModal.js";
import inventoryClient from "../../../clients/inventoryClient.js";
import { useParams } from "react-router-dom";
import SpecSpinner from "../../../components/SpecSpinner/index.js";
import AreaCountList from "./AreaCountList.js";
import AddProductToCountModal from "../../../components/Modals/AddProductToCountModal/index.js";
import {
  ExpandLessOutlined,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";

const UnorganizedAreaCountDetails = ({ areaId, areaCountId }) => {
  const [createAreaModalOpen, toggleCreateAreaModalOpen] = useState(false);
  const [isMobileMode, toggleMobileMode] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [isAddProductOpen, toggleIsAddProcuctOpen] = useState(false);
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const queryClient = useQueryClient();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    toggleMobileMode(largeScreen ? false : true);
  }, [largeScreen]);

  let { establishmentid, stockcountid } = useParams();
  const { isLoading, isError, data } = useQuery(
    [
      "areacount",
      {
        establishmentId: establishmentid,
        stockCountId: stockcountid,
        areaCountId: areaCountId,
      },
    ],
    inventoryClient.getAreaCountById
  );

  const handleUpdateBaseRecordQuantity = (recordId, newQuantity) => {
    queryClient.setQueryData(
      [
        "areacount",
        {
          establishmentId: establishmentid,
          stockCountId: stockcountid,
          areaId: areaId,
        },
      ],
      (prev) => {
        console.log(prev);
        const newExpectedRecordData = prev.areaCount.stockCountRecords.map(
          (x) => {
            if (x.id === recordId) {
              return { ...x, quantityFound: newQuantity };
            }

            return x;
          }
        );

        const newState = Object.assign({}, prev);
        newState.areaCount.stockCountRecords = newExpectedRecordData;
        console.log(newState);
        return newState;
      }
    );
  };

  if (isLoading) {
    return <SpecSpinner open text="loading area..." />;
  }

  const handleCloseAddProductModal = () => {
    toggleIsAddProcuctOpen(false);
  };

  const { areaCount } = data;
  return (
    <>
      <Card
        elevation={0}
        sx={{
          width: "100%",
          padding: 0,
          borderRadius: "8px",
          marginTop: 4,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ marginLeft: 2, fontWeight: 700 }}
          >
            Products not associated to an area
          </Typography>
          {!expanded ? (
            <IconButton
              variant="outlined"
              sx={{
                margin: 2,
                width: "32px",
                height: "32px",
              }}
              onClick={handleExpandClick}
            >
              <KeyboardArrowDownOutlined />
            </IconButton>
          ) : (
            <IconButton
              variant="outlined"
              sx={{
                margin: 2,
                width: "32px",
                height: "32px",
              }}
              onClick={handleExpandClick}
            >
              <ExpandLessOutlined />
            </IconButton>
          )}
        </Box>
        <Collapse in={expanded}>
          <CardContent>
            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                sx={{ marginTop: 4, overflow: "scroll" }}
              >
                {isError && (
                  <Grid item>
                    <Alert severity="error">
                      There was a error trying to load the area count details.
                    </Alert>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                  }}
                >
                  <Grid item>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 1,
                        border: "1px solid",
                        paddingLeft: 2,
                        borderColor: (theme) => theme.palette.terrain[300],
                        backgroundColor: (theme) => theme.palette.pure.white,
                        borderRadius: 2,
                        width: {
                          xs: "300px",
                          sm: "300px",
                          md: "400px",
                        },
                        height: "48px",
                      }}
                    >
                      <Typography variant="body1" sx={{ marginRight: 4 }}>
                        Input Figures By:
                      </Typography>
                      <ToggleButton
                        selected={isMobileMode}
                        value="slider"
                        onChange={() => toggleMobileMode(!isMobileMode)}
                        sx={{
                          height: "32px",
                          "&.Mui-selected, &.Mui-selected:hover": {
                            color: (theme) => theme.palette.pure.white,
                            backgroundColor: (theme) =>
                              theme.palette.secondary[800],
                          },
                        }}
                      >
                        <Typography variant="body2">Slider</Typography>
                      </ToggleButton>
                      <ToggleButton
                        selected={!isMobileMode}
                        value="keyboard"
                        onChange={() => toggleMobileMode(!isMobileMode)}
                        sx={{
                          height: "32px",
                          "&.Mui-selected, &.Mui-selected:hover": {
                            color: (theme) => theme.palette.pure.white,
                            backgroundColor: (theme) =>
                              theme.palette.secondary[800],
                          },
                        }}
                      >
                        <Typography variant="body2">Keyboard</Typography>
                      </ToggleButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {areaCount && (
                <AreaCountList
                  areaCountName={areaCount.name}
                  stockCountRecords={areaCount.stockCountRecords}
                  isMobileMode={isMobileMode}
                  updateBaseRecordQuantity={handleUpdateBaseRecordQuantity}
                />
              )}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>

      <AddProductToCountModal
        areaCountId={areaCount?.id}
        establishmentId={establishmentid}
        stockCountId={stockcountid}
        isOpen={isAddProductOpen}
        toggleModalOpen={handleCloseAddProductModal}
      />
      <CreateAreaModal
        isOpen={createAreaModalOpen}
        toggleModalOpen={toggleCreateAreaModalOpen}
      />
    </>
  );
};

export default UnorganizedAreaCountDetails;
