import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";

const SortBy = ({ sortByOptions, selectedSortBy, setSelectedSortBy }) => {
  const toggleButtonStyle = {
    borderTopRightRadius: "8px !important",
    borderBottomRightRadius: "8px !important",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    border: "none",
    backgroundColor: (theme) => theme.palette.terrain[200],
    "&.Mui-selected, &.Mui-selected:hover": {
      color: (theme) => theme.palette.pure.white,
      backgroundColor: (theme) => theme.palette.terrain[900],
    },
  };

  const handleSelect = (_, v) => {
    setSelectedSortBy(v);
  };
  return (
    <Box>
      <Box>
        <Typography variant="h5">Sort By</Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <ToggleButtonGroup
          value={selectedSortBy}
          onChange={handleSelect}
          exclusive
        >
          {sortByOptions.map((x) => (
            <ToggleButton
              id={x.value}
              key={x.value}
              value={x.value}
              sx={[
                toggleButtonStyle,
                {
                  marginRight: 2,
                },
              ]}
            >
              <Typography sx={{ textTransform: "none" }} variant="subtitle1">
                {x.key}
              </Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default SortBy;
