import React, { useContext, useEffect } from "react";

import Box from "@mui/material/Box";
import useInvoice from "../../hooks/useInvoice";
import { useParams } from "react-router-dom";
import SpecSpinner from "../../components/SpecSpinner";
import useVendors from "../../hooks/useVendors";
import InvoiceDetailsForm from "./components/InvoiceDetailsForm";
import useProductLibrary from "../../hooks/useProductLibrary";
import ApprovedInvoice from "./components/ApprovedInvoice";
import inventoryObjects from "../../clients/inventoryObjects";
import useEstablishmentExpenseGroups from "../../hooks/useEstablishmentExpenseGroups";
import Store, { activeRouteKeys } from "../../Store/Store";

const InvoiceDetails = () => {
  let { establishmentid, invoiceid } = useParams();
  const store = useContext(Store);
  const [{ isLoading: isLoadingVendors }, vendorMap] =
    useVendors(establishmentid);
  const [{ isLoading: isLoadingProducts, data: productData }] =
    useProductLibrary(establishmentid);
  const { isLoading: isLoadingExpenseGroups, data: expenseGroupsData } =
    useEstablishmentExpenseGroups(establishmentid);
  const { data: invoiceData, isLoading: isLoadingInvoice } = useInvoice(
    establishmentid,
    invoiceid
  );

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.invoices,
      `spec/${establishmentid}/invoices/${invoiceid}`
    );
  }, []);

  if (
    isLoadingInvoice ||
    isLoadingVendors ||
    isLoadingProducts ||
    isLoadingExpenseGroups
  ) {
    return <SpecSpinner text="Loading Invoice Details..." open />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        padding: 4,
      }}
    >
      {invoiceData?.invoice?.status ===
      inventoryObjects.invoiceStatuses.approved ? (
        <ApprovedInvoice
          invoice={invoiceData.invoice}
          vendorMap={vendorMap}
          productLibrary={productData.products}
          expenseGroups={expenseGroupsData.expenseGroups}
        />
      ) : (
        <InvoiceDetailsForm
          invoice={invoiceData.invoice}
          vendorMap={vendorMap}
          productLibrary={productData.products}
          expenseGroups={expenseGroupsData.expenseGroups}
        />
      )}
    </Box>
  );
};

export default InvoiceDetails;
