import { Box, Grid, IconButton, List, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KegSpecific from "./KegSpecific";
import CreateKegSizeModal from "../../../components/Modals/CreateKegSizeModal";
import Store from "../../../Store/Store";
import useEstablishmentKegSizes from "../../../hooks/useEstablishmentKegSizes";

const UnitSizesSettings = ({ defaultStyle }) => {
  const [kegSizeModalOpen, toggleKegSizeModalOpen] = useState(false);
  const store = useContext(Store);
  const { data: kegSizes } = useEstablishmentKegSizes(store.currentEstablishment?.id);

  return (
    <Box sx={{ height: "fit-content" }}>
      <Box sx={{ marginTop: 6 }}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={10}>
            <Typography variant="h4">Keg Specific</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{
                width: "32px",
                height: "32px",
                border: "2px solid",
                borderColor: (theme) => theme.palette.primary[800],
                marginRight: 4,
              }}
              onClick={() => toggleKegSizeModalOpen(true)}
            >
              <AddOutlinedIcon sx={{ fontSize: 20 }} />
            </IconButton>
            <InfoOutlinedIcon
              sx={{
                color: (theme) => theme.palette.primary[800],
                cursor: "pointer",
              }}
            />
          </Grid>
          <Box sx={{ marginTop: 4 }}>
            <Typography variant="body1">
              Add the sizes that your establishment uses. This will show up
              when adding unit sizes to your products.
            </Typography>
          </Box>
        </Grid>
        <Box sx={{ marginTop: 4 }}>
          <List>
            {kegSizes ? kegSizes.unitsOfMeasurement.map((x) => (
              <KegSpecific
                key={x.kegName}
                keg={x}
                defaultStyle={defaultStyle}
              />
            )) : " "} 
          </List>
        </Box>
      </Box>
      <CreateKegSizeModal
        isOpen={kegSizeModalOpen}
        toggleModalOpen={toggleKegSizeModalOpen}
        headerText="Create Keg Size"
        buttonText="Create Keg Size"
      />
    </Box>
  );
};

export default UnitSizesSettings;
