import { useQuery } from "react-query";
import { checkAccountDeletable } from "../clients/establishmentsClient";

function useAccountDeletableCheck() {
  const queryReturnValues = useQuery(
    ["account-deletable-check"],
    checkAccountDeletable,
  );

  return queryReturnValues;
}

export default useAccountDeletableCheck;
