import React from "react";
import { modalBoxStyle } from "..//styles";
import { Typography, Box, Modal, Button } from "@mui/material";
import LiquorIcon from '@mui/icons-material/Liquor';

const LegacyUpgradeEstablishmentModal = ({
  toggleUpgradeEstablishmentModalOpen,
  open,
  isLoading,
  hasError,
  performUpgradeCallback,
  isUpgradeComplete,
  handlePostUpgrade
}) => {

  const dummyCloseAfterUpgrade = () => {
    return;
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={isUpgradeComplete ? dummyCloseAfterUpgrade : toggleUpgradeEstablishmentModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalBoxStyle}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LiquorIcon sx={{ fontSize: 100 }} />
          </div>
          <Typography
            sx={{
              fontSize: "2rem",
              marginBottom: "2rem",
              display: "flex",
              fontWeight: "bold",
              justifyContent: "center",
              marginTop: 6
            }}
          >
            {" "}
            Upgrade Establishment{" "}
          </Typography>
          { hasError ? (
            <Typography
            sx={{
              marginBottom: "1.5rem",
              display: "flex",
              fontWeight: "bold",
              justifyContent: "center",
              color: theme => theme.palette.error.main,
            }}
          >There was a problem trying to upgrade your establishment. Please try reloading the page and attempting your request again, or reach out if the issue persists.</Typography>
          ) : null}
          {isUpgradeComplete ? (
            <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
            variant="body1"
          >
            Congratulations, your upgrade is complete! Click below to refresh your establishment and see your updated establishment!
          </Typography>
          ) : (
            <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
            variant="body1"
          >
            Are you sure you are ready to upgrade your establishment? You will lose access to the Legacy Menus tab, and all of your staff on the mobile app will no longer see the menus created there. Once you upgrade your establishment, your mobile app will start showing data and sections for inventory and from the new menus tab (as long as the mobile app has been updated to the latest release). This operation cannot be undone. 
          </Typography>
          )}
          <div
            style={{
              display: "flex",
              marginTop: "1rem",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              disabled={isLoading || isUpgradeComplete}
              sx={{
                borderRadius: 2,
              }}
              onClick={toggleUpgradeEstablishmentModalOpen}
            >
              <Typography variant="smallButton">Cancel</Typography>
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{
                marginLeft: "1rem",
                padding: "0px 25px 0px 25px",
                borderRadius: 2,
              }}
              onClick={isUpgradeComplete ? handlePostUpgrade : performUpgradeCallback}
            >
              {isUpgradeComplete ? (
                <Typography variant="smallButton">Reload</Typography>
              ) : (
                <Typography variant="smallButton">Convert to New Menus</Typography>
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LegacyUpgradeEstablishmentModal;
