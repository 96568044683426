import React, { useEffect, useState } from "react";
import { v4 as guidGenerator } from "uuid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SpecBackButton from "../SpecBackButton";
import Divider from "@mui/material/Divider";
import GridContainer from "../GridContainer";
import RecipeRowItem from "./components/RecipeRowItem";
import { useForm } from "react-hook-form";
import CTABlock from "../CTABlock";
import { Switch } from "@mui/material";
import ImageControl from "../ImageControl";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddIcon from "@mui/icons-material/Add";

var _ = require("lodash");

export const validateRecipeIngredients = (ingredients) => {
  let ingredientsCopy = [...ingredients];
  let filteredIngs = ingredientsCopy.filter((x) => {
    if (!x.name && !x.unit && !x.amount) {
      return;
    } else {
      return x;
    }
  });

  let invalidIngs = [];

  filteredIngs.forEach((i) => {
    if (!i.name) {
      invalidIngs.push(i);
    }
  });

  return {
    cleanedIngredients: filteredIngs,
    invalidIngredients: invalidIngs.length > 0 ? invalidIngs : undefined,
  };
};

const RecipeDrinkForm = ({
  startingRecipe,
  submitCallback,
  goBack,
  isEmbedded,
  categoryId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: startingRecipe || {},
    mode: "all",
  });
  const [recipeActive, toggleRecipeActive] = useState(true);
  const [recipeImageUrl, setRecipeImageUrl] = useState("");
  const [recipeIngredients, updateIngredients] = useState([]);
  const [ingredientErrors, updateIngredientErrors] = useState([]);
  const [isImageControlOpen, toggleIsImageControlOpen] = useState(false);

  const handleActiveToggle = () => {
    toggleRecipeActive(!recipeActive);
  };

  useEffect(() => {
    toggleRecipeActive(startingRecipe ? startingRecipe.active : true);
    setRecipeImageUrl(startingRecipe ? startingRecipe.imageURL : "");
    updateIngredients(
      startingRecipe ? _.cloneDeep(startingRecipe.ingredients) : []
    );
    updateIngredientErrors([]);
  }, [startingRecipe]);

  const handleSave = (recipe) => {
    let hasError = false;
    // the text fields for the recipe will have been validated by react-hook-form
    // here we validate the ingredients and set them along with the active status
    const validationResult = validateRecipeIngredients(recipeIngredients);
    if (validationResult.invalidIngredients) {
      hasError = true;
      updateIngredientErrors(validationResult.invalidIngredients);
    }

    if (!hasError) {
      recipe.ingredients = validationResult.cleanedIngredients;
      recipe.active = recipeActive;
      recipe.categoryId = categoryId;
      recipe.imageURL = recipeImageUrl;
      submitCallback(recipe);
    }
  };

  const handleAddIngredient = () => {
    const updatedIngredients = [...recipeIngredients];
    updatedIngredients.push({
      id: guidGenerator(),
      name: "",
      amount: "",
      unit: "",
    });
    updateIngredients(updatedIngredients);
  };

  const handleEditIngredient = (id, property, value) => {
    if (property === "name") {
      let ingErrCopy = [...ingredientErrors];
      let filteredErrors = ingErrCopy.filter((e) => e.id !== id);
      updateIngredientErrors(filteredErrors);
    }
    const updatedIngredients = [...recipeIngredients];
    const ingIndex = updatedIngredients.findIndex((i) => i.id === id);
    updatedIngredients[ingIndex][property] = value;
    updateIngredients(updatedIngredients);
  };

  const handleRemoveIngredient = (id) => {
    let updatedIngredients = [...recipeIngredients];
    updatedIngredients = updatedIngredients.filter((i) => i.id !== id);
    updateIngredients(updatedIngredients);
  };

  const imageControlCallback = (photoLocation) => {
    setRecipeImageUrl(photoLocation);
    toggleIsImageControlOpen(false)
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.pure.white,
        }}
      >
        <GridContainer direction="column">
          {!isEmbedded && (
            <Box sx={{ paddingLeft: "24px" }}>
              <SpecBackButton onClick={() => goBack()} />
            </Box>
          )}
          <Box>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography variant="h3">Drink Information</Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 4,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box style={{ height: 10, marginTop: "0px" }}>{""}</Box>
                      <TextField
                        size="small"
                        fullWidth
                        name="name"
                        id="name"
                        label="Name of Drink"
                        placeholder="Enter a drink name"
                        InputLabelProps={{ shrink: true, required: true }}
                        {...register("name", {
                          required: "Please enter a drink name",
                        })}
                      />
                      {errors.name ? (
                        <Box
                          sx={{
                            color: "red",
                            marginTop: "0px",
                            paddingTop: "4px",
                            height: 10,
                          }}
                        >
                          {errors.name.message}
                        </Box>
                      ) : (
                        <Box style={{ height: 10, marginTop: "0px" }}>{""}</Box>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      color="secondary"
                      checked={recipeActive}
                      onClick={handleActiveToggle}
                    />
                    <Typography variant="subtitle2">
                      {recipeActive ? "Status: Active" : "Status: Inactive"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "flex-start", sm: "flex-end" },
                    }}
                  >
                    <ImageControl
                      imageSrc={recipeImageUrl}
                      imageControlCallback={imageControlCallback}
                      isImageControlOpen={isImageControlOpen}
                      toggleIsImageControlOpen={toggleIsImageControlOpen}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </GridContainer>
        <Divider />
        <GridContainer direction="column">
          <Box sx={{ padding: 2, marginBottom: 4 }}>
            <Grid container direction="column" spacing={4}>
              <Grid item sx={{ marginTop: 2 }}>
                <Typography variant="h3">Drink Description</Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={9}>
                      <Grid item>
                        <Grid container direction="column">
                          <TextField
                            size="small"
                            fullWidth
                            name="glass"
                            id="glass"
                            label="Glass Type"
                            placeholder="Enter a glass type"
                            InputLabelProps={{ shrink: true }}
                            {...register("glass")}
                          />
                          {errors.glass ? (
                            <Box
                              sx={{
                                color: "red",
                                marginTop: "0px",
                                paddingTop: "4px",
                                height: 10,
                              }}
                            >
                              {errors.glass?.message}
                            </Box>
                          ) : (
                            <Box style={{ height: 10, marginTop: "0px" }}>
                              {""}
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <TextField
                          size="small"
                          fullWidth
                          id="outlined-helperText"
                          label="Garnish"
                          placeholder="Enter a garnish"
                          InputLabelProps={{ shrink: true }}
                          {...register("garnish")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column">
                      <TextField
                        size="small"
                        id="preparation"
                        label="Preparation"
                        name="preparation"
                        placeholder="Step 1... Step 2... Step 3..."
                        InputLabelProps={{ shrink: true, required: true }}
                        multiline
                        fullWidth
                        rows={5}
                        {...register("preparation", {
                          required: {
                            value: true,
                            minLength: 1,
                            message: "Please enter preparation instructions",
                          },
                        })}
                      />
                      {errors.preparation ? (
                        <Box
                          sx={{
                            color: "red",
                            marginTop: "0px",
                            paddingTop: "4px",
                            height: 10,
                          }}
                        >
                          {errors.preparation?.message}
                        </Box>
                      ) : (
                        <Box style={{ height: 10, marginTop: "0px" }}>{""}</Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </GridContainer>
        <Divider />
        <GridContainer direction="column">
          <Box sx={{ padding: 2 }}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  spacing={4}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} md={7} lg={8} xl={9}>
                    <Typography variant="h3">Drink Ingredients</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    lg={4}
                    xl={3}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {recipeIngredients?.length > 0 && (
                      <Button
                        variant="contained"
                        onClick={handleAddIngredient}
                        sx={{
                          borderRadius: 2,
                        }}
                      >
                        <AddOutlinedIcon sx={{ marginRight: 1 }} />
                        <Typography variant="smallButton">
                          Add New Ingredient
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  {recipeIngredients?.length === 0 ? (
                    <CTABlock
                      buttonText="Add New Ingredient"
                      header="Your Glass is Empty"
                      subheader="You have not entered any ingredients yet. Get started now..."
                      handleCTA={handleAddIngredient}
                      ButtonImage={AddIcon}
                    />
                  ) : (
                    <>
                      <Grid item>
                        <Grid container direction="row" spacing={4}>
                          <Grid item xs={2}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Amount
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Unit
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Ingredient
                            </Typography>
                          </Grid>
                          <Grid item xs={1}></Grid>
                        </Grid>
                      </Grid>

                      {recipeIngredients?.map((x) => (
                        <RecipeRowItem
                          editIngredient={handleEditIngredient}
                          removeIngredient={handleRemoveIngredient}
                          ingredient={x}
                          key={x.id}
                          isInvalidIngredient={ingredientErrors.some(
                            (e) => e.id === x.id
                          )}
                        />
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </GridContainer>
        <Divider />
        <GridContainer direction="column">
          <Box sx={{ padding: 2, marginTop: 4 }}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography variant="h3">Additional Information</Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Grid item sx={{ marginBottom: 4 }}>
                      <TextField
                        size="small"
                        id="talkingPoints"
                        label="Tasting Notes"
                        placeholder="Step 1..."
                        multiline
                        fullWidth
                        rows={5}
                        {...register("talkingPoints", { required: false })}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      id="history"
                      label="History"
                      placeholder="Step 1..."
                      multiline
                      fullWidth
                      rows={5}
                      {...register("history", { required: false })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </GridContainer>
        <Divider />
        <GridContainer>
          <Grid
            container
            // direction="row-reverse"
            spacing={2}
            sx={{
              paddingTop: "12px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: "100%",
                  borderRadius: 2,
                }}
              >
                <Typography variant="smallButton">Save</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={goBack}
                sx={{
                  width: "100%",
                  borderRadius: 2,
                }}
              >
                <Typography variant="smallButton">Cancel</Typography>
              </Button>
            </Grid>
          </Grid>
        </GridContainer>
      </Box>
    </form>
  );
};

export default RecipeDrinkForm;
