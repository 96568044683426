import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import { Avatar, Menu, MenuItem, Box } from "@mui/material";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { useNavigate } from "react-router-dom";
import utilFunctions from "../../../utilities/utilFunctions";

const MenulistItem = ({
  deleteModalCallback,
  copyModalCallback,
  moveModalCallback,
  menuId,
  establishmentId,
  menuItem,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMoveModal = () => {
    moveModalCallback(menuItem);
    handleClose();
  };

  const handleCopyModal = () => {
    copyModalCallback(menuItem);
    handleClose();
  };

  const handleDeleteModal = () => {
    deleteModalCallback(menuItem);
    handleClose();
  };

  return (
    <>
      <ListItem
        key={menuItem.id}
        disableGutters
        sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
        secondaryAction={
          <IconButton
            sx={{
              color: (theme) => theme.palette.terrain[900],
              "&:hover": {
                backgroundColor: (theme) => theme.palette.pure.white,
                color: (theme) => theme.palette.pure.black,
              },
            }}
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
        }
      >
        <ListItemButton
          onClick={() =>
            navigate(
              `/homebase/${establishmentId}/menus/${menuId}/${menuItem.id}`
            )
          }
        >
          {menuItem.imageURL ? (
            <Avatar
              sx={{
                zIndex: 1,
                width: "32px",
                height: "32px",
                resize: "contain",
                alignSelf: "center",
              }}
              src={menuItem.imageURL}
            />
          ) : (
            <Box
              sx={{
                zIndex: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.terrain[400],
                borderRadius: "100%",
                overflow: "hidden",
              }}
            >
              <AddPhotoAlternateOutlinedIcon sx={{ fontSize: "12px" }} />
            </Box>
          )}
          <ListItemText
            primary={utilFunctions.capitalizeString(menuItem.name)}
            sx={{ marginLeft: 4 }}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleMoveModal} sx={{ margin: "5px" }}>
          <DriveFileMoveOutlinedIcon sx={{ marginRight: "5px" }} />
          Move To
        </MenuItem>
        <MenuItem onClick={handleCopyModal} sx={{ margin: "5px" }}>
          <ContentCopyIcon sx={{ marginRight: "5px" }} />
          Copy
        </MenuItem>
        <MenuItem onClick={handleDeleteModal} sx={{ margin: "5px" }}>
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenulistItem;
