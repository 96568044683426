import React from "react";
import { modalBoxStyle } from "..//styles";
import { Typography, Box, Modal, Button } from "@mui/material";
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const StaffOwnershipTransferModal = ({
  staffMember,
  toggleStaffOwnershipTransferModal,
  open,
  isLoading,
  hasError,
  transferOwnershipCallback
}) => {

  if (!staffMember) {
    return null;
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={toggleStaffOwnershipTransferModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalBoxStyle}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <GroupAddIcon sx={{ fontSize: 100 }} />
          </div>
          <Typography
            style={{
              fontSize: "2rem",
              marginBottom: "2rem",
              display: "flex",
              fontWeight: "bold",
              justifyContent: "center",
            }}
          >
            {" "}
            Transfer Ownership{" "}
          </Typography>
          { hasError ? (
            <Typography
            sx={{
              marginBottom: "1.5rem",
              display: "flex",
              fontWeight: "bold",
              justifyContent: "center",
              color: theme => theme.palette.error.main,
            }}
          >There was a problem trying to transfer ownership. Please try reloading the page and attempting your request again, or reach out if the issue persists.</Typography>
          ) : null}
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
            variant="body1"
          >
            Are you sure you want to transfer ownership of this establishment to {staffMember?.firstName} {staffMember?.lastName}?
            This will give them the ability to manage billing for the establishment. There can only be one account marked as the owner, so this will remove your ownership as well.
          </Typography>
          <div
            style={{
              display: "flex",
              marginTop: "1rem",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              disabled={isLoading}
              sx={{
                borderRadius: 2,
              }}
              onClick={toggleStaffOwnershipTransferModal}
            >
              <Typography variant="smallButton">Cancel</Typography>
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{
                marginLeft: "1rem",
                padding: "0px 25px 0px 25px",
                borderRadius: 2,
              }}
              onClick={transferOwnershipCallback}
            >
              <Typography variant="smallButton">Yes</Typography>
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default StaffOwnershipTransferModal;
