import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React, { useContext } from "react";
import inventoryObjects from "../../../../clients/inventoryObjects";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import Store from "../../../../Store/Store";
import utilFunctions from "../../../../utilities/utilFunctions";

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
  fontWeight: 400,
};

const MiscRow = ({ lineItem, expenseGroups }) => {
  const store = useContext(Store);
  const { currencySymbol, currency, locale } = useVendorCurrency(
    store.user?.country
  );

  const extractExpenseGroupName = () => {
    const group = expenseGroups.find(x => x.id === lineItem.expenseGroupId);

    return group?.name || "-";
  }

  const extractExpenseSubgroupName = () => {
    const subgroup = expenseGroups.find(x => x.id === lineItem.expenseGroupId)?.expenseSubgroups?.find(x => x.id === lineItem.expenseSubgroupId);

    return subgroup?.name || "-";
  }

  return (
    <TableRow sx={{ height: 24 }}>
      <TableCell sx={[tableCellStyle, { width: "400px" }]}>
        <Box>
          <Typography variant="body2">{lineItem.name}</Typography>
        </Box>
        <Box>
          <Typography variant="overline">{lineItem.type === inventoryObjects.miscLineItemTypes.credit ? "credit" : "expense"}</Typography>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {lineItem.expenseGroupId ? extractExpenseGroupName() : '-'}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {lineItem.expenseSubgroupId ? extractExpenseSubgroupName() : '-'}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>{currencySymbol}{utilFunctions.convertCentsToLargerFormatCurrency(lineItem.cost?.amount, locale, currency)}</Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default MiscRow;
