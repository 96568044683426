import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const TagPill = ({ selected, onClickCallback, tag }) => {
  return (
    <Chip
      label={
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: selected
                ? (theme) => theme.palette.pure.white
                : (theme) => theme.palette.terrain[900],
            }}
          >
            {tag?.name || ""}
          </Typography>
          <CloseOutlinedIcon
            sx={{
              fontSize: 14,
              color: selected
                ? (theme) => theme.palette.pure.white
                : (theme) => theme.palette.terrain[900],
              marginLeft: 2,
            }}
          />
        </Box>
      }
      size="medium"
      onClick={() => onClickCallback(tag)}
      clickable
      variant="outlined"
      sx={{
        margin: 1,
        backgroundColor: selected
          ? (theme) => theme.palette.terrain[900]
          : (theme) => theme.palette.pure.white,
        borderColor: (theme) => theme.palette.primary[800],
      }}
    />
  );
};

export default TagPill;
