import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AdditionalOptions from "./AdditionalOptions";
import ShowOptions from "./ShowOptions";
// import SortBy from "./SortBy";
import FilterBy from "./FilterBy";
import DateRange from "./DateRange";
import { presetDateRangeOptions } from "../../../../../utilities/dateRangeUtils";
import {
  invoiceExpenseGroupingReportGroupingOptions,
  invoiceReportTypes,
  reportDateGroupings,
} from "../../../../../clients/inventoryObjects";
import inventoryClient from "../../../../../clients/inventoryClient";
import utilFunctions from "../../../../../utilities/utilFunctions";
import { useMutation } from "react-query";
import ReportingFooter from "../../ReportingFooter";
import DateGrouping from "./DateGrouping";
import InsufficientReportDataModal from "../../../../../components/Modals/InsufficientReportDataModal";

const dateSelectionFormats = {
  presetOption: 0,
  customRange: 1,
};

const dateGranularities = [
  { key: "Day", value: reportDateGroupings.day },
  { key: "Week", value: reportDateGroupings.week },
  { key: "Month", value: reportDateGroupings.month },
  { key: "Quarter", value: reportDateGroupings.quarter },
  { key: "Year", value: reportDateGroupings.year },
];

const ExpenseGroupOrSubgroupTotalsByDate = ({
  establishmentId,
  establishmentTimezone,
  expenseGroups,
  setAlertSuccess,
  setErrorMessage,
}) => {
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customStartDateDisplay, setCustomStartDateDisplay] = useState(null);
  const [customEndDateDisplay, setCustomEndDateDisplay] = useState(null);
  const [selectedPresetDateRange, setSelectedPresetDateRange] = useState(
    presetDateRangeOptions[0].value
  );
  const [dateSelectionFormat, setDateSelectionFormat] = useState(
    dateSelectionFormats.presetOption
  );
  const [selectedDateGrouping, setDateGrouping] = useState(
    reportDateGroupings.month
  );
  const [isUsingExpenseGroupFilter, toggleIsUsingExpenseGroupFilter] =
    useState(false);
  const [expenseGroupFilters, setExpenseGroupFilters] = useState([]);
  const [allowUnspecified, toggleAllowUnspecified] = useState(true);
  const [optionsForSubgroupFilter, setOptionsForSubgroupFilter] = useState([]);
  const [optionsForSubgroupFilterMap, setOptionsForSubgroupFilterMap] =
    useState(undefined);
  const [reportGroupingChoice, setReportGroupingChoice] = useState(
    invoiceExpenseGroupingReportGroupingOptions.expenseGroupsOnly
  );
  const [isEmptyResultModalOpen, toggleIsEmptyResultModalOpen] =
    useState(false);
  const [includeSumRow, toggleIncludeSumRow] = useState(false);

  useEffect(() => {
    // we need to get expense group/subgroups in a structure that will play nice with display
    // in a single dropdown. This is also where typescript would be nice
    const displayOptions = [];
    expenseGroups?.forEach((e) => {
      // we are providing a subgroup filter, so skip expense groups with none
      if (e.expenseSubgroups === null || e.expenseSubgroups?.length === 0) {
        return;
      }

      const expenseGroupDisplay = Object.assign({}, e, { type: "group" });

      displayOptions.push(expenseGroupDisplay);

      e.expenseSubgroups.forEach((s) => {
        const subgroupDisplay = {
          id: s.id,
          expenseGroupId: e.id,
          name: s.name,
          type: "subgroup",
          parentName: e.name,
        };
        displayOptions.push(subgroupDisplay);
      });
    });

    setOptionsForSubgroupFilterMap(
      new Map(displayOptions.map((x) => [x.id, x]))
    );
    setOptionsForSubgroupFilter(displayOptions);
  }, [expenseGroups]);

  const generateInvoiceReportMutation = useMutation(
    inventoryClient.generateInvoiceReport,
    {
      onSuccess: (response) => {
        if (response.status === 204) {
          toggleIsEmptyResultModalOpen(true);
        } else {
          utilFunctions.downloadFile(
            response?.data?.report?.invoiceReportMetadata?.s3PresignedUrl
          );
          setAlertSuccess(
            "Report generated successfully, your download should have started."
          );
        }
      },
      onError: () => {
        setErrorMessage("Report generation failed!");
      },
    }
  );

  const handleGenerateReport = () => {
    const payload = {
      establishmentId: establishmentId,
      reportType: invoiceReportTypes.invoiceExpenseGroupTotals,
      invoiceExpenseGroupingTotalsFilters: {
        allowUnspecified: allowUnspecified,
        dateGroupingSelection: selectedDateGrouping,
        includeSumRow: includeSumRow,
        expenseGroupIdsFilter:
          isUsingExpenseGroupFilter &&
          reportGroupingChoice !=
            invoiceExpenseGroupingReportGroupingOptions.expenseSubgroupsOnly
            ? expenseGroupFilters
                .filter((x) => x.type === "group")
                .map((x) => x.id)
            : [], // api treats an empty array as not wanting filters
        expenseSubgroupIdsFilter:
          isUsingExpenseGroupFilter &&
          reportGroupingChoice !=
            invoiceExpenseGroupingReportGroupingOptions.expenseGroupsOnly
            ? expenseGroupFilters
                .filter((x) => x.type === "subgroup")
                .map((x) => x.id)
            : [],
        groupingType: reportGroupingChoice,
        
      },
    };

    if (dateSelectionFormat == dateSelectionFormats.presetOption) {
      const dateRange = presetDateRangeOptions
        .find((x) => x.value === selectedPresetDateRange)
        .func(establishmentTimezone);
      payload.startDate = dateRange.start;
      payload.endDate = dateRange.end;
    } else {
      payload.startDate = customStartDate;
      payload.endDate = customEndDate;
    }

    generateInvoiceReportMutation.mutate(payload);
  };

  const handleGroupAddClick = (newValue, updatedObj) => {
    const existingSet = new Set(newValue);
    const finalValue = [...newValue];
    updatedObj.expenseSubgroups.forEach((s) => {
      if (!existingSet.has(s.id)) {
        finalValue.push(optionsForSubgroupFilterMap.get(s.id));
      }
    });
    setExpenseGroupFilters(finalValue);
  };

  const handleGroupRemoveClick = (newValue, updatedObj) => {
    const subgroupIdSet = new Set(updatedObj.expenseSubgroups.map((x) => x.id));
    const finalValue = newValue.filter((x) => !subgroupIdSet.has(x.id));

    setExpenseGroupFilters(finalValue);
  };

  const handleSubgroupAddClick = (newValue, updatedObj) => {
    const currentlySelectedSet = new Set(newValue.map((x) => x.id));

    if (!currentlySelectedSet.has(updatedObj.expenseGroupId)) {
      const parentGroup = optionsForSubgroupFilter.find(
        (x) => x.id === updatedObj.expenseGroupId
      );
      const finalValue = [...newValue, parentGroup];
      setExpenseGroupFilters(finalValue);
    } else {
      setExpenseGroupFilters([...newValue]);
    }
  };

  const handleSubgroupRemoveClick = (newValue) => {
    setExpenseGroupFilters([...newValue])
  };

  const handleClearGroupFilters = () => {
    setExpenseGroupFilters([]);
  };

  const handleSetReportGroupingChoice = (v) => {
    setReportGroupingChoice(v);
    handleClearGroupFilters();
  };

  return (
    <>
      <Box>
        <Box sx={{ marginTop: 8 }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "fit-content",
                width: "70%",
                borderRadius: 2,
                backgroundColor: (theme) => theme.palette.terrain[200],
                border: "1px solid",
                borderColor: (theme) => theme.palette.terrain[300],
                padding: 2,
              }}
            >
              <Typography variant="body2">
                Invoice totals grouped by Expense Groups or Subgroups over a
                period of time.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <DateRange
            customStartDate={customStartDate}
            setCustomStartDate={setCustomStartDate}
            customEndDate={customEndDate}
            setCustomEndDate={setCustomEndDate}
            selectedPresetDateRange={selectedPresetDateRange}
            setSelectedPresetDateRange={setSelectedPresetDateRange}
            dateSelectionFormat={dateSelectionFormat}
            setDateSelectionFormat={setDateSelectionFormat}
            dateSelectionFormats={dateSelectionFormats}
            presetDateRangeOptions={presetDateRangeOptions}
            establishmentTimezone={establishmentTimezone}
            customStartDateDisplay={customStartDateDisplay}
            setCustomStartDateDisplay={setCustomStartDateDisplay}
            customEndDateDisplay={customEndDateDisplay}
            setCustomEndDateDisplay={setCustomEndDateDisplay}
          />
        </Box>

        <Box sx={{ marginTop: 8 }}>
          <DateGrouping
            dateGroupings={dateGranularities}
            selectedDateGrouping={selectedDateGrouping}
            setDateGrouping={setDateGrouping}
          />
        </Box>
        {/* <Box sx={{ marginTop: 8 }}>
          <SortBy />
        </Box> */}
        <Box sx={{ marginTop: 8 }}>
          <ShowOptions
            setReportGroupingChoice={handleSetReportGroupingChoice}
            reportGroupingChoice={reportGroupingChoice}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <FilterBy
            expenseGroups={optionsForSubgroupFilter}
            isUsingExpenseGroupFilter={isUsingExpenseGroupFilter}
            toggleIsUsingExpenseGroupFilter={toggleIsUsingExpenseGroupFilter}
            expenseGroupFilters={expenseGroupFilters}
            handleGroupAddClick={handleGroupAddClick}
            handleGroupRemoveClick={handleGroupRemoveClick}
            handleSubgroupAddClick={handleSubgroupAddClick}
            handleSubgroupRemoveClick={handleSubgroupRemoveClick}
            handleClearGroupFilters={handleClearGroupFilters}
            reportGroupingChoice={reportGroupingChoice}
          />
        </Box>
        <Box sx={{ marginTop: 0 }}>
          <AdditionalOptions
            allowUnspecified={allowUnspecified}
            toggleAllowUnspecified={toggleAllowUnspecified}
            includeSumRow={includeSumRow}
            toggleIncludeSumRow={toggleIncludeSumRow}
          />
        </Box>
      </Box>
      <ReportingFooter
        buttonText={"Create Report"}
        disableButton={
          isUsingExpenseGroupFilter && expenseGroupFilters?.length <= 0 // if we haven't chosen any expense groups but checked the box
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange && // if we selected custom range but didn't choose dates
              (!customEndDate || !customStartDate)
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange &&
              customEndDate < customStartDate // if the custom end date is before the start date
            ? true
            : false
        }
        submit={handleGenerateReport}
        isLoading={generateInvoiceReportMutation?.isLoading}
      />
      <InsufficientReportDataModal
        isOpen={isEmptyResultModalOpen}
        toggleModalOpen={toggleIsEmptyResultModalOpen}
        loading={false}
      />
    </>
  );
};

export default ExpenseGroupOrSubgroupTotalsByDate;
