import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SpecBackButton from "../../components/SpecBackButton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import inventoryClient from "../../clients/inventoryClient";
import SpecSpinner from "../../components/SpecSpinner";
import { useNavigate, useParams } from "react-router-dom";
import OrderReviewRow from "./components/OrderDetailsRow";
import OrderStatusChip from "../../components/OrderStatusChip";
import PlaceOrderButton from "../../components/PlaceOrderButton";
import useVendorCurrency from "../../hooks/useVendorCurrency";
import Store, { activeRouteKeys } from "../../Store/Store";
import utilFunctions from "../../utilities/utilFunctions";
import DescriptionToolTip from "../../components/DescriptionToolTip";
import ProductSetupIllustrationModal from "../../components/Modals/ProductSetupIllustrationModal";
import useEstablishmentSettings from "../../hooks/useEstablishmentSettings";
import useVendors from "../../hooks/useVendors";
var moment = require("moment");

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const OrderDetails = () => {
  const [productSetupModalOpen, toggleProductSetupModalOpen] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  let { establishmentid: establishmentId, orderid: orderId } = useParams();
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );
  const [{ isLoading: isLoadingVendors }, vendorMap] = useVendors(
    store.currentEstablishment?.id
  );

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.ordering,
      `spec/${establishmentId}/order/${orderId}`
    );
  }, []);

  const handleToggleProductSetupModal = () => {
    toggleProductSetupModalOpen(true);
  };

  const { data, isLoading } = useQuery(
    ["order-details", { establishmentId, orderId }],
    inventoryClient.getOderById
  );

  const markOrderAsPlacedMutation = useMutation(
    inventoryClient.markOrderAsPlaced,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["ordercart", { establishmentId }],
        });

        queryClient.invalidateQueries({
          queryKey: ["order-details", { establishmentId, orderId }],
        });
      },
    }
  );

  const handlePlaceOrder = async () => {
    await markOrderAsPlacedMutation.mutateAsync({
      establishmentId: establishmentId,
      orderId: orderId,
    });
  };

  const handleOpenEmail = (orderText) => {
    const email =
      vendorMap[order.vendorId]?.contacts[0]?.email || "no-email-found";
    const mailto_link =
      "mailto:" +
      email +
      "?subject=" +
      encodeURIComponent("New Order") +
      "&body=" +
      encodeURIComponent(orderText);
    document.location.href = mailto_link;
  }

  if (isLoading || isLoadingVendors) {
    return <SpecSpinner open text={"Getting your order information..."} />;
  }

  const order = data.order;
  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ width: "100%", maxWidth: "2440px", padding: 4 }}
      >
        <Grid item sx={{ marginBottom: 6 }}>
          <SpecBackButton
            backText="Orders"
            onClick={() => navigate(`/spec/${establishmentId}/ordering/`)}
          />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography variant="h2">
                {moment(order.createdDate).format("MMMM DD, YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ marginBottom: 6 }}>
          <Typography
            variant="largeButton"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            {order.vendor?.name}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            flexDirection: "row",
            display: "flex",
            marginBottom: 4,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              fontWeight: 700,
              marginRight: 1,
            }}
          >
            Products:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.pure.black, marginRight: 1 }}
          >
            {data.order?.lineItems?.length || 0}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              marginRight: 1,
            }}
          >
            {"|"}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              fontWeight: 700,
              marginRight: 1,
            }}
          >
            Estimated Total Cost:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.pure.black, marginRight: 1 }}
          >
            {order.estimatedCost !== null
              ? `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
                  order.estimatedCost.amount,
                  locale,
                  currency
                )}`
              : "N/A"}
          </Typography>
        </Grid>
        <Grid item sx={{ marginBottom: 6 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "40px",
              backgroundColor: (theme) => theme.palette.pure.white,
              borderRadius: 1,
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 2,
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 700, marginRight: 1 }}
                >
                  Order Placed:
                </Typography>
                <Typography variant="body1">
                  {moment(data.order?.orderDate).format("MM-DD-YY hh:mm a")}
                </Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  direction="row"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item sx={{ marginRight: 2 }}>
                    {/* <OrderStatusCTA
                      status={order.orderStatus}
                      orderText={order.orderText}
                      placeOrder={handlePlaceOrder}
                    /> */}
                    <OrderStatusChip status={order.orderStatus} />
                  </Grid>
                  <Grid item>
                    <PlaceOrderButton
                      orderText={order.orderText}
                      placeOrder={handlePlaceOrder}
                      iconButton
                      openEmail={handleOpenEmail}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <TableContainer
          sx={{
            overflow: "scroll",
            borderRadius: 2,
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[400],
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead sx={{ height: "24px" }}>
              <TableRow sx={{ height: 24 }}>
                <TableCell
                  sx={[tableHeadStyle, { width: "380px", borderLeft: "none" }]}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Product Name
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Product Name"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              Product Name is the official and commonly used
                              name of a product.
                            </Box>
                            <Box sx={{ marginTop: 1 }}>
                              <Typography variant="body2">
                                Best practices:
                              </Typography>
                            </Box>
                            <ul
                              style={{
                                listStylePosition: "outside",
                                marginTop: 1,
                              }}
                            >
                              <li style={{ marginTop: 1 }}>
                                Names should be specific: <q>Absolut Vodka</q>{" "}
                                and
                                <q>Absolut Citron</q> are distinct and have
                                separate uses, so they should be divided into
                                separate products with unique names.
                              </li>
                              <li>
                                Names can contain the name of the producer, if
                                that is how the product is colloquially referred
                                to. <q>Makers Mark Bourbon</q> contains the name
                                of the Producer, wheras{" "}
                                <q>Elijah Craig Small Batch Bourbon</q>
                                does not include the name of the producer,{" "}
                                <q>Heaven Hill</q>.
                              </li>
                              <li>
                                Names should be free of reference to size, like
                                <q>750 mL</q>, as this is defined at the Product
                                Format level. Names should also be free of
                                reference to packaging type, like <q>bottle</q>,
                                as this is defined at the Product Format level.
                              </li>
                              <li>
                                If the product is a wine, include the vintage.
                              </li>
                            </ul>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Amount
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Amount"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The number of Purchase Units ordered.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Unit Name
                    <Box sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Unit Name"
                        buttonText="Show Illustration"
                        buttonAction={handleToggleProductSetupModal}
                        description={
                          <Box>
                            The name of the quantity of Product Formats
                            purchased. Most establishments will only have two
                            distinct purchase units: <q>unit</q> or{" "}
                            <q>bottle</q> to indicate a purchase amount of 1,
                            and <q>case</q> or <q>pack</q> to indicate a
                            purchase amount of 6 or 12, depending on how the
                            producer ships the product.
                            <br />
                            <br />
                            Purchase unit name is sent to reps when ordering, so
                            make sure the name is clear.
                            <br />
                            <br />
                            Purchase Unit Names must be distinct among Purchase
                            Units from the same vendor.
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Unit Size
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Unit Size"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The unit size of the Product Format that was
                              ordered.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    SKU
                    <Box sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="SKU"
                        description={
                          <Box>
                            The Stock Keeping Unit, or SKU, is the vendor&apos;s
                            internal identifying code for the product. This can
                            be found on most invoices. Purchase Units for the
                            same vendor often have the same SKU.
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Est. Cost
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Estimated Cost"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The Purchase Unit price x amount.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={[tableHeadStyle, { width: "56px", borderRight: "none" }]}
                ></TableCell>
              </TableRow>
            </TableHead>
            {data.order?.lineItems?.map((x) => (
              <OrderReviewRow key={x.id} lineItem={x} />
            ))}
          </Table>
        </TableContainer>
      </Grid>
      <ProductSetupIllustrationModal
        isOpen={productSetupModalOpen}
        toggleModalOpen={toggleProductSetupModalOpen}
      />
    </>
  );
};

export default OrderDetails;
