import React, { useEffect, useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ImageByUrl from "./components/ImageByUrl";
import UploadImage from "./components/UploadImage";
import s3Client from "../../../clients/s3Client";

const ImageControlModal = ({
  isOpen,
  closeModal,
  loading,
  handleUpdate,
  imageSrc,
  isLoading,
}) => {
  // 0 for by url, 1 for upload
  const [imageSetView, toggleImageSetView] = useState(0);
  const [isCropping, toggleIsCropping] = useState(false); // we bring this here so if the upload flow triggers a crop we can hide footer
  const [newLinkURL, setNewLinkURL] = useState(null);
  const [uploadBlobURL, setUploadBlobURL] = useState(null);
  const [uploadFileBlob, setUploadFileBlob] = useState(null);
  const [isUploadingS3, toggleIsUploadingS3] = useState(false);

  useEffect(() => {
    if (
      imageSrc?.startsWith("https://ephemeral-public.s3") ||
      imageSrc?.startsWith("https://spec-hosted.s3")
    ) {
      toggleImageSetView(1);
    }
  }, [isOpen]);

  const handleCloseModal = () => {
    setNewLinkURL(null);
    setUploadBlobURL(null);
    closeModal();
  };

  const handleViewChange = (e, newView) => {
    if (newView === 0) {
      // clear cropping when navigating away from the upload flow
      toggleIsCropping(false);
    }
    toggleImageSetView(newView);
  };

  const handleUrlSave = () => {
    handleUpdate(newLinkURL);
  };

  const handleUploadSave = async () => {
    toggleIsUploadingS3(true);
    const s3Response = await s3Client.uploadImage(uploadFileBlob);
    toggleIsUploadingS3(false);
    handleUpdate(s3Response);
  };

  const handleSetImage = () => {
    if (imageSetView === 0) {
      handleUrlSave();
    } else {
      handleUploadSave();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid
            container
            direction="column"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Typography variant="h4">Manage Image</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleCloseModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box
              sx={{
                marginTop: 4,
                padding: 2,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid",
                borderRadius: 2,
                borderColor: (theme) => theme.palette.terrain[300],
              }}
            >
              <Typography variant="body2">Set Image by:</Typography>
              <ToggleButtonGroup
                size="small"
                color="primary"
                value={imageSetView}
                exclusive
                onChange={handleViewChange}
                aria-label="Platform"
              >
                <ToggleButton value={0} sx={{ width: "80px" }}>
                  <Typography variant="body2">URL</Typography>
                </ToggleButton>
                <ToggleButton value={1} sx={{ width: "80px" }}>
                  <Typography variant="body2" sx={{ textTransform: "none" }}>
                    Upload
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box
              sx={{
                marginTop: 4,
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {imageSetView === 0 ? (
                <ImageByUrl
                  imageSrc={imageSrc}
                  newLinkURL={newLinkURL}
                  setNewLinkURL={setNewLinkURL}
                />
              ) : (
                <UploadImage
                  imageSrc={imageSrc}
                  isCropping={isCropping}
                  toggleIsCropping={toggleIsCropping}
                  uploadBlobURL={uploadBlobURL}
                  setUploadBlobURL={setUploadBlobURL}
                  setUploadFileBlob={setUploadFileBlob}
                />
              )}
            </Box>
            {isCropping ? null : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "50%",
                    height: "40px",
                    marginLeft: 1,
                  }}
                  disabled={
                    (imageSetView === 0 && imageSrc === newLinkURL) ||
                    (imageSetView === 1 && imageSrc === uploadBlobURL) ||
                    isLoading || isUploadingS3
                  }
                  onClick={handleSetImage}
                >
                  {isLoading || isUploadingS3 ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="largeButton">Set Image</Typography>
                  )}
                </Button>
              </Box>
            )}
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default ImageControlModal;
