import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import DescriptionToolTip from "../../../../../components/DescriptionToolTip";

const AdditionalOptions = ({ allowUnspecified, toggleAllowUnspecified, includeSumRow, toggleIncludeSumRow }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox
          checked={allowUnspecified}
          onChange={() => toggleAllowUnspecified(!allowUnspecified)}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Allow Unspecified
        </Typography>
        <DescriptionToolTip
          header="Allow Products with Unspecified Expense Groups"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Whether or not to include a line item for products with
                unspecified expense groups. This means if a product does not
                have an expense group set for a purchase unit, it will get
                grouped under an &apos;Unspecified&apos; line item.
              </Box>
            </Box>
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox checked={includeSumRow} onChange={() => toggleIncludeSumRow(!includeSumRow)} />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Include All Sum Row
        </Typography>
        <DescriptionToolTip
          header="Include All Sum Row"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Include a row at the top of the entire report showing the sum of
                all invoice line items per expense group or subgroup in the date
                group.
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default AdditionalOptions;
