import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
// import DragHandleIcon from "@mui/icons-material/DragHandle";

const SearchResultsList = ({ results, isLoading, addProduct }) => {
  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ width: "100%", marginTop: 4, maxHeight: "400px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          maxHeight: "400px",
          overflow: "scroll",
        }}
      >
        <Box>
          <List>
            {results.map((r) => (
              <ListItem
                key={r.productFormatId}
                sx={{
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  height: "48px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 2,
                  borderBottom: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                }}
                // onClick={() => toggleSelected(!selected)}
              >
                <Grid container>
                  <Grid container direction="row">
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      {/* <DragHandleIcon
                        sx={{
                          fontSize: 16,
                          color: (theme) => theme.palette.primary[800],
                        }}
                      /> */}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {r.displayName}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ dispaly: "flex", justifyContent: "flex-end" }}
                  xs={4}
                >
                  <Grid item>
                    <Button onClick={() => addProduct(r.productFormatId)}>
                      +Add
                    </Button>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchResultsList;
