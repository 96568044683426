import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Grid, MenuItem, Typography } from "@mui/material";
import {
  productSizeTypes,
  unitOfMeasurementTypes,
} from "../../../../clients/inventoryObjects";

const defaultUomOption = { abbreviation: "Select Unit of Measurement", id: -1 };

const UnitSizeRow = ({
  unitSize,
  type,
  updateUnitSize,
  unitsOfMeasurement,
  formatId,
  unitSizeAmountError,
  unitSizeUomError
}) => {
  const [uomOptions, setUomOptions] = useState([defaultUomOption]);
  const [kegUomOptions, setKegUomOptions] = useState([]);

  useEffect(() => {
    if (type === productSizeTypes.Volumetric) {
      const regularUoms = unitsOfMeasurement?.filter(
        (x) => !x.isKegSize && x.type === unitOfMeasurementTypes.Volumetric
      );
      const kegUoms = unitsOfMeasurement?.filter((x) => x.isKegSize);
      setUomOptions([defaultUomOption, ...regularUoms]);
      setKegUomOptions(kegUoms);
    }

    if (type === productSizeTypes.Mass) {
      setUomOptions(
        unitsOfMeasurement?.filter(
          (x) => !x.isKegSize && x.type === unitOfMeasurementTypes.Mass
        )
      );
    }
  }, [formatId]);

  const handleQuantityUpdate = (e) => {
    updateUnitSize(
      Object.assign({}, unitSize, {
        size: e.target.value,
      })
    );
  };

  const handleUoMChange = (e) => {
    let newData = {};
    if (e.target.value.isKegSize) {
      newData = Object.assign({}, unitSize, {
        unitOfMeasurement: e.target.value,
        size: 0,
      });
    } else {
      newData = Object.assign({}, unitSize, {
        unitOfMeasurement: e.target.value,
      });
    }

    updateUnitSize(newData);
  };

  const FormInputLabelStyles = {
    shrink: true,
  };

  return (
    <>
      <Grid item xs={6}>
        <TextField
          required
          size="small"
          fullWidth
          id="size"
          label="Quantity"
          placeholder="Quantity"
          type="number"
          InputLabelProps={FormInputLabelStyles}
          disabled={Boolean(unitSize?.unitOfMeasurement?.isKegSize)}
          onChange={handleQuantityUpdate}
          error={unitSizeAmountError}
          value={unitSize?.size}
        />
        {unitSizeAmountError && <Typography sx={{ color: theme => theme.palette.error[600]} }>Required</Typography>}
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          label="Unit of Measurement"
          onChange={handleUoMChange}
          value={unitSize?.unitOfMeasurement}
          sx={{ width: "100%" }}
          size="small"
          error={unitSizeUomError}
        >
          {uomOptions.map((x) => (
            <MenuItem key={x.abbreviation} value={x}>
              {x.abbreviation}
            </MenuItem>
          ))}
          {type === productSizeTypes.Volumetric &&
            kegUomOptions.map((x) => (
              <MenuItem key={x.kegAbbreviation} value={x}>
                {x.kegAbbreviation}
              </MenuItem>
            ))}
        </TextField>
        {unitSizeUomError && <Typography sx={{ color: theme => theme.palette.error[600]} }>Required</Typography>}
      </Grid>
    </>
  );
};

export default UnitSizeRow;
