import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import {
  getEstablishment,
  getEstablishmentCategories,
  updateBeer,
  updateRecipe,
  updateWine,
} from "../../clients/establishmentsClient";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Store from "../../Store/Store";
import { EditOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import SpecBackButton from "../../components/SpecBackButton";
import { useNavigate, useParams } from "react-router-dom";
import RecipeDetailDisplay from "../../components/DrinkDetailDisplays/RecipeDetailDisplay";
import RecipeDrinkForm from "../../components/DrinkForm/RecipeDrinkForm";
import WineDetailDisplay from "../../components/DrinkDetailDisplays/WineDetailDisplay";
import WineDrinkForm from "../../components/DrinkForm/WineDrinkForm";
import BeerDrinkForm from "../../components/DrinkForm/BeerDrinkForm";
import BeerDetailDisplay from "../../components/DrinkDetailDisplays/BeerDetailDisplay";
import { Alert, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import CopyItemModal from "../../components/Modals/CopyItemModal";
import MoveItemModal from "../../components/Modals/MoveItemModal";
import DeleteItemModal from "../../components/Modals/DeleteItemModal";
import utilFunctions from "../../utilities/utilFunctions";

const MenuItemDetails = () => {
  const store = useContext(Store);
  const [itemData, setItemData] = useState(undefined);
  const [editing, toggleEditing] = useState(false);
  const [loading, toggleLoading] = useState(true);
  const [isError, toggleError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [moveItemModalOpen, toggleMoveItemModalOpen] = useState(false);
  const [copyItemModalOpen, toggleCopyItemModalOpen] = useState(false);
  const [deleteItemModalOpen, toggleDeleteItemModalOpen] = useState(false);
  const [itemToMove, setItemToMove] = useState(undefined);
  const [itemToCopy, setItemToCopy] = useState(undefined);
  const [itemToDelete, setItemToDelete] = useState(undefined);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageContent, setErrorMessageContent] = useState("");
  const [successMessageContent, setSuccessMessageContent] = useState("");
  let { menuid, establishmentid, itemid } = useParams();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAlertClose = () => {
    setAlertSuccess(false);
    setErrorMessage(false);
  };

  const fetchItemData = async () => {
    try {
      toggleLoading(true);
      const categories = await getEstablishmentCategories(establishmentid);
      const currentEstablishment = await getEstablishment(establishmentid);
      store.updateCurrentEstablishment(currentEstablishment);
      const menu = categories.find((c) => c.id === menuid);
      const itemToDisplay = menu.items?.find((x) => x.id === itemid);
      if (!itemToDisplay) {
        navigate(`/homebase/${establishmentid}/menus/${menuid}`);
      }
      setItemData(itemToDisplay);

      toggleLoading(false);
    } catch (err) {
      console.log(err);
      toggleError(true);
      toggleLoading(false);
    }
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  const copyCallback = () => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! Your item has been copied.");
    toggleCopyItemModalOpen(false);
    handleClose();
  };

  const moveCallback = async () => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! Your item has been moved.");
    toggleMoveItemModalOpen(false);
    handleClose();
  };

  const deleteCallback = () => {
    navigate(`/homebase/${establishmentid}/menus/${menuid}`, {
      state: { displayDeleteSuccess: true },
    });
  };

  const errorCallback = (errorMessage) => {
    setErrorMessage(true);
    setErrorMessageContent(errorMessage);
  };

  const handleMoveModal = () => {
    setItemToMove(itemData);
    toggleMoveItemModalOpen(!moveItemModalOpen);
  };

  const handleCopyModal = () => {
    setItemToCopy(itemData);
    toggleCopyItemModalOpen(!copyItemModalOpen);
  };

  const handleDeleteModal = () => {
    setItemToDelete(itemData);
    toggleDeleteItemModalOpen(!deleteItemModalOpen);
  };

  const saveRecipeUpdates = async (recipe) => {
    toggleLoading(true);
    await updateRecipe(recipe);
    await fetchItemData();
    toggleEditing(false);
  };

  const saveWineUpdates = async (wine) => {
    toggleLoading(true);
    await updateWine(wine);
    await fetchItemData();
    toggleEditing(false);
  };

  const saveBeerUpdates = async (beer) => {
    toggleLoading(true);
    await updateBeer(beer);
    await fetchItemData();
    toggleEditing(false);
  };

  const renderItemDisplayByType = () => {
    switch (itemData.type) {
      case "recipe":
        return <RecipeDetailDisplay recipe={itemData} />;
      case "wine":
        return <WineDetailDisplay wine={itemData} />;
      case "beer":
        return <BeerDetailDisplay beer={itemData} />;
      default:
        return null;
    }
  };

  const renderDrinkFormByType = () => {
    switch (itemData.type) {
      case "recipe":
        return (
          <Box
            sx={{
              // backgroundColor: (theme) => theme.palette.pure.white,
              // borderRadius: 4,
              padding: 2,
              // border: (theme) => `2px solid ${theme.palette.terrain["03"]}`,
              maxWidth: { xs: "100px", sm: "200px", md: "1400px" },
              border: "1px solid blue",
            }}
          >
            <RecipeDrinkForm
              goBack={() => toggleEditing(false)}
              isEmbedded
              categoryId={menuid}
              startingRecipe={itemData}
              submitCallback={saveRecipeUpdates}
            />
          </Box>
        );
      case "wine":
        return (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.pure.white,
              borderRadius: 4,
              padding: 2,
              border: (theme) => `2px solid ${theme.palette.terrain["03"]}`,
            }}
          >
            <WineDrinkForm
              goBack={() => toggleEditing(false)}
              isEmbedded
              categoryId={menuid}
              startingWine={itemData}
              submitCallback={saveWineUpdates}
            />
          </Box>
        );
      case "beer":
        return (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.pure.white,
              borderRadius: 4,
              padding: 2,
              border: (theme) => `2px solid ${theme.palette.terrain["03"]}`,
            }}
          >
            <BeerDrinkForm
              goBack={() => toggleEditing(false)}
              isEmbedded
              categoryId={menuid}
              startingBeer={itemData}
              submitCallback={saveBeerUpdates}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Spinner
        size="lg"
        color="primary"
        style={{
          width: "5rem",
          height: "5rem",
          position: "absolute",
          left: "50%",
          top: "50%",
        }}
      />
    );
  }

  if (isError) {
    return (
      <Typography variant="h2" sx={{ color: "red" }}>
        There was an error fetching your drink. Please try refreshing.
      </Typography>
    );
  }

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ maxWidth: "2440px", padding: 2 }}
      >
        <Box>
          <SpecBackButton
            onClick={() =>
              navigate(`/homebase/${establishmentid}/menus/${menuid}`)
            }
          />
        </Box>
        <Grid
          container
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "2440px",
          }}
        >
          <Grid item sx={{ marginBottom: 8, marginTop: 6 }} xs={10}>
            <Typography variant="h2">Drink Details</Typography>
          </Grid>
          {!editing && (
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                onClick={handleClick}
                sx={{
                  height: 40,
                  width: 40,
                  border: "1px solid",
                  color: (theme) => theme.palette.primary.main,
                  borderRadius: 2,
                }}
              >
                <MoreHorizIcon
                  sx={{ color: (theme) => theme.palette.pure.black }}
                />
              </IconButton>
              <IconButton
                variant="outlined"
                sx={{
                  marginLeft: 3,
                  height: 40,
                  width: 72,
                  color: (theme) => theme.palette.primary.main,
                  border: "1px solid",
                }}
                onClick={() => toggleEditing(true)}
              >
                <EditOutlined
                  sx={{
                    fontSize: "18px",
                    marginRight: 1,
                    color: (theme) => theme.palette.pure.black,
                  }}
                />
                <Typography
                  variant="smallButton"
                  sx={{ color: (theme) => theme.palette.pure.black }}
                >
                  Edit
                </Typography>
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h4">
            Drink Type: {utilFunctions.capitalizeString(itemData.type)}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "2440px", marginLeft: 4, marginTop: 6 }}>
          {editing ? renderDrinkFormByType() : renderItemDisplayByType()}
        </Box>
      </Grid>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleMoveModal} sx={{ margin: "5px" }}>
          <DriveFileMoveOutlinedIcon sx={{ marginRight: "5px" }} />
          Move To
        </MenuItem>
        <MenuItem onClick={handleCopyModal} sx={{ margin: "5px" }}>
          <ContentCopyIcon sx={{ marginRight: "5px" }} />
          Copy
        </MenuItem>
        <MenuItem onClick={handleDeleteModal} sx={{ margin: "5px" }}>
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <CopyItemModal
        isOpen={copyItemModalOpen}
        toggleModalOpen={handleCopyModal}
        item={itemToCopy}
        copyCallback={copyCallback}
        errorCallback={errorCallback}
      />
      <MoveItemModal
        isOpen={moveItemModalOpen}
        toggleModalOpen={handleMoveModal}
        item={itemToMove}
        moveCallback={moveCallback}
        errorCallback={errorCallback}
      />
      <DeleteItemModal
        isOpen={deleteItemModalOpen}
        toggleModalOpen={handleDeleteModal}
        item={itemToDelete}
        errorCallback={errorCallback}
        deleteCallback={deleteCallback}
      />
      <Box>
        <Snackbar
          open={alertSuccess}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {successMessageContent}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessageContent}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default MenuItemDetails;
