import React, { useState } from "react";
import { CircularProgress, Box, Typography } from "@mui/material";
import inventoryClient from "../../../clients/inventoryClient";
import CreateTagForm from "./components/CreateTagForm";
import { useMutation } from "react-query";

const CreateTagModal = ({
  establishmentId,
  successCallback,
  handleCreateTagClick
}) => {
  const [isError, toggleError] = useState(false);

  const createTagMutation = useMutation(inventoryClient.createTag,
    {
      onSuccess: () => {
        successCallback();
        handleCreateTagClick();
      },
      onError: () => {
        toggleError(true);
      }
    })

  const handleCreateSection = async (name) => {
    createTagMutation.mutate({ establishmentId, tag: name});
  };

  return (
      <Box>
        {createTagMutation.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <CreateTagForm
            submitFormCallback={handleCreateSection}
          />
        )}
        {isError && <Typography>There was a problem creating your tag</Typography>}
      </Box>
  );
};

export default CreateTagModal;
