import React, { useState, useContext } from "react";
import {
  Box,
 // Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { modalBoxStyle } from "../styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddFromLibrary from "./components/AddFromLibrary";
import AddProductOptions from "./components/AddProductOptions";
import Store from "../../../Store/Store";
//import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import useProductLibrary from "../../../hooks/useProductLibrary";

const ProductListModal = ({ isOpen, toggleModalOpen, area, addProducts }) => {
  const [addingFromLibrary, toggleAddingFromLibrary] = useState(true);
  const [addingNew, toggleAddingNew] = useState(false);
  const store = useContext(Store);

  // eslint-disable-next-line no-unused-vars
  const [{ isLoading, isError, data}, _] = useProductLibrary(store.currentEstablishment?.id);

  const handleClose = () => {
    toggleModalOpen(false);
    toggleAddingNew(false);
    toggleAddingFromLibrary(true);
  };

  const renderHeader = () => {
    if (addingNew) {
      return "Create New Product";
    } else if (addingFromLibrary) {
      return "Add from Library";
    }
    return "Add Products";
  };

  const renderModalBody = () => {
    if (addingNew) {
      return <Box>Adding a new product</Box>;
    } else if (addingFromLibrary) {
      return (
        <AddFromLibrary
          area={area}
          loadingInventory={isLoading}
          inventoryError={isError}
          inventory={data?.products}
          addProducts={addProducts}
        />
      );
    }
    return (
      <AddProductOptions
        toggleAddingFromLibrary={toggleAddingFromLibrary}
        toggleAddingNew={toggleAddingNew}
      />
    );
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={[modalBoxStyle, { height: "600px" }]}>
        <Grid
          container
          sx={{
            marginBottom: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="h3" sx={{ fontSize: { xs: 18, sm: 24 } }}>
              {renderHeader()}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {/* <Button variant="text" onClick={() => toggleAddingNew(true)}>
              <AddOutlinedIcon
                sx={{
                  fontSize: { xs: 14, sm: 20 },
                  color: (theme) => theme.palette.primary[800],
                }}
              />
              <Typography
                varaint="largeButton"
                sx={{
                  color: (theme) => theme.palette.primary[800],
                  textTransform: "none",
                  fontSize: { xs: 12, sm: 16 },
                  textDecoration: "underline",
                }}
              >
                Create New
              </Typography>
            </Button> */}
            <IconButton onClick={handleClose} sx={{ marginRight: -4 }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box>{renderModalBody()}</Box>
      </Box>
    </Modal>
  );
};

export default ProductListModal;
