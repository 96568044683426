import React, { useEffect, useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VolumeSizeCard from "./components/VolumeSizeCard";
import MassSizeCard from "./components/MassSizeCard";
import ArbitrarySizeCard from "./components/ArbitrarySizeCard";
import {
  productSizeTypes,
  unitOfMeasurementTypes,
} from "../../../clients/inventoryObjects";
import { cloneDeep } from "lodash";

const YieldModal = ({
  isOpen,
  toggleModalOpen,
  recipeYields,
  updateRecipe,
  unitsOfMeasurement,
  isLoading,
}) => {
  const [yieldsCopy, setYieldsCopy] = useState([]);

  useEffect(() => {
    const copied = recipeYields.map((x) => cloneDeep(x));
    setYieldsCopy(copied);
  }, [recipeYields]);

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const handleUpdateSize = (type, newSize) => {
    const updated = yieldsCopy.map((x) => {
      if (x.type === type) {
        const updatedObj = cloneDeep(x);
        updatedObj.size = newSize;
        return updatedObj;
      } else {
        return x;
      }
    });

    setYieldsCopy(updated);
  };

  const handleUpdateUoM = (type, uom) => {
    const updated = yieldsCopy.map((x) => {
      if (x.type === type) {
        const updatedObj = cloneDeep(x);
        updatedObj.unitOfMeasurement = uom;
        return updatedObj;
      } else {
        return x;
      }
    });

    setYieldsCopy(updated);
  };

  const handleSetDefault = (type) => {
    const updated = yieldsCopy.map((x) => {
      if (x.type === type) {
        const updatedObj = cloneDeep(x);
        updatedObj.isDefaultSize = true;
        return updatedObj;
      } else {
        const updatedObj = cloneDeep(x);
        updatedObj.isDefaultSize = false;
        return updatedObj;
      }
    });

    setYieldsCopy(updated);
  };

  const handleSave = async () => {
    await updateRecipe("yield", yieldsCopy);
    closeModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography variant="h4" sx={{ marginBottom: 4 }}>
                  Edit Yield
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box>
              <Box sx={{ marginBottom: 8 }}>
                <Divider />
              </Box>

              <Box
                sx={{
                  maxWidth: "100%",
                  width: "100%",
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 2,
                  minHeight: "68px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: 2,
                  }}
                >
                  <Typography variant="h4">Volume</Typography>
                </Box>
                <VolumeSizeCard
                  size={yieldsCopy?.find(
                    (x) => x.type === productSizeTypes.Volumetric
                  )}
                  unitsOfMeasurement={unitsOfMeasurement?.filter(
                    (x) => x.type === unitOfMeasurementTypes.Volumetric && !x.isKegSize
                  )}
                  setDefault={handleSetDefault}
                  updateSize={handleUpdateSize}
                  updateUoM={handleUpdateUoM}
                />
              </Box>
              <Box
                sx={{
                  maxWidth: "100%",
                  width: "100%",
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 2,
                  minHeight: "68px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: 2,
                  }}
                >
                  <Typography variant="h4">Mass</Typography>
                </Box>

                <MassSizeCard
                  size={yieldsCopy?.find(
                    (x) => x.type === productSizeTypes.Mass
                  )}
                  unitsOfMeasurement={unitsOfMeasurement?.filter(
                    (x) => x.type === unitOfMeasurementTypes.Mass
                  )}
                  setDefault={handleSetDefault}
                  updateSize={handleUpdateSize}
                  updateUoM={handleUpdateUoM}
                />
              </Box>
              <Box
                sx={{
                  maxWidth: "100%",
                  width: "100%",
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 2,
                  minHeight: "68px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: 2,
                  }}
                >
                  <Typography variant="h4">Count</Typography>
                </Box>
                <ArbitrarySizeCard
                  size={yieldsCopy?.find(
                    (x) => x.type === productSizeTypes.Arbitrary
                  )}
                  setDefault={handleSetDefault}
                  updateSize={handleUpdateSize}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={handleSave}
                  sx={{
                    marginLeft: "1rem",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="smallButton">Update Yield</Typography>
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default YieldModal;
