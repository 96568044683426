import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";

const ArbitrarySizeCard = ({ unitSize, updateUnitSize, changeDefaultSize }) => {
  const { register, watch } = useForm({ mode: "all", defaultValues: unitSize });

  const handleMassQuantityUpdate = () => {
    const newData = Object.assign({}, unitSize, { size: watch("size") });
    updateUnitSize(newData);
  };

  const handleChangeDefault = () => {
    if (!unitSize.isDefaultSize) {
      changeDefaultSize(unitSize.type);
    }
  };

  const FormInputLabelStyles = {
    shrink: true,
  };

  return (
    <Grid container direction="row" sx={{ padding: 2 }}>
      <Grid item xs={2}>
        {Boolean(unitSize?.isDefaultSize) && (
          <Typography sx={{ paddingLeft: "3px" }}>Primary</Typography>
        )}{" "}
        <Radio
          checked={unitSize?.isDefaultSize}
          onChange={handleChangeDefault}
        />
      </Grid>
      <Grid item xs={10} sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
        <form style={{ width: "100%" }}>
          <Grid
            container
            spacing={3}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingLeft: 2,
            }}
          >
            <Grid item sx={{ width: "45%" }}>
              <TextField
                required
                size="small"
                fullWidth
                id="size"
                label="Quantity"
                placeholder="Quantity"
                autoComplete="off"
                InputLabelProps={FormInputLabelStyles}
                disabled={Boolean(unitSize?.unitOfMeasurement?.isKegSize)}
                {...register("size", {
                  required: true,
                  onChange: handleMassQuantityUpdate,
                })}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ArbitrarySizeCard;
