import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { MoreHorizOutlined } from "@mui/icons-material";
import DeleteMenuModal from "../../../../../components/Modals/DeleteMenuModal";

const menuCardStyle = {
  borderRadius: 4,
  padding: 2,
  width: "100%",
  height: "60px",
  maxHeight: "60px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: "3px solid",
  borderColor: (theme) => theme.palette.terrain[300],
  backgroundColor: (theme) => theme.palette.pure.white,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: (theme) => theme.palette.terrain[300],
  },
};

const MenuOption = ({ menu, handleClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [deleteDistributorModalOpen, toggleDeleteDistributorModalOpen] =
    useState(false);
  const [callback, setCallback] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteModal = () => {
    toggleDeleteDistributorModalOpen(!deleteDistributorModalOpen);
  };

  const deleteCallback = () => {
    setCallback(!callback);
  };

  const handleDeleteMenuClick = () => {
    handleClose();
    toggleDeleteDistributorModalOpen(true);
  };

  // const menuUpdateMutation = useMutation(inventoryClient.patchMenu, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({
  //       queryKey: [
  //         "menus",
  //         {
  //           establishmentId: establishmentid,
  //         },
  //       ],
  //     });
  //     queryClient.refetchQueries({
  //       queryKey: [
  //         "menus",
  //         {
  //           establishmentId: establishmentid,
  //         },
  //       ],
  //     });
  //   },
  // });

  // const handleUpdateMenuFavorite = () => {
  //   menuUpdateMutation.mutate({
  //     establishmentId: establishmentid,
  //     menuId: menu.id,
  //     isFavoriteUpdate: { value: !menu.isFavorite },
  //   });
  // };

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", bottom: "25%", left: 8 }}></Box>
      <Box>
        <IconButton
          sx={{
            position: "absolute",
            bottom: "25%",
            right: 8,
            border: "none",
            height: "32px",
            width: "32px",
            background: "none",
            "&:hover": {
              background: "transparent",
              color: (theme) => theme.palette.primary["main"],
              border: "none",
            },
          }}
          onClick={handleOptionClick}
        >
          <MoreHorizOutlined
            sx={{
              color: (theme) => theme.palette.primary[800],
              fontSize: 18,
            }}
          />
        </IconButton>
      </Box>
      <Box sx={menuCardStyle} onClick={handleClick}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            component="div"
            onClick={handleClick}
            sx={{
              width: "85%",
              marginLeft: 2,
            }}
          >
            <Typography variant="largeButton" noWrap>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxHeight: "40px",
                  textTransform: "none",
                }}
              >
                {menu.name}
              </div>
            </Typography>
            {menu?.isActive === false && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50px",
                  height: "16px",
                  backgroundColor: (theme) => theme.palette.terrain[100],
                  borderRadius: 0.5,
                  padding: 1,
                }}
              >
                <Typography
                  variant="overline"
                  sx={{
                    fontWeight: 700,
                    fontSize: 10,
                    color: (theme) => theme.palette.pure.black,
                  }}
                >
                  Inactive
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem sx={{ margin: "5px" }} onClick={handleDeleteMenuClick}>
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <DeleteMenuModal
        deleteCallback={deleteCallback}
        isOpen={deleteDistributorModalOpen}
        toggleModalOpen={handleDeleteModal}
        menuId={menu.id}
      />
    </Box>
  );
};

export default MenuOption;
