import { useQuery } from "react-query";
import { getEstablishmentSubscription } from "../clients/establishmentsClient";

function useEstablishmentSubscription(establishmentId) {
    const queryReturnValues = useQuery(
        ["subscription", {establishmentId}],
        getEstablishmentSubscription,
        {
          refetchOnWindowFocus: false,
          staleTime: 120 * 60 * 1000,
          cacheTime: 120 * 60 * 1000,
        }
    );

   return queryReturnValues;
}

export default useEstablishmentSubscription;