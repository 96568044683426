import React from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  Link,
  Alert,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const CloseAccountModal = ({
  isOpen,
  toggleModalOpen,
  loading,
  isLoadingCheck,
  accountDeletableDetails,
  deleteUserAccount,
}) => {
  const closeModal = () => {
    toggleModalOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading || isLoadingCheck ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid
            container
            direction="column"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Typography variant="h2">Close Account</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                marginTop: 4,
              }}
            >
              <Typography variant="h3">
                Are you sure you want to close your account?
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                width: "100%",
                marginTop: 4,
              }}
            >
              <Typography variant="body1">
                We are sorry to see you go! If there is anything we can do to
                help, please don{`'`}t hesitate to{" "}
                <Link
                  href="https://www.specapp.com/contact-us"
                  target="_blank"
                  rel="noopener"
                >
                  contact us
                </Link>{" "}
                .
              </Typography>
              <br />
              <Typography variant="body1">
                Once you close your account we will remove all of your personal data, and you will lose access to your establishment content and anything in the
                Spec Mobile App.
              </Typography>
              <br />
              <Typography variant="body1">
                Once your account is closed, all of your details will be removed
                from our database.
              </Typography>
              <Alert sx={{marginTop: 4}} severity="warning">Any establishments you are the owner of will result in all current members losing access to that establishment as well. You can transfer ownership to keep them available for other users.</Alert>
              {!accountDeletableDetails?.deletable ? (
                <Alert sx={{marginTop: 4 }} severity="error">You currently are the owner of an establishment with an active subscription. Please either transfer ownership or cancel your current subscriptions before closing your account to avoid accidental continued charges.</Alert>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "300px",
                  height: "40px",
                  borderRadius: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 6,
                  paddingRight: 6,
                }}
                onClick={deleteUserAccount}
                disabled={!accountDeletableDetails.deletable}
              >
                <Typography variant="largeButton">Yes, Close Account</Typography>
              </Button>
            </Box>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default CloseAccountModal;
