import React from "react";
import { TextField, Grid, Box } from "@mui/material";

const FormInputLabelStyles = {
  shrink: true,
};

const TextFieldStyles = {
  marginBottom: "32px",
  width: "100%",
};

const AddressDetails = ({ register }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        spacing={{ xs: 1, md: 3 }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={6}>
          <TextField
            size="small"
            required
            label="Address"
            id="address"
            name="address"
            placeholder="Enter Address"
            InputLabelProps={FormInputLabelStyles}
            sx={TextFieldStyles}
            {...register("address", { required: true })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            required
            label="City"
            id="city"
            name="city"
            placeholder="Enter City"
            sx={TextFieldStyles}
            InputLabelProps={FormInputLabelStyles}
            {...register("city", { required: true })}
          />
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ display: "flex", justifyContent: "space-between" }}
        spacing={{ xs: 1, md: 3 }}
      >
        <Grid item xs={6}>
          <TextField
            size="small"
            required
            label="State"
            id="state"
            name="state"
            placeholder="Enter State"
            sx={TextFieldStyles}
            InputLabelProps={FormInputLabelStyles}
            {...register("state", { required: true })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            required
            label="Zip Code"
            id="zip"
            name="zipCode"
            placeholder="Enter Zip Code"
            sx={TextFieldStyles}
            InputLabelProps={FormInputLabelStyles}
            {...register("zipCode", { required: true })}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressDetails;
