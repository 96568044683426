import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useProductLibraryPaginatedSearch(establishmentId, searchString, page, filters, sortAscending, propertyForSort) {
    const queryReturnValues = useQuery(
      [
        "paginated-product-search",
        {
          establishmentId,
          page,
          searchString,
          filters,
          sortAscending, 
          propertyForSort,
        },
      ],
      inventoryClient.searchProductInventoryPaginated,
      {
        enabled: !!searchString,
        refetchOnWindowFocus: false,
      }
    );

    return queryReturnValues;
}

export default useProductLibraryPaginatedSearch;