export const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "360px", md: "600px" },
  maxWidth: { xs: "360px !important", md: "600px !important" },
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 8,
  borderRadius: 4,
};
