import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { modalBoxStyle } from "../styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TabularItemSelection from "./components/TabularItemSelection";
import OrderSelectionList from "./components/OrderSelectionList";
import SpecBackButton from "../../SpecBackButton";
import CTABlock from "../../CTABlock";

const LinkOrderModal = ({
  isOpen,
  toggleModalOpen,
  isLoadingOrders,
  orders,
  nextOrderPageToLoad,
  totalOrderPages,
  errorLoadingOrders,
  loadMoreCallback,
  completeLink,
  currentLinkedOrders,
}) => {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isStepTwo, toggleIsStepTwo] = useState(false);
  const [itemsToExcludeMap, setItemsToExcludeMap] = useState({});

  const handleClose = () => {
    setItemsToExcludeMap({});
    toggleIsStepTwo(false);
    toggleModalOpen(false);
    setSelectedOrders([]);
  };

  const handleNextStep = async () => {
    if (isStepTwo) {
      const payload = { ordersToLink: [] };

      selectedOrders.forEach((x) => {
        payload.ordersToLink.push({
          orderId: x.id,
          shouldCreateLineItems: true,
          lineItemsToExcludeIds: itemsToExcludeMap[x.id],
        });
      });
      await completeLink(payload);
      handleClose();
    } else {
      toggleIsStepTwo(true);
    }
  };

  const handleSelectOrder = (selectedOrder) => {
    setItemsToExcludeMap({});
    if (selectedOrders.find((x) => x.id === selectedOrder.id) !== undefined) {
      setSelectedOrders(
        selectedOrders.filter((x) => x.id !== selectedOrder.id)
      );
    } else {
      setSelectedOrders([...selectedOrders, selectedOrder]);
    }
  };

  const handleUpdateExcluded = (currentlyExcluded, item, order) => {
    if (currentlyExcluded) {
      const newMap = Object.assign({}, itemsToExcludeMap);
      newMap[order.id] = newMap[order.id].filter((x) => x !== item.id);
      setItemsToExcludeMap(newMap);
    } else {
      const newMap = Object.assign({}, itemsToExcludeMap);
      newMap[order.id] = [...newMap[order.id], item.id];
      setItemsToExcludeMap(newMap);
    }
  };

  const generateExclusionMap = () => {
    const newMap = {};

    selectedOrders.forEach((x) => {
      newMap[x.id] = [];
    });
    setItemsToExcludeMap(newMap);
  };

  const handleGoBackFromStepTwo = () => {
    toggleIsStepTwo(false);
  };

  if (currentLinkedOrders?.length >= 3) {
    return (
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={[modalBoxStyle]}>
          <Grid
            container
            sx={{
              marginBottom: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h4"></Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton onClick={handleClose} sx={{ marginRight: -4 }}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <CTABlock
            hideButton
            header={"Max Order Limit Reached"}
            subheader="You can only link 3 orders to an invoice"
          />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={[
          modalBoxStyle,
          { maxHeight: "600px", width: "400px !important", paddingTop: 3 },
        ]}
      >
        <Grid
          container
          sx={{
            marginBottom: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={6}>
            {isStepTwo && <SpecBackButton onClick={handleGoBackFromStepTwo} />}
            <Typography variant="h4">
              {isStepTwo ? "Link Order Items" : "Link Orders"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton onClick={handleClose} sx={{ marginRight: -4 }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>

        {isStepTwo ? (
          <TabularItemSelection
            selectedOrders={selectedOrders}
            handleUpdateExcluded={handleUpdateExcluded}
            generateExclusionMap={generateExclusionMap}
            itemsToExcludeMap={itemsToExcludeMap}
          />
        ) : (
          <OrderSelectionList
            orders={orders}
            loadMoreCallback={loadMoreCallback}
            errorLoadingOrders={errorLoadingOrders}
            totalOrderPages={totalOrderPages}
            nextOrderPageToLoad={nextOrderPageToLoad}
            isLoadingOrders={isLoadingOrders}
            selectOrderCallback={handleSelectOrder}
            selectedOrders={selectedOrders}
            currentLinkedOrders={currentLinkedOrders}
          />
        )}
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={handleNextStep}
            disabled={!isStepTwo && selectedOrders.length === 0}
          >
            <Typography variant="largeButton">
              {isStepTwo ? "Link Items" : "Next Step"}
            </Typography>{" "}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LinkOrderModal;
