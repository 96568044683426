import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SpecSpinner from "../../../SpecSpinner";
import ProductOptionsList from "./ProductOptionsList";
import utilFunctions from "../../../../utilities/utilFunctions";

const AddFromLibrary = ({ area, loadingInventory, inventory, addProducts }) => {
  const [searching, toggleSearching] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selected, toggleSelected] = useState([]);
  const [selectedOptions, setOptionsData] = useState([]);

  useEffect(() => {
    const existingMap = {};

    area.productFormats?.forEach((x) => {
      existingMap[x.id] = x;
    });

    const optionsData = [];
    inventory?.forEach((x) => {
      x.productFormats?.forEach((f) => {
        if (existingMap[f.id] === undefined) {
          let sizeInfo = utilFunctions.extractFormatSizingName(f);
          optionsData.push({ name: x.name, unitSize: sizeInfo, id: f.id });
        }
      });
    });

    setOptions(optionsData);
  }, [inventory]);

  const handleSearch = (searchEvent) => {
    const searchText = searchEvent.target.value;
    if (!searchText) {
      toggleSearching(false);
      setFilteredOptions([]);
    } else {
      const searched = options.filter((x) =>
        x.name.toUpperCase().includes(searchText.toUpperCase())
      );
      setFilteredOptions(searched);
      toggleSearching(true);
    }
  };

  if (loadingInventory) {
    return <SpecSpinner open text="Loading Product Library Data..." />;
  }

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setOptionsData([...selectedOptions, e.target.id]);
    } else {
      setOptionsData(selectedOptions.filter((x) => x !== e.target.id));
    }
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.terrain[50],
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
          borderRadius: 2,
          height: "410px",
        }}
      >
        <Box>
          <TextField
            size="small"
            fullWidth
            id="productSearch"
            autoComplete="off"
            onChange={handleSearch}
            inputProps={{
              sx: {
                marginBottom: 1,
              },
            }}
            sx={{
              backgroundColor: (theme) => theme.palette.pure.white,
              marginBottom: 2,
              margin: 2,
              width: "96%",
              borderRadius: 1,
            }}
            label={
              <>
                <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                Search for products...
              </>
            }
          />
        </Box>

        <Box sx={{ marginLeft: 2 }}>
          <Typography variant="body1" sx={{ marginTop: 4 }}>
            Select one or more products to add to this area:
          </Typography>
        </Box>
        <Box sx={{ height: "300px", overflow: "scroll" }}>
          <ProductOptionsList
            options={searching ? filteredOptions : options}
            searching={searching}
            toggleSearching={toggleSearching}
            selected={selected}
            toggleSelected={toggleSelected}
            handleOptionCheckUncheck={handleCheckboxChange}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <Button
          variant="contained"
          onClick={() => addProducts(selectedOptions)}
        >
          Add Selected Items
        </Button>
      </Box>
    </Box>
  );
};

export default AddFromLibrary;
