import React from "react";
import { Box, Grid, List, Divider, Button, Typography } from "@mui/material";
import FamilyNestedListItem from "./FamilyNestedListItem";

const FamilyFilters = ({
  families,
  filters,
  toggleFamilyTarget,
  toggleCategoryTarget,
  toggleSubcategoryTarget,
  clearFilters,
}) => {
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 3,
        }}
      ></Grid>
      <Box
        sx={{
          marginTop: 4,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            padding: 2,
            backgroundColor: (theme) => theme.palette.terrain[100],
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
          }}
        >
          <Typography variant="body2">Classification</Typography>
          <Button
            variant="outlined"
            sx={{ marginLeft: "auto" }}
            onClick={clearFilters}
          >
            <Typography variant="body1">Clear</Typography>{" "}
          </Button>
        </Box>
        <List
          disablePadding
          sx={{
            overflow: "scroll",
            maxHeight: { xs: "200px", md: "300px" },
            // borderLeft: "1px solid",
            // borderRight: "1px solid",
            borderTop: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
          }}
        >
          {families.map((x) => (
            <FamilyNestedListItem
              key={x.id}
              family={x}
              filters={filters}
              toggleCategoryTarget={toggleCategoryTarget}
              toggleFamilyTarget={toggleFamilyTarget}
              toggleSubcategoryTarget={toggleSubcategoryTarget}
            />
          ))}
        </List>
      </Box>
      <Divider sx={{ marginLeft: -8, marginRight: -8 }} />
    </>
  );
};

export default FamilyFilters;
