import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useEstablishmentAreas(establishmentId) {
  const queryReturnValues = useQuery(
    ["areas", { establishmentId }],
    inventoryClient.getAreasForEstablishment,
    {
      staleTime: 1000 * 60 * 60,
    }
  );

  return queryReturnValues;
}

export default useEstablishmentAreas;
