import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

const ChooseStockCounts = ({
  pastStockCounts,
  setCountForReport,
  countForReport,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) => {
  const [options, setOptions] = useState([]);
  const listboxRef = useRef();

  useEffect(() => {
    setOptions(
      pastStockCounts ? pastStockCounts?.flatMap((page) => page.pastCounts) : []
    );
  }, [pastStockCounts]);

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight ===
      event.target.scrollTop + event.target.clientHeight;
    if (bottom && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="h5">Choose Stock Counts</Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "72px",
            width: "35%",
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.terrain[200],
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            padding: 2,
            marginTop: 2,
          }}
        >
          <Select
            value={countForReport || ""}
            size="small"
            onChange={(e) => setCountForReport(e.target.value)}
            displayEmpty
            fullWidth
            sx={{
              height: "40px",
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            MenuProps={{
              PaperProps: {
                onScroll: handleScroll,
                ref: listboxRef,
                style: {
                  maxHeight: 150, // Limit the height of the dropdown
                  overflowY: "auto", // Enable scrolling
                },
              },
            }}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option.name || "(Missing Count Name)"}
              </MenuItem>
            ))}
            {isFetchingNextPage && (
              <MenuItem
                disabled
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <CircularProgress size={24} />
              </MenuItem>
            )}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseStockCounts;
