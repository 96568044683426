import React, { useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
} from "@mui/material";

const CreateNewModal = ({
  isOpen,
  toggleModalOpen,
  modalIcon,
  modalHeader,
  modalText,
  modalSubmit,
}) => {
  const [loading, toggleLoading] = useState(false);

  const closeModal = () => {
    toggleModalOpen(false);
    toggleLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item sx={{ marginBottom: 4 }}>
                {modalIcon}
              </Grid>
              <Grid item sx={{ marginBottom: 8 }}>
                <Typography variant="h2">{modalHeader}</Typography>
              </Grid>
              <Grid item sx={{ marginBottom: 8 }}>
                <Typography variant="body1" sx={{ fontSize: "12px" }}>
                  {modalText}
                </Typography>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={closeModal}
                  sx={{
                    marginLeft: "1rem",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="smallButton">No, Cancel</Typography>
                </Button>
                <Button
                  variant="contained"
                  onClick={modalSubmit}
                  sx={{
                    marginLeft: "1rem",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="smallButton">Yes, Create</Typography>
                </Button>
              </Box>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CreateNewModal;
