import { Checkbox, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const selectedStyle = {
  cursor: "pointer",
  width: "100%",
  height: "44px",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[900],
  backgroundColor: (theme) => theme.palette.terrain[900],
  marginBottom: 2,
};

const defaultStyle = {
  cursor: "pointer",
  width: "100%",
  height: "44px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[300],
  borderRadius: "4px",
  marginBottom: 2,
};

const AreaOption = ({ area, areaTargets, toggleAreaTarget }) => {
  const handleToggleSelected = () => {
    toggleAreaTarget(area.id);
  };

  return (
    <Grid
      item
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        width: "100%",
      }}
    >
      <Box
        sx={areaTargets[area.id] ? selectedStyle : defaultStyle}
        onClick={handleToggleSelected}
      >
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              size="small"
              sx={{
                color: (theme) => theme.palette.primary[800],
                "&.Mui-checked": {
                  color: (theme) => theme.palette.pure.white,
                },
              }}
              checked={areaTargets[area.id] || false}
            />
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: 12, sm: 12, md: 16 },
                color: areaTargets[area.id]
                  ? (theme) => theme.palette.pure.white
                  : (theme) => theme.palette.pure.black,
              }}
            >
              {area.name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AreaOption;
