import { Card, CardContent, Grid } from "@mui/material";

import React from "react";
import CutOffTimeSection from "../../../../views/Vendors/components/AddDistributorCard/components/CutOffTimeSection";
import DeliveryDaySection from "../../../../views/Vendors/components/AddDistributorCard/components/DeliveryDaySection";

const OrderDayDetailSection = ({
  validOrderDays,
  updateOrderDay
}) => {
  return (
    <Grid container>
      {validOrderDays.map((d) => (
        <Grid container key={d.day} sx={{ flexDirection: "column" }}>
          <Card
            elevation={0}
            sx={{
              border: "2px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              borderRadius: 2,
              backgroundColor: (theme) => theme.palette.terrain[50],
              marginBottom: 4,
            }}
          >
            <CardContent sx={{ paddingTop: 0 }}>
              <Grid item>
                <DeliveryDaySection
                  modalView
                  orderDay={d}
                  updateOrderDay={updateOrderDay}
                />
              </Grid>
            </CardContent>
            <CardContent
              sx={{
                borderTop: "1px solid",
                borderColor: (theme) => theme.palette.terrain[300],
                paddingTop: 4,
              }}
            >
              <Grid item>
                <CutOffTimeSection
                  modalView
                  orderDay={d}
                  updateOrderDay={updateOrderDay}
                />{" "}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default OrderDayDetailSection;
