import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import utilFunctions from "../../utilities/utilFunctions";

const sectionBubbleStyle = {
  borderRadius: 2,
  backgroundColor: (theme) => theme.palette.pure.white,
  border: (theme) => `2px solid ${theme.palette.terrain["03"]}`,
};

const imageStyle = {
  borderRadius: "100%",
  height: "89px",
  width: "89px",
  border: "2px solid #7E7E7E",
  margin: "auto",
};

const BeerDetailDisplay = ({ beer }) => (
  <Grid container direction="column" spacing={4}>
    <Grid
      item
      sx={{
        ...sectionBubbleStyle,
        ...{
          height: 137,
          display: "flex",
          alignItems: "center",
          paddingLeft: 6,
          paddingRight: 6,
          paddingBottom: 4,
        },
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <Typography variant="overline">Drink Status: </Typography>
          <Typography
            variant="overline"
            sx={{ color: (theme) => theme.palette.info.main }}
          >
            {beer.active ? "Active" : "Inactive"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">{utilFunctions.capitalizeString(beer.name)}</Typography>
        </Grid>
      </Grid>
      {beer.imageURL && <img src={beer.imageURL} style={imageStyle} />}
    </Grid>
    <Grid item>
      <Grid container direction="row" spacing={4}>
        <Grid item sx={{ paddingLeft: "0 !important" }} md={6}>
          <Grid container direction="column">
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Producer
              </Typography>
              <Typography variant="body1">{beer.producer || ""}</Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Style
              </Typography>
              <Typography variant="body1">{beer.style || ""}</Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                ABV
              </Typography>
              <Typography variant="body1">
                {beer.abv ? `${beer.abv}%` : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container direction="column">
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Tasting Notes
              </Typography>
              <Typography variant="body1">{beer.tastingNotes || ""}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default BeerDetailDisplay;
