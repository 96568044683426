import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const SubscriptionScreenLock = ({ show }) => {
    if (!show) {
        return null;
    }

    const navigate = useNavigate();
    const {establishmentid} = useParams();
    const navigateToSettings = () => {
      navigate(`/spec/${establishmentid}/settings`);
    }

    const modalStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white
        backdropFilter: 'blur(10px)', // Blurs the background content
        zIndex: 1000, // High z-index to ensure it's above other content
    };

    return (
      <div style={modalStyle}>
      <LockIcon style={{ fontSize: '4rem', marginBottom: '20px' }} /> {/* Adjust icon size as needed */}
      <Typography variant="h4" sx={{marginTop: 2, marginBottom: 2}}>Spec Pro Subscription Required</Typography>
      <Button variant="contained" color="primary" onClick={navigateToSettings}>
          Upgrade Now
      </Button>
  </div>
    );
};

export default SubscriptionScreenLock;