import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import SpecSpinner from "../../components/SpecSpinner";
import useMasterSubmissions from "../../hooks/useMasterSubmissions";
import SubmissionsList from "./components/SubmissionsList";
import SpecMasterEntrySubmissionMapModal from "../../components/Modals/SpecMasterEntrySubmissionMapModal";
import inventoryClient from "../../clients/inventoryClient";
import OtherSubmissionsSuggestions from "./components/OtherSubmissionsSuggestions";
import ExistingProductsSuggestions from "./components/ExistingProductSuggestions";
import { useMutation, useQueryClient } from "react-query";
import useMasterUnitOfMeasurements from "../../hooks/useMasterUnitOfMeasurements";
import useMasterFamilies from "../../hooks/useMasterFamilies";
import UnitSizeDisplayRow from "./components/UnitSizeDisplayRow";
import EditMasterProductDetailsForm from "./components/EditMasterProductDetailsForm";
import EditMasterFormatDetailsForm from "./components/EditMasterFormatDetailsForm";

const MasterSubmissionsControl = () => {
  const [isMappingModalOpen, toggleIsMappingModalOpen] = useState(false);
  const [loadingSuggestions, toggleLoadingSuggestions] = useState(false);
  const [isEditingProduct, toggleIsEditingProduct] = useState(false);
  const [isEditingFormat, toggleIsEditingFormat] = useState(false);
  const [prefillOptionForMap, setMapPrefill] = useState(undefined);
  const queryClient = useQueryClient();
  const [suggestions, setSuggestions] = useState(undefined);
  const [currentSelectedSubmission, setCurrentSelectedSubmission] =
    useState(undefined);
  const {
    data: masterSubmissionData,
    isLoading: isLoadingMasterSubmissionData,
    isError: isLoadingSubmissionsError,
    error: submissionLoadError,
  } = useMasterSubmissions();

  const { data: masterFamilyData, isLoading: isLoadingMasterFamilyData } =
    useMasterFamilies();

  const { data: masterUnitOfMeasurementsData, isLoading: isLoadingMasterUoms } =
    useMasterUnitOfMeasurements();

  const loadSuggestions = async () => {
    if (!currentSelectedSubmission) {
      return;
    }

    toggleLoadingSuggestions(true);
    const response =
      await inventoryClient.adminGetMasterProductSubmissionSuggestions(
        currentSelectedSubmission?.id
      );
    setSuggestions(response);
    toggleLoadingSuggestions(false);
  };

  useEffect(() => {
    loadSuggestions();
  }, [currentSelectedSubmission]);

  const refetchAndClear = () => {
    queryClient.invalidateQueries({
      queryKey: ["master-submissions"],
    });
    queryClient.refetchQueries({
      queryKey: ["master-submissions"],
    });
  };

  function getRandomName() {
    const names = ["grant", "dave", "connor", "calum", "nathan"];
    const randomIndex = Math.floor(Math.random() * names.length);
    return names[randomIndex];
  }

  const approveSubmissionMutation = useMutation(
    inventoryClient.adminApproveMasterSubmission,
    {
      onSuccess: () => {
        refetchAndClear();
        setCurrentSelectedSubmission(undefined);
        clearEditingStates();
        alert(`submission approved! beers on ${getRandomName()}!`);
      },
      onError: (res) => {
        console.log(res.response);
        alert(`uh oh: ${res.response?.data?.Messages[0]}`);
      },
    }
  );

  const clearEditingStates = () => {
    toggleIsEditingFormat(false);
    toggleIsEditingProduct(false);
  };

  const denySubmissionMutation = useMutation(
    inventoryClient.adminDenyMasterSubmission,
    {
      onSuccess: () => {
        refetchAndClear();
        setCurrentSelectedSubmission(undefined);
        clearEditingStates();
        alert("submission denied. get that shit outta here");
      },
    }
  );

  const changeMappingMutation = useMutation(
    inventoryClient.adminMapEntryToExisting,
    {
      onSuccess: (res) => {
        setCurrentSelectedSubmission(res.updatedSubmission);
        clearEditingStates();
        alert("mapping updated!");
        refetchAndClear();
      },
    }
  );

  const updateProductEntryMutation = useMutation(
    inventoryClient.patchMasterProductEntry,
    {
      onSuccess: (res) => {
        setCurrentSelectedSubmission(res.updatedSubmission);
        clearEditingStates();
        alert("product details updated!");
        refetchAndClear();
      },
    }
  );

  const updateFormatEntryMutation = useMutation(
    inventoryClient.patchMasterFormatEntry,
    {
      onSuccess: (res) => {
        setCurrentSelectedSubmission(res.updatedSubmission);
        clearEditingStates();
        alert("format details updated!");
        refetchAndClear();
      },
    }
  );

  const clearPrefill = () => {
    setMapPrefill(undefined);
  };

  const handleMapPrefill = (suggestion) => {
    setMapPrefill(suggestion);
    toggleIsMappingModalOpen(true);
  };

  const handleApprove = () => {
    approveSubmissionMutation.mutate({
      submissionId: currentSelectedSubmission?.id,
    });
  };

  const handleDeny = () => {
    denySubmissionMutation.mutate({
      submissionId: currentSelectedSubmission?.id,
    });
  };

  const handleMapping = (masterProductId) => {
    changeMappingMutation.mutate({
      submissionId: currentSelectedSubmission?.id,
      existingProductId: masterProductId,
    });
  };

  const handleSubmissionSelect = (sub) => {
    clearEditingStates();
    setCurrentSelectedSubmission(sub);
  };

  const handleCancelProductEdit = () => {
    toggleIsEditingProduct(false);
  };

  const handleSaveProductEdit = (updatedProduct) => {
    const payload = {
      submissionId: currentSelectedSubmission?.id,
      productNameUpdate: { value: updatedProduct?.name },
      productFamilyIdUpdate: { value: updatedProduct?.productFamilyId },
      productCategoryIdUpdate: { value: updatedProduct?.categoryId },
      productSubcategoryIdUpdate: { value: updatedProduct?.subcategoryId },
    };

    updateProductEntryMutation.mutate(payload);
  };

  const handleSaveFormat = (updatedFormat) => {
    const payload = {
      submissionId: currentSelectedSubmission?.id,
      unitSizesUpdate: { value: updatedFormat?.sizes },
    };

    updateFormatEntryMutation.mutate(payload);
  };

  if (
    isLoadingMasterSubmissionData ||
    isLoadingMasterFamilyData ||
    isLoadingMasterUoms
  ) {
    return (
      <div>
        <SpecSpinner open text="Loading submissions..." />
      </div>
    );
  }

  console.log(masterSubmissionData);
  console.log(suggestions);
  console.log(masterUnitOfMeasurementsData);
  console.log(masterFamilyData);

  if (isLoadingSubmissionsError) {
    if (submissionLoadError.response.status === 401) {
      return (
        <Typography
          variant="h2"
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          Who the fuck are you? We have alerted the FBI to your IP.
        </Typography>
      );
    }

    return (
      <Typography
        variant="h2"
        sx={{ color: (theme) => theme.palette.error.main }}
      >
        Shit, something went wrong loading the submissions. Slack grant.
      </Typography>
    );
  }

  const newProductEntry = currentSelectedSubmission?.newMasterProductEntry;
  const newFormatEntry = currentSelectedSubmission?.newMasterProductFormatEntry;
  return (
    <>
      <Grid container sx={{ width: "100%", height: "100%", padding: 5 }}>
        <Grid container direction="row" xs={12}>
          <Grid item direction="column" xs={6}>
            <SubmissionsList
              submissions={masterSubmissionData?.submissions || []}
              onSubmissionSelect={handleSubmissionSelect}
            />
          </Grid>
          <Grid item direction="column" xs={6}>
            <Grid item sx={{ paddingLeft: 4, paddingRight: 4 }}>
              {currentSelectedSubmission === undefined ? (
                <Typography>Select a Submission to view details</Typography>
              ) : (
                <Box>
                  <Typography
                    variant="h4"
                    sx={{ marginTop: 4, marginBottom: 4 }}
                  >
                    Spec Master Entry Details
                  </Typography>
                  <Typography variant="body2">
                    Submission Type:{" "}
                    {currentSelectedSubmission?.existingMasterProductId
                      ? "Map to existing master product"
                      : "Create new master product"}
                  </Typography>
                  {isEditingProduct ? null : (
                    <Typography variant="body2">
                      {currentSelectedSubmission?.existingMasterProductId
                        ? "Map to: " +
                          currentSelectedSubmission?.existingSpecMasterProduct
                            ?.name
                        : "Name: " +
                          currentSelectedSubmission?.newMasterProductEntry
                            ?.name}
                    </Typography>
                  )}
                  {!currentSelectedSubmission?.existingMasterProductId ? (
                    <>
                      {isEditingProduct ? (
                        <EditMasterProductDetailsForm
                          product={newProductEntry}
                          masterFamilies={
                            masterFamilyData?.masterFamilies || []
                          }
                          cancel={handleCancelProductEdit}
                          save={handleSaveProductEdit}
                          isLoading={updateProductEntryMutation?.isLoading}
                        />
                      ) : (
                        <Box
                          sx={{
                            borderTop: "1px solid grey",
                            borderBottom: "1px solid grey",
                            paddingTop: 3,
                            paddingBottom: 3,
                            marginTop: 1,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={
                              newProductEntry?.productFamilyId
                                ? {}
                                : { color: "red" }
                            }
                          >
                            Master Family:{" "}
                            {newProductEntry?.productFamilyId
                              ? masterFamilyData?.masterFamilies?.find(
                                  (x) =>
                                    x.id === newProductEntry.productFamilyId
                                )?.name
                              : "NOT SET - MISSING"}
                          </Typography>
                          <Typography variant="body2">
                            Master Category:{" "}
                            {newProductEntry?.categoryId
                              ? masterFamilyData?.masterFamilies
                                  ?.find(
                                    (x) =>
                                      x.id === newProductEntry.productFamilyId
                                  )
                                  ?.specMasterCategories?.find(
                                    (c) => c.id === newProductEntry?.categoryId
                                  )?.name
                              : "NOT SET"}
                          </Typography>
                          <Typography variant="body2">
                            Master Subcategory:{" "}
                            {newProductEntry?.subcategoryId
                              ? masterFamilyData?.masterFamilies
                                  ?.find(
                                    (x) =>
                                      x.id === newProductEntry.productFamilyId
                                  )
                                  ?.specMasterCategories?.find(
                                    (c) => c.id === newProductEntry?.categoryId
                                  )
                                  ?.specMasterSubcategories?.find(
                                    (x) =>
                                      x.id === newProductEntry?.subcategoryId
                                  )?.name
                              : "NOT SET"}
                          </Typography>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ marginTop: 1 }}
                            onClick={() => toggleIsEditingProduct(true)}
                          >
                            Edit Product Details
                          </Button>
                        </Box>
                      )}
                      {isEditingFormat ? (
                        <EditMasterFormatDetailsForm
                          format={newFormatEntry}
                          masterUoms={
                            masterUnitOfMeasurementsData?.masterUnitOfMeasurements ||
                            []
                          }
                          cancel={() => toggleIsEditingFormat(false)}
                          save={handleSaveFormat}
                          isLoading={updateFormatEntryMutation?.isLoading}
                        />
                      ) : (
                        <>
                          {newFormatEntry?.sizes?.map((x) => (
                            <UnitSizeDisplayRow key={x.type} unitSize={x} />
                          ))}
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => toggleIsEditingFormat(true)}
                          >
                            Edit Format Details
                          </Button>
                        </>
                      )}
                    </>
                  ) : null}
                  <div>
                    <pre>
                      {JSON.stringify(currentSelectedSubmission, null, 2)}
                    </pre>
                  </div>
                </Box>
              )}
            </Grid>
            {currentSelectedSubmission ? (
              <Grid item>
                <Button variant="contained" onClick={handleApprove}>
                  <Typography variant="largeButton">Approve</Typography>
                </Button>
                <Button
                  variant="outlined"
                  sx={{ marginLeft: 3 }}
                  onClick={handleDeny}
                >
                  <Typography variant="largeButton">Deny</Typography>
                </Button>
                <Button
                  variant="outlined"
                  sx={{ marginLeft: 3 }}
                  onClick={() => toggleIsMappingModalOpen(true)}
                >
                  <Typography variant="largeButton">Map to Existing</Typography>
                </Button>
              </Grid>
            ) : null}

            {suggestions ? (
              <>
                <Grid item sx={{ margin: 4 }}>
                  <Typography variant="h5">
                    Related Submission Suggestions
                  </Typography>
                  {loadingSuggestions ? (
                    <CircularProgress />
                  ) : (
                    <OtherSubmissionsSuggestions
                      suggestions={
                        suggestions?.otherSubmissionSuggestions || []
                      }
                    />
                  )}
                </Grid>
                <Grid item sx={{ margin: 4 }}>
                  <Typography variant="h5">
                    Existing Product Suggestions
                  </Typography>
                  {loadingSuggestions ? (
                    <CircularProgress />
                  ) : (
                    <ExistingProductsSuggestions
                      suggestions={
                        suggestions?.existingProductSuggestions || []
                      }
                      mapToSuggestionCallback={handleMapPrefill}
                    />
                  )}
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <SpecMasterEntrySubmissionMapModal
        isOpen={isMappingModalOpen}
        toggleModalOpen={toggleIsMappingModalOpen}
        prefillMapOption={prefillOptionForMap}
        clearPrefill={clearPrefill}
        handleMap={handleMapping}
      />
    </>
  );
};

export default MasterSubmissionsControl;
