import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { recipeIngredientTypes } from "../../../clients/inventoryObjects";

const freeTextOption = { id: -1, name: "Free Text Ingredient" };

const ProductSelectionToggle = ({
  options,
  selectCallback,
  selectedId,
  ingredientType,
  selected,
  ingredient,
  clearProduct,
}) => {
  const [editing, toggleEditing] = useState(true);
  const [product, setProduct] = useState({});

  useEffect(() => {
    const foundProduct = options.find((x) => x.id === selectedId);
    if (foundProduct) {
      setProduct(foundProduct);
      toggleEditing(false);
    } else if (ingredient.type === recipeIngredientTypes.OpenText) {
      setProduct(freeTextOption);
      toggleEditing(false);
    }
  }, [selectedId, ingredientType]);

  const handleClear = async () => {
    setProduct({});
    await clearProduct();
    toggleEditing(true);
  };

  const handleOnProductChange = async (e, newValue) => {
    if (newValue === null) {
      handleClear();
    } else {
      await selectCallback(newValue);
    }
  };

  const renderOption = (props, option) => {
    return (
      <li {...props} key={option.id || option.name} style={{ padding: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: 2,
            "&:hover": {
              color: (theme) => theme.palette.pure.white,
              backgroundColor: (theme) => theme.palette.terrain[500],
            },
          }}
        >
          <Box sx={{ width: "100%", marginBottom: 0.5 }}>
            <Typography variant="body1">{option.name}</Typography>
          </Box>
          {option.id !== -1 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
                width: "fit-content",
                height: "12px",
                borderRadius: 0.5,
                backgroundColor: (theme) =>
                  option.isProduct
                    ? theme.palette.secondary[800]
                    : theme.palette.info[900],
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  fontWeight: 700,
                  color: (theme) => theme.palette.pure.white,
                }}
              >
                {option.isProduct ? "Product" : "Recipe"}
              </Typography>
            </Box>
          )}
        </Box>
      </li>
    );
  };

  if (
    editing ||
    (selectedId === null && ingredient?.type !== recipeIngredientTypes.OpenText)
  ) {
    return (
      <Autocomplete
        fullWidth
        disableClearable
        size="small"
        getOptionLabel={(o) => o.name || ""}
        onChange={handleOnProductChange}
        options={[freeTextOption, ...options]}
        renderOption={renderOption}
        isOptionEqualToValue={(o, v) =>
          o.name?.toLowerCase()?.includes(v.name?.toLowerCase())
        }
        filterOptions={(opts, v) => {
          let newOptions = opts.filter((x) =>
            x.name?.toLowerCase().includes(v.inputValue?.toLowerCase())
          );
          if (newOptions.length === 0) newOptions = [freeTextOption];

          return newOptions;
        }}
        sx={{ height: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            fullWidth
            label={"Product"}
            sx={{
              height: "100%",
              "& .MuiFilledInput-root": {
                background: (theme) => theme.palette.pure.white,
              },
            }}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
          />
        )}
        value={product || {}}
      />
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.pure.white,
        height: "100%",
        width: "100%",
        display: "flex",
        padding: 2,
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        cursor: "default",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 1,
          width: "fit-content",
          height: "23px",
          borderRadius: 0.5,
          backgroundColor: (theme) =>
            ingredient?.type === recipeIngredientTypes.Recipe
              ? theme.palette.info[900]
              : theme.palette.secondary[800],
        }}
      >
        <Typography
          variant="body1"
          noWrap
          sx={{
            fontWeight: 700,
            color: (theme) => theme.palette.pure.white,
          }}
        >
          {selected?.name}
          {ingredient.type === recipeIngredientTypes.OpenText
            ? "Free Text Ingredient"
            : ""}
        </Typography>
        <IconButton
          sx={{
            backgroundColor: "transparent",
            height: "24px",
            width: "24px",
            marginRight: -1,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={() => handleClear()}
        >
          <ClearOutlinedIcon
            sx={{ color: (theme) => theme.palette.pure.white, fontSize: 18 }}
          />
        </IconButton>
      </Box>
      <IconButton
        sx={{ backgroundColor: "transparent" }}
        onClick={() => toggleEditing(true)}
      >
        <EditIcon
          fontSize="small"
          sx={{ color: (theme) => theme.palette.primary[800] }}
        />
      </IconButton>
    </Box>
  );
};

export default ProductSelectionToggle;
