import utilFunctions from "./utilFunctions";


export const session = {
    check: function check(tokenExpired, expirationCallback) {
        const checkDelay = 1000;
        const accessToken = localStorage.getItem('at');
        if (accessToken) {
            const tokenData = utilFunctions.parseJwt(accessToken);
            if(tokenData && Date.now() >= tokenData.exp * 1000) {
                expirationCallback();
            }
        }
        clearTimeout(session.timeout);
        setTimeout(
            () => session.check(tokenExpired, expirationCallback),
            checkDelay,
        );
    },
};