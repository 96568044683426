import { Avatar, Box, Grid, Typography } from "@mui/material";
import React from "react";

const OrderLineItemOption = ({ lineItem, order, selectedItem, selectItemCallback }) => {

  const handleAction = () => {
    selectItemCallback(lineItem, order);
  }

  const selected = selectedItem?.id === lineItem.id;
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "58px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[400],
        backgroundColor: (theme) =>
          selected ? theme.palette.secondary[900] : theme.palette.terrain[50],
        padding: 2,
        cursor: "pointer",
      }}
      onClick={handleAction}
    >
      <Box>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: 0,
          }}
        >
          <Grid item xs={2}>
            <Grid item>
              <Avatar />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Box>
              <div
                style={{
                  maxWidth: "140px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography
                  noWrap
                  variant="subtitle2"
                  sx={{
                    color: (theme) =>
                      selected
                        ? theme.palette.pure.white
                        : theme.palette.pure.black,
                  }}
                >
                  {lineItem.productName}
                </Typography>
              </div>
            </Box>
            <Box>
              <div
                style={{
                  maxWidth: "320px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography
                  variant="overline"
                  noWrap
                  sx={{
                    fontWeight: 700,
                    color: (theme) =>
                      selected
                        ? theme.palette.pure.white
                        : theme.palette.pure.black,
                  }}
                >
                  {`${lineItem.unitSize} ${lineItem.unitSizeAbbreviation}`}
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderLineItemOption;
