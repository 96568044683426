import { Box, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabPanel from "../../../TabPanel";
import OrderTabNav from "./OrderTabNav";
import ProductCard from "./ProductCard";
var moment = require("moment");

const TabularItemSelection = ({
  selectedOrders,
  handleUpdateExcluded,
  itemsToExcludeMap,
  generateExclusionMap,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    generateExclusionMap();
  }, [selectedOrders]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <OrderTabNav
        currentTab={currentTab}
        handleChange={handleTabChange}
        selectedOrders={selectedOrders}
      />
      <Box
        sx={{
          height: "48px",
          width: "100%",
          borderRadius: 1,
          backgroundColor: (theme) => theme.palette.terrain[100],
          padding: 2,
          marginTop: 4,
        }}
      >
        <Box>
          <Typography variant="body1">Line Items from Order:</Typography>
        </Box>
        <Box>
          <Typography variant="body2">
            {selectedOrders[currentTab] !== undefined
              ? moment(selectedOrders[currentTab].createdDate).format(
                  "MMMM DD, YYYY"
                )
              : ""}
            {", "}
            {"$"}
            {selectedOrders[currentTab].estimatedCost.amount}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: 2,
          height: "260px",
          overflow: "scroll",
          borderRadius: 2,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
        }}
      >
        {selectedOrders.map((x, i) => (
          <TabPanel key={i} value={currentTab} index={i}>
            {x.lineItems.map((l) => (
              <ProductCard
                key={l.id}
                lineItem={l}
                order={x}
                isExcluded={itemsToExcludeMap[x.id]?.includes(l.id)}
                updateExcluded={handleUpdateExcluded}
              />
            ))}
          </TabPanel>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "100%",
          height: "40px",
          backgroundColor: (theme) => theme.palette.terrain[100],
          borderRadius: 1,
          marginTop: 2,
          padding: 1,
        }}
      >
        <Switch />
        <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
          Skip Line Item Creation
        </Typography>
      </Box>
    </>
  );
};

export default TabularItemSelection;
