import React from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  TextField,
  Divider,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SpecBackButton from "../../../SpecBackButton";

const NewTemplateNameStep = ({
  handleNextAction,
  handleBack,
  templateName,
  handleNameChange,
  closeModal,
}) => {
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 3,
        }}
      >
        <Grid item>
          <SpecBackButton backText="Back" onClick={handleBack} />
        </Grid>
        <Grid item>
          <IconButton onClick={closeModal}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: 2, display: "flex", flexDirection: "row" }}>
        <Typography variant="h3">Create Template</Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: { xs: 10, sm: 10, md: 12 } }}>
          Partial audit templates allow you to count specific areas and product
          families/categories.
        </Typography>
      </Box>
      <Box sx={{ marginBottom: 6, marginTop: 4 }}>
        <TextField
          size="small"
          variant="outlined"
          autoComplete="off"
          fullWidth
          placeholder="Enter name"
          label="Name"
          InputLabelProps={{ shrink: true }}
          value={templateName}
          onChange={handleNameChange}
        />
      </Box>
      <Divider sx={{ marginLeft: -8, marginRight: -8, marginTop: 8 }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <Button
          variant="contained"
          onClick={handleNextAction}
          disabled={!templateName}
          sx={{
            width: "90%",
            borderRadius: 2,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <Typography variant="smallButton">Next</Typography>
        </Button>
      </Box>
    </>
  );
};

export default NewTemplateNameStep;
