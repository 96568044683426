import { Box, Grid, MenuItem, Radio, Select, Typography } from "@mui/material";
import React from "react";
import { invoiceNonItemizedOrderByTypesForSummary } from "../../../../../clients/inventoryObjects";

const selectedStyle = {
  cursor: "pointer",
  width: "100%",
  height: "95px",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.primary[800],
  backgroundColor: (theme) => theme.palette.terrain[200],
  marginBottom: 2,
};

const defaultStyle = {
  cursor: "pointer",
  width: "100%",
  height: "95px",
  border: "1px solid gray",
  borderRadius: "4px",
  marginBottom: 2,
};

const SortBy = ({
  orderByCategoryChoice,
  orderByCategoryOptions,
  setOrderByCategoryChoice,
  timeSortChoice,
  setTimeSortChoice,
  vendorSortChoice,
  setVendorSortChoice,
  invoiceTotalSortChoice,
  setInvoiceTotalSortChoice,
}) => {
  const handleChooseTime = () => {
    setOrderByCategoryChoice(orderByCategoryOptions.time);
  };

  const handleChooseVendorCategory = () => {
    setOrderByCategoryChoice(orderByCategoryOptions.vendor);
  };

  const handleChooseInvoiceTotalCategory = () => {
    setOrderByCategoryChoice(orderByCategoryOptions.invoiceTotal);
  };

  const handleTimeSortChoice = (e) => {
    setTimeSortChoice(e.target.value);
  };

  const handleVendorSortChoice = (e) => {
    setVendorSortChoice(e.target.value);
  };

  const handleInvoiceSortChoice = (e) => {
    setInvoiceTotalSortChoice(e.target.value);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="h5">Sort by</Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Grid
          container
          direction="row"
          spacing={2}
          sx={{ marginTop: 2, width: { xl: "70%", lg: "100%", md: "100%" } }}
        >
          <Grid item xs={4.5}>
            <Box
              onClick={handleChooseTime}
              sx={
                orderByCategoryChoice === orderByCategoryOptions.time
                  ? selectedStyle
                  : defaultStyle
              }
            >
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid item>
                  <Radio
                    checked={
                      orderByCategoryChoice === orderByCategoryOptions.time
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: 12, sm: 12, md: 16 },
                      color:
                        orderByCategoryChoice === orderByCategoryOptions.time
                          ? (theme) => theme.palette.primary[800]
                          : (theme) => theme.palette.pure.black,
                    }}
                  >
                    Time
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                <Select
                  value={timeSortChoice}
                  onChange={handleTimeSortChoice}
                  sx={{
                    width: "100%",
                    height: "40px",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <MenuItem
                    value={
                      invoiceNonItemizedOrderByTypesForSummary.dateNewestFirst
                    }
                  >
                    Newest to Oldest
                  </MenuItem>
                  <MenuItem
                    value={
                      invoiceNonItemizedOrderByTypesForSummary.dateOldestFirst
                    }
                  >
                    Oldest to Newest
                  </MenuItem>
                </Select>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              onClick={handleChooseVendorCategory}
              sx={
                orderByCategoryChoice === orderByCategoryOptions.vendor
                  ? selectedStyle
                  : defaultStyle
              }
            >
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid item>
                  <Radio
                    checked={
                      orderByCategoryChoice === orderByCategoryOptions.vendor
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: 12, sm: 12, md: 16 },
                      color:
                        orderByCategoryChoice === orderByCategoryOptions.vendor
                          ? (theme) => theme.palette.primary[800]
                          : (theme) => theme.palette.pure.black,
                    }}
                  >
                    Vendor
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                <Select
                  value={vendorSortChoice}
                  onChange={handleVendorSortChoice}
                  sx={{
                    width: "100%",
                    height: "40px",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <MenuItem
                    value={invoiceNonItemizedOrderByTypesForSummary.vendorAtoZ}
                  >
                    A-Z
                  </MenuItem>
                  <MenuItem
                    value={invoiceNonItemizedOrderByTypesForSummary.vendorZtoA}
                  >
                    Z-A
                  </MenuItem>
                </Select>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4.5}>
            <Box
              onClick={handleChooseInvoiceTotalCategory}
              sx={
                orderByCategoryChoice === orderByCategoryOptions.invoiceTotal
                  ? selectedStyle
                  : defaultStyle
              }
            >
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid item>
                  <Radio
                    checked={
                      orderByCategoryChoice ===
                      orderByCategoryOptions.invoiceTotal
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: 12, sm: 12, md: 16 },
                      color:
                        orderByCategoryChoice ===
                        orderByCategoryOptions.invoiceTotal
                          ? (theme) => theme.palette.primary[800]
                          : (theme) => theme.palette.pure.black,
                    }}
                  >
                    Invoice Total
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                <Select
                  value={invoiceTotalSortChoice}
                  onChange={handleInvoiceSortChoice}
                  sx={{
                    width: "100%",
                    height: "40px",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                >
                  <MenuItem
                    value={
                      invoiceNonItemizedOrderByTypesForSummary.invoiceTotalHighToLow
                    }
                  >
                    High to Low
                  </MenuItem>
                  <MenuItem
                    value={
                      invoiceNonItemizedOrderByTypesForSummary.invoiceTotalLowToHigh
                    }
                  >
                    Low to High
                  </MenuItem>
                </Select>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SortBy;
