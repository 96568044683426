import { Alert, Snackbar } from "@mui/material";
import React from "react";

const SnackBarError = ({ errorMessage, setErrorMessage }) => {
  return (
    <Snackbar
      open={errorMessage ? true : false}
      onClose={() => setErrorMessage(undefined)}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity="error" variant="filled">
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarError;
