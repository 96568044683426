import React, { useState, useEffect } from "react";
import { modalBoxStyle } from "../styles";
import {
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FamilyFilters from "./components/FamilyFilters";

const ProductFiltersModal = ({
  isOpen,
  toggleModalOpen,
  currentFilters,
  families,
  onCompleteCallback,
}) => {
  const [inMemFilters, setInMemFilters] = useState({});

  useEffect(() => {
    if (currentFilters) {
      var newInMemFilters = { };

      currentFilters?.familyFilters?.forEach((f) => {
        newInMemFilters[f.id] = {};

        f.categoryFilters?.forEach((c) => {
          newInMemFilters[f.id][c.id] = {};

          c.subcategoryFilters?.forEach((s) => {
            newInMemFilters[f.id][c.id][s.id] = {};
          });
        });
      });
      setInMemFilters(newInMemFilters);
    }
  }, [isOpen]);

  const handleToggleFamily = (familyId) => {
    if (inMemFilters[familyId]) {
      const updatedFilters = { ...inMemFilters };
      delete updatedFilters[familyId];
      console.log(updatedFilters);
      setInMemFilters(updatedFilters);
    } else {
      setInMemFilters({ ...inMemFilters, [familyId]: {} });
    }
  };

  const handleToggleCategory = (familyId, categoryId) => {
    const familyFilters = inMemFilters[familyId] || {};

    if (familyFilters[categoryId]) {
      const updatedFamilyFilters = { ...familyFilters };
      delete updatedFamilyFilters[categoryId];
      setInMemFilters({ ...inMemFilters, [familyId]: updatedFamilyFilters });
    } else {
      setInMemFilters({
        ...inMemFilters,
        [familyId]: { ...familyFilters, [categoryId]: {} },
      });
    }
  };

  const handleToggleSubcategory = (familyId, categoryId, subcategoryId) => {
    const familyFilters = inMemFilters[familyId] || {};
    const categoryFilters = familyFilters[categoryId] || {};

    if (categoryFilters[subcategoryId]) {
      const updatedCategoryFilters = { ...categoryFilters };
      delete updatedCategoryFilters[subcategoryId];
      setInMemFilters({
        ...inMemFilters,
        [familyId]: {
          ...familyFilters,
          [categoryId]: updatedCategoryFilters,
        },
      });
    } else {
      setInMemFilters({
        ...inMemFilters,
        [familyId]: {
          ...familyFilters,
          [categoryId]: { ...categoryFilters, [subcategoryId]: {} },
        },
      });
    }
  };

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const handleSubmit = () => {
    const familyFilters = [];

    for (const familyId in inMemFilters) {
      const familyObj = {
        id: familyId,
        categoryFilters: [],
      };

      for (const categoryId in inMemFilters[familyId]) {
        const categoryObj = {
          id: categoryId,
          subcategoryFilters: [],
        };

        for (const subcategoryId in inMemFilters[familyId][categoryId]) {
          categoryObj.subcategoryFilters.push({ id: subcategoryId });
        }

        familyObj.categoryFilters.push(categoryObj);
      }

      familyFilters.push(familyObj);
    }

    // Now 'familyFilters' contains the structure you want to send
    // You can submit this object via HTTP to your C# API
    const productFiltersDto = { familyFilters };
    onCompleteCallback(productFiltersDto);
  };

  const handleClearFilters = () => {
    setInMemFilters({});
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0,
          }}
        >
          <Grid item>
            <Typography variant="h4">Filter Products</Typography>
          </Grid>
          <Grid item>
            <IconButton
              sx={{ backgroundColor: "transparent" }}
              onClick={closeModal}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box>
          <FamilyFilters
            filters={inMemFilters}
            toggleCategoryTarget={handleToggleCategory}
            toggleSubcategoryTarget={handleToggleSubcategory}
            toggleFamilyTarget={handleToggleFamily}
            families={families}
            clearFilters={handleClearFilters}
          />
        </Box>
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="contained" onClick={handleSubmit}>
            <Typography variant="largeButton">Apply</Typography>{" "}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductFiltersModal;
