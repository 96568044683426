import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import utilFunctions from "../../../utilities/utilFunctions";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import Store from "../../../Store/Store";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";

var moment = require("moment");

const CreateInvoiceModal = ({
  isOpen,
  toggleModalOpen,
  buttonText,
  headerText,
  loading,
  vendors,
  createInvoice,
}) => {
  const { control } = useForm();
  const store = useContext(Store);
  const [dateValue, setDateValue] = useState(null);
  const [selectedVendor, setVendor] = useState(-1);
  const { data: settings } = useEstablishmentSettings(store.currentEstablishment?.id);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceTotalDisplay, setInvoiceTotalDisplay] = useState(0.00);
  const { currencySymbol, currency, locale } = useVendorCurrency(settings?.inventorySettings?.currency);

  useEffect(() => {
    setInvoiceTotalDisplay(utilFunctions.convertCentsToLargerFormatCurrency(0, locale, currency));
  }, [isOpen]);

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const handleCreateInvoice = () => {
    formatTotal();
    // TODO: update invoice total here to pull the settings for the establishment
    // for the invoice total
    createInvoice({
      invoiceDate: moment.utc(dateValue).format(),
      vendorId: selectedVendor,
      invoiceNumber: invoiceNumber,
      invoiceTotal: { amount: utilFunctions.convertCurrencyToCents(invoiceTotalDisplay), type: 0, unit: 0 }
    });
    setVendor(-1);
    setInvoiceNumber("");
    setInvoiceTotalDisplay(0.00);
    setDateValue(null);
  };

  const handleTotalUpdate = (e) => {
    setInvoiceTotalDisplay(e.target.value);
  }

  const formatTotal = () => {
    var convertedToCents = utilFunctions.convertCurrencyToCents(invoiceTotalDisplay);
    setInvoiceTotalDisplay(utilFunctions.convertCentsToLargerFormatCurrency(convertedToCents, locale, currency));
  }

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <form>
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="h2">{headerText}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={closeModal}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Box sx={{ marginTop: 8, width: "100%" }}>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                  Invoice Date
                </Typography>
                <Controller
                  valueName="selected"
                  control={control}
                  name="invoiceDate"
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        dateFormat="mm/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e);
                        }}
                        value={dateValue}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            variant="outlined"
                            autoComplete="off"
                            onFocus={utilFunctions.highlightInputOnFocus}
                            fullWidth
                            {...params}
                            sx={{ height: "40px",  "& legend": { display: "none" } }}
                            value={dateValue}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Box>
              <Box sx={{ marginTop: 8, width: "100%" }}>
                <Typography variant="body2" sx={{ marginBottom: 2, }}>
                  Vendor
                </Typography>
                <Select
                  fullWidth
                  label="Vendor"
                  value={selectedVendor.id}
                  sx={{ height: "40px",  "& legend": { display: "none" } }}
                  onChange={(e) => setVendor(e.target.value)}
                  placeholder="Select Vendor"
                  defaultValue={"Select Vendor"}
                >
                  {vendors?.map((x) => (
                    <MenuItem value={x.id} key={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box sx={{ marginTop: 8, width: "100%" }}>
                <Typography variant="body2" sx={{ marginBottom: 2, }}>
                  Invoice Number
                </Typography>
                <TextField
                  size="small"
                  hiddenLabel
                  fullWidth
                  placeholder="Enter Invoice Number"
                  autoComplete="off"
                  value={invoiceNumber}
                  onFocus={utilFunctions.highlightInputOnFocus}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                  sx={{ height: "40px",  "& legend": { display: "none" } }}
                />
              </Box>
              {/* Need to wire up Invoice Total */}
              <Box sx={{ marginTop: 8, width: "100%", marginBottom: 4 }}>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                  Invoice Total
                </Typography>
                <TextField
                  size="small"
                  label={null}
                  fullWidth
                  placeholder="Enter Invoice Total"
                  autoComplete="off"
                  value={invoiceTotalDisplay}
                  onBlur={formatTotal}
                  onChange={handleTotalUpdate}
                  onFocus={utilFunctions.highlightInputOnFocus}
                  sx={{ height: "40px", "& legend": { display: "none" } }}
                  InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                  onClick={() => handleCreateInvoice()}
                >
                  <Typography variant="largeButton">{buttonText}</Typography>
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default CreateInvoiceModal;
