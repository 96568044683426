import React from "react";
import { Grid, Typography, Button } from "@mui/material";

const Footer = () => {
  return (
    <Grid
      item
      sx={{
        width: "100%",
        backgroundColor: (theme) => theme.palette.pure.black,
      }}
    >
      <Grid container direction="column">
        <Grid container direction="row">
          <Grid item sx={{ display: "flex", margin: "auto" }}>
            <Grid
              container
              direction="row"
              spacing={6}
              sx={{
                height: "120px",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item>
                <Button
                  href="https://www.specapp.com/about"
                  variant="outlined"
                  target={"_blank"}
                  underline="none"
                  color="inherit"
                  sx={{
                    border: "1px solid",
                    backgroundColor: (theme) => theme.palette.terrain["01"],
                    borderColor: (theme) => theme.palette.terrain[700],
                    borderRadius: 2,
                    color: (theme) => theme.palette.pure.white,
                    "&:hover": {
                      color: (theme) => theme.palette.terrain["01"],
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ textTransform: "none" }}>
                    About Us
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  href="https://www.specapp.com/terms-policies"
                  target={"_blank"}
                  variant="outlined"
                  underline="none"
                  color="inherit"
                  sx={{
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.terrain[700],
                    borderRadius: 2,
                    backgroundColor: (theme) => theme.palette.terrain["01"],

                    color: (theme) => theme.palette.pure.white,
                    "&:hover": {
                      color: (theme) => theme.palette.terrain["01"],
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ textTransform: "none" }}>
                    Terms and Policies
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  href="https://www.specapp.com/blog"
                  target={"_blank"}
                  variant="outlined"
                  underline="none"
                  color="inherit"
                  sx={{
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.terrain[700],
                    borderRadius: 2,
                    backgroundColor: (theme) => theme.palette.terrain["01"],

                    color: (theme) => theme.palette.pure.white,
                    "&:hover": {
                      color: (theme) => theme.palette.terrain["01"],
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ textTransform: "none" }}>
                    Blog
                  </Typography>
                </Button>
              </Grid>

              <Grid container>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    margin: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    underline="none"
                    color="inherit"
                    sx={{
                      color: (theme) => theme.palette.pure.white,
                    }}
                  >
                    &copy; 2024 Spec Software, LLC
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
