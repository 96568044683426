import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import OrderPill from "./OrderPill";
import UnlinkedIcon from "../../../components/CustomIcons/UnlinkedIcon";
import OrderFilterBanner from "./OrderFilterBanner";

const OrderPillBox = ({
  currentLinkedOrders,
  orderColorMap,
  setOrderForFilter,
  orderForFilter,
  invoiceLineItems,
  unlinkOrder,
  isNoUnlinkedLineItems,
}) => {
  const handleDisableOrderFilter = () => {
    setOrderForFilter(undefined);
  };

  const handleToggleOrderFilter = (order) => {
    if (orderForFilter !== order.id) {
      setOrderForFilter(order.id);
    } else {
      handleDisableOrderFilter();
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        {currentLinkedOrders.map((x) => (
          <OrderPill
            key={x.index}
            order={x}
            color={orderColorMap[x.id]?.primary}
            toggleOrderFilter={handleToggleOrderFilter}
            orderForFilter={orderForFilter}
          />
        ))}
        {isNoUnlinkedLineItems ? null : (
          <Chip
            size="medium"
            label={
              <Box>
                <Typography variant="body2">Unlinked</Typography>{" "}
              </Box>
            }
            avatar={
              <Box
                sx={{
                  borderRadius: "100%",
                  width: "32px",
                  height: "32px",
                  backgroundColor: (theme) => theme.palette.terrain[300],
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <UnlinkedIcon sx={{ fontSize: 18 }} />
              </Box>
            }
            sx={{
              backgroundColor: (theme) => theme.palette.terrain[100],
              cursor: "pointer",
              borderBottomLeftRadius: orderForFilter !== undefined && 0,
              borderBottomRightRadius: orderForFilter !== undefined && 0,
            }}
            onClick={() => handleToggleOrderFilter({ id: null })}
          />
        )}
      </Box>
      <OrderFilterBanner
        orderColorMap={orderColorMap}
        linkedOrders={currentLinkedOrders}
        invoiceItems={invoiceLineItems}
        handleClose={handleDisableOrderFilter}
        orderForFilter={orderForFilter}
        unlinkOrder={unlinkOrder}
      />
    </>
  );
};

export default OrderPillBox;
