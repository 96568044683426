import React, { useEffect, useState, useContext } from "react";
import StaffDisplay from "./components/StaffDisplay";
import {
  getEstablishmentMemberships,
  getEstablishment,
  transferEstablishmentOwnership,
} from "../../clients/establishmentsClient";
import {
  TextField,
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Grid,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import Store from "../../Store/Store";
import ReactGA from "react-ga";
import { useParams } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddIcon from "@mui/icons-material/Add";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import EditStaffModal from "../../components/Modals/EditStaffModal";
import DeleteStaffModal from "../../components/Modals/DeleteStaffModal";
import AddStaffMemberModal from "../../components/Modals/AddStaffMemberModal";
import SpecSpinner from "../../components/SpecSpinner";
import { useMutation } from "react-query";
import StaffOwnershipTransferModal from "../../components/Modals/StaffOwnershipTransferModal";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";

function filterStaffMembers(staffData, staffFilter) {
  return staffData.filter((x) => {
    return (
      x.firstName.toLowerCase().includes(staffFilter.toLowerCase()) ||
      x.lastName.toLowerCase().includes(staffFilter.toLowerCase())
    );
  });
}

const StaffManagement = () => {
  let { establishmentid } = useParams();
  const store = useContext(Store);
  const [staffData, updateStaffData] = useState([]);
  const [loading, toggleLoading] = useState(true);
  const [name, updateName] = useState("");
  const [error, toggleError] = useState(false);
  const [staffFilter, updateStaffFilter] = useState("");
  const [editStaffModalOpen, toggleEditStaffModal] = useState(false);
  const [deleteStaffModalOpen, toggleDeleteStaffModal] = useState(false);
  const [addStaffModalOpen, toggleAddStaffModalOpen] = useState(false);
  const [userToTransferOwnership, setUserToTransferOwnership] = useState(null);
  const [isTransferOwnershipModalOpen, toggleTransferOwnershipModal] =
    useState(false);
  const [isErrorWithOwnershipTransfer, toggleErrorWithOwnershipTransfer] =
    useState(false);
  const [userToEdit, setUserToEdit] = useState(undefined);
  const [userToDelete, setUserToDelete] = useState(undefined);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  function refreshPage() {
    window.location.reload();
  }

  const handleClose = () => {
    setAlertSuccess(false);
    setErrorMessage(false);
  };

  const handleOpenEditModal = (staffMember) => {
    setUserToEdit(staffMember);
    toggleEditStaffModal(true);
  };

  const handleCloseEditModal = () => {
    setUserToEdit(undefined);
    toggleEditStaffModal(false);
  };

  const handleOpenDeleteModal = (staffMember) => {
    setUserToDelete(staffMember);
    toggleDeleteStaffModal(true);
  };

  const handleOpenOwnershipTransferModal = (staffMember) => {
    setUserToTransferOwnership(staffMember);
    toggleTransferOwnershipModal(true);
  };

  const handleCloseOwnershipTransferModal = () => {
    setUserToTransferOwnership(undefined);
    toggleTransferOwnershipModal(false);
    toggleErrorWithOwnershipTransfer(false);
  };

  const handleCloseDeleteModal = () => {
    setUserToDelete(undefined);
    toggleDeleteStaffModal(false);
  };

  const handleCloseAddStaffModal = () => {
    toggleAddStaffModalOpen(false);
  };

  const setStaffFilter = (filter) => {
    updateStaffFilter(filter);
  };

  const transferOwnershipMutation = useMutation(
    transferEstablishmentOwnership,
    {
      onSuccess: () => {
        window.location.reload();
      },
      onError: (err) => {
        console.log(err);
        toggleErrorWithOwnershipTransfer(true);
      },
    }
  );

  const handleTransferOwnership = () => {
    transferOwnershipMutation.mutate({
      establishmentId: establishmentid,
      userId: userToTransferOwnership?.userId,
    });
  };

  const fetchEstablishmentMemberships = async () => {
    try {
      toggleLoading(true);
      setSpinnerOpen(true);
      const memberships = await getEstablishmentMemberships(establishmentid);
      const { name } = await getEstablishment(establishmentid);
      updateStaffData([...memberships.membershipProfiles]);
      updateName(name);
      handleCloseEditModal();
      toggleError(false);
      toggleLoading(false);
      setSpinnerOpen(false);
    } catch (err) {
      console.log(err);
      toggleLoading(false);
      toggleError(true);
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    fetchEstablishmentMemberships();
  }, [establishmentid]);

  if (loading) {
    return (
      <SpecSpinner open={spinnerOpen} text="Loading staff information..." />
    );
  }

  const currentUser = staffData?.find((x) => x.userId === store.userId);
  const hasNoOwner =
    staffData?.find((x) => x.isOwner) === undefined ? true : false;
  return (
    <>
      <>
        <Grid
          container
          direction={"column"}
          sx={{
            maxWidth: "2440px",
            marginTop: 6,
            marginLeft: 2,
            padding: 4,
          }}
        >
          {error && (
            <Typography variant="h6" sx={{ color: "red" }}>
              There was an error fetching establishment data, please refresh
            </Typography>
          )}
          <Grid item sx={{ marginBottom: 6 }}>
            <Grid item sm={12}>
              <Typography variant="h2" sx={{ fontSize: { xs: 20, sm: 28 } }}>
                {name} Staff Members
              </Typography>
            </Grid>
          </Grid>
          {hasNoOwner ? (
            <Box
              sx={{
                width: "100%",
                height: "fit-content",
                borderRadius: 2,
                marginBottom: 6,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  width: "52px",
                  minHeight: "100% !important",
                  backgroundColor: (theme) => theme.palette.warning[800],
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <WarningOutlinedIcon sx={{ color: "#fff" }} />
              </Box>
              <Box
                sx={{
                  width: `calc(100% - 52px)`,
                  border: "2px solid",
                  borderColor: (theme) => theme.palette.warning[800],
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                  backgroundColor: (theme) => theme.palette.warning[50],
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  paddingLeft: 4,
                }}
              >
                <Typography variant="subtitle2">
                  This establishment is currently missing an owner. An owner is
                  required to manage billing and upgrade to Spec Pro. This could
                  be because something has either gone wrong or we were unable
                  to automatically detect the owner. Please reach out to
                  dave@specapp.com with your establishment and the name for the
                  user in Spec that you would like to be set as the owner. Also
                  ensure they have an account and have been added as an admin
                  here.
                </Typography>
              </Box>
            </Box>
          ) : null}
          {staffData && staffData.length < 2 && (
            <Grid item sx={{ paddingRight: 2, marginBottom: 4 }}>
              <Typography variant="body1">
                Welcome to the Staff Management section. This is where you can
                add staff members to your establishment, which enables access
                through the Spec Mobile App. There, they can access your
                establishment training materials, signature cocktail recipes,
                and any important information essential to your beverage
                program. You can easily add staff with one click using their
                phone number or email address. To get started, click the Add
                Staff Member button below!
              </Typography>
            </Grid>
          )}
          <Grid item sx={{marginTop: 0, marginBottom: 3}}>
          <Alert severity="info">
            <Typography variant="body1">
              Establishment owners are the only ones who can control billing.
            </Typography>
            <Typography variant="body1">
              Admins are users who have access to the Spec Dashboard and
              inventory features in the Spec mobile app.
            </Typography>
            <Typography variant="body1">
              Users are staff members who you want to share recipes with in the
              mobile app but do not have access to change things or view
              inventory functions inside the Spec Dashboard or mobile app.
            </Typography>
          </Alert>
          </Grid>
          <Grid
            container
            direction="row"
            sx={{
              marginBottom: 6,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={6}
              xl={6}
              sx={{ paddingRight: 3 }}
            >
              <TextField
                size="small"
                fullWidth
                onChange={(e) => setStaffFilter(e.target.value)}
                id="staffSearch"
                inputProps={{
                  sx: {
                    backgroundColor: (theme) => theme.palette.pure.white,
                    marginBottom: 1,
                  },
                }}
                sx={{
                  backgroundColor: (theme) => theme.palette.pure.white,
                  width: { xs: "340px", sm: "100%" },
                }}
                label={
                  <>
                    <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                    Search for Staff Member
                  </>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={6}
              xl={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", md: "flex-end" },
                marginTop: { xs: 4, sm: 4, md: 0 },
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 2,
                }}
              >
                <Button
                  onClick={refreshPage}
                  variant="outlined"
                  sx={{
                    background: (theme) => theme.palette.pure.white,
                    borderRadius: 2,
                    padding: 4,
                    height: "40px",
                  }}
                >
                  <AutorenewIcon style={{ marginRight: 3 }} />
                  <Typography variant="smallButton">Refresh</Typography>
                </Button>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => toggleAddStaffModalOpen(true)}
                  sx={{
                    height: "40px",
                    padding: 4,
                    borderRadius: 2,
                  }}
                >
                  <AddIcon
                    style={{
                      marginRight: 1,
                      fontSize: "20px",
                    }}
                  />
                  <Typography variant="smallButton">
                    Add Staff Member
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: { xs: 300, sm: 400, md: 700 } }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        background: "black",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Employee Name
                    </TableCell>
                    <TableCell
                      sx={{
                        background: "black",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Permissions
                    </TableCell>
                    <TableCell sx={{ background: "black" }}></TableCell>
                  </TableRow>
                </TableHead>
                <StaffDisplay
                  staffMembers={
                    staffFilter
                      ? filterStaffMembers(staffData, staffFilter)
                      : staffData
                  }
                  setUserToEdit={handleOpenEditModal}
                  setUserToDelete={handleOpenDeleteModal}
                  currentUserId={store.userId}
                  currentUser={currentUser}
                  fetchEstablishmentMemberships={fetchEstablishmentMemberships}
                  setUserToOwnerTransfer={handleOpenOwnershipTransferModal}
                />
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </>
      <AddStaffMemberModal
        open={addStaffModalOpen}
        toggleModal={handleCloseAddStaffModal}
        establishmentId={establishmentid}
        setAlertSuccess={setAlertSuccess}
        setAlertMessage={setAlertMessage}
        toggleError={toggleError}
        setErrorMessage={setErrorMessage}
        fetchEstablishmentMemberships={fetchEstablishmentMemberships}
      />
      <EditStaffModal
        staffMember={userToEdit}
        fetchEstablishmentMemberships={fetchEstablishmentMemberships}
        establishmentId={establishmentid}
        toggleEditStaffModal={handleCloseEditModal}
        open={editStaffModalOpen}
        setAlertSuccess={setAlertSuccess}
        setAlertMessage={setAlertMessage}
        toggleError={toggleError}
        setErrorMessage={setErrorMessage}
      />
      <StaffOwnershipTransferModal
        hasError={isErrorWithOwnershipTransfer}
        isLoading={transferOwnershipMutation?.isLoading}
        staffMember={userToTransferOwnership}
        open={isTransferOwnershipModalOpen}
        toggleStaffOwnershipTransferModal={handleCloseOwnershipTransferModal}
        transferOwnershipCallback={handleTransferOwnership}
      />
      <DeleteStaffModal
        open={deleteStaffModalOpen}
        staffMember={userToDelete}
        establishmentId={establishmentid}
        fetchEstablishmentMemberships={fetchEstablishmentMemberships}
        toggleDeleteStaffModal={handleCloseDeleteModal}
        setAlertSuccess={setAlertSuccess}
        setAlertMessage={setAlertMessage}
        toggleError={toggleError}
        setErrorMessage={setErrorMessage}
      />
      <Box>
        <Snackbar
          open={alertSuccess}
          onClose={handleClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {alertMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={error}
          onClose={handleClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default StaffManagement;
