import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const EditMasterProductDetailsForm = ({ product, masterFamilies, cancel, save, isLoading }) => {
  const [productData, setProductData] = useState({});

  useEffect(() => {
    if (product !== undefined) {
      setProductData(Object.assign({}, product));
    }
  }, [product]);

  const handleUpdateName = (e) => {
    setProductData(Object.assign({}, productData, { name: e.target.value }));
  }

  const handleSave = () => {
    save(productData);
  }

  const handleCancel = () => {
    cancel();
  }

  const handleFamilyUpdate = (e) => {
    setProductData(Object.assign({}, productData, { productFamilyId: e.target.value, categoryId: null, subcategoryId: null }));
  }

  const handleCategoryUpdate = (e) => {
    setProductData(Object.assign({}, productData, { categoryId: e.target.value, subcategoryId: null  }));
  }

  const handleSubcategoryUpdate = (e) => {
    setProductData(Object.assign({}, productData, { subcategoryId: e.target.value }));
  }

  const matchingFamily = masterFamilies?.find(x => x.id === productData?.productFamilyId);
  const matchingCategory = matchingFamily?.specMasterCategories?.find(x => x.id === productData?.categoryId);
  return (
    <Box sx={{marginTop: 4, marginBottom: 4, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <TextField value={productData?.name || ''} onChange={handleUpdateName} label={"Product Name"} />
      <InputLabel id="family-label" sx={{marginTop: 2}}>Family</InputLabel>
      <Select labelId="family-label" sx={{marginTop: 2}} value={productData?.productFamilyId || ''} onChange={handleFamilyUpdate}>
        {masterFamilies?.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
      </Select>
      <InputLabel id="category-label" sx={{marginTop: 2}}>Category</InputLabel>
      <Select labelId="category-label" sx={{marginTop: 2}} value={productData?.categoryId || ''} onChange={handleCategoryUpdate} disabled={!productData?.productFamilyId}>
        {matchingFamily ? matchingFamily.specMasterCategories?.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>) : null}
      </Select>
      <InputLabel id="subcategory-label" sx={{marginTop: 2}}>Subcategory</InputLabel>
      <Select labelId="subcategory-label" sx={{marginTop: 2}} value={productData?.subcategoryId || ''} onChange={handleSubcategoryUpdate} disabled={!productData?.categoryId}>
        {matchingCategory ? matchingCategory?.specMasterSubcategories?.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>) : null}
      </Select>
      <Box>
      <Button variant="contained" disabled={isLoading} onClick={handleSave}>Save</Button>
      <Button variant="outlined" disabled={isLoading} onClick={handleCancel}>Cancel</Button>
      </Box>
    </Box>
  );
};

export default EditMasterProductDetailsForm;
