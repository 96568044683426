import axios from 'axios';
const queryString = require('query-string');
import ReactGA from "react-ga";
import utilFunctions from '../utilities/utilFunctions';

export const identityConfig = {
    baseURL: '',
    accessToken: '',
};

export const exchangeUserToken = async (token, source) => {
    ReactGA.event({
        category: 'User',
        action: 'Login'
    });
    const params = {
        grant_type: 'exchange',
        iss: source,
        token: token,
        client_id: 'specmobile',
        client_secret: 'specmobile_2d07fd2a-5509-45f7-95c5-a3c0e54c133f',
        scopes: 'establishments'
    }

    const options = {
        baseURL: identityConfig.baseURL,
        headers: { "Content-Type": "application/x-www-form-urlencoded",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS, DELETE',
        'Access-Control-Allow-Headers': 'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type',
        'Accept': 'application/json' },
    }

    try {
        const response = await axios.post('/connect/token', queryString.stringify(params), options);
        identityConfig.accessToken = response.data.access_token;
        return response.data;
    } catch (err) {
        console.log(err);
        return { error: "Error while exchanging tokens" };
    }
}

export function getAccessToken() {
    return `Bearer ${identityConfig.accessToken}`;
}

export function getUserIdFromToken() {
    if (identityConfig.accessToken) {
        const tokenData = utilFunctions.parseJwt(identityConfig.accessToken);
        return tokenData.sub;
    }
}
