import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const getCardStyles = (selected) => ({
  borderRadius: "10%",
  border: "2px solid rgba(0, 0, 0, 0.12)",
  height: { xs: "75px", sm: "75px", md: "100px" },
  width: { xs: "75px", sm: "75px", md: "100px" },
  backgroundColor: (theme) =>
    selected ? theme.palette.secondary.main : theme.palette.pure.white,
});

const getTextStyles = (selected) => ({
  textAlign: "center",
  color: (theme) =>
    selected ? theme.palette.pure.white : theme.palette.pure.black,
  fontSize: { xs: 12, sm: 12, md: 20 },
});

const getIconStyles = (selected) => ({
  fontSize: { xs: 24, sm: 24, md: 40 },
  marginBottom: { xs: 1, sm: 1, md: 2 },
  color: (theme) =>
    selected ? theme.palette.pure.white : theme.palette.pure.black,
});

const DrinkTypeCard = ({ type, Icon, onClick, selected }) => {
  const cardStyles = getCardStyles(selected);
  const textStyles = getTextStyles(selected);
  const iconStyles = getIconStyles(selected);
  return (
    <Box
      sx={{
        height: { xs: "75px", sm: "75px", md: "100px" },
        width: { xs: "75px", sm: "75px", md: "100px" },
        cursor: "pointer",
      }}
    >
      <Card elevation={0} onClick={onClick} sx={cardStyles}>
        <CardContent
          sx={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Icon sx={iconStyles} />
          <Typography variant="largeButton" sx={textStyles}>
            {type}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DrinkTypeCard;
