import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import KegTransferRow from "./KegTransferRow";
import { useMutation } from "react-query";
import Store from "../../../Store/Store";
import inventoryClient from "../../../clients/inventoryClient";
import TotalCostBadge from "../components/TotalCostBadge";
import DescriptionToolTip from "../../../components/DescriptionToolTip";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  borderBottom: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const InvoiceKegCard = ({
  kegTransfers,
  updateCache,
  invoiceId,
  invalidLineItems,
}) => {
  const [expanded, setExpanded] = useState(true);

  const [kegTransfersCopy, setKegTransfersCopy] = useState([]);
  const store = useContext(Store);
  const useMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    setKegTransfersCopy([...kegTransfers]);
  }, [kegTransfers]);

  const addNewKegTransferMutation = useMutation(
    inventoryClient.addKegTransfer,
    {
      onSuccess: (result) => {
        const kegTransfers = [...kegTransfersCopy, result.kegTransfer];
        setKegTransfersCopy(kegTransfers);
        updateCache();
      },
    }
  );

  const handleUpdateKegTransferCache = () => {
    updateCache();
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card
      elevation={0}
      sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
    >
      {" "}
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <Grid item xs={12} sm={3}>
          <Typography variant="h3">Keg Transfers</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 2,
                  height: "40px",
                  border: "1px solid",
                  borderRadius: 1,
                  borderColor: (theme) => theme.palette.terrain[300],
                  backgroundColor: (theme) => theme.palette.terrain[50],
                }}
              >
                <TotalCostBadge
                  displayText="Keg Transfer Cost:"
                  lineItems={kegTransfersCopy}
                  isKeg
                />
              </Box>
              <Button
                sx={{marginLeft: 2}}
                disabled={addNewKegTransferMutation.isLoading}
                variant="contained"
                onClick={() => {
                  addNewKegTransferMutation.mutate({
                    establishmentId: store.currentEstablishment?.id,
                    invoiceId,
                  });
                }}
              >
                {useMobile ? null : <AddIcon sx={{ fontSize: 18, marginRight: 2 }} />}
                <Typography
                  variant="largeButton"
                  sx={{ fontSize: { xs: 12, md: 14 } }}
                >
                  Add Transfer
                </Typography>
              </Button>

          {useMobile ? null : (
            <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "36px",
              marginLeft: 2,
            }}
          >
            {!expanded ? (
              <IconButton
                sx={{
                  color: (theme) => theme.palette.pure.black,
                  height: "36px",
                }}
                onClick={handleExpandClick}
              >
                <KeyboardArrowDownOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  color: (theme) => theme.palette.pure.black,
                  height: "36px",
                }}
                onClick={handleExpandClick}
              >
                <ExpandLessOutlinedIcon />
              </IconButton>
            )}
          </Box>
          )}
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <TableContainer
          sx={{
            overflow: "scroll",
            borderRadius: 2,
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[400],
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead sx={{ height: "24px" }}>
              <TableRow sx={{ height: 24 }}>
                <TableCell
                  sx={[tableHeadStyle, { width: "44px", borderLeft: "none" }]}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box component="div" sx={{ marginRight: -2 }}></Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "160px !important" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Transfer Type
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Keg Deposit/Return"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              <Typography variant="body2">Deposit</Typography>
                              <Typography variant="body1">
                                The kegs are dropped off by the Vendor
                              </Typography>
                              <Typography variant="body2" sx={{ marginTop: 1 }}>
                                Return
                              </Typography>
                              <Typography variant="body1">
                                The kegs are given back to the Vendor.
                              </Typography>
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "620px !important" }]}>
                  Keg Transfer Name
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "80px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Quantity
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Quantity"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The number of kegs either deposited or returned.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "90px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Keg Cost
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Keg Cost"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The total cost of the entire keg deposit or
                              return.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "90px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Unit Cost
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Unit Cost"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The cost of the individual Keg deposit or return.
                              This is automatically calculated for you by
                              dividing the total cost by the quantity.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={[tableHeadStyle, { width: "56px", borderRight: "none" }]}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {kegTransfersCopy.map((x) => (
                <KegTransferRow
                  key={x.id}
                  kegTransfer={x}
                  updateKegTransferCache={handleUpdateKegTransferCache}
                  invoiceId={invoiceId}
                  invalidLineItems={invalidLineItems}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Card>
  );
};

export default InvoiceKegCard;
