import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { productSizeTypes } from "../../../clients/inventoryObjects";
import utilFunctions from "../../../utilities/utilFunctions";

const YieldToggle = ({ toggleYieldModalOpen, recipe }) => {
  if (
    recipe.yield?.find((x) => x.isDefaultSize)?.unitOfMeasurement === null &&
    recipe.yield?.find((x) => x.isDefaultSize)?.type !==
      productSizeTypes.Arbitrary
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: { xs: "54px", md: "124px" },
          height: "36px",
          borderRadius: 1,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
          backgroundColor: (theme) => theme.palette.pure.white,
        }}
      >
        <Button variant="text" onClick={() => toggleYieldModalOpen(true)}>
          <Typography
            variant="smallButton"
            sx={{
              fontSize: { xs: 10, md: 12 },
              lineHeight: { xs: 1, md: 1.5 },
            }}
          >
            + Set Yield
          </Typography>
        </Button>
      </Box>
    );
  }

  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", md: "space-between" },
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => toggleYieldModalOpen(true)}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: { xs: "center", md: "flex-start" },
        }}
      >
        Yield:
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          height: "20px",
          backgroundColor: (theme) => theme.palette.info[600],
          borderRadius: 1,
          padding: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "fit-content",
          marginLeft: 2,
          cursor: "pointer",
        }}
        onClick={() => toggleYieldModalOpen(true)}
      >
        <Typography
          variant="overline"
          sx={{
            color: (theme) => theme.palette.pure.white,
            fontWeight: 700,
            fontSize: { xs: 8, md: 10 },
            lineHeight: { xs: 1, md: 1.5 },
          }}
        >
          {utilFunctions.extractRecipeYieldSizeDisplay(recipe)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default YieldToggle;
