import React, { useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const DeleteExpenseGroupModal = ({
  handleDelete,
  isOpen,
  expenseGroupName,
  toggleModalOpen,
}) => {
  const [loading, toggleLoading] = useState(false);

  const closeModal = () => {
    toggleModalOpen(!isOpen);
    toggleLoading(false);
  };

  const handleDeleteCallback = async () => {
    toggleLoading(true);
    await handleDelete();
    toggleLoading(false);
    closeModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <DeleteOutlineOutlinedIcon sx={{ fontSize: "60px" }} />
              </Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h2">Delete Expense Group</Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  Are you sure you want to delete &quot;{expenseGroupName}&quot;?
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "center", marginTop: 1 }}
                >
                  By selecting yes, you will lose all information associated with this expense group. This will delete all expense subgroups under this expense group as well as remove the expense group and any subgroups from any associated products or invoices.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{ borderRadius: 2 }}
                  variant="outlined"
                  onClick={() => {
                    toggleModalOpen();
                  }}
                >
                  <Typography variant="smallButton">No, Cancel</Typography>
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ borderRadius: 2, marginLeft: 2 }}
                  onClick={handleDeleteCallback}
                >
                  <Typography variant="smallButton">Yes, Delete</Typography>
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default DeleteExpenseGroupModal;
