import React from "react";
import { modalBoxStyle } from "./styles";
import { CircularProgress, Box, Modal, Alert } from "@mui/material";

const MessageSuccessModal = ({
  isSendingMessage,
  showMessageModal,
  className,
  displaySuccessMessage,
  toggleMessageSuccessModal,
  error,
}) => {
  return (
    <Modal
      open={showMessageModal}
      onClose={toggleMessageSuccessModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={className}
    >
      {displaySuccessMessage ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Box
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="72px"
              viewBox="0 0 24 24"
              width="72px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
            </svg>
            <Box sx={{ fontSize: "24px", margin: "10px", fontWeight: "600" }}>
              Message Sent!
            </Box>
            <p>
              Your message has been sent to all users in this establishment.
            </p>
          </Box>
        </Box>
      ) : (
        <Box sx={modalBoxStyle}>
          {error && (
            <Alert severity="error" style={{ marginBottom: "1rem" }}>
              Something went wrong with sending your message. Please try again.
            </Alert>
          )}
          {isSendingMessage && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Box>
      )}
    </Modal>
  );
};

export default MessageSuccessModal;
