import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CountProductListTable from "./CountProductListTable";
import SpecBackButton from "../../components/SpecBackButton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import inventoryClient from "../../clients/inventoryClient";
import SpecSpinner from "../../components/SpecSpinner";
import useVendorCurrency from "../../hooks/useVendorCurrency";
import Store, { activeRouteKeys } from "../../Store/Store";
import utilFunctions from "../../utilities/utilFunctions";
import { inventoryCountTypesMap } from "../../clients/inventoryObjects";
import CreateMissingCostsModal from "../../components/Modals/CreateMissingCostsModal";
import MissingParLevelsModal from "../../components/Modals/MissingParLevelsModal";

const StockCountReview = () => {
  const [costsModalOpen, toggleCostsModalOpen] = useState(false);
  const [parsModalOpen, setParsModalOpen] = useState(false);
  const [recordForCostSetting, setRecordForCostSetting] = useState(null);
  const [searchParams] = useSearchParams();
  const store = useContext(Store);
  const navigate = useNavigate();
  const { establishmentid: establishmentId, stockcountid: stockCountId } =
    useParams();
  const { currencySymbol, currency, locale } = useVendorCurrency(
    store.user?.country
  );
  const { isLoading, data } = useQuery(
    ["stock-count", { establishmentId, stockCountId }],
    inventoryClient.getStockCountById
  );

  const handleCostsModalOpen = (record) => {
    setRecordForCostSetting(record);
    toggleCostsModalOpen(true);
  };

  const handleClostCostModal = () => {
    setRecordForCostSetting(null);
    toggleCostsModalOpen(false)
  }

  const handleParsModalOpen = () => {
    setParsModalOpen(!parsModalOpen);
  };

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.stockCount,
      `spec/${establishmentId}/count-review/${stockCountId}`
    );
  }, []);

  const showCongrats = searchParams.get("congrats") != null;

  if (isLoading) {
    return <SpecSpinner open text={"Loading Review..."} />;
  }

  const stockCount = data.stockCount;
  return (
    <>
      <Grid
        container
        direction="column"
        sx={{
          maxWidth: "2440px",
          padding: 4,
          marginTop: 6,
        }}
      >
        <Box>
          <SpecBackButton
            backText="Stock Count"
            onClick={() => navigate(`/spec/${establishmentId}/stockcount`)}
          />
        </Box>
        <Box sx={{ marginTop: 6 }}>
          <Typography variant="subtitle1">
            {inventoryCountTypesMap[stockCount.type]}
          </Typography>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h2">{stockCount.name}</Typography>
        </Box>
        {showCongrats ? (
          <Box>
            <Typography variant="h2" sx={{ marginTop: 6 }}>
              Congratulations!
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ marginTop: 6, fontWeight: 700 }}
            >
              You have successfully submitted the count {stockCount?.name}
            </Typography>
          </Box>
        ) : null}
        <Box
          sx={{
            width: "100%",
            height: "68px",
            display: "flex",
            alignItems: "center",
            marginTop: 6,
            backgroundColor: (theme) => theme.palette.pure.white,
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            borderRadius: 2,
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                Total Value on Hand:
              </Typography>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle2">
                {currencySymbol}
                {utilFunctions.convertCentsToLargerFormatCurrency(
                  stockCount.finalTotalValue?.amount || 0,
                  locale,
                  currency
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {/* <Box sx={{ marginTop: 6 }}>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <TextField
                fullWidth
                size="small"
                select
                autoComplete="off"
                sx={{
                  height: "34px",
                  width: "200px",
                  backgroundColor: (theme) => theme.palette.pure.white,
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
              >
                <MenuItem>Product Summary</MenuItem>
              </TextField>
              <Button
                variant="outlined"
                sx={{
                  marginLeft: 2,
                  border: "2px solid",
                  borderColor: (theme) => theme.palette.primary.main,
                }}
              >
                <TableViewOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                <Typography variant="largeButton">Group</Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  marginLeft: 2,
                  border: "2px solid",
                  borderColor: (theme) => theme.palette.primary.main,
                }}
              >
                <TuneOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                <Typography variant="largeButton">Filter</Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  marginLeft: 2,
                  border: "2px solid",
                  borderColor: (theme) => theme.palette.primary.main,
                }}
              >
                <FilterListOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                <Typography variant="largeButton">Sort</Typography>
              </Button>
            </Grid>
             <Grid item>
              <Button variant="contained">
                <Typography variant="largeButton">Order to Par</Typography>
              </Button>
            </Grid> 
          </Grid>
        </Box> */}
        <Box sx={{ marginTop: 6 }}>
          <CountProductListTable
            records={stockCount?.stockCountRecords}
            openRecordCostSetModal={handleCostsModalOpen}
            toggleParsModalOpen={handleParsModalOpen}
          />
        </Box>
        <CreateMissingCostsModal
          headerText="Missing Costs"
          buttonText="Save Details"
          isOpen={costsModalOpen}
          record={recordForCostSetting}
          closeModal={handleClostCostModal}
        />
        <MissingParLevelsModal
          headerText="Missing Par Levels"
          buttonText="Save Pars"
          isOpen={parsModalOpen}
          toggleModalOpen={handleParsModalOpen}
        />
      </Grid>
    </>
  );
};

export default StockCountReview;
