import React, { useContext } from "react";
import Store from "../../Store/Store";
import { Typography } from "@mui/material";
import MenusDisplay from "./components/MenusDisplay";
import { useParams } from "react-router-dom";
import SpecSpinner from "../../components/SpecSpinner";
import useEstablishment from "../../hooks/useEstablishment";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";

const Menus = () => {
  const store = useContext(Store);
  let { establishmentid } = useParams();
  const { isLoading: isLoadingEstablishment, error: isEstablishmentLoadError } =
    useEstablishment(establishmentid, store);
  const { isLoading: isLoadingSubscriptionData } =
    useEstablishmentSubscription(establishmentid);

  if (isLoadingEstablishment || isLoadingSubscriptionData) {
    return <SpecSpinner open text="Loading establishment information..." />;
  }

  if (isEstablishmentLoadError) {
    return (
      <Typography
        variant="h2"
        sx={{ color: (theme) => theme.palette.error.main }}
      >
        There was an error fetching your establishments. Please try refreshing.
      </Typography>
    );
  }

  return (
    <>
      <MenusDisplay />
    </>
  );
};

export default Menus;
