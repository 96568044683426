import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SpecBackButton from "../../../components/SpecBackButton";
import SportsBarOutlinedIcon from "@mui/icons-material/SportsBarOutlined";
import LocalBarOutlinedIcon from "@mui/icons-material/LocalBarOutlined";
import WineBarOutlinedIcon from "@mui/icons-material/WineBarOutlined";
import DrinkTypeCard from "./DrinkTypeCard";
import BeerDrinkForm from "../../../components/DrinkForm/BeerDrinkForm";
import WineDrinkForm from "../../../components/DrinkForm/WineDrinkForm";
import RecipeDrinkForm from "../../../components/DrinkForm/RecipeDrinkForm";

const AddNewDrinkForm = ({
  goBack,
  categoryId,
  addRecipeCallback,
  addWineCallback,
  addBeerCallback,
}) => {
  const [type, setType] = useState(undefined);

  const handleAddRecipe = (recipe) => {
    addRecipeCallback(recipe);
  };

  const handleAddWine = (wine) => {
    addWineCallback(wine);
  };

  const handleAddBeer = (beer) => {
    addBeerCallback(beer);
  };

  const renderFormByType = () => {
    switch (type) {
      case "recipe":
        return (
          <RecipeDrinkForm
            submitCallback={handleAddRecipe}
            isEmbedded
            goBack={goBack}
            categoryId={categoryId}
          />
        );
      case "wine":
        return (
          <WineDrinkForm
            submitCallback={handleAddWine}
            isEmbedded
            goBack={goBack}
            categoryId={categoryId}
          />
        );
      case "beer":
        return (
          <BeerDrinkForm
            submitCallback={handleAddBeer}
            isEmbedded
            goBack={goBack}
            categoryId={categoryId}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ maxWidth: "2440px", width: "100%" }}
      >
        <Box sx={{ marginLeft: -2, marginTop: 4 }}>
          <SpecBackButton onClick={() => goBack()} />
        </Box>
        <Box sx={{ marginBottom: 6, marginTop: 4 }}>
          <Typography variant="h2">Add New Drink</Typography>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h4">Select Drink Type</Typography>
        </Box>
        <Box>
          <Grid
            container
            direction="row"
            spacing={6}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Grid item>
              <DrinkTypeCard
                type="Recipe"
                Icon={LocalBarOutlinedIcon}
                onClick={() => setType("recipe")}
                selected={type === "recipe"}
              />
            </Grid>
            <Grid item>
              <DrinkTypeCard
                type="Wine"
                Icon={WineBarOutlinedIcon}
                onClick={() => setType("wine")}
                selected={type === "wine"}
              />
            </Grid>
            <Grid item>
              <DrinkTypeCard
                type="Beer"
                Icon={SportsBarOutlinedIcon}
                onClick={() => setType("beer")}
                selected={type === "beer"}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid
        sx={{
          backgroundColor: (theme) => theme.palette.terrain["04"],
          width: "100%",
          maxWidth: "2440px",
          marginTop: 6,
        }}
      >
        <Box>
          <Grid container direction="column" spacing={4}>
            {type && <Box>{renderFormByType()}</Box>}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default AddNewDrinkForm;
