import React, { useContext } from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import Store from "../../../Store/Store";
import utilFunctions from "../../../utilities/utilFunctions";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";

const tableCellStyle = {
  height: "60px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderTop: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const OrderDetailsRow = ({ lineItem }) => {
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  return (
    <TableRow sx={{ height: "60px" }}>
      <TableCell sx={[tableCellStyle, { width: "380px" }]}>
        <Typography variant="body2">{lineItem.productName}</Typography>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "120px" }]}>
        <Typography variant="body1">{`x${lineItem.orderAmount}`}</Typography>
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            width: "120px",
          },
        ]}
      >
        <Typography variant="body1">
          {lineItem.purchaseUnitName || ""}
        </Typography>
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            width: "120px",
          },
        ]}
      >
        <Typography variant="body1">{`${lineItem.unitSize || ""} ${
          lineItem.unitSizeAbbreviation || ""
        } `}</Typography>
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            width: "120px",
          },
        ]}
      >
        <Typography variant="body1">{lineItem.sku || ""}</Typography>
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            width: "120px",
          },
        ]}
      >
        <Typography variant="body1">
          {lineItem.estimatedCost !== null
            ? `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
                lineItem.estimatedCost.amount,
                locale,
                currency
              )}`
            : "N/A"}
        </Typography>
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            width: "56px",
          },
        ]}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton sx={{ margin: 0, padding: 0 }}>
            <MoreHorizOutlinedIcon
              sx={{ color: (theme) => theme.palette.primary[800] }}
            />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default OrderDetailsRow;
