import { Box, Checkbox, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import Store from "../../../../Store/Store";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import utilFunctions from "../../../../utilities/utilFunctions";
import useEstablishmentSettings from "../../../../hooks/useEstablishmentSettings";

const PurchaseUnitCard = ({
  updatePreview,
  handleSelect,
  excludeFromUpdatesMap,
}) => {
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  const handleCheckboxChange = () => {
    handleSelect(updatePreview.purchaseUnitId);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "95px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[400],
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.terrain[50],
        padding: 2,
        marginTop: 3,
      }}
    >
      <Box>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            margin: 0,
          }}
        >
          <Grid item>
            <Checkbox
              sx={{
                color: (theme) => theme.palette.primary[800],
                marginLeft: -2,
              }}
              checked={
                excludeFromUpdatesMap[updatePreview?.purchaseUnitId] === true
                  ? false
                  : true
              }
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item>
            <Box>
              <div
                style={{
                  maxWidth: "260px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography noWrap variant="body1">{`${
                  updatePreview.productName
                } ${updatePreview.unitSize || ""}${
                  updatePreview.unitSizeAbbreviation
                }`}</Typography>
              </div>
            </Box>
            <Box>
              <Typography variant="body1">
                {updatePreview.purchaseUnitName}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                borderRadius: 1,
                backgroundColor: (theme) => theme.palette.terrain[100],
                padding: 1,
              }}
            >
              <Box>
                <Typography variant="body2">Current Price:</Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  {currencySymbol}
                  {updatePreview?.currentPrice ? utilFunctions.convertCentsToLargerFormatCurrency(
                    updatePreview.currentPrice.amount,
                    locale,
                    currency
                  ) : '0.00'}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowForwardOutlinedIcon
              sx={{ color: (theme) => theme.palette.primary[800] }}
            />
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                borderRadius: 1,
                backgroundColor: (theme) => theme.palette.terrain[100],
                border: "1px solid",
                borderColor: (theme) => theme.palette.success[500],
                padding: 1,
              }}
            >
              <Box>
                <Typography variant="body2">Updated Price:</Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  {currencySymbol}
                  {updatePreview?.updatedPrice ? utilFunctions.convertCentsToLargerFormatCurrency(
                    updatePreview.updatedPrice.amount,
                    locale,
                    currency
                  ) : '0.00'}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PurchaseUnitCard;
