import React, { useCallback } from "react";
import { Box } from "@mui/material";
import ProductSizingCard from "./ProductSizingCard";
import UnitSizeModal from "../../../../components/Modals/UnitSizeModal";
import { useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CTABlock from "../../../../components/CTABlock";

const ProductSizingsTab = ({
  inventory,
  unitsOfMeasurement,
  updateProductCallback,
  toggleAddingProduct,
  searching,
  updateExpandedParent,
  refetchCurrentPageProducts,
}) => {
  const [unitSizeModalOpen, toggleUnitSizeModalOpen] = useState(false);
  const [unitSizeModalProduct, setUnitSizeModalProduct] = useState(null);
  const [unitSizeFormatId, setUnitSizeFormatId] = useState(null);

  const handleProductUnitSizeEdit = useCallback((product, formatId) => {
    toggleUnitSizeModalOpen(true);
    setUnitSizeModalProduct(product);
    setUnitSizeFormatId(formatId);
  }, []);

  const handleCloseUnitSizeModal = useCallback(() => {
    toggleUnitSizeModalOpen(false);
    setUnitSizeModalProduct(null);
    setUnitSizeFormatId(null);
  }, []);

  const handleSizeUpdateComplete = (product) => {
    updateProductCallback(product);
  }

  if (inventory.length === 0) {
    return searching ? (
      <CTABlock
        header="No Results Found"
        hideButton
        ButtonImage={AddOutlinedIcon}
      />
    ) : (
      <CTABlock
        header="Your Product List is Empty..."
        subheader="You have not added ay products yet. Get started now..."
        buttonText="Add Products"
        handleCTA={toggleAddingProduct}
        ButtonImage={AddOutlinedIcon}
      />
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
         <Box>
            {inventory.map((x) => (
              <ProductSizingCard
                product={x}
                key={x.id}
                unitsOfMeasurement={unitsOfMeasurement}
                updateProductCallback={updateProductCallback}
                handleProductUnitSizeEdit={handleProductUnitSizeEdit}
                updateExpandedParent={updateExpandedParent}
                refetchCurrentPageProducts={refetchCurrentPageProducts}
              />
            ))}
          </Box>
      </Box>
      <UnitSizeModal
        isOpen={unitSizeModalOpen}
        toggleModalOpen={handleCloseUnitSizeModal}
        updateProductCallback={updateProductCallback}
        product={unitSizeModalProduct}
        formatId={unitSizeFormatId}
        unitsOfMeasurement={unitsOfMeasurement}
        updateCallback={handleSizeUpdateComplete}
      />
    </Box>
  );
};

export default ProductSizingsTab;
