import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import AddDistributorCard from "./AddDistributorCard";
import DistributorFormFooter from "./DistributorFormFooter";
import DistributorFormHeader from "./DistributorFormHeader";
import DistributorStepper from "./DistributorStepper";
import CTABlock from "../../../components/CTABlock";
import Store from "../../../Store/Store";
var moment = require("moment-timezone");

const AddDistributorForm = ({
  addDistributors,
  toggleDeleteDistributorModalOpen,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [newDistributors, setNewDistributors] = useState([]);
  const [userTimezones, setUserTimezones] = useState([]);

  const store = useContext(Store);

  useEffect(() => {
    setUserTimezones(moment.tz.zonesForCountry(store.user.country || "us"));
  }, []);

  const addNewDistributor = (newDistributor) => {
    setNewDistributors([...newDistributors, newDistributor]);
  };

  const updateDistributor = (updatedDistributor) => {
    let newState = newDistributors.map((x) => {
      if (x.id === updatedDistributor.id) {
        return updatedDistributor;
      }
      return x;
    });
    setNewDistributors(newState);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSubmit = () => {
    addDistributors(newDistributors);
  };

  return (
    <Box sx={{ marginBottom: "80px" }}>
      <Grid
        container
        direction="row"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={12} md={12} lg={4} sx={{ marginTop: 4 }}>
          <Typography variant="h2" sx={{ fontSize: { xs: 20, sm: 28 } }}>
            Add Vendors
          </Typography>
        </Grid>
        <DistributorStepper activeStep={activeStep} />
      </Grid>

      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <DistributorFormHeader
            addNewDistributor={addNewDistributor}
            activeStep={activeStep}
          />
          <Box>
            {newDistributors.length === 0 ? (
              <CTABlock
                hideButton
                header="No Vendors Added"
                subheader="Add a vendor above to get started"
              />
            ) : (
              newDistributors.map((x) => (
                <AddDistributorCard
                  distributor={x}
                  step={activeStep}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  handleExpandClick={handleExpandClick}
                  key={x.id}
                  updateDistributor={updateDistributor}
                  timeZones={userTimezones}
                  toggleDeleteDistributorModalOpen={
                    toggleDeleteDistributorModalOpen
                  }
                />
              ))
            )}
          </Box>
        </Box>
      </>
      <DistributorFormFooter
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        newDistributors={newDistributors}
        submit={handleSubmit}
      />
    </Box>
  );
};

export default AddDistributorForm;
