import React, { useState, useEffect } from "react";
import {
  Fab,
  Avatar,
  Box,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

const InteractiveDisplay = ({
  imageSrc,
  error,
  loading,
  size,
  tableView,
  handleClick,
}) => {
  const [photoURL, setPhotoURL] = useState(null);

  const InteractiveDisplaySize =
    size === "small"
      ? {
          width: "48px",
          height: "48px",
        }
      : {
          width: "100px",
          height: "100px",
        };

  const InteractiveDisplayIconSize =
    size === "small"
      ? {
          fontSize: 20,
        }
      : {
          fontSize: 36,
        };

  useEffect(() => {
    if (imageSrc) {
      setPhotoURL(imageSrc);
    }
  }, [imageSrc]);

  if (loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {photoURL ? (
        <Box>
          <Box sx={[InteractiveDisplaySize, { position: "relative" }]}>
            <Box
              sx={[
                InteractiveDisplaySize,
                {
                  position: "absolute",
                  zIndex: 1,
                  borderRadius: "100%",
                  cursor: "pointer",
                },
              ]}
              onClick={handleClick}
            >
              <Box
                sx={{
                  "&:hover": {
                    transition: ".3s",
                    backgroundColor: "rgba(40,40,40,.70)",
                  },
                  display: "flex",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  height: "100%",
                  paddingLeft: size === "small" ? 2 : 8,
                  paddingRight: 2,
                  paddingTop: size === "small" ? 1.5 : 8,
                  paddingBottom: 1.5,
                  borderRadius: "100%",
                  zIndex: 2,
                  border: "2px solid",
                  borderColor: (theme) => theme.palette.terrain[400],
                  color: "#ffffff",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    margin: "auto",
                    "&:hover": {
                      opacity: 1,
                    },
                    opacity: 0,
                    height: "100%",
                    width: "100%",
                    marginTop: 1.5,
                    marginLeft: 1,
                  }}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
            <Avatar
              sx={[
                InteractiveDisplaySize,
                {
                  cursor: "pointer",
                  position: "absolute",
                },
              ]}
              src={photoURL}
              onClick={handleClick}
            />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box>
            {tableView ? (
              <Button
                sx={{
                  color: (theme) => theme.palette.primary[800],
                  textTransform: "none",
                }}
                onClick={handleClick}
              >
                <AddIcon
                  sx={{ fontSize: 14, marginRight: 1, marginBottom: 1 }}
                />
                <Typography variant="smallButton">Add Image</Typography>
              </Button>
            ) : (
              <Fab
                sx={[
                  InteractiveDisplaySize,
                  {
                    background: "#ffffff",
                    border: "3px solid",
                    borderColor: (theme) => theme.palette.terrain[400],
                    borderRadius: "100%",
                    boxShadow: "none",
                  },
                ]}
                aria-label="Upload"
                onClick={handleClick}
              >
                <AddPhotoAlternateOutlinedIcon
                  sx={InteractiveDisplayIconSize}
                />
              </Fab>
            )}
          </Box>
          {error && (
            <Typography
              variant="body1"
              sx={{ color: (theme) => theme.palette.error.main }}
            >
              {" "}
              {error}{" "}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

InteractiveDisplay.propTypes = {};

export default InteractiveDisplay;
