import React, { useState } from "react";
import { modalBoxStyle } from "./styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const ProductFamilyWarningModal = ({
  isOpen,
  toggleModalOpen,
  productCount
}) => {
  const [loading, toggleLoading] = useState(false);

  const closeModal = () => {
    toggleModalOpen(!isOpen);
    toggleLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ReportProblemIcon sx={{ fontSize: "60px" }} />
              </Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h3">Uh oh!</Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography>It looks like you still have {productCount} products with this product family. Please re-assign them in the product library before deleting.</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ borderRadius: 2, marginLeft: 2 }}
                  onClick={closeModal}
                >
                  <Typography variant="smallButton">Got it!</Typography>
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ProductFamilyWarningModal;
