import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useProductLibrary(establishmentId) {
    const queryReturnValues = useQuery(
        ["inventory", { establishmentId }],
        inventoryClient.getProductInventoryReactQuery,
        {
            staleTime: 1000 * 60 * 10
        }
    );

    if (queryReturnValues.isLoading) {
        return [queryReturnValues, {}];
    } else {
        const responseMap = {};
        queryReturnValues.data.products.forEach(x => responseMap[x.id] = x);
        return [queryReturnValues, responseMap];
    }
}

export default useProductLibrary;