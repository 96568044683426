import React, { useContext, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import SpecBackButton from "../../components/SpecBackButton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Store from "../../Store/Store";
import inventoryClient from "../../clients/inventoryClient";
import SpecSpinner from "../../components/SpecSpinner";
import { Link, useNavigate } from "react-router-dom";
import VendorOrderReviewCard from "./components/VendorOrderReviewCard";
import OrderReviewFooter from "./components/OrderReviewFooter";
import utilFunctions from "../../utilities/utilFunctions";
import VendorOrderCard from "../Ordering/components/VendorOrderCard";
import inventoryObjects from "../../clients/inventoryObjects";
import useVendorCurrency from "../../hooks/useVendorCurrency";
import useEstablishmentSettings from "../../hooks/useEstablishmentSettings";
import useVendors from "../../hooks/useVendors";

const NewOrderReview = () => {
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );
  const [savedOrders, setSavedOrders] = useState(null);
  const queryClient = useQueryClient();
  const [{ isLoading: isLoadingVendors }, vendorMap] = useVendors(
    store.currentEstablishment?.id
  );

  const establishmentId = store.currentEstablishment?.id;
  const navigate = useNavigate();

  const confirmOrdersMutation = useMutation(inventoryClient.confirmOrders, {
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: [
          "ordercart",
          { establishmentId: store.currentEstablishment?.id },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "ordercart-preview",
          { establishmentId: store.currentEstablishment?.id },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          "existing-orders",
          { establishmentId: store.currentEstablishment?.id },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "existing-orders",
          { establishmentId: store.currentEstablishment?.id },
        ],
      });
      setSavedOrders(result.pendingOrders);
    },
  });

  const markOrderAsPlacedMutation = useMutation(
    inventoryClient.markOrderAsPlaced,
    {
      onSuccess: (result, variables) => {
        const newSavedOrders = savedOrders.map((x) => {
          if (x.id === variables.orderId) {
            return Object.assign({}, x, {
              orderDate: result.orderedDate,
              orderStatus: inventoryObjects.OrderStatuses.ordered,
            });
          } else {
            return x;
          }
        });

        setSavedOrders(newSavedOrders);
      },
    }
  );

  const handlePlaceOrder = async (orderId) => {
    await markOrderAsPlacedMutation.mutateAsync({
      establishmentId: establishmentId,
      orderId: orderId,
    });
  };

  const { data, isLoading, isRefetching } = useQuery(
    ["ordercart-preview", { establishmentId }],
    inventoryClient.getCartOrderPreview,
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err) => {
        if (err.response?.status === 400 && savedOrders?.length > 0) {
          return;
        } else if (err.response?.status === 400) {
          queryClient.invalidateQueries({
            queryKey:["ordercart-preview", { establishmentId }],
          })
          queryClient.refetchQueries({
            queryKey:["ordercart-preview", { establishmentId }],
          })
          navigate(`/spec/${store.currentEstablishment?.id}/ordering`);
          return;
        }

        console.log(err);
      },
    }
  );

  const removeOrderItemMutation = useMutation(inventoryClient.removeCartItem, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey:["ordercart-preview", { establishmentId }],
      })
      queryClient.refetchQueries({
        queryKey:["ordercart-preview", { establishmentId }],
      })
      queryClient.invalidateQueries({
        queryKey: ["ordercart", { establishmentId }],
      })
      queryClient.refetchQueries({
        queryKey: ["ordercart", { establishmentId }],
      })
    },
  });

  const hanldleRemoveCartItem = async (orderLineItem) => {
    await removeOrderItemMutation.mutateAsync({
      establishmentId: orderLineItem.establishmentId,
      cartItemId: orderLineItem.cartItemId,
    });
  }

  const handleConfirmOrders = async () => {
    await confirmOrdersMutation.mutateAsync(establishmentId);
  };

  if (confirmOrdersMutation.isLoading) {
    return <SpecSpinner open text="Finalizing orders..." />;
  }

  if ((isLoading || isLoadingVendors) && !savedOrders) {
    return <SpecSpinner open text={"Getting your order preview ready..."} />;
  }

  if (!isLoading && !confirmOrdersMutation.isLoading && !data && !savedOrders) {
    navigate(`/spec/${store.currentEstablishment?.id}/ordering`);
    return null;
  }

  let productCount = 0;
  let totalEstimatedCost = 0;

  data.orders?.forEach((x) => {
    productCount += x.lineItems?.length || 0;
    totalEstimatedCost += x.estimatedCost?.amount || 0;
  });

  if (savedOrders) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 4,
          }}
        >
          <Grid
            container
            direction="column"
            sx={{ width: "100%", maxWidth: "2440px", padding: 6 }}
          >
            <Grid item sx={{ marginBottom: 6 }}>
              <Typography variant="h2">Your Orders</Typography>
            </Grid>
            <Grid item sx={{ marginBottom: 6 }}>
              <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
                Below are a list of your orders grouped by specific vendor.
                Directly build an order into your email, or copy and paste the
                content to your prefered or process of placing an order. These
                orders will now live seperately in the your order history list.{" "}
                <Link
                  style={{ color: "#C74822" }}
                  to={`/spec/${store.currentEstablishment?.id}/ordering`}
                >
                  Go To Order History.
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ padding: 4 }}>
            {savedOrders?.map((x, i) => (
              <VendorOrderCard
                order={x}
                key={x.id}
                currency={currency}
                currencyLocale={locale}
                currencySymbol={currencySymbol}
                renderTopMargin={i === 0 ? false : true}
                placeOrder={handlePlaceOrder}
                vendorMap={vendorMap}
              />
            ))}
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box>
          <Grid
            container
            direction="column"
            sx={{
              display: "flex",
              width: "100%",
              maxWidth: "2440px",
              padding: 2,
              height: "100%",
            }}
          >
            <Grid item sx={{ marginBottom: 6 }}>
              <SpecBackButton
                backText="Place New Order"
                onClick={() => {
                  queryClient.refetchQueries({
                    queryKey: ["ordercart", { establishmentId }],
                  });
                  navigate(
                    `/spec/${store.currentEstablishment?.id}/ordering/new`
                  );
                }}
              />
            </Grid>
            <Grid item sx={{ marginBottom: 6 }}>
              <Typography variant="h2">Review Your Order</Typography>
            </Grid>
            <Grid item sx={{ marginBottom: 6 }}>
              <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
                Before we create specific orders, please review and confirm your
                selections. Once you are happy to move forward, Spec will
                generate a message for you to send to each specific vendor.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginBottom: "120px",
            padding: 2,
          }}
        >
          {data.orders?.map((x, i) => (
            <VendorOrderReviewCard
              order={x}
              key={x.id}
              currency={currency}
              currencyLocale={locale}
              currencySymbol={currencySymbol}
              renderTopMargin={i === 0 ? false : true}
              deleteCartItem={hanldleRemoveCartItem}
              isDeletingCartItem={removeOrderItemMutation.isLoading || isRefetching}
            />
          ))}
        </Box>
      </Box>
      <OrderReviewFooter
        productCount={productCount}
        estimatedCost={`${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
          totalEstimatedCost,
          locale,
          currency
        )}`}
        submit={handleConfirmOrders}
      />
    </>
  );
};

export default NewOrderReview;
