import { Box, MenuItem, Select, Typography } from "@mui/material";
import React from "react";

const ShowOptions = ({
  reportTypeOptions,
  setReportType,
  reportTypeChoice,
}) => {
  const handleChange = (e) => {
    setReportType(e.target.value);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="h5">Show Top Ordered</Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "72px",
            width: "35%",
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.terrain[200],
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            padding: 2,
            marginTop: 2,
          }}
        >
          <Select
            value={reportTypeChoice}
            onChange={handleChange}
            sx={{
              width: "100%",
              height: "40px",
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
          >
            {reportTypeOptions.map((x) => (
              <MenuItem value={x.value} key={x.value}>
                {x.key}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default ShowOptions;
