import React from "react";
import { Box, Checkbox, Grid, ListItem, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CTABlock from "../../CTABlock";

const TagList = ({
  tags,
  searching,
  handleOptionCheckUncheck,
  emptyOptionsCTAText,
}) => {
  if (tags.length === 0) {
    return searching ? (
      <CTABlock
        header="No Results Found"
        hideButton
        ButtonImage={AddOutlinedIcon}
      />
    ) : (
      <CTABlock
        header={emptyOptionsCTAText}
        subheader=""
        hideButton
        ButtonImage={AddOutlinedIcon}
      />
    );
  }

  return (
    <Box sx={{ width: "100%", marginTop: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          {tags.map((t) => (
            <Box key={t.id}>
              <ListItem
                sx={{
                  backgroundColor: (theme) => theme.palette.pure.white,
                  height: "48px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 2,
                  borderBottom: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                }}
                // onClick={() => toggleSelected(!selected)}
              >
                <Grid container>
                  <Grid
                    container
                    direction="row"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: { xs: "200px" },
                            maxHeight: { xs: "20px" },
                          }}
                        >
                          {t.name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ dispaly: "flex", justifyContent: "flex-end" }}
                >
                  <Grid item>
                    <Checkbox
                      id={t.id}
                      onChange={handleOptionCheckUncheck}
                      checked={t.checked}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TagList;
