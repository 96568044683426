import { Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import utilFunctions from "../../../utilities/utilFunctions";
import UpdateConfirmModal from "../../../components/Modals/UpdateConfirmModal";

const MenuDisplayNameToggle = ({
  updateDisplayName,
  ingredientDisplayName,
  isNotAvailable,
}) => {
  const [editing, toggleEditing] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [updateConfirmModalOpen, toggleUpdateConfirmModalOpen] =
    useState(false);

  useEffect(() => {
    toggleEditing(false);
    setDisplayName(ingredientDisplayName);
  }, [ingredientDisplayName]);

  const handleUpdate = async () => {
    toggleEditing(false);
    await updateDisplayName(displayName);
  };

  // return a empty grid item since we have to embed the grid inside of here
  if (isNotAvailable) {
    return (
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40px",
          backgroundColor: (theme) => theme.palette.pure.white,
          borderColor: (theme) => theme.palette.terrain[400],
          borderRadius: 1,
          padding: 0,
        }}
      ></Grid>
    );
  }

  if (editing) {
    return (
        <TextField
          variant="filled"
          fullWidth
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            height: "100%",
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              height: "40px",
              paddingBottom: 4,
              paddingTop: -4,
            },
          }}
          InputProps={{ disableUnderline: true, height: "100%" }}
          placeholder="Menu Display Name"
          onChange={(e) => setDisplayName(e.target.value)}
          onBlur={handleUpdate}
          value={displayName}
          onKeyDown={utilFunctions.blurInput}
        />
    );
  }

  return (
    <>
      <Grid
        item
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: (theme) => theme.palette.pure.white,
          padding: 2,
        }}
      >
        <div
          style={{
            maxWidth: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="body1" noWrap>
            {displayName}
          </Typography>
        </div>
        <IconButton
          sx={{ backgroundColor: "transparent" }}
          onClick={() => toggleEditing(true)}
        >
          <EditIcon
            fontSize="small"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          />
        </IconButton>
      </Grid>
      <UpdateConfirmModal
        isOpen={updateConfirmModalOpen}
        toggleModalOpen={toggleUpdateConfirmModalOpen}
        handleUpdate={handleUpdate}
        headerText="Update Menu Display Name?"
        buttonText="Yes, Update"
        bodyText="Would you like to update the Menu Display Name to match the new ingredient selected?"
      />
    </>
  );
};

export default MenuDisplayNameToggle;
