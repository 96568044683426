import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { productSizeTypes } from "../../../clients/inventoryObjects";
import utilFunctions from "../../../utilities/utilFunctions";

const QuantityToggle = ({ openQuantityModal, ingredient }) => {
  if (
    ingredient.quantity?.unitOfMeasurement === null &&
    ingredient.quantity?.type !== productSizeTypes.Arbitrary
  ) {
    return (
      <Button
        variant="text"
        onClick={() => openQuantityModal(true, ingredient)}
      >
        <Typography variant="smallButton">+ Quantity</Typography>
      </Button>
    );
  }

  return (
    <Box
      sx={{
        height: "20px",
        backgroundColor: (theme) => theme.palette.info[600],
        borderRadius: 1,
        padding: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "fit-content",
        marginLeft: 2,
        cursor: "pointer",
      }}
      onClick={() => openQuantityModal(true, ingredient)}
    >
      <Typography
        variant="overline"
        noWrap
        sx={{
          color: (theme) => theme.palette.pure.white,
          fontWeight: 700,
        }}
      >
        {ingredient.quantity?.amount || ""}{" "}
        {utilFunctions.extractRecipeQuantityAbbreviation(ingredient.quantity)}
      </Typography>
    </Box>
  );
};

export default QuantityToggle;
