import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React, { useContext } from "react";
import OrderLinkIcon from "../../../../components/CustomIcons/OrderLinkIcon";
import UnlinkedIcon from "../../../../components/CustomIcons/UnlinkedIcon";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import Store from "../../../../Store/Store";
import utilFunctions from "../../../../utilities/utilFunctions";
import useEstablishmentSettings from "../../../../hooks/useEstablishmentSettings";

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
  fontWeight: 400,
};

const ApprovedProductRow = ({ lineItem, orderColorMap, expenseGroups }) => {
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { currencySymbol, currency, locale } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  const calculateUnitCost = () => {
    if (lineItem.amount === 0) {
      return 0;
    }

    if (lineItem.cost?.amount === 0) {
      return 0;
    }

    return lineItem.cost?.amount / lineItem.amount;
  };

  const extractExpenseGroupName = () => {
    const group = expenseGroups.find((x) => x.id === lineItem.expenseGroupId);

    return group?.name || "-";
  };

  const extractExpenseSubgroupName = () => {
    const subgroup = expenseGroups
      .find((x) => x.id === lineItem.expenseGroupId)
      ?.expenseSubgroups?.find((x) => x.id === lineItem.expenseSubgroupId);

    return subgroup?.name || "-";
  };

  return (
    <TableRow sx={{ height: 24 }}>
      <TableCell sx={[tableCellStyle, { width: "44px", maxWidth: "44px" }]}>
        <Box
          sx={[
            {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 2,
              width: "36px",
              height: "36px",
              backgroundColor: orderColorMap[lineItem.orderLinkId]?.primary,
            },
          ]}
        >
          <Box
            sx={[
              {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                height: "30px",
                width: "30px",
                backgroundColor: orderColorMap[lineItem.orderLinkId]?.secondary,
              },
            ]}
          >
            {lineItem.orderLinkId === null ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30px",
                  width: "30px",
                  backgroundColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 2,
                }}
              >
                <UnlinkedIcon />
              </Box>
            ) : (
              <OrderLinkIcon
                sx={{
                  color: (theme) => theme.palette.pure.black,
                  fontSize: 20,
                }}
              />
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "360px" }]}>
        <Box>
          <Typography variant="body2">{lineItem.productName}</Typography>
        </Box>
        <Box>
          <Typography variant="overline">
            {lineItem.unitSize} {lineItem.unitSizeAbbreviation || ""}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {lineItem.expenseGroupId ? extractExpenseGroupName() : "-"}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            {lineItem.expenseSubgroupId ? extractExpenseSubgroupName() : "-"}
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex" }}>{lineItem.purchaseUnitName}</Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex" }}>{lineItem.amount}</Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex" }}>
          {currencySymbol}
          {utilFunctions.convertCentsToLargerFormatCurrency(
            lineItem.cost?.amount,
            locale,
            currency
          )}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex" }}>
          {currencySymbol}
          {utilFunctions.convertCentsToLargerFormatCurrency(
            calculateUnitCost(),
            locale,
            currency
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ApprovedProductRow;
