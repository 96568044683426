import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DateRange from "./DateRange";
import AdditionalOptions from "./AdditionalOptions";
import ProductSelector from "./ProductSelector";
import SortBy from "./SortBy";
import { presetDateRangeOptions } from "../../../../../utilities/dateRangeUtils";
import { useMutation } from "react-query";
import inventoryClient from "../../../../../clients/inventoryClient";
import utilFunctions from "../../../../../utilities/utilFunctions";
import { invoiceReportTypes } from "../../../../../clients/inventoryObjects";
import ReportingFooter from "../../ReportingFooter";
import InsufficientReportDataModal from "../../../../../components/Modals/InsufficientReportDataModal";

const dateSelectionFormats = {
  presetOption: 0,
  customRange: 1,
};

const ProductPriceChanges = ({
  establishmentId,
  establishmentTimezone,
  setAlertSuccess,
  setErrorMessage,
  products,
  unitsOfMeasurement,
}) => {
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customStartDateDisplay, setCustomStartDateDisplay] = useState(null);
  const [customEndDateDisplay, setCustomEndDateDisplay] = useState(null);
  const [selectedPresetDateRange, setSelectedPresetDateRange] = useState(
    presetDateRangeOptions[0].value
  );
  const [dateSelectionFormat, setDateSelectionFormat] = useState(
    dateSelectionFormats.presetOption
  );
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVolumetricCostPer, setVolumetricCostPer] = useState(
    unitsOfMeasurement.find((x) => x.abbreviation === "fl oz")?.id
  );
  const [selectedMassCostPer, setMassCostPer] = useState(
    unitsOfMeasurement.find((x) => x.abbreviation === "oz")?.id
  );
  const [orderByAscending, toggleOrderByAscending] = useState(false);
  const [isEmptyResultModalOpen, toggleIsEmptyResultModalOpen] =
    useState(false);
  const [includeAverageRow, toggleIncludeAverageRow] = useState(false);

  const generateInvoiceReportMutation = useMutation(
    inventoryClient.generateInvoiceReport,
    {
      onSuccess: (response) => {
        if (response?.status === 204) {
          toggleIsEmptyResultModalOpen(true);
        } else {
          utilFunctions.downloadFile(
            response?.data?.report?.invoiceReportMetadata?.s3PresignedUrl
          );
          setAlertSuccess(
            "Report generated successfully, your download should have started."
          );
        }
      },
      onError: () => {
        setErrorMessage("Report generation failed!");
      },
    }
  );

  const handleGenerateReport = () => {
    const payload = {
      establishmentId: establishmentId,
      reportType: invoiceReportTypes.invoiceProductPriceChangesOverTime,
      invoiceProductPriceChangesOverTimeFilters: {
        productId: selectedProduct?.id,
        orderBy: orderByAscending ? 1 : 0,
        includeAverageRow,
        volumetricUnitForCostPer: unitsOfMeasurement.find(
          (x) => x.id === selectedVolumetricCostPer
        ),
        massUnitForCostPer: unitsOfMeasurement.find(
          (x) => x.id === selectedMassCostPer
        ),
      },
    };

    if (dateSelectionFormat == dateSelectionFormats.presetOption) {
      const dateRange = presetDateRangeOptions
        .find((x) => x.value === selectedPresetDateRange)
        .func(establishmentTimezone);
      payload.startDate = dateRange.start;
      payload.endDate = dateRange.end;
    } else {
      payload.startDate = customStartDate;
      payload.endDate = customEndDate;
    }

    generateInvoiceReportMutation.mutate(payload);
  };

  return (
    <>
      <Box>
        <Box sx={{ marginTop: 8 }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "fit-content",
                width: "70%",
                borderRadius: 2,
                backgroundColor: (theme) => theme.palette.terrain[200],
                border: "1px solid",
                borderColor: (theme) => theme.palette.terrain[300],
                padding: 2,
              }}
            >
              <Typography variant="body2">
                This report shows the change in price on an invoice for a single
                product over a specified time.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <DateRange
            customStartDate={customStartDate}
            setCustomStartDate={setCustomStartDate}
            customEndDate={customEndDate}
            setCustomEndDate={setCustomEndDate}
            selectedPresetDateRange={selectedPresetDateRange}
            setSelectedPresetDateRange={setSelectedPresetDateRange}
            dateSelectionFormat={dateSelectionFormat}
            setDateSelectionFormat={setDateSelectionFormat}
            dateSelectionFormats={dateSelectionFormats}
            presetDateRangeOptions={presetDateRangeOptions}
            establishmentTimezone={establishmentTimezone}
            customStartDateDisplay={customStartDateDisplay}
            setCustomStartDateDisplay={setCustomStartDateDisplay}
            customEndDateDisplay={customEndDateDisplay}
            setCustomEndDateDisplay={setCustomEndDateDisplay}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <ProductSelector
            products={products}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <AdditionalOptions
            unitsOfMeasurement={unitsOfMeasurement}
            selectedVolumetricCostPer={selectedVolumetricCostPer}
            selectedMassCostPer={selectedMassCostPer}
            setVolumetricCostPer={setVolumetricCostPer}
            setMassCostPer={setMassCostPer}
            includeAverageRow={includeAverageRow}
            toggleIncludeAverageRow={toggleIncludeAverageRow}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <SortBy
            orderByAscending={orderByAscending}
            toggleOrderByAscending={toggleOrderByAscending}
          />
        </Box>
      </Box>
      <ReportingFooter
        buttonText={"Create Report"}
        disableButton={
          selectedProduct === null || generateInvoiceReportMutation?.isLoading
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange && // if we selected custom range but didn't choose dates
              (!customEndDate || !customStartDate)
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange &&
              customEndDate < customStartDate // if the custom end date is before the start date
            ? true
            : false
        }
        submit={handleGenerateReport}
        isLoading={generateInvoiceReportMutation?.isLoading}
      />
      <InsufficientReportDataModal
        isOpen={isEmptyResultModalOpen}
        toggleModalOpen={toggleIsEmptyResultModalOpen}
        loading={false}
      />
    </>
  );
};

export default ProductPriceChanges;
