import React, { useContext } from "react";
import { Box, Button, Typography, Grid, styled } from "@mui/material";
import Store from "../../../../Store/Store";

const FooterGridWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 3,
  borderTop: "1px solid ",
  borderColor: theme.palette.terrain[400],
  backgroundColor: theme.palette.terrain[50],
  position: "fixed",
  bottom: 0,
  marginLeft: 4,
  marginRight: 0,
  width: "98%",
  maxWidth: "2440px",
  height: "80px",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: "100%",
    maxWidth: "2200px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  display: "flex",
  alignItems: "center",
}));

const AddMenuItemsFooter = ({ submit, handleBack, itemCount }) => {
  const { sideNavOpen } = useContext(Store);

  return (
    <FooterGridWrapper open={sideNavOpen}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "2440px",
          width: { xs: "100%", md: `calc(98% - 250px)` },
          marginLeft: { xs: 0, md: "120px" },
        }}
      >
        <Grid
          item
          sx={{
            paddingLeft: 8,
          }}
        >
          <Button
            variant="outlined"
            sx={{ height: "42px", width: "120px" }}
            onClick={handleBack}
          >
            <Typography variant="smallButton">Back</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Box sx={{ padding: 2 }}>
            <Button
              type="button"
              variant="contained"
              sx={{ width: "180px", height: "42px" }}
              onClick={submit}
              disabled={itemCount === 0}
            >
              <Typography variant="largeButton">Complete</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FooterGridWrapper>
  );
};

export default AddMenuItemsFooter;
