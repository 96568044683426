import React, { useContext, useEffect, useState } from "react";
import {
  getEstablishment,
  getEstablishmentCategories,
} from "../../clients/establishmentsClient";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { Alert, Snackbar, Grid } from "@mui/material";
import Store from "../../Store/Store";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import homebaseConstants from "../Homebase/constants";
import utilFunctions from "../../utilities/utilFunctions";
import SpecClassicCocktailDetails from "./components/SpecClassicCocktailDetails";
import CopyItemModal from "../../components/Modals/CopyItemModal";
import SpecClassicListItem from "./components/SpecClassicListItem";
import SpecSpinner from "../../components/SpecSpinner";

const SpecClassicCocktails = () => {
  const store = useContext(Store);
  const [specClassics, setSpecClassics] = useState(undefined);
  const [loading, toggleLoading] = useState(true);
  const [isError, toggleError] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [copyItemModalOpen, toggleCopyItemModalOpen] = useState(false);
  const [itemToCopy, setItemToCopy] = useState(undefined);
  let { menuid, establishmentid } = useParams();
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageContent, setErrorMessageContent] = useState("");
  const [successMessageContent, setSuccessMessageContent] = useState("");
  const [spinnerOpen, setSpinnerOpen] = useState(false);

  const handleAlertClose = () => {
    setAlertSuccess(false);
    setErrorMessage(false);
  };

  const handleCopyModal = (currentItem) => {
    setItemToCopy(currentItem);
    toggleCopyItemModalOpen(!copyItemModalOpen);
  };

  const copyCallback = async () => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! This cocktail has been copied.");
    toggleCopyItemModalOpen(false);
  };

  const errorCallback = (errorMessage) => {
    setErrorMessage(true);
    setErrorMessageContent(errorMessage);
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        toggleLoading(true);
        setSpinnerOpen(true);
        const classics = await getEstablishmentCategories(
          homebaseConstants.SpecEstId
        );
        const currentEstablishment = await getEstablishment(establishmentid);
        store.updateCurrentEstablishment(currentEstablishment);
        setSpecClassics(classics[0] !== undefined ? classics[0].items : []);
        toggleLoading(false);
        setSpinnerOpen(false);
      } catch (err) {
        console.log(err);
        toggleError(true);
        toggleLoading(false);
        setSpinnerOpen(false);
      }
    };

    fetchMenuData();
  }, []);

  const handleGoBackFromItem = () => {
    setCurrentItem(undefined);
  };

  const handleSetCurrentItem = (item) => {
    setCurrentItem(item);
  };

  if (loading) {
    return (
      <SpecSpinner open={spinnerOpen} text="Loading menu information..." />
    );
  }

  if (isError) {
    return (
      <Typography variant="h2" sx={{ color: "red" }}>
        There was an error fetching Spec Classics. Please try refreshing.
      </Typography>
    );
  }

  if (currentItem) {
    return (
      <SpecClassicCocktailDetails
        item={currentItem}
        goBack={handleGoBackFromItem}
      />
    );
  }

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ maxWidth: "2440px", padding: 4, marginTop: 6 }}
      >
        <Box>
          <Box>
            <Typography variant="h2">Spec Classic Cocktails</Typography>
          </Box>
          <Box sx={{ marginTop: 8 }}>
            <Typography variant="body1" sx={{ marginBottom: 6 }}>
              Spec provides a helpful list of classic cocktails, as well as some
              of our own personal niche favourites. Learn and share these
              recipes with friends, or maybe copy the recipe to your own menus
              as a starting point for any twists you may want to create.
            </Typography>
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <List sx={{ width: "100%" }}>
              {utilFunctions.sortByNameAlphabetically(specClassics).map((c) => (
                <>
                  <SpecClassicListItem
                    establishmentId={establishmentid}
                    menuItem={c}
                    menuId={menuid}
                    onItemSelect={handleSetCurrentItem}
                    copyModalCallback={handleCopyModal}
                  />
                </>
              ))}
            </List>
          </Box>
        </Box>
      </Grid>
      <CopyItemModal
        isOpen={copyItemModalOpen}
        toggleModalOpen={handleCopyModal}
        item={itemToCopy}
        copyCallback={copyCallback}
        errorCallback={errorCallback}
      />
      <Box>
        <Snackbar
          open={alertSuccess}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {successMessageContent}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessageContent}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default SpecClassicCocktails;
