import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useMenu(establishmentId, menuId) {
    const queryReturnValues = useQuery(
        ["menu", { establishmentId, menuId }],
        inventoryClient.getMenuDetails,
        {
          refetchOnWindowFocus: false,
          staleTime: 120 * 60 * 1000,
          cacheTime: 120 * 60 * 1000,
        }
    );

   return queryReturnValues;
}

export default useMenu;