import React from "react";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { DeleteOutlineOutlined } from "@mui/icons-material";

const ImageOptionsMenu = ({
  anchorEl,
  attachments,
  closeMenu,
  addCallback,
  viewCallback,
  deleteCallback,
  approvedInvoice,
}) => {
  const open = Boolean(anchorEl);

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
      {attachments &&
        attachments.map((a) => (
          <MenuItem key={a.id} sx={{ margin: "5px", maxWidth: "250px", minWidth: "250px", display: 'flex', flexDirection: 'row' }}>
            <Box
              sx={{
                maxWidth: "300px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography onClick={() => viewCallback(a.url)}>
                {a.displayName || "attachment"}
              </Typography>
            </Box>
            {!approvedInvoice && (
              <DeleteOutlineOutlined
                sx={{ color: "red", marginLeft: "auto" }}
                onClick={() => deleteCallback(a)}
              />
            )}
          </MenuItem>
        ))}
      {!approvedInvoice && (
        <MenuItem sx={{ margin: "5px" }} onClick={addCallback}>
          <AddOutlinedIcon sx={{ fontSize: 18, marginRight: 2 }} />
          Add
        </MenuItem>
      )}
    </Menu>
  );
};

export default ImageOptionsMenu;
