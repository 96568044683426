import {
  Box,
  // IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
// import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import inventoryObjects from "../../../clients/inventoryObjects";
var moment = require("moment");

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderTop: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
  fontWeight: 400,
};

const RecentlyUpdatedRow = ({ changeSet, staffMemberMap }) => {
  return (
    <TableRow sx={{ height: 24 }}>
      <TableCell sx={[tableCellStyle, { width: "360px" }]}>
        <Box>
          <Typography variant="body1">{changeSet.contentName}</Typography>
        </Box>
        <Box>
          <Typography variant="overline">{changeSet.contentTag}</Typography>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {inventoryObjects.changeSetTypes[changeSet.type]}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>{staffMemberMap[changeSet.userId] || "User Not Found"}</Box>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex" }}>
          {moment(changeSet.updateDate).format("hh:mm a")}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex" }}>
          {moment(changeSet.updateDate).format("MM-DD-YYYY")}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "48px" }]}>
        <Box sx={{ display: "flex" }}>
          {/* <IconButton>
            <ArrowForwardIosOutlinedIcon
              sx={{
                color: (theme) => theme.palette.primary[800],
                fontSize: 18,
              }}
            />{" "}
          </IconButton> */}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default RecentlyUpdatedRow;
