import React, { useContext, useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { inventoryCountTypesMap } from "../../../clients/inventoryObjects";
import { useNavigate, useParams } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import Store from "../../../Store/Store";
import DeleteStockCountModal from "../../../components/Modals/DeleteStockCountModal";

const moment = require("moment");

const CountDetailRow = ({ tableCellStyle, count }) => {
  const navigate = useNavigate();
  const { establishmentid: establishmentId } = useParams();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const open = Boolean(anchorEl);
  const store = useContext(Store);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDeleteModalOpen = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const deleteStockCountMutation = useMutation(
    inventoryClient.deleteStockCount,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "past-stockcounts",
            {
              establishmentId: store?.currentEstablishment.id,
            },
          ],
        });
        queryClient.refetchQueries({
          queryKey: [
            "past-stockcounts",
            {
              establishmentId: store?.currentEstablishment.id,
            },
          ],
        });
      },
    }
  );

  const handleDeleteStockCount = () => {
    try {
      const archivePayload = {
        establishmentId: store.currentEstablishment?.id,
        stockCountId: count.id,
      };

      deleteStockCountMutation.mutate(archivePayload);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <TableRow sx={{ height: 24, cursor: "pointer" }}>
        <TableCell
          sx={[tableCellStyle, { width: "420px" }]}
          onClick={() =>
            navigate(`/spec/${establishmentId}/count-review/${count.id}`)
          }
        >
          <Typography variant="body2">{count.name || "[No Name]"}</Typography>
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "118px" }]}>
          <Typography variant="body1">
            {inventoryCountTypesMap[count.type]}
          </Typography>
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "118px" }]}>
          <Typography variant="body1">
            {count.stockCountRecords?.length || 0}
          </Typography>
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "118px" }]}>
          <Typography variant="body1">
            {moment(count.updateDateTime).format("hh:mm a")}
          </Typography>
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "84px" }]}>
          <Typography variant="body1">
            {moment(count.createDateTime).format("MM-DD-YYYY")}
          </Typography>
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "48px" }]}>
          <IconButton
            sx={{ margin: 0, padding: 0 }}
            onClick={handleOptionClick}
          >
            <MoreHorizOutlinedIcon
              sx={{ color: (theme) => theme.palette.primary[800] }}
            />
          </IconButton>
        </TableCell>
      </TableRow>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* <MenuItem
          sx={{
            margin: "5px",
            "&:hover": {
              color: (theme) => theme.palette.pure.white,
              backgroundColor: (theme) => theme.palette.terrain[500],
            },
          }}
        >
          <EditIcon sx={{ marginRight: "5px" }} />
          Edit
        </MenuItem> */}
        <MenuItem
          onClick={toggleDeleteModalOpen}
          sx={{
            margin: "5px",
            "&:hover": {
              color: (theme) => theme.palette.pure.white,
              backgroundColor: (theme) => theme.palette.terrain[500],
            },
          }}
        >
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <DeleteStockCountModal
        deleteCallback={handleDeleteStockCount}
        isOpen={deleteModalOpen}
        toggleModalOpen={toggleDeleteModalOpen}
      />
    </>
  );
};

export default CountDetailRow;
