import React from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const RepCard = ({ contact, showCtas, deleteVendorContact }) => {
  const handleOpenEmail = () => {
    const email = contact.email;
    const mailto_link =
      "mailto:" +
      email +
      "?subject=" +
      encodeURIComponent("") +
      "&body=" +
      encodeURIComponent("");
    document.location.href = mailto_link;
  };

  return (
    <Grid
      container
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 4,
      }}
    >
      <Grid item xs={8}>
        <Grid
          container
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={1}>
            <Avatar
              sx={{ height: { xs: 20, md: 32 }, width: { xs: 20, md: 32 } }}
            />
          </Grid>
          <Grid item xs={11}>
            <Box sx={{ marginLeft: { xs: 2, md: 0 } }}>
              <Typography variant="h5">{contact.name}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Grid container direction="row">
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">Contact Number: </Typography>
                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                  {contact.phoneNumber}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">Email Address: </Typography>
                <Typography variant="body1" sx={{ marginLeft: 1 }}>
                  {contact.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {showCtas ? (
          <>
            <Grid item>
              <EmailOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={handleOpenEmail}
              />
            </Grid>
            <Grid item sx={{ marginLeft: 4 }}>
              <LocalPhoneOutlinedIcon />
            </Grid>
            <Grid>
              <DeleteOutlineIcon
                sx={{ color: "red", marginLeft: 4, cursor: "pointer" }}
                onClick={() => deleteVendorContact(contact.id)}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default RepCard;
