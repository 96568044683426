import React, { useContext, useState } from "react";
import { Alert, Box, Button, Grid, Snackbar, Typography } from "@mui/material";

import Store from "../../../Store/Store";
import CTABlock from "../../../components/CTABlock";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SpecSpinner from "../../../components/SpecSpinner";
import { useNavigate } from "react-router-dom";
import SpecBackButton from "../../../components/SpecBackButton";
import CreateExpenseGroupOrganizationModal from "../../../components/Modals/CreateExpenseGroupOrganizationModal.js";
import ExpenseGroupCard from "./ExpenseGroupsCard.js";
import useEstablishmentExpenseGroups from "../../../hooks/useEstablishmentExpenseGroups.js";
import inventoryClient from "../../../clients/inventoryClient.js";
import { useQueryClient } from "react-query";

const ExpenseGroups = () => {
  const [addingExpenseGroup, toggleAddingExpenseGroup] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const store = useContext(Store);
  const {
    data: expenseGroupData,
    isLoading: groupsLoading,
    isError: error,
  } = useEstablishmentExpenseGroups(store.currentEstablishment?.id);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleAddExpenseGroup = async (expenseGroupName) => {
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      name: expenseGroupName,
    };

    await inventoryClient.addExpenseGroup(payload);

    queryClient.invalidateQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
  };

  if (groupsLoading) {
    return (
      <SpecSpinner
        open={groupsLoading}
        text={"Loading expense group data..."}
      />
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          maxWidth: "2440px",
          padding: 4,
          marginTop: 6,
        }}
      >
        {error && (
          <Typography variant="h6" sx={{ color: "red" }}>
            There was an error expense group data, please refresh
          </Typography>
        )}
        <>
          <Grid item sx={{ marginLeft: -2 }}>
            <SpecBackButton
              onClick={() =>
                navigate(`/spec/${store.currentEstablishment?.id}/organization`)
              }
              backText="Organization"
            />
          </Grid>
          <Grid
            container
            sx={{
              maxWidth: "2440px",
              marginBottom: 6,
            }}
          >
            <Grid item xs={9}>
              <Typography variant="h2">Expense Groups</Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 2,
                  padding: 4,
                  justifySelf: "flex-end",
                  height: "40px",
                }}
                onClick={() => toggleAddingExpenseGroup(true)}
              >
                <AddOutlinedIcon
                  sx={{ marginRight: 1, fontSize: { xs: 14, md: 20 } }}
                />
                <Typography
                  variant="smallButton"
                  sx={{
                    fontSize: { xs: "8px", md: "12px" },
                    lineHeight: { xs: 1 },
                  }}
                >
                  Create Expense Group
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid container sx={{ marginBottom: 2, width: "100%" }}>
            <Grid item xs={12}>
              {expenseGroupData &&
              expenseGroupData?.expenseGroups?.length > 0 ? (
                expenseGroupData.expenseGroups.map((e) => (
                  <ExpenseGroupCard key={e.id} expenseGroup={e} />
                ))
              ) : (
                <CTABlock
                  header={"You haven't added any expense groups"}
                  subheader={"Add expense groups to organize your inventory"}
                  hideButton
                />
              )}
            </Grid>
          </Grid>
        </>
      </Grid>
      <CreateExpenseGroupOrganizationModal
        isOpen={addingExpenseGroup}
        toggleModalOpen={toggleAddingExpenseGroup}
        modalHeader="Create Expense Group"
        modalText="Expense groups are top level categories where money is spent through the establishment (e.g. Bar Spends)"
        placeholderText="Enter Expense Group Name"
        createExpenseGroup={handleAddExpenseGroup}
        buttonText="Create Expense Group"
      />
      <Box>
        <Snackbar
          open={alertSuccess ? true : false}
          onClose={() => setAlertSuccess(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {alertSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage ? true : false}
          onClose={() => setErrorMessage(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default ExpenseGroups;
