import React, { useContext } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { modalBoxStyle } from "../styles";
import LogoStampWhite from "../../../assets/img/LogoStampWhite.png";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LiquorOutlinedIcon from "@mui/icons-material/LiquorOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import LocalBarOutlinedIcon from "@mui/icons-material/LocalBarOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate, useParams } from "react-router-dom";
import Store from "../../../Store/Store";

const SubscriptionSuccessModal = ({ handleModalToggle, isOpen }) => {
  const CenteredContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const store = useContext(Store);
  const { establishmentid } = useParams();
  const navigate = useNavigate();

  const iconSize = {
    fontSize: 28,
    color: (theme) => theme.palette.primary[700],
    marginBottom: 1,
  };

  const InventoryComponents = [
    {
      text: "Product Library",
      route:
        store.activeRoutes?.productLibrary ||
        `/spec/${establishmentid}/productlibrary`,
      icon: <LiquorOutlinedIcon sx={iconSize} />,
    },
    {
      text: "Organization",
      route:
        store.activeRoutes?.areas || `/spec/${establishmentid}/organization`,
      icon: <AccountTreeOutlinedIcon sx={iconSize} />,
    },
    {
      text: "Vendors",
      route: store.activeRoutes?.vendors || `/spec/${establishmentid}/vendors`,
      icon: <LocalShippingOutlinedIcon sx={iconSize} />,
    },
    {
      text: "Ordering",
      route:
        store.activeRoutes?.ordering || `/spec/${establishmentid}/ordering`,
      icon: <ShoppingCartIcon sx={iconSize} />,
    },
    {
      text: "Invoices",
      route:
        store.activeRoutes?.invoices || `/spec/${establishmentid}/invoices`,
      icon: <ReceiptLongOutlinedIcon sx={iconSize} />,
    },
    {
      text: "Stock Count",
      route:
        store.activeRoutes?.stockCount || `/spec/${establishmentid}/stockcount`,
      icon: <InsertChartOutlinedIcon sx={iconSize} />,
    },
    {
      text: "Menus",
      route: store.activeRoutes?.menu || `/spec/${establishmentid}/menus`,
      icon: <MenuBookOutlinedIcon sx={iconSize} />,
    },
    {
      text: "Recipes",
      route: store.activeRoutes?.recipes || `/spec/${establishmentid}/recipes`,
      icon: <LocalBarOutlinedIcon sx={iconSize} />,
    },
  ];

  const InventoryComponentCard = ({ component }) => {
    const handleNavigate = () => {
      navigate(component.route);
    }
    return (
      <Box
      onClick={handleNavigate}
        sx={{
          width: "30%",
          height: "80px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
          borderRadius: 2,
          margin: 2,
          cursor: "pointer",
        }}
      >
        {component.icon}
        <Typography variant="h5">{component.text}</Typography>
      </Box>
    );
  };

  const renderFooter = () => {
    return (
      <Box sx={{ display: "flex", marginTop: "1rem" }}>
        <Button
          variant="contained"
          onClick={handleModalToggle}
          sx={{
            border: "none",
            borderRadius: 2,
            marginTop: "20px",
            padding: "8px 60px",
          }}
        >
          <Typography variant="smallButton">Continue</Typography>
        </Button>
      </Box>
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleModalToggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[modalBoxStyle, { padding: 0 }]}>
        <Box sx={{ height: "500px", overflow: "scroll" }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                height: "135px",
                backgroundColor: (theme) => theme.palette.success[600],
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
              }}
            >
              <Box>
                <Avatar src={LogoStampWhite} />
              </Box>
              <Typography variant="h3">
                Officially a Pro Establishment!
              </Typography>
              <Typography variant="body1">
                Now you can get started on setting up all the cool features we
                offer...
              </Typography>
            </Box>
            <Box sx={{ padding: 4 }}>
              <Typography variant="h2">Get Started</Typography>
              <Typography variant="body1">
                We recommend populating your Product Library with Product
                Formats and Purchase Units first, but get started in any way you
                feel best.
              </Typography>
            </Box>
            <Box sx={{ padding: 2 }}>
              <Grid container>
                {InventoryComponents.map((x) => (
                  <InventoryComponentCard component={x} key={x.text} />
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
        {/* MODAL FOOTER */}
        <Divider />
        <Box sx={[CenteredContainerStyles, { padding: 4, paddingTop: 0 }]}>
          {renderFooter()}
        </Box>
      </Box>
    </Modal>
  );
};

export default SubscriptionSuccessModal;
