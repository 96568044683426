import { Grid, Typography } from "@mui/material";
import React from "react";
import GenericErrorIllustration from "../CustomIcons/GenericErrorIllustration";
// import GenericErrorIllustration from "../../assets/img/GenericErrorIllustration.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid
          item
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          xs={12}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* <img src={GenericErrorIllustration} height="320px" width="520px" /> */}
          <GenericErrorIllustration sx={{ fontSize: 340 }} />
          <Typography
            variant="h2"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            {"Something's gone wrong"}
          </Typography>
          <Typography variant="subtitle2">
            It seems there is an error loading this page. Try closing and
            restarting the app to fix it.
          </Typography>
          <Typography variant="subtitle2">
            If the issue persists please reach out to our support team:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            support@specapp.com
          </Typography>
        </Grid>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
