import React, { useContext } from "react";
import { Box, Button, Typography, Grid, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Store from "../../../Store/Store";

const FooterGridWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  borderTop: "1px solid",
  borderColor: theme.palette.terrain[400],
  backgroundColor: theme.palette.terrain[50],
  position: "fixed",
  bottom: 0,
  marginLeft: -35,
  marginRight: 0,
  width: "100%",
  maxWidth: "2440px",
  height: "80px",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - 200px)`,
    maxWidth: "2200px",
    marginRight: 0,
    marginLeft: -90,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  display: "flex",
  alignItems: "center",
  zIndex: 1,
}));

const ReportingFooter = ({ submit, buttonText, isLoading, disableButton }) => {
  const { sideNavOpen } = useContext(Store);

  return (
    <FooterGridWrapper open={sideNavOpen}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          maxWidth: "2440px",
          paddingRight: 4,
        }}
      >
        <Grid item>
          <Box sx={{ padding: 2 }}>
            <Button
              type="button"
              variant="contained"
              sx={{
                width: "148px",
                height: "42px",
                backgroundColor: (theme) =>
                  buttonText === "Complete"
                    ? theme.palette.success[600]
                    : theme.palette.primary[800],
              }}
              onClick={submit}
              disabled={isLoading || disableButton}
            >
              <Typography variant="largeButton">
                {isLoading ? <CircularProgress color="inherit" /> : buttonText}
              </Typography>{" "}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FooterGridWrapper>
  );
};

export default ReportingFooter;
