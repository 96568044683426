import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DateRange from "./DateRange";
import ShowOptions from "./ShowOptions";
import TopProducts from "./TopLimitControl";
import SortBy from "./SortBy";
import {
  orderReportOrderByTypes,
  orderReportTypes,
} from "../../../../clients/inventoryObjects";
import ReportingFooter from "../ReportingFooter";
import { useMutation } from "react-query";
import inventoryClient from "../../../../clients/inventoryClient";
import utilFunctions from "../../../../utilities/utilFunctions";
import { presetDateRangeOptions } from "../../../../utilities/dateRangeUtils";
import InsufficientReportDataModal from "../../../../components/Modals/InsufficientReportDataModal";

const reportTypeOptions = [
  { key: "Products", value: orderReportTypes.topProduct },
  { key: "Product Formats", value: orderReportTypes.topProductFormat },
  { key: "Vendors", value: orderReportTypes.topVendor },
  { key: "Families", value: orderReportTypes.topFamily },
  { key: "Categories", value: orderReportTypes.topCategory },
  { key: "Subcategories", value: orderReportTypes.topSubcategory },
  { key: "Expense Groups", value: orderReportTypes.topExpenseGroup },
  {
    key: "Expense Subgroups",
    value: orderReportTypes.topExpenseSubgroup,
  },
];

const dateSelectionFormats = {
  presetOption: 0,
  customRange: 1,
};

const OrderingReport = ({
  establishmentSettings,
  setAlertSuccess,
  setErrorMessage,
  establishmentId,
}) => {
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [selectedPresetDateRange, setSelectedPresetDateRange] = useState(
    presetDateRangeOptions[0].value
  );
  const [reportType, setReportType] = useState(reportTypeOptions[0].value);
  const [dateSelectionFormat, setDateSelectionFormat] = useState(
    dateSelectionFormats.presetOption
  );
  const [sortBy, setSortBy] = useState(orderReportOrderByTypes.orderTotal);
  const [topLimit, setTopLimit] = useState(null);
  const [useTopLimit, toggleUseTopLimit] = useState(false);
  const [isEmptyResultModalOpen, toggleIsEmptyResultModalOpen] = useState(false);

  const generateTopOrderMutation = useMutation(
    inventoryClient.generateTopOrderReport,
    {
      onSuccess: (response) => {
        if (response.status === 204) {
          toggleIsEmptyResultModalOpen(true);
        } else {
          utilFunctions.downloadFile(
            response?.data?.report?.orderReportMetadata?.s3PresignedUrl
          );
          setAlertSuccess(
            "Report generated successfully, your download should have started."
          );
        }
      },
      onError: () => {
        setErrorMessage("Report generation failed!");
      },
    }
  );

  const handleGenerateReport = () => {
    const payload = {
      establishmentId: establishmentId,
      topLimit: useTopLimit ? topLimit : -1,
      reportType: reportType,
      orderBy: sortBy,
    };

    if (dateSelectionFormat == dateSelectionFormats.presetOption) {
      const dateRange = presetDateRangeOptions
        .find((x) => x.value === selectedPresetDateRange)
        .func(establishmentSettings?.inventorySettings.timeZone);
      payload.startDate = dateRange.start;
      payload.endDate = dateRange.end;
    } else {
      payload.startDate = customStartDate;
      payload.endDate = customEndDate;
    }

    generateTopOrderMutation.mutate(payload);
  };

  return (
    <>
      <Box>
        <Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "fit-content",
                width: "70%",
                borderRadius: 2,
                backgroundColor: (theme) => theme.palette.terrain[200],
                border: "1px solid",
                borderColor: (theme) => theme.palette.terrain[300],
                padding: 2,
              }}
            >
              <Typography variant="body2">
                This report allows for various grouping options to allow for
                insights to how much of various items you are ordering. All
                order totals here are estimates based on purchase unit prices
                set at the time of the order.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <ShowOptions
            reportTypeOptions={reportTypeOptions}
            setReportType={setReportType}
            reportTypeChoice={reportType}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <DateRange
            customStartDate={customStartDate}
            setCustomStartDate={setCustomStartDate}
            customEndDate={customEndDate}
            setCustomEndDate={setCustomEndDate}
            selectedPresetDateRange={selectedPresetDateRange}
            setSelectedPresetDateRange={setSelectedPresetDateRange}
            dateSelectionFormat={dateSelectionFormat}
            setDateSelectionFormat={setDateSelectionFormat}
            dateSelectionFormats={dateSelectionFormats}
            presetDateRangeOptions={presetDateRangeOptions}
            establishmentTimezone={
              establishmentSettings?.inventorySettings.timeZone
            }
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <TopProducts
            topLimit={topLimit}
            setTopLimit={setTopLimit}
            useTopLimit={useTopLimit}
            toggleTopLimit={toggleUseTopLimit}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <SortBy setSortBy={setSortBy} sortByChoice={sortBy} />
        </Box>
      </Box>
      <ReportingFooter
        buttonText={"Create Report"}
        disableButton={
          useTopLimit && topLimit <= 0 // if we haven't set a top limit but checked the box
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange && // if we selected custom range but didn't choose dates
              (!customEndDate || !customStartDate)
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange &&
              customEndDate < customStartDate // if the custom end date is before the start date
            ? true
            : false
        }
        submit={handleGenerateReport}
        isLoading={generateTopOrderMutation?.isLoading}
      />
      <InsufficientReportDataModal isOpen={isEmptyResultModalOpen} toggleModalOpen={toggleIsEmptyResultModalOpen} loading={false} />
    </>
  );
};

export default OrderingReport;
