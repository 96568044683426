import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";

const ReportingHeader = ({ reportType, handleReportType }) => {
  const toggleButtonStyle = {
    borderTopRightRadius: "8px !important",
    borderBottomRightRadius: "8px !important",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    border: "none",
    backgroundColor: (theme) => theme.palette.terrain[200],
    "&.Mui-selected, &.Mui-selected:hover": {
      color: (theme) => theme.palette.pure.white,
      backgroundColor: (theme) => theme.palette.terrain[900],
    },
  };
  return (
    <Box>
      <Box>
        <Typography variant="h5">Generate Report From</Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        {/* <Button variant="contained" sx={{ marginRight: 1, borderRadius: 2 }}>
          Product Library
        </Button> */}
        <ToggleButtonGroup
          value={reportType}
          onChange={handleReportType}
          exclusive
        >
          {/* <ToggleButton
            value="Product Library"
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Product Library
            </Typography>
          </ToggleButton> */}
          <ToggleButton
            value="Ordering"
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Ordering
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="Invoices"
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Invoices
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="Stock Counts"
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Stock Counts
            </Typography>
          </ToggleButton>
          {/* <ToggleButton
            value="Recipes"
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Recipes
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="Menus"
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Menus
            </Typography>
          </ToggleButton> */}
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default ReportingHeader;
