import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography, Grid, styled, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Store from "../../../Store/Store";

const FooterGridWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, isSmallScreen }) => ({
  borderTop: "1px solid",
  borderColor: theme.palette.terrain[400],
  backgroundColor: theme.palette.terrain[50],
  position: "fixed",
  bottom: 0,
  left: 0, // Ensure the footer starts from the left edge
  right: 0, // Ensure the footer ends at the right edge
  margin: 0,
  width: "100%",
  height: isSmallScreen ? "120px" : "80px",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  display: "flex",
  alignItems: "center",
}));

const OrderReviewFooter = ({ submit, productCount, estimatedCost }) => {
  const [loading, toggleLoading] = useState(false);
  const { sideNavOpen } = useContext(Store);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    toggleLoading(false);
  });

  return (
    <FooterGridWrapper open={sideNavOpen} isSmallScreen={isSmallScreen}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: { xs: 2, sm: 3, md: 4 },
        }}
      >
        {/* Left Section: Products and Total Cost */}
        <Grid
          item
          sm={9}
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row", // Stack on small screens
            alignItems: isSmallScreen ? "flex-start" : "center",
            paddingLeft: { xs: 2, md: sideNavOpen ? 62 : 12 },
            gap: isSmallScreen ? 1 : 2, // Add spacing between items
          }}
        >
          {/* Products */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "row" : "inherit",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.terrain[500],
                marginRight: isSmallScreen ? 1 : 0,
              }}
            >
              Products:
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.pure.black,
              }}
            >
              {productCount}
            </Typography>
          </Box>

          {/* Estimated Total Cost */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "row" : "inherit",
              alignItems: "center",
              marginTop: isSmallScreen ? 1 : 0, // Add spacing when stacked
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.terrain[500],
                marginRight: isSmallScreen ? 1 : 0,
              }}
            >
              Estimated Total:
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.pure.black,
              }}
            >
              {estimatedCost}
            </Typography>
          </Box>
        </Grid>

        {/* Right Section: Confirm Button */}
        <Grid
          item
          sm={3}
          sx={{
            display: "flex",
            justifyContent: isSmallScreen ? "flex-start" : "flex-end", // Stick to the right on larger screens
            paddingRight: { xs: 2, md: 4 },
            marginTop: isSmallScreen ? 2 : 0, // Add spacing when stacked
          }}
        >
          <Box>
            <Button
              type="button"
              variant="contained"
              sx={{
                width: { xs: "140px", md: "180px" },
                height: "42px",
              }}
              onClick={submit}
            >
              <Typography variant="largeButton">
                {loading ? <CircularProgress color="inherit" /> : "Confirm"}
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FooterGridWrapper>
  );
};

export default OrderReviewFooter;
