import { useInfiniteQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function usePastStockCountsInfiniteScroll(establishmentId) {
  const queryReturnValues = useInfiniteQuery(
    ["past-stockcounts-infinite", { establishmentId }],
    ({ pageParam = 1 }) => inventoryClient.getPastStockCountsForEstablishmentInfiniteScroll(establishmentId, pageParam),
    {
      enabled: true,
      staleTime: 1000 * 60 * 5,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        return currentPage < lastPage.totalPages ? currentPage + 1 : undefined;
      },
    }
  );

  return queryReturnValues;
}

export default usePastStockCountsInfiniteScroll;
