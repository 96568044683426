import {
  Alert,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CreateAreaModal from "../../../components/Modals/CreateAreaModal.js";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import inventoryClient from "../../../clients/inventoryClient.js";
import { useParams } from "react-router-dom";
import SpecSpinner from "../../../components/SpecSpinner/index.js";
import SpecBackButton from "../../../components/SpecBackButton/index.js";
import StockCountFooter from "./StockCountFooter.js";
import AreaCountList from "./AreaCountList.js";
import SectionPill from "../../AreaDetails/components/SectionPill.js";
import useAreaDetails from "../../../hooks/useAreaDetails.js";
import AddProductToCountModal from "../../../components/Modals/AddProductToCountModal/index.js";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ForceAreaCountCompletionModal from "../../../components/Modals/ForceAreaCountCompletion.js/index.js";

const AreaCountDetails = ({
  areaId,
  areaCountId,
  goBackToAreaList,
  completeCallback,
  stockCountId,
  stockCountName,
}) => {
  const [createAreaModalOpen, toggleCreateAreaModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isMobileMode, toggleMobileMode] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [isAddProductOpen, toggleIsAddProcuctOpen] = useState(false);
  const [isForceAreaCompleteModalOpen, toggleIsForceAreaCompleteModalOpen] =
    useState(false);
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const queryClient = useQueryClient();

  const completeArea = useMutation(inventoryClient.completeAreaCount, {
    onSuccess: () => {
      toggleIsForceAreaCompleteModalOpen(false);
      queryClient.invalidateQueries({
        queryKey: [
          "areacount",
          {
            establishmentId: establishmentid,
            stockCountId: stockcountid,
            areaCountId: areaCountId,
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "areacount",
          {
            establishmentId: establishmentid,
            stockCountId: stockcountid,
            areaCountId: areaCountId,
          },
        ],
      });
      completeCallback();
    },
    onError: (err) => {
      if (err.response?.status === 422) {
        toggleIsForceAreaCompleteModalOpen(true);
      }
    },
  });

  useEffect(() => {
    toggleMobileMode(largeScreen ? false : true);
  }, [largeScreen]);

  let { establishmentid, stockcountid } = useParams();
  const { data: areaData, isLoading: isLoadingAreaData } = useAreaDetails(
    establishmentid,
    areaId
  );
  const { isLoading, isError, data } = useQuery(
    [
      "areacount",
      {
        establishmentId: establishmentid,
        stockCountId: stockcountid,
        areaCountId: areaCountId,
      },
    ],
    inventoryClient.getAreaCountById,
    {
      enabled: !!areaCountId,
    }
  );

  const handleUpdateBaseRecordQuantity = (recordId, newQuantity) => {
    queryClient.setQueryData(
      [
        "areacount",
        {
          establishmentId: establishmentid,
          stockCountId: stockcountid,
          areaId: areaId,
        },
      ],
      (prev) => {
        console.log(prev);
        const newExpectedRecordData = prev.areaCount.stockCountRecords.map(
          (x) => {
            if (x.id === recordId) {
              return { ...x, quantityFound: newQuantity };
            }

            return x;
          }
        );

        const newState = Object.assign({}, prev);
        newState.areaCount.stockCountRecords = newExpectedRecordData;
        console.log(newState);
        return newState;
      }
    );
  };

  const addProductToAreaCountCallback = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "areacount",
        {
          establishmentId: establishmentid,
          stockCountId: stockcountid,
          areaCountId: areaCountId,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "areacount",
        {
          establishmentId: establishmentid,
          stockCountId: stockcountid,
          areaCountId: areaCountId,
        },
      ],
    });
    toggleIsAddProcuctOpen(false);
  };

  if (isLoading || isLoadingAreaData) {
    return <SpecSpinner open text="loading area..." />;
  }

  if (completeArea.isLoading) {
    return <SpecSpinner open text="Marking area as complete..." />;
  }

  const handleCancelForceAreaCompleteModal = () => {
    toggleIsForceAreaCompleteModalOpen(false);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleOpenAddProductModal = () => {
    toggleIsAddProcuctOpen(true);
  };

  const handleCloseAddProductModal = () => {
    toggleIsAddProcuctOpen(false);
  };

  const handleSubmit = () => {
    completeArea.mutate({
      establishmentId: establishmentid,
      areaId,
      stockCountId,
    });
  };

  const handleForceSubmit = () => {
    completeArea.mutate({
      establishmentId: establishmentid,
      areaId,
      stockCountId,
      forceComplete: true,
    });
  };

  const handleSectionClick = (sectionId) => {
    if (selected == sectionId) {
      setSelected(undefined);
    } else {
      setSelected(sectionId);
    }
  };

  const { areaCount } = data;
  return (
    <>
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          maxWidth: "2440px",
          width: "100%",
          height: `calc(100% - 70px)`,
          overflow: "scroll",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginTop: 6,
          padding: 4,
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <SpecBackButton backText={"Areas"} onClick={goBackToAreaList} />
          </Grid>
          {/* Stock Count Type Badge */}
          {/* <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "92px",
                height: "20px",
                padding: "2px",
                backgroundColor: (theme) => theme.palette.info[800],
                borderRadius: "2px",
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  color: (theme) => theme.palette.pure.white,
                  fontWeight: 700,
                }}
              >
                Inventory Audit
              </Typography>
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Grid item xs={7}>
                <Typography variant="h3">{data?.areaCount.name}</Typography>
                <Typography variant="body1">{stockCountName}</Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={handleOpenAddProductModal}
                      sx={{ height: "40px" }}
                    >
                      <AddOutlinedIcon sx={{ fontSize: 22, marginRight: 1 }} />
                      <Typography variant="largeButton">Add Product</Typography>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginLeft: 2,
                    }}
                  >
                    <IconButton
                      variant="outlined"
                      sx={{
                        height: "36px",
                        width: "36px",
                        border: "2px solid",
                        borderRadius: "8px",
                        borderColor: (theme) => theme.palette.primary.main,
                      }}
                    >
                      <FilterListOutlinedIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* SECTION PILLS */}
          <Grid item xs={12} sx={{ marginTop: 6 }}>
            <Stack direction="row" spacing={1}>
              {areaData?.area?.sections?.map((s) => (
                <SectionPill
                  key={s.id}
                  section={s}
                  selected={selected === s.id}
                  clickChip={() => handleSectionClick(s.id)}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" sx={{ marginTop: 4 }}>
              {isError && (
                <Grid item>
                  <Alert severity="error">
                    There was a error trying to load the area count details.
                  </Alert>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sx={{
                  marginTop: 2,
                  width: "100%",
                }}
              >
                <Grid
                  container
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {/* PRODUCT SEARCH */}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <TextField
                      size="small"
                      fullWidth
                      id="productSearch"
                      autoComplete="off"
                      value={searchText}
                      onChange={handleSearch}
                      inputProps={{
                        sx: {
                          marginBottom: 1,
                        },
                      }}
                      sx={{
                        backgroundColor: (theme) => theme.palette.pure.white,
                        borderRadius: 1,
                        width: "100%",
                      }}
                      label={
                        <>
                          <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                          Search for products...
                        </>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={8}
                    sx={{
                      marginTop: { xs: 4, sm: 0, md: 4, lg: 0 },
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: {
                          xs: "flex-start",
                          sm: "flex-start",
                          md: "space-between",
                          lg: "flex-end",
                        },
                      }}
                    >
                      {/* KEY */}
                      <Grid
                        item
                        sx={{
                          height: "48px",
                          width: "320px",
                          maxWidth: "320px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          border: "1px solid",
                          borderColor: (theme) => theme.palette.terrain[300],
                          backgroundColor: (theme) => theme.palette.pure.white,
                          borderRadius: 2,
                          paddingLeft: 2,
                          paddingRight: 2,
                          marginRight: 1,
                          marginTop: { xs: 4, sm: 4, md: 0 },
                        }}
                      >
                        <Box sx={{ marginRight: 2 }}>
                          <Typography variant="body2">Key</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginRight: 2,
                          }}
                        >
                          <Typography variant="body1" sx={{ fontSize: 10 }}>
                            Empty:
                          </Typography>
                          <Box
                            sx={{
                              width: "24px",
                              height: "16px",
                              borderRadius: 1,
                              backgroundColor: (theme) =>
                                theme.palette.pure.white,
                              border: "2px solid",
                              borderColor: (theme) =>
                                theme.palette.terrain[300],
                              marginLeft: 1,
                              marginRight: 2,
                            }}
                          ></Box>
                          <Typography
                            variant="body1"
                            sx={{
                              color: (theme) => theme.palette.terrain[400],
                            }}
                          >
                            |
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginRight: 2,
                          }}
                        >
                          <Typography variant="body1" sx={{ fontSize: 10 }}>
                            Not Entered:
                          </Typography>
                          <Box
                            sx={{
                              width: "24px",
                              height: "16px",
                              borderRadius: 1,
                              backgroundColor: (theme) =>
                                theme.palette.warning[50],
                              border: "2px solid",
                              borderColor: (theme) =>
                                theme.palette.warning[700],
                              marginLeft: 1,
                              marginRight: 2,
                            }}
                          ></Box>
                          <Typography
                            variant="body1"
                            sx={{
                              color: (theme) => theme.palette.terrain[400],
                            }}
                          >
                            |
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginRight: 2,
                          }}
                        >
                          <Typography variant="body1" sx={{ fontSize: 10 }}>
                            Filled:
                          </Typography>
                          <Box
                            sx={{
                              width: "24px",
                              height: "16px",
                              borderRadius: 1,
                              backgroundColor: (theme) =>
                                theme.palette.info[50],
                              border: "2px solid",
                              borderColor: (theme) => theme.palette.info[600],
                              marginLeft: 1,
                            }}
                          ></Box>
                        </Box>
                      </Grid>
                      {/* INPUT COMPONENT */}
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          border: "1px solid",
                          paddingLeft: 2,
                          paddingRight: 2,
                          borderColor: (theme) => theme.palette.terrain[300],
                          backgroundColor: (theme) => theme.palette.pure.white,
                          borderRadius: 2,
                          width: "320px",
                          maxWidth: "320px",
                          height: "48px",
                          marginTop: { xs: 4, sm: 4, md: 0 },
                        }}
                      >
                        <Typography variant="body1" sx={{ marginRight: 4 }}>
                          Input Figures By:
                        </Typography>
                        <Box>
                          <ToggleButton
                            selected={isMobileMode}
                            value="slider"
                            onChange={() => toggleMobileMode(!isMobileMode)}
                            sx={{
                              height: "32px",
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              "&.Mui-selected, &.Mui-selected:hover": {
                                height: "32px",
                                color: (theme) => theme.palette.pure.white,
                                backgroundColor: (theme) =>
                                  theme.palette.secondary[800],
                              },
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ textTransform: "none" }}
                            >
                              Slider
                            </Typography>
                          </ToggleButton>
                          <ToggleButton
                            selected={!isMobileMode}
                            value="keyboard"
                            onChange={() => toggleMobileMode(!isMobileMode)}
                            sx={{
                              height: "32px",
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              "&.Mui-selected, &.Mui-selected:hover": {
                                height: "32px",
                                color: (theme) => theme.palette.pure.white,
                                backgroundColor: (theme) =>
                                  theme.palette.secondary[800],
                              },
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ textTransform: "none" }}
                            >
                              Keyboard
                            </Typography>
                          </ToggleButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item>
                <Typography variant="body1">
                  Products are best ordered in the order that you input them.
                  This order can be amended using the icon. All entries are
                  automatically saved when entered.
                </Typography>
              </Grid> */}
            </Grid>
            <Grid item sx={{ height: "100%" }}>
              {areaCount && (
                <AreaCountList
                  searching={searchText ? true : false}
                  selectedSectionId={selected}
                  section={
                    selected
                      ? areaData?.area?.sections?.find((x) => x.id === selected)
                      : null
                  }
                  areaCountName={areaCount.name}
                  stockCountRecords={areaCount.stockCountRecords}
                  searchText={searchText}
                  isMobileMode={isMobileMode}
                  updateBaseRecordQuantity={handleUpdateBaseRecordQuantity}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StockCountFooter
        buttonText="Submit"
        submit={handleSubmit}
        handleBack={goBackToAreaList}
      />
      <AddProductToCountModal
        areaCountId={areaCount?.id}
        establishmentId={establishmentid}
        stockCountId={stockcountid}
        isOpen={isAddProductOpen}
        toggleModalOpen={handleCloseAddProductModal}
        onSuccessCallback={addProductToAreaCountCallback}
      />

      <CreateAreaModal
        isOpen={createAreaModalOpen}
        toggleModalOpen={toggleCreateAreaModalOpen}
      />
      <ForceAreaCountCompletionModal
        isOpen={isForceAreaCompleteModalOpen}
        cancelCallback={handleCancelForceAreaCompleteModal}
        completeCallback={handleForceSubmit}
        isLoading={completeArea?.isLoading}
      />
    </>
  );
};

export default AreaCountDetails;
