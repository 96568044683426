import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import AdditionalInfoRow from "./AdditionalInfoRow";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  lineHeight: 1.2,
  borderBottom: "none",
};

const AdditionalInfoTable = ({ additionalInformation }) => {
  return (
    <TableContainer
      sx={{
        display: "flex",
        width: "100%",
        overflow: "scroll !important",
        borderRadius: 2,
        border: "1px solid",
        height: "100%",
        borderColor: (theme) => theme.palette.terrain[400],
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="customized table">
        <TableHead sx={{ height: "24px" }}>
          <TableRow sx={{ height: 24 }}>
            <TableCell sx={[tableHeadStyle, { width: "100%" }]}>
              Description
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <AdditionalInfoRow additionalInformation={additionalInformation} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdditionalInfoTable;
