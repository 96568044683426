import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import utilFunctions from "../../../utilities/utilFunctions";
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderTop: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderBottom: "none",
  backgroundColor: (theme) => theme.palette.pure.white,
  cursor: "default",
};

const StaffTableRow = ({
  staffMember,
  editPermissionsCallback,
  deleteStaffCallback,
  currentUserId,
  currentUser,
  ownershipTransferCallback,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditPermissions = () => {
    setAnchorEl(null);
    editPermissionsCallback(staffMember);
  };

  const handleDeleteStaff = () => {
    setAnchorEl(null);
    deleteStaffCallback(staffMember);
  };

  const handleOwnershipTransfer = () => {
    setAnchorEl(null);
    ownershipTransferCallback(staffMember);
  }

  return (
    <TableRow key={staffMember.userId} sx={{ height: 24, width: "100%" }}>
      <TableCell sx={[tableCellStyle, { width: "65%" }]}>
        <Typography variant="body1">
          {staffMember.firstName} {staffMember.lastName}{" "}
          {staffMember?.isOwner ? "(Owner)" : null}
        </Typography>
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <Typography variant="body1">
          {utilFunctions.capitalizeString(staffMember.role)}
        </Typography>
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            textAlign: "end",
          },
        ]}
      >
        {currentUserId !== staffMember.userId && (
          <IconButton
            size="small"
            sx={{ border: "1px solid #ffffff" }}
            onClick={handleClick}
          >
            <MoreHorizIcon sx={{ marginLeft: "auto" }} />
          </IconButton>
        )}
      </TableCell>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
        <MenuItem
          onClick={handleEditPermissions}
          disabled={currentUserId === staffMember.userId || staffMember?.isOwner ? true : false}
          sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
          focusVisibleClassName="none"
        >
          <EditIcon sx={{ marginRight: 4 }} /> Edit Permissions
        </MenuItem>
         <MenuItem
         onClick={handleDeleteStaff}
         disabled={currentUserId === staffMember.userId || staffMember?.isOwner ? true : false}
         sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
         focusVisibleClassName="none"
       >
         <DeleteIcon
           sx={{
             marginRight: 4,
           }}
         />{" "}
         Remove User
       </MenuItem>
        {currentUser?.isOwner &&
        staffMember.userId !== currentUserId &&
        staffMember.role === "admin" ? (
          <MenuItem
            onClick={handleOwnershipTransfer}
            sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
            focusVisibleClassName="none"
          >
            <GroupAddIcon
              sx={{
                marginRight: 4,
              }}
            />{" "}
            Transfer Ownership
          </MenuItem>
        ) : null}
      </Menu>
    </TableRow>
  );
};

export default StaffTableRow;
