import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import {
  Menu,
  MenuItem,
  Box,
  Avatar,
  Grid,
  Chip,
  Typography,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import utilFunctions from "../../../utilities/utilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import TaggingMenu from "../../../components/TaggingMenu";
import { useMutation } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import DeleteRecipeModal from "../../../components/Modals/DeleteRecipeModal";

const RecipeListItem = ({ recipe, allTags, handleDeleteRecipe, invalidateParentQueries }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tagAnchorEl, setTagAnchorEl] = useState(null);
  const [tagChipSelected, setTagChipSelected] = useState(false);
  const [isErrorEditingTags, toggleErrorUpdatingTags] = useState(false);
  const [deleteRecipeModalOpen, toggleDeletingRecipeModal] = useState(false);
  const tagsOpen = Boolean(tagAnchorEl);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { establishmentid } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTagChipSelected(false);
  };

  const handleTagMenuClick = (event) => {
    setTagAnchorEl(event.currentTarget);
    setTagChipSelected(!tagChipSelected);
  };
  const handleCloseSection = () => {
    setTagAnchorEl(null);
    setTagChipSelected(false);
  };

  const handleDeleteModalOpen = () => {
    toggleDeletingRecipeModal(!deleteRecipeModalOpen);
  };

  const updateTagsMutation = useMutation(inventoryClient.multiEditRecipeTags, {
    onSuccess: () => {
      setTagAnchorEl(null);
      invalidateParentQueries();
      setTagChipSelected(false);
    },
    onError: () => {
      toggleErrorUpdatingTags(true);
    },
  });

  const handleSaveTagsClicked = (tagsToAdd, tagsToRemove) => {
    updateTagsMutation.mutate({
      establishmentId: establishmentid,
      recipeId: recipe.id,
      tagsToAdd,
      tagsToRemove,
    });
  };

  return (
    <>
      <ListItem
        // key={}
        disableGutters
        sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
        secondaryAction={
          <IconButton onClick={handleClick}>
            <MoreHorizIcon />
          </IconButton>
        }
      >
        <ListItemButton onClick={() => navigate(recipe.id)}>
          {recipe.imageUrl ? (
            <Avatar
              sx={{
                zIndex: 1,
                width: "32px",
                height: "32px",
                resize: "contain",
                alignSelf: "center",
              }}
              src={recipe.imageUrl}
            />
          ) : (
            <Box
              sx={{
                zIndex: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.terrain[400],
                borderRadius: "100%",
                overflow: "hidden",
              }}
            >
              <AddPhotoAlternateOutlinedIcon sx={{ fontSize: "12px" }} />
            </Box>
          )}

          <ListItemText
            primary={utilFunctions.capitalizeString(recipe.name)}
            sx={{ marginLeft: 4 }}
          />
        </ListItemButton>

        <Grid item sx={{ marginRight: 1 }}>
          <Chip
            label={
              <Typography
                variant="body2"
                sx={{
                  color: tagChipSelected
                    ? (theme) => theme.palette.pure.white
                    : (theme) => theme.palette.primary[800],
                }}
              >
                {recipe?.tags?.length} Tags
              </Typography>
            }
            clickable
            onClick={handleTagMenuClick}
            sx={{
              height: "24px",
              width: "87px",
              backgroundColor: tagChipSelected
                ? (theme) => theme.palette.terrain[900]
                : (theme) => theme.palette.terrain[300],
            }}
          />
        </Grid>
      </ListItem>
      <Divider />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* <MenuItem onClick={handleCopyModal} sx={{ margin: "5px" }}>
          <ContentCopyIcon sx={{ marginRight: "5px" }} />
          Copy
        </MenuItem> */}
        <MenuItem onClick={handleDeleteModalOpen} sx={{ margin: "5px" }}>
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <TaggingMenu
        anchorEl={tagAnchorEl}
        open={tagsOpen}
        cancel={handleCloseSection}
        allTags={allTags}
        emptyOptionsText={`No tags have been added to ${recipe?.name}`}
        header={"Recipe Tags"}
        subHeader={recipe?.name}
        preSelectedTags={recipe.tags}
        saveCallback={handleSaveTagsClicked}
        submitText={"Save"}
        loading={updateTagsMutation.isLoading}
        searchPromptText={"Search for tags..."}
        isError={isErrorEditingTags}
        helpText={"Assign tags to recipes for easier filtering."}
      />
      <DeleteRecipeModal
        deleteRecipe={handleDeleteRecipe}
        isOpen={deleteRecipeModalOpen}
        toggleModalOpen={handleDeleteModalOpen}
        recipeName={recipe?.name}
        recipeId={recipe?.id}
        />
    </>
  );
};

export default RecipeListItem;
