import {
  Accordion,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

const HomeOptions = ({ homeOptions }) => {
  return (
    <List sx={{ padding: 0 }}>
      <Accordion
        disableGutters
        elevation={0}
        defaultExpanded
        sx={{
          background: "none",
          margin: 0,
          padding: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlinedIcon />}
          id="employeeContent"
          expanded={{ maxHeight: "44px" }}
          sx={{
            background: "none",
            margin: 0,
            padding: 0,
            marginBottom: -6,
            marginTop: -2,
          }}
        >
          <ListItem>
            <Typography
              variant="subtitle2"
              sx={{
                color: (theme) => theme.palette.terrain[900],
                fontWeight: 700,
                marginLeft: -2,
              }}
            >
              Home
            </Typography>
          </ListItem>
        </AccordionSummary>
        <AccordionSummary sx={{ padding: 0, marginBottom: -4 }}>
          <Box sx={{ width: "100%", padding: 0 }}>
            {homeOptions.map((option, index) => (
              <Link
                key={index}
                to={option.route}
                style={{
                  color: location.pathname.includes(option.route)
                    ? "#E78271"
                    : (theme) => theme.palette.terrain["01"],
                  textDecoration: "none",
                  padding: 0,
                  backgroundColor: "none !important",
                }}
              >
                <ListItem
                  sx={{
                    backgroundColor: location.pathname.includes(option.route)
                      ? "#000"
                      : "none",
                    borderRadius: 0.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: location.pathname.includes(option.route)
                        ? "#E78271"
                        : (theme) => theme.palette.terrain["01"],
                    }}
                  >
                    {option.icon}
                  </ListItemIcon>

                  <ListItemText
                    primaryTypographyProps={{
                      variant: "subtitle2",
                      background: "none !important",
                    }}
                    primary={option.text}
                    sx={{
                      marginLeft: -6,
                      background: "none !important",
                      color: location.pathname.includes(option.route)
                        ? "#E78271"
                        : (theme) => theme.palette.terrain["01"],
                    }}
                  />
                </ListItem>
              </Link>
            ))}
          </Box>
        </AccordionSummary>
      </Accordion>
    </List>
  );
};

export default HomeOptions;
