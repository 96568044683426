import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Description from "./Description";
import AdditionalOptions from "./AdditionalOptions";
import SortBy from "./SortBy";
import DateRange from "./DateRange";
import { presetDateRangeOptions } from "../../../../../utilities/dateRangeUtils";
import FilterBy from "./FilterBy";
import { useMutation } from "react-query";
import inventoryClient from "../../../../../clients/inventoryClient";
import utilFunctions from "../../../../../utilities/utilFunctions";
import { invoiceReportTypes } from "../../../../../clients/inventoryObjects";
import ReportingFooter from "../../ReportingFooter";
import InsufficientReportDataModal from "../../../../../components/Modals/InsufficientReportDataModal";

const dateSelectionFormats = {
  presetOption: 0,
  customRange: 1,
};

const ItemizedInvoiceByDate = ({
  establishmentId,
  establishmentTimezone,
  expenseGroups,
  setAlertSuccess,
  setErrorMessage,
  vendors,
}) => {
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customStartDateDisplay, setCustomStartDateDisplay] = useState(null);
  const [customEndDateDisplay, setCustomEndDateDisplay] = useState(null);
  const [selectedPresetDateRange, setSelectedPresetDateRange] = useState(
    presetDateRangeOptions[0].value
  );
  const [dateSelectionFormat, setDateSelectionFormat] = useState(
    dateSelectionFormats.presetOption
  );
  const [isUsingFilter, toggleIsUsingFilter] = useState(false);
  const [isUsingVendorFilter, toggleIsUsingVendorFilter] = useState(false);
  const [isUsingExpenseGroupFilter, toggleIsUsingExpenseGroupFilter] =
    useState(true);
  const [expenseGroupFilters, setExpenseGroupFilters] = useState([]);
  const [allowUnspecified, toggleAllowUnspecified] = useState(true);
  const [optionsForSubgroupFilter, setOptionsForSubgroupFilter] = useState([]);
  const [optionsForSubgroupFilterMap, setOptionsForSubgroupFilterMap] =
    useState(undefined);
  const [vendorFilters, setVendorFilters] = useState([]);
  const [orderByAscending, toggleOrderByAscending] = useState(false);
  const [isEmptyResultModalOpen, toggleIsEmptyResultModalOpen] =
    useState(false);
  const [includeSumRow, toggleIncludeSumRow] = useState(false);

  useEffect(() => {
    // we need to get expense group/subgroups in a structure that will play nice with display
    // in a single dropdown. This is also where typescript would be nice
    const displayOptions = [];
    expenseGroups?.forEach((e) => {
      // we are providing a subgroup filter, so skip expense groups with none
      if (e.expenseSubgroups === null || e.expenseSubgroups?.length === 0) {
        return;
      }

      const expenseGroupDisplay = Object.assign({}, e, { type: "group" });

      displayOptions.push(expenseGroupDisplay);

      e.expenseSubgroups.forEach((s) => {
        const subgroupDisplay = {
          id: s.id,
          expenseGroupId: e.id,
          name: s.name,
          type: "subgroup",
          parentName: e.name,
        };
        displayOptions.push(subgroupDisplay);
      });
    });

    setOptionsForSubgroupFilterMap(
      new Map(displayOptions.map((x) => [x.id, x]))
    );
    setOptionsForSubgroupFilter(displayOptions);
  }, [expenseGroups]);

  const generateInvoiceReportMutation = useMutation(
    inventoryClient.generateInvoiceReport,
    {
      onSuccess: (response) => {
        if (response?.status === 204) {
          toggleIsEmptyResultModalOpen(true);
        } else {
          utilFunctions.downloadFile(
            response?.data?.report?.invoiceReportMetadata?.s3PresignedUrl
          );
          setAlertSuccess(
            "Report generated successfully, your download should have started."
          );
        }
      },
      onError: () => {
        setErrorMessage("Report generation failed!");
      },
    }
  );

  const generateGroupFilters = () => {
    const selectedExpenseGroups = new Map();

    expenseGroupFilters.forEach((x) => {
      if (x.type === "group") {
        selectedExpenseGroups.set(x.id, {
          expenseGroupId: x.id,
          expenseSubgroupIds: [],
        });
      }
    });

    expenseGroupFilters.forEach((x) => {
      if (x.type === "subgroup") {
        selectedExpenseGroups
          .get(x.expenseGroupId)
          .expenseSubgroupIds.push(x.id);
      }
    });

    return Array.from(selectedExpenseGroups.values());
  };

  const handleGenerateReport = () => {
    const payload = {
      establishmentId: establishmentId,
      reportType: invoiceReportTypes.itemizedInvoiceByDate,
      itemizedInvoiceByDateFilters: {
        includeSumRow: includeSumRow,
        allowUnspecifiedExpenseGroups: allowUnspecified,
        vendorTargetIds:
          isUsingFilter && isUsingVendorFilter
            ? vendorFilters.map((x) => x.id)
            : [], // api treats an empty array as not wanting filters
        expenseGroupFilters:
          isUsingFilter && isUsingExpenseGroupFilter
            ? generateGroupFilters()
            : [], // api treats an empty array as not wanting filters
        orderByAscending: orderByAscending,
      },
    };

    if (dateSelectionFormat == dateSelectionFormats.presetOption) {
      const dateRange = presetDateRangeOptions
        .find((x) => x.value === selectedPresetDateRange)
        .func(establishmentTimezone);
      payload.startDate = dateRange.start;
      payload.endDate = dateRange.end;
    } else {
      payload.startDate = customStartDate;
      payload.endDate = customEndDate;
    }

    generateInvoiceReportMutation.mutate(payload);
  };

  const handleGroupAddClick = (newValue, updatedObj) => {
    const existingSet = new Set(newValue);
    const finalValue = [...newValue];
    updatedObj.expenseSubgroups.forEach((s) => {
      if (!existingSet.has(s.id)) {
        finalValue.push(optionsForSubgroupFilterMap.get(s.id));
      }
    });
    setExpenseGroupFilters(finalValue);
  };

  const handleGroupRemoveClick = (newValue, updatedObj) => {
    const subgroupIdSet = new Set(updatedObj.expenseSubgroups.map((x) => x.id));
    const finalValue = newValue.filter((x) => !subgroupIdSet.has(x.id));

    setExpenseGroupFilters(finalValue);
  };

  const handleSubgroupAddClick = (newValue, updatedObj) => {
    const currentlySelectedSet = new Set(newValue.map((x) => x.id));

    if (!currentlySelectedSet.has(updatedObj.expenseGroupId)) {
      const parentGroup = optionsForSubgroupFilter.find(
        (x) => x.id === updatedObj.expenseGroupId
      );
      const finalValue = [...newValue, parentGroup];
      setExpenseGroupFilters(finalValue);
    } else {
      setExpenseGroupFilters([...newValue]);
    }
  };

  const handleSubgroupRemoveClick = (newValue, updatedObj) => {
    const parentGroup = expenseGroups.find(
      (x) => x.id === updatedObj.expenseGroupId
    );
    const allParentSubgroups = new Set(
      parentGroup.expenseSubgroups.map((x) => x.id)
    );

    if (!newValue.some((x) => allParentSubgroups.has(x.id))) {
      const finalValue = newValue.filter(
        (x) => x.id !== updatedObj.expenseGroupId
      );
      setExpenseGroupFilters(finalValue);
    } else {
      setExpenseGroupFilters([...newValue]);
    }
  };

  const handleClearSubgroupFilters = () => {
    setExpenseGroupFilters([]);
  };

  return (
    <>
      <Box>
        <Box sx={{ marginTop: 8 }}>
          <Description />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <DateRange
            customStartDate={customStartDate}
            setCustomStartDate={setCustomStartDate}
            customEndDate={customEndDate}
            setCustomEndDate={setCustomEndDate}
            selectedPresetDateRange={selectedPresetDateRange}
            setSelectedPresetDateRange={setSelectedPresetDateRange}
            dateSelectionFormat={dateSelectionFormat}
            setDateSelectionFormat={setDateSelectionFormat}
            dateSelectionFormats={dateSelectionFormats}
            presetDateRangeOptions={presetDateRangeOptions}
            establishmentTimezone={establishmentTimezone}
            customStartDateDisplay={customStartDateDisplay}
            setCustomStartDateDisplay={setCustomStartDateDisplay}
            customEndDateDisplay={customEndDateDisplay}
            setCustomEndDateDisplay={setCustomEndDateDisplay}
          />
        </Box>

        <Box sx={{ marginTop: 8 }}>
          <SortBy
            orderByAscending={orderByAscending}
            toggleOrderByAscending={toggleOrderByAscending}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <FilterBy
            expenseGroups={optionsForSubgroupFilter}
            isUsingExpenseGroupFilter={isUsingExpenseGroupFilter}
            toggleIsUsingExpenseGroupFilter={toggleIsUsingExpenseGroupFilter}
            expenseGroupFilters={expenseGroupFilters}
            handleGroupAddClick={handleGroupAddClick}
            handleGroupRemoveClick={handleGroupRemoveClick}
            handleSubgroupAddClick={handleSubgroupAddClick}
            handleSubgroupRemoveClick={handleSubgroupRemoveClick}
            handleClearSubgroupFilters={handleClearSubgroupFilters}
            isUsingFilter={isUsingFilter}
            toggleIsUsingFilter={toggleIsUsingFilter}
            isUsingVendorFilter={isUsingVendorFilter}
            toggleIsUsingVendorFilter={toggleIsUsingVendorFilter}
            vendors={vendors}
            vendorFilters={vendorFilters}
            setVendorFilters={setVendorFilters}
          />
        </Box>
        <Box sx={{ marginTop: 0 }}>
          <AdditionalOptions
            allowUnspecified={allowUnspecified}
            toggleAllowUnspecified={toggleAllowUnspecified}
            includeSumRow={includeSumRow}
            toggleIncludeSumRow={toggleIncludeSumRow}
          />
        </Box>
      </Box>
      <ReportingFooter
        buttonText={"Create Report"}
        disableButton={
          isUsingFilter &&
          isUsingExpenseGroupFilter &&
          expenseGroupFilters?.length <= 0 // if we haven't chosen any expense groups but checked the box
            ? true
            : isUsingFilter && isUsingVendorFilter && vendorFilters?.length <= 0 // if we chost vendor filters but havent set any
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange && // if we selected custom range but didn't choose dates
              (!customEndDate || !customStartDate)
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange &&
              customEndDate < customStartDate // if the custom end date is before the start date
            ? true
            : false
        }
        submit={handleGenerateReport}
        isLoading={generateInvoiceReportMutation?.isLoading}
      />
      <InsufficientReportDataModal
        isOpen={isEmptyResultModalOpen}
        toggleModalOpen={toggleIsEmptyResultModalOpen}
        loading={false}
      />
    </>
  );
};

export default ItemizedInvoiceByDate;
