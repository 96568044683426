import { Box, Checkbox, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { unitOfMeasurementTypes } from "../../../../../clients/inventoryObjects";
import DescriptionToolTip from "../../../../../components/DescriptionToolTip";

const AdditionalOptions = ({
  unitsOfMeasurement,
  selectedVolumetricCostPer,
  selectedMassCostPer,
  setVolumetricCostPer,
  setMassCostPer,
  includeAverageRow,
  toggleIncludeAverageRow,
}) => {
  const handleSetVolumetric = (e) => {
    setVolumetricCostPer(e.target.value);
  };

  const handleSetMass = (e) => {
    setMassCostPer(e.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 0,
        }}
      >
        <Typography variant="h5">Volumetric Unit Cost (Cost Per)</Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "72px",
            width: "35%",
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.terrain[200],
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            padding: 2,
            marginTop: 2,
          }}
        >
          <Select
            value={selectedVolumetricCostPer}
            onChange={handleSetVolumetric}
            sx={{
              width: "100%",
              height: "40px",
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
          >
            {unitsOfMeasurement
              .filter(
                (x) =>
                  x.type === unitOfMeasurementTypes.Volumetric && !x.isKegSize
              )
              .map((x) => (
                <MenuItem value={x.id} key={x.id}>
                  {x.name}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 0,
          marginTop: 6,
        }}
      >
        <Typography variant="h5">Mass Unit (Cost Per)</Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "72px",
            width: "35%",
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.terrain[200],
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            padding: 2,
            marginTop: 2,
          }}
        >
          <Select
            value={selectedMassCostPer}
            onChange={handleSetMass}
            sx={{
              width: "100%",
              height: "40px",
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
          >
            {unitsOfMeasurement
              .filter((x) => x.type === unitOfMeasurementTypes.Mass)
              .map((x) => (
                <MenuItem value={x.id} key={x.id}>
                  {x.name}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
          marginTop: 6,
        }}
      >
        <Checkbox checked={includeAverageRow} onChange={() => toggleIncludeAverageRow(!includeAverageRow)} />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Include average row
        </Typography>
        <DescriptionToolTip
          header="Include Average Row"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Include a row at the top of the entire report showing the
                average price paid for the product.
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default AdditionalOptions;
