const createPalette = (config) => {
  if (!config?.useDarkMode) {
    return {
      background: { default: "#FBFBFB" },
      primary: {
        main: "#E78271",
        light: "#F1A498",
        dark: "#B83F15",
        50: "#FFEDED",
        100: "#FFD2D1",
        200: "#F1A498",
        300: "#E78271",
        400: "#EF6B4B",
        500: "#F1612C",
        600: "#E5592E",
        700: "#D34F28",
        800: "#C74822",
        900: "#B83F15",
      },
      secondary: {
        main: "#080E40",
        light: "#656D99",
        50: "#E4E5ED",
        100: "#BBBED4",
        200: "#8F95B6",
        300: "#656D99",
        400: "#475086",
        500: "#293474",
        600: "#242E6C",
        700: "#1C2662",
        800: "#141D56",
        900: "#080E40",
      },
      terrain: {
        "01": "#282828",
        "02": "#7E7E7E",
        "03": "#C6C6C6",
        "04": "#F6F6F6",
        50: "#FBFBFB",
        100: "#F6F6F6",
        200: "#F2F2F2",
        300: "#E8E8E8",
        400: "#C6C6C6",
        500: "#A7A7A7",
        600: "#7E7E7E",
        700: "#696969",
        800: "#4A4A4A",
        900: "#282828",
      },
      success: {
        main: "#1BB55B",
        light: "#C2E8CD",
        dark: "#B83F15",
        50: "#E6F6EB",
        100: "#C2E8CD",
        200: "#9ADAAD",
        300: "#6FCC8C",
        400: "#4BC074",
        500: "#1BB55B",
        600: "#0FA651",
        700: "#009345",
        800: "#00823A",
        900: "#006326",
      },
      error: {
        main: "#FF401D",
        light: "#FFC9BB",
        50: "#FEE8E7",
        100: "#FFC9BB",
        200: "#FFA58F",
        300: "#FF8062",
        400: "#FF603F",
        500: "#FF401D",
        600: "#FF3419",
        700: "#F82C13",
        800: "#EA210D",
        900: "#D20000",
      },
      warning: {
        main: "#EB7600",
        light: "#FCDDB1",
        50: "#FEF2DF",
        100: "#FCDDB1",
        200: "#FAC87E",
        300: "#F8B14B",
        400: "#F7A025",
        500: "#F59100",
        600: "#F18500",
        700: "#EB7600",
        800: "#E56600",
        900: "#DB4B01",
      },
      info: {
        main: "#32B2DB",
        light: "#B2E3F1",
        50: "#E0F4F9",
        100: "#B2E3F1",
        200: "#81D1E8",
        300: "#53BEDF",
        400: "#32B2DB",
        500: "#0CA4D7",
        600: "#0096C9",
        700: "#0084B7",
        800: "#0073A3",
        900: "#005485",
      },
      gradient: {
        primary: "linear-gradient(90deg, #B15345 0%, #E78271 100%)",
        dark: "linear-gradient(270deg, #7E7E7E 0%, #282828 100%)",
      },
      pure: {
        black: "#000000",
        white: "#FFFFFF",
      },
    };
  } else {
    return {
      mode: "dark",
      background: { default: "#1d1d1d" },
      primary: {
        main: "#E78271",
        light: "#F1A498",
        dark: "#B83F15",
        50: "#8C2704",
        100: "#A4330C",
        200: "#B83F15",
        300: "#C74822",
        400: "#D34F28",
        500: "#E5592E",
        600: "#F1612C",
        700: "#EF6B4B",
        800: "#E78271",
        900: "#F1A498",
      },
      secondary: {
        main: "#080E40",
        light: "#656D99",
        50: "#080E40",
        100: "#141D56",
        200: "#1C2662",
        300: "#242E6C",
        400: "#293474",
        500: "#475086",
        600: "#656D99",
        700: "#8F95B6",
        800: "#BBBED4",
        900: "#E4E5ED",
      },
      terrain: {
        "01": "#F6F6F6",
        "02": "#C6C6C6",
        "03": "#7E7E7E",
        "04": "#282828",
        50: "#1D1D1D",
        100: "#272727",
        200: "#313131",
        300: "#404040",
        400: "#4B4B4B",
        500: "#585858",
        600: "#6D6D6D",
        700: "#808080",
        800: "#8F8F8F",
        900: "#9D9D9D",
      },
      success: {
        main: "#1BB55B",
        light: "#C2E8CD",
        dark: "#B83F15",
        50: "#00431A",
        100: "#0B612B",
        200: "#137135",
        300: "#1D823F",
        400: "#239048",
        500: "#2DA455",
        600: "#39B864",
        700: "#45CD73",
        800: "#51DC80",
        900: "#5EED8E",
      },
      error: {
        main: "#FF401D",
        light: "#FFC9BB",
        50: "#800F00",
        100: "#8F170C",
        200: "#9C1E12",
        300: "#AC2616",
        400: "#B82F15",
        500: "#BC4032",
        600: "#BC4032",
        700: "#E05F50",
        800: "#EF6E60",
        900: "#FE8375",
      },
      warning: {
        main: "#EB7600",
        light: "#FCDDB1",
        50: "#643000",
        100: "#763900",
        200: "#803D00",
        300: "#8D4400",
        400: "#9F4D01",
        500: "#AF5501",
        600: "#BF5C00",
        700: "#CF6401",
        800: "#DE6B01",
        900: "#E97101",
      },
      info: {
        main: "#32B2DB",
        light: "#B2E3F1",
        50: "#00304B",
        100: "#003858",
        200: "#004368",
        300: "#004C76",
        400: "#00517D",
        500: "#005E92",
        600: "#0471AD",
        700: "#0E82C2",
        800: "#1D97DA",
        900: "#2AA9EF",
      },
      gradient: {
        primary: "linear-gradient(90deg, #B15345 0%, #E78271 100%)",
        dark: "linear-gradient(270deg, #7E7E7E 0%, #282828 100%)",
      },
      pure: {
        black: "#ffffff",
        white: "#000000",
      },
    };
  }
};

export default createPalette;
