import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useProductLibraryPaginated(establishmentId, page, filters, sortAscending, propertyForSort) {
    const queryReturnValues = useQuery(
        ["inventory-paginated", { establishmentId, page, filters, sortAscending, propertyForSort }],
        inventoryClient.getProductInventoryPaginated,
        {
            staleTime: 1000 * 60 * 10,
            keepPreviousData: true,
        }
    );

    return queryReturnValues;
}

export default useProductLibraryPaginated;