import React, { useContext, useEffect, useState } from "react";
import MenuOption from "./components/MenuOption";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  addCategory,
  migrateForInventory,
} from "../../../../clients/establishmentsClient";
import Store from "../../../../Store/Store";
import { Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MenuManagementModal from "../../../../components/Modals/MenuManagementModal";
import CTABlock from "../../../../components/CTABlock";
import AddIcon from "@mui/icons-material/Add";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { Link } from "react-router-dom";
import LegacyUpgradeEstablishmentModal from "../../../../components/Modals/LegacyUpgradeEstablishmentModal";
import { useMutation } from "react-query";

const MenusDisplay = ({ selectCategory, menus }) => {
  const [menuOptions, setMenuOptions] = useState([]);
  const [creatingMenu, toggleCreatingMenu] = useState(false);
  const [isMigrationSucceeded, toggleMigrationSuccess] = useState(false);
  const [isMigrationError, toggleIsMigrationError] = useState(false);
  const [isUpgradeModalOpen, toggleIsUpgradeModalOpen] = useState(false);
  const store = useContext(Store);

  useEffect(() => {
    setMenuOptions(menus);
  }, [menus]);

  const handleMenuCreateToggle = () => {
    toggleCreatingMenu(!creatingMenu);
  };

  const handleMenuCreation = async (menuData) => {
    const { currentEstablishment } = store;
    const menu = {
      name: menuData.name,
      establishmentId: currentEstablishment.id,
      active: menuData.active,
      display: true,
      type: menuData.type,
    };
    const newMenu = await addCategory(menu);
    selectCategory(newMenu.id);
  };

  const upgradeEstablishmentMutation = useMutation(migrateForInventory, {
    onSuccess: () => {
      toggleMigrationSuccess(true);
    },
    onError: () => {
      toggleIsMigrationError(true);
    },
  });

  const handleSuccessUpgradeReload = () => {
    window.location.href =
      window.location.origin +
      `/spec/${store.currentEstablishment?.id}/dashboard`;
  };

  const handleUpgradeEstablishment = () => {
    const { currentEstablishment } = store;
    upgradeEstablishmentMutation.mutate({
      establishmentId: currentEstablishment?.id,
    });
  };

  const handleOpenUpgradeModal = () => {
    toggleIsUpgradeModalOpen(true);
  };

  const handleCloseUpgradeModal = () => {
    toggleIsMigrationError(false);
    toggleIsUpgradeModalOpen(false);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{
          maxWidth: "2440px",
          minHeight: "800px",
          padding: 4,
          marginTop: 6,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "fit-content",
            borderRadius: 2,
            marginBottom: 6,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              width: "52px",
              minHeight: "100% !important",
              backgroundColor: (theme) => theme.palette.warning[800],
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WarningOutlinedIcon sx={{ color: "#fff" }} />
          </Box>
          <Box
            sx={{
              width: `calc(100% - 52px)`,
              border: "2px solid",
              borderColor: (theme) => theme.palette.warning[800],
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              backgroundColor: (theme) => theme.palette.warning[50],
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              paddingLeft: 2,
              height: "fit-content",
              padding: 4,
            }}
          >
            <Typography variant="h3" sx={{ marginBottom: 1 }}>
              This feature is being deprecated
            </Typography>
            <Typography variant="subtitle2">
              We have completely revamped Spec, with new inventory, ordering,
              invoicing and cost analysis features! As part of this we have
              updated our existing menus into a new format, where recipes live
              independant of each other. Going forward, all of our new features
              will use that new structure. In order to take advantage of that,
              you will need to migrate any existing menus you have into the new
              format for{" "}
              <Link
                to={`/spec/${store.currentEstablishment?.id}/menus`}
                style={{ color: "#E78271" }}
              >
                Menus
              </Link>{" "}
              and{" "}
              <Link
                to={`/spec/${store.currentEstablishment?.id}/recipes`}
                style={{ color: "#E78271" }}
              >
                Recipes
              </Link>{" "}
              . Once you have completed that, you can use the button on this
              screen to mark your establishment as upgraded. Make sure you have
              finished migrating first because you will lose access to the data
              on this screen. The mobile app will continue to show the old
              structure and data until you upgrade. Once you upgrade, you will
              be able to see all the new menus, recipes and inventory management
              tools in the mobile app as well!
            </Typography>
          </Box>
        </Box>
        {menuOptions.length > 0 ? (
          <Box sx={{ marginRight: { xs: 2, sm: 4 } }}>
            <Grid
              container
              sx={{
                marginBottom: 2,
                display: "flex",
              }}
            >
              <Grid item xs={6} sm={6} md={6} xl={6} lg={6}>
                <Typography variant="h2">
                  {store.currentEstablishment.name} Menus
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleMenuCreateToggle}
                  sx={{ borderRadius: 2, padding: 2.5 }}
                >
                  <AddOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                  <Typography variant="smallButton">Create New Menu</Typography>
                </Button>
              </Grid>
            </Grid>
            <Box>
              <Button
                variant="contained"
                onClick={handleOpenUpgradeModal}
                sx={{
                  borderRadius: 2,
                  marginBottom: 4,
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.warning.light
                      : theme.palette.warning["400"],
                }}
              >
                Convert from Legacy Menus
              </Button>
            </Box>
            <Box>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 6,
                }}
              >
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h3">All Menus</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {menuOptions.map((m) => (
                  <Grid item key={m.id} xs={6} sm={5} md={5} lg={4} xl={3}>
                    <MenuOption
                      data={m}
                      handleClick={() => selectCategory(m.id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        ) : (
          <Grid sx={{ padding: "20px" }}>
            <Box sx={{ paddingLeft: 5, marginBottom: 5 }}>
              <Typography variant="h2">Menus</Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={handleOpenUpgradeModal}
                sx={{
                  borderRadius: 2,
                  marginBottom: 4,
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.warning.light
                      : theme.palette.warning["400"],
                }}
              >
                Convert from Legacy Menus
              </Button>
            </Box>
            <CTABlock
              header="Your Menu List is Empty"
              subheader="You have not added any menus yet. Get started now..."
              buttonText="CREATE NEW MENU"
              ButtonImage={AddIcon}
              handleCTA={() => toggleCreatingMenu(true)}
            />
          </Grid>
        )}
      </Grid>
      <MenuManagementModal
        onSave={handleMenuCreation}
        modalOpen={creatingMenu}
        toggleModalOpen={handleMenuCreateToggle}
      />
      <LegacyUpgradeEstablishmentModal
        handlePostUpgrade={handleSuccessUpgradeReload}
        hasError={isMigrationError}
        isLoading={upgradeEstablishmentMutation.isLoading}
        isUpgradeComplete={isMigrationSucceeded}
        open={isUpgradeModalOpen}
        performUpgradeCallback={handleUpgradeEstablishment}
        toggleUpgradeEstablishmentModalOpen={handleCloseUpgradeModal}
      />
    </>
  );
};

export default MenusDisplay;
