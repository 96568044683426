import { Grid, Typography } from "@mui/material";
import React from "react";
import ProductListRow from "./ProductListRow";
import CTABlock from "../../../components/CTABlock/index.js";

const AreaCountList = ({
  stockCountRecords,
  isMobileMode,
  updateBaseRecordQuantity,
  searchText,
  searching,
  selectedSectionId,
  section,
}) => {
  const expected = selectedSectionId
    ? stockCountRecords.filter(
        (x) => x.sections.find((s) => s.id === selectedSectionId) !== undefined
      )
    : stockCountRecords;
  return (
    <>
      <Grid item sx={{ marginTop: 6 }}>
        {section ? (
          <Typography
            variant="h4"
            sx={{
              color: (theme) => theme.palette.terrain["02"],
              marginLeft: 1,
            }}
          >
            {section.name} Products
          </Typography>
        ) : null}
      </Grid>
      <Grid
        item
        sx={{
          marginTop: 3,
          padding: 0,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
          backgroundColor: (theme) => theme.palette.pure.white,
          borderBottom: "none",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        {expected.length > 0 ? (
          stockCountRecords.map((x, i) => (
            <ProductListRow
              updateBaseRecordQuantity={updateBaseRecordQuantity}
              record={x}
              index={i}
              key={x.id}
              mobileDisplay={isMobileMode}
              searchText={searchText}
              searching={searching}
              selectedSectionId={selectedSectionId}
            />
          ))
        ) : (
          <>
            {searching ? (
              <Typography>No Results</Typography>
            ) : (
              <CTABlock
                header={`No Products are listed in this ${
                  selectedSectionId ? "section" : "area"
                }`}
                subheader={`Either add products to the ${
                  selectedSectionId ? "section" : "area"
                } or explore the unexpected products`}
                hideButton
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default AreaCountList;
