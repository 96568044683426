import React, { useState, useEffect, useContext } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { cloneDeep } from "lodash";
import inventoryClient from "../../../clients/inventoryClient";
import Store from "../../../Store/Store";
import {
  productSizeTypes,
  unitOfMeasurementTypes,
} from "../../../clients/inventoryObjects";
import VolumeSizeCard from "./components/VolumeSizeCard";
import MassSizeCard from "./components/MassSizeCard";
import ArbitrarySizeCard from "./components/ArbitrarySizeCard";

const UnitSizeModal = ({
  isOpen,
  toggleModalOpen,
  product,
  formatId,
  unitsOfMeasurement,
  updateCallback,
}) => {
  const [loading, toggleLoading] = useState(false);
  const [productFormatSizes, setSizes] = useState(null);
  const store = useContext(Store);

  const closeModal = () => {
    toggleModalOpen(false);
    toggleLoading(false);
  };

  useEffect(() => {
    const formatSizes =
      product?.productFormats?.find((x) => x.id === formatId)?.sizes || [];
    setSizes(formatSizes);
  }, [product]);

  const updateSizeByType = (newSizeData) => {
    const productFormatSizesCopy = productFormatSizes.map((x) => {
      if (newSizeData.type === x.type) return newSizeData;

      return x;
    });

    setSizes(productFormatSizesCopy);
  };

  const handleChangeDefaultSize = (type) => {
    const productFormatSizesCopy = productFormatSizes.map((x) => {
      if (x.type === type) {
        return Object.assign({}, x, { isDefaultSize: true})
      }

      return Object.assign({}, x, { isDefaultSize: false})
    });

    setSizes(productFormatSizesCopy);
  }

  const handleSubmit = async () => {
    toggleLoading(true);
    const payload = productFormatSizes.map((x) => {
      if (x.unitOfMeasurement != null && x.unitOfMeasurement.id === -1) {
        x.unitOfMeasurement = null;
      }

      return x;
    });

    const response = await inventoryClient.updateProductFormat(
      {
        id: formatId,
        sizesUpdate: { value: payload },
      },
      store.currentEstablishment?.id
    );

    const pCopy = cloneDeep(product);
    pCopy.productFormats = pCopy.productFormats.map((x) => {
      if (x.id === response.productFormat?.id) {
        return response.productFormat;
      }

      return x;
    });

    updateCallback(pCopy);
    toggleLoading(false);
    closeModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography variant="h4" sx={{ marginBottom: 4 }}>
                  Add Unit Size
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box>
              <Box sx={{ marginBottom: 8 }}>
                <Divider />
              </Box>
              <Box sx={{ marginBottom: 8 }}>
                <Typography variant="body1" sx={{ fontSize: "12px" }}>
                  Add different unit sizes depending on how your products are
                  stored. Note that only diferent measurements of the same size
                  should be stored in this location, multiple product sizes can
                  be added seperately.
                </Typography>
              </Box>
              <Grid
                container
                sx={{ display: "flex", alignItems: "center", marginBottom: 4 }}
              >
                <Grid item xs={6}>
                  <Typography variant="h4">Size Variations</Typography>
                </Grid>
              </Grid>

              <Box
                sx={{
                  maxWidth: "100%",
                  width: "100%",
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 2,
                  minHeight: "68px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: 2,
                  }}
                >
                  <Typography variant="h4">Volume</Typography>
                </Box>
                {productFormatSizes && (
                  <VolumeSizeCard
                    unitSize={productFormatSizes.find(
                      (x) => x.type === productSizeTypes.Volumetric
                    )}
                    unitsOfMeasurement={unitsOfMeasurement?.filter(
                      (x) => x.type === unitOfMeasurementTypes.Volumetric
                    )}
                    updateUnitSize={updateSizeByType}
                    changeDefaultSize={handleChangeDefaultSize}
                  />
                )}
              </Box>
              <Box
                sx={{
                  maxWidth: "100%",
                  width: "100%",
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 2,
                  minHeight: "68px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: 2,
                  }}
                >
                  <Typography variant="h4">Mass</Typography>
                </Box>
                {productFormatSizes && (
                  <MassSizeCard
                    unitSize={productFormatSizes.find(
                      (x) => x.type === productSizeTypes.Mass
                    )}
                    unitsOfMeasurement={unitsOfMeasurement?.filter(
                      (x) => x.type === unitOfMeasurementTypes.Mass
                    )}
                    updateUnitSize={updateSizeByType}
                    changeDefaultSize={handleChangeDefaultSize}
                  />
                )}
              </Box>
              <Box
                sx={{
                  maxWidth: "100%",
                  width: "100%",
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 2,
                  minHeight: "68px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: 2,
                  }}
                >
                  <Typography variant="h4">Count</Typography>
                </Box>
                {productFormatSizes && (
                  <ArbitrarySizeCard
                    unitSize={productFormatSizes.find(
                      (x) => x.type === productSizeTypes.Arbitrary
                    )}
                    updateUnitSize={updateSizeByType}
                    changeDefaultSize={handleChangeDefaultSize}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={loading}
                  sx={{
                    marginLeft: "1rem",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="smallButton">Submit</Typography>
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default UnitSizeModal;
