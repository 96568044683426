import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { invoiceExpenseGroupingReportGroupingOptions } from "../../../../../clients/inventoryObjects";

const FilterBy = ({
  expenseGroups,
  isUsingExpenseGroupFilter,
  toggleIsUsingExpenseGroupFilter,
  expenseGroupFilters,
  handleGroupAddClick,
  handleGroupRemoveClick,
  handleSubgroupAddClick,
  handleSubgroupRemoveClick,
  handleClearGroupFilters,
  reportGroupingChoice,
}) => {
  const handleToggleUsingFilter = () => {
    toggleIsUsingExpenseGroupFilter(!isUsingExpenseGroupFilter);
  };

  const handleSelectChange = (_, newValue, reason, details) => {
    if (details?.option?.type === "group") {
      reason === "removeOption"
        ? handleGroupRemoveClick(newValue, details.option)
        : handleGroupAddClick(newValue, details.option);
    } else if (reason === "clear") {
      handleClearGroupFilters();
    } else {
      reason === "removeOption"
        ? handleSubgroupRemoveClick(newValue, details.option)
        : handleSubgroupAddClick(newValue, details.option);
    }
  };

  const calculateSelectedCount = () => {
    if (reportGroupingChoice === invoiceExpenseGroupingReportGroupingOptions.expenseGroupsOnly) {
      return expenseGroupFilters.filter(
        (x) => x.type === "group"
      ).length;
    } else if (reportGroupingChoice === invoiceExpenseGroupingReportGroupingOptions.expenseSubgroupsOnly) {
      return expenseGroupFilters.filter(
        (x) => x.type === "subgroup"
      ).length;
    } else {
      return expenseGroupFilters.length;
    }
  }

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox
          sx={{ marginLeft: -3 }}
          checked={isUsingExpenseGroupFilter}
          onChange={handleToggleUsingFilter}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Filter by Groups
        </Typography>
      </Box>
      {isUsingExpenseGroupFilter ? (
        <Box sx={{ marginTop: 2 }}>
          {/* <Button variant="contained" sx={{ marginRight: 1, borderRadius: 2 }}>
          Product Library
        </Button> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "72px",
              width: "35%",
              borderRadius: 2,
              backgroundColor: (theme) => theme.palette.terrain[200],
              border: "1px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              padding: 2,
              marginTop: 2,
            }}
          >
            <Autocomplete
              multiple
              size="small"
              disabled={!isUsingExpenseGroupFilter}
              fullWidth
              options={expenseGroups}
              getOptionLabel={(option) => option.id}
              disableCloseOnSelect
              renderTags={() => null}
              value={expenseGroupFilters}
              onChange={handleSelectChange}
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
                "& .MuiAutocomplete-option": {
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              }}
              renderOption={(props, option) =>
                reportGroupingChoice ===
                  invoiceExpenseGroupingReportGroupingOptions.expenseGroupsOnly &&
                option.type === "subgroup" ? null : (
                  <ListItem
                    key={option.id}
                    id={option.id}
                    {...props}
                    sx={
                      option.type === "group"
                        ? {
                            borderLeft: "6px solid",
                            borderLeftColor: "transparent",
                            "&:hover": {
                              color: (theme) => theme.palette.pure.white,
                            },
                          }
                        : {
                            backgroundColor: (theme) =>
                              theme.palette.terrain[50],
                            borderLeft: "6px solid",
                            borderLeftColor: (theme) =>
                              theme.palette.primary[300],
                            marginLeft: 3,
                            padding: 0,
                            "&:hover": {
                              color: (theme) => theme.palette.pure.white,
                            },
                          }
                    }
                  >
                    <Checkbox
                      sx={{
                        color: (theme) => theme.palette.primary[800],
                        marginLeft: 0,
                      }}
                      checked={expenseGroupFilters.some(
                        (item) => item.id === option.id
                      )}
                      onChange={() => {}}
                    />
                    <ListItemText primary={option.name} />
                  </ListItem>
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={
                    expenseGroupFilters?.length > 0
                      ? `${
                          calculateSelectedCount()
                        } Group(s) Selected`
                      : "No Groups Selected"
                  }
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    style: { cursor: "pointer" },
                  }} // Disable typing
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    width: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    cursor: "pointer",
                  }}
                />
              )}
            />
          </Box>
          {isUsingExpenseGroupFilter ? (
            <Box sx={{ marginTop: 3, marginBottom: 2 }}>
              {expenseGroupFilters.map((group) =>
                (reportGroupingChoice ===
                  invoiceExpenseGroupingReportGroupingOptions.expenseGroupsOnly &&
                  group.type === "subgroup") ||
                (reportGroupingChoice ===
                  invoiceExpenseGroupingReportGroupingOptions.expenseSubgroupsOnly &&
                  group.type === "group") ? null : (
                  <Chip
                    key={group.id}
                    label={group.type === "subgroup" ? `${group.parentName}:${group.name}` : group.name}
                    onDelete={() => {
                      // we are sadly mimicing the autocomplete here, so we need to wrap the group in the option property
                      handleSelectChange(
                        null,
                        expenseGroupFilters.filter(
                          (item) => item.id !== group.id
                        ),
                        "removeOption",
                        { option: group }
                      );
                    }}
                    sx={{ marginRight: 1, marginBottom: 1 }}
                  />
                )
              )}
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default FilterBy;
