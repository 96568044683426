import React, { useState, useEffect, useContext } from "react";
import { modalBoxStyle } from "../styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
  Switch,
  CircularProgress,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Store from "../../../Store/Store";
import inventoryClient from "../../../clients/inventoryClient";
import { useMutation, useQueryClient } from "react-query";

const EditRecipeModal = ({
  isOpen,
  toggleModalOpen,
  buttonText,
  headerText,
  recipe,
}) => {
  const [recipeActiveCopy, toggleRecipeActive] = useState();
  const [isSharedWithStaffCopy, toggleIsSharedWithStaff] = useState(false);
  const [recipeName, setRecipeName] = useState("");
  const store = useContext(Store);
  const queryClient = useQueryClient();

  useEffect(() => {
    setRecipeName(recipe.name);
    toggleRecipeActive(recipe.isActive);
    toggleIsSharedWithStaff(recipe.isSharedWithStaff);
  }, [recipe, isOpen]);

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const handleActiveStatus = () => {
    toggleRecipeActive(!recipeActiveCopy);
  };

  const handleRecipeName = (e) => {
    setRecipeName(e.target.value);
  };

  const recipeMutation = useMutation(inventoryClient.patchRecipe, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "recipes",
          {
            establishmentId: store.currentEstablishment?.id,
            recipeId: recipe.id,
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "recipes",
          {
            establishmentId: store.currentEstablishment?.id,
            recipeId: recipe.id,
          },
        ],
      });

      closeModal();
    },
  });

  const handleEditRecipe = () => {
    let payload = {
      establishmentId: store.currentEstablishment?.id,
      recipeId: recipe.id,
    };

    payload["IsActiveUpdate"] = { value: recipeActiveCopy };
    payload["NameUpdate"] = { value: recipeName };
    payload["isSharedWithStaffUpdate"] = { value: isSharedWithStaffCopy }

    recipeMutation.mutate(payload);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        <form>
          <Grid
            container
            direction="column"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Typography variant="h2">{headerText}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: 8, width: "100%" }}>
              <TextField
                size="small"
                required
                fullWidth
                defaultValue={recipeName}
                label="Name"
                InputLabelProps={{ shrink: true }}
                onChange={handleRecipeName}
              />
            </Box>
            <Box
              sx={{
                marginTop: 6,
                width: "100%",
                height: "40px",
                backgroundColor: (theme) => theme.palette.secondary[50],
                borderRadius: 2,
              }}
            >
              <Grid container direction="row">
                <Grid item>
                  <Switch
                    checked={recipeActiveCopy}
                    onClick={handleActiveStatus}
                  />
                </Grid>
                <Grid item sx={{ alignItems: "center", display: "flex" }}>
                  {" "}
                  <Typography variant="subtitle2">
                    {recipeActiveCopy
                      ? "Recipe Status: Active"
                      : "Recipe Status: Inactive"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                marginTop: 2,
                width: "100%",
                height: "40px",
                backgroundColor: (theme) => theme.palette.secondary[50],
                borderRadius: 2,
              }}
            >
              <Grid container direction="row">
                <Grid item>
                  <Switch
                    checked={isSharedWithStaffCopy}
                    onClick={() => toggleIsSharedWithStaff(!isSharedWithStaffCopy)}
                  />
                </Grid>
                <Grid item sx={{ alignItems: "center", display: "flex" }}>
                  {" "}
                  <Typography variant="subtitle2">
                    {isSharedWithStaffCopy ? "Show to Staff" : "Hide from Staff"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3rem",
              }}
            >
              {recipeMutation.isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  onClick={handleEditRecipe}
                  disabled={recipeMutation.isLoading || !recipeName}
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="largeButton">{buttonText}</Typography>
                </Button>
              )}
            </Box>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default EditRecipeModal;
