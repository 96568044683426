import React, { useContext, useState } from "react";
import { Box, Button, Typography, Grid, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Store from "../../../Store/Store";

const BackButtonStyle = {
  background: "#ffffff",
  height: "42px",
  border: "1px solid #000000",
  borderColor: (theme) => theme.palette.primary.main,
  borderRadius: "8px",
  marginRight: "16px",
  padding: "8px 64px",
  color: "#000000",
};

const FooterGridWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 10,
  borderTop: "1px solid",
  borderColor: theme.palette.terrain[400],
  backgroundColor: theme.palette.terrain[50],
  position: "fixed",
  bottom: 0,
  marginLeft: 0,
  marginRight: 10,
  width: "96%",
  maxWidth: "2440px",
  height: "80px",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: "100%",
    maxWidth: "2200px",
    marginRight: 10,
    marginLeft: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  display: "flex",
  alignItems: "center",
}));

const DistributorFormFooter = ({
  activeStep,
  setActiveStep,
  newDistributors,
  submit,
}) => {
  const [loading, toggleLoading] = useState(false);

  const { sideNavOpen } = useContext(Store);

  const handleNext = () => {
    toggleLoading(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderFooterSection = () => {
    if (activeStep === 0) {
      return (
        <FooterGridWrapper open={sideNavOpen}>
          <Grid
            container
            sx={{
              width: { xs: "90%", md: `calc(96% - 240px)` },
              maxWidth: "2440px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid
              item
              sx={{
                height: "80px",
                padding: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                disabled={newDistributors.length === 0}
                onClick={handleNext}
                sx={{ width: "180px", height: "42px" }}
              >
                <Typography variant="largeButton">Next</Typography>{" "}
              </Button>
            </Grid>
          </Grid>
        </FooterGridWrapper>
      );
    }

    if (activeStep === 1 || activeStep === 2 || activeStep === 3) {
      return (
        <FooterGridWrapper open={sideNavOpen}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: { xs: "90%", md: `calc(96% - 240px)` },
              maxWidth: "2440px",
            }}
          >
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={BackButtonStyle}
                onClick={handleBack}
              >
                <Typography variant="smallButton">Back</Typography>
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="button"
                variant="contained"
                sx={{ width: "160px", height: "42px" }}
                onClick={handleNext}
              >
                <Typography variant="largeButton">Next</Typography>{" "}
              </Button>
            </Grid>
          </Grid>
        </FooterGridWrapper>
      );
    }

    if (activeStep === 4) {
      return (
        <FooterGridWrapper open={sideNavOpen}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: { xs: "90%", md: `calc(96% - 240px)` },
              maxWidth: "2440px",
            }}
          >
            <Grid item sx={{ marginLeft: 2 }}>
              <Button
                variant="contained"
                sx={BackButtonStyle}
                onClick={handleBack}
              >
                <Typography variant="smallButton">Back</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Box sx={{ padding: 2 }}>
                <Button
                  type="button"
                  variant="contained"
                  sx={{ width: "160px", height: "42px" }}
                  onClick={submit}
                >
                  <Typography variant="largeButton">
                    {loading ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      "Complete"
                    )}
                  </Typography>{" "}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </FooterGridWrapper>
      );
    }

    return null;
  };

  return <Box sx={{ width: "100%" }}>{renderFooterSection()}</Box>;
};

export default DistributorFormFooter;
