import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useRecipePaginatedSearch(establishmentId, searchTerm, page, filters) {
    const queryReturnValues = useQuery(
      [
        "paginated-recipe-search",
        {
          establishmentId,
          page,
          searchTerm,
          filters
        },
      ],
      inventoryClient.searchRecipesPaginated,
      {
        enabled: !!searchTerm,
        refetchOnWindowFocus: false,
      }
    );

    return queryReturnValues;
}

export default useRecipePaginatedSearch;