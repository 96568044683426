import React, { useState } from "react";
import { Alert, Box, Grid, Link, Snackbar, Typography } from "@mui/material";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import { useParams } from "react-router-dom";
import SpecSpinner from "../../components/SpecSpinner";
import ReportingHeader from "./components/ReportingHeader";
//import ProductLibraryReport from "./components/ProductLibraryReport";
import OrderingReport from "./components/OrderingReport";
import InvoiceReport from "./components/InvoiceReport";
import StockCountReport from "./components/StockCountReport";
import useEstablishmentSettings from "../../hooks/useEstablishmentSettings";
import useVendors from "../../hooks/useVendors";
import useProductLibrary from "../../hooks/useProductLibrary";
import useEstablishmentUoMs from "../../hooks/useEstablishmentUoMs";
import SubscriptionScreenLock from "../../components/SubscriptionScreenLock";
//import RecipesReport from "./components/RecipesReport";
//import MenusReport from "./components/MenusReport";
// import ArticleIcon from "@mui/icons-material/Article";

const Reporting = () => {
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [reportType, setReportType] = useState("Ordering");
  const { establishmentid: establishmentId } = useParams();
  const { data: subscriptionState, isLoading: isLoadingSubscriptionState } =
    useEstablishmentSubscription(establishmentId);
  const { data: settings, isLoading: isLoadingSettings } =
    useEstablishmentSettings(establishmentId);
  // eslint-disable-next-line no-unused-vars
  const [{ isLoading: isLoadingVendors, data: vendorData }, _] =
    useVendors(establishmentId);

  // eslint-disable-next-line no-unused-vars
  const [{ isLoading: isLoadingProducts, data: productData }, __] =
    useProductLibrary(establishmentId);
  const {
    data: unitOfMeasurementData,
    isLoading: isLoadingUnitsOfMeasurement,
  } = useEstablishmentUoMs(establishmentId);

  const handleReportType = (event, newReportType) => {
    setReportType(newReportType);
  };

  if (
    isLoadingSubscriptionState ||
    isLoadingSettings ||
    isLoadingVendors ||
    isLoadingUnitsOfMeasurement ||
    isLoadingProducts
  ) {
    return <SpecSpinner open text="Loading" />;
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "2440px",
          padding: 4,
        }}
      >
        <Grid
          container
          direction="column"
          sx={{
            width: "100%",
            maxWidth: "2440px",
            marginBottom: "100px",
          }}
        >
          <>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: "72px",
                  borderRadius: 1,
                  backgroundColor: "#9900F6",
                  display: "flex",
                  alignItems: "center",
                  padding: 2,
                  marginTop: 6,
                }}
              >
                <Grid container direction="row">
                  <Grid
                    item
                    sx={{ display: "flex", flexDirection: "column" }}
                    xs={12}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: { xs: 10, sm: 12, md: 16 },
                      }}
                    >
                      This Feature is Currently in Beta
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#fff",
                        fontSize: { xs: 8, sm: 10, md: 12 },
                        marginTop: 1,
                      }}
                    >
                      Welcome to the Spec Reporting Beta. We are excited to get
                      this long requested feature out and get your feedback. If
                      you have any issues or suggestions, please email us at{" "}
                      <Link
                        target="_blank"
                        href="mailto:contact@specapp.com"
                        color="#fff"
                        sx={{
                          fontWeight: 900,
                          color: "#fff",
                          textDecoration: "underline #fff",
                          "&:hover": {
                            color: (theme) => theme.palette.primary[800],
                          },
                        }}
                      >
                        contact@specapp.com
                      </Link>
                      !
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              container
              sx={{
                maxWidth: "2440px",
                marginBottom: 6,
                display: "flex",
                justifyContent: "space-between",
                marginTop: 6,
              }}
            >
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h2"
                  sx={{ fontSize: { xs: 20, sm: 20, md: 28 } }}
                >
                  Reporting
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {/* <Box
                  sx={{
                    height: "32px",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.info[700],
                    backgroundColor: (theme) => theme.palette.info[50],
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    marginTop: 6,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.info[700],
                      fontWeight: 700,
                    }}
                  >
                    Product Library, Recipe, and Menu reporting coming soon!
                  </Typography>
                </Box> */}
              </Grid>
              {/* <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "flex-end" },
                  marginTop: { xs: 4, md: 0 },
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    padding: 4,
                    height: "40px",
                  }}
                  onClick={() => toggleInvoiceModalOpen(true)}
                >
                  <ArticleIcon sx={{ fontSize: { xs: 14, sm: 14, md: 20 } }} />
                  <Typography
                    variant="smallButton"
                    sx={{ fontSize: { xs: 10, sm: 12, md: 14 }, marginLeft: 2 }}
                  >
                    Templates
                  </Typography>
                </Button>
              </Grid> */}
            </Grid>
            {/* {!generatingReport && (
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => setGeneratingReport(true)}
                >
                  Generate Report
                </Button>
              </Grid>
            )} */}
          </>
          <Box sx={{ marginTop: 2 }}>
            <ReportingHeader
              reportType={reportType}
              setReportType={setReportType}
              handleReportType={handleReportType}
            />
          </Box>
          <Box sx={{ marginTop: 8 }}>
            {/* {reportType === "Product Library" && <ProductLibraryReport />} */}
            {reportType === "Ordering" && (
              <OrderingReport
                establishmentSettings={settings}
                setAlertSuccess={setAlertSuccess}
                setErrorMessage={setErrorMessage}
                establishmentId={establishmentId}
              />
            )}
            {reportType === "Invoices" && (
              <InvoiceReport
                establishmentId={establishmentId}
                establishmentSettings={settings}
                setAlertSuccess={setAlertSuccess}
                setErrorMessage={setErrorMessage}
                vendors={vendorData?.vendors || []}
                products={productData?.products || []}
                unitsOfMeasurement={
                  unitOfMeasurementData?.unitsOfMeasurement || []
                }
              />
            )}
            {reportType === "Stock Counts" && (
              <StockCountReport
                establishmentId={establishmentId}
                subscriptionState={subscriptionState}
                setAlertSuccess={setAlertSuccess}
                setErrorMessage={setErrorMessage}
              />
            )}
            {/* {reportType === "Recipes" && <RecipesReport />}
            {reportType === "Menus" && <MenusReport />} */}
          </Box>
        </Grid>
        <SubscriptionScreenLock show={!subscriptionState?.isActive} />
        <Box>
          <Snackbar
            open={alertSuccess ? true : false}
            onClose={() => setAlertSuccess(undefined)}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success" variant="filled">
              {alertSuccess}
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorMessage ? true : false}
            onClose={() => setErrorMessage(undefined)}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error" variant="filled">
              {errorMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
      {/* {generatingReport && <ReportingFooter buttonText="Create Report" />} */}
    </>
  );
};

export default Reporting;
