import React from "react";
import { Outlet, Navigate } from "react-router-dom"
import Store from "../../Store/Store";

const ProtectedRoute = () => (
  <Store.Consumer>
    {({ authenticated }) => {
      return authenticated ? <Outlet /> : <Navigate to="/login" />
    }
    }
  </Store.Consumer>
);

export default ProtectedRoute;
