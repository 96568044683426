import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import utilFunctions from "../../utilities/utilFunctions";

const sectionBubbleStyle = {
  borderRadius: 2,
  backgroundColor: (theme) => theme.palette.pure.white,
  border: (theme) => `2px solid ${theme.palette.terrain["03"]}`,
};

const imageStyle = {
  borderRadius: "100%",
  height: "89px",
  width: "89px",
  border: "2px solid #7E7E7E",
  margin: "auto",
};

const WineDetailDisplay = ({ wine }) => (
  <Grid container direction="column" spacing={4}>
    <Grid
      item
      sx={{
        ...sectionBubbleStyle,
        ...{
          height: 137,
          display: "flex",
          alignItems: "center",
          paddingLeft: 6,
          paddingRight: 6,
          paddingBottom: 4,
        },
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <Typography variant="overline">Drink Status: </Typography>
          <Typography
            variant="overline"
            sx={{ color: (theme) => theme.palette.info.main }}
          >
            {wine.active ? "Active" : "Inactive"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">{utilFunctions.capitalizeString(wine.name)}</Typography>
        </Grid>
      </Grid>
      {wine.imageURL && <img src={wine.imageURL} style={imageStyle} />}
    </Grid>
    <Grid item>
      <Grid container direction="row" spacing={4}>
        <Grid item sx={{ paddingLeft: "0 !important" }} md={6}>
          <Grid container direction="column">
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Vintage
              </Typography>
              <Typography variant="body1">{wine.vintage || ""}</Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Country
              </Typography>
              <Typography variant="body1">{wine.country || ""}</Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Varietals
              </Typography>
              <Typography variant="body1">{wine.varietals || ""}</Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                History
              </Typography>
              <Typography variant="body1">{wine.history || ""}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container direction="column">
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                ABV
              </Typography>
              <Typography variant="body1">
                {wine.abv ? `${wine.abv}%` : ""}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Region
              </Typography>
              <Typography variant="body1">{wine.region || ""}</Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Winemaking Notes
              </Typography>
              <Typography variant="body1">
                {wine.winemakingNotes || ""}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Tasting Notes
              </Typography>
              <Typography variant="body1">{wine.tastingNotes || ""}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default WineDetailDisplay;
