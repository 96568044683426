import { SvgIcon } from "@mui/material";
import React from "react";

const UnlinkedIcon = (props) => {
  return (
    <SvgIcon
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1831 2.31039C12.7238 2.74029 13.1395 3.2481 13.4266 3.83317L16.4795 0.833313L17.75 2.08167L1.50232 18.0468L0.25 16.7806L8.34227 8.82906V6.63035L10.1165 6.63035V7.08571L12.0358 5.19974C11.9189 4.70504 11.6655 4.2641 11.269 3.87452C10.7049 3.32027 10.0293 3.04489 9.22937 3.04489C8.42949 3.04489 7.7538 3.32027 7.18973 3.87452C6.62582 4.42863 6.34727 5.0905 6.34727 5.87246L6.34727 9.7986H4.57307V5.87246C4.57307 4.60672 5.02974 3.52201 5.9367 2.63083C6.8435 1.7398 7.9453 1.29274 9.22937 1.29274C10.3423 1.29274 11.3298 1.63202 12.1831 2.31039ZM13.3539 4.21614C13.3243 4.14213 13.2928 4.0693 13.2592 3.99764C12.9866 3.41577 12.582 2.91146 12.0452 2.48471C12.6481 2.96401 13.0843 3.54116 13.3539 4.21614Z"
        fill="#C74822"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26258 14.598L4.7757 16.059L4.83443 16.1985C5.20516 17.0788 5.77841 17.7946 6.55164 18.3416C7.32866 18.8914 8.20124 19.1666 9.16286 19.1666C10.4469 19.1666 11.5487 18.7196 12.4555 17.8286C13.3625 16.9374 13.8192 15.8527 13.8192 14.5869V10.9876H12.045V14.5869C12.045 15.3689 11.7664 16.0308 11.2025 16.5849C10.6384 17.1391 9.96274 17.4145 9.16286 17.4145C8.46233 17.4145 7.85671 17.1869 7.33592 16.729C6.81124 16.2677 6.48129 15.694 6.34296 15.0008L6.26258 14.598ZM13.5972 11.2101V14.5869C13.5972 14.5869 13.5972 14.587 13.5972 14.5869V11.2101Z"
        fill="#C74822"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.05 10.8765L8.27575 12.6199V13.829H10.05V10.8765Z"
        fill="#C74822"
      />
    </SvgIcon>
  );
};

export default UnlinkedIcon;
