import React from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SpecBackButton from "../../../SpecBackButton";
import AreaOption from "./AreaOption";

const NewTemplateAreasStep = ({
  handleNextAction,
  handleBack,
  areas,
  isLoading,
  toggleAreaTarget,
  areaTargetIds,
  closeModal,
}) => {
  if (isLoading) {
    return (
      <Grid item>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 3,
        }}
      >
        <Grid item>
          <SpecBackButton backText="Back" onClick={handleBack} />
        </Grid>
        <Grid item>
          <IconButton onClick={closeModal}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: 2, display: "flex", flexDirection: "row" }}>
        <Typography variant="h3">Specify Areas</Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: { xs: 10, sm: 10, md: 12 } }}>
          Select the areas you want included in the count.
        </Typography>
      </Box>
      <Box
        sx={{
          marginBottom: 6,
          marginTop: 4,
          overflow: "scroll",
          maxHeight: { xs: "200px", md: "340px" },
        }}
      >
        <Grid container direction="column">
          {areas?.map((x) => (
            <AreaOption
              key={x.id}
              area={x}
              areaTargets={areaTargetIds}
              toggleAreaTarget={toggleAreaTarget}
            />
          ))}
        </Grid>
      </Box>
      <Divider sx={{ marginLeft: -8, marginRight: -8 }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 4,
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
              0 Selected
            </Typography>
            <Typography variant="body1">of 12 Areas</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              onClick={handleNextAction}
              disabled={
                !Object.keys(areaTargetIds).find((x) => areaTargetIds[x])
              }
              sx={{
                width: "80%",
                borderRadius: 2,
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 6,
                paddingRight: 6,
              }}
            >
              <Typography variant="smallButton">Next</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NewTemplateAreasStep;
