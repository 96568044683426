import { SvgIcon } from "@mui/material";
import React from "react";

const CalculatorIcon = (props) => {
  return (
    <SvgIcon
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3.375C3 2.96079 3.33579 2.625 3.75 2.625H14.25C14.6642 2.625 15 2.96079 15 3.375V7.125C15 7.53921 14.6642 7.875 14.25 7.875H3.75C3.33579 7.875 3 7.53921 3 7.125V3.375ZM4.875 6V4.5H13.125V6H4.875Z"
      />
      <path d="M3 11.0625C3 10.1305 3.75552 9.375 4.6875 9.375C5.61948 9.375 6.375 10.1305 6.375 11.0625C6.375 11.9945 5.61948 12.75 4.6875 12.75C3.75552 12.75 3 11.9945 3 11.0625Z" />
      <path d="M9 9.375C8.06802 9.375 7.3125 10.1305 7.3125 11.0625C7.3125 11.9945 8.06802 12.75 9 12.75C9.93198 12.75 10.6875 11.9945 10.6875 11.0625C10.6875 10.1305 9.93198 9.375 9 9.375Z" />
      <path d="M11.625 11.0625C11.625 10.1305 12.3805 9.375 13.3125 9.375C14.2445 9.375 15 10.1305 15 11.0625C15 11.9945 14.2445 12.75 13.3125 12.75C12.3805 12.75 11.625 11.9945 11.625 11.0625Z" />
      <path d="M4.6875 13.6875C3.75552 13.6875 3 14.443 3 15.375C3 16.307 3.75552 17.0625 4.6875 17.0625C5.61948 17.0625 6.375 16.307 6.375 15.375C6.375 14.443 5.61948 13.6875 4.6875 13.6875Z" />
      <path d="M7.3125 15.375C7.3125 14.443 8.06802 13.6875 9 13.6875C9.93198 13.6875 10.6875 14.443 10.6875 15.375C10.6875 16.307 9.93198 17.0625 9 17.0625C8.06802 17.0625 7.3125 16.307 7.3125 15.375Z" />
      <path d="M13.3125 13.6875C12.3805 13.6875 11.625 14.443 11.625 15.375V19.6875C11.625 20.6195 12.3805 21.375 13.3125 21.375C14.2445 21.375 15 20.6195 15 19.6875V15.375C15 14.443 14.2445 13.6875 13.3125 13.6875Z" />
      <path d="M3 19.6875C3 18.7555 3.75552 18 4.6875 18C5.61948 18 6.375 18.7555 6.375 19.6875C6.375 20.6195 5.61948 21.375 4.6875 21.375C3.75552 21.375 3 20.6195 3 19.6875Z" />
      <path d="M9 18C8.06802 18 7.3125 18.7555 7.3125 19.6875C7.3125 20.6195 8.06802 21.375 9 21.375C9.93198 21.375 10.6875 20.6195 10.6875 19.6875C10.6875 18.7555 9.93198 18 9 18Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4375 0C1.09131 0 0 1.09131 0 2.4375V21.5625C0 22.9087 1.09131 24 2.4375 24H15.5625C16.9087 24 18 22.9087 18 21.5625V2.4375C18 1.09131 16.9087 0 15.5625 0H2.4375ZM1.875 2.4375C1.875 2.12684 2.12684 1.875 2.4375 1.875H15.5625C15.8732 1.875 16.125 2.12684 16.125 2.4375V21.5625C16.125 21.8732 15.8732 22.125 15.5625 22.125H2.4375C2.12684 22.125 1.875 21.8732 1.875 21.5625V2.4375Z"
      />
    </SvgIcon>
  );
};

export default CalculatorIcon;
