import React from "react";
import { TableBody } from "@mui/material";
import StaffTableRow from "./StaffTableRow";

const StaffDisplay = ({
  staffMembers,
  currentUserId,
  setUserToEdit,
  setUserToDelete,
  currentUser,
  setUserToOwnerTransfer,
}) => {
  const handleSelectStaffToDelete = (staffMember) => {
    setUserToDelete(staffMember);
  };

  const handleSelectStaffToEdit = (staffMember) => {
    setUserToEdit(staffMember);
  };

  const handleSelectStaffForOwnerTransfer = (staffMember) => {
    setUserToOwnerTransfer(staffMember);
  }

  return (
    <>
      <TableBody>
        {staffMembers.map((c) => (
          <StaffTableRow
            key={c.userId}
            staffMember={c}
            currentUserId={currentUserId}
            deleteStaffCallback={handleSelectStaffToDelete}
            editPermissionsCallback={handleSelectStaffToEdit}
            currentUser={currentUser}
            ownershipTransferCallback={handleSelectStaffForOwnerTransfer}
          />
        ))}
      </TableBody>
    </>
  );
};

export default StaffDisplay;
