import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import LiveEditErrorModal from "../../components/Modals/LiveEditErrorModal";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import CTABlock from "../../components/CTABlock";
import OrderTable from "./components/OrderTable";
import Store, { activeRouteKeys } from "../../Store/Store";
import useExistingOrders from "../../hooks/useExistingOrders";
import SpecSpinner from "../../components/SpecSpinner";
import useOrderCart from "../../hooks/useOrderCart";
import useVendors from "../../hooks/useVendors";
import SnackBarSuccess from "../../components/SnackBarSuccess";
import SnackBarError from "../../components/SnackBarError";

const Ordering = () => {
  const [errorModalOpen, toggleErrorModalOpen] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const store = useContext(Store);
  const { establishmentid } = useParams();

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.ordering,
      `spec/${establishmentid}/ordering`
    );
  }, []);

  const { isLoading: isLoadingCart, data: cartData } = useOrderCart(
    store.currentEstablishment?.id
  );
  const {
    isLoading: isLoadingOrders,
    data,
    refetch: refetchExistingOrders,
  } = useExistingOrders(store.currentEstablishment?.id, page);
  const [{ isLoading: isLoadingVendors }, vendorMap] = useVendors(
    store.currentEstablishment?.id
  );

  if (isLoadingOrders || isLoadingCart || isLoadingVendors) {
    return <SpecSpinner open text="Loading past orders..." />;
  }
  return (
    <>
      <Grid
        container
        sx={{
          maxWidth: "2440px",
          padding: 4,
          marginTop: 6,
        }}
      >
        <>
          <Grid
            container
            sx={{
              maxWidth: "2440px",
              marginBottom: 6,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={8}>
              <Typography
                variant="h2"
                sx={{ fontSize: { xs: 20, sm: 20, md: 28 } }}
              >
                Ordering
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: { xs: 4, md: 0 },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 2,
                  padding: 4,

                  height: "40px",
                }}
                onClick={() =>
                  navigate(
                    `/spec/${store.currentEstablishment?.id}/ordering/new`
                  )
                }
              >
                <AddIcon sx={{ fontSize: { xs: 14, sm: 14, md: 20 } }} />
                <Typography
                  variant="smallButton"
                  sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
                >
                  {cartData?.cart?.items?.length > 0
                    ? "Continue Order"
                    : "New Order"}
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%" }}>
            <Grid
              container
              sx={{
                marginBottom: 6,
                marginLeft: 0,
                display: "flex",
                justifyContent: "space-between",
                alignitems: "center",
                maxWidth: "2440px",
              }}
            >
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: { xs: 2, sm: 2, md: 0 },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontSize: { xs: 16, sm: 16, md: 20 } }}
                >
                  Order History
                </Typography>
              </Grid>
              {/* Search Bar */}
              {/* <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <TextField
                  size="small"
                  fullWidth
                  id="productSearch"
                  autoComplete="off"
                  inputProps={{
                    sx: {
                      marginBottom: 1,
                    },
                  }}
                  sx={{
                    backgroundColor: (theme) => theme.palette.pure.white,
                    borderRadius: 1,
                  }}
                  label={
                    <>
                      <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                      Search Orders...
                    </>
                  }
                />
                <Button
                  variant="outlined"
                  sx={{
                    marginLeft: 2,
                    border: "2px solid",
                    borderColor: (theme) => theme.palette.primary.main,
                  }}
                >
                  <TuneOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                  <Typography variant="largeButton">Filters</Typography>
                </Button>
              </Grid> */}
            </Grid>
            <Grid container sx={{ marginBottom: 2, width: "100%" }}>
                <Box
                sx={{
                  width: "100%",
                  maxWidth: "2440px",
                }}
              >
                {data?.orders?.length > 0 ? (
                  <OrderTable
                    orders={data.orders}
                    refetchExistingOrders={refetchExistingOrders}
                    vendorMap={vendorMap}
                    setPage={setPage}
                    page={page}
                    totalPages={data.totalPages}
                    setAlertSuccess={setAlertSuccess}
                  />
                ) : (
                  <CTABlock
                    handleCTA={() =>
                      navigate(
                        `/spec/${store.currentEstablishment?.id}/ordering/new`
                      )
                    }
                    ButtonImage={AddIcon}
                    buttonText="New Order"
                    header="You have not created any orders yet."
                    subheader="Click the button below to begin your first order!"
                  />
                )}
              </Box>
            </Grid>
          </Box>
        </>
      </Grid>
      <LiveEditErrorModal
        isOpen={errorModalOpen}
        toggleErrorModalOpen={toggleErrorModalOpen}
        bodyText="Are you sure you want to navigate back? You will lose any unsaved information."
        headerText="Hold on..."
        buttonText="Go Back"
      />
      <Box>
        <SnackBarSuccess
          alertSuccess={alertSuccess}
          setAlertSuccess={setAlertSuccess}
          durationLength={5000}
        />
        <SnackBarError
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          durationLength={3000}
        />
      </Box>
    </>
  );
};

export default Ordering;
