import React from "react";
import { modalBoxStyle } from "./styles";
import { Typography, Box, Modal, Button } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { removeEmployee } from "../../clients/establishmentsClient";

const DeleteStaffModal = ({
  staffMember,
  open,
  establishmentId,
  toggleDeleteStaffModal,
  setAlertSuccess,
  setAlertMessage,
  toggleError,
  setErrorMessage,
  fetchEstablishmentMemberships,
}) => {
  async function removeStaffMember() {
    try {
      await removeEmployee(establishmentId, staffMember.userId);
      setAlertSuccess(true);
      setAlertMessage("Staff member removed successfully!");
      toggleDeleteStaffModal();
      fetchEstablishmentMemberships();
    } catch (err) {
      console.log(err);
      toggleError(true);
      setErrorMessage(
        "There was a problem removing staff member. Please refresh and try again."
      );
    }
  }

  const handleCloseModal = () => {
    setAlertSuccess(false);
    toggleError(false);
    toggleDeleteStaffModal();
  };

  if (!staffMember) {
    return null;
  }

  return (
    <div>
      <Modal open={open} onClose={handleCloseModal}>
        <Box sx={modalBoxStyle}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DeleteOutlinedIcon sx={{ fontSize: 100 }} />
          </div>
          <Typography
            style={{
              marginBottom: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
            variant="h2"
          >
            {" "}
            Remove Staff Member{" "}
          </Typography>
          <Typography
            style={{
              marginBottom: "1rem",
              display: "flex",
              textAlign: "center",
            }}
            variant="body1"
          >
            {" "}
            Are you sure you want to remove {staffMember.firstName}{" "}
            {staffMember.lastName}? By selecting ‘Yes, Remove’, they will no
            longer be able to access this establishments content.{" "}
          </Typography>

          <div
            style={{
              display: "flex",
              marginTop: "1rem",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{ color: "black", borderRadius: 2 }}
              onClick={handleCloseModal}
            >
              <Typography variant="smallButton">No, Cancel</Typography>
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                marginLeft: "1rem",
                padding: "0px 25px 0px 25px",
                borderRadius: 2,
              }}
              onClick={removeStaffMember}
            >
              <Typography variant="smallButton">Yes, Remove</Typography>
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteStaffModal;
