import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  // Checkbox,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Store from "../../../../Store/Store";
import inventoryClient from "../../../../clients/inventoryClient";
import { MoreHorizOutlined } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import utilFunctions from "../../../../utilities/utilFunctions";
import { useMutation } from "react-query";
import DeleteProductModal from "../../../../components/Modals/DeleteProductModal";

const tableCellStyle = {
  padding: 0,
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const TrainingDetailsRow = ({ product, updateProductCallback, deleteCallback }) => {
  const [productCopy, setProductCopy] = useState({});
  const [descriptionDisabled, toggleDescriptionDisabled] = useState(false);
  const [tastingNotesDisabled, toggleTastingNotesDisabled] = useState(false);
  const [toggleDeleteProductModalOpen, setDeleteProductModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const store = useContext(Store);

  useEffect(() => {
    setProductCopy(product);
  }, [product]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteProductMutation = useMutation(inventoryClient.deleteProduct, {
    onSuccess: () => {
      deleteCallback();
    },
  });

  const handleProductUpdate = async (property) => {
    switch (property) {
      case "description":
        toggleDescriptionDisabled(true);
        await inventoryClient.updateProduct(
          {
            id: productCopy.id,
            descriptionUpdate: { value: productCopy[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(productCopy);
        toggleDescriptionDisabled(false);
        break;
      case "tastingNotes":
        toggleTastingNotesDisabled(true);
        await inventoryClient.updateProduct(
          {
            id: productCopy.id,
            tastingNotesUpdate: { value: productCopy[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(productCopy);
        toggleTastingNotesDisabled(false);
        break;
      default:
        console.log("default case hit");
    }
  };

  const handleCopyUpdate = (property, newValue) => {
    const newData = Object.assign({}, productCopy);
    newData[property] = newValue;
    setProductCopy(newData);
  };

  const handleDeleteProduct = async () => {
    try {
      const payload = {
        establishmentId: store.currentEstablishment?.id,
        productId: product?.id,
      };

      await deleteProductMutation.mutateAsync(payload);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteModal = () => {
    setDeleteProductModalOpen(!toggleDeleteProductModalOpen);
  };

  return (
    <TableRow>
      {/* CHECKBOX */}
      {/* <TableCell
        sx={[
          tableCellStyle,
          {
            borderLeft: "none",
            backgroundColor: (theme) => theme.palette.pure.white,
          },
        ]}
      >
        <Checkbox sx={{ color: (theme) => theme.palette.primary[800] }} />
      </TableCell> */}
      <TableCell sx={[tableCellStyle, { borderLeft: "none" }]}>
        <Box sx={{ paddingLeft: 2 }}>
          <Typography variant="body2">{productCopy.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            {productCopy.productFamily &&
              productCopy.productFamily.id !== -1 &&
              productCopy.productFamily.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: (theme) => theme.palette.terrain[400],
              marginLeft: 1,
              marginRight: 1,
            }}
          >
            {productCopy.category && productCopy.category.id !== -1 && "/"}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            {productCopy.category &&
              productCopy.category.id !== -1 &&
              productCopy.category.name}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: (theme) => theme.palette.terrain[400],
              marginLeft: 1,
              marginRight: 1,
            }}
          >
            {productCopy.subcategory &&
              productCopy.subcategory.id !== -1 &&
              " / "}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            {productCopy.subcategory &&
              productCopy.subcategory.id !== -1 &&
              productCopy.subcategory.name}{" "}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={tableCellStyle}>
        {" "}
        <TextField
          size="small"
          fullWidth
          variant="filled"
          id="description"
          placeholder="Description"
          autoComplete="off"
          disabled={descriptionDisabled}
          onKeyDown={utilFunctions.blurInput}
          onBlur={() => handleProductUpdate("description")}
          InputProps={{ disableUnderline: true }}
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          value={productCopy.description || ""}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />{" "}
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <TextField
          size="small"
          fullWidth
          variant="filled"
          id="tastingNotes"
          placeholder="Tasting Notes"
          autoComplete="off"
          disabled={tastingNotesDisabled}
          onKeyDown={utilFunctions.blurInput}
          value={productCopy.tastingNotes || ""}
          InputProps={{ disableUnderline: true }}
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          onBlur={() => handleProductUpdate("tastingNotes")}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />{" "}
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "40px", borderRight: "none" }]}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            sx={{ width: "32px", height: "32px" }}
            onClick={handleClick}
          >
            <MoreHorizOutlined
              sx={{
                color: (theme) => theme.palette.primary[800],
                fontSize: 20,
              }}
            />
          </IconButton>
        </Box>
      </TableCell>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem sx={{ margin: "5px" }} onClick={handleDeleteModal} disabled={deleteProductMutation?.isLoading}>
          <DeleteOutlineOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
          <Typography variant="body1">Delete</Typography>
        </MenuItem>
      </Menu>
      <DeleteProductModal
        handleDeleteProduct={handleDeleteProduct}
        product={product}
        isOpen={toggleDeleteProductModalOpen}
        toggleModalOpen={handleDeleteModal}
      />
    </TableRow>
  );
};

export default TrainingDetailsRow;
