import React from "react";
import { Box, Typography } from "@mui/material";
import inventoryObjects from "../../clients/inventoryObjects";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const InvoiceStatusChip = ({ status }) => {
  const productStatusText = {
    1: "Unreconciled",
    2: "Reconciled",
    3: "Approved",
  };

  const productStatusStyle = () => {
    if (status === inventoryObjects.invoiceStatuses.unreconciled) {
      return {
        backgroundColor: (theme) => theme.palette.error[100],
        color: (theme) => theme.palette.pure.black,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.black,
        },
      };
    }
    if (status === inventoryObjects.invoiceStatuses.reconciled) {
      return {
        backgroundColor: (theme) => theme.palette.info[500],
        color: (theme) => theme.palette.pure.white,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.white,
        },
      };
    }
    if (status === inventoryObjects.invoiceStatuses.approved) {
      return {
        backgroundColor: (theme) => theme.palette.success[800],
        color: (theme) => theme.palette.pure.white,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.white,
        },
      };
    }
  };

  return (
    <Box
      sx={[
        productStatusStyle,
        {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      ]}
    >
      {status === inventoryObjects.invoiceStatuses.approved && (
        <CheckOutlinedIcon
          sx={{
            color: (theme) => theme.palette.pure.white,
            fontSize: { xs: 14, md: 20 },
            marginRight: 2,
          }}
        />
      )}
      <Typography variant="bod2" sx={{ fontSize: { xs: 9, md: 12 } }}>
        {productStatusText[status]}
      </Typography>
    </Box>
  );
};

export default InvoiceStatusChip;
