import React, { useState, useContext } from "react";
import { modalBoxStyle } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Typography,
  Box,
  Modal,
  Tabs,
  Tab,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { sendEstablishmentInvite } from "../../clients/establishmentsClient";
import TabPanel from "../TabPanel";
import MuiPhoneNumber from "material-ui-phone-number";
import Store from "../../Store/Store";

const AddStaffMemberModal = ({
  establishmentId,
  open,
  toggleModal,
  setAlertSuccess,
  setAlertMessage,
  toggleError,
  setErrorMessage,
}) => {
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const [value, setValue] = useState(0);
  const store = useContext(Store);

  const sendAddStaffForm = async (telecomInput) => {
    try {
      let telecomType = "null";
      if (telecomInput.includes("@")) {
        telecomType = "email";
      } else {
        telecomType = "phone";
      }

      await sendEstablishmentInvite(
        establishmentId,
        "user",
        telecomType,
        telecomInput
      );
      setAlertSuccess(true);
      setAlertMessage("Staff member added successfully!");
      toggleError(false);
      setDisplaySuccessMessage(true);
    } catch (err) {
      console.log(err);
      toggleError(true);
      setErrorMessage(
        "There was a problem adding staff member. Please refresh and try again."
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseModal = () => {
    setAlertSuccess(false);
    toggleError(false);
    setDisplaySuccessMessage(false);
    setValue(0);
    toggleModal();
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      {displaySuccessMessage ? (
        <Box sx={modalBoxStyle}>
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <FontAwesomeIcon
              color="#5D5D5D"
              style={{ padding: 10 }}
              icon={faCheckCircle}
              size="7x"
            />
            <Typography
              variant="h2"
              sx={{
                color: (theme) => theme.palette.terrain["01"],
                marginBottom: 3,
              }}
            >
              Invite Sent!
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: (theme) => theme.palette.terrain["01"] }}
            >
              The employee has been sent an invite and they will receive the
              request to join the establishment momentarily.
            </Typography>

          </Box>
          <Box
            component={"div"}
            sx={{
              display: "flex",
              marginTop: "1rem",
              justifyContent: "right",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: 2,
              }}
              onClick={() => setDisplaySuccessMessage(false)}
            >
              <Typography variant="smallButton">Ok</Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={modalBoxStyle}>
          <Typography
            variant="h4"
            sx={{ fontSize: "2rem", marginBottom: "1rem" }}
          >
            Add Staff Member
          </Typography>
          <Divider sx={{ borderBottomWidth: 2, marginBottom: 2 }} />
          <Typography
            variant="body1"
          >
            Add a new staff member by entering either thier mobile number or
            email address.
          </Typography>
          <Box sx={{ marginTop: 6 }}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                sx={{ marginBottom: 4 }}
              >
                <Tab component={"span"} label="Email Address" />
                <Tab component={"span"} label="Phone Number" />
              </Tabs>
            </Box>
            <Box>
              <TabPanel value={value} index={0}>
                <Box>
                  <TextField
                    size="small"
                    fullWidth
                    label="Email Address"
                    id="telecomId"
                    name="telecomId"
                  />
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box>
                  <MuiPhoneNumber
                    size="small"
                    sx={{ width: "100%" }}
                    disableAreaCodes
                    defaultCountry={store.selectedCountry || "us"}
                    InputLabelProps={{ shrink: true }}
                    label="Mobile Number"
                    placeholder="Enter remaining digits"
                    variant="outlined"
                    id="telecomId"
                  />
                </Box>
              </TabPanel>
            </Box>
          </Box>
          <Box
            component={"div"}
            sx={{
              display: "flex",
              marginTop: "1rem",
              justifyContent: "right",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: 2,
              }}
              onClick={handleCloseModal}
            >
              <Typography variant="smallButton">Cancel</Typography>
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                marginLeft: "1rem",
                borderRadius: 2,
              }}
              onClick={(e) => {
                e.preventDefault();
                sendAddStaffForm(document.getElementById("telecomId").value);
              }}
            >
              <Typography variant="smallButton">Send</Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default AddStaffMemberModal;
