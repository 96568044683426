import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const SliderInput = ({ recordValue, updateProductQuantity, onClose }) => {
  const [wholeNumbersDisplay, setWholeNumbersDisplay] = useState("0");
  const [fractionalNumbersDisplay, setFractionalNumbersDisplay] =
    useState("00");

  useEffect(() => {
    if (recordValue && !isNaN(Number(recordValue))) {
      const valueFixed = parseFloat(recordValue).toFixed(2);
      const wholeNum = valueFixed.split(".")[0];
      const fractNum = valueFixed.split(".")[1];

      setWholeNumbersDisplay(wholeNum);
      setFractionalNumbersDisplay(fractNum);
    }
  }, [recordValue]);

  const handleSliderChange = (e) => {
    if (e.target.value <= 9) {
      setFractionalNumbersDisplay(`0${e.target.value}`);
    } else {
      setFractionalNumbersDisplay(e.target.value);
    }
  };

  const handleRemoveCount = () => {
    const currentWhole = Number(wholeNumbersDisplay);

    if (currentWhole === 0) {
      return;
    }

    setWholeNumbersDisplay(`${currentWhole - 1}`);
  };

  const handleAddCount = () => {
    setWholeNumbersDisplay(`${Number(wholeNumbersDisplay) + 1}`);
  };

  const handleSaveValue = () => {
    const finalValue = parseFloat(
      wholeNumbersDisplay + "." + fractionalNumbersDisplay
    );
    updateProductQuantity(finalValue);
    onClose();
  };

  return (
    <Box
      sx={{
        width: { xs: "325px", md: "400px" },
        margin: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          sx={{
            width: { xs: "325px", md: "400px" },
            height: "95px",
            border: "1px solid",
            backgroundColor: (theme) => theme.palette.pure.white,
            borderColor: (theme) => theme.palette.terrain[300],
            borderRadius: 2,
          }}
        >
          <Grid item sx={{ padding: 2 }}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <IconButton onClick={handleRemoveCount}>
                  <RemoveOutlinedIcon
                    sx={{
                      color: (theme) => theme.palette.primary[800],
                    }}
                  />{" "}
                </IconButton>
              </Grid>
              <Grid item sx={{ display: "flex" }}>
                <Typography variant="h3">{wholeNumbersDisplay}</Typography>
                <Typography variant="h3">.</Typography>
                <Typography variant="h3">{fractionalNumbersDisplay}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleAddCount}>
                  <AddOutlinedIcon
                    sx={{
                      color: (theme) => theme.palette.primary[800],
                    }}
                  />{" "}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid
            item
            sx={{
              borderTop: "1px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              width: { xs: "325px", md: "400px" },
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid
                item
                sx={{
                  width: "25%",
                  borderRight: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button>
                  <Typography
                    variant="smallButton"
                    sx={{
                      color: (theme) => theme.palette.primary[800],
                    }}
                  >
                    -1 Case
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                sx={{
                  width: "25%",
                  borderRight: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Button>
                  <Typography
                    variant="smallButton"
                    sx={{
                      color: (theme) => theme.palette.primary[800],
                    }}
                  >
                    -.5 Case
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                sx={{
                  width: "25%",
                  borderRight: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Button>
                  <Typography
                    variant="smallButton"
                    sx={{
                      color: (theme) => theme.palette.primary[800],
                    }}
                  >
                    +.5 Case
                  </Typography>
                </Button>
              </Grid>
              <Grid
                item
                sx={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Button>
                  <Typography
                    variant="smallButton"
                    sx={{
                      color: (theme) => theme.palette.primary[800],
                    }}
                  >
                    +1 Case
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: 4,
        }}
      >
        <Grid
          item
          sx={{
            width: { xs: "325px", md: "400px" },
            height: "60px",
            border: "1px solid",
            backgroundColor: (theme) => theme.palette.pure.white,
            borderColor: (theme) => theme.palette.terrain[300],
            borderRadius: 2,
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Grid item xs={12} sx={{ padding: 2 }}>
              <Slider
                value={Number(fractionalNumbersDisplay)}
                min={0}
                max={99}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* Checkbox Component */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: 4,
        }}
      >
        <Grid
          container
          direction={"row"}
          justifyContent="center"
          sx={{ width: { xs: "325px", md: "400px" } }}
        >
          <Grid item sx={{ width: "100%" }}>
            <Button
              variant="contained"
              onClick={handleSaveValue}
              sx={{ width: "100%" }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        {/* Do we need this no product in section? What does it do? */}
        {/* <Grid
          item
          sx={{
            width: "400px",
            height: "60px",
            border: "1px solid",
            backgroundColor: (theme) => theme.palette.pure.white,
            borderColor: (theme) => theme.palette.terrain[300],
            borderRadius: 2,
          }}
        >
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item>
              <Checkbox sx={{ color: (theme) => theme.palette.primary[800] }} />
            </Grid>
            <Grid item>No product in this section</Grid>
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  );
};

export default SliderInput;
