import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useRecentUpdates(establishmentId) {
    const queryReturnValues = useQuery(
        ["recent-updates", { establishmentId }],
        inventoryClient.getRecentUpdates
    );

   return queryReturnValues;
}

export default useRecentUpdates;