import React from "react";
import { Box } from "@mui/material";
import PurchaseDetailsCard from "./PurchaseDetailsCard";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CTABlock from "../../../../components/CTABlock";

const PurchaseDetailsTab = function PurchaseDetailsTab({
  inventory,
  vendors,
  updateProductCallback,
  triggerError,
  toggleAddingProduct,
  searching,
  expenseGroups,
  openExpenseGroupEdit,
  openExpenseSubgroupEdit,
  updateExpandedParent,
  refetchCurrentPageProducts,
  subscriptionData
}) {
  if (inventory.length > 0)
    return (
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {inventory && inventory.length > 0 && (
            <Box>
              {inventory.map((x) => (
                <PurchaseDetailsCard
                  product={x}
                  key={x.id}
                  vendors={vendors}
                  updateProductCallback={updateProductCallback}
                  triggerError={triggerError}
                  expenseGroups={expenseGroups}
                  openExpenseGroupEdit={openExpenseGroupEdit}
                  openExpenseSubgroupEdit={openExpenseSubgroupEdit}
                  updateExpandedParent={updateExpandedParent}
                  refetchCurrentPageProducts={refetchCurrentPageProducts}
                  subscriptionData={subscriptionData}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    );
  return searching ? (
    <CTABlock
      header="No Results Found"
      hideButton
      ButtonImage={AddOutlinedIcon}
    />
  ) : (
    <CTABlock
      header="Your Product List is Empty..."
      subheader="You have not added ay products yet. Get started now..."
      buttonText="Add Products"
      handleCTA={toggleAddingProduct}
      ButtonImage={AddOutlinedIcon}
    />
  );
};

export default PurchaseDetailsTab;
