import React, { useState } from "react";
import { modalBoxStyle } from "./styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const DeleteRecipeModal = ({
  deleteRecipe,
  isOpen,
  recipeName,
  recipeId,
  toggleModalOpen
}) => {
  const [loading, toggleLoading] = useState(false);

  const closeModal = () => {
    toggleModalOpen();
  };

  const handleDelete = async () => {
    try {
      toggleLoading(true);
      await deleteRecipe(recipeId);
      toggleLoading(false);
    } catch (err) {
      console.log(err);
      toggleLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <DeleteOutlineOutlinedIcon sx={{ fontSize: "60px" }} />
              </Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h2">Delete Recipe</Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  Are you sure you want to delete &quot;{recipeName}&quot;?
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{ borderRadius: 2 }}
                  variant="outlined"
                  onClick={() => {
                    toggleModalOpen();
                  }}
                >
                  <Typography variant="smallButton">No, Cancel</Typography>
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ borderRadius: 2, marginLeft: 2 }}
                  onClick={handleDelete}
                >
                  <Typography variant="smallButton">Yes, Delete</Typography>
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default DeleteRecipeModal;
