import React, { useState } from "react";
import useEstablishmentAreas from "../../../../hooks/useEstablishmentAreas";
import { useParams } from "react-router-dom";
import useProductFamilies from "../../../../hooks/useProductFamilies";
import NewTemplateNameStep from "./NewTemplateNameStep";
import NewTemplateFamiliesStep from "./NewTemplateFamiliesStep";
import NewTemplateAreasStep from "./NewTemplateAreasStep";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../../clients/inventoryClient";
import { CircularProgress } from "@mui/material";

const CreateNewTemplateFlow = ({
  toggleCreatingNew,
  closeModal,
  toggleIsCreating,
}) => {
  const [newTemplateName, setNewTemplateName] = useState("");
  const [areaTargetIds, setAreaTargetIds] = useState({});
  const [productFamilyTargetIds, setProductFamilyTargetIds] = useState({});
  const [categoryTargetIds, setCategoryTargetIds] = useState({});
  const [subcategoryTargetIds, setSubcategoryTargetIds] = useState({});
  const [creationStep, setCreationStep] = useState(0);
  const { establishmentid: establishmentId } = useParams();
  const queryClient = useQueryClient();
  const { data: areaData, isLoading: isLoadingAreas } =
    useEstablishmentAreas(establishmentId);
  const { data: productFamiliesData, isLoading: isLoadingProductFamilies } =
    useProductFamilies(establishmentId);

  const handleBack = () => {
    if (creationStep === 0) {
      setNewTemplateName("");
      setAreaTargetIds({});
      setProductFamilyTargetIds({});
      setCategoryTargetIds({});
      setSubcategoryTargetIds({});
      toggleCreatingNew(false);
    } else {
      setCreationStep(creationStep - 1);
    }
  };

  const createTemplateMutation = useMutation(inventoryClient.createNewStockCountTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "templates",
          {
            establishmentId
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "templates",
          {
            establishmentId
          },
        ],
      });
      setNewTemplateName("");
      setAreaTargetIds({});
      setProductFamilyTargetIds({});
      setCategoryTargetIds({});
      setSubcategoryTargetIds({});
      toggleCreatingNew(false);
    }
  })

  const handleNextStep = () => {
    if (creationStep === 2) {
      // build payload
      const payload = {
        name: newTemplateName,
        productFamilyTargetIds: Object.keys(productFamilyTargetIds).filter(
          (x) => productFamilyTargetIds[x]
        ),
        categoryTargetIds: Object.keys(categoryTargetIds).filter(
          (x) => categoryTargetIds[x]
        ),
        subcategoryTargetIds: Object.keys(subcategoryTargetIds).filter(
          (x) => subcategoryTargetIds[x]
        ),
        areaTargetIds: Object.keys(areaTargetIds).filter(x => areaTargetIds[x]),
        establishmentId,
      };
      createTemplateMutation.mutate(payload);
    } else {
      setCreationStep(creationStep + 1);
    }
  };

  const handleUpdateName = (e) => {
    setNewTemplateName(e.target.value);
  };

  const selectAll = () => {
    const newTargetObject = Object.assign({}, productFamilyTargetIds);
    const newCategoryTargets = Object.assign({}, categoryTargetIds);
    const newSubcategoryTargets = Object.assign({}, subcategoryTargetIds);

    const newBoolValue = Object.keys(productFamilyTargetIds).length < productFamiliesData?.productFamilyCategories?.length || 
    Object.keys(productFamilyTargetIds)?.some(x => productFamilyTargetIds[x] === false) ? true : false;

    productFamiliesData?.productFamilyCategories?.forEach((family) => {
      newTargetObject[family.id] = newBoolValue;
      family.categories?.forEach((x) => {
        newCategoryTargets[x.id] = newBoolValue;
        x.subcategories?.forEach((sub) => {
          newSubcategoryTargets[sub.id] = newBoolValue;
        });
      });
    });
    setProductFamilyTargetIds(newTargetObject);
    setCategoryTargetIds(newCategoryTargets);
    setSubcategoryTargetIds(newSubcategoryTargets);
  }

  const handleToggleFamilyTarget = (family) => {
    const newTargetObject = Object.assign({}, productFamilyTargetIds);
    const newCategoryTargets = Object.assign({}, categoryTargetIds);
    const newSubcategoryTargets = Object.assign({}, subcategoryTargetIds);

    if (productFamilyTargetIds[family.id]) {
      newTargetObject[family.id] = false;
      family.categories?.forEach((x) => {
        newCategoryTargets[x.id] = false;
        x.subcategories?.forEach((sub) => {
          newSubcategoryTargets[sub.id] = false;
        });
      });
    } else {
      newTargetObject[family.id] = true;
      family.categories?.forEach((x) => {
        newCategoryTargets[x.id] = true;
        x.subcategories?.forEach((sub) => {
          newSubcategoryTargets[sub.id] = true;
        });
      });
    }
    setProductFamilyTargetIds(newTargetObject);
    setCategoryTargetIds(newCategoryTargets);
    setSubcategoryTargetIds(newSubcategoryTargets);
  };

  const handleToggleCategoryTarget = (category, familyId) => {
    const newTargetObject = Object.assign({}, categoryTargetIds);
    const newFamilyTargets = Object.assign({}, productFamilyTargetIds);
    const newSubcategoryTargets = Object.assign({}, subcategoryTargetIds);

    if (categoryTargetIds[category.id]) {
      newTargetObject[category.id] = false;
      category.subcategories?.forEach((x) => {
        newSubcategoryTargets[x.id] = false;
      });
    } else {
      newFamilyTargets[familyId] = true;
      newTargetObject[category.id] = true;
      category.subcategories?.forEach((x) => {
        newSubcategoryTargets[x.id] = true;
      });
    }

    setCategoryTargetIds(newTargetObject);
    setSubcategoryTargetIds(newSubcategoryTargets);
    setProductFamilyTargetIds(newFamilyTargets);
  };

  const handleToggleSubcategoryTarget = (
    familyId,
    categoryId,
    subcategoryId
  ) => {
    const newTargetObject = Object.assign({}, subcategoryTargetIds);
    const newFamilyTargets = Object.assign({}, productFamilyTargetIds);
    const newCategoryTargets = Object.assign({}, categoryTargetIds);

    if (subcategoryTargetIds[subcategoryId]) {
      newTargetObject[subcategoryId] = false;
    } else {
      newTargetObject[subcategoryId] = true;
      newFamilyTargets[familyId] = true;
      newCategoryTargets[categoryId] = true;
    }

    setSubcategoryTargetIds(newTargetObject);
    setProductFamilyTargetIds(newFamilyTargets);
    setCategoryTargetIds(newCategoryTargets);
  };

  const handleToggleAreaTarget = (id) => {
    const newTargetObject = Object.assign({}, areaTargetIds);
    newTargetObject[id] = areaTargetIds[id] ? false : true;
    setAreaTargetIds(newTargetObject);
  };

  if (createTemplateMutation.isLoading) {
    return <CircularProgress />
  }

  switch (creationStep) {
    case 0:
      return (
        <NewTemplateNameStep
          handleBack={handleBack}
          closeModal={closeModal}
          handleNameChange={handleUpdateName}
          handleNextAction={handleNextStep}
          templateName={newTemplateName}
        />
      );
    case 1:
      return (
        <NewTemplateAreasStep
          areas={areaData?.areas}
          handleBack={handleBack}
          isLoading={isLoadingAreas}
          handleNextAction={handleNextStep}
          toggleAreaTarget={handleToggleAreaTarget}
          areaTargetIds={areaTargetIds}
          closeModal={closeModal}
        />
      );
    case 2:
      return (
        <NewTemplateFamiliesStep
          families={productFamiliesData?.productFamilyCategories}
          handleBack={handleBack}
          isLoading={isLoadingProductFamilies}
          handleNextAction={handleNextStep}
          productFamilyTargetIds={productFamilyTargetIds}
          categoryTargetIds={categoryTargetIds}
          subcategoryTargetIds={subcategoryTargetIds}
          toggleFamilyTarget={handleToggleFamilyTarget}
          toggleCategoryTarget={handleToggleCategoryTarget}
          toggleSubcategoryTarget={handleToggleSubcategoryTarget}
          closeModal={closeModal}
          selectAll={selectAll}
        />
      );
    default:
      toggleIsCreating(false);
      return null;
  }
};

export default CreateNewTemplateFlow;
