import { Box } from "@mui/material";
import React, { useState } from "react";
// import ReportType from "./ReportType";
import BasicInventory from "./BasicInventory";
import usePastStockCountsInfiniteScroll from "../../../../hooks/usePastStockCountsInfiniteScroll";

const StockCountReport = ({ establishmentId, subscriptionState, setAlertSuccess, setErrorMessage }) => {
  // const [reportType, setReportType] = useState("Basic Inventory");
  const [page, setPage] = useState(1);
  const {
    isLoading: isLoadingPastStockCounts,
    //isError: isErrorLoadingPastStockCounts,
    data: pastStockCountData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = usePastStockCountsInfiniteScroll(
    establishmentId,
    page,
    subscriptionState?.isActive && establishmentId != undefined
  );

  return (
    <>
      <Box>
        <Box>
          {/* <ReportType
            reportType={reportType}
            setReportType={setReportType}
            reportTypeOptions={reportTypeOptions}
          /> */}
            <BasicInventory
              establishmentId={establishmentId}
              subscriptionState={subscriptionState}
              isLoadingPastStockCounts={isLoadingPastStockCounts}
              pastStockCounts={pastStockCountData?.pages}
              setPage={setPage}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              setAlertSuccess={setAlertSuccess}
              setErrorMessage={setErrorMessage}
            />
          {/* {reportType.label === "Theoretical Product Format Run Rate" && (
          <ProductFormatRunRate setReportType={setReportType} />
        )} */}
        </Box>
      </Box>
    </>
  );
};

export default StockCountReport;
