import React, { useEffect, useState, useContext } from "react";
import {
  getMoreMessages,
  getEstablishmentMessages,
  sendEstablishmentMessage,
} from "../../clients/establishmentsClient";
import Store from "../../Store/Store";
import ReactGA from "react-ga";
import MessagesList from "./components/MessagesList";
import ComposeMessage from "./components/ComposeMessage";
import SentMessage from "./components/SentMessage";
import MessageSuccessModal from "../../components/Modals/MessageSuccessModal";
import Grid from "@mui/material/Grid";
import { Typography, Box, Snackbar, Alert } from "@mui/material";
import DeleteMessageModal from "../../components/Modals/DeleteMessageModal";

const AdminMessages = (props) => {
  const store = useContext(Store);
  const [error, toggleError] = useState(false);
  const [loading, toggleLoading] = useState(true);
  const [isSendingMessage, toggleSendingMessage] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [activeMessage, setActiveMessage] = useState(null);
  const [messageIdToDelete, setMessageIdToDelete] = useState(undefined);
  const [currentPaginationToken, setCurrentPaginationToken] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageContent, setErrorMessageContent] = useState("");
  const [successMessageContent, setSuccessMessageContent] = useState("");
  const [deleteItemModalOpen, toggleDeleteItemModalOpen] = useState(false);

  const toggleMessageSuccessModal = () => {
    toggleError(false);
    setShowMessageModal(!showMessageModal);
  };

  const handleAlertClose = () => {
    setAlertSuccess(false);
    setErrorMessage(false);
  };

  const messagePaper = {
    padding: 6,
    borderRadius: 2,
    height: 700,
    background: (theme) => theme.palette.pure.white,
    border: "1px solid",
    borderColor: (theme) => theme.palette.terrain[400],
    display: "flex",
    direction: "column",
  };

  const messageListPaper = {
    height: 700,
    overflow: "auto",
  };

  const { className } = props;

  const loadEstablishmentMessages = async () => {
    try {
      toggleError(false);
      toggleLoading(true);
      const { messages, paginationToken } = await getEstablishmentMessages(
        store.currentEstablishment?.id
      );
      setMessageList(messages);
      setCurrentPaginationToken(paginationToken);
      toggleLoading(false);
    } catch (err) {
      console.log(err);
      toggleLoading(false);
      toggleError(true);
    }
  };

  const loadMoreMessages = async () => {
    try {
      const { messages, paginationToken } = await getMoreMessages(
        store.currentEstablishment?.id,
        currentPaginationToken
      );
      const updatedMessages = messageList.concat(messages);
      setCurrentPaginationToken(paginationToken);
      setMessageList(updatedMessages);
      toggleError(false);
      toggleLoading(false);
    } catch (err) {
      console.log(err);
      toggleError(true);
    }
  };

  const handleSendMessage = async (message) => {
    try {
      toggleError(false);
      toggleSendingMessage(true);
      toggleMessageSuccessModal();
      await sendEstablishmentMessage(message);
      setDisplaySuccessMessage(true);
      loadEstablishmentMessages();
      toggleSendingMessage(false);
    } catch (err) {
      console.log(err);
      toggleError(true);
    }
  };

  const deleteCallback = async () => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! Your item has been deleted.");
    loadEstablishmentMessages();
    toggleDeleteItemModalOpen(false);
    setActiveMessage(null);
  };

  const handleDeleteModal = (messageId) => {
    setMessageIdToDelete(messageId);
    toggleDeleteItemModalOpen(!deleteItemModalOpen);
  };

  const errorCallback = (errorMessage) => {
    setErrorMessage(true);
    setErrorMessageContent(errorMessage);
  };

  const clearActiveMessage = () => {
    setActiveMessage(null);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    loadEstablishmentMessages();
  }, [store.currentEstablishment]);

  return (
    <>
      <MessageSuccessModal
        showMessageModal={showMessageModal}
        toggleMessageSuccessModal={toggleMessageSuccessModal}
        handleSendMessage={handleSendMessage}
        className={className}
        establishmentId={store.currentEstablishment?.id}
        displaySuccessMessage={displaySuccessMessage}
        error={error}
        isSendingMessage={isSendingMessage}
      />
      <Grid
        container
        direction="column"
        sx={{
          maxWidth: "1400px",
          padding: 2,
          paddingLeft: 6,
          minHeight: "800px",
          width: "100%",
          marginTop: 6,
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Grid item xs={12} sx={{ marginBottom: 8 }}>
            <Box>
              <Typography variant="h2">Messages</Typography>
            </Box>
          </Grid>
          {/* START OF MESSAGES BOXES. */}

          <Grid
            container
            direction="row-reverse"
            spacing={4}
            columns={12}
            sx={{ width: "100%" }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Box component="div" sx={messagePaper}>
                {activeMessage ? (
                  <SentMessage
                    messages={messageList}
                    activeMessage={activeMessage}
                    clearActiveMessage={clearActiveMessage}
                    deleteModalCallback={handleDeleteModal}
                  />
                ) : (
                  <ComposeMessage
                    handleSendMessage={handleSendMessage}
                    establishmentId={store.currentEstablishment?.id}
                    toggleSendingMessage={toggleSendingMessage}
                  />
                )}
              </Box>
            </Grid>
            {/* SENT MESSAGES VIEW */}
            <Grid item xs={12} sm={12} md={6}>
              <Box component="div" sx={messageListPaper}>
                <MessagesList
                  messages={messageList}
                  currentPaginationToken={currentPaginationToken}
                  activeMessage={activeMessage}
                  setActiveMessage={setActiveMessage}
                  loadMoreMessages={loadMoreMessages}
                  loading={loading}
                  error={error}
                  style={{ width: "100%" }}
                  deleteModalCallback={handleDeleteModal}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <DeleteMessageModal
        isOpen={deleteItemModalOpen}
        toggleModalOpen={handleDeleteModal}
        messageId={messageIdToDelete}
        errorCallback={errorCallback}
        deleteCallback={deleteCallback}
        actionToDisplay={"Are you sure you want to delete this message?"}
      />
      <Box>
        <Snackbar
          open={alertSuccess}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {successMessageContent}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessageContent}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default AdminMessages;
