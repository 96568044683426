import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Store from "../../../Store/Store";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import utilFunctions from "../../../utilities/utilFunctions";
import { useMutation } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import inventoryObjects from "../../../clients/inventoryObjects";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";
import ReconciledIndicator from "../../../components/ReconciledIndicator";

const tableCellStyle = {
  height: "60px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const KegTransferRow = ({ kegTransfer, updateKegTransferCache, invoiceId, invalidLineItems }) => {
  const [kegTransferCopy, setKegTransferCopy] = useState({});
  const [costDisplay, setCostDisplay] = useState(0);
  const [reconciled, setReconciled] = useState(false);
  const [validationFailures, setValidationFailures] = useState([]);
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings.inventorySettings?.currency
  );

  useEffect(() => {
    setKegTransferCopy(kegTransfer);
    setCostDisplay(
      utilFunctions.convertCentsToLargerFormatCurrency(
        kegTransfer?.cost?.amount || 0,
        locale,
        currency
      )
    );
    if (invalidLineItems[kegTransfer?.id]) {
      setReconciled(false);
      setValidationFailures(invalidLineItems[kegTransfer?.id]?.reasons)
    } else {
      setReconciled(true);
      setValidationFailures([]);
    }
  }, []);

  const kegTransferMutation = useMutation(inventoryClient.patchKegTransfer, {
    onSuccess: (result) => {
      updateKegTransferCache(result.kegTransfer);
    },
  });

  const deleteKegTransferMutation = useMutation(
    inventoryClient.deleteInvoiceKegTransfer,
    {
      onSuccess: () => {
        updateKegTransferCache();
      },
    }
  );

  const handleUpdateKegTransfer = (property, value) => {
    const payload = {
      establishmentId: kegTransfer.establishmentId,
      invoiceId,
      lineItemId: kegTransfer.id,
    };
    payload[`${property}Update`] = { value: value };
    kegTransferMutation.mutate(payload);
  };

  const updateKegTransferCopy = (property, value) => {
    const kegTransferUpdates = Object.assign({}, kegTransferCopy);
    kegTransferUpdates[property] = value;
    setKegTransferCopy(kegTransferUpdates);
  };

  const handleOnBlur = (property) => {
    const payload = {
      establishmentId: kegTransfer.establishmentId,
      invoiceId,
      lineItemId: kegTransfer.id,
    };
    payload[`${property}Update`] = { value: kegTransferCopy[property] };
    kegTransferMutation.mutate(payload);
  };

  const calculateUnitCost = () => {
    if (kegTransfer.amount === 0) {
      return 0;
    }

    if (kegTransfer.cost?.amount === 0) {
      return 0;
    }

    return kegTransfer.cost?.amount / kegTransfer.amount;
  };

  return (
    <TableRow>
      <TableCell sx={[tableCellStyle, { width: "44px", borderLeft: "none" }]}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReconciledIndicator reconciled={reconciled} validationFailures={validationFailures} />
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "160px" }]}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ToggleButtonGroup
            sx={{ height: "34px" }}
            value={kegTransferCopy?.type}
            onChange={(e, newType) => {
              updateKegTransferCopy("type", newType);
              handleUpdateKegTransfer("type", newType);
            }}
            exclusive
          >
            <ToggleButton value={inventoryObjects.kegTransferTypes.expense}>
              <Typography
                variant="body2"
                sx={{
                  textTransform: "none",
                  fontSize: { xs: 6, md: 8, lg: 12 },
                }}
              >
                Deposit
              </Typography>
            </ToggleButton>
            <ToggleButton value={inventoryObjects.kegTransferTypes.credit}>
              <Typography
                variant="body2"
                sx={{
                  textTransform: "none",
                  fontSize: { xs: 6, md: 8, lg: 12 },
                }}
              >
                Return
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "620px" }]}>
        <TextField
          size="small"
          variant="standard"
          fullWidth
          id="name"
          autoComplete="off"
          InputProps={{ disableUnderline: "true" }}
          value={kegTransferCopy?.name || ""}
          placeholder="Enter an Expense Name"
          onChange={(e) => updateKegTransferCopy("name", e.target.value)}
          onFocus={(e) => e.target.select()}
          onBlur={() => handleOnBlur("name")}
          onKeyDown={utilFunctions.blurInput}
        />
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "80px" }]}>
        <TextField
          size="small"
          variant="standard"
          id="amount"
          autoComplete="off"
          InputLabelProps={{ shrink: "true" }}
          InputProps={{ disableUnderline: "true" }}
          placeholder="Amount"
          disabled={kegTransferMutation.isLoading}
          value={kegTransferCopy.amount}
          onChange={(e) => {
            let val = e.target.value.replace(/[^0-9]/g, "");

            if (val.length > 1) {
              val = val.replace(/^0+/, "");
            }
            updateKegTransferCopy("amount", val || 0);
          }}
          onFocus={(e) => e.target.select()}
          onBlur={() => handleOnBlur("amount")}
          onKeyDown={utilFunctions.blurInput}
        />
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "90px" }]}>
        <TextField
          size="small"
          variant="standard"
          fullWidth
          required
          id="name"
          autoComplete="off"
          InputProps={{
            disableUnderline: "true",
            inputMode: "numeric",
            startAdornment: (
              <InputAdornment sx={{ marginBottom: 1 }} position="start">
                {currencySymbol}
              </InputAdornment>
            ),
          }}
          value={costDisplay}
          onChange={(e) => {
            setCostDisplay(e.target.value);
            updateKegTransferCopy(
              "cost",
              Object.assign({}, kegTransferCopy, {
                amount: utilFunctions.convertCurrencyToCents(e.target.value),
              })
            );
          }}
          onFocus={(e) => e.target.select()}
          onBlur={() => {
            setCostDisplay(
              utilFunctions.convertCentsToLargerFormatCurrency(
                kegTransferCopy?.cost?.amount || 0,
                locale,
                currency
              )
            );
            handleOnBlur("cost");
          }}
          onKeyDown={utilFunctions.blurInput}
        />
      </TableCell>
      {/* Need to implement Unit Cost */}
      <TableCell sx={[tableCellStyle, { width: "90px" }]}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: "24px",
            backgroundColor: (theme) => theme.palette.terrain[100],
          }}
        >
          <Typography variant="body1">
            {currencySymbol}{" "}
            {utilFunctions.convertCentsToLargerFormatCurrency(
              calculateUnitCost() || 0,
              locale,
              currency
            )}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "56px", borderRight: "none" }]}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            variant="outlined"
            sx={{
              height: "36px",
              width: "36px",
              border: "1px solid",
              borderColor: (theme) => theme.palette.error[500],
              borderRadius: 2,
            }}
            disabled={deleteKegTransferMutation.isLoading}
            onClick={() =>
              deleteKegTransferMutation.mutate({
                establishmentId: store.currentEstablishment?.id,
                invoiceId,
                kegTransferId: kegTransferCopy.id,
              })
            }
          >
            <DeleteOutlineIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default KegTransferRow;
