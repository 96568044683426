import {
  Autocomplete,
  Box,
  Grid,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const ProductSelector = ({ products, selectedProduct, setSelectedProduct }) => {
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="h5">Product Selector</Typography>
      </Box>
      <Box sx={{ width: "70%", marginTop: 2 }}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                height: "92px",
                borderRadius: 2,
                backgroundColor: (theme) => theme.palette.terrain[200],
                border: "1px solid",
                borderColor: (theme) => theme.palette.terrain[300],
                padding: 2,
              }}
            >
              <Typography variant="subtitle2">Product Name</Typography>
              <Autocomplete
                size="small"
                fullWidth
                getOptionLabel={(o) => o.name}
                onChange={(e, v) => setSelectedProduct(v)}
                options={products}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ height: "40px !important" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label={"Choose Product"}
                      sx={{ width: "100%", height: "40px !important" }}
                    />
                  );
                }}
                renderOption={(props, option) => (
                  <ListItem
                    {...props}
                    key={option.id}
                    sx={{
                      "&:hover": {
                        color: (theme) => theme.palette.pure.white,
                      },
                    }}
                  >
                    {option.name}
                  </ListItem>
                )}
                value={selectedProduct}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProductSelector;
