import { Button, Grid, Typography, styled } from "@mui/material";
import React, { useContext } from "react";
import Store from "../../../../Store/Store";

const FooterGridWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  borderTop: "1px solid ",
  borderColor: theme.palette.terrain[400],
  backgroundColor: theme.palette.terrain[50],
  position: "fixed",
  bottom: 0,
  width: "97%",
  maxWidth: "2440px",
  height: "80px",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(97% - 240px)`,
    maxWidth: "2200px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  display: "flex",
  alignItems: "center",
  zIndex: 1000
}));

const AddProductFooter = ({ activeStep, newProducts, handleSubmit }) => {
  const { sideNavOpen } = useContext(Store);

  if (activeStep > 4) {
    return null;
  }

  return (
    <FooterGridWrapper open={sideNavOpen}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          maxWidth: "2440px",
        }}
      >
        <Grid
          item
          sx={{
            paddingLeft: 8,
            marginRight: 4,
          }}
        >
          <Button
            type="button"
            variant="contained"
            disabled={!newProducts || newProducts.length === 0}
            sx={{ width: "180px", height: "42px" }}
            onClick={handleSubmit}
          >
            <Typography variant="smallButton">Submit</Typography>
          </Button>
        </Grid>
      </Grid>
    </FooterGridWrapper>
  );
};

export default AddProductFooter;
