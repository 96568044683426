import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Grid,
  ListItem,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const selectedStyle = {
  cursor: "pointer",
  width: "100%",
  height: "90px",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.primary[800],
  backgroundColor: (theme) => theme.palette.terrain[200],
  marginBottom: 2,
};

const defaultStyle = {
  cursor: "pointer",
  width: "100%",
  height: "90px",
  border: "1px solid gray",
  borderRadius: "4px",
  marginBottom: 2,
};

const FilterBy = ({
  expenseGroups,
  isUsingExpenseGroupFilter,
  toggleIsUsingExpenseGroupFilter,
  expenseGroupFilters,
  handleGroupAddClick,
  handleGroupRemoveClick,
  handleSubgroupAddClick,
  handleSubgroupRemoveClick,
  handleClearGroupFilters,
  isUsingFilter,
  toggleIsUsingFilter,
  isUsingVendorFilter,
  toggleIsUsingVendorFilter,
  vendorFilters,
  setVendorFilters,
  vendors,
  reportGroupingChoice
}) => {
  const handleToggleUsingFilter = () => {
    toggleIsUsingFilter(!isUsingFilter);
  };

  const handleSelectChange = (_, newValue, reason, details) => {
    if (details?.option?.type === "group") {
      reason === "removeOption"
        ? handleGroupRemoveClick(newValue, details.option)
        : handleGroupAddClick(newValue, details.option);
    } else if (reason === "clear") {
      handleClearGroupFilters();
    } else {
      reason === "removeOption"
        ? handleSubgroupRemoveClick(newValue, details.option)
        : handleSubgroupAddClick(newValue, details.option);
    }
  };

  const handleVendorChange = (_, newValue) => {
    setVendorFilters(newValue);
  };

  const handleSetVendorToggle = () => {
    if (isUsingVendorFilter && isUsingFilter) {
      return;
    } else {
      toggleIsUsingVendorFilter(true);
      toggleIsUsingExpenseGroupFilter(false);
      toggleIsUsingFilter(true);
    }
  };

  const handleToggleExpenseGroupFilter = () => {
    if (isUsingExpenseGroupFilter && isUsingFilter) {
      return;
    } else {
      toggleIsUsingVendorFilter(false);
      toggleIsUsingExpenseGroupFilter(true);
      toggleIsUsingFilter(true);
    }
  };

  const calculateSelectedCount = () => {
    if (reportGroupingChoice === 0) {
      return expenseGroupFilters.filter(
        (x) => x.type === "group"
      ).length;
    } else if (reportGroupingChoice === 1) {
      return expenseGroupFilters.filter(
        (x) => x.type === "subgroup"
      ).length;
    } else {
      return expenseGroupFilters.length;
    }
  }

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox
          sx={{ marginLeft: -3 }}
          checked={isUsingFilter}
          onChange={handleToggleUsingFilter}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Filter by
        </Typography>
      </Box>
      {isUsingFilter ? (
        <Box>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ marginTop: 2, width: "70%" }}
          >
            <Grid item xs={6} sx={{ height: "100%" }}>
              <Box
                onClick={handleToggleExpenseGroupFilter}
                sx={
                  isUsingFilter && isUsingExpenseGroupFilter
                    ? selectedStyle
                    : defaultStyle
                }
              >
                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                  <Grid item>
                    <Radio
                      checked={isUsingFilter && isUsingExpenseGroupFilter}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 700,
                        fontSize: { xs: 12, sm: 12, md: 16 },
                        color:
                          isUsingFilter && isUsingExpenseGroupFilter
                            ? (theme) => theme.palette.primary[800]
                            : (theme) => theme.palette.pure.black,
                      }}
                    >
                      Expense {reportGroupingChoice === 0 ? "Groups" : "Subgroups"}
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                <Autocomplete
              multiple
              size="small"
              disabled={!isUsingExpenseGroupFilter}
              fullWidth
              options={expenseGroups}
              getOptionLabel={(option) => option.id}
              disableCloseOnSelect
              renderTags={() => null}
              value={expenseGroupFilters}
              onChange={handleSelectChange}
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
                "& .MuiAutocomplete-option": {
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              }}
              renderOption={(props, option) =>
                reportGroupingChoice ===
                  0 &&
                option.type === "subgroup" ? null : (
                  <ListItem
                    key={option.id}
                    id={option.id}
                    {...props}
                    sx={
                      option.type === "group"
                        ? {
                            borderLeft: "6px solid",
                            borderLeftColor: "transparent",
                            "&:hover": {
                              color: (theme) => theme.palette.pure.white,
                            },
                          }
                        : {
                            backgroundColor: (theme) =>
                              theme.palette.terrain[50],
                            borderLeft: "6px solid",
                            borderLeftColor: (theme) =>
                              theme.palette.primary[300],
                            marginLeft: 3,
                            padding: 0,
                            "&:hover": {
                              color: (theme) => theme.palette.pure.white,
                            },
                          }
                    }
                  >
                    <Checkbox
                      sx={{
                        color: (theme) => theme.palette.primary[800],
                        marginLeft: 0,
                      }}
                      checked={expenseGroupFilters.some(
                        (item) => item.id === option.id
                      )}
                      onChange={() => {}}
                    />
                    <ListItemText primary={option.name} />
                  </ListItem>
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={
                    expenseGroupFilters?.length > 0
                      ? `${
                          calculateSelectedCount()
                        } Group(s) Selected`
                      : "No Groups Selected"
                  }
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    style: { cursor: "pointer" },
                  }} // Disable typing
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    width: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    cursor: "pointer",
                  }}
                />
              )}
            />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                onClick={handleSetVendorToggle}
                sx={
                  isUsingFilter && isUsingVendorFilter
                    ? selectedStyle
                    : defaultStyle
                }
              >
                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                  <Grid item>
                    <Radio checked={isUsingFilter && isUsingVendorFilter} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 700,
                        fontSize: { xs: 12, sm: 12, md: 16 },
                        color:
                          isUsingFilter && isUsingVendorFilter
                            ? (theme) => theme.palette.primary[800]
                            : (theme) => theme.palette.pure.black,
                      }}
                    >
                      Vendors
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                  <Autocomplete
                    multiple
                    fullWidth
                    options={vendors}
                    getOptionLabel={(option) => option.name}
                    disableCloseOnSelect
                    renderTags={() => null}
                    value={vendorFilters}
                    onChange={handleVendorChange}
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        "&:hover": {
                          cursor: "pointer",
                        },
                      },
                      "& .MuiAutocomplete-option": {
                        "&:hover": {
                          cursor: "pointer",
                        },
                      },
                    }}
                    renderOption={(props, option) => (
                      <ListItem
                        key={option.id}
                        {...props}
                        sx={{
                          "&:hover": {
                            color: (theme) => theme.palette.pure.white,
                          },
                        }}
                      >
                        <Checkbox
                          sx={{ color: (theme) => theme.palette.primary[800] }}
                          checked={vendorFilters.some(
                            (item) => item.id === option.id
                          )}
                          onChange={() => {}}
                        />
                        <ListItemText primary={option.name} />
                      </ListItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        placeholder={
                          vendorFilters?.length > 0
                            ? `${vendorFilters.length} Vendor(s) Selected`
                            : "No Vendors Selected"
                        }
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                          style: { cursor: "pointer" },
                        }} // Disable typing
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          width: "100%",
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          cursor: "pointer",
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                {isUsingExpenseGroupFilter && isUsingFilter ? (
                  <Box sx={{ marginTop: 3, marginBottom: 2 }}>
                  {expenseGroupFilters.map((group) =>
                    (reportGroupingChoice ===
                      0 &&
                      group.type === "subgroup") ||
                    (reportGroupingChoice ===
                      1 &&
                      group.type === "group") ? null : (
                      <Chip
                        key={group.id}
                        label={group.type === "subgroup" ? `${group.parentName}:${group.name}` : group.name}
                        onDelete={() => {
                          // we are sadly mimicing the autocomplete here, so we need to wrap the group in the option property
                          handleSelectChange(
                            null,
                            expenseGroupFilters.filter(
                              (item) => item.id !== group.id
                            ),
                            "removeOption",
                            { option: group }
                          );
                        }}
                        sx={{ marginRight: 1, marginBottom: 1 }}
                      />
                    )
                  )}
                </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
              {isUsingVendorFilter && isUsingFilter ? (
                  <Box sx={{ marginTop: 1, marginBottom: 4 }}>
                    {vendorFilters.map((v) => (
                      <Chip
                        key={v.id}
                        label={v.name}
                        onDelete={() => {
                          setVendorFilters(
                            vendorFilters.filter((item) => item.id !== v.id)
                          );
                        }}
                        sx={{ marginRight: 1, marginBottom: 1 }}
                      />
                    ))}
                  </Box>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
};

export default FilterBy;
