import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const AddProductCTA = () => {
  const ProductExampleBox = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "108px",
          backgroundColor: (theme) => theme.palette.terrain[200],
          border: "2px dashed",
          borderColor: (theme) => theme.palette.terrain[300],
          borderRadius: 2,
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            marginBottom: 1,
          }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h5"
              sx={{ color: (theme) => theme.palette.terrain[400] }}
            >
              Product Name
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box
              sx={{
                width: "32px",
                height: "32px",
                backgroundColor: (theme) => theme.palette.terrain[300],
                borderRadius: 1,
                marginRight: 1,
              }}
            />
            <Box
              sx={{
                width: "32px",
                height: "32px",
                backgroundColor: (theme) => theme.palette.terrain[300],
                borderRadius: 1,
                marginLeft: 1,
              }}
            />
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={4}>
              <Box
                sx={{
                  width: "100%",
                  height: "32px",
                  backgroundColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 1,
                  marginRight: 1,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  width: "100%",
                  height: "32px",
                  backgroundColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 1,
                  marginRight: 1,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  width: "100%",
                  height: "32px",
                  backgroundColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 1,
                  marginRight: 1,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        backgroundColor: (theme) => theme.palette.terrain[100],
        padding: 6,
        borderRadius: 2,
        marginTop: 4,
      }}
    >
      <Grid
        item
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        xs={12}
      >
        <Typography variant="h3">Add as many products as you want!</Typography>
      </Grid>
      <Grid
        item
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        xs={12}
      >
        <Typography variant="subtitle2" sx={{ marginTop: 3, marginBottom: 3 }}>
          Add multiple products, and then sort your Product Library by {"'"}
          Newest First{"'"} to continue filling in the information for your
          newly added products
        </Typography>
      </Grid>
      <Box sx={{ width: "100%", height: "300px", marginTop: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <ProductExampleBox />
          </Grid>
          <Grid item xs={6}>
            <ProductExampleBox />
          </Grid>
          <Grid item xs={6}>
            <ProductExampleBox />
          </Grid>
          <Grid item xs={6}>
            <ProductExampleBox />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AddProductCTA;
