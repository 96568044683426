import { useQuery } from "react-query";
import authClient from "../clients/authClient";

function useUserProfile() {
  const queryReturnValues = useQuery(
    ["userProfile"],
    authClient.getUserProfile,
    {
      staleTime: 1000 * 60 * 20,
    }
  );

  return queryReturnValues;
}

export default useUserProfile;
