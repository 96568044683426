import React from "react";
import { modalBoxStyle } from "../styles";
import { Box, IconButton, Link, Modal, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MobileAlertIcon from "../../CustomIcons/MobileAlertIcon";

const MobileDisplayAlertModal = ({ isOpen, toggleModalOpen }) => {
  const closeModal = () => {
    toggleModalOpen(!isOpen);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[modalBoxStyle, { width: { xs: "340px", md: "400px" } }]}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={closeModal}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <MobileAlertIcon
            sx={{ fontSize: 120, color: (theme) => theme.palette.primary[800] }}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <Typography
            variant="h4"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            Mobile Version Coming Soon
          </Typography>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            It looks like you are on a mobile device. Unfortunately, our web app
            isn&apos;t fully optimized for mobile devices. You can get the full
            experience on a larger device, or download our companion Mobile App.
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", marginTop: 4 }}
          >
            We know this isn&apos;t ideal, but we promise the experience on a
            larger device is worth it!
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Link
              href="https://apps.apple.com/us/app/spec-pro/id1454755076?itsct=apps_box_badge&amp;itscg=30200"
              sx={{
                width: "125px",
              }}
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1552176000&h=64eba89b8337a122e132b26421e24f4f"
                alt="Download on the App Store"
                style={{
                  width: "125px",
                }}
              />
            </Link>
          </Box>
          <Box>
            <Link href="https://play.google.com/store/apps/details?id=com.spec.pro&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src="https://mcusercontent.com/54c65f604256d6be9651d3858/images/199492b4-3f5c-ba0c-e1a2-6e304c90b1d1.png"
                style={{ width: "160px" }}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MobileDisplayAlertModal;
