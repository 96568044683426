import { SvgIcon } from "@mui/material";
import React from "react";

const SpecLogoMain = (props) => {
  return (
    <SvgIcon
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx="14.1284" cy="14" rx="14.1284" ry="14" fill="#E78271" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21825 3.84862L8.1191 4.64703C7.50474 5.09328 7.25872 5.8511 7.52234 6.48445L10.4671 13.5556C10.5913 13.8543 10.9924 13.9398 11.2822 13.7293L17.4314 9.26249C17.7216 9.05173 17.7459 8.65683 17.4811 8.45978L11.216 3.79943C10.6556 3.38248 9.83205 3.40291 9.21825 3.84862ZM14.3413 24.5H15.716C16.4844 24.5 17.1538 23.9893 17.3395 23.2615L19.4099 15.1388C19.4974 14.7954 19.2314 14.4632 18.8687 14.4632H11.178C10.815 14.4632 10.549 14.796 10.6368 15.1393L12.7184 23.2634C12.9046 23.9901 13.5737 24.5 14.3413 24.5Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default SpecLogoMain;
