import React, { useState, useEffect, useMemo } from "react";
import { modalBoxStyle } from "../styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { debounce } from "lodash";
import inventoryClient from "../../../clients/inventoryClient";
import SearchResultsList from "./components/SearchResultsList";

const SpecMasterEntrySubmissionMapModal = ({
  isOpen,
  toggleModalOpen,
  handleMap,
  prefillMapOption,
  clearPrefill,
}) => {
  const [loading, toggleLoading] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [searchString, setSearchString] = useState("");
  const [selected, setSelected] = useState(undefined);

  useEffect(() => {
    setSelected(prefillMapOption);
  }, [prefillMapOption]);

  useEffect(() => {
    setSearchString("");
  }, [isOpen]);

  const getSearchResults = async () => {
    toggleLoading(true);
    const result = await inventoryClient.searchSpecMasterProducts({
      searchString,
    });
    setSearchResults(result.results);
    toggleLoading(false);
  };

  useEffect(() => {
    if (!searchString) {
      toggleLoading(false);
      setSearchResults([]);
    } else {
      getSearchResults();
    }

    return () => debouncedSearchHandler.cancel();
  }, [searchString]);

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  const debouncedSearchHandler = useMemo(() => debounce(handleSearch, 300));

  const closeModal = () => {
    toggleModalOpen(false);
    clearPrefill();
    setSelected(undefined);
    toggleLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        <>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 6,
            }}
          >
            <Grid item>
              <Typography variant="h4">Search Master Products</Typography>
            </Grid>
            <Grid item>
              <IconButton
                sx={{ backgroundColor: "transparent" }}
                onClick={closeModal}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          {selected ? <Typography variant="h5" sx={{marginBottom: 2}}>Map entry to product: {selected?.name}?</Typography> : null}
          <Box sx={{ marginBottom: 2, display: "flex", flexDirection: "row" }}>
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: 16, sm: 16, md: 20 } }}
            >
              Search by Product Name
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 6, marginTop: 4 }}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              placeholder="Product Name"
              autoComplete="off"
              label="Name"
              InputLabelProps={{ shrink: true }}
              onChange={debouncedSearchHandler}
            />
          </Box>
          <Box>
            <SearchResultsList results={searchResults} isLoading={loading} onOptionClick={setSelected} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              disabled={loading || !selected}
              onClick={() => handleMap(selected?.id)}
              sx={{
                marginLeft: "1rem",
                borderRadius: 2,
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 6,
                paddingRight: 6,
              }}
            >
              <Typography variant="smallButton">Map</Typography>
            </Button>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default SpecMasterEntrySubmissionMapModal;
