import { useQuery } from "react-query";
import { getEstablishmentReactQuery } from "../clients/establishmentsClient";

function useEstablishment(establishmentId, store) {
    const queryReturnValues = useQuery(
        ["establishment", { establishmentId }],
        getEstablishmentReactQuery,
        {
            onSuccess: (result) => {
                if (store) {
                    store.updateCurrentEstablishment(result);
                }
            },
            staleTime: 5000
        }
    );

   return queryReturnValues;
}

export default useEstablishment;