import { Box, Tooltip, Typography } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import React from "react";

const ReconciledIndicator = ({ reconciled, validationFailures }) => {
  const header = reconciled ? (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Box
        sx={{
          width: "24px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "100%",
          backgroundColor: (theme) => theme.palette.info[600],
          marginRight: 2,
        }}
      >
        <CheckOutlinedIcon
          sx={{ fontSize: 18, color: (theme) => theme.palette.pure.white }}
        />
      </Box>
      <Typography variant="h4">Item Reconciled</Typography>
    </Box>
  ) : (
    <Box>
      <Typography variant="h4">Item Not Reconciled</Typography>
    </Box>
  );
  const description = reconciled ? (
    <Box>
      <Typography variant="body1">
        All required fields have been set for this line item.
      </Typography>
    </Box>
  ) : (
    <Box>
      <Typography variant="body1">
        You are missing the following required information to make this item
        reconciled:
      </Typography>
      <Typography variant="body1">
        <ul>
          {validationFailures.map(x => <li key={x}>{x}</li>)}
        </ul>
      </Typography>
    </Box>
  );

  return (
    <Tooltip
      title={
        <ReconciledTooltipContentArea
          header={header}
          description={description}
        />
      }
      componentsProps={{
        tooltip: {
          sx: {
            color: (theme) => theme.palette.pure.black,
            bgcolor: (theme) => theme.palette.pure.white,
            border: "2px solid",
            borderColor: (theme) => theme.palette.terrain[200],
          },
        },
      }}
    >
      <Box
        sx={{
          width: "24px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 1,
          cursor: "default",
          backgroundColor: (theme) =>
            reconciled ? theme.palette.info[600] : theme.palette.terrain[100],
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: (theme) =>
              reconciled
                ? theme.palette.pure.white
                : theme.palette.primary[800],
          }}
        >
          R
        </Typography>
      </Box>
    </Tooltip>
  );
};

const ReconciledTooltipContentArea = ({
  header,
  description,
  props,
}) => {
  return (
    <Box
      {...props}
      sx={{
        backgroundColor: (theme) => theme.palette.pure.white,
        padding: 1,
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.primary[800] }}
        >
          {header}
        </Typography>
      </Box>
      <Box component="div" sx={{ marginTop: 1 }}>
        <Typography component="div" variant="body1">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReconciledIndicator;
