import React from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const times = [
  "12:00am",
  "12:30am",
  "1:00am",
  "1:30am",
  "2:00am",
  "2:30am",
  "3:00am",
  "3:30am",
  "4:00am",
  "4:30am",
  "5:00am",
  "5:30am",
  "6:00am",
  "6:30am",
  "7:00am",
  "7:30am",
  "8:00am",
  "8:30am",
  "9:00am",
  "9:30am",
  "10:00am",
  "10:30am",
  "11:00am",
  "11:30am",
  "12:00pm",
  "12:30pm",
  "1:00pm",
  "1:30pm",
  "2:00pm",
  "2:30pm",
  "3:00pm",
  "3:30pm",
  "4:00pm",
  "4:30pm",
  "5:00pm",
  "5:30pm",
  "6:00pm",
  "6:30pm",
  "7:00pm",
  "7:30pm",
  "8:00pm",
  "8:30pm",
  "9:00pm",
  "9:30pm",
  "10:00pm",
  "10:30pm",
  "11:00pm",
  "11:30pm",
];

const orderDayLongMap = {
  0: "Monday",
  1: "Tuesday",
  2: "Wednesday",
  3: "Thursday",
  4: "Friday",
  5: "Saturday",
  6: "Sunday",
};

const CutOffTimeSection = ({ orderDay, updateOrderDay }) => {
  const handleCutOffTime = (e) => {
    const copy = Object.assign({}, orderDay);
    copy.orderCutOffTime = e.target.value;
    updateOrderDay(copy);
  };

  const handleNoCutOff = () => {
    const copy = Object.assign({}, orderDay);

    if (copy.noCutOffTime) {
      copy.noCutOffTime = false;
    } else {
      copy.noCutOffTime = true;
    }

    updateOrderDay(copy);
  };

  return (
    <Box>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Typography variant="h5" sx={{ marginBottom: 4 }}>
            {orderDayLongMap[orderDay.day]} Order Placement Cut-off Time
          </Typography>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              width: "100%",
              marginBottom: 2,
            }}
          >
            <Grid item xs={6}>
              <FormControl>
                <InputLabel>Time</InputLabel>
                <Select
                  variant="outlined"
                  sx={{ height: 40, marginRight: 4 }}
                  label="Time"
                  value={orderDay.orderCutOffTime}
                  onChange={handleCutOffTime}
                  disabled={orderDay.noCutOffTime}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: (theme) =>
                            theme.palette.primary[800],
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.secondary[600],
                        },
                        "& .MuiMenuItem-root.Mui-selected:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.secondary[800],
                        },
                      },
                    },
                  }}
                >
                  {times &&
                    times.map((time) => (
                      <MenuItem key={time} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                height: 36,
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <FormControlLabel
                label={
                  <Typography
                    variant="body1"
                    sx={{ fontSize: { xs: 8, md: 12 } }}
                  >
                    {" "}
                    No cut-off times for placing an order
                  </Typography>
                }
                componentsProps={{ fontSize: { xs: 6 } }}
                control={<Checkbox />}
                checked={orderDay.noCutOffTime}
                onChange={handleNoCutOff}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CutOffTimeSection;
