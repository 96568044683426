import React from "react";
import {
  Box,
  Checkbox,
  Grid,
  // IconButton,
  ListItem,
  Typography,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import DragHandleIcon from "@mui/icons-material/DragHandle";
import CTABlock from "../../CTABlock";

const ProductOptionsList = ({
  options,
  searching,
  handleOptionCheckUncheck,
  emptyOptionsCTAText,
}) => {
  if (options.length === 0) {
    return searching ? (
      <CTABlock
        header="No Results Found"
        hideButton
        ButtonImage={AddOutlinedIcon}
      />
    ) : (
      <CTABlock
        header={emptyOptionsCTAText}
        subheader=""
        hideButton
        ButtonImage={AddOutlinedIcon}
      />
    );
  }

  return (
    <Box sx={{ width: "100%", marginTop: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          {options.map((o) => (
            <Box key={o.id}>
              <ListItem
                sx={{
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  height: "48px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 2,
                  borderBottom: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                }}
                // onClick={() => toggleSelected(!selected)}
              >
                <Grid container>
                  <Grid
                    container
                    direction="row"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      {/* <DragHandleIcon
                        sx={{
                          fontSize: 16,
                          color: (theme) => theme.palette.primary[800],
                        }}
                      /> */}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "400px",
                            maxHeight: { xs: "20px" },
                          }}
                        >
                          {o.name}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="overline">{o.sizeInfo}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    dispaly: "flex",
                    justifyContent: "flex-end",
                    width: "120px",
                  }}
                >
                  <Grid item>
                    <Checkbox id={o.id} onChange={handleOptionCheckUncheck} />
                  </Grid>
                  {/* <Grid item>
                    <IconButton variant="outlined">
                      <MoreHorizIcon
                        sx={{
                          color: (theme) => theme.palette.primary[800],
                        }}
                      />
                    </IconButton>
                  </Grid> */}
                </Grid>
              </ListItem>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductOptionsList;
