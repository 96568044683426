import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useStockCountTemplates(establishmentId) {
  const queryReturnValues = useQuery(
    ["templates", { establishmentId }],
    inventoryClient.getStockCountTemplates,
    {
      staleTime: 1000 * 60 * 10,
    }
  );

  return queryReturnValues;
}

export default useStockCountTemplates;
