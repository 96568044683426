import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import MuiPhoneNumber from "material-ui-phone-number";
import Store from "../../../Store/Store";

const RepRowItem = ({ rep, updateRep, modalView }) => {
  const { register, watch } = useForm({ mode: "all", defaultValues: rep });
  const store = useContext(Store);
  const handleRepUpdate = (phone, updatePhone) => {
    updateRep({
      id: rep.id,
      name: watch("name"),
      phoneNumber: updatePhone ? phone : rep.phoneNumber,
      email: watch("email"),
    });
  };

  const repRowModalStyle = modalView && { marginRight: 0 };

  const FormInputLabelStyles = {
    shrink: true,
  };

  return (
    <Grid
      item
      sx={[
        repRowModalStyle,
        {
          maxWidth: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      ]}
    >
      <form>
        <Grid
          container
          direction="row"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 4,
            alignItems: "center",
            zIndex: -1,
          }}
        >
          <Grid item xs={3}>
            <TextField
              required
              size="small"
              fullWidth
              id="name"
              autoComplete="off"
              label="Contact Name"
              placeholder="Enter Contact Name"
              InputLabelProps={FormInputLabelStyles}
              {...register("name", {
                required: true,
                onChange: handleRepUpdate,
              })}
            />
          </Grid>
          <Grid item xs={4}>
            <MuiPhoneNumber
              size="small"
              sx={{ width: "100%" }}
              disableAreaCodes
              defaultCountry={store.selectedCountry || "us"}
              InputLabelProps={FormInputLabelStyles}
              label="Contact Number"
              placeholder="Enter remaining digits"
              variant="outlined"
              id="phoneNumber"
              autoComplete="off"
              value={rep.phoneNumber}
              onChange={(phone) => handleRepUpdate(phone, true)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              size="small"
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="off"
              placeholder="Enter Contact Email"
              InputLabelProps={FormInputLabelStyles}
              // value={rep.email}
              onChange={handleRepUpdate}
              {...register("email", { onChange: handleRepUpdate })}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default RepRowItem;
