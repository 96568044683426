import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import DescriptionToolTip from "../../../../../components/DescriptionToolTip";

const FilterBy = ({
  expenseGroups,
  isUsingExpenseGroupFilter,
  toggleIsUsingExpenseGroupFilter,
  setExpenseGroupFilters,
  expenseGroupFilters,
}) => {
  const handleToggleUsingFilter = () => {
    toggleIsUsingExpenseGroupFilter(!isUsingExpenseGroupFilter);
  };

  const handleSelectChange = (_, newValue) => {
    setExpenseGroupFilters(newValue);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox
          sx={{ marginLeft: -3 }}
          checked={isUsingExpenseGroupFilter}
          onChange={handleToggleUsingFilter}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Filter by Expense Group
        </Typography>
        <DescriptionToolTip
          header="Filter by Expense Group"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Filter invoice line items by their expense group.
              </Box>
            </Box>
          }
        />
      </Box>
      {isUsingExpenseGroupFilter ? (
        <Box sx={{ marginTop: 2 }}>
          {/* <Button variant="contained" sx={{ marginRight: 1, borderRadius: 2 }}>
          Product Library
        </Button> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "72px",
              width: "35%",
              borderRadius: 2,
              backgroundColor: (theme) => theme.palette.terrain[200],
              border: "1px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              padding: 2,
              marginTop: 2,
            }}
          >
            <Autocomplete
              multiple
              fullWidth
              options={expenseGroups}
              getOptionLabel={(option) => option.name}
              disableCloseOnSelect
              renderTags={() => null}
              value={expenseGroupFilters}
              onChange={handleSelectChange}
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
                "& .MuiAutocomplete-option": {
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              }}
              renderOption={(props, option) => (
                <ListItem
                  key={option.id}
                  {...props}
                  sx={{
                    "&:hover": {
                      color: (theme) => theme.palette.pure.white,
                    },
                  }}
                >
                  <Checkbox
                    checked={expenseGroupFilters.some(
                      (item) => item.id === option.id
                    )}
                    sx={{ color: (theme) => theme.palette.primary[800] }}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      if (isChecked) {
                        setExpenseGroupFilters([
                          ...expenseGroupFilters,
                          option,
                        ]);
                      } else {
                        setExpenseGroupFilters(
                          expenseGroupFilters.filter(
                            (item) => item.id !== option.id
                          )
                        );
                      }
                    }}
                  />
                  <ListItemText primary={option.name} />
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={
                    expenseGroupFilters?.length > 0
                      ? `${expenseGroupFilters.length} Expense Group(s) Selected`
                      : "No Expense Groups Selected"
                  }
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    style: { cursor: "pointer" },
                  }} // Disable typing
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    width: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    cursor: "pointer",
                  }}
                />
              )}
            />
          </Box>
          {isUsingExpenseGroupFilter ? (
            <Box sx={{ marginTop: 4, marginBottom: 2 }}>
              {expenseGroupFilters.map((group) => (
                <Chip
                  key={group.id}
                  label={group.name}
                  onDelete={() => {
                    setExpenseGroupFilters(
                      expenseGroupFilters.filter((item) => item.id !== group.id)
                    );
                  }}
                  sx={{ marginRight: 1, marginBottom: 1 }}
                />
              ))}
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default FilterBy;
