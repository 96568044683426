import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ProductLineItemRow from "./ProductLineItemRow";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import Store from "../../../Store/Store";
import TotalCostBadge from "../components/TotalCostBadge";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import LinkOrderModal from "../../../components/Modals/LinkOrderModal";
import OrderPillBox from "./OrderPillBox";
import { useParams } from "react-router-dom";
import ManageLinkedInvoiceLineItemModal from "../../../components/Modals/ManageLinkedInvoiceLineItemModal";
import DescriptionToolTip from "../../../components/DescriptionToolTip";

const colorIndexMap = {
  0: { primary: "#B2E3F1", secondary: "#E0F4F9", simpleName: "blue" },
  1: { primary: "#FCDDB1", secondary: "#FEF2DF", simpleName: "yellow" },
  2: { primary: "#FFCABB", secondary: "#FDE8E7", simpleName: "red" },
};

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const InvoiceProductCard = ({
  lineItems,
  updateCache,
  invoiceId,
  productLibrary,
  vendorId,
  currentLinkedOrders,
  expenseGroups,
  toggleIsExpenseGroupModalOpen,
  openSubgoupEditModal,
  invalidLineItems,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [lineItemsCopy, setLineItemsCopy] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [linkOrderModalOpen, toggleLinkOrderModalOpen] = useState(false);
  const [listType, setListType] = useState("");
  const store = useContext(Store);
  const useMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { establishmentid } = useParams();
  const [nextOrderPageToLoad, setNextOrderPageToLoad] = useState(1);
  const [orderOptions, setOrderOptions] = useState([]);
  const [totalOrderPages, setTotalOrderPages] = useState(1);
  const [isLoadingVendorOrders, toggleIsLoadingVendorOrders] = useState(false);
  const [loadingOrdersError, toggleLoadingOrdersError] = useState(false);
  const [orderColorMap, setOrderColorMap] = useState({});
  const [orderForFilter, setOrderForFilter] = useState(undefined);
  const [isNoUnlinkedLineItems, toggleIsNoUnlinkedLineItems] = useState(false);
  const [
    isManageLinkedLineItemModalOpen,
    toggleIsManageLinkedLineItemModalOpen,
  ] = useState(false);
  const [lineItemToManageLink, setLineItemToManageLink] = useState(undefined);
  const queryClient = useQueryClient();

  useEffect(() => {
    setLineItemsCopy([...lineItems]);
    const optionsList = [];
    productLibrary?.forEach((x) => {
      x.productFormats.forEach((y) => {
        optionsList.push(
          Object.assign({}, y, { productName: x.name, productId: x.id })
        );
      });
    });

    setProductOptions(optionsList);
    const newColorMap = {};
    currentLinkedOrders?.forEach((x, i) => {
      newColorMap[x.id] = colorIndexMap[i];
    });
    setOrderColorMap(newColorMap);
    toggleIsNoUnlinkedLineItems(!lineItems.some((x) => x.orderLinkId === null));
  }, [lineItems, productLibrary]);

  useEffect(() => {
    fetchPaginatedVendorOrderData();
  }, []);

  const fetchPaginatedVendorOrderData = async () => {
    try {
      toggleLoadingOrdersError(false);
      toggleIsLoadingVendorOrders(true);
      const response = await inventoryClient.getExistingOrders({
        queryKey: [
          {},
          {
            establishmentId: establishmentid,
            page: nextOrderPageToLoad,
            vendorId,
          },
        ],
      });
      setOrderOptions([...orderOptions, ...response.orders]);
      toggleIsLoadingVendorOrders(false);
      setNextOrderPageToLoad(nextOrderPageToLoad + 1);
      setTotalOrderPages(response.totalPages);
    } catch (e) {
      console.log(e);
      toggleLoadingOrdersError(true);
      toggleIsLoadingVendorOrders(false);
    }
  };

  const addLineItemMutation = useMutation(inventoryClient.addProductLineItem, {
    onSuccess: (result) => {
      const newLineItems = [...lineItemsCopy, result.lineItem];
      setLineItemsCopy(newLineItems);
      updateCache();
    },
  });

  const invalidateInvoiceQueryCaches = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "invoice",
        {
          establishmentId: establishmentid,
          invoiceId,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "invoice",
        {
          establishmentId: establishmentid,
          invoiceId,
        },
      ],
    });
  };

  const linkOrdersMutation = useMutation(inventoryClient.linkOrdersToInvoice, {
    onSuccess: invalidateInvoiceQueryCaches,
  });

  const linkLineItemMutation = useMutation(
    inventoryClient.linkInvoiceLineItemToOrderLineItem,
    {
      onSuccess: invalidateInvoiceQueryCaches,
    }
  );

  const unlinkOrderFromInvoiceMutation = useMutation(
    inventoryClient.unlinkOrderFromInvoice,
    {
      onSuccess: () => {
        invalidateInvoiceQueryCaches();
        setOrderForFilter(undefined);
      },
    }
  );

  const handleCompleteLink = async (payload) => {
    payload.invoiceId = invoiceId;
    payload.establishmentId = establishmentid;
    await linkOrdersMutation.mutateAsync(payload);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenLinkModal = () => {
    toggleLinkOrderModalOpen(true);
  };

  const handleLineItemLinkSelect = (lineItem) => {
    setLineItemToManageLink(lineItem);
    toggleIsManageLinkedLineItemModalOpen(true);
  };

  const handleCloseManageLinkedItemModal = () => {
    setLineItemToManageLink(undefined);
    toggleIsManageLinkedLineItemModalOpen(false);
  };

  const handleUnlinkOrder = (orderId) => {
    let payload = {
      establishmentId: establishmentid,
      invoiceId: invoiceId,
      orderId: orderId,
    };

    unlinkOrderFromInvoiceMutation.mutate(payload);
  };

  const handleLinkLineItem = async (
    lineItem,
    itemToLink,
    skipLineItemMapping
  ) => {
    const payload = {
      establishmentId: establishmentid,
      invoiceId: invoiceId,
      invoiceLineItemId: lineItem.id,
      orderLineItemId: itemToLink.id,
      orderId: itemToLink.orderId,
      skipLineItemMapping,
    };

    await linkLineItemMutation.mutateAsync(payload);
  };

  return (
    <Card
      elevation={0}
      sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
    >
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 8,
         // width: "100%",
        }}
      >
        <Grid item xs={12} sm={2}>
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: 20, md: 24 },
              lineHeight: { xs: 1, md: 1.5 },
            }}
          >
            Line Items
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          {/* TOTAL COST BADGE */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              marginRight: 2,
              width: "220px",
              height: "40px",
              border: "1px solid",
              borderRadius: 1,
              borderColor: (theme) => theme.palette.terrain[300],
              backgroundColor: (theme) => theme.palette.terrain[50],
            }}
          >
            <TotalCostBadge
              displayText={useMobile ? "Total:" : "Total Line Item Cost:"}
              lineItems={lineItemsCopy}
            />
          </Box>
          <Button
            variant="outlined"
            sx={{ marginRight: 2 }}
            onClick={() => handleOpenLinkModal()}
          >
            {useMobile ? null : <LinkOutlinedIcon
              sx={{ transform: "rotate(90deg)", fontSize: 18, marginRight: 1 }}
            />}
            <Typography
              variant="largeButton"
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Link Order
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              addLineItemMutation.mutate({
                establishmentId: store.currentEstablishment?.id,
                invoiceId,
              })
            }
            disabled={addLineItemMutation.isLoading}
          >
            {useMobile ? null : <AddIcon sx={{ fontSize: 18, marginRight: 2 }} />}
            <Typography
              variant="largeButton"
              sx={{ fontSize: { xs: 12, md: 14 } }}
            >
              Add Product
            </Typography>
          </Button>
          {useMobile ? null : <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "36px",
              marginLeft: 2,
            }}
          >
            {!expanded ? (
              <IconButton
                sx={{
                  color: (theme) => theme.palette.pure.black,
                  height: "36px",
                }}
                onClick={handleExpandClick}
              >
                <KeyboardArrowDownOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  color: (theme) => theme.palette.pure.black,
                  height: "36px",
                }}
                onClick={handleExpandClick}
              >
                <ExpandLessOutlinedIcon />
              </IconButton>
            )}
          </Box>}
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <OrderPillBox
          orderColorMap={orderColorMap}
          currentLinkedOrders={currentLinkedOrders}
          invoiceLineItems={lineItems}
          orderForFilter={orderForFilter}
          setOrderForFilter={setOrderForFilter}
          unlinkOrder={handleUnlinkOrder}
          isNoUnlinkedLineItems={isNoUnlinkedLineItems}
        />

        {/* <ProductLineItemHeader /> */}

        <TableContainer
          sx={{
            overflow: "scroll",
            borderRadius: 2,
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[400],
            marginTop: 2,
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead sx={{ height: "24px" }}>
              <TableRow sx={{ height: 24 }}>
                <TableCell
                  sx={[tableHeadStyle, { width: "44px", borderLeft: "none" }]}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box component="div" sx={{ marginRight: -2 }}></Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "56px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Link
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Link"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              Identifies which order the Line Item is linked to,
                              or if Line Item is not linked at all.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "324px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Product Name
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Product Name"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              Product Name of Line Item.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "140px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Expense Group
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Expense Group"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              Expense Groups are how purchases are categorized
                              in Reporting.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "140px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Expense Subgroup
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Expense Subgroup"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              Expense Subgroups are an additional categorization
                              layer beneath Expense Groups.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Purchase Unit
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Purchase Unit"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              Name of the quantity of Product Formats purchased.
                              Usually either a single unit (bottle or can), or a
                              case (or package) of units.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "80px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Quantity
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Quantity"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The number of Purchase Units received.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "90px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Total Cost
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Total Cost"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The total cost of the invoice line.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "90px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Unit Cost
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Unit Cost"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The cost of the individual Purchase Unit. This is
                              automatically calculated for you by dividing the
                              total cost by the quantity.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={[tableHeadStyle, { width: "56px", borderRight: "none" }]}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lineItemsCopy.map((x) => (
                <ProductLineItemRow
                  key={x.id}
                  lineItem={x}
                  productOptions={productOptions}
                  updateLineItemCache={updateCache}
                  toggleExpenseGroupModalOpen={toggleIsExpenseGroupModalOpen}
                  openSubgoupEditModal={openSubgoupEditModal}
                  orderColorMap={orderColorMap}
                  orderForFilter={orderForFilter}
                  lineItemLinkSelectCallback={handleLineItemLinkSelect}
                  expenseGroups={expenseGroups}
                  invalidLineItems={invalidLineItems}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
      <LinkOrderModal
        isOpen={linkOrderModalOpen}
        toggleModalOpen={toggleLinkOrderModalOpen}
        listType={listType}
        setListType={setListType}
        isLoadingOrders={isLoadingVendorOrders}
        orders={orderOptions}
        nextOrderPageToLoad={nextOrderPageToLoad}
        totalOrderPages={totalOrderPages}
        errorLoadingOrders={loadingOrdersError}
        loadMoreCallback={fetchPaginatedVendorOrderData}
        completeLink={handleCompleteLink}
        currentLinkedOrders={currentLinkedOrders}
      />
      <ManageLinkedInvoiceLineItemModal
        isOpen={isManageLinkedLineItemModalOpen}
        completeLink={handleLinkLineItem}
        currentLinkedOrders={currentLinkedOrders}
        invoiceLineItems={lineItems}
        lineItem={lineItemToManageLink}
        handleCloseModal={handleCloseManageLinkedItemModal}
        openLinkOrdersModalCallback={handleOpenLinkModal}
        isLoading={linkLineItemMutation.isLoading}
        orderColorMap={orderColorMap}
      />
    </Card>
  );
};

export default InvoiceProductCard;
