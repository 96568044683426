import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import MiscItemRow from "./MiscItemRow";
import Store from "../../../Store/Store";
import inventoryClient from "../../../clients/inventoryClient";
import { useMutation } from "react-query";
import TotalCostBadge from "../components/TotalCostBadge";
import DescriptionToolTip from "../../../components/DescriptionToolTip";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  borderBottom: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const InvoiceMiscCard = ({
  miscLineItems,
  updateCache,
  invoiceId,
  expenseGroups,
  openSubgoupEditModal,
  toggleIsExpenseGroupModalOpen,
  invalidLineItems,
}) => {
  const [expanded, setExpanded] = useState(true);

  const [miscLineItemsCopy, setKegTransfersCopy] = useState([]);
  const useMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const store = useContext(Store);

  useEffect(() => {
    setKegTransfersCopy([...miscLineItems]);
  }, [miscLineItems]);

  const addMiscMutation = useMutation(inventoryClient.addMiscLineItem, {
    onSuccess: (result) => {
      const miscLineItemsUpdates = [
        ...miscLineItemsCopy,
        result.miscellaneousLineItem,
      ];
      setKegTransfersCopy(miscLineItemsUpdates);
      updateCache();
    },
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      elevation={0}
      sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
    >
      {" "}
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <Grid item xs={12} sm={2}>
          <Typography variant="h3">Miscellaneous</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 2,
                  width: "220px",
                  height: "40px",
                  border: "1px solid",
                  borderRadius: 1,
                  borderColor: (theme) => theme.palette.terrain[300],
                  backgroundColor: (theme) => theme.palette.terrain[50],
                }}
              >
                <TotalCostBadge
                  displayText="Total Other Costs:"
                  lineItems={miscLineItemsCopy}
                  isMisc
                />
              </Box>
              <Button
                sx={{marginLeft: 2}}
                variant="contained"
                onClick={() =>
                  addMiscMutation.mutate({
                    establishmentId: store.currentEstablishment?.id,
                    invoiceId,
                  })
                }
                disabled={addMiscMutation.isLoading}
              >
                {useMobile ? null : <AddIcon sx={{ fontSize: 18, marginRight: 2 }} />}
                <Typography
                  variant="largeButton"
                  sx={{ fontSize: { xs: 12, md: 14 } }}
                >
                  Add Amount
                </Typography>
              </Button>


          {useMobile ? null : (
            <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "36px",
              marginLeft: 2,
            }}
          >
            {!expanded ? (
              <IconButton
                sx={{
                  color: (theme) => theme.palette.pure.black,
                  height: "36px",
                }}
                onClick={handleExpandClick}
              >
                <KeyboardArrowDownOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  color: (theme) => theme.palette.pure.black,
                  height: "36px",
                }}
                onClick={handleExpandClick}
              >
                <ExpandLessOutlinedIcon />
              </IconButton>
            )}
          </Box>
          )}
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <TableContainer
          sx={{
            overflow: "scroll",
            borderRadius: 2,
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[400],
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead sx={{ height: "24px" }}>
              <TableRow sx={{ height: 24 }}>
                <TableCell
                  sx={[tableHeadStyle, { width: "44px", borderLeft: "none" }]}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box component="div" sx={{ marginRight: -2 }}></Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "160px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Line Type
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Miscellaneous Expense/Credit"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}></Box>
                            <Typography variant="body2">Expense</Typography>
                            <Typography variant="body1">
                              The line item reflects an expense/negative
                              balance.
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: 1 }}>
                              Credit
                            </Typography>
                            <Typography variant="body1">
                              The line item reflects a return/positive balance.
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "340px" }]}>
                  Miscellaneous Name
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "140px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Expense Group
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Expense Group"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              Expense Groups are how purchases are categorized
                              in Reporting.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "140px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Expense Subgroup
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Expense Subgroup"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              Expense Subgroups are an additional categorization
                              layer beneath Expense Groups.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "90px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Total Cost
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Total Cost"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The total cost of the invoice line.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={[tableHeadStyle, { width: "56px", borderRight: "none" }]}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {miscLineItems.map((x) => (
                <MiscItemRow
                  key={x.id}
                  miscItem={x}
                  invoiceId={invoiceId}
                  updateCache={updateCache}
                  expenseGroups={expenseGroups}
                  openSubgoupEditModal={openSubgoupEditModal}
                  toggleIsExpenseGroupModalOpen={toggleIsExpenseGroupModalOpen}
                  invalidLineItems={invalidLineItems}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Card>
  );
};

export default InvoiceMiscCard;
