import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import utilFunctions from "../../../utilities/utilFunctions";

const VendorOrderReviewCard = ({
  order,
  currencySymbol,
  currencyLocale,
  currency,
  renderTopMargin,
  deleteCartItem,
  isDeletingCartItem,
}) => {
  //const onMediumOrLower = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const onSmallerScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );
  const calculateVendorCost = (lineItems) => {
    let total = 0;

    lineItems.forEach((x) => {
      const itemCost =
        x.estimatedCost?.amount === null ||
        x.estimatedCost?.amount === undefined
          ? 0
          : x.estimatedCost.amount;

      total += itemCost;
    });

    return `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
      total,
      currencyLocale,
      currency
    )}`;
  };

  const handleDeleteCartItem = (cartItem) => {
    deleteCartItem(cartItem);
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: renderTopMargin ? 4 : 0,
        }}
      >
        <Grid item sm={5} xs={12} sx={{ marginBottom: 2 }}>
          <Typography variant="h4">{order.vendor?.name || ""}</Typography>
        </Grid>
        <Grid
          item
          sm={7}
          xs={12}
          container
          direction={onSmallerScreen ? "column" : "row"}
          sx={{
            justifyContent: onSmallerScreen ? "flex-start" : "flex-end", // Align to the end on larger screens
            alignItems: onSmallerScreen ? "flex-start" : "center",
            textAlign: onSmallerScreen ? "left" : "right", // Adjust text alignment for responsiveness
            gap: onSmallerScreen ? 0.5 : 2, // Add spacing between items
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              marginRight: onSmallerScreen ? 0 : 1,
            }}
          >
            Products:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.pure.black }}
          >
            {order.lineItems?.length || 0}
          </Typography>
          {!onSmallerScreen && (
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.terrain[500],
                marginLeft: 2,
                marginRight: 2,
              }}
            >
              {"|"}
            </Typography>
          )}
          <Typography
            variant="subtitle1"
            sx={{
              color: (theme) => theme.palette.terrain[500],
              marginRight: onSmallerScreen ? 0 : 1,
            }}
          >
            Estimated Total Cost:
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (theme) => theme.palette.pure.black }}
          >
            {calculateVendorCost(order.lineItems)}
          </Typography>
        </Grid>
      </Grid>
      {order.lineItems?.map((x) => (
        <Box
          sx={{
            width: "100%",
            border: "1px solid",
            padding: 2,
            borderRadius: 2,
            borderColor: (theme) => theme.palette.terrain[300],
            backgroundColor: (theme) => theme.palette.pure.white,
            marginTop: 2,
            display: "flex", // Use flexbox for alignment
            justifyContent: "space-between", // Space out the content
            alignItems: "center", // Vertically align items
          }}
          key={x.id}
        >
          {/* Left Content */}
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2">
              {x.productName}{" "}
              {x.unitSize === null
                ? `(${x.unitSizeAbbreviation}`
                : `(${x.unitSize} ${x.unitSizeAbbreviation}`}
              {x.sku === null ? ")" : `, SKU: ${x.sku})`}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
              {x.orderAmount} {x.purchaseUnitName}{" "}
              {`(${x.totalUnitsOrdered} units)`}
            </Typography>
          </Box>

          {/* Price */}
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 700, marginRight: 2 }} // Fixed spacing before close button
          >
            {x.estimatedCost?.amount > 0
              ? `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
                  x.estimatedCost.amount,
                  currencyLocale,
                  currency
                )}`
              : "N/A"}
          </Typography>

          {/* Close Button */}
          <IconButton
            disabled={isDeletingCartItem}
            onClick={() => handleDeleteCartItem(x)}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      ))}
    </>
  );
};

export default VendorOrderReviewCard;
