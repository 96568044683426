import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React from "react";

const NestedListExpandIcon = ({ isOpen, toggleOpen }) => {
  return isOpen ? (
    <ExpandLess
      sx={{
        borderRadius: 150,
        backgroundColor: (theme) => theme.palette.terrain[800],
        color: (theme) => theme.palette.pure.white,
      }}
      onClick={toggleOpen}
    />
  ) : (
    <ExpandMore
      sx={{
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[200],
        borderRadius: 150,
      }}
      onClick={toggleOpen}
    />
  );
};

export default NestedListExpandIcon;
