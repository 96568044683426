import React from "react";
import { modalBoxStyle } from "./styles";
import { Typography, Box, Modal, Button } from "@mui/material";
import { updateStaffRole } from "../../clients/establishmentsClient";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const EditStaffModal = ({
  staffMember,
  establishmentId,
  fetchEstablishmentMemberships,
  toggleEditStaffModal,
  open,
  setAlertSuccess,
  setAlertMessage,
  toggleError,
  setErrorMessage,
}) => {
  async function editStaffRole() {
    try {
      const newRole = staffMember.role === "admin" ? "user" : "admin";
      await updateStaffRole(newRole, establishmentId, staffMember.userId);
      setAlertSuccess(true);
      setAlertMessage("User permissions updated successfully!");
      fetchEstablishmentMemberships();
    } catch (err) {
      console.log(err);
      toggleError(true);
      setErrorMessage(
        "There was a problem updaing user permissions. Please referesh and try again."
      );
    }
  }

  const toggle = () => {
    setAlertSuccess(false);
    toggleEditStaffModal();
  };

  function editMessages() {
    if (staffMember.role == "admin")
      return `Would you like to remove admin permissions
    from ${staffMember.firstName} ${staffMember.lastName}`;
    else {
      return `Would you like to give admin permissions to ${staffMember.firstName} ${staffMember.lastName}`;
    }
  }

  if (!staffMember) {
    return null;
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={toggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalBoxStyle}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <EditOutlinedIcon sx={{ fontSize: 100 }} />
          </div>
          <Typography
            style={{
              fontSize: "2rem",
              marginBottom: "2rem",
              display: "flex",
              fontWeight: "bold",
              justifyContent: "center",
            }}
          >
            {" "}
            Edit Permissions{" "}
          </Typography>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
            variant="body1"
          >
            {" "}
            {editMessages()}?{" "}
          </Typography>
          <div
            style={{
              display: "flex",
              marginTop: "1rem",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: 2,
              }}
              onClick={toggle}
            >
              <Typography variant="smallButton">Cancel</Typography>
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                marginLeft: "1rem",
                padding: "0px 25px 0px 25px",
                borderRadius: 2,
              }}
              onClick={(e) => {
                e.preventDefault();
                editStaffRole();
              }}
            >
              <Typography variant="smallButton">Yes</Typography>
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditStaffModal;
