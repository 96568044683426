import * as React from "react";
import inventoryObjects from "../clients/inventoryObjects";

const activeRouteKeys = {
  menu: "menu",
  vendors: "vendors",
  areas: "areas",
  productLibrary: "productLibrary",
  invoices: "invoices",
  stockCount: "stockCount",
  recipes: "recipes",
  ordering: "ordering",
  organization: "organization"
};

const Store = React.createContext({
  authenticated: false,
  tokenExpired: false,
  user: undefined,
  updateUserProfile: () => {},
  logout: () => {},
  userId: undefined,
  establishments: [],
  activeRoutes: {},
  currentEstablishment: undefined,
  updateEstablishmentOptions: () => {},
  updateCurrentEstablishment: () => {},
  updateSelectedCountry: () => {},
  updateActiveRoute: () => {},
  sideNavOpen: true,
  toggleSideNavOpen: () => {},
  resetActiveRoutes: () => {},
  appendInvalidateQueriesQueue: () => {},
  resetInvalidateQueriesQueue: () => {},
  invalidateQueriesQueue: [],
  recipeFilters: {
    sortAsc: true,
    sortByCreatedDate: false,
    tagFilters: [],
    showInactive: false,
  },
  recipeSearchText: '',
  recipeSelectedPage: 1,
  setRecipeFilters: () => {},
  setRecipeSelectedPage: () => {},
  setRecipeSearchText: () => {},
  productLibraryPage: 1,
  setProductLibraryPage: () => {},
  productLibrarySortAscending: true,
  toggleProductLibrarySortAscending: () => {},
  productLibraryPropertyForSort: inventoryObjects.productSortProperties.name,
  setProductLibraryPropertyForSort: () => {},
  productLibrarySortFilters: {},
  setProductLibrarySortFilters: () => {},
  productLibrarySearchText: '',
  setProductLibrarySearchText: () => {},
  resetCachedEstablishmentData: () => {},
  selectedProductLibraryPanel: 0,
  setSelectedProductLibraryPanel: () => {},
  newProductsToAdd: [],
  setNewProductsToAdd: () => {},
});

export default Store;

export { activeRouteKeys };
