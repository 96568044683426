import React, { useContext, useEffect, useState } from "react";
import { modalBoxStyle } from "./styles";
import {
  CircularProgress,
  TextField,
  Button,
  Typography,
  Box,
  Modal,
  Alert,
  MenuItem,
  Divider,
} from "@mui/material";
import {
  addBeer,
  addRecipe,
  addWine,
  getFlattenedEstablishmentCategories,
} from "../../clients/establishmentsClient";
import Store from "../../Store/Store";
import utilFunctions from "../../utilities/utilFunctions";

const FormInputLabelStyles = {
  shrink: true,
};

const CopyItemModal = ({
  titleType,
  item,
  copyCallback,
  isOpen,
  toggleModalOpen,
  errorCallback,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, toggleLoading] = useState(true);
  const [error, toggleError] = useState(false);
  const [addError, toggleAddError] = useState(false);
  const [newTitle, setNewTitle] = useState();
  const [catDest, setCatDest] = useState("");
  const store = useContext(Store);

  useEffect(() => {
    const fetchFlatCategories = async () => {
      try {
        const establishment = store.currentEstablishment;
        if (item) {
          const response = await getFlattenedEstablishmentCategories(
            establishment.id
          );
          setOptions(response);
          toggleLoading(false);
        }
      } catch (err) {
        toggleLoading(false);
        console.log(err);
      }
    };

    if (item) {
      setNewTitle(`${item.name} - Copy`);
    }
    fetchFlatCategories();
  }, [item]);

  const disabledButtonBackgroundColor = !catDest ? "#c6c6c6" : "";

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const addItemByType = async (newItem) => {
    switch (newItem.type) {
      case "recipe":
        await addRecipe(newItem);
        break;
      case "wine":
        await addWine(newItem);
        break;
      case "beer":
        await addBeer(newItem);
        break;
      default:
        throw "error";
    }
  };

  const handleCopyCallback = async () => {
    try {
      toggleAddError(false);
      toggleLoading(true);
      const newItem = Object.assign({}, item, {
        name: newTitle,
        ancestorItemId: item.id,
        categoryId: catDest,
      });
      await addItemByType(newItem);
      await copyCallback(newItem, catDest);
      toggleLoading(false);
    } catch (err) {
      console.log(err);
      toggleLoading(false);
      toggleAddError(true);
      errorCallback("There was an error trying to copy your item.");
    }
  };

  const setCategoryDestination = async (categoryId) => {
    if (categoryId === "Select Destination") {
      setCatDest(null);
    } else {
      setCatDest(categoryId);
    }
  };

  const handleFormChange = (e) => {
    setNewTitle(e.target.value);
    if (e.target.id === "name" && !e.target.value.trim()) {
      toggleError(true);
    } else {
      toggleError(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box>
              <Typography variant="h4" sx={{ marginBottom: 4 }}>
                Copy Item {utilFunctions.capitalizeString(titleType)}
              </Typography>
              <Box sx={{ marginBottom: 10 }}>
                <Divider />
              </Box>
            </Box>
            {addError && (
              <Alert severity="error" style={{ marginBottom: "1rem" }}>
                There was a problem trying to create the copy
              </Alert>
            )}
            {error && (
              <Alert severity="error" style={{ marginBottom: "1rem" }}>
                Name cannot be empty
              </Alert>
            )}
            <Box sx={{ marginBottom: 6 }}>
              <TextField
                size="small"
                fullWidth
                style={{ marginBottom: "1rem" }}
                InputLabelProps={FormInputLabelStyles}
                label="Item Title"
                value={newTitle}
                onChange={handleFormChange}
                id="name"
              />
            </Box>
            <Box sx={{ marginBottom: 6 }}>
              <Typography variant="subtitle2">
                Where would you like to copy {`"${item?.name}"`} to?
              </Typography>
            </Box>
            <Box sx={{ marginBottom: 8 }}>
              <TextField
                size="small"
                fullWidth
                select
                label="Select Destination"
                InputLabelProps={FormInputLabelStyles}
                value={catDest}
                id="selectCat"
                onChange={(event) => setCategoryDestination(event.target.value)}
              >
                {options.map((o) => (
                  <MenuItem key={o.id} value={o.id}>
                    {o.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: "1rem",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 4,
                  paddingRight: 4,
                }}
                onClick={() => {
                  toggleModalOpen();
                }}
              >
                <Typography variant="smallButton">Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  background: `${disabledButtonBackgroundColor}`,
                  marginLeft: "1rem",
                  borderRadius: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 6,
                  paddingRight: 6,
                }}
                onClick={handleCopyCallback}
                disabled={error || !catDest}
              >
                <Typography variant="smallButton">Copy</Typography>
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CopyItemModal;
