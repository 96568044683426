import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Store, { activeRouteKeys } from "../../Store/Store";
import { useNavigate, useParams } from "react-router-dom";
import OrganizationCard from "./components/OrganizationCard";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import useEstablishmentOrganizationCount from "../../hooks/useEstablishmentOrganizationCount";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import SpecSpinner from "../../components/SpecSpinner";

const OrganizationDisplay = () => {
  const { establishmentid } = useParams();
  const store = useContext(Store);
  const navigate = useNavigate();
  const { data: data, isLoading: isLoadingOrgCount } = useEstablishmentOrganizationCount(establishmentid)
  const { data: subscriptionState, isLoading: isLoadingSubscriptionState } = useEstablishmentSubscription(establishmentid);

  const handleAreaClick = () => {
    navigate(`/spec/${establishmentid}/organization/areas`);
  };

  const handleFamilyClick = () => {
    navigate(`/spec/${establishmentid}/organization/families`);
  };

  const handleExpenseGroupClick = () => {
    navigate(`/spec/${establishmentid}/organization/expensegroups`);
  };

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.organization,
      `spec/${establishmentid}/organization`
    );
  }, []);

  const organizationData = [
    {
      name: "Families",
      subHeader1: "Product Families",
      subHeader1Data: data ? data.productFamilyCount : 0,
      subHeader2: "Categories",
      subHeader2Data: data ? data.categoryCount : 0,
      subHeader3: "Subcategories",
      subHeader3Data: data ? data.subCategoryCount : 0,
      handleClick: handleFamilyClick,
      icon: (
        <AccountTreeOutlinedIcon
          sx={{
            fontSize: 52,
            color: (theme) => theme.palette.primary[800],
          }}
        />
      ),
    },
    {
      name: "Areas",
      subHeader1: "Areas",
      subHeader1Data: data ? data.areaCount : 0,
      subHeader2: "Sections",
      subHeader2Data: data ? data.sectionCount : 0,
      handleClick: handleAreaClick,
      isSubscriptionDisabled: !subscriptionState?.isActive,
      icon: (
        <LocationOnOutlinedIcon
          sx={{
            fontSize: 52,
            color: (theme) => theme.palette.primary[800],
          }}
        />
      ),
    },
    // {
    //   name: "Par Levels",
    //   subHeader1: "Low Pars Set",
    //   subHeader1Data: "0",
    //   subHeader2: "On Hands Set",
    //   subHeader2Data: "0",
    //   subHeader3: "High Pars Set",
    //   subHeader3Data: "0",
    //   handleClick: handleFamilyClick,
    //   icon: (
    //     <BarChartOutlinedIcon
    //       sx={{
    //         fontSize: 52,
    //         color: (theme) => theme.palette.primary[800],
    //       }}
    //     />
    //   ),
    // },
    // {
    //   name: "Tags",
    //   subHeader1: "Tags Created",
    //   subHeader1Data: "0",
    //   subHeader2: "Recipes Assigned",
    //   subHeader2Data: "0",
    //   handleClick: handleFamilyClick,
    //   icon: (
    //     <BookOutlinedIcon
    //       sx={{
    //         fontSize: 52,
    //         color: (theme) => theme.palette.primary[800],
    //       }}
    //     />
    //   ),
    // },
    {
      name: "Expense Groups",
      subHeader1: "Expense Groups",
      subHeader1Data: data ? data.expenseGroupCount : 0,
      subHeader2: "Expense Subgroups",
      subHeader2Data: data ? data.expenseSubGroupCount : 0,
      handleClick: handleExpenseGroupClick,
      icon: (
        <PaymentsOutlinedIcon
          sx={{
            fontSize: 52,
            color: (theme) => theme.palette.primary[800],
          }}
        />
      ),
    },
  ];

  if (isLoadingOrgCount || isLoadingSubscriptionState) {
    return <SpecSpinner open text="Organizing details..." />
  }

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "2440px",
          height: "800px",
          padding: 4,
          marginTop: 6,
        }}
      >
        <Box>
          <Grid
            container
            sx={{
              marginBottom: 8,
              display: "flex",
            }}
          >
            <Grid item xs={5} sm={4} md={3} xl={2} lg={2}>
              <Typography variant="h2">Organization</Typography>
            </Grid>
          </Grid>
          <Box>
            <Grid
              container
              spacing={3}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {organizationData.map((x) => (
                <Grid item xs={6} sm={6} md={6} lg={4} xl={3} key={x.name}>
                  <OrganizationCard
                    handleClick={x.handleClick}
                    icon={x.icon}
                    organizationData={x}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default OrganizationDisplay;
