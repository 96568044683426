import React from "react";
import { Chip, Typography } from "@mui/material";

const SectionPill = ({ selected, clickChip, section }) => {
  return (
    <Chip
      label={
        <Typography
          variant="body2"
          sx={{
            color: selected
              ? (theme) => theme.palette.pure.white
              : (theme) => theme.palette.terrain[900],
          }}
        >
          {section.name}
        </Typography>
      }
      size="medium"
      onClick={() => clickChip(section)}
      clickable
      variant="outlined"
      sx={{
        backgroundColor: selected
          ? (theme) => theme.palette.terrain[900]
          : (theme) => theme.palette.pure.white,
        borderColor: (theme) => theme.palette.primary[800],
      }}
    />
  );
};

export default SectionPill;
