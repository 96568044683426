import React from "react";
import { Box, Tab, Tabs } from "@mui/material";

const tabStyle = {
  textTransform: "none",
  fontSize: { xs: "12px", md: "16px" },
  fontFamily: "Inter",
  "&:hover": {
    color: (theme) => theme.palette.primary.light,
    opacity: 1,
  },
  "&.Mui-selected": {
    color: (theme) => theme.palette.primary.main,
    fontWeight: "700",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
};

const TabularOrderItemNav = ({ currentTab, handleChange, selectedOrders }) => {

  if (selectedOrders.length <= 1) {
    return null;
  }

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        sx={{
          marginBottom: 2,
          "& .MuiTabs-indicator": {
            backgroundColor: (theme) => theme.palette.primary.main,
            height: 3,
          },
        }}
      >
        {selectedOrders.map((x, i) => (
          <Tab
            component={"span"}
            key={x.id}
            label={`Order ${i + 1}`}
            sx={tabStyle}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default TabularOrderItemNav;
