import { Box, TextField, Typography } from "@mui/material";
import React from "react";

const ProductCard = ({ id, handleUpdate, productName, productFormat }) => {
  const handleNameChange = (e) => {
    handleUpdate(id, e.target.value);
  };
  return (
    <Box
      sx={{
        height: "60px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: (theme) => theme.palette.terrain[50],
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[300],
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "160px",
        }}
      >
        {" "}
        <div
          style={{
            maxWidth: "160px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography noWrap variant="body1">
            {productName}
          </Typography>
        </div>
        <Typography variant="overline">{productFormat}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Low Par"
          // value={price}
          placeholder="Low Par"
          autoComplete="off"
          onChange={handleNameChange}
          inputProps={{ background: (theme) => theme.palette.pure.white }}
          sx={{
            width: "100px",
            marginRight: 1,
            "& .MuiOutlinedInput-root": {
              background: (theme) => theme.palette.pure.white,
            },
          }}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          // value={price}
          label="High Par"
          placeholder="High Par"
          autoComplete="off"
          onChange={handleNameChange}
          inputProps={{ background: (theme) => theme.palette.pure.white }}
          sx={{
            width: "100px",
            marginLeft: 1,
            "& .MuiOutlinedInput-root": {
              background: (theme) => theme.palette.pure.white,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ProductCard;
