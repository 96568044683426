import React from "react";
import { Box, IconButton } from "@mui/material";
import OrderLinkIcon from "../../../components/CustomIcons/OrderLinkIcon";
import UnlinkedIcon from "../../../components/CustomIcons/UnlinkedIcon";
import SubstitutionIcon from "../../../components/CustomIcons/SubstitutionIcon";

const OrderLineItemLinkButton = ({
  lineItem,
  lineItemLinkSelectCallback,
  orderColorMap,
}) => {
  const handleLineItemLinkSelect = () => {
    lineItemLinkSelectCallback(lineItem);
  };
  if (lineItem.orderLineItemLinkId === null) {
    return (
      <IconButton
        sx={{
          backgroundColor: (theme) => theme.palette.terrain[200],
          width: "36px",
          height: "36px",
          padding: 0,
        }}
        onClick={handleLineItemLinkSelect}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "100%",
            height: "30px",
            width: "30px",
            backgroundColor: (theme) => theme.palette.terrain[200],
          }}
        >
          <UnlinkedIcon sx={{ fontSize: 18 }} />
        </Box>
      </IconButton>
    );
  }

  return (
    <IconButton
      sx={{
        backgroundColor: orderColorMap[lineItem.orderLinkId]?.secondary,
        width: "36px",
        height: "36px",
        padding: 0,
      }}
      onClick={handleLineItemLinkSelect}
    >
      {lineItem.isSubstitution ? (
        <SubstitutionIcon
          color={orderColorMap[lineItem.orderLinkId]?.simpleName}
          sx={{ fontSize: 30 }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "100%",
            height: "30px",
            width: "30px",
            backgroundColor: orderColorMap[lineItem.orderLinkId]?.primary,
          }}
        >
          <OrderLinkIcon
            sx={{
              color: (theme) => theme.palette.pure.black,
              fontSize: 18,
            }}
          />
        </Box>
      )}
    </IconButton>
  );
};

export default OrderLineItemLinkButton;
