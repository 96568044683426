import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import { Box, Typography } from "@mui/material";

const ArbitrarySizeCard = ({ size, updateSize, setDefault }) => {
  const FormInputLabelStyles = {
    shrink: true,
  };

  return (
    <Grid container direction="row" sx={{ padding: 2 }}>
      <Grid item xs={2}>
        <Typography>Primary</Typography>{" "}
        <Radio
          checked={size.isDefaultSize}
          onChange={() => setDefault(size.type)}
        />
      </Grid>
      <Grid item xs={10} sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
        <Box style={{ width: "100%" }}>
          <Grid
            container
            spacing={3}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingLeft: 2,
            }}
          >
            <Grid item sx={{ width: "100%" }}>
              <TextField
                required
                size="small"
                fullWidth
                id="numericalCount"
                label="Numerical Count"
                inputProps={{
                  autocomplete: 'off',
                }}
                placeholder="Numerical Count"
                InputLabelProps={FormInputLabelStyles}
                value={size?.size}
                onChange={(e) => updateSize(size.type, e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ArbitrarySizeCard;
