import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "bootstrap/dist/css/bootstrap.min.css";
import loadConfig from "./utilities/loadConfig";
import configuration from "./configuration.json";
import ReactGA from "react-ga";
require("./assets/specscss/specstyles.scss");
require("./scss/todo.scss");
require("./assets/css/defaultStyles.css");
require("./scss/todo.scss");

AWS.config.update({
  region: "us-east-2",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-2:36da2a7e-0fef-404d-adc6-03e5b5d4dc49",
  }),
});

ReactGA.initialize("UA-121808732-3");

loadConfig(
  configuration.identityBaseUrl,
  configuration.authBaseUrl,
  configuration.establishmentsBaseUrl,
  configuration.inventoryBaseUrl
).then(() => {
  ReactDOM.render(<App />, document.getElementById("root"));
});
