import React, { useContext, useEffect, useState } from "react";
import {
  getEstablishment,
  getEstablishmentCategories,
  updateCategory,
  addRecipe,
  addWine,
  addBeer,
} from "../../clients/establishmentsClient";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import MenuListItem from "./components/MenuListItem";
import Alert from "@mui/material/Alert";
import { IconButton, Snackbar } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Store from "../../Store/Store";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import SpecBackButton from "../../components/SpecBackButton";
import AddNewDrinkForm from "./components/AddNewDrinkForm";
import CTABlock from "../../components/CTABlock";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MenuManagementModal from "../../components/Modals/MenuManagementModal";
import utilFunctions from "../../utilities/utilFunctions";

import CopyItemModal from "../../components/Modals/CopyItemModal";
import MoveItemModal from "../../components/Modals/MoveItemModal";
import DeleteItemModal from "../../components/Modals/DeleteItemModal";
import SpecSpinner from "../../components/SpecSpinner";

const MenuDetails = () => {
  const store = useContext(Store);
  const [menuData, setMenuData] = useState(undefined);
  const [loading, toggleLoading] = useState(true);
  const [isError, toggleError] = useState(false);
  const [creating, toggleCreating] = useState(false);
  const [updatingMenu, toggleUpdatingMenu] = useState(false);
  let { menuid, establishmentid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [itemToMove, setItemToMove] = useState(undefined);
  const [itemToCopy, setItemToCopy] = useState(undefined);
  const [itemToDelete, setItemToDelete] = useState(undefined);
  const [moveItemModalOpen, toggleMoveItemModalOpen] = useState(false);
  const [copyItemModalOpen, toggleCopyItemModalOpen] = useState(false);
  const [deleteItemModalOpen, toggleDeleteItemModalOpen] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageContent, setErrorMessageContent] = useState("");
  const [successMessageContent, setSuccessMessageContent] = useState("");
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [spinnerText, setSpinnerText] = useState("");

  const handleMoveModal = (item) => {
    setItemToMove(item);
    toggleMoveItemModalOpen(!moveItemModalOpen);
  };

  const handleAlertClose = () => {
    setAlertSuccess(false);
    setErrorMessage(false);
  };

  const handleCopyModal = (item) => {
    setItemToCopy(item);
    toggleCopyItemModalOpen(!copyItemModalOpen);
  };

  const handleDeleteModal = (item) => {
    setItemToDelete(item);
    toggleDeleteItemModalOpen(!deleteItemModalOpen);
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        toggleLoading(true);
        setSpinnerOpen(true);
        setSpinnerText("Loading Menu Information");
        let menu = location.state?.menu;
        if (!menu) {
          const categories = await getEstablishmentCategories(establishmentid);
          menu = categories.find((c) => c.id === menuid);
          const currentEstablishment = await getEstablishment(establishmentid);
          store.updateCurrentEstablishment(currentEstablishment);
        }
        setMenuData(menu);
        toggleLoading(false);
        setSpinnerOpen(false);
        setSpinnerText("");
      } catch (err) {
        console.log(err);
        toggleError(true);
        toggleLoading(false);
        setSpinnerOpen(false);
        setSpinnerText("");
      }
    };

    if (location.state?.displayDeleteSuccess) {
      setAlertSuccess(true);
      setSuccessMessageContent("Success! Your item has been deleted.");
      navigate(location.pathname, { replace: true });
    }
    fetchMenuData();
  }, []);

  const handleRefreshCategories = async () => {
    const currentEstablishment = await getEstablishment(establishmentid);
    const estCategories = await getEstablishmentCategories(establishmentid);
    const updatedMenuData = estCategories.find((m) => m.id === menuData.id);
    setMenuData(updatedMenuData);
    store.updateCurrentEstablishment(currentEstablishment);
  };

  const handleUpdateMenu = async (updatedMenu) => {
    toggleLoading(true);
    setSpinnerOpen(true);
    setSpinnerText("Updating menu information...");
    const updatedMenuData = Object.assign({}, menuData, updatedMenu);
    await updateCategory(updatedMenuData);
    setMenuData(updatedMenuData);
    handleEditMenuToggle();
    toggleLoading(false);
    setSpinnerOpen(false);
    setSpinnerText(false);
  };

  const handleEditMenuToggle = () => {
    toggleUpdatingMenu(!updatingMenu);
  };

  const moveCallback = async () => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! Your item has been moved.");
    toggleMoveItemModalOpen(false);
    await handleRefreshCategories();
  };

  const copyCallback = async () => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! Your item has been copied.");
    toggleCopyItemModalOpen(false);
    await handleRefreshCategories();
  };

  const deleteCallback = async () => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! Your item has been deleted.");
    toggleDeleteItemModalOpen(false);
    await handleRefreshCategories();
  };

  const errorCallback = (errorMessage) => {
    setErrorMessage(true);
    setErrorMessageContent(errorMessage);
  };

  const addRecipeCallback = async (recipe) => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! Your item has been created.");
    toggleLoading(true);
    setSpinnerOpen(true);
    setSpinnerText("Adding Recipe...");
    addRecipe(recipe);
    await handleRefreshCategories();

    toggleCreating(false);
    toggleLoading(false);
    setSpinnerOpen(false);
    setSpinnerText("");
  };

  const addWineCallback = async (wine) => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! Your item has been created.");
    toggleLoading(true);
    setSpinnerOpen(true);
    setSpinnerText("Adding Wine...");
    addWine(wine);
    await handleRefreshCategories();

    toggleCreating(false);
    toggleLoading(false);
    setSpinnerOpen(false);
    setSpinnerText("");
  };

  const addBeerCallback = async (beer) => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! Your item has been created.");
    toggleLoading(true);
    setSpinnerOpen(true);
    setSpinnerText("Adding Beer...");
    addBeer(beer);
    await handleRefreshCategories();

    toggleCreating(false);
    toggleLoading(false);
    setSpinnerOpen(false);
    setSpinnerText("");
  };

  if (loading) {
    return <SpecSpinner open={spinnerOpen} text={spinnerText} />;
  }

  if (isError) {
    return (
      <Typography variant="h2" sx={{ color: "red" }}>
        There was an error fetching your menu. Please try refreshing.
      </Typography>
    );
  }

  if (creating) {
    return (
      <Grid
        container
        sx={{
          width: "100%",
          maxWidth: "2440px",
          paddingBottom: 8,
          justifySelf: "flex-start",
        }}
      >
        <Grid item xs={12}>
          <AddNewDrinkForm
            goBack={() => toggleCreating(false)}
            categoryId={menuData.id}
            addRecipeCallback={addRecipeCallback}
            addWineCallback={addWineCallback}
            addBeerCallback={addBeerCallback}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ maxWidth: "2440px", padding: 4 }}
      >
        <Grid item>
          <SpecBackButton
            onClick={() => navigate(`/homebase/${establishmentid}/menus`)}
          />
        </Grid>
        <Grid item sx={{ marginLeft: 2, marginTop: 6 }}>
          <Grid
            container
            spacing={4}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Grid item>
                <Grid container direction="column">
                  <Grid item sx={{ marginBottom: 4 }}>
                    <Grid
                      container
                      direction="row"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item>
                        <Typography
                          variant="h2"
                          sx={{ marginRight: 2, fontSize: { xs: 18, sm: 24 } }}
                        >
                          {utilFunctions.capitalizeString(menuData.name)}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ marginLeft: 2 }}>
                        <IconButton
                          sx={{
                            border: "1px solid",
                            color: (theme) => theme.palette.primary.main,
                            height: "28px",
                            width: "28px",
                            borderRadius: 2,
                          }}
                          variant="outlined"
                          onClick={handleEditMenuToggle}
                        >
                          <EditIcon
                            sx={{
                              width: "12px",
                              color: (theme) => theme.palette.pure.black,
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "140px",
                        height: "32px",
                        borderRadius: 1,
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.terrain[300],
                        backgroundColor: (theme) => theme.palette.pure.white,
                        marginRight: 2,
                      }}
                    >
                      <Typography variant="body1">Menu Type: </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.info.main,
                          marginLeft: 1,
                        }}
                      >
                        {utilFunctions.capitalizeString(menuData.type)}{" "}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "140px",
                        height: "32px",
                        borderRadius: 1,
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.terrain[300],
                        backgroundColor: (theme) => theme.palette.pure.white,
                      }}
                    >
                      <Typography variant="body1">Menu Status: </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.info.main,
                          marginLeft: 1,
                        }}
                      >
                        {menuData.active ? "Active" : "Inactive"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={5} md={4}>
              {menuData?.items?.length > 0 && (
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{ borderRadius: 2, padding: 2.5 }}
                    onClick={() => toggleCreating(true)}
                  >
                    <AddOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                    <Typography variant="smallButton">Add New Drink</Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Box sx={{ marginTop: 4 }}>
            {menuData.items?.length > 0 ? (
              <List sx={{ width: "100%" }}>
                {utilFunctions
                  .sortByNameAlphabetically(menuData.items)
                  .map((r) => (
                    <MenuListItem
                      key={r.id}
                      establishmentId={establishmentid}
                      menuItem={r}
                      menuId={menuid}
                      deleteModalCallback={handleDeleteModal}
                      copyModalCallback={handleCopyModal}
                      moveModalCallback={handleMoveModal}
                    />
                  ))}
              </List>
            ) : (
              <CTABlock
                header="Your Menu is Empty"
                subheader="You have not added any drinks yet. Get started now..."
                buttonText="Add New Drink"
                ButtonImage={AddIcon}
                handleCTA={() => toggleCreating(true)}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <MenuManagementModal
        onSave={handleUpdateMenu}
        modalOpen={updatingMenu}
        toggleModalOpen={handleEditMenuToggle}
        existingMenu={menuData}
      />
      <CopyItemModal
        isOpen={copyItemModalOpen}
        toggleModalOpen={handleCopyModal}
        item={itemToCopy}
        copyCallback={copyCallback}
        errorCallback={errorCallback}
      />
      <MoveItemModal
        isOpen={moveItemModalOpen}
        toggleModalOpen={handleMoveModal}
        item={itemToMove}
        moveCallback={moveCallback}
        errorCallback={errorCallback}
      />
      <DeleteItemModal
        isOpen={deleteItemModalOpen}
        toggleModalOpen={handleDeleteModal}
        item={itemToDelete}
        errorCallback={errorCallback}
        deleteCallback={deleteCallback}
      />
      <Box>
        <Snackbar
          open={alertSuccess}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {successMessageContent}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessageContent}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default MenuDetails;
