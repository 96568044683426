import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const TabPanel = ({ children, value, index }) => {
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
