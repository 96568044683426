import getSymbolFromCurrency from "currency-symbol-map";


function useVendorCurrency(inventorySettingsCurrencyType) {
  let locale = "en-US";
  let currency = "USD";
  let currencySymbol = getSymbolFromCurrency("USD");
  switch (inventorySettingsCurrencyType) {
    case 2:
      locale = "en-GB";
      currency = "GBP";
      currencySymbol = getSymbolFromCurrency("GBP");
      break;
    default:
      locale = "en-US";
      currency = "USD";
      currencySymbol = getSymbolFromCurrency("USD");
  }

  return { locale, currency, currencySymbol };
}

export default useVendorCurrency;
