import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import inventoryObjects from "../../clients/inventoryObjects";

const ParDisplayBadge = ({ record }) => {
  if (record.finalParStatus === inventoryObjects.parStatusTypes.good) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "82px",
          height: "24px",
          backgroundColor: (theme) => theme.palette.success[50],
          borderRadius: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.success[700] }}
        >
          Par Good
        </Typography>
      </Box>
    );
  } else if (record.finalParStatus === inventoryObjects.parStatusTypes.over) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "82px",
          height: "24px",
          backgroundColor: (theme) => theme.palette.warning[50],
          borderRadius: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.warning[800] }}
        >
          Over Par
        </Typography>
      </Box>
    );
  } else if (record.finalParStatus === inventoryObjects.parStatusTypes.under) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "82px",
          height: "24px",
          backgroundColor: (theme) => theme.palette.error[50],
          borderRadius: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.error[800] }}
        >
          Under Par
        </Typography>
      </Box>
    );
  } else if (
    record.finalParStatus === inventoryObjects.parStatusTypes.unknown
  ) {
    return (
      <Box
        sx={{
          width: "100px",
          height: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
            variant="body1"
          >
            Par not set
          </Typography>
        {/* <Button
          variant="text"
          sx={{ width: "100px" }}
          onClick={toggleParsModalOpen}
        >
          <Typography
            variant="smallButton"
            sx={{ textDecoration: "underline" }}
          >
            Set Par Levels
          </Typography>
        </Button> */}
      </Box>
    );
  }
};

export default ParDisplayBadge;
