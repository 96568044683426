import React from "react";
import { Chip, Typography } from "@mui/material";

const UpdatedTablePill = ({ data, onSelected, currentSelected }) => {

  const handleSelect = (e) => {
    e.preventDefault();
    onSelected(data);
  }

  const selected = currentSelected === data.tag ? true : false;
  return (
    <Chip
      label={
        <Typography
          variant="body2"
          sx={{
            color: selected
              ? (theme) => theme.palette.pure.white
              : (theme) => theme.palette.terrain[900],
          }}
        >
          {data.tagName}
        </Typography>
      }
      size="medium"
      onClick={handleSelect}
      clickable
      variant="outlined"
      sx={{
        backgroundColor: selected
          ? (theme) => theme.palette.terrain[900]
          : (theme) => theme.palette.pure.white,
        borderColor: (theme) => theme.palette.primary[800],
        marginRight: 2,
      }}
    />
  );
};

export default UpdatedTablePill;
