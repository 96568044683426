import React, { useState, useEffect, useContext } from "react";
import {
  // Checkbox,
  TableCell,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Box,
  IconButton,
  Menu,
} from "@mui/material";
import Store from "../../../../Store/Store";
import inventoryClient from "../../../../clients/inventoryClient";
import ImageControl from "../../../../components/ImageControl";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { productSizeTypes } from "../../../../clients/inventoryObjects";
import { MoreHorizOutlined } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { cloneDeep } from "lodash";
import utilFunctions from "../../../../utilities/utilFunctions";
import DeleteProductFormatModal from "../../../../components/Modals/DeleteProductFormatModal";

const productStatusDefaultStyle = {
  backgroundColor: (theme) => theme.palette.success[500],
  color: (theme) => theme.palette.pure.white,
  fontWeight: 700,
  height: "24px",
  width: "110px",
  borderRadius: 100,
  borderBottom: "none",
  "& .MuiSvgIcon-root": {
    color: (theme) => theme.palette.pure.white,
  },
};

const tableCellStyle = {
  padding: 0,
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const ProductFormatSizingRow = ({
  format,
  updateProductCallback,
  handleProductUnitSizeEdit,
  product,
  handleProductFormatDelete,
  refetchCurrentPageProducts,
}) => {
  const [formatCopy, setFormatCopy] = useState({});
  const [sizeDisabled, toggleSizeDisabled] = useState(false);
  const [upcDisabled, toggleUpcDisabled] = useState(false);
  const [packagingTypeDisabled, togglePackagingTypeDisabled] = useState(false);
  const [isImageControlOpen, toggleIsImageControlOpen] = useState(false);
  const [imgDisabled, toggleImgDisabled] = useState(false);
  const [parDisabled, toggleParDisabled] = useState(false);
  const [productFormatStatusDisabled, toggleFormatStatusDisabled] =
    useState(false);
  const [deleteProductFormatModal, setDeleteProductFormatModal] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const store = useContext(Store);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDeleteProductFormat = () => {
    setDeleteProductFormatModal(!deleteProductFormatModal);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setFormatCopy(cloneDeep(format));
  }, [format]);

  const handleDelete = () => {
    handleProductFormatDelete(format.id);
  };

  const handleFormatUpdate = async (property) => {
    switch (property) {
      case "name":
        await inventoryClient.updateProductFormat(
          { id: formatCopy.id, nameUpdate: { value: formatCopy[property] } },
          store.currentEstablishment?.id
        );
        updateProductCallback(formatCopy);
        break;
      case "size":
        toggleSizeDisabled(true);
        await inventoryClient.updateProductFormat(
          { id: formatCopy.id, sizeUpdate: { value: formatCopy[property] } },
          store.currentEstablishment?.id
        );
        updateProductCallback(formatCopy);
        toggleSizeDisabled(false);
        break;
      case "upc":
        toggleUpcDisabled(true);
        await inventoryClient.updateProductFormat(
          { id: formatCopy.id, upcUpdate: { value: formatCopy[property] } },
          store.currentEstablishment?.id
        );
        updateProductCallback(formatCopy);
        toggleUpcDisabled(false);
        break;
      case "packagingType":
        togglePackagingTypeDisabled(true);
        await inventoryClient.updateProductFormat(
          {
            id: formatCopy.id,
            packagingTypeUpdate: { value: formatCopy[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(formatCopy);
        togglePackagingTypeDisabled(false);
        break;
      case "caseQuantity":
        await inventoryClient.updateProductFormat(
          {
            id: formatCopy.id,
            caseQuantityUpdate: { value: formatCopy[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(formatCopy);
        break;
      case "imgUrl":
        toggleImgDisabled(true);
        await inventoryClient.updateProductFormat(
          { id: formatCopy.id, vintageUpdate: { value: formatCopy[property] } },
          store.currentEstablishment?.id
        );
        updateProductCallback(formatCopy);
        toggleImgDisabled(false);
        break;
      case "low":
        toggleParDisabled(true);
        await inventoryClient.updateProductFormat(
          {
            id: formatCopy.id,
            lowParUpdate: { value: formatCopy.par[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(formatCopy);
        toggleParDisabled(false);
        break;
      case "high":
        toggleParDisabled(true);
        await inventoryClient.updateProductFormat(
          {
            id: formatCopy.id,
            highParUpdate: { value: formatCopy.par[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(formatCopy);
        toggleParDisabled(false);
        break;
      default:
        console.log("default case hit");
    }
  };

  const handleStatusUpdate = async (e) => {
    const newStatus = e.target.value;
    const newState = handleCopyUpdate("productFormatStatus", newStatus);
    toggleFormatStatusDisabled(true);
    await inventoryClient.updateProductFormat(
      {
        id: formatCopy.id,
        productFormatStatusUpdate: { value: newStatus },
      },
      store.currentEstablishment?.id
    );
    updateProductCallback(newState);
    refetchCurrentPageProducts();
    toggleFormatStatusDisabled(false);
  };

  const handleImageUpdate = async (imgUrl) => {
    toggleImgDisabled(true);
    const result = await inventoryClient.updateProductFormat(
      {
        id: formatCopy.id,
        imageUrlUpdate: { value: imgUrl },
      },
      store.currentEstablishment?.id
    );
    setFormatCopy(result.productFormat);
    updateProductCallback(result.productFormat);
    toggleIsImageControlOpen(false);
    toggleImgDisabled(false);
  };

  const productStatusStyle = (activeState) => {
    if (activeState === 1) {
      return {
        backgroundColor: (theme) => theme.palette.success[500],
        color: (theme) => theme.palette.pure.white,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.white,
        },
      };
    }
    if (activeState === 2) {
      return {
        backgroundColor: (theme) => theme.palette.error[900],
        color: (theme) => theme.palette.pure.white,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.white,
        },
      };
    }
    if (activeState === 4) {
      return {
        backgroundColor: (theme) => theme.palette.error[200],
        color: (theme) => theme.palette.pure.black,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.black,
        },
      };
    }
    if (activeState === 5) {
      return {
        backgroundColor: (theme) => theme.palette.terrain[400],
        color: (theme) => theme.palette.pure.black,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.black,
        },
      };
    }
  };

  const handleCopyUpdate = (property, newValue) => {
    const newData = Object.assign({}, formatCopy);
    if (property === "high" || property === "low") {
      newData.par[property] = newValue;
    } else {
      newData[property] = newValue;
    }
    setFormatCopy(newData);
    return newData;
  };

  const handleCopyUpdateFromEvent = (e) => {
    handleCopyUpdate(e.target.id, e.target.value);
  };

  const handleProductUnitSizeClick = () => {
    handleProductUnitSizeEdit(product, format.id);
  };

  const handleBlurUpdate = (e) => {
    handleFormatUpdate(e.target.id);
  };

  const defaultSize = formatCopy?.sizes?.find((x) => x.isDefaultSize);
  const defaultAbbreviation =
    utilFunctions.extractSizeAbbreviation(defaultSize);

  return (
    <>
      <TableRow sx={{ width: "100%" }}>
        {/* CHECKBOX */}
        {/* <TableCell
        sx={{
          borderLeft: "none",
          borderBottom: "none",
          borderColor: (theme) => theme.palette.terrain[400],
          background: (theme) => theme.palette.pure.white,
          padding: 0,
          width: "32px !important",
        }}
      >
        <Checkbox sx={{ color: (theme) => theme.palette.primary[800] }} />
      </TableCell> */}
        <TableCell
          sx={[tableCellStyle, { width: "120px", borderLeft: "none" }]}
        >
          {defaultSize?.unitOfMeasurement === null &&
          defaultSize?.type !== productSizeTypes.Arbitrary ? (
            <Button
              onClick={handleProductUnitSizeClick}
              disabled={sizeDisabled}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <AddOutlinedIcon
                sx={{
                  fontSize: "14px",
                  color: (theme) => theme.palette.primary[900],
                  marginBottom: 1,
                  marginRight: 1,
                }}
              />
              <Typography
                variant="smallButton"
                sx={{ color: (theme) => theme.palette.primary[900] }}
              >
                Unit Size
              </Typography>
            </Button>
          ) : (
            <Box
              onClick={handleProductUnitSizeClick}
              sx={{
                cursor: "pointer",
                height: "20px",
                backgroundColor: (theme) => theme.palette.info[600],
                borderRadius: 1,
                padding: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",
                marginLeft: 2,
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  color: (theme) => theme.palette.pure.white,
                  fontWeight: 700,
                  fontSize: { xs: 6, md: 10 },
                }}
              >
                {!defaultSize?.isKegSize && defaultSize?.size}{" "}
                {defaultAbbreviation}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "118px" }]}>
          <TextField
            size="small"
            variant="filled"
            fullWidth
            id="packagingType"
            autoComplete="off"
            disabled={packagingTypeDisabled}
            placeholder="Packaging Type"
            value={formatCopy.packagingType || ""}
            onBlur={handleBlurUpdate}
            onKeyDown={utilFunctions.blurInput}
            InputProps={{ disableUnderline: true }}
            sx={{
              "& .MuiFilledInput-root": {
                background: (theme) => theme.palette.pure.white,
                paddingTop: 0,
                paddingLeft: 0,
                paddingBottom: 4,
              },
            }}
            onChange={handleCopyUpdateFromEvent}
          />{" "}
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "140px" }]}>
          <TextField
            size="small"
            variant="filled"
            id="upc"
            type="number"
            autoComplete="off"
            fullWidth
            placeholder="UPC"
            disabled={upcDisabled}
            onKeyDown={utilFunctions.blurInput}
            value={formatCopy.upc || ""}
            InputProps={{
              disableUnderline: true,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            sx={{
              "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& .MuiFilledInput-root": {
                background: (theme) => theme.palette.pure.white,
                paddingTop: 0,
                paddingLeft: 0,
                paddingBottom: 4,
                "&:hover": {
                  background: "rgba(0, 0, 0, 0.09)",
                },
              },
            }}
            onBlur={handleBlurUpdate}
            onChange={handleCopyUpdateFromEvent}
          />{" "}
        </TableCell>

        <TableCell sx={[tableCellStyle, { width: "14%" }]}>
          <Box sx={{ paddingLeft: 1 }}>
            <ImageControl
              tableView
              imgDisabled={imgDisabled}
              isImageControlOpen={isImageControlOpen}
              toggleIsImageControlOpen={toggleIsImageControlOpen}
              size="small"
              imageControlCallback={handleImageUpdate}
              imageSrc={format?.imageUrl}
              isLoading={imgDisabled}
            />
          </Box>
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "60px" }]}>
          <TextField
            size="small"
            variant="filled"
            fullWidth
            autoComplete="off"
            id="low"
            placeholder="88"
            onBlur={handleBlurUpdate}
            onKeyDown={utilFunctions.blurInput}
            disabled={parDisabled}
            value={formatCopy.par?.low || ""}
            InputProps={{ disableUnderline: true, inputMode: "numeric" }}
            sx={{
              "& .MuiFilledInput-root": {
                background: (theme) => theme.palette.error[100],
                paddingTop: 0,
                paddingLeft: 0,
                paddingBottom: 4,
                borderRadius: 0,
                "&:hover": {
                  background: "rgba(0, 0, 0, 0.09)",
                },
              },
            }}
            onChange={handleCopyUpdateFromEvent}
          />{" "}
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "60px" }]}>
          <TextField
            size="small"
            variant="filled"
            fullWidth
            autoComplete="off"
            id="high"
            onKeyDown={utilFunctions.blurInput}
            placeholder="88"
            onBlur={handleBlurUpdate}
            disabled={parDisabled}
            value={formatCopy.par?.high || ""}
            InputProps={{ disableUnderline: true, inputMode: "numeric" }}
            sx={{
              "& .MuiFilledInput-root": {
                background: (theme) => theme.palette.info[100],
                paddingTop: 0,
                paddingLeft: 0,
                paddingBottom: 4,
                borderRadius: 0,
                "&:hover": {
                  background: "rgba(0, 0, 0, 0.09)",
                },
              },
            }}
            onChange={handleCopyUpdateFromEvent}
          />{" "}
        </TableCell>
        <TableCell
          sx={[
            tableCellStyle,
            {
              padding: 4,
              borderRight: "none",
              margin: "auto",
              height: "100%",
              width: "124px",
            },
          ]}
        >
          <Select
            id="productFormatStatus"
            label="Status"
            value={formatCopy.productFormatStatus || 1}
            onChange={handleStatusUpdate}
            disabled={productFormatStatusDisabled}
            sx={
              formatCopy.productFormatStatus
                ? productStatusStyle(formatCopy.productFormatStatus)
                : productStatusDefaultStyle
            }
          >
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={2}>Inactive</MenuItem>
          </Select>
        </TableCell>
        <TableCell
          sx={[tableCellStyle, { width: "40px", borderRight: "none" }]}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              sx={{ width: "32px", height: "32px" }}
              onClick={handleClick}
            >
              <MoreHorizOutlined
                sx={{
                  color: (theme) => theme.palette.primary[800],
                  fontSize: 20,
                }}
              />
            </IconButton>
          </Box>
        </TableCell>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            sx={{ margin: "5px" }}
            disabled={format.isDefault}
            onClick={toggleDeleteProductFormat}
          >
            <DeleteOutlineOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
            <Typography variant="body1">Delete</Typography>
          </MenuItem>
        </Menu>
      </TableRow>
      <DeleteProductFormatModal
        deleteCallback={handleDelete}
        isOpen={deleteProductFormatModal}
        toggleModalOpen={toggleDeleteProductFormat}
      />
    </>
  );
};

export default ProductFormatSizingRow;
