import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useEstablishmentUoMs(establishmentId) {
  const queryReturnValues = useQuery(
    ["uoms", { establishmentId }],
    inventoryClient.getAllUnitOfMeasurementsReactQuery,
    { refetchOnWindowFocus: false, staleTime: 1000 * 60 * 10 }
  );

  return queryReturnValues;
}

export default useEstablishmentUoMs;
