import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";

const ImageByUrl = ({ imageSrc, newLinkURL, setNewLinkURL }) => {
  useEffect(() => {
    if (
      !imageSrc?.startsWith("https://ephemeral-public.s3") &&
      !imageSrc?.startsWith("https://spec-hosted.s3")
    ) {
      setNewLinkURL(imageSrc);
    }
  }, [imageSrc]);

  const handleSetImageUrl = (e) => {
    setNewLinkURL(e.target.value);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 700, fontSize: 16, textAlign: "left" }}
          >
            Enter Image URL
          </Typography>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">
            Copy and paste an independent image URL, not a full page with an
            image embedded within.
          </Typography>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            label="Enter URL"
            placeholder="Enter URL"
            autoComplete="off"
            value={newLinkURL}
            onChange={handleSetImageUrl}
            inputProps={{
              sx: {
                marginBottom: 1,
                height: "100%",
              },
            }}
            sx={{
              backgroundColor: (theme) => theme.palette.pure.white,
              borderColor: (theme) => theme.palette.terrain[400],
              borderRadius: 1,
              height: "100%",
            }}
          />
        </Box>
        <Box
          sx={{
            height: 250,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {newLinkURL ? (
            <img
              src={newLinkURL}
              alt="No Image Found"
              style={{ maxHeight: 250, maWidth: 300 }}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
};

ImageByUrl.propTypes = {};

export default ImageByUrl;
