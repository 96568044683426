import React from "react";
import { Box, Grid, CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import UnitedKingdom from "../../../assets/img/UnitedKingdom.png";
import UnitedStates from "../../../assets/img/UnitedStates.png";

const LocationDetails = ({ selectedCountry, setSelectedCountry }) => {
  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
  };

  const CardStyle = {
    width: { xs: 100, md: 175 },
    height: { xs: 170, md: 225 },
    margin: 4,
    boxShadow: "0px 8px 40px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
  };

  const CardMediaStyle = {
    width: "80%",
    borderRadius: "50%",
    margin: "auto",
    marginBottom: 0,
  };

  const CardTextStyle = {
    margin: "auto",
    align: "center",
    paddingTop: 0,
  };

  const rightAlignItem = {
    display: "flex",
    justifyContent: "flex-end",
    padding: "8px 8px 0 0",
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        width: { xs: "300px", md: "500px" },
        height: "250px",
        margin: "auto",
      }}
    >
      <Card sx={CardStyle}>
        <CardActionArea
          id="United States"
          onClick={() => {
            handleSelectCountry("us");
          }}
        >
          <Box sx={rightAlignItem}>
            {selectedCountry === "us" ? <CircleIcon /> : <CircleOutlinedIcon />}
          </Box>

          <CardMedia
            component="img"
            sx={CardMediaStyle}
            image={UnitedStates}
            alt="united states"
          />
          <CardContent sx={CardTextStyle}>
            <Box sx={{ padding: "auto", textAlign: "center" }}>
              <Typography variant="largeButton">United States</Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>

      <Card sx={CardStyle}>
        <CardActionArea
          id="United Kingdom"
          name="locale"
          onClick={() => handleSelectCountry("gb")}
        >
          <Box sx={rightAlignItem}>
            {selectedCountry === "gb" ? <CircleIcon /> : <CircleOutlinedIcon />}
          </Box>
          <CardMedia
            component="img"
            sx={CardMediaStyle}
            image={UnitedKingdom}
            alt="green iguana"
          />
          <CardContent sx={CardTextStyle}>
            <Box sx={{ padding: "auto", textAlign: "center" }}>
              <Typography variant="largeButton">United Kingdom</Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default LocationDetails;
