import { Box, TextField } from "@mui/material";
// import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React from "react";

const GroupCard = ({ name, id, handleUpdate }) => {
  const handleNameChange = (e) => {
    handleUpdate(id, e.target.value);
  };
  return (
    <Box
      sx={{
        height: "57px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: (theme) => theme.palette.terrain[50],
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[300],
        padding: 2,
      }}
    >
      {/* <IconButton sx={{ marginRight: 2 }}>
        <DragHandleOutlinedIcon
          sx={{ color: (theme) => theme.palette.primary[800] }}
        />
      </IconButton> */}
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={name}
        autoComplete="off"
        onChange={handleNameChange}
        inputProps={{ background: (theme) => theme.palette.pure.white }}
        sx={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
          "& .MuiOutlinedInput-root": {
            background: (theme) => theme.palette.pure.white,
          },
        }}
      />
      {/* <IconButton
        sx={{
          width: "36px",
          height: "36px",
          border: "2px solid",
          borderColor: (theme) => theme.palette.error[500],
          marginLeft: 2,
        }}
      >
        <DeleteOutlineOutlinedIcon
          sx={{ color: (theme) => theme.palette.error[500] }}
        />
      </IconButton> */}
    </Box>
  );
};

export default GroupCard;
