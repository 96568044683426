const successStyle = {
  "@keyframes animate": {
    "0%": {
      borderTopColor: "transparent",
      borderRightColor: "transparent",
      borderBottomColor: "transparent",
      borderLeftColor: "transparent",
    },
    "25%": {
      borderTopColor: (theme) => theme.palette.success[500],
      borderRightColor: "transparent",
      borderBottomColor: "transparent",
      borderLeftColor: "transparent",
    },
    "50%": {
      borderTopColor: (theme) => theme.palette.success[500],
      borderRightColor: (theme) => theme.palette.success[500],
      borderBottomColor: "transparent",
      borderLeftColor: "transparent",
    },
    "75%": {
      borderTopColor: (theme) => theme.palette.success[500],
      borderRightColor: (theme) => theme.palette.success[500],
      borderBottomColor: (theme) => theme.palette.success[500],
      borderLeftColor: "transparent",
    },
    "100%": {
      border: (theme) => `2px solid ${theme.palette.success[500]}`,
    },
  },
  "@keyframes background-fade": {
    "0%": {
      backgroundColor: (theme) => theme.palette.terrain[50],
    },
    "100%": {
      backgroundColor: (theme) => theme.palette.success[50],
    },
  },
  background: "transparent",
  border: "2px solid transparent",
  display: "flex",
  alignItems: "center",
  animation: "animate 0.25s linear forwards, background-fade 2s ease-in-out",
  height: "40px",
  minHeight: "40px",
  width: "120px",
  borderColor: (theme) => theme.palette.terrain[500],
  backgroundColor: (theme) => theme.palette.success[50],
  borderRadius: 1,
  padding: 1,
};

export { successStyle };
