import { Box, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../../../TabPanel";
import OrderLineItemOption from "./OrderLineItemOption";
import TabularOrderItemNav from "./TabularOrderItemNav";
var moment = require("moment");

const TabularOrderItemList = ({ selectedOrders, unlinkedOrderItemsMap, setItemToLink, itemToLink, skipLineItemMapping, toggleSkipLineItemMapping }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setItemToLink(undefined);
    setCurrentTab(newValue);
  };

  const handleSelectItem = (item) => {
    if (itemToLink?.id === item.id) {
        setItemToLink(undefined);
    } else {
        setItemToLink(item);
    }
  }

  return (
    <>
      <TabularOrderItemNav currentTab={currentTab} handleChange={handleTabChange} selectedOrders={selectedOrders} />
      <Box
        sx={{
          height: "48px",
          width: "100%",
          borderRadius: 1,
          backgroundColor: (theme) => theme.palette.terrain[100],
          padding: 2,
        }}
      >
        <Box>
          <Typography variant="body1">Line Items from Order:</Typography>
        </Box>
        <Box>
          <Typography variant="body2">{selectedOrders[currentTab] !== undefined ? moment(selectedOrders[currentTab].createdDate).format("MMMM DD, YYYY") : ""}</Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: 2, height: "260px", overflow: "scroll" }}>
        {selectedOrders.map((x, i) => (
          <TabPanel key={i} value={currentTab} index={i}>
            {unlinkedOrderItemsMap[x.id]?.length === 0 ? <Typography variant="body2">All items from this order have been linked</Typography> : unlinkedOrderItemsMap[x.id]?.map((l) => (
              <OrderLineItemOption key={l.id} lineItem={l} order={x} selectedItem={itemToLink} selectItemCallback={handleSelectItem} />
            ))}
          </TabPanel>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "100%",
          height: "40px",
          backgroundColor: (theme) => theme.palette.terrain[100],
          borderRadius: 1,
          marginTop: 2,
          padding: 1,
        }}
      >
        <Switch checked={skipLineItemMapping} onChange={toggleSkipLineItemMapping} inputProps={{ 'aria-label': 'controlled' }} />
        <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
          Do not auto fill line item with order details
        </Typography>
      </Box>
    </>
  );
};

export default TabularOrderItemList;
