import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

const SentMessage = ({
  activeMessage,
  clearActiveMessage,
  messages,
  deleteModalCallback,
}) => {
  const displayMessage = messages.find((m) => activeMessage === m.id);

  const formattedDate = new Date(
    displayMessage?.creationDate
  ).toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const handleDeleteModal = () => {
    deleteModalCallback(activeMessage);
  };

  return (
    <Box
      sx={{
        minHeight: "100%",
        display: "flex",
        width: "100%",
      }}
    >
      <Grid item sx={{ direction: "column", width: "100%" }}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 6,
          }}
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => clearActiveMessage()}
              sx={{
                borderRadius: 2,
                padding: "4px 16px",
                height: "32px",
              }}
            >
              <CreateOutlinedIcon style={{ fontSize: "14px" }} />
              <Box>
                <Typography variant="smallButton" sx={{ marginLeft: 2 }}>
                  Compose Message
                </Typography>
              </Box>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleDeleteModal}
              sx={{
                borderRadius: 2,
                padding: "4px 16px",
                height: "32px",
              }}
            >
              <DeleteOutlineOutlinedIcon style={{ fontSize: "14px" }} />
              <Typography
                variant="smallButton"
                sx={{
                  marginLeft: "5px",
                  alignSelf: "center",
                  justifySelf: "center",
                }}
              >
                Delete
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Stack
          sx={{
            minHeight: "94%",
            display: "flex",
            position: "relative",
          }}
        >
          <Box component="div" sx={{ marginBottom: 2 }}>
            <Typography
              variant="overline"
              sx={{ color: (theme) => theme.palette.terrain["01"] }}
            >
              {formattedDate}
            </Typography>
          </Box>
          <Box component="div">
            <Typography variant="h4" sx={{ marginBottom: 6 }}>
              {displayMessage?.title}
            </Typography>
          </Box>

          <Box component="div" sx={{ minHeight: 450, overflow: "auto" }}>
            <Typography variant="subtitle1" sx={{ lineHeight: "21px" }}>
              {displayMessage?.body}
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Box>
  );
};

export default SentMessage;
