import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SpecBackButton from "../SpecBackButton";
import Divider from "@mui/material/Divider";
import GridContainer from "../GridContainer";
import { useForm } from "react-hook-form";
import { Switch } from "@mui/material";
import ImageControl from "../ImageControl";

const BeerDrinkForm = ({
  startingBeer,
  submitCallback,
  goBack,
  isEmbedded,
  categoryId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: startingBeer || {},
  });
  const [beerActive, toggleBeerActive] = useState(true);
  const [beerImageURL, setBeerImageURL] = useState("");
  const [isImageControlOpen, toggleIsImageControlOpen] = useState(false);

  const handleActiveToggle = () => {
    toggleBeerActive(!beerActive);
  };

  useEffect(() => {
    toggleBeerActive(startingBeer ? startingBeer.active : true);
    setBeerImageURL(startingBeer ? startingBeer.imageURL : "");
  }, [startingBeer]);

  const handleSave = (beerData) => {
    beerData.active = beerActive;
    beerData.categoryId = categoryId;
    beerData.abv = beerData.abv === "" ? 0 : beerData.abv;
    beerData.imageURL = beerImageURL;
    submitCallback(beerData);
  };

  const imageControlCallback = async (photoLocation) => {
    setBeerImageURL(photoLocation);
    toggleIsImageControlOpen(false);
  };

  return (
    <form noValidate onSubmit={handleSubmit(handleSave)}>
      <GridContainer direction="column">
        {!isEmbedded && (
          <Box sx={{ paddingLeft: "24px" }}>
            <SpecBackButton onClick={() => goBack()} />
          </Box>
        )}
        <Box>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h3">Beer Information</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 4,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid container direction="column">
                    <Box style={{ height: 10, marginTop: "0px" }}>{""}</Box>
                    <TextField
                      size="small"
                      fullWidth
                      id="name"
                      label="Name of Beer"
                      InputLabelProps={{ shrink: true, required: true }}
                      {...register("name", {
                        required: "Please enter beer name",
                      })}
                    />
                    {errors.name ? (
                      <Box
                        sx={{
                          color: "red",
                          marginTop: "0px",
                          paddingTop: "4px",
                          height: 10,
                        }}
                      >
                        {errors.name.message}
                      </Box>
                    ) : (
                      <Box style={{ height: 10, marginTop: "0px" }}>{""}</Box>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Switch
                    color="secondary"
                    checked={beerActive}
                    onClick={handleActiveToggle}
                  />
                  <Typography variant="subtitle2">
                    {beerActive ? "Status: Active" : "Status: Inactive"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "flex-start", sm: "flex-end" },
                  }}
                >
                  <ImageControl
                    imageSrc={beerImageURL}
                    imageControlCallback={imageControlCallback}
                    isImageControlOpen={isImageControlOpen}
                    toggleIsImageControlOpen={toggleIsImageControlOpen}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </GridContainer>
      <Divider />
      <GridContainer direction="column">
        <Box sx={{ padding: 2, marginTop: 4, marginBottom: 4 }}>
          <Grid container direction="column" spacing={4}>
            <Grid item sx={{ marginBottom: 4 }}>
              <Typography variant="h3">Product Description</Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item>
                      <Grid container direction="column">
                        <TextField
                          size="small"
                          fullWidth
                          id="producer"
                          label="Producer"
                          placeholder="Who produced this beer?"
                          InputLabelProps={{ shrink: true, required: true }}
                          {...register("producer", {
                            required: "Please enter producer",
                          })}
                        />
                        {errors.producer ? (
                          <Box
                            sx={{
                              color: "red",
                              marginTop: "0px",
                              paddingTop: "4px",
                              height: "16px",
                            }}
                          >
                            {errors.producer.message}
                          </Box>
                        ) : (
                          <Box sx={{ height: "16px", marginTop: "0px" }}>
                            {""}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <TextField
                          size="small"
                          fullWidth
                          id="style"
                          label="Style"
                          placeholder="e.g. Lager or IPA"
                          InputLabelProps={{ shrink: true, required: true }}
                          {...register("style", {
                            required: "Please enter style information",
                          })}
                        />
                        {errors.style ? (
                          <Box
                            sx={{
                              color: "red",
                              marginTop: "0px",
                              paddingTop: "4px",
                              height: "16px",
                            }}
                          >
                            {errors.style.message}
                          </Box>
                        ) : (
                          <Box sx={{ minHeight: "16px", marginTop: "0px" }}>
                            {""}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TextField
                        size="small"
                        fullWidth
                        type="number"
                        id="abv"
                        label="ABV"
                        placeholder="Enter Aclohol %"
                        step="any"
                        InputLabelProps={{ shrink: true }}
                        {...register("abv", {
                          required: false,
                          validate: () => true,
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    id="tastingNotes"
                    label="Tasting Notes"
                    placeholder="Enter distinct flavors.."
                    multiline
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    rows={8}
                    {...register("tastingNotes", { required: false })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </GridContainer>
      <Divider />
      <GridContainer>
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: "12px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              sx={{
                width: "100%",
                borderRadius: 2,
              }}
            >
              <Typography variant="smallButton">Save</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={goBack}
              sx={{
                width: "100%",
                borderRadius: 2,
              }}
            >
              <Typography variant="smallButton">Cancel</Typography>
            </Button>
          </Grid>
        </Grid>
      </GridContainer>
    </form>
  );
};

export default BeerDrinkForm;
