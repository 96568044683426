import { authConfig } from "../clients/authClient";
import { identityConfig } from "../clients/identityClient";
import { establishmentsClientConfig } from "../clients/establishmentsClient";
import { inventoryConfig } from "../clients/inventoryClient";

export default async function loadConfig(
    identityBaseUrl,
    authBaseUrl,
    establishmentsBaseUrl,
    inventoryBaseUrl
) {
    authConfig.baseURL = authBaseUrl;
    identityConfig.baseURL = identityBaseUrl;
    establishmentsClientConfig.baseURL = establishmentsBaseUrl;
    inventoryConfig.baseURL = inventoryBaseUrl;
}