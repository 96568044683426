import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import inventoryObjects from "../../clients/inventoryObjects";

const OptionCard = ({
  handleClick,
  displayText,
  MainIcon,
  mainIconSx,
  areaCompleteSx,
  // optionCardCompleteSx,
  descriptionCompleteSx,
  status,
}) => {

  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: "pointer",
      }}
    >
      <Box
        sx={[
          {
            zIndex: 0,
            display: "flex",
            width: "100%",
            height: { xs: "76px", sm: "76px", md: "80px" },
            flexDirection: "column",
            backgroundColor: (theme) => theme.palette.pure.white,
            borderLeft: "3px solid",
            borderRight: "3px solid",
            borderTop: "3px solid",
            borderBottom: "none",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
          areaCompleteSx,
        ]}
      >
        <Grid
          container
          direction="row"
          sx={[
            {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: 4,
            },
            areaCompleteSx,
          ]}
        >
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth: "100%",
            }}
          >
            <Box
              sx={[
                {
                  width: { xs: "32px", sm: "48px" },
                  height: { xs: "32px", sm: "48px" },
                  minWidth: { xs: "32px", sm: "48px" },
                  minHeight: { xs: "32px", sm: "48px" },
                  borderRadius: "100%",
                  border: "2px solid",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                areaCompleteSx,
              ]}
            >
              <MainIcon sx={mainIconSx} />
            </Box>
            <Typography
              variant="h5"
              sx={{
                marginLeft: { xs: 1, sm: 2 },
                whiteSpace: { xs: "nowrap", md: "normal" },
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {displayText}
            </Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Box>
      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            padding: 2,
            margin: 0,
            height: "35px",
            width: "100%",
            border: "3px solid",
            backgroundColor: (theme) => theme.palette.pure.white,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            zIndex: 1,
          },
          descriptionCompleteSx,
        ]}
      >
        <Typography variant="body1" sx={{ fontSize: { xs: 10, md: 12 } }}>
          {status === inventoryObjects.StockCountStatuses.Complete
            ? "Complete"
            : "In Progress"}
        </Typography>
      </Box>
    </Box>
  );
};

export default OptionCard;
