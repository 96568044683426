import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useEstablishmentRateLimits(establishmentId, enabled) {
    const queryReturnValues = useQuery(
        ["rate-limits", { establishmentId }],
        inventoryClient.getRateLimitOverview,
        {
            staleTime: 1000 * 60 * 60,
            refetchOnWindowFocus: false,
            enabled
        }
    );

   return queryReturnValues;
}

export default useEstablishmentRateLimits;