import React from 'react';
import LockIcon from '@mui/icons-material/Lock';

const SubscriptionCellLock = () => {
  const containerStyle = {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative', // Needed for absolute positioning of children
};

const blurBackgroundStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Translucent background
    borderRadius: '50%', // Circular shape
    width: '2em', // Adjust size as needed
    height: '2em', // Adjust size as needed
    filter: 'blur(8px)', // Adjust the blur effect as needed
};

const lockIconStyle = {
    position: 'relative', // Ensures it's above the blurred background
    zIndex: 2, // Higher than the blurred background
    opacity: 0.9
};

return (
    <div style={containerStyle}>
        <div style={blurBackgroundStyle} />
        <LockIcon style={lockIconStyle} />
    </div>
);
};

export default SubscriptionCellLock;
