import React, { useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Radio,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { modalBoxStyle } from "../styles";
import LogoStampWhite from "../../../assets/img/LogoStampWhite.png";
import DescriptionToolTip from "../../DescriptionToolTip";

const selectedStyle = {
  cursor: "pointer",
  width: "100%",
  height: "44px",
  borderRadius: 2,
  border: "2px solid",
  borderColor: (theme) => theme.palette.primary[800],
  backgroundColor: (theme) => theme.palette.primary[50],
  marginBottom: 2,
};

const defaultStyle = {
  cursor: "pointer",
  width: "100%",
  height: "44px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[300],
  borderRadius: 2,
  marginBottom: 2,
};

const UpgradeAccountModal = ({
  handleModalToggle,
  isOpen,
  upgradeCallback,
  isLoadingSession,
  isOwner,
}) => {
  // const [currency, setCurrency] = useState("dollar");
  const [billing, setBilling] = useState("annual");

  const CenteredContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const handleUpgradeClick = () => {
    upgradeCallback(billing);
  };

  const SpecProDetails = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          backgroundColor: (theme) => theme.palette.info[50],
          border: "1px solid",
          borderColor: (theme) => theme.palette.info[500],
          margin: 4,
          borderRadius: 2,
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <DescriptionToolTip />
            <Typography variant="h5" sx={{ marginLeft: 1 }}>
              What does Spec Pro have to offer?
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">
            Spec Pro includes features that can help streamline your
            establishment operations, saving you time and money!
          </Typography>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">Included Features:</Typography>
          <ul>
            <li>Product Formats & Purchase Units in Product Library</li>
            <li>Dynamic costing calculations on Menus, Recipes, and Items</li>
            <li>Library Organization</li>
            <li>Vendor detail storage</li>
            <li>Stock Counts - Full and Partial Inventory Audits</li>
            <li>Create and Manage Orders</li>
            <li>Invoicing for received Orders</li>
            <li>Cost Analyisis - Profit Margin, Pour Cost, Menu Pricing</li>
          </ul>
        </Box>
      </Box>
    );
  };

  // const CurrencyDetails = () => {
  //   return (
  //     <Box sx={{ marginTop: 4, padding: 4 }}>
  //       <Box sx={{ marginBottom: 2 }}>
  //         <Typography variant="h3">Currency Selection</Typography>
  //         <Typography variant="body1">
  //           Select the currency in which you would like to be billed and
  //           invoiced{" "}
  //         </Typography>
  //       </Box>

  //       <Box
  //         sx={currency === "dollar" ? selectedStyle : defaultStyle}
  //         onClick={() => setCurrency("dollar")}
  //       >
  //         <Grid
  //           item
  //           sx={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Radio checked={currency === "dollar"} />
  //           <Typography
  //             variant="subtitle1"
  //             sx={{
  //               fontWeight: 700,
  //               fontSize: { xs: 12, sm: 12, md: 16 },
  //               color:
  //                 currency === "dollar"
  //                   ? (theme) => theme.palette.primary[800]
  //                   : (theme) => theme.palette.pure.black,
  //             }}
  //           >
  //             US Dollar ($)
  //           </Typography>
  //         </Grid>
  //       </Box>
  //       <Box
  //         sx={currency === "sterling" ? selectedStyle : defaultStyle}
  //         onClick={() => setCurrency("sterling")}
  //       >
  //         <Grid
  //           item
  //           sx={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Radio checked={currency === "sterling"} />
  //           <Typography
  //             variant="subtitle1"
  //             sx={{
  //               fontWeight: 700,
  //               fontSize: { xs: 12, sm: 12, md: 16 },
  //               color:
  //                 currency === "sterling"
  //                   ? (theme) => theme.palette.primary[800]
  //                   : (theme) => theme.palette.pure.black,
  //             }}
  //           >
  //             Pound Sterling (£)
  //           </Typography>
  //         </Grid>
  //       </Box>
  //     </Box>
  //   );
  // };

  const BillingPreferences = () => {
    return (
      <Box sx={{ marginTop: 4, padding: 4 }}>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="body1">
            Select your billing preferences:{" "}
          </Typography>
        </Box>
        <Box
          sx={billing === "monthly" ? selectedStyle : defaultStyle}
          onClick={() => setBilling("monthly")}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Radio checked={billing === "monthly"} />
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: 12, sm: 12, md: 16 },
                  color:
                    billing === "monthly"
                      ? (theme) => theme.palette.primary[800]
                      : (theme) => theme.palette.pure.black,
                }}
              >
                Monthly
              </Typography>
            </Grid>
            <Grid item sx={{ marginRight: 2 }}>
              <Typography variant="body2">12 x $49.00</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={billing === "annual" ? selectedStyle : defaultStyle}
          onClick={() => setBilling("annual")}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Radio checked={billing === "annual"} />
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: 12, sm: 12, md: 16 },
                  color:
                    billing === "annual"
                      ? (theme) => theme.palette.primary[800]
                      : (theme) => theme.palette.pure.black,
                }}
              >
                Annual
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                marginRight: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 2,
                  backgroundColor: "#B640FF",
                  width: "62px",
                  height: "20px",
                  marginRight: 2,
                }}
              >
                <Typography variant="body2">10% Off</Typography>
              </Box>
              <Typography variant="body2">1 x $529.00</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const renderFooter = () => {
    return (
      <Box sx={{ display: "flex", marginTop: "1rem", flexDirection: 'column' }}>
        {!isOwner ? (
          <Alert icon={false} severity="warning" variant="outlined">Only the Establishment Owner can manage billing</Alert>
        ) : null}
        <Button
          variant="contained"
          disabled={isLoadingSession || !isOwner}
          onClick={handleUpgradeClick}
          sx={{
            border: "none",
            borderRadius: 2,
            marginTop: "20px",
            padding: "8px 60px",
          }}
        >
          <Typography variant="smallButton">
            {isLoadingSession ? (
              <CircularProgress color="inherit" />
            ) : (
              "Proceed to Payment"
            )}
          </Typography>
        </Button>
      </Box>
    );
  };

  // const RenderCreditCard = () => {
  //   return (
  //     <Box sx={{ marginTop: 6, padding: 4 }}>
  //       <Box>
  //         <Typography variant="h3">Credit Card Details</Typography>
  //         <Typography variant="body1" sx={{ marginTop: 2 }}>
  //           Enter your card details for payments and billing
  //         </Typography>
  //       </Box>
  //       <Box sx={{ marginTop: 4 }}>
  //         <Box
  //           sx={{
  //             display: "flex",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //             marginTop: 4,
  //           }}
  //         >
  //           <TextField
  //             size="small"
  //             label="Cardholder Name"
  //             required
  //             sx={{ width: "48%" }}
  //           />
  //           <TextField
  //             size="small"
  //             label="Billing Zip Code"
  //             required
  //             sx={{ width: "48%" }}
  //           />
  //         </Box>
  //         <Box sx={{ marginTop: 4 }}>
  //           <TextField fullWidth size="small" label="Card Number" required />
  //         </Box>
  //         <Box
  //           sx={{
  //             display: "flex",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //             marginTop: 4,
  //           }}
  //         >
  //           <TextField
  //             size="small"
  //             label="Exp Date"
  //             required
  //             sx={{ width: "48%" }}
  //           />
  //           <TextField
  //             size="small"
  //             label="CVV"
  //             required
  //             sx={{ width: "48%" }}
  //           />
  //         </Box>
  //       </Box>
  //     </Box>
  //   );
  // };

  return (
    <Modal
      open={isOpen}
      onClose={handleModalToggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[modalBoxStyle, { padding: 0 }]}>
        <Box sx={{ height: "500px", overflow: "scroll" }}>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "88px",
              background: (theme) => theme.palette.gradient.primary,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              padding: 4,
            }}
          >
            <Grid
              container
              sx={{ display: "flex", flexDirection: "column", width: "80%" }}
            >
              <Grid item>
                <Typography variant="smallButton">Upgrade to</Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="Spec Logo"
                  src={LogoStampWhite}
                  sx={{
                    height: "28px",
                    width: "28px",
                    marginRight: 2,
                  }}
                />
                <Typography variant="h2">Spec Pro</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              sx={{
                width: "40px",
                padding: "0px",
                margin: "0px",
                marginBottom: "1rem",
              }}
            >
              <IconButton
                onClick={handleModalToggle}
                sx={{ marginBottom: "1rem", margin: "0px", padding: "0px" }}
              >
                <CloseIcon
                  sx={{
                    color: (theme) => theme.palette.terrain["01"],
                    padding: "0px",
                    margin: "0px",
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
          {SpecProDetails()}

          {/* ESTABLISHMENT DETAILS FORM */}
          {/* {RenderCreditCard()}

            {CurrencyDetails()} */}

          {BillingPreferences()}
        </Box>
        {/* MODAL FOOTER */}
        <Divider />
        <Box sx={[CenteredContainerStyles, { padding: 4, paddingTop: 0 }]}>
          {renderFooter()}
        </Box>
      </Box>
    </Modal>
  );
};

export default UpgradeAccountModal;
