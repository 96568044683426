import axios from "axios";
import { v4 as guidGenerator } from "uuid";

const s3Client = {
  uploadImage: async (photo) => {
    if (!photo) {
      return "";
    }

    const fileID = guidGenerator();

    const fileKey =
      encodeURIComponent("tempimgs") + "/" + fileID;

    var upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: "ephemeral-public",
        Key: fileKey,
        Body: photo,
        ACL: "public-read",
        ContentType: photo.type,
      },
    });

    var promise = upload.promise();

    return await promise.then(
      (data) => {
        /*
        response looks like:
        Bucket: "ephemeral-public"
        ETag: "\"a673489bbd4b2dc899661d59797df269\""
        Key: "tempimgs/You-Got-Tickets-To-Dave-Chappelle-&-Joe-Rogan.pdf-99b4218f-b82a-402e-acbe-162965623e87"
        Location: "https://ephemeral-public.s3.us-east-2.amazonaws.com/tempimgs/blah-blah-%26-blah"
        key: "tempimgs/You-Got-Tickets-To-Dave-Chappelle-&-Joe-Rogan.pdf-99b4218f-b82a-402e-acbe-162965623e87"
        */
        return data.Location;
      },
      (err) => {
        console.log(err);
      }
    );
  },
  uploadFileWithFileNamePreserved: async (file) => {
    if (!file) {
      return "";
    }

    const fileKey =
      encodeURIComponent("tempimgs") + "/" + `${file?.name.replace(/ /g, '-')}-${guidGenerator()}`;

    var upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: "ephemeral-public",
        Key: fileKey,
        Body: file,
        ACL: "public-read",
        ContentType: file.type,
      },
    });

    var promise = upload.promise();

    return await promise.then(
      (data) => {
        /*
        response looks like:
        Bucket: "ephemeral-public"
        ETag: "\"a673489bbd4b2dc899661d59797df269\""
        Key: "tempimgs/You-Got-Tickets-To-Dave-Chappelle-&-Joe-Rogan.pdf-99b4218f-b82a-402e-acbe-162965623e87"
        Location: "https://ephemeral-public.s3.us-east-2.amazonaws.com/tempimgs/blah-blah-%26-blah"
        key: "tempimgs/You-Got-Tickets-To-Dave-Chappelle-&-Joe-Rogan.pdf-99b4218f-b82a-402e-acbe-162965623e87"
        */
        return data.Location;
      },
      (err) => {
        console.log(err);
      }
    );
  },
  makeHeadCall: async (url) => {
    try {
      return await axios.head(url);
    } catch (err) {
      console.log(err);
      return { hasError: true, err };
    }
  }
};

export default s3Client;
