import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useMenusPaginated(establishmentId, page, filters) {
    const queryReturnValues = useQuery(
        ["menus-paginated", { establishmentId, page, filters }],
        inventoryClient.getMenusPaginated,
        {
            staleTime: 1000 * 60 * 10,
            keepPreviousData: true,
        }
    );

    return queryReturnValues;
}

export default useMenusPaginated;