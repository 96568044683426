import React, { useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const CreateSubcategoryModal = ({
  isOpen,
  toggleModalOpen,
  modalText,
  handleAddSubcategory,
  categoryId
}) => {
  const [name, setName] = useState();
  const [loading, toggleLoading] = useState();

  const closeModal = () => {
    setName(null);
    toggleModalOpen(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  }

  const handleSubmit = async () => {
      toggleLoading(true);
      await handleAddSubcategory(name, categoryId);
      toggleLoading(false);
      closeModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="h2">Create Subcategory</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={closeModal}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Grid>
                <Grid item sx={{ marginTop: 4 }}>
                  <Typography variant="body1">{modalText}</Typography>
                </Grid>
              </Grid>
              <Box sx={{ marginTop: 8, width: "100%" }}>
                <TextField
                  size="small"
                  fullWidth
                  label="Name"
                  autoComplete="off"
                  value={name}
                  onChange={handleNameChange}
                  placeholder={"Enter Subcategory Name"}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  disabled={!name}
                  onClick={handleSubmit}
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="largeButton">Create Subcategory</Typography>
                </Button>
              </Box>
            </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default CreateSubcategoryModal;
