import { Box, Button, Typography } from '@mui/material';
import React from 'react';

const EditVendorModalFooter = ({reactFormSubmit, submissionCallback}) => {
    return (
      <Box
        component={"div"}
        sx={{
          display: "flex",
          marginTop: "1rem",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={reactFormSubmit(submissionCallback)}
          sx={{
            borderRadius: 2,
          }}
        >
          <Typography variant="smallButton">Confirm</Typography>
        </Button>
      </Box>
    );
  };

  export default EditVendorModalFooter;