import React, { useContext, useState } from "react";
import { Alert, Box, Button, Grid, Snackbar, Typography } from "@mui/material";

import Store from "../../../Store/Store";
import CTABlock from "../../../components/CTABlock";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SpecSpinner from "../../../components/SpecSpinner";
import { useNavigate } from "react-router-dom";
import SpecBackButton from "../../../components/SpecBackButton";
import ProductFamilyCard from "./ProductFamilyCard";
import useEstablishmentProductFamilyGroups from "../../../hooks/useEstablishmentProductFamilyGroups";
import CreateProductFamilyModal from "../../../components/Modals/CreateProductFamilyModal";

const Families = () => {
  const [addingProductFamily, toggleAddingProductFamily] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const store = useContext(Store);
  const {
    data: productFamilyData,
    isLoading: familiesLoading,
    isError: error,
  } = useEstablishmentProductFamilyGroups(store.currentEstablishment?.id);
  const navigate = useNavigate();

  if (familiesLoading) {
    return (
      <SpecSpinner open={familiesLoading} text={"Loading family data..."} />
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          maxWidth: "2440px",
          padding: 4,
          marginTop: 6,
        }}
      >
        {error && (
          <Typography variant="h6" sx={{ color: "red" }}>
            There was an error fetching product family data, please refresh
          </Typography>
        )}
        <>
          <Grid item sx={{ marginLeft: -2 }}>
            <SpecBackButton
              onClick={() =>
                navigate(`/spec/${store.currentEstablishment?.id}/organization`)
              }
              backText="Organization"
            />
          </Grid>
          <Grid
            container
            sx={{
              maxWidth: "2440px",
              marginBottom: 6,
            }}
          >
            <Grid item xs={9}>
              <Typography variant="h2">Product Family Groups</Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 2,
                  padding: 4,
                  justifySelf: "flex-end",
                  height: "40px",
                }}
                onClick={() => toggleAddingProductFamily(true)}
              >
                <AddOutlinedIcon sx={{ marginRight: 1, fontSize: 20 }} />
                <Typography
                  variant="smallButton"
                  sx={{
                    fontSize: { xs: 10, md: 12 },
                    lineHeight: { xs: 1.5, md: 2 },
                  }}
                >
                  Create Family
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid container sx={{ marginBottom: 2, width: "100%" }}>
            <Grid item xs={12}>
              {productFamilyData &&
              productFamilyData?.productFamilies.length > 0 ? (
                productFamilyData?.productFamilies.map((p) => (
                  <ProductFamilyCard key={p.id} productFamilyGroup={p} />
                ))
              ) : (
                <CTABlock
                  header={"You haven't added any product family groups"}
                  subheader={
                    "Add product family groups to organize your inventory"
                  }
                  hideButton
                />
              )}
            </Grid>
          </Grid>
        </>
      </Grid>
      <CreateProductFamilyModal
        isOpen={addingProductFamily}
        toggleModalOpen={toggleAddingProductFamily}
      />
      <Box>
        <Snackbar
          open={alertSuccess ? true : false}
          onClose={() => setAlertSuccess(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {alertSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage ? true : false}
          onClose={() => setErrorMessage(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default Families;
