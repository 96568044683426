/* eslint-disable react/display-name */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import CTABlock from "../../../../components/CTABlock";
import ProductDetailRow from "./ProductDetailRow";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DescriptionToolTip from "../../../../components/DescriptionToolTip";

const tableHeadStyle = {
  paddingTop: 1,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const tableCellStyle = {
  padding: 0,
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
  fontWeight: 400,
};

const AllProductsTab = ({
  inventory,
  updateProductCallback,
  toggleAddingProduct,
  searching,
  deleteCallback,
  refetchCurrentPageProducts,
}) => {
  if (inventory.length > 0)
    return (
      <Box sx={{ width: "100%" }}>
        <TableContainer
          sx={{
            display: "flex",
            width: "100%",
            overflow: "scroll !important",
            borderRadius: 2,
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[400],
          }}
        >
          <Table
            sx={{ width: "100%", overflow: "scroll" }}
            aria-label="customized table"
          >
            <TableHead sx={{ height: "24px" }}>
              <TableRow sx={{ height: 24 }}>
                {/* CHECKBOX HEADER */}
                {/* <TableCell
                  sx={{
                    borderLeft: "none",
                    borderBottom: "1px solid",
                    borderColor: (theme) => theme.palette.terrain[400],
                    background: (theme) => theme.palette.terrain[300],
                    padding: 0,
                    width: "32px",
                  }}
                ></TableCell> */}
                <TableCell
                  sx={[tableHeadStyle, { width: "400px", borderLeft: "none" }]}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Product Name
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Product Name"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              Product Name is the official and commonly used
                              name of a product.
                            </Box>
                            <Box sx={{ marginTop: 1 }}>
                              <Typography variant="body2">
                                Best practices:
                              </Typography>
                            </Box>
                            <ul
                              style={{
                                listStylePosition: "outside",
                                marginTop: 1,
                              }}
                            >
                              <li style={{ marginTop: 1 }}>
                                Names should be specific: <q>Absolut Vodka</q>{" "}
                                and <q>Absolut Citron</q> are distinct and have
                                separate uses, so they should be divided into
                                separate products with unique names.
                              </li>
                              <li>
                                Names can contain the name of the producer, if
                                that is how the product is colloquially referred
                                to. <q>Makers Mark Bourbon</q> contains the name
                                of the Producer, wheras{" "}
                                <q>Elijah Craig Small Batch Bourbon</q> does not
                                include the name of the producer,{" "}
                                <q>Heaven Hill</q>.
                              </li>
                              <li>
                                Names should be free of reference to size, like{" "}
                                <q>750 mL</q>, as this is defined at the Product
                                Format level. Names should also be free of
                                reference to packaging type, like <q>bottle</q>,
                                as this is defined at the Product Format level.
                              </li>
                              <li>
                                If the product is a wine, include the vintage.
                              </li>
                            </ul>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "50px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    ABV
                    <Box component="div" sx={{ marginRight: -3 }}>
                      <DescriptionToolTip
                        header="ABV"
                        description={
                          <Box>
                            Stands for <q>alcohol by volume.</q> If the product
                            is non-alcoholic, enter 0. Remember that the ABV can
                            be calculated by dividing the proof by 2.
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Region
                    <Box component="div" sx={{ marginRight: -3 }}>
                      <DescriptionToolTip
                        header="Region"
                        description={
                          <Box>
                            The country that the product is produced in.
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={[tableHeadStyle, { borderRight: "none", width: "100px" }]}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Producer
                    <Box component="div" sx={{ marginRight: -3 }}>
                      <DescriptionToolTip
                        header="ABV"
                        description={
                          <Box>The official name of the producer.</Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={[tableHeadStyle, { width: "100px", lineHeight: 1 }]}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Raw Material
                    <Box component="div" sx={{ marginRight: -3 }}>
                      <DescriptionToolTip
                        header="Raw Materials"
                        description={
                          <Box>
                            The raw materials that the product was derived from.
                            For whiskeys, this field can store the mashbill; for
                            wine, the grape varietals, etc.
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Vintage
                    <Box component="div" sx={{ marginRight: -3 }}>
                      <DescriptionToolTip
                        header="Vintage"
                        description={
                          <Box>
                            The vintage date of the product. If the product has
                            none, enter <q>NV.</q>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Appellation
                    <Box component="div" sx={{ marginRight: -3 }}>
                      <DescriptionToolTip
                        header="Appellation"
                        description={
                          <Box>
                            The legally defined and protected geographical area,
                            most commonly used to identify where the grapes for
                            a wine are grown.
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                  Status
                </TableCell>
                <TableCell
                  sx={[tableHeadStyle, { width: "40px", borderRight: "none" }]}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventory.map((x) => (
                <ProductDetailRow
                  key={x.id}
                  product={x}
                  tableCellStyle={tableCellStyle}
                  updateProductCallback={updateProductCallback}
                  deleteCallback={deleteCallback}
                  refetchCurrentPageProducts={refetchCurrentPageProducts}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  return searching ? (
    <CTABlock
      header="No Results Found"
      hideButton
      ButtonImage={AddOutlinedIcon}
    />
  ) : (
    <CTABlock
      header="Your Product List is Empty..."
      subheader="You have not added ay products yet. Get started now..."
      buttonText="Add Products"
      handleCTA={toggleAddingProduct}
      ButtonImage={AddOutlinedIcon}
    />
  );
};

export default AllProductsTab;
