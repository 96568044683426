import React, { useContext, useState } from "react";
import { Card, CardContent, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { inventoryCountTypesMap } from "../../../clients/inventoryObjects";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Store from "../../../Store/Store";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
const moment = require("moment");

const ActiveCountCard = ({ count, viewCountDetails }) => {
  const handleViewCountDetails = () => {
    viewCountDetails(count.id);
  };
  const monthDayYear = moment(count.createDateTime).format("MM/DD/YY");
  const lastUpdated =
    moment(count.updateDateTime).format("MM/DD/YY") ==
    moment().format("MM/DD/YY")
      ? moment(count.updateDateTime).format("hh:mm a")
      : moment(count.updateDateTime).format("MM/DD/YY");
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const store = useContext(Store);
    
  const handleClose = () => {
        setAnchorEl(null);
      };
    
  const handleOptionClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
   const deleteStockCountMutation = useMutation(
     inventoryClient.deleteStockCount,
     {
       onSuccess: () => {
         queryClient.invalidateQueries({
           queryKey: [
             "active-stockcounts",
             {
               establishmentId: store?.currentEstablishment.id,
             },
           ],
         });
         queryClient.refetchQueries({
           queryKey: [
             "active-stockcounts",
             {
               establishmentId: store?.currentEstablishment.id,
             },
           ],
         });
       },
     }
   );

   const handleDeleteStockCount = async () => {
     try {
      console.log("HUH")
       const archivePayload = {
         establishmentId: store.currentEstablishment?.id,
         stockCountId: count.id,
       };

       deleteStockCountMutation.mutate(archivePayload);
     } catch (err) {
       console.log(err);
     }
   };
  return (
    <Card
      sx={{
        width: "330px !important",
        minWidth: "330px !important",
        height: "180px",
        borderRadius: "8px",
        elevation: 0,
        marginRight: 4,
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[300],
        "&:hover": {
          backgroundColor: (theme) => theme.palette.terrain[100],
        },
      }}
      elevation={0}
    >
      <CardContent>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
          }}
        >
          <Grid
            item
            sx={{ cursor: "pointer" }}
            onClick={handleViewCountDetails}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
              {count.name || "[No Name]"}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton sx={{ background: "none" }} onClick={handleOptionClick}>
              <MoreHorizOutlinedIcon
                sx={{
                  color: (theme) => theme.palette.primary[800],
                  "&:hover": {
                    color: (theme) => theme.palette.primary[300],
                  },
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ cursor: "pointer" }}
          onClick={handleViewCountDetails}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 3,
            }}
          >
            <Grid item>
              <Typography variant="body1">Find Products By:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">Location</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 3,
            }}
          >
            <Grid item>
              <Typography variant="body1">Type of Count</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {inventoryCountTypesMap[count.type]}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 3,
            }}
          >
            <Grid item>
              <Typography variant="body1">Date Started</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{monthDayYear}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 3,
            }}
          >
            <Grid item>
              <Typography variant="body1">Last Updated:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{lastUpdated}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem sx={{ margin: "5px" }} onClick={handleDeleteStockCount}>
          <DeleteOutlineOutlinedIcon
            sx={{ marginRight: "5px" }}
          />
          Delete
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default ActiveCountCard;
