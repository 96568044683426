import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { Box, Typography } from "@mui/material";
import utilFunctions from "../../../utilities/utilFunctions"
import CreateKegSizeModal from "../../../components/Modals/CreateKegSizeModal";

const KegSpecific = ({
  keg,
  defaultStyle
}) => {
  const [editKegSizeModalOpen, toggleEditKegSizeModalOpen] = useState(false);

  return (
    <ListItem
      key={keg.id}
      dense
      disableGutters
      sx={defaultStyle}
    >
      <ListItemButton onClick={() => toggleEditKegSizeModalOpen(true)}>
        <ListItemText
          primary={
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="body2">
                {utilFunctions.capitalizeString(keg.kegName)}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginLeft: 2,
                  color: (theme) => theme.palette.terrain[600],
                }}
              >
                {`(${keg.kegAbbreviation} - ${keg.kegUnitQuantity + " " + keg.name})`}
              </Typography>
            </Box>
          }
          sx={{ marginLeft: 4 }}
        />
      </ListItemButton>
      {editKegSizeModalOpen ? 
      <CreateKegSizeModal
      isOpen={editKegSizeModalOpen}
      toggleModalOpen={toggleEditKegSizeModalOpen}
      headerText="Edit Keg Size"
      buttonText="Save Edits"
      keg={keg}/>
       :
      ""}
    </ListItem>
  );
};

export default KegSpecific;
