import React, { useContext, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import SubdirectoryArrowRightOutlinedIcon from "@mui/icons-material/SubdirectoryArrowRightOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import DeleteFamilyModal from "../../../components/Modals/DeleteFamilyModal";
import EditFamilyModal from "../../../components/Modals/EditFamilyModal";
import CreateCategoryModal from "../../../components/Modals/CreateCategoryModal";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import Store from "../../../Store/Store";
import ProductFamilyWarningModal from "../../../components/Modals/ProductFamilyWarningModal";
import DeleteCategoryModal from "../../../components/Modals/DeleteCategoryModal";
import DeleteSubCategoryModal from "../../../components/Modals/DeleteSubcategoryModal";
import CreateSubcategoryModal from "../../../components/Modals/CreateSubcategoryModal";

const ProductFamilyCard = ({ productFamilyGroup }) => {
  const [expanded, setExpanded] = useState(false);
  const [categoryExpanded, setCategoryExpanded] = useState({});
  const [deleteFamilyModalOpen, toggleDeleteFamilyModalOpen] = useState(false);
  const [productFamilyCheckModal, toggleProductFamilyCheckModal] =
    useState(false);
  const [addingCategory, toggleAddingCategory] = useState(false);
  const [addingSubCategory, toggleAddingSubCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubcategory, setSelectedSubcategory] = useState();
  const [deleteCategoryModalOpen, toggleDeleteCategoryModalOpen] =
    useState(false);
  const [deleteSubCategoryModalOpen, toggleDeleteSubCategoryModalOpen] =
    useState(false);
  const [editFamilyModalOpen, toggleEditFamilyModalOpen] = useState(false);
  const [editCategoryModalOpen, toggleEditCategoryModalOpen] = useState(false);
  const [editSubCategoryModalOpen, toggleEditSubCategoryModalOpen] =
    useState(false);
  const [familyAnchorEl, setFamilyAnchorEl] = useState(null);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const [subCategoryAnchorEl, setSubCategoryAnchorEl] = useState(null);
  const [productCount, setProductCount] = useState();
  const familyMenuOpen = Boolean(familyAnchorEl);
  const categoryMenuOpen = Boolean(categoryAnchorEl);
  const subCategoryMenuOpen = Boolean(subCategoryAnchorEl);
  const queryClient = useQueryClient();
  const store = useContext(Store);

  const handleFamilyMenuClick = (event) => {
    setFamilyAnchorEl(event.currentTarget);
  };

  const handleCategoryMenuClick = (event, category) => {
    setSelectedCategory(category);
    setCategoryAnchorEl(event.currentTarget);
  };

  const handleSubCategoryMenuClick = (event, subcategory) => {
    setSelectedSubcategory(subcategory);
    setSubCategoryAnchorEl(event.currentTarget);
  };

  const handleFamilyMenuClose = () => {
    setFamilyAnchorEl(null);
  };

  const handleCategoryMenuClose = () => {
    setCategoryAnchorEl(null);
  };

  const handleSubCategoryMenuClose = () => {
    setSubCategoryAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAddingSubcategoryClick = (subcategory) => {
    setSelectedCategory(subcategory);
    toggleAddingSubCategory(true);
  };

  const handleDeleteFamilyCheck = async () => {
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      productFamilyId: productFamilyGroup.id,
    };

    const response = await inventoryClient.getProductCountByFamilyId(payload);

    if (response.productCount > 0) {
      setProductCount(response.productCount);
      toggleProductFamilyCheckModal(true);
    } else {
      toggleDeleteFamilyModalOpen(true);
    }
  };

  const handleCategoryExpandClick = (categoryId) => {
    setCategoryExpanded((prevExpanded) => ({
      ...prevExpanded,
      [categoryId]: !prevExpanded[categoryId],
    }));
  };

  const deleteFamilyMutation = useMutation(inventoryClient.deleteFamily, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "productFamilyGroups",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
      setFamilyAnchorEl(null);
      queryClient.refetchQueries({
        queryKey: [
          "productFamilyGroups",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
    },
  });

  const deleteCategoryMutation = useMutation(inventoryClient.deleteCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "productFamilyGroups",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
      setCategoryAnchorEl(null);
      queryClient.refetchQueries({
        queryKey: [
          "productFamilyGroups",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
    },
  });

  const deleteSubCategoryMutation = useMutation(
    inventoryClient.deleteSubCategory,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "productFamilyGroups",
            {
              establishmentId: store.currentEstablishment?.id,
            },
          ],
        });
        setSubCategoryAnchorEl(null);
        queryClient.refetchQueries({
          queryKey: [
            "productFamilyGroups",
            {
              establishmentId: store.currentEstablishment?.id,
            },
          ],
        });
      },
    }
  );

  const patchFamilyMutation = useMutation(inventoryClient.patchFamily, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "productFamilyGroups",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
      setFamilyAnchorEl(null);
      queryClient.refetchQueries({
        queryKey: [
          "productFamilyGroups",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
    },
  });

  const patchCategoryMutation = useMutation(inventoryClient.patchCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "productFamilyGroups",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
      setCategoryAnchorEl(null);
      queryClient.refetchQueries({
        queryKey: [
          "productFamilyGroups",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
    },
  });

  const patchSubCategoryMutation = useMutation(
    inventoryClient.patchSubcategory,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "productFamilyGroups",
            {
              establishmentId: store.currentEstablishment?.id,
            },
          ],
        });
        setSubCategoryAnchorEl(null);
        queryClient.refetchQueries({
          queryKey: [
            "productFamilyGroups",
            {
              establishmentId: store.currentEstablishment?.id,
            },
          ],
        });
      },
    }
  );

  const handlePatchFamily = async (name) => {
    let payload = {
      establishmentId: store.currentEstablishment?.id,
      productFamilyId: productFamilyGroup.id,
      name: name,
    };
    await patchFamilyMutation.mutateAsync(payload);
    setFamilyAnchorEl(false);
  };

  const handlePatchCategory = async (name) => {
    let payload = {
      establishmentId: store.currentEstablishment?.id,
      categoryId: selectedCategory?.id,
      name: name,
    };
    await patchCategoryMutation.mutateAsync(payload);
    setCategoryAnchorEl(false);
  };

  const handleAddCategory = async (name, productFamilyId) => {
    await inventoryClient.addCategory(
      name,
      productFamilyId,
      store.currentEstablishment?.id
    );
    queryClient.invalidateQueries({
      queryKey: [
        "productFamilyGroups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "productFamilyGroups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
  };

  const handleAddSubcategory = async (name, categoryId) => {
    await inventoryClient.addSubcategory(
      name,
      categoryId,
      store.currentEstablishment?.id
    );
    queryClient.invalidateQueries({
      queryKey: [
        "productFamilyGroups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "productFamilyGroups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
  };

  const handlePatchSubcategory = async (name) => {
    let payload = {
      establishmentId: store.currentEstablishment?.id,
      subcategoryId: selectedSubcategory?.id,
      name: name,
    };
    await patchSubCategoryMutation.mutateAsync(payload);
    setSubCategoryAnchorEl(false);
  };

  const handleDeleteFamily = async () => {
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      productFamilyId: productFamilyGroup.id,
    };

    await deleteFamilyMutation.mutateAsync(payload);
  };

  const handleDeleteCategory = async () => {
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      categoryId: selectedCategory?.id,
    };

    await deleteCategoryMutation.mutateAsync(payload);
  };

  const handleDeleteSubCategory = async () => {
    console.log(selectedSubcategory);
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      subcategoryId: selectedSubcategory.id,
    };

    await deleteSubCategoryMutation.mutateAsync(payload);
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain["300"],
          marginBottom: 4,
        }}
      >
        <CardContent
          sx={{ height: "56px", width: "100%", margin: 0, padding: 0 }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "56px",
              backgroundColor: (theme) => theme.palette.pure.white,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    marginLeft: 4,
                    color: (theme) => theme.palette.pure.black,
                  }}
                >
                  {productFamilyGroup.name}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box>
                <IconButton
                  onClick={handleFamilyMenuClick}
                  sx={{
                    height: "32px",
                    width: "32px",
                    marginRight: 2,
                    color: (theme) => theme.palette.primary[800],
                    "&:hover": {
                      background: "none",
                      color: (theme) => theme.palette.terrain[100],
                    },
                  }}
                >
                  <MoreHorizOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  onClick={() => toggleAddingCategory(true)}
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary[800],
                    height: "32px",
                    width: "32px",
                    marginRight: 2,
                  }}
                >
                  <AddOutlinedIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "36px",
                }}
              >
                {!expanded ? (
                  <IconButton
                    sx={{
                      color: (theme) => theme.palette.terrain[900],
                      height: "36px",
                      background: "none",
                      "&:hover": {
                        background: "none",
                        color: (theme) => theme.palette.terrain[100],
                      },
                    }}
                    onClick={handleExpandClick}
                  >
                    <KeyboardArrowDownOutlinedIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      color: (theme) => theme.palette.terrain[900],
                      height: "36px",
                      background: "none",
                      "&:hover": {
                        background: "none",
                        color: (theme) => theme.palette.terrain[100],
                      },
                    }}
                    onClick={handleExpandClick}
                  >
                    <ExpandLessOutlinedIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Grid>
        </CardContent>
        {productFamilyGroup.categories?.map((x) => (
          <>
            <Collapse in={expanded} timeout="auto" unmountOnExit key={x.id}>
              <CardContent
                sx={{
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  borderTop: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  height: "56px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    marginTop: 6,
                  }}
                >
                  <Box>
                    <Grid
                      container
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <SubdirectoryArrowRightOutlinedIcon
                        sx={{
                          color: (theme) => theme.palette.info[600],
                          fontSize: 24,
                          marginLeft: 4,
                        }}
                      />
                      <Grid item>
                        <Box>
                          <Box>
                            <Typography
                              variant="h5"
                              sx={{
                                color: (theme) => theme.palette.pure.black,
                                marginLeft: 2,
                              }}
                            >
                              {x.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box>
                      <IconButton
                        onClick={(e) => handleCategoryMenuClick(e, x)}
                        sx={{
                          height: "32px",
                          width: "32px",
                          marginRight: 2,
                          color: (theme) => theme.palette.primary[800],
                          "&:hover": {
                            background: "none",
                            color: (theme) => theme.palette.terrain[100],
                          },
                        }}
                      >
                        <MoreHorizOutlinedIcon />
                      </IconButton>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => handleAddingSubcategoryClick(x)}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary[800],
                          height: "32px",
                          width: "32px",
                          marginRight: 2,
                        }}
                      >
                        <AddOutlinedIcon />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "36px",
                      }}
                    >
                      {!categoryExpanded[x.id] ? (
                        <IconButton
                          sx={{
                            color: (theme) => theme.palette.terrain[900],
                            height: "36px",
                            background: "none",
                            "&:hover": {
                              background: "none",
                              color: (theme) => theme.palette.terrain[100],
                            },
                          }}
                          onClick={() => handleCategoryExpandClick(x.id)}
                        >
                          <KeyboardArrowDownOutlinedIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{
                            color: (theme) => theme.palette.terrain[900],
                            height: "36px",
                            background: "none",
                            "&:hover": {
                              background: "none",
                              color: (theme) => theme.palette.terrain[100],
                            },
                          }}
                          onClick={() => handleCategoryExpandClick(x.id)}
                        >
                          <ExpandLessOutlinedIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </CardContent>
            </Collapse>
            {x.subcategories?.map((c) => (
              <Collapse
                in={categoryExpanded[x.id]}
                timeout="auto"
                unmountOnExit
                key={c.id}
              >
                <Box
                  sx={{
                    background: (theme) => theme.palette.terrain[100],
                    borderTop: "1px solid",
                    borderColor: (theme) => theme.palette.terrain[300],
                    padding: 0,
                    height: "56px",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      // marginTop: 3,
                    }}
                  >
                    <Box sx={{ marginLeft: 6 }}>
                      <Grid
                        container
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <SubdirectoryArrowRightOutlinedIcon
                          sx={{
                            color: (theme) => theme.palette.info[600],
                            fontSize: 22,
                            marginLeft: 4,
                          }}
                        />
                        <Box></Box>
                        <Box>
                          <Box>
                            <Typography
                              variant="h5"
                              sx={{
                                color: (theme) => theme.palette.pure.black,
                                marginLeft: 2,
                              }}
                            >
                              {c.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box>
                        <IconButton
                          onClick={(e) => handleSubCategoryMenuClick(e, c)}
                          sx={{
                            height: "32px",
                            width: "32px",
                            color: (theme) => theme.palette.primary[800],
                            marginRight: 1,
                            "&:hover": {
                              background: "none",
                              color: (theme) => theme.palette.terrain[100],
                            },
                          }}
                        >
                          <MoreHorizOutlinedIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Collapse>
            ))}
          </>
        ))}
      </Card>
      <Menu
        anchorEl={familyAnchorEl}
        open={familyMenuOpen}
        onClose={handleFamilyMenuClose}
      >
        <MenuItem
          onClick={() => toggleEditFamilyModalOpen(true)}
          sx={{ margin: "5px" }}
        >
          <EditIcon sx={{ marginRight: "5px" }} />
          Rename
        </MenuItem>
        <MenuItem onClick={handleDeleteFamilyCheck} sx={{ margin: "5px" }}>
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={categoryAnchorEl}
        open={categoryMenuOpen}
        onClose={handleCategoryMenuClose}
      >
        <MenuItem
          onClick={() => toggleEditCategoryModalOpen(true)}
          sx={{ margin: "5px" }}
        >
          <EditIcon sx={{ marginRight: "5px" }} />
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => toggleDeleteCategoryModalOpen(true)}
          sx={{ margin: "5px" }}
        >
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={subCategoryAnchorEl}
        open={subCategoryMenuOpen}
        onClose={handleSubCategoryMenuClose}
      >
        <MenuItem
          onClick={() => toggleEditSubCategoryModalOpen(true)}
          sx={{ margin: "5px" }}
        >
          <EditIcon sx={{ marginRight: "5px" }} />
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => toggleDeleteSubCategoryModalOpen(true)}
          sx={{ margin: "5px" }}
        >
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <DeleteFamilyModal
        handleDelete={handleDeleteFamily}
        isOpen={deleteFamilyModalOpen}
        toggleModalOpen={toggleDeleteFamilyModalOpen}
        name={productFamilyGroup.name}
        modalHeader="Family"
      />
      <ProductFamilyWarningModal
        isOpen={productFamilyCheckModal}
        toggleModalOpen={toggleProductFamilyCheckModal}
        productCount={productCount && productCount}
      />
      <DeleteCategoryModal
        handleDelete={handleDeleteCategory}
        categoryName={selectedCategory?.name}
        isOpen={deleteCategoryModalOpen}
        toggleModalOpen={toggleDeleteCategoryModalOpen}
      />
      <DeleteSubCategoryModal
        handleDelete={handleDeleteSubCategory}
        subcategoryName={selectedSubcategory?.name}
        isOpen={deleteSubCategoryModalOpen}
        toggleModalOpen={toggleDeleteSubCategoryModalOpen}
      />
      <EditFamilyModal
        isOpen={editFamilyModalOpen}
        toggleModalOpen={toggleEditFamilyModalOpen}
        headerText="Edit Family Name"
        item={productFamilyGroup}
        handlePatch={handlePatchFamily}
      />
      <EditFamilyModal
        isOpen={editCategoryModalOpen}
        toggleModalOpen={toggleEditCategoryModalOpen}
        headerText="Edit Category Name"
        item={selectedCategory}
        handlePatch={handlePatchCategory}
      />
      <EditFamilyModal
        isOpen={editSubCategoryModalOpen}
        toggleModalOpen={toggleEditSubCategoryModalOpen}
        headerText="Edit Subcategory Name"
        item={selectedSubcategory}
        handlePatch={handlePatchSubcategory}
      />
      <CreateCategoryModal
        isOpen={addingCategory}
        toggleModalOpen={toggleAddingCategory}
        modalText="Categories allow you to separate families into specific types (e.g. Spirits > Gin)"
        handleAddCategory={handleAddCategory}
        productFamilyId={productFamilyGroup.id}
      />
      <CreateSubcategoryModal
        isOpen={addingSubCategory}
        toggleModalOpen={toggleAddingSubCategory}
        modalText="Subcategories are the final tier of grouping, allowing for one more layer of organization (e.g. Spirits > Gin > Sloe)"
        handleAddSubcategory={handleAddSubcategory}
        categoryId={selectedCategory?.id}
        establishmentId={store.currentEstablishment?.id}
      />
    </>
  );
};

export default ProductFamilyCard;
