import React from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  List,
  CircularProgress,
  Divider,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SpecBackButton from "../../../SpecBackButton";
import FamilyNestedListItem from "./FamilyNestedListItem";

const NewTemplateFamiliesStep = ({
  handleNextAction,
  handleBack,
  families,
  isLoading,
  productFamilyTargetIds,
  categoryTargetIds,
  subcategoryTargetIds,
  toggleFamilyTarget,
  toggleCategoryTarget,
  toggleSubcategoryTarget,
  closeModal,
  selectAll
}) => {
  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 3,
        }}
      >
        <Grid item>
          <SpecBackButton backText="Back" onClick={handleBack} />
        </Grid>
        <Grid item>
          <IconButton onClick={closeModal}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: 2, display: "flex", flexDirection: "row" }}>
        <Typography variant="h3">Specify Product Groups</Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: { xs: 10, sm: 10, md: 12 } }}>
          {" "}
          Select the product families, categories and subcategories you want
          included in the count.
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: 4,
          // overflow: "scroll",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "41px",
            backgroundColor: (theme) => theme.palette.terrain[100],
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            padding: 2,
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
          }}
        >
          <Button variant="outlined" onClick={selectAll}>
            <Typography variant="smallButton">Select All</Typography>
          </Button>
        </Box>
        <List
          className="no-side-scroll-bar"
          disablePadding
          sx={{
            overflow: "scroll",
            maxHeight: { xs: "200px", md: "300px" },
            borderLeft: "1px solid",
            borderRight: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
          }}
        >
          {families.map((x) => (
            <FamilyNestedListItem
              key={x.id}
              family={x}
              categoryTargetIds={categoryTargetIds}
              productFamilyTargetIds={productFamilyTargetIds}
              subcategoryTargetIds={subcategoryTargetIds}
              toggleCategoryTarget={toggleCategoryTarget}
              toggleFamilyTarget={toggleFamilyTarget}
              toggleSubcategoryTarget={toggleSubcategoryTarget}
            />
          ))}
        </List>
      </Box>
      <Divider sx={{ marginLeft: -8, marginRight: -8 }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <Button
          variant="contained"
          onClick={handleNextAction}
          disabled={
            !Object.keys(productFamilyTargetIds)?.find(
              (x) => productFamilyTargetIds[x]
            )
          }
          sx={{
            borderRadius: 2,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <Typography variant="smallButton">Create Template</Typography>
        </Button>
      </Box>
    </>
  );
};

export default NewTemplateFamiliesStep;
