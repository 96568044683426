import React from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const UpdateConfirmModal = ({
  isOpen,
  toggleModalOpen,
  buttonText,
  headerText,
  loading,
  handleUpdate,
  bodyText,
}) => {
  const closeModal = () => {
    toggleModalOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid
            container
            direction="column"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item></Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box>
              <EditOutlinedIcon sx={{ fontSize: 72 }} />
            </Box>
            <Box
              sx={{
                marginTop: 4,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography variant="h2">{headerText}</Typography>
            </Box>
            <Box
              sx={{ marginTop: 4, display: "flex", justifyContent: "center" }}
            >
              <Typography variant="body1">{bodyText}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3rem",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                onClick={closeModal}
                sx={{ width: "50%", marginRight: 1 }}
              >
                <Typography variant="largeButton">No, Ignore</Typography>
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "50%",
                  height: "40px",
                  marginLeft: 1,
                }}
                onClick={() => handleUpdate()}
              >
                <Typography variant="largeButton">{buttonText}</Typography>
              </Button>
            </Box>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default UpdateConfirmModal;
