import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import utilFunctions from "../../utilities/utilFunctions";

const sectionBubbleStyle = {
  borderRadius: 2,
  backgroundColor: (theme) => theme.palette.pure.white,
  border: (theme) => `2px solid ${theme.palette.terrain["03"]}`,
};

const imageStyle = {
  borderRadius: "100%",
  height: "89px",
  width: "89px",
  border: "2px solid #7E7E7E",
  margin: "auto",
};

const RecipeDetailDisplay = ({ recipe }) => (
  <Grid container direction="column" spacing={4} sx={{ maxWidth: "2440px" }}>
    <Grid
      item
      sx={{
        ...sectionBubbleStyle,
        ...{
          height: "125px",
          display: "flex",
          alignItems: "center",
          paddingLeft: 6,
          paddingRight: 6,
          paddingBottom: 4,
        },
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <Typography variant="overline">Drink Status: </Typography>
          <Typography
            variant="overline"
            sx={{ color: (theme) => theme.palette.info.main }}
          >
            {recipe.active ? "Active" : "Inactive"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">
            {utilFunctions.capitalizeString(recipe.name)}
          </Typography>
        </Grid>
      </Grid>
      {recipe.imageURL && <img src={recipe.imageURL} style={imageStyle} />}
    </Grid>
    <Grid item>
      <Grid container direction="row" spacing={4}>
        <Grid item sx={{ paddingLeft: "0 !important" }} md={6}>
          <Grid container direction="column">
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: "80px", marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Glass Type
              </Typography>
              <Typography variant="body1">{recipe.glass || ""}</Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: "80px", marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Garnish
              </Typography>
              <Typography variant="body1">{recipe.garnish || ""}</Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: "80px", marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Process
              </Typography>
              <Typography variant="body1">
                {recipe.preparation || ""}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: "80px", marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                History
              </Typography>
              <Typography variant="body1">{recipe.history || ""}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container direction="column">
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: "80px", marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Drink Ingredients
              </Typography>
              <Grid container direction="column">
                {recipe.ingredients?.length > 0 &&
                  recipe.ingredients.map((i, index) => (
                    <Grid
                      key={index}
                      item
                      sx={{
                        ...sectionBubbleStyle,
                        ...{
                          height: 44,
                          display: "flex",
                          padding: 3,
                          marginBottom: 3,
                        },
                      }}
                    >
                      <Typography variant="body2">{i.amount}</Typography>
                      <Typography variant="title" color="inherit" noWrap>
                        &nbsp;
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.terrain["02"] }}
                      >
                        {i.unit}
                      </Typography>
                      <Typography variant="title" color="inherit" noWrap>
                        &nbsp;
                      </Typography>
                      <Typography variant="body2">{i.name}</Typography>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                ...sectionBubbleStyle,
                ...{ minHeight: 98, marginBottom: 6, padding: 4 },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 3,
                  color: (theme) => theme.palette.terrain["02"],
                }}
              >
                Tasting Notes
              </Typography>
              <Typography variant="body1">
                {recipe.talkingPoints || ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default RecipeDetailDisplay;
