import { useQuery } from "react-query";
import { getMyMemberships } from "../clients/establishmentsClient";

function useMyMemberships() {
    const queryReturnValues = useQuery(
        ["my-memberships"],
        getMyMemberships,
        {
          refetchOnWindowFocus: false,
          staleTime: 120 * 60 * 1000,
          cacheTime: 120 * 60 * 1000,
        }
    );

   return queryReturnValues;
}

export default useMyMemberships;