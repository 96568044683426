import {
  Box,
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import NestedListExpandIcon from "./NestedListExpandIcon";

const FamilyNestedListItem = ({
  family,
  filters,
  toggleFamilyTarget,
  toggleCategoryTarget,
  toggleSubcategoryTarget,
}) => {
  const [isFamilyExpanded, toggleIsFamilyExpanded] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});

  const handleFamilyExpanded = () => {
    toggleIsFamilyExpanded(!isFamilyExpanded);
  };

  const handleExpandCategory = (id) => {
    const newExpanded = Object.assign({}, expandedCategories);
    newExpanded[id] = expandedCategories[id] ? false : true;
    setExpandedCategories(newExpanded);
  };

  const handleFamilyCheckClick = () => {
    toggleFamilyTarget(family.id);
  };

  const handleCategoryCheckClick = (e) => {
    toggleCategoryTarget(family.id, e.target.id);
  };

  const handleSubcategoryCheckClick = (e) => {
    const idChain = e.target.id.split(",");
    toggleSubcategoryTarget(idChain[0], idChain[1], idChain[2]);
  };

  return (
    <>
      <ListItemButton
        disableRipple
        disableTouchRipple
        sx={{
          paddingLeft: 3,
          borderLeft: (theme) => `2px solid ${theme.palette.primary[100]}`,
          margin: 0,
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            borderBottom: "1px solid",
            borderBottomColor: (theme) => theme.palette.terrain[300],
            padding: 1,
          }}
        >
          <ListItemIcon>
            <Checkbox
              id={family.id}
              checked={filters[family.id] !== undefined ? true : false}
              indeterminate={filters[family.id] !== undefined ? true : false}
              onChange={handleFamilyCheckClick}
              sx={{ color: (theme) => theme.palette.primary[800] }}
            />
          </ListItemIcon>
          <ListItemText sx={{ marginLeft: -2 }}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography variant="body1" noWrap>
                {family.name}
              </Typography>
            </div>
          </ListItemText>
          {family.categories?.length > 0 ? (
            <NestedListExpandIcon
              isOpen={isFamilyExpanded}
              toggleOpen={handleFamilyExpanded}
            />
          ) : null}
        </Box>
      </ListItemButton>
      {family.categories?.length > 0 ? (
        <Collapse in={isFamilyExpanded} timeout="auto">
          <List component="div" disablePadding>
            {family.categories?.map((x) => (
              <>
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  key={x.id}
                  sx={{
                    backgroundColor: (theme) => theme.palette.terrain[50],
                    borderLeft: (theme) =>
                      `4px solid ${theme.palette.primary[200]}`,
                    margin: 0,
                    padding: 0,
                  }}
                  id={x.id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      borderBottom: "1px solid",
                      borderBottomColor: (theme) => theme.palette.terrain[300],
                      padding: 1,
                      paddingLeft: 6,
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        sx={{ color: (theme) => theme.palette.primary[800] }}
                        id={x.id}
                        checked={
                          filters[family.id] !== undefined &&
                          filters[x.productFamilyId][x.id] !== undefined
                            ? true
                            : false
                        }
                        onChange={handleCategoryCheckClick}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        marginLeft: -2,
                      }}
                    >
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography variant="body1" noWrap>
                          {x.name}
                        </Typography>
                      </div>
                    </ListItemText>
                    {x.subcategories?.length > 0 ? (
                      <NestedListExpandIcon
                        isOpen={expandedCategories[x.id] || false}
                        toggleOpen={() => handleExpandCategory(x.id)}
                      />
                    ) : null}
                  </Box>
                </ListItemButton>
                {x.subcategories?.length > 0 ? (
                  <Collapse
                    in={isFamilyExpanded && expandedCategories[x.id]}
                    timeout="auto"
                  >
                    <List component="div" disablePadding>
                      {x.subcategories?.map((sub) => (
                        <ListItemButton
                          disableRipple
                          disableTouchRipple
                          key={sub.id}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.terrain[50],
                            borderLeft: "6px solid",
                            borderLeftColor: (theme) =>
                              theme.palette.primary[300],
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100%",
                              borderBottom: "1px solid",
                              borderBottomColor: (theme) =>
                                theme.palette.terrain[300],
                              padding: 1,
                              paddingLeft: 9,
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                sx={{
                                  color: (theme) => theme.palette.primary[800],
                                }}
                                id={`${family.id},${x.id},${sub.id}`}
                                checked={
                                  filters[family.id] !== undefined &&
                                  filters[family.id][x.id] !== undefined &&
                                  filters[family.id][x.id][sub.id] !== undefined
                                    ? true
                                    : false || false
                                }
                                onChange={handleSubcategoryCheckClick}
                              />
                            </ListItemIcon>
                            <ListItemText sx={{ marginLeft: -2 }}>
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Typography variant="body1" noWrap>
                                  {sub.name}
                                </Typography>
                              </div>
                            </ListItemText>
                          </Box>
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                ) : null}
              </>
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
};

export default FamilyNestedListItem;
