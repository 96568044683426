import React, { useEffect, useState } from "react";
import { modalBoxStyle } from "./styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const EditExpenseGroupModal = ({
  isOpen,
  toggleModalOpen,
  headerText,
  item,
  editExpenseGroup,
  editExpenseSubgroup
}) => {
  const [name, setName] = useState(item?.name);
  const [isLoading, toggleIsLoading] = useState(false);

  useEffect(() => {
    setName(item?.name)
  },[item])

  const closeModal = () => {
    toggleModalOpen(false);
    toggleIsLoading(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async () => {
    if (headerText === "Edit Expense Group Name") {
      toggleIsLoading(true);
      await editExpenseGroup(name);
      closeModal();
    }
    if (headerText === "Edit Subgroup Name") {
      toggleIsLoading(true);
      const subgroup = {id: item.id, name: name};
      await editExpenseSubgroup(subgroup);
      closeModal();
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        <form>
          <Grid
            container
            direction="column"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Typography variant="h2">{headerText}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: 8, width: "100%" }}>
              <TextField
                size="small"
                required
                fullWidth
                value={name}
                label="*Specific* Name"
                InputLabelProps={{ shrink: true }}
                onChange={handleNameChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3rem",
              }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  disabled={!name}
                  onClick={handleSubmit}
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="largeButton">Save Details</Typography>
                </Button>
              )}
            </Box>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default EditExpenseGroupModal;