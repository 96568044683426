import React, { useEffect, useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
  MenuItem,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  productSizeTypes,
  unitOfMeasurementTypes,
} from "../../../clients/inventoryObjects";
import { cloneDeep } from "lodash";

const servingTypeObjects = [
  { displayText: "Volumetric", value: productSizeTypes.Volumetric },
  { displayText: "Mass", value: productSizeTypes.Mass },
  { displayText: "Count", value: productSizeTypes.Arbitrary },
];

const IngredientQuantityModal = ({
  isOpen,
  toggleModalOpen,
  loading,
  ingredient,
  unitsOfMeasurement,
  updateIngredient,
}) => {
  const [quantityCopy, setQuantityCopy] = useState({});
  const [servingType, setServingType] = useState(
    unitOfMeasurementTypes.Volumetric
  );
  const [uomOptions, setUomOptions] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (
      ingredient?.quantity?.unitOfMeasurement !== null &&
      ingredient?.quantity?.unitOfMeasurement !== undefined
    ) {
      handleSetUomOptions(ingredient.quantity?.type);
      setServingType(ingredient.quantity.type);
      setQuantityCopy(cloneDeep(ingredient.quantity));
    } else if (ingredient?.quantity?.type === productSizeTypes.Arbitrary) {
      setServingType(productSizeTypes.Arbitrary);
      setQuantityCopy(
        Object.assign({}, ingredient?.quantity, {
          type: productSizeTypes.Arbitrary,
        })
      );
    } else {
      handleSetUomOptions(unitOfMeasurementTypes.Volumetric);
      setServingType(productSizeTypes.Volumetric);
      setQuantityCopy(
        Object.assign({}, ingredient?.quantity, {
          type: productSizeTypes.Volumetric,
        })
      );
    }
  }, [ingredient]);

  const closeModal = () => {
    setQuantityCopy({});
    setErrors([]);
    toggleModalOpen(false, null);
  };

  const handleSetUomOptions = (type) => {
    switch (type) {
      case productSizeTypes.Volumetric:
        setUomOptions(
          unitsOfMeasurement.filter(
            (x) => x.type === unitOfMeasurementTypes.Volumetric && !x.isKegSize
          )
        );
        break;
      case productSizeTypes.Mass:
        setUomOptions(
          unitsOfMeasurement.filter(
            (x) => x.type === unitOfMeasurementTypes.Mass
          )
        );
        break;
      case productSizeTypes.Arbitrary:
        setUomOptions(
          unitsOfMeasurement.filter(
            (x) => x.type === unitOfMeasurementTypes.Arbitrary
          )
        );
        break;
      default:
        setUomOptions(
          unitsOfMeasurement.filter(
            (x) => x.type === unitOfMeasurementTypes.Volumetric && !x.isKegSize
          )
        );
        break;
    }
  };

  const handleTypeChange = (e) => {
    if (e.target.value === servingType) {
      return;
    }

    setServingType(e.target.value);
    handleSetUomOptions(e.target.value);
    const updatedQuantity = cloneDeep(quantityCopy);
    updatedQuantity.type = e.target.value;
    updatedQuantity.unitOfMeasurement = null;
    setQuantityCopy(updatedQuantity);
  };

  const handleSetAmount = (e) => {
    const isNumeric = /^[0-9.]*$/.test(e.target.value);
    if (isNumeric) {
      const updatedQuantity = cloneDeep(quantityCopy);
      updatedQuantity.amount = e.target.value;
      setQuantityCopy(updatedQuantity);
    }
  };

  const handleUomChange = (e) => {
    const updatedQuantity = cloneDeep(quantityCopy);
    updatedQuantity.unitOfMeasurement = e.target.value;
    setQuantityCopy(updatedQuantity);
  };

  const handleSubmit = async () => {
    setErrors([]);
    let hasErrors = [];
    if (quantityCopy.amount === null || quantityCopy.amount === undefined) {
      hasErrors.push("amount");
    }

    if (
      quantityCopy.unitOfMeasurement == null &&
      servingType !== productSizeTypes.Arbitrary
    ) {
      hasErrors.push("measurement");
    }

    if (hasErrors.length > 0) {
      setErrors(hasErrors);
    } else {
      await updateIngredient("quantity", quantityCopy, ingredient.id);
      closeModal();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid
            container
            direction="column"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Typography variant="h2">Edit Quantity</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: 8, width: "100%" }}>
              <TextField
                select
                size="small"
                fullWidth
                label="Type of Serving"
                value={servingType}
                placeholder="Volume"
                onChange={handleTypeChange}
              >
                {servingTypeObjects.map((x) => (
                  <MenuItem
                    key={x.value}
                    value={x.value}
                    sx={{
                      "&:hover": {
                        color: (theme) => theme.palette.pure.white,
                        backgroundColor: (theme) => theme.palette.terrain[500],
                      },
                    }}
                  >
                    {x.displayText}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ marginTop: 4, width: "100%" }}>
              <Grid container spacing={2} sx={{ width: "100%" }}>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Amount"
                    onChange={handleSetAmount}
                    value={quantityCopy.amount || ""}
                    error={errors.includes("amount")}
                  />
                </Grid>
                <Grid item xs={6}>
                  {servingType !== productSizeTypes.Arbitrary && (
                    <TextField
                      size="small"
                      select
                      fullWidth
                      label="Measurement"
                      value={
                        uomOptions.find(
                          (x) => x.id === quantityCopy.unitOfMeasurement?.id
                        ) || {}
                      }
                      onChange={handleUomChange}
                      error={errors.includes("measurement")}
                    >
                      {uomOptions.map((x) => (
                        <MenuItem
                          key={x.abbreviation}
                          value={x}
                          sx={{
                            "&:hover": {
                              color: (theme) => theme.palette.pure.white,
                              backgroundColor: (theme) =>
                                theme.palette.terrain[500],
                            },
                          }}
                        >
                          {x.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "300px",
                  height: "40px",
                  borderRadius: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 6,
                  paddingRight: 6,
                }}
                onClick={handleSubmit}
              >
                <Typography variant="largeButton">Save Quantity</Typography>
              </Button>
            </Box>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default IngredientQuantityModal;
