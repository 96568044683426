import React from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

const SpecSpinner = ({ open, text }) => {
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <Grid
          container
          direction="column"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item>
            <CircularProgress
              sx={{ size: { xs: "1rem", sm: "2rem", md: "4rem" } }}
              color="inherit"
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h2"
              sx={{ fontSize: { xs: 20, sm: 20, md: 28 } }}
            >
              {text}
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
    </Box>
  );
};

export default SpecSpinner;
