import React, { useContext, useEffect, useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CreateAutoComplete from "../../CreateAutoComplete";
import Store from "../../../Store/Store";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import inventoryClient from "../../../clients/inventoryClient";
import { useQueryClient } from "react-query";
import UnitSizeRow from "./components/UnitSizeRow";
import utilFunctions from "../../../utilities/utilFunctions";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";

const ProductDetailsMissingModal = ({
  isOpen,
  toggleModalOpen,
  missingDetailsHeader,
  missingDetailsData,
  unitsOfMeasurement,
  vendors,
}) => {
  const [loading, toggleLoading] = useState(false);
  const [sizeDataRequired, toggleSizeDataRequired] = useState(false);
  const [vendorDataRequired, toggleVendorDataRequired] = useState(false);
  const [purchaseUnitDataRequired, togglePurchaseUnitDataRequired] =
    useState(false);
  const [priceDataRequired, togglePriceDataRequired] = useState(false);
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(store.currentEstablishment?.id);
  const { locale, currency, currencySymbol } = useVendorCurrency(settings?.inventorySettings?.currency);

  const [tempSizeData, setTempSizeData] = useState({});
  const [tempVendorData, setTempVendorData] = useState(null);
  const [tempPurchaseUnit, setTempPurchaseUnit] = useState({});
  const [tempPrice, setTempPrice] = useState({});
  const [vendorDisabled, toggleVendorDisabled] = useState(false);
  const queryClient = useQueryClient();
  
  const [unitSizeAmountError, toggleUnitSizeAmountError] = useState(false);
  const [unitSizeUomError, toggleUnitSizeUomError] = useState(false);
  const [vendorError, toggleVendorError] = useState(false);
  const [priceError, togglePriceError] = useState(false);
  const [unitNameError, toggleUnitNameError] = useState(false);
  const [unitAmountError, toggleUnitAmountError] = useState(false);
  const [savingError, toggleSavingError] = useState(false);

  useEffect(() => {
    if (missingDetailsData?.vendor?.id === null ||
      missingDetailsData?.vendor?.id === undefined ||
      missingDetailsData?.vendor?.id === -1) {
      toggleVendorDataRequired(true);
    } else {
      toggleVendorDataRequired(false);
    }

    if (
      !missingDetailsData?.purchaseUnit ||
      !missingDetailsData?.purchaseUnit?.name ||
      !missingDetailsData?.purchaseUnit?.unitAmount
    ) {
      togglePurchaseUnitDataRequired(true);
      setTempPurchaseUnit({
        name: missingDetailsData?.purchaseUnit?.name || "",
        unitAmount: missingDetailsData?.purchaseUnit?.unitAmount || undefined,
      });
    } else {
      togglePurchaseUnitDataRequired(false);
    }

    const defaultSize = missingDetailsData?.currentFormat?.sizes?.find(
      (x) => x.isDefaultSize
    );
    if (!defaultSize?.size) {
      toggleSizeDataRequired(true);
      setTempSizeData({
        isDefaultSize: true,
        isKegSize: defaultSize ? defaultSize.isKegSize : false,
        size: defaultSize ? defaultSize.size : null,
        type: defaultSize ? defaultSize.type : 1,
        unitOfMeasurement: defaultSize ? defaultSize.unitOfMeasurement : null,
      });
    } else {
      toggleSizeDataRequired(false);
    }

    if (
      missingDetailsData?.purchaseUnit?.price?.amount === null ||
      missingDetailsData?.purchaseUnit?.price?.amount === undefined
    ) {
      togglePriceDataRequired(true);
      setTempPrice({ amount: null, type: locale === "gbp" ? 2 : 0, unit: 0 });
    } else {
      togglePriceDataRequired(false);
    }
  }, [missingDetailsData]);
  
  const clearErrors = () => {
    toggleVendorError(false);
    toggleUnitSizeAmountError(false);
    toggleUnitSizeUomError(false);
    toggleUnitNameError(false);
    toggleUnitAmountError(false);
    togglePriceError(false);
    toggleSavingError(false);
  }

  const closeModal = () => {
    clearErrors();
    toggleModalOpen(false);
    toggleLoading(false);
  };

  const handleSelectVendor = async (v) => {
    setTempVendorData(v);
  };

  const handleCreateVendor = async (v) => {
    toggleVendorDisabled(true);
    const result = await inventoryClient.addDistributors(
      [{ name: v.name }],
      store.currentEstablishment?.id
    );
    handleSelectVendor(result.data.vendors[0]);

    queryClient.invalidateQueries({
      queryKey: [
        "vendors",
        { establishmentId: store.currentEstablishment?.id },
      ],
    });
    toggleVendorDisabled(false);
  };

  const handleClearVendor = async () => {
    setTempVendorData(null);
  };

  const handleSave = async () => {
    try {
      let errors = false;
    clearErrors();

    if (vendorDataRequired && (!tempVendorData?.id || tempVendorData?.id === -1)) {
      errors = true;
      toggleVendorError(true);
    }

    if (sizeDataRequired) {
      if (!tempSizeData?.unitOfMeasurement || tempSizeData.unitOfMeasurement.id === -1) {
        errors = true;
        toggleUnitSizeUomError(true);
      }

      if ((tempSizeData?.size === null || tempSizeData === undefined) && !tempSizeData?.unitOfMeasurement?.isKegSize) {
        errors = true;
        toggleUnitSizeAmountError(true);
      }
    }

    if (purchaseUnitDataRequired) {
      if (!tempPurchaseUnit.name) {
        errors = true;
        toggleUnitNameError(true);
      }

      if (!tempPurchaseUnit?.unitAmount && !tempPurchaseUnit?.unitAmount !== 0) {
        errors = true;
        toggleUnitAmountError(true);
      }
    }

    if (priceDataRequired) {
      if (!tempPrice.amount && tempPrice.amount !== 0) {
        errors = true;
        togglePriceError(true);
      }
    }

    if (errors) {
      return;
    }

    toggleLoading(true);
    if (sizeDataRequired) {
      await inventoryClient.updateProductFormat({ id: missingDetailsData.currentFormat.id, sizesUpdate: { value: [tempSizeData, ...missingDetailsData.currentFormat.sizes.filter(x => x.type !== tempSizeData.type)]}}, store.currentEstablishment?.id)
    }

    let payload = { id: missingDetailsData.purchaseUnit.id };

    if (vendorDataRequired) {
      payload["vendorUpdate"] = { value: tempVendorData.id };
    }

    if (priceDataRequired) {
      payload["priceUpdate"] = { value: tempPrice };
    }

    if (purchaseUnitDataRequired) {
      payload["nameUpdate"] = { value: tempPurchaseUnit.name };
      payload["unitAmountUpdate"] = { value: tempPurchaseUnit.unitAmount }
    }

    if (vendorDataRequired || priceDataRequired || purchaseUnitDataRequired) {
      await inventoryClient.updatePurchaseDetail(payload, store.currentEstablishment?.id)
    }

    queryClient.invalidateQueries({ queryKey: ["inventory", { establishmentId: store.currentEstablishment?.id }]});
    queryClient.invalidateQueries({ queryKey: ["inventory-paginated", { establishmentId: store.currentEstablishment?.id }]});
    queryClient.invalidateQueries({ queryKey: ["paginated-product-search", { establishmentId: store.currentEstablishment?.id }]});
    toggleLoading(false);
    closeModal();
    } catch (err) {
      console.log(err);
      toggleSavingError(true);
      toggleLoading(false);
    }
  };

  const defaultSize = missingDetailsData?.currentFormat?.sizes?.find(
    (x) => x.isDefaultSize
  );

  const handleUpdateUnitSize = (newData) => {
    setTempSizeData(newData);
  };

  const productSizings = () => {
    return (
      <Box>
        <Box>
          <Typography variant="h5">Product Sizings</Typography>
        </Box>
        <Box sx={{ marginTop: 6 }}>
          <Grid
            container
            direction="row"
            spacing={3}
            sx={{ dispaly: "flex", justifyContent: "space-between" }}
          >
             <UnitSizeRow
            formatId={missingDetailsData?.currentFormat?.id}
            type={defaultSize?.type}
            unitSize={tempSizeData}
            updateUnitSize={handleUpdateUnitSize}
            unitsOfMeasurement={unitsOfMeasurement}
            unitSizeAmountError={unitSizeAmountError}
            unitSizeUomError={unitSizeUomError}            
          />
          </Grid>
        </Box>
      </Box>
    );
  };

  const vendorDetails = () => {
    return (
      <Box>
        <Box sx={{ marginTop: 6 }}>
          <CreateAutoComplete
            disabled={vendorDisabled}
            options={vendors}
            getOptionLabel={(option) => option.name}
            value={tempVendorData || { id: -1, name: "" }}
            handleSelect={handleSelectVendor}
            handleNew={handleCreateVendor}
            handleClear={handleClearVendor}
            error={vendorError}
            isOptionEqualToFunc={(o) =>
              o.id === tempVendorData?.id || o.id === -1
            }
            type="outlined"
            label="Vendor"
            modalHeader="Create New Vendor"
            modalText="Are you sure you want to create a new Vendor? All vendors can be edited in the Distributors section."
            useCreateModal
          />
          {vendorError && <Typography sx={{ color: theme => theme.palette.error[600]} }>Required</Typography>}
        </Box>
      </Box>
    );
  };

  const purchaseDetails = () => {
    return (
      <Box>
        <Box sx={{ marginTop: 6 }}>
          <Grid
            container
            direction="row"
            spacing={3}
            sx={{ dispaly: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Purchase Unit Name"
                placeholder="Case, Half case, Bottle..."
                InputLabelProps={{ shrink: true }}
                value={tempPurchaseUnit.name}
                error={unitNameError}
                onChange={(e) =>
                  setTempPurchaseUnit(
                    Object.assign({}, tempPurchaseUnit, {
                      name: e.target.value,
                    })
                  )
                }
              />
              {unitNameError && <Typography sx={{ color: theme => theme.palette.error[600]} }>Required</Typography>}
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                label="Purchase Unit Amount"
                placeholder="# of units in this purchase unit"
                error={unitAmountError}
                type="number"
                InputLabelProps={{ shrink: true }}
                value={tempPurchaseUnit.unitAmount}
                onChange={(e) =>
                  setTempPurchaseUnit(
                    Object.assign({}, tempPurchaseUnit, {
                      unitAmount: e.target.value,
                    })
                  )
                }
              />
              {unitAmountError && <Typography sx={{ color: theme => theme.palette.error[600]} }>Required</Typography>}
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const priceDetails = () => {
    return (
      <Box>
        <Box sx={{ marginTop: 6 }}>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="price"
              type="text"
              error={priceError}
              placeholder="Price"
              InputProps={{
                inputMode: "numeric",
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbol}
                  </InputAdornment>
                ),
              }}
              inputProps={{ max: 100000 }}
              value={tempPrice?.amount === null ? utilFunctions.convertCentsToLargerFormatCurrency(tempPrice?.amount, locale, currency) : null}
              onChange={(e) =>
                setTempPrice(
                  Object.assign({}, tempPrice, { amount: utilFunctions.convertCurrencyToCents(e.target.value) })
                )
              }
            />
            {priceError && <Typography sx={{ color: theme => theme.palette.error[600]} }>Required</Typography>}
          </Grid>
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 6,
              }}
            >
              <Grid item>
                <Typography variant="h4">Product Details Required</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
            {savingError && <Typography sx={{ color: theme => theme.palette.error[600]} }>An error occurred while trying to save your updates.</Typography>}
              <Typography
                variant="h4"
                sx={{ fontSize: { xs: 16, sm: 16, md: 20 } }}
              >
                {missingDetailsHeader}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                You are missing key bits of information necessary to add this
                product to your order. Please fill out all of the details below
                to continue the order process.
              </Typography>
            </Box>
            {sizeDataRequired && (
              <Box sx={{ marginBottom: 6, marginTop: 4 }}>
                {productSizings()}
              </Box>
            )}
            {(vendorDataRequired ||
              purchaseUnitDataRequired ||
              priceDataRequired) && (
              <Box>
                <Typography variant="h5">Purchase Details</Typography>
              </Box>
            )}
            {vendorDataRequired && (
              <Box sx={{ marginBottom: 6, marginTop: 4 }}>
                {vendorDetails()}
              </Box>
            )}
            {purchaseUnitDataRequired && (
              <Box sx={{ marginBottom: 6, marginTop: 4 }}>
                {purchaseDetails()}
              </Box>
            )}
            {priceDataRequired && (
              <Box sx={{ marginBottom: 6, marginTop: 4 }}>{priceDetails()}</Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                variant="contained"
                disabled={loading}
                onClick={handleSave}
                sx={{
                  marginLeft: "1rem",
                  borderRadius: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 6,
                  paddingRight: 6,
                }}
              >
                <Typography variant="smallButton">
                 Update
                </Typography>
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ProductDetailsMissingModal;
