import React, { useState, useEffect, useContext } from "react";
import { modalBoxStyle } from "./styles";
// nodejs library to set properties for components
import {
  Button,
  Typography,
  Box,
  Modal,
  TextField,
  Divider,
} from "@mui/material";
import { updateEstablishment } from "../../clients/establishmentsClient";
import Store from "../../Store/Store";
import { CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import ImageControl from "../ImageControl";
import SpecSpinner from "../SpecSpinner";

const CenteredContainerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const FormInputLabelStyles = {
  shrink: true,
};

const TextFieldStyles = {
  marginBottom: "32px",
};

const EditEstablishmentModal = ({
  modalOpen,
  toggleModalOpen,
  isLoading,
  toggleIsLoading,
  setErrorMessage,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({ mode: "all" });
  const [establishmentCopy, updateEstablishmentCopy] = useState({});
  const [addressData, setAddress] = useState({});
  const [newPhoto, setNewPhoto] = useState(null);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [isImageControlOpen, toggleIsImageControlOpen] = useState(false);

  const store = useContext(Store);

  const disabledButtonBackgroundColor = !isValid ? "#c6c6c6" : "";

  const imageControlCallback = async (photoLocation) => {
    setNewPhoto(photoLocation);
    toggleIsImageControlOpen(false);
  };

  const closeModal = () => {
    toggleModalOpen(!modalOpen);
  };

  const handleSave = async (data) => {
    try {
      toggleIsLoading(true);
      setSpinnerOpen(true);
      const response = await updateEstablishment({
        ...store.currentEstablishment,
        ...{
          imageURL: newPhoto || establishmentCopy.imageURL,
          name: data.name,
          Address: {
            addressLine1: data.address,
            city: data.city,
            stateProvince: data.state,
            postalCode: data.zip,
            addressLine2: data.website,
          },
        },
      });
      // the response doesn't include categories, but determining
      // the data type on change looks at this for information on the type, so we add it back
      // response.categories = store.currentEstablishment?.categories;
      store.updateCurrentEstablishment(response);
      store.updateEstablishmentOptions([...store.establishments, response]);
      closeModal();
      toggleIsLoading(false);
      setSpinnerOpen(false);
    } catch (err) {
      setErrorMessage(true);
      console.log(err);
    }
  };

  useEffect(() => {
    updateEstablishmentCopy(store.currentEstablishment);
    setAddress(store.currentEstablishment?.address);
  }, [store.currentEstablishment]);

  useEffect(() => {
    {
      establishmentCopy,
        addressData &&
          reset({
            name: establishmentCopy.name,
            website: addressData.addressLine2,
            address: addressData.addressLine1,
            city: addressData.city,
            state: addressData.stateProvince,
            zip: addressData.postalCode,
          });
    }
  }, [establishmentCopy, addressData]);

  if (isLoading) {
    return (
      <SpecSpinner
        open={spinnerOpen}
        text="Updating establishment information..."
      />
    );
  }

  return (
    <Modal
      open={modalOpen}
      onClose={toggleModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
          {" "}
          Edit Establishment{" "}
        </Typography>
        <Divider sx={{ borderBottom: 2, marginBottom: 4 }} />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <ImageControl
            imageSrc={newPhoto || establishmentCopy.imageURL}
            imageControlCallback={imageControlCallback}
            isImageControlOpen={isImageControlOpen}
            toggleIsImageControlOpen={toggleIsImageControlOpen}
          />
        )}
        <form onSubmit={handleSubmit(handleSave)}>
          <Box sx={{ marginTop: 8 }}>
            <TextField
              size="small"
              required
              InputLabelProps={FormInputLabelStyles}
              fullWidth
              label="Establishment Name"
              id="name"
              name="name"
              sx={TextFieldStyles}
              {...register("name", {
                required: {
                  value: true,
                  message: "Please enter an establishment name",
                },
              })}
            />
            {errors.name && (
              <span style={{ color: "red", marginTop: "0px" }}>
                {errors.name?.message}
              </span>
            )}
            <TextField
              size="small"
              required
              InputLabelProps={FormInputLabelStyles}
              fullWidth
              label="Website"
              id="addressLine2"
              name="website"
              sx={TextFieldStyles}
              {...register("website", {
                required: {
                  value: true,
                  message: "Please enter a website",
                },
              })}
            />
            {errors.website && (
              <span style={{ color: "red", marginTop: "0px" }}>
                {errors.website?.message}
              </span>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                size="small"
                required
                InputLabelProps={FormInputLabelStyles}
                label="Address"
                id="addressLine1"
                name="address"
                sx={{
                  marginBottom: "32px",
                  width: "49%",
                  marginRight: { xs: 1, sm: 0 },
                }}
                {...register("address", {
                  required: {
                    value: true,
                    message: "Please enter an address",
                  },
                })}
              />
              {errors.address && (
                <span style={{ color: "red", marginTop: "0px" }}>
                  {errors.address?.message}
                </span>
              )}
              <TextField
                size="small"
                required
                InputLabelProps={FormInputLabelStyles}
                fullWidth
                label="City"
                id="city"
                name="city"
                sx={{ marginBottom: "32px", width: "49%" }}
                {...register("city", {
                  required: {
                    value: true,
                    message: "Please enter a city",
                  },
                })}
              />
              {errors.city && (
                <span style={{ color: "red", marginTop: "0px" }}>
                  {errors.city?.message}
                </span>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                size="small"
                required
                InputLabelProps={FormInputLabelStyles}
                fullWidth
                label="State"
                id="stateProvince"
                name="state"
                sx={{
                  marginBottom: "32px",
                  width: "49%",
                  marginRight: { xs: 1, sm: 0 },
                }}
                {...register("state", {
                  required: {
                    value: true,
                    message: "Please enter a state",
                  },
                })}
              />
              {errors.state && (
                <span style={{ color: "red", marginTop: "0px" }}>
                  {errors.state?.message}
                </span>
              )}
              <TextField
                size="small"
                required
                InputLabelProps={FormInputLabelStyles}
                fullWidth
                label="Zip Code"
                id="zip"
                name="zip"
                sx={{ marginBottom: "32px", width: "49%" }}
                {...register("zip", {
                  required: {
                    value: true,
                    message: "Please enter a zip code",
                  },
                })}
              />
              {errors.zip && (
                <span style={{ color: "red", marginTop: "0px" }}>
                  {errors.zip?.message}
                </span>
              )}
            </Box>
          </Box>

          <Box sx={CenteredContainerStyles}>
            <Box
              sx={{
                display: "flex",
                marginTop: "1rem",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 2,
                  marginTop: "20px",
                  marginRight: 4,
                  padding: "8px 60px",
                }}
                onClick={closeModal}
              >
                <Typography variant="smallButton">Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || isLoading}
                sx={{
                  background: `${disabledButtonBackgroundColor}`,
                  border: "none",
                  borderRadius: 2,
                  marginTop: "20px",
                  padding: "8px 60px",
                  color: "#ffffff",
                }}
              >
                <Typography variant="smallButton">
                  {isLoading ? <CircularProgress color="inherit" /> : "Save"}
                </Typography>
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

EditEstablishmentModal.propTypes = {};

export default EditEstablishmentModal;
