import React from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useForm } from "react-hook-form";

const CreateSectionForm = ({ closeModal, submitFormCallback }) => {
  const { register, handleSubmit, reset } = useForm();

  const handleSubmitForm = async (data) => {
    await submitFormCallback(data.name);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid
        container
        direction="column"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Typography variant="h2">Create Section</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeModal}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="body1">
            Sections are specific groups within your area -- for example, a
            cupboard or a speedrail.
          </Typography>
        </Box>
        <Box sx={{ marginTop: 8, width: "100%" }}>
          <TextField
            size="small"
            fullWidth
            label="Enter Section Name"
            placeholder="Enter Section Name"
            {...register("name", { required: true })}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "3rem",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "300px",
              height: "40px",
              borderRadius: 2,
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 6,
              paddingRight: 6,
            }}
            type="submit"
          >
            <Typography variant="largeButton">Create Section</Typography>
          </Button>
        </Box>
      </Grid>
    </form>
  );
};

export default CreateSectionForm;
