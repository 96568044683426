import { Typography } from "@mui/material";
import React from "react";
import RepCard from "../../RepCard";

const ReviewSection = ({ distributor }) => {
  return (
    <>
      {distributor.contacts && distributor.contacts.length > 0 ? (
        distributor.contacts?.map((c) => (
          <RepCard
            key={c.id}
            contact={{
              name: c.name,
              phoneNumber: c.phoneNumber,
              email: c.email,
            }}
          />
        ))
      ) : (
        <Typography>No Contacts Added</Typography>
      )}
    </>
  );
};

export default ReviewSection;
