import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import SettingsTabNav from "./components/SettingsTabNav";
import TabPanel from "../../components/TabPanel";
import EstablishmentDetails from "../EstablishmentDetails";
import UnitSizesSettings from "./components/UnitSizesSettings";
import CostAnalysisSettings from "./components/CostAnalysisSettings";
import UserPreferences from "./components/UserPreferences";

const Settings = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkedStyle = {
    backgroundColor: (theme) => theme.palette.primary[50],
    borderBottom: "1px solid",
    borderColor: (theme) => theme.palette.terrain[400],
    height: "50px",
  };

  const defaultStyle = {
    backgroundColor: (theme) => theme.palette.pure.white,
    borderBottom: "1px solid",
    borderColor: (theme) => theme.palette.terrain[400],
    height: "50px",
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "2440px",
        padding: 4,
        marginTop: 6,
      }}
    >
      <Box>
        <Typography variant="h2">Settings</Typography>
      </Box>
      <Box sx={{ marginTop: 6 }}>
        <Typography variant="body1">
          These settings will be consistent throughout this establishment. If at
          any point you decide to change any of these settings, the numerical
          figures will automatically be calculated according to the new form of
          measurements.
        </Typography>
      </Box>
      <Box sx={{ marginTop: 6, marginLeft: 0, width: "100%" }}>
        <Box sx={{ width: "75%" }}>
          <SettingsTabNav currentTab={value} handleChange={handleChange} />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: `calc(100% + 80px)`,
          marginBottom: "80px",
        }}
      >
        <TabPanel value={value} index={0}>
          <EstablishmentDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UnitSizesSettings defaultStyle={defaultStyle} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CostAnalysisSettings
            defaultStyle={defaultStyle}
            checkedStyle={checkedStyle}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <UserPreferences />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Settings;
