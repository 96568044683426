import React from "react";
import { modalBoxStyle } from "./styles";
import { Button, Typography, Box, Modal } from "@mui/material";

const SessionExpiredModal = ({ toggle, isOpen }) => (
  <Modal
    open={isOpen}
    onClose={toggle}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    style={{ marginTop: "2em" }}
  >
    <Box sx={modalBoxStyle}>
      <Typography style={{ fontSize: "2rem", marginBottom: "1rem" }}>
        {" "}
        Session Expired
      </Typography>
      <Typography style={{ fontSize: "1rem", marginBottom: "1rem" }}>
        {" "}
        Your session has expired. Please sign in again.{" "}
      </Typography>
      <div style={{ border: "solid", borderWidth: "thin" }} />
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <Button
          variant="contained"
          type="submit"
          style={{ marginLeft: "1rem" }}
          onClick={toggle}
        >
          {" "}
          Ok{" "}
        </Button>
      </div>
    </Box>
  </Modal>
);

export default SessionExpiredModal;
