import { Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";

const OrderFilterBanner = ({
  orderForFilter,
  orderColorMap,
  linkedOrders,
  invoiceItems,
  unlinkOrder,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  if (orderForFilter === undefined) {
    return null;
  }

  const orderForDisplay = linkedOrders.find((x) => x.id === orderForFilter);
  let invoiceItemsLinkedToOrder = 0;

  invoiceItems.forEach((x) => {
    if (x.orderLinkId === orderForFilter) {
      invoiceItemsLinkedToOrder += 1;
    }
  });

  const handleUnlinkOrder = () => {
    handleCloseMenu();
    unlinkOrder(orderForFilter);
  };

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "40px",
              backgroundColor: (theme) =>
                orderForFilter === null
                  ? theme.palette.terrain[200]
                  : orderColorMap[orderForFilter]?.primary,
              borderBottomLeftRadius: 2,
              borderBottomRightRadius: 2,
              borderTopRightRadius: 2,
            }}
          >
            <Grid container>
              <Grid item xs={11} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 700,
                    fontSize: 16,
                    marginLeft: 2,
                    color: (theme) => theme.palette.terrain[600],
                  }}
                >
                  {orderForFilter === null
                    ? `Showing ${invoiceItemsLinkedToOrder} unlinked line items`
                    : `${invoiceItemsLinkedToOrder} out of ${orderForDisplay?.lineItems?.length} products linked from this order`}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton sx={{ background: "none" }} onClick={handleClick}>
                  <MoreHorizOutlinedIcon
                    sx={{ color: (theme) => theme.palette.primary[800] }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        <MenuItem sx={{ margin: "5px" }} onClick={handleUnlinkOrder}>
          <UndoOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
          <Typography variant="body1">Unlink Order</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default OrderFilterBanner;
