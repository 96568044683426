import React, { useState, useEffect, useContext } from "react";
import {
  getUserAdminEstablishments,
  createEstablishment,
} from "../../clients/establishmentsClient.js";
import Footer from "../../components/Footer";
import Header from "../../components/Header/index";
import EstablishmentOption from "./components/EstablishmentOption/index.js";
import NewEstablishmentModal from "../../components/Modals/NewEstablishmentModal";
import ReactGA from "react-ga";
import Store from "../../Store/Store.js";
import { Grid, Box, Typography, CircularProgress, Button } from "@mui/material";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { SplitTreatments } from "@splitsoftware/splitio-react";
import SpecSpinner from "../../components/SpecSpinner/index.js";
import inventoryClient from "../../clients/inventoryClient.js";
import { useQueryClient } from "react-query";
import useUserProfile from "../../hooks/useUserProfile.js";
import EstablishmentLoginHelpModal from "../../components/Modals/EstablishmentLoginHelpModal/index.js";

const spinnerWrapperStyles = {
  display: "flex",
  justifyContent: "center",
  margin: "6em",
};

const AddIconComponent = () => {
  return (
    <Box
      sx={{
        borderRadius: "100%",
        height: "60px",
        width: "60px",
        border: "2px solid",
        borderColor: (theme) => theme.palette.terrain["03"],
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AddIcon style={{ fontSize: "24px" }} />
    </Box>
  );
};

const Establishments = () => {
  const store = useContext(Store);
  const navigate = useNavigate();
  const [loading, toggleLoading] = useState(true);
  const [addingEstablishment, toggleAddingEstablishment] = useState(false);
  const [choices, updateChoices] = useState([]);
  const [loadEstablishmentsError, toggleEstError] = useState(false);
  const [showCreateNew, toggleShowCreateNew] = useState(false);
  const [creatingFirstEst, toggleCreatingFirstEst] = useState(false);
  const [showHelpModal, toggleHelpModal] = useState(false);
  const [newEstablishment, updateNewEstablishment] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    website: "",
    inventorySettings: {
      timezone: "",
      currencyType: "",
      locale: "",
    },
  });
  const queryClient = useQueryClient();
  const { data: userProfileData, isLoading: isLoadingUserProfile } =
    useUserProfile();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const loadAdminEstablishments = async () => {
      try {
        const response = await getUserAdminEstablishments();
        updateChoices([
          ...response.sort(function (a, b) {
            var textA = a.name;
            var textB = b.name;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }),
          {
            name: "Create New",
            ImageComponent: AddIconComponent,
            id: -1,
          },
        ]);

        if (response.length === 0) {
          toggleCreatingFirstEst(true);
        }
        store.updateEstablishmentOptions(response);
        toggleLoading(false);
      } catch (err) {
        console.log(err);
        toggleEstError(true);
        toggleLoading(false);
      }
    };

    loadAdminEstablishments();
  }, []);

  const handleChoiceSelection = async (establishment, treatments) => {
    const estId = establishment.id;
    const { treatment } = treatments;
    if (estId === -1) {
      toggleShowCreateNew(true);
    } else {
      store.updateCurrentEstablishment(establishment);

      if (treatment === "on") {
        navigate(`/spec/${estId}/dashboard`);
      } else {
        navigate(`/homebase/${estId}/menus`);
      }
    }
  };

  const handleCreationUpdate = (prop, value) => {
    const estCopy = Object.assign({}, newEstablishment);
    estCopy[prop] = value;
    updateNewEstablishment(estCopy);
  };

  const handleCreateEstablishment = async (newEst) => {
    try {
      toggleAddingEstablishment(true);
      const response = await createEstablishment(newEst);
      await inventoryClient.seedInventory(
        response.id,
        newEst.inventorySettings
      );
      queryClient.invalidateQueries({
        queryKey: ["my-memberships"],
      });
      queryClient.refetchQueries({
        queryKey: ["my-memberships"],
      });
      store.updateCurrentEstablishment(response);
      store.updateEstablishmentOptions([...store.establishments, response]);
      toggleAddingEstablishment(false);
      navigate(`/homebase/${response.id}`);
    } catch (err) {
      console.log(err);
      toggleAddingEstablishment(false);
    }
  };

  const handleModalToggle = () => {
    toggleShowCreateNew(!showCreateNew);
  };

  if (isLoadingUserProfile) {
    return <SpecSpinner open />;
  }

  if (userProfileData?.profile === null) {
    navigate("/create-profile");
  }

  return (
    <>
      <SplitTreatments names={["enable-inv-mgmt"]}>
        {({ treatments, isReady }) => {
          // Passes down a TreatmentsWithConfig object and SplitContext properties like the boolean `isReady` flag.
          return isReady ? (
            <>
              <Box sx={{ minHeight: "100%" }}>
                <Header />
                <Box>
                  {loadEstablishmentsError && (
                    <Box>
                      There was an error trying to load your establishments.
                      Please try refreshing
                    </Box>
                  )}
                  {loading ? (
                    <Box>
                      <Box>
                        <Grid container justify="center">
                          <Grid item xs={12} sm={12} md={4}>
                            <Box sx={spinnerWrapperStyles}>
                              <CircularProgress color="primary" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          display: "flex",
                          margin: "auto",
                          minHeight: "600px",
                          maxWidth: "2440px",
                        }}
                      >
                        <Grid
                          sx={{
                            width: "100%",
                            padding: 4,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Typography
                              variant="h2"
                              sx={{
                                fontSize: { xs: 14, md: 28 },
                                lineHeight: { xs: 1.5, md: 4 },
                              }}
                            >
                              Select Your Establishment:
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            {/* New Box to group the buttons */}
                            <Button
                              variant="outlined"
                              sx={{
                                height: "40px",
                                marginRight: { xs: 1, md: 2 },
                              }}
                              onClick={() => toggleHelpModal(true)}
                            >
                              <Typography
                                variant="largeButton"
                                sx={{
                                  fontSize: { xs: 12, md: 16 },
                                  lineHeight: { xs: 1.25, md: 2 },
                                }}
                              >
                                Need Help?
                              </Typography>
                            </Button>
                            <Button
                              variant="contained"
                              sx={{ height: "40px" }}
                              onClick={() => toggleShowCreateNew(true)}
                            >
                              <Typography
                                variant="largeButton"
                                sx={{
                                  fontSize: { xs: 12, md: 16 },
                                  lineHeight: { xs: 1.25, md: 2 },
                                }}
                              >
                                Create New Establishment
                              </Typography>
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          columns={{ xs: 6, sm: 9, md: 12, lg: 15, xl: 15 }}
                          sx={{
                            display: "flex",
                            maxWidth: "2440px",
                            paddingLeft: 10,
                          }}
                        >
                          {choices.map((c) => (
                            <Grid
                              item
                              key={c.id}
                              xs={3}
                              md={3}
                              lg={3}
                              xl={2.5}
                              sx={{
                                display: "flex",
                                paddingRight: 4,
                                marginBottom: 12,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleChoiceSelection(
                                  c,
                                  treatments["enable-inv-mgmt"]
                                )
                              }
                            >
                              <EstablishmentOption
                                key={c.id}
                                id={c.id}
                                establishmentName={c.name}
                                imageSrc={c.imageURL}
                                ImageComponent={c.ImageComponent}
                                imgAlt={c.name}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  <NewEstablishmentModal
                    showCreateNew={showCreateNew}
                    handleCreateEstablishment={handleCreateEstablishment}
                    handleCreationUpdate={handleCreationUpdate}
                    newEstablishment={newEstablishment}
                    handleModalToggle={handleModalToggle}
                    loading={addingEstablishment}
                    creatingFirstEst={creatingFirstEst}
                  />
                  <EstablishmentLoginHelpModal
                    isOpen={showHelpModal}
                    toggleModalOpen={toggleHelpModal}
                  />
                </Box>
              </Box>
              <Footer />
            </>
          ) : (
            <SpecSpinner open text="Loading..." />
          );
        }}
      </SplitTreatments>
    </>
  );
};

export default Establishments;
