import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import SpecBackButton from "../../../../components/SpecBackButton";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const ReviewInvoice = ({ goBack }) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: 6,
      }}
    >
      <Box>
        <SpecBackButton backText="Invoices" onClick={() => goBack()} />
      </Box>
      <Grid container direction="column" sx={{ width: "100%", marginTop: 6 }}>
        <Grid item>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h2">Invoice Details</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton
                variant="outlined"
                sx={{
                  width: "40px",
                  height: "40px",
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.primary[800],
                }}
              >
                <MoreHorizOutlinedIcon
                  sx={{ color: (theme) => theme.palette.primary[800] }}
                />
              </IconButton>
              <Button variant="outlined" sx={{ marginLeft: 2 }}>
                <EditOutlinedIcon sx={{ fontSize: 18, marginRight: 1 }} />
                <Typography variant="largeButton">Edit</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item sx={{ marginTop: 6 }}>
            <Typography variant="subtitle2">
              Etiam porta sem malesuada magna mollis euismod. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Etiam porta sem malesuada magna mollis euismod. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            </Typography>
          </Grid>
          <Box
            sx={{
              height: "150px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              border: "2px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              backgroundColor: (theme) => theme.palette.pure.white,
              borderRadius: 2,
              marginTop: 6,
            }}
          >
            <Grid
              container
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: 4,
              }}
            >
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="h2">01/01/2022</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">Lipman Brothers</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Typography variant="h2">1234567890</Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Typography variant="subtitle2">
                          Delivered: 01/03/2023
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ marginLeft: 4 }}>
                    <Avatar sx={{ height: "58px", width: "58px" }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: 4,
              }}
            >
              <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 700, marginRight: 1 }}
                >
                  Invoice Total:
                </Typography>
                <Typography variant="subtitle2">$888,888.888</Typography>
              </Grid>
              <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 700, marginRight: 1 }}
                >
                  Order Total:
                </Typography>
                <Typography variant="subtitle2">$888,888.888</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              border: "2px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              backgroundColor: (theme) => theme.palette.pure.white,
              borderRadius: 2,
              marginTop: 6,
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                padding: 4,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.terrain[600] }}
                >
                  Products
                </Typography>
              </Grid>
              <Grid item>
                {" "}
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.terrain[600] }}
                >
                  $888,888.88
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: 4,
              }}
            >
              <Grid item sx={{ width: "45%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    height: "50px",
                    border: "2px solid",
                    borderRadius: 2,
                    borderColor: (theme) => theme.palette.terrain[300],
                  }}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h5">Product Name</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.terrain[600] }}
                      >
                        Sizing (SKU Number)
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item sx={{ width: "24%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    height: "50px",
                    border: "2px solid",
                    borderRadius: 2,
                    borderColor: (theme) => theme.palette.terrain[300],
                  }}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h5">Subcategory</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.terrain[600] }}
                      >
                        Category
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item sx={{ width: "14%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    height: "50px",
                    border: "2px solid",
                    borderRadius: 2,
                    borderColor: (theme) => theme.palette.terrain[300],
                  }}
                >
                  <Grid item>
                    <Typography variant="h5">x88 Cases</Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid item sx={{ width: "14%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    height: "50px",
                    border: "2px solid",
                    borderRadius: 2,
                    borderColor: (theme) => theme.palette.terrain[300],
                  }}
                >
                  <Grid item>
                    <Typography variant="h5">$888,888.88</Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              border: "2px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              backgroundColor: (theme) => theme.palette.pure.white,
              borderRadius: 2,
              marginTop: 6,
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                padding: 4,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.terrain[600] }}
                >
                  Keg Transfers
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.terrain[600] }}
                >
                  $888,888.88
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: 4,
              }}
            >
              <Grid item sx={{ width: "84%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    height: "50px",
                    border: "2px solid",
                    borderRadius: 2,
                    borderColor: (theme) => theme.palette.terrain[300],
                  }}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h5">
                        Keg Transfer Name / Extra Info
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.terrain[600] }}
                      >
                        At Cost / In Credit
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item sx={{ width: "14%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    height: "50px",
                    border: "2px solid",
                    borderRadius: 2,
                    borderColor: (theme) => theme.palette.terrain[300],
                  }}
                >
                  <Grid item>
                    <Typography variant="h5">$888,888.88</Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              border: "2px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              backgroundColor: (theme) => theme.palette.pure.white,
              borderRadius: 2,
              marginTop: 6,
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                padding: 4,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.terrain[600] }}
                >
                  Miscellaneous
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.terrain[600] }}
                >
                  $888,888.88
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: 4,
              }}
            >
              <Grid item sx={{ width: "84%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    height: "50px",
                    border: "2px solid",
                    borderRadius: 2,
                    borderColor: (theme) => theme.palette.terrain[300],
                  }}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="h5">
                        Miscellaneous / Details
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.terrain[600] }}
                      >
                        At Cost / In Credit
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item sx={{ width: "14%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 2,
                    height: "50px",
                    border: "2px solid",
                    borderRadius: 2,
                    borderColor: (theme) => theme.palette.terrain[300],
                  }}
                >
                  <Grid item>
                    <Typography variant="h5">$888,888.88</Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              border: "2px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              backgroundColor: (theme) => theme.palette.pure.white,
              borderRadius: 2,
              marginTop: 6,
            }}
          >
            <Grid
              container
              direction="column"
              sx={{
                padding: 4,
                display: "flex",
              }}
            >
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.terrain[600] }}
                >
                  Additional Info
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: 2 }}>
                <Typography variant="body1">
                  Donec sed odio dui. Cum sociis natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewInvoice;
