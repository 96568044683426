import {
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

const DropDownBadgeSelector = ({
  options,
  selectCallback,
  selectedId,
  selected,
  extractDisplayFunction,
  isNotAvailable,
  menuItemRenderFunction,
}) => {
  const [editing, toggleEditing] = useState(false);

  useEffect(() => {
    toggleEditing(false);
  }, [selectedId]);

  const handleSelect = async (value) => {
    await selectCallback(value);
  };

  // return a empty grid item since we have to embed the grid inside of here
  if (isNotAvailable) {
    return (
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40px",
          backgroundColor: (theme) => theme.palette.pure.white,
          borderColor: (theme) => theme.palette.terrain[400],
          borderRadius: 1,
          padding: 0,
        }}
      ></Grid>
    );
  }

  if (editing || selectedId === null || selectedId === undefined) {
    return (
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40px",
          padding: 0,
        }}
      >
        <TextField
          size="small"
          select
          fullWidth
          variant="filled"
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            height: "100%",
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              height: "40px",
              paddingBottom: 4,
              paddingTop: -4,
            },
          }}
          InputProps={{ disableUnderline: true, height: "100%" }}
          value={selectedId || ""}
          onChange={(e) => handleSelect(e.target.value)}
        >
          {options?.map((x) => (
            <MenuItem
              key={x.id}
              value={x.id}
              onClick={() => toggleEditing(false)}
            >
              {menuItemRenderFunction ? menuItemRenderFunction(x) : x}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  }

  return (
    <Grid
      item
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
        backgroundColor: (theme) => theme.palette.pure.white,
        padding: 2,
      }}
    >
      <Typography variant="body1">
        {extractDisplayFunction(selected, selectedId, options)}
      </Typography>
      <IconButton
        sx={{ backgroundColor: "transparent" }}
        onClick={() => toggleEditing(true)}
      >
        <EditIcon
          fontSize="small"
          sx={{ color: (theme) => theme.palette.primary[800] }}
        />
      </IconButton>
    </Grid>
  );
};

export default DropDownBadgeSelector;
