import { Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import Store from "../../../../Store/Store";
import utilFunctions from "../../../../utilities/utilFunctions";
import useEstablishmentSettings from "../../../../hooks/useEstablishmentSettings";

const creditExpenseOperators = {
  0: function (total, val) {
    return total - val;
  },
  1: function (total, val) {
    return total + val;
  },
};

const depositReturnOperators = {
    0: function (total, val) {
      return total - val;
    },
    1: function (total, val) {
      return total + val;
    },
  };

// Used for sub sections displaying total cost, such as misc item totals or keg transfer totals
const TotalCostBadge = ({ displayText, lineItems, isKeg, isMisc }) => {
  const [costDisplay, setCostDisplay] = useState(0);
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(store.currentEstablishment?.id);
  const { locale, currency, currencySymbol } = useVendorCurrency(settings?.inventorySettings?.currency);

  useEffect(() => {
    let total = 0;
    lineItems.forEach((x) => {
      if (isKeg) {
        total = creditExpenseOperators[x.type || 0](total, x?.cost?.amount);
      } else if(isMisc) {
        total = depositReturnOperators[x?.type || 0](total, x.cost.amount);
      } else {
        let amountMultiplied = x?.cost?.amount;
        total += amountMultiplied;
      }
    });
    setCostDisplay(
      utilFunctions.convertCentsToLargerFormatCurrency(total, locale, currency)
    );
  }, [lineItems]);
  return (
    <>
      <Typography variant="body1">{displayText}</Typography>
      <Typography variant="body2">
        {currencySymbol}
        {costDisplay}
      </Typography>
    </>
  );
};

export default TotalCostBadge;
