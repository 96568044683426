import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const CTABlock = ({
  handleCTA,
  header,
  subheader,
  buttonText,
  ButtonImage,
  hideButton,
}) => (
  <Grid
    container
    direction="column"
    sx={{
      backgroundColor: (theme) => theme.palette.terrain[100],
      padding: 12,
      borderRadius: 2,
    }}
  >
    <Grid
      item
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      xs={12}
    >
      <Typography variant="h3">{header}</Typography>
    </Grid>
    <Grid
      item
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      xs={12}
    >
      <Typography variant="subtitle2" sx={{ marginTop: 3, marginBottom: 3 }}>
        {subheader}
      </Typography>
    </Grid>
    <Grid
      item
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      xs={12}
    >
      {!hideButton && (
        <Button
          variant="contained"
          sx={{
            minWidth: "160px",
            minHeight: "40px",
            borderRadius: 2,
          }}
          onClick={handleCTA}
        >
          {ButtonImage && (
            <ButtonImage sx={{ fontSize: "20px", marginRight: 1 }} />
          )}
          <Typography variant="largeButton">{buttonText}</Typography>
        </Button>
      )}
    </Grid>
  </Grid>
);

export default CTABlock;
