import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import DescriptionToolTip from "../../../../../components/DescriptionToolTip";

const AdditionalOptions = ({ allowUnspecified, toggleAllowUnspecified, includeSumRow, toggleIncludeSumRow }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox
          checked={allowUnspecified}
          onChange={() => toggleAllowUnspecified(!allowUnspecified)}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Allow Unspecified
        </Typography>
        <DescriptionToolTip
          header="Allow Products with Unspecified Expense Groups"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Whether or not to include a line item for invoice line items
                with an unspecified values as their Expense Group or Subgroup
                when filtering on Expense Group or Subgroup. This option has no
                effect if you choose not to filter by expense groups for this
                report.
              </Box>
            </Box>
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox checked={includeSumRow} onChange={() => toggleIncludeSumRow(!includeSumRow)} />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Include All Sum Row
        </Typography>
        <DescriptionToolTip
          header="Include All Sum Row"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Include a row at the top of the entire report showing the sum of
                all values.
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default AdditionalOptions;
