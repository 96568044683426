import React from "react";
import { Grid, Typography } from "@mui/material";
import SpecBackButton from "../../../components/SpecBackButton";
import VendorOrderCard from "./VendorOrderCard";

const VendorOrders = () => {
  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ width: "100%", maxWidth: "2440px", padding: 6 }}
      >
        <Grid item sx={{ marginBottom: 6 }}>
          <SpecBackButton backText="Place New Order" />
        </Grid>
        <Grid item sx={{ marginBottom: 6 }}>
          <Typography variant="h2">Your Orders</Typography>
        </Grid>
        <Grid item sx={{ marginBottom: 6 }}>
          <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
            Below are a list of your orders, grouped by specific vendor.
            Directly build an order into your email, or copy and paste the
            content to your preferred process of placing an order, such as
            texting your rep. These orders will now live separately in your
            order history list.
          </Typography>
        </Grid>
        <VendorOrderCard />
      </Grid>
    </>
  );
};

export default VendorOrders;
