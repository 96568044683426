import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useRecipe(establishmentId, recipeId) {
    const queryReturnValues = useQuery(
        ["recipes", { establishmentId, recipeId }],
        inventoryClient.getRecipeDetails
    );

   return queryReturnValues;
}

export default useRecipe;