import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import utilFunctions from "../../../utilities/utilFunctions";
import MenuTableCellBadge from "./MenuTableCellBadge";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { cloneDeep } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import { useNavigate, useParams } from "react-router-dom";
import Store from "../../../Store/Store";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import useEstablishment from "../../../hooks/useEstablishment";
import ServingSizeToggle from "./ServingSizeToggle";
import MissingDataPointsTooltip from "../../../components/MissingDataPointsTooltip";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";

const tableCellStyle = {
  padding: 0,
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const MenuItemRow = ({
  openServingSize,
  item,
  updateMenuItem,
}) => {
  const [menuCostDisplay, setMenuCostDisplay] = useState(null);
  const [isUpdatingMenuPrice, toggleIsUpdatingMenuPrice] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [itemCopy, setItemCopy] = useState(null);
  const queryClient = useQueryClient();
  const { establishmentid, menuid } = useParams();
  const navigate = useNavigate();
  const store = useContext(Store);
  // calling this to ensure establishmentData is populated in the store;
  useEstablishment(establishmentid, store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  useEffect(() => {
    setItemCopy(cloneDeep(item));
      setMenuCostDisplay(
        utilFunctions.convertCentsToLargerFormatCurrency(
          item.menuPrice.amount,
          locale,
          currency
        )
      );
  }, [item]);

  const extractName = () => {
    if (item.recipeId !== null) {
      return item?.name;
    } else {
      let sizeInfo = utilFunctions.extractFormatSizingName(item?.productFormat);
      let name = `${item.name} ${sizeInfo}`;
      return name;
    }
  };

  const navigateToItem = () => {
    if (item.recipeId !== null) {
      navigate(`/spec/${establishmentid}/recipes/${item.recipeId}`);
    } else {
      navigate(`/spec/${establishmentid}/productlibrary`);
    }
  };

  const handleAnchorClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const removeMenuItemMutation = useMutation(inventoryClient.removeMenuItem, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "menu",
          {
            establishmentId: establishmentid,
            invoiceId: menuid,
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "menu",
          {
            establishmentId: establishmentid,
            menuId: menuid,
          },
        ],
      });
    },
  });

  const handleRemoveItem = async () => {
    setAnchorEl(null);
    await removeMenuItemMutation.mutateAsync({
      establishmentId: establishmentid,
      menuItemId: item.id,
    });
  };

  const handleOpenServingSizeModal = () => {
    openServingSize(itemCopy);
  };

  const handleUpdateMenuPrice = async () => {
    toggleIsUpdatingMenuPrice(true);
    const newAmount = utilFunctions.convertCurrencyToCents(menuCostDisplay);
    await updateMenuItem(
      "menuPrice",
      Object.assign({}, itemCopy.menuPrice, { amount: newAmount }),
      item.id
    );
    setMenuCostDisplay(
      utilFunctions.convertCentsToLargerFormatCurrency(
        newAmount,
        locale,
        currency
      )
    );
    toggleIsUpdatingMenuPrice(false);
  };

  const updateMarkUpCopy = (e) => {
    const copiedItem = Object.assign({}, itemCopy);
    copiedItem.markUp = e.target.value;
    setItemCopy(copiedItem);
  };

  const handleMarkUpUpdate = async () => {
    await updateMenuItem("markUp", itemCopy.markUp, item.id);
  };

  const costAnalysis = itemCopy?.costAnalysis;
  return (
    <TableRow>
      <TableCell sx={[tableCellStyle, { borderLeft: "none" }]}>
        <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Typography
            variant="body2"
            noWrap
            sx={{
              fontSize: 12,
              fontWeight: 700,
              color: (theme) => theme.palette.primary[800],
              cursor: "pointer",
            }}
            onClick={navigateToItem}
          >
            <div
              style={{
                maxWidth: "160px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {costAnalysis?.isValid ? null : (
                <MissingDataPointsTooltip
                  missingValues={costAnalysis?.missingData}
                />
              )}
              {extractName()}
            </div>
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={tableCellStyle}>
        {" "}
        <ServingSizeToggle
          menuItem={itemCopy}
          openServingSizeModal={handleOpenServingSizeModal}
        />
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <MenuTableCellBadge
            id="ingredientCost"
            prefixAdornment={costAnalysis?.isValid ? currencySymbol : ""}
            displayValue={
              costAnalysis?.isValid
                ? utilFunctions.convertCentsToLargerFormatCurrency(
                    costAnalysis?.ingredientCost?.result?.amount,
                    locale,
                    currency
                  )
                : null
            }
          />
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <MenuTableCellBadge
            id="laborCost"
            prefixAdornment={costAnalysis?.isValid ? currencySymbol : ""}
            displayValue={
              costAnalysis?.isValid
                ? utilFunctions.convertCentsToLargerFormatCurrency(
                    costAnalysis?.laborCost?.result?.amount,
                    locale,
                    currency
                  )
                : null
            }
          />
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <MenuTableCellBadge
            id="totalCost"
            prefixAdornment={costAnalysis?.isValid ? currencySymbol : ""}
            displayValue={
              costAnalysis?.isValid
                ? utilFunctions.convertCentsToLargerFormatCurrency(
                    costAnalysis?.totalCost?.result?.amount,
                    locale,
                    currency
                  )
                : null
            }
          />
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <>
            {" "}
            <TextField
              size="small"
              variant="filled"
              id="markUp"
              value={itemCopy?.markUp}
              onKeyDown={utilFunctions.blurInput}
              onFocus={utilFunctions.highlightInputOnFocus}
              InputProps={{
                disableUnderline: true,
                inputMode: "numeric",
                startAdornment: (
                  <InputAdornment
                    sx={{ marginTop: 4, marginLeft: 2 }}
                    position="start"
                  >
                    x
                  </InputAdornment>
                ),
              }}
              onBlur={handleMarkUpUpdate}
              onChange={updateMarkUpCopy}
              sx={{
                "& .MuiFilledInput-root": {
                  background: (theme) => theme.palette.pure.white,
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingBottom: 4,
                  borderBottom: 0,
                },
              }}
            />{" "}
          </>
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <MenuTableCellBadge
            id="reccMenuPrice"
            prefixAdornment={costAnalysis?.isValid ? currencySymbol : ""}
            displayValue={
              costAnalysis?.isValid
                ? utilFunctions.convertCentsToLargerFormatCurrency(
                    costAnalysis?.recommendedMenuPrice?.result?.amount,
                    locale,
                    currency
                  )
                : null
            }
          />
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <TextField
            size="small"
            variant="filled"
            id="menuPrice"
            on
            placeholder="$0.00"
            onBlur={handleUpdateMenuPrice}
            disabled={isUpdatingMenuPrice}
            onChange={(e) => setMenuCostDisplay(e.target.value)}
            onKeyDown={utilFunctions.blurInput}
            onFocus={utilFunctions.highlightInputOnFocus}
            InputProps={{
              disableUnderline: true,
              inputMode: "numeric",
              startAdornment: (
                <InputAdornment
                  sx={{ marginTop: 4, marginLeft: 2 }}
                  position="start"
                >
                  {currencySymbol}
                </InputAdornment>
              ),
            }}
            value={menuCostDisplay}
            sx={{
              "& .MuiFilledInput-root": {
                background: (theme) => theme.palette.pure.white,
                paddingTop: 0,
                paddingLeft: 0,
                paddingBottom: 4,
              },
            }}
            // onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
          />
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <MenuTableCellBadge
            id="targetSpread"
            prefixAdornment={costAnalysis?.isValid ? currencySymbol : ""}
            displayValue={
              costAnalysis?.isValid
                ? utilFunctions.convertCentsToLargerFormatCurrency(
                    costAnalysis?.targetSpread?.result?.amount,
                    locale,
                    currency
                  )
                : null
            }
          />
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <MenuTableCellBadge
            id="ingredientPercentage"
            suffixAdornment={"%"}
            displayValue={
              costAnalysis?.isValid
                ? (costAnalysis?.ingredientPercentage * 100).toFixed(2)
                : null
            }
          />
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <MenuTableCellBadge
            id="laborPercentage"
            suffixAdornment={"%"}
            displayValue={
              costAnalysis?.isValid
                ? (costAnalysis?.laborPercentage * 100).toFixed(2)
                : null
            }
          />
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <MenuTableCellBadge
            id="totalCostPercentage"
            suffixAdornment={"%"}
            displayValue={
              costAnalysis?.isValid
                ? (
                    costAnalysis?.laborPercentage * 100 +
                    costAnalysis?.ingredientPercentage * 100
                  ).toFixed(2)
                : null
            }
          />
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <MenuTableCellBadge
            id="netProfit"
            prefixAdornment={currencySymbol}
            displayValue={
              costAnalysis?.isValid
                ? utilFunctions.convertCentsToLargerFormatCurrency(
                    costAnalysis?.netProfit?.result?.amount,
                    locale,
                    currency
                  )
                : null
            }
          />
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            borderRight: "none",
          },
        ]}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IconButton
            onClick={handleAnchorClick}
            sx={{
              margin: 0,
              "&:hover": {
                color: (theme) => theme.palette.terrain[300],
                backgroundColor: "transparent",
              },
            }}
          >
            <MoreHorizIcon
              sx={{
                color: (theme) => theme.palette.primary[800],
                "&:hover": { color: (theme) => theme.palette.terrain[300] },
              }}
            />
          </IconButton>
        </Box>
      </TableCell>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={handleRemoveItem}
          sx={{
            margin: "5px",
            "&:hover": {
              color: (theme) => theme.palette.pure.white,
              backgroundColor: (theme) => theme.palette.terrain[500],
            },
          }}
        >
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
    </TableRow>
  );
};

export default MenuItemRow;
