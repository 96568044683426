import React from "react";
import { modalBoxStyle } from "../styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const EstablishmentLoginHelpModal = ({ isOpen, toggleModalOpen }) => {
  const closeModal = () => {
    toggleModalOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        <Grid
          container
          direction="column"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={8}>
              <Typography variant="h2">
                Not Seeing the Establishment You Expect?
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={closeModal}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
              marginTop: 4,
            }}
          >
            <Typography variant="h5">
              There are two common reasons why you might not be seeing an
              establishment:
            </Typography>
            <List
              sx={{ width: "100%", maxWidth: 560, bgcolor: "background.paper" }}
            >
              <ListItem>
                <Typography variant="body1">
                  <b>1. Make sure you signed in with the correct account.</b> It
                  {"'"}s easy to accidentally choose a different account than
                  you created the establishment with, or that you used to join
                  an establishment on your phone. Make sure the account you used
                  is the one you{"'"}re signed into now.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  <b>2. Make sure you have the correct permissions.</b> When
                  users first join an establishment, they don{"'"}t have access
                  to view the Spec Dashboard, they can only see the shared
                  content in the mobile app. If an admin added you and wants you
                  to do work in the Spec Dashboard, make sure they have given
                  you Admin permissions in their establishment.
                </Typography>
              </ListItem>
            </List>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "300px",
                height: "40px",
                borderRadius: 2,
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 6,
                paddingRight: 6,
              }}
              onClick={closeModal}
            >
              <Typography variant="largeButton">Got it!</Typography>
            </Button>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EstablishmentLoginHelpModal;
