import { SvgIcon } from "@mui/material";
import React from "react";

const CalculatorIconCrossed = (props) => {
  return (
    <SvgIcon
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M21 2.4375V18.1224L19.125 16.2542V2.4375C19.125 2.12684 18.8732 1.875 18.5625 1.875H5.43751C5.23592 1.875 5.05909 1.98105 4.95978 2.14041L3.62367 0.809156C4.0699 0.312426 4.71722 0 5.43751 0H18.5625C19.9087 0 21 1.09131 21 2.4375Z" />
      <path d="M17.9798 15.1131C17.867 14.3888 17.2935 13.817 16.5683 13.7068L17.9798 15.1131Z" />
      <path d="M7.68751 13.6875C6.75553 13.6875 6.00001 14.443 6.00001 15.375C6.00001 16.307 6.75553 17.0625 7.68751 17.0625C8.61949 17.0625 9.37501 16.307 9.37501 15.375C9.37501 14.443 8.61949 13.6875 7.68751 13.6875Z" />
      <path d="M6.00001 19.6875C6.00001 18.7555 6.75553 18 7.68751 18C8.61949 18 9.37501 18.7555 9.37501 19.6875C9.37501 20.6195 8.61949 21.375 7.68751 21.375C6.75553 21.375 6.00001 20.6195 6.00001 19.6875Z" />
      <path d="M12 18C11.068 18 10.3125 18.7555 10.3125 19.6875C10.3125 20.6195 11.068 21.375 12 21.375C12.932 21.375 13.6875 20.6195 13.6875 19.6875C13.6875 18.7555 12.932 18 12 18Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4053 23.158C19.9584 23.6738 19.2986 24 18.5625 24H5.43751C4.09132 24 3.00001 22.9087 3.00001 21.5625V5.81593L0.00708008 2.83385L1.39529 1.44058L22.6289 22.597L21.2407 23.9903L20.4053 23.158ZM14.625 17.3987L13.454 16.232C13.1604 16.7291 12.6192 17.0625 12 17.0625C11.068 17.0625 10.3125 16.307 10.3125 15.375C10.3125 14.7581 10.6436 14.2184 11.1378 13.9241L9.13568 11.9293C8.84076 12.421 8.30256 12.75 7.68751 12.75C6.75553 12.75 6.00001 11.9945 6.00001 11.0625C6.00001 10.4496 6.32671 9.91309 6.81546 9.6175L4.87501 7.68411V21.5625C4.87501 21.8732 5.12685 22.125 5.43751 22.125H18.5625C18.7803 22.125 18.9692 22.0012 19.0626 21.8202L17.7723 20.5346C17.4801 21.0371 16.9358 21.375 16.3125 21.375C15.3805 21.375 14.625 20.6195 14.625 19.6875V17.3987Z"
      />
      <path d="M13.6701 10.8191C13.5633 10.0799 12.9774 9.49574 12.2374 9.39156L13.6701 10.8191Z" />
      <path d="M17.25 7.875H10.7153L8.83345 6H16.125V4.5H7.87501V5.04505L6.02106 3.19783C6.10067 2.86912 6.39683 2.625 6.75001 2.625H17.25C17.6642 2.625 18 2.96079 18 3.375V7.125C18 7.53921 17.6642 7.875 17.25 7.875Z" />
      <path d="M14.625 11.0625C14.625 10.1305 15.3805 9.375 16.3125 9.375C17.2445 9.375 18 10.1305 18 11.0625C18 11.9945 17.2445 12.75 16.3125 12.75C15.3805 12.75 14.625 11.9945 14.625 11.0625Z" />
    </SvgIcon>
  );
};

export default CalculatorIconCrossed;
