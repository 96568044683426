import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { productSizeTypes } from "../../../clients/inventoryObjects";
import utilFunctions from "../../../utilities/utilFunctions";

const ServingSizeToggle = ({ openServingSizeModal, menuItem }) => {
  if (
    menuItem?.servingSize?.unitOfMeasurement === null &&
    menuItem?.servingSize?.type !== productSizeTypes.Arbitrary
  ) {
    return (
      <Button variant="text" onClick={() => openServingSizeModal()}>
        <Typography
          variant="smallButton"
          sx={{ color: (theme) => theme.palette.primary[800], fontSize: 12 }}
        >
          + Serving Size
        </Typography>
      </Button>
    );
  }

  return (
    <Box
      sx={{
        height: "20px",
        backgroundColor: (theme) => theme.palette.info[600],
        borderRadius: 1,
        padding: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "fit-content",
        marginLeft: 2,
        cursor: "pointer",
      }}
      onClick={() => openServingSizeModal()}
    >
      <Typography
        variant="overline"
        sx={{
          color: (theme) => theme.palette.pure.white,
          fontWeight: 700,
        }}
      >
        {menuItem?.servingSize?.size || ""}{" "}
        {utilFunctions.extractServingSizeAbbreviation(menuItem?.servingSize)}
      </Typography>
    </Box>
  );
};

export default ServingSizeToggle;
