import { Grid } from "@mui/material";
import React from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CTABlock from "../../../components/CTABlock";
import NewOrderProductCard from "./NewOrderProductCard";

const stockCount = [0];

const NewOrderTable = ({
  toggleAddingStockCount,
  productLibrary,
  setMissingDetailsData,
  vendorFilter,
}) => {

  if (stockCount.length > 0)
    return (
      <>
        <Grid container spacing={2} direction="row" sx={{ minWidth: "100%" }}>
          {productLibrary.map((x) => (
            <NewOrderProductCard
              key={x.id}
              product={x}
              setMissingDetailsData={setMissingDetailsData}
              vendorFilter={vendorFilter}
            />
          ))}
        </Grid>
      </>
    );
  return (
    <CTABlock
      header="You have not performed any stock counts yet... "
      subheader="You have no counts available for this period. Any active reports will appear above."
      buttonText="New Count"
      ButtonImage={AddOutlinedIcon}
      handleCTA={() => toggleAddingStockCount(true)}
    />
  );
};

export default NewOrderTable;
