import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { FormControl, MenuItem, Radio, Typography } from "@mui/material";

const defaultUomOption = { name: "Select Unit of Measurement", id: -1 };

const MassSizeCard = ({
  unitSize,
  updateUnitSize,
  unitsOfMeasurement,
  changeDefaultSize,
}) => {
  const { register, watch } = useForm({ mode: "all", defaultValues: unitSize });
  const [uomOptions, setUomOptions] = useState([defaultUomOption]);

  useEffect(() => {
    const regularUoms = unitsOfMeasurement.filter((x) => !x.isKegSize);
    setUomOptions([defaultUomOption, ...regularUoms]);
  }, [unitsOfMeasurement]);

  const handleMassQuantityUpdate = () => {
    const newData = Object.assign({}, unitSize, { size: watch("size") });
    updateUnitSize(newData);
  };

  const handleUoMChange = (e) => {
    let newData = Object.assign({}, unitSize, {
      unitOfMeasurement: e.target.value,
    });

    updateUnitSize(newData);
  };

  const handleChangeDefault = () => {
    if (!unitSize.isDefaultSize) {
      changeDefaultSize(unitSize.type);
    }
  };

  const FormInputLabelStyles = {
    shrink: true,
  };

  return (
    <Grid container direction="row" sx={{ padding: 2 }}>
      <Grid item xs={2}>
        {Boolean(unitSize?.isDefaultSize) && (
          <Typography sx={{ paddingLeft: "3px" }}>Primary</Typography>
        )}{" "}
        <Radio
          checked={unitSize?.isDefaultSize}
          onChange={handleChangeDefault}
        />
      </Grid>
      <Grid item xs={10} sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
        <form style={{ width: "100%" }}>
          <Grid
            container
            spacing={3}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingLeft: 2,
            }}
          >
            <Grid item sx={{ width: "50%" }}>
              <TextField
                required
                size="small"
                fullWidth
                id="size"
                label="Quantity"
                placeholder="Quantity"
                autoComplete="off"
                InputLabelProps={FormInputLabelStyles}
                disabled={Boolean(unitSize?.unitOfMeasurement?.isKegSize)}
                {...register("size", {
                  required: true,
                  onChange: handleMassQuantityUpdate,
                })}
              />
            </Grid>
            <Grid item sx={{ width: "50%" }}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  select
                  label="Unit of Measurement"
                  onChange={handleUoMChange}
                  value={
                    uomOptions.find(
                      (x) => x.id === unitSize.unitOfMeasurement?.id
                    ) || uomOptions[0]
                  }
                  sx={{ width: "100%" }}
                  size="small"
                >
                  {uomOptions.map((x) => (
                    <MenuItem key={x.name} value={x}>
                      {x.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default MassSizeCard;
