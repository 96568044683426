import {
  DialogActions,
  DialogContent,
  Box,
  Typography,
  Button,
  Slider,
  Grid,
  Input,
  CircularProgress,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./utils/cropImage";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};

const CropEasy = ({
  handleCropConfirm,
  photoURL,
  handleChange,
  loading,
  setLoading,
}) => {
  const fileRef = useRef();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleClick = () => {
    fileRef.current.click();
  };

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      setLoading(true);
      const { file, url } = await getCroppedImg(photoURL, croppedAreaPixels);
      handleCropConfirm(file, url);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DialogContent
        dividers
        sx={{
          background: (theme) => theme.palette.terrain[50],
          position: "relative",
          height: "auto",
          width: "auto",
          minWidth: { sm: 400 },
          minHeight: { sm: 400 },
          borderRadius: 2,
          marginTop: 2,
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Cropper
            image={photoURL}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onZoomChange={setZoom}
            onCropChange={setCrop}
            onCropComplete={cropComplete}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column", mx: 3, my: 2 }}>
        <Box>
          <Grid
            container
            direction="column"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mb: 1,
            }}
          >
            <Grid item>
              <Typography variant="h6">Zoom: {zoomPercent(zoom)}</Typography>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <ZoomOutIcon sx={{ fontSize: "28px" }} />
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={zoomPercent}
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom) => setZoom(zoom)}
                sx={{ width: "300px", marginLeft: 4, marginRight: 4 }}
              />
              <ZoomInIcon sx={{ fontSize: "28px" }} />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ display: "flex", gap: 2, flexWrap: "wrap", marginTop: "8px" }}
        >
          <Input
            type="file"
            sx={{ display: "none" }}
            inputRef={fileRef}
            onChange={handleChange}
          />
          <Button
            variant="outlined"
            onClick={handleClick}
            sx={{ marginRight: "16px", width: "200px" }}
          >
            <Typography variant="smallButton">Replace Image</Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ marginLeft: "16px", width: "200px" }}
            onClick={cropImage}
          >
            <Typography variant="smallButton">
              {" "}
              {loading ? <CircularProgress size={14} /> : "Apply"}
            </Typography>
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default CropEasy;
