import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
  Switch,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const CreateRecipeModal = ({
  isOpen,
  toggleModalOpen,
  buttonText,
  headerText,
  loading,
  addRecipe,
}) => {
  const { register, handleSubmit } = useForm();
  const [recipeActive, toggleRecipeActive] = useState(true);
  const [sharedWithStaff, toggleSharedWithStaff] = useState(true);

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const handleCreateRecipe = (data) => {
    data.isActive = recipeActive;
    data.isSharedWithStaff = sharedWithStaff;
    addRecipe(data);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <form onSubmit={handleSubmit(handleCreateRecipe)}>
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="h2">{headerText}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={closeModal}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Box sx={{ marginTop: 8, width: "100%" }}>
                <TextField
                  size="small"
                  required
                  fullWidth
                  label="Name"
                  autoComplete="off"
                  placeholder="Enter Recipe Name"
                  InputLabelProps={{ shrink: true }}
                  {...register("name", { required: true })}
                />
              </Box>
              <Box
                sx={{
                  marginTop: 6,
                  width: "100%",
                  height: "40px",
                  backgroundColor: (theme) => theme.palette.secondary[50],
                  borderRadius: 2,
                }}
              >
                <Grid container direction="row">
                  <Grid item>
                    <Switch
                      checked={recipeActive}
                      onClick={() => toggleRecipeActive(!recipeActive)}
                    />
                  </Grid>
                  <Grid item sx={{ alignItems: "center", display: "flex" }}>
                    {" "}
                    <Typography variant="subtitle2">
                      {recipeActive
                        ? "Recipe Status: Active"
                        : "Recipe Status: Inactive"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  marginTop: 1,
                  width: "100%",
                  height: "40px",
                  backgroundColor: (theme) => theme.palette.secondary[50],
                  borderRadius: 2,
                }}
              >
                <Grid container direction="row">
                  <Grid item>
                    <Switch
                      checked={sharedWithStaff}
                      onClick={() => toggleSharedWithStaff(!sharedWithStaff)}
                    />
                  </Grid>
                  <Grid item sx={{ alignItems: "center", display: "flex" }}>
                    {" "}
                    <Typography variant="subtitle2">
                      {sharedWithStaff
                        ? "Share with Staff: Visible"
                        : "Share with Staff: Hidden"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                  type="submit"
                >
                  <Typography variant="largeButton">{buttonText}</Typography>
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default CreateRecipeModal;
