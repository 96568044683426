import {
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

const OtherSubmissionsSuggestions = ({ suggestions }) => {
  if (suggestions?.length === 0) {
    return <Typography>No other submissions matched name comparison</Typography>;
  }
  return (
    <>
      <List sx={{ width: "100%", marginTop: 4 }}>
        {suggestions?.map((x) => (
          <ListItem
            key={x.id}
            disableGutters
            sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
          >
            <ListItemText
              primary={
                x.existingMasterProductId === null
                  ? x.newMasterProductEntry?.name
                  : x.existingSpecMasterProduct?.name
              }
              sx={{ marginLeft: 4 }}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default OtherSubmissionsSuggestions;
