import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SpecBackButton from "../SpecBackButton";
import Divider from "@mui/material/Divider";
import GridContainer from "../GridContainer";
import { Switch } from "@mui/material";
import { useForm } from "react-hook-form";
import ImageControl from "../ImageControl";

const WineDrinkForm = ({
  startingWine,
  submitCallback,
  goBack,
  isEmbedded,
  categoryId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: startingWine || {},
  });
  const [wineActive, toggleWineActive] = useState(true);
  const [wineImageURL, setWineImageUrl] = useState("");
  const [isImageControlOpen, toggleIsImageControlOpen] = useState(false);

  const handleActiveToggle = () => {
    toggleWineActive(!wineActive);
  };

  useEffect(() => {
    toggleWineActive(startingWine ? startingWine.active : true);
    setWineImageUrl(startingWine ? startingWine.imageURL : "");
  }, [startingWine]);

  const handleSave = (wineData) => {
    wineData.active = wineActive;
    wineData.categoryId = categoryId;
    wineData.abv = wineData.abv === "" ? 0 : wineData.abv;
    wineData.imageURL = wineImageURL;
    submitCallback(wineData);
  };

  const imageControlCallback = async (photoLocation) => {
    setWineImageUrl(photoLocation);
    toggleIsImageControlOpen(false);
  };

  return (
    <form noValidate onSubmit={handleSubmit(handleSave)}>
      <GridContainer direction="column">
        {!isEmbedded && (
          <Box sx={{ paddingLeft: "24px" }}>
            <SpecBackButton onClick={() => goBack()} />
          </Box>
        )}
        <Box>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h3">Wine Information</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 4,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid container direction="column">
                    <Box style={{ height: 10, marginTop: "0px" }}>{""}</Box>
                    <TextField
                      size="small"
                      fullWidth
                      required
                      id="name"
                      label="Name of Wine"
                      InputLabelProps={{ shrink: true, required: true }}
                      {...register("name", {
                        required: "Please enter name of wine",
                      })}
                    />
                    {errors.name ? (
                      <Box
                        sx={{
                          color: "red",
                          marginTop: "0px",
                          paddingTop: "4px",
                          height: 10,
                        }}
                      >
                        {errors.name.message}
                      </Box>
                    ) : (
                      <Box style={{ height: 10, marginTop: "0px" }}>{""}</Box>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Switch
                    color="secondary"
                    checked={wineActive}
                    onClick={handleActiveToggle}
                  />
                  <Typography variant="subtitle2">
                    {wineActive ? "Status: Active" : "Status: Inactive"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "flex-start", sm: "flex-end" },
                  }}
                >
                  <ImageControl
                    imageSrc={wineImageURL}
                    imageControlCallback={imageControlCallback}
                    isImageControlOpen={isImageControlOpen}
                    toggleIsImageControlOpen={toggleIsImageControlOpen}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </GridContainer>
      <Divider />
      <GridContainer direction="column">
        <Box sx={{ padding: 2, marginBottom: 4 }}>
          <Grid container direction="column" spacing={4}>
            <Grid item sx={{ marginTop: 2 }}>
              <Typography variant="h3">Product Description</Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item>
                      <Grid container direction="column">
                        <TextField
                          size="small"
                          fullWidth
                          required
                          id="vintage"
                          label="Vintage"
                          InputLabelProps={{ shrink: true, required: true }}
                          {...register("vintage", {
                            required: "Please enter vintage information",
                          })}
                        />
                        {errors.vintage ? (
                          <Box
                            sx={{
                              color: "red",
                              marginTop: "0px",
                              paddingTop: "4px",
                              height: "16px",
                            }}
                          >
                            {errors.vintage.message}
                          </Box>
                        ) : (
                          <Box style={{ height: "16px", marginTop: "0px" }}>
                            {""}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <TextField
                          size="small"
                          fullWidth
                          required
                          id="country"
                          label="Country"
                          InputLabelProps={{ shrink: true, required: true }}
                          {...register("country", {
                            required: "Please enter country information",
                          })}
                        />
                        {errors.country ? (
                          <Box
                            sx={{
                              color: "red",
                              marginTop: "0px",
                              paddingTop: "4px",
                              height: "16px",
                            }}
                          >
                            {errors.country.message}
                          </Box>
                        ) : (
                          <Box style={{ height: "16px", marginTop: "0px" }}>
                            {""}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item sx={{ marginBottom: 4 }}>
                      <TextField
                        size="small"
                        fullWidth
                        type="number"
                        id="abv"
                        label="ABV"
                        step="any"
                        InputLabelProps={{ shrink: true }}
                        {...register("abv", {
                          required: false,
                          validate: () => true,
                        })}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        size="small"
                        fullWidth
                        id="region"
                        label="Region"
                        InputLabelProps={{ shrink: true }}
                        {...register("region", { required: false })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item sx={{ marginBottom: 4 }}>
                      <TextField
                        size="small"
                        id="varietals"
                        label="Varietal(s)"
                        placeholder="Enter the wine varietal composition"
                        multiline
                        fullWidth
                        rows={5}
                        InputLabelProps={{ shrink: true }}
                        {...register("varietals", { required: false })}
                      />
                    </Grid>

                    <Grid item sx={{ marginBottom: 4 }}>
                      <TextField
                        size="small"
                        id="history"
                        label="History"
                        placeholder="Does the wine have any interesting historical information?"
                        multiline
                        fullWidth
                        rows={5}
                        InputLabelProps={{ shrink: true }}
                        {...register("history", { required: false })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item sx={{ marginBottom: 4 }}>
                      <TextField
                        size="small"
                        id="winemakingNotes"
                        label="Winemaking Notes"
                        placeholder="Enter notes from the wine maker here."
                        multiline
                        fullWidth
                        rows={5}
                        InputLabelProps={{ shrink: true }}
                        {...register("winemakingNotes", { required: false })}
                      />
                    </Grid>
                    <Grid item sx={{ marginBottom: 4 }}>
                      <TextField
                        size="small"
                        id="tastingNotes"
                        label="Tasting Notes"
                        placeholder="Enter distinct flavors"
                        multiline
                        fullWidth
                        rows={5}
                        InputLabelProps={{ shrink: true }}
                        {...register("tastingNotes", { required: false })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </GridContainer>
      <Divider />
      <GridContainer>
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: "12px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              sx={{
                width: "100%",
                borderRadius: 2,
              }}
            >
              <Typography variant="smallButton">Save</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={goBack}
              sx={{
                width: "100%",
                borderRadius: 2,
              }}
            >
              <Typography variant="smallButton">Cancel</Typography>
            </Button>
          </Grid>
        </Grid>
      </GridContainer>
    </form>
  );
};

export default WineDrinkForm;
