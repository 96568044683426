import React from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  TextField,
  Radio,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import inventoryObjects from "../../../../clients/inventoryObjects";

const selectedStyle = {
  cursor: "pointer",
  width: "100%",
  height: "90px",
  borderRadius: "4px",
  border: "2px solid",
  borderColor: (theme) => theme.palette.primary[800],
  backgroundColor: (theme) => theme.palette.primary[50],
  marginBottom: 2,
};

const defaultStyle = {
  cursor: "pointer",
  width: "100%",
  height: "90px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[300],
  borderRadius: "4px",
  marginBottom: 2,
};

const InitCountForm = ({
  handleNextAction,
  setCountType,
  countType,
  countName,
  handleNameChange,
  closeModal,
}) => {
  const handleSetCountTypeToAudit = () => {
    setCountType(inventoryObjects.stockCountTypes.inventoryAudit);
  };

  const handleSetCountTypeToPartial = () => {
    setCountType(inventoryObjects.stockCountTypes.partial);
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 6,
        }}
      >
        <Grid item>
          <Typography variant="h4">Count Details</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={closeModal}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: 2, display: "flex", flexDirection: "row" }}>
        <Typography
          variant="h4"
          sx={{
            color: (theme) => theme.palette.primary[800],
            fontSize: { xs: 16, sm: 16, md: 20 },
            marginRight: 2,
          }}
        >
          Step 01:
        </Typography>
        <Typography variant="h4" sx={{ fontSize: { xs: 16, sm: 16, md: 20 } }}>
          Count Name
        </Typography>
      </Box>
      <Box sx={{ marginBottom: 6, marginTop: 4 }}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          placeholder="E.g. June - End of Month"
          label="Name"
          InputLabelProps={{ shrink: true }}
          value={countName}
          onChange={handleNameChange}
        />
      </Box>
      <Box sx={{ marginBottom: 2, display: "flex", flexDirection: "row" }}>
        <Typography
          variant="h4"
          sx={{
            color: (theme) => theme.palette.primary[800],
            fontSize: { xs: 16, sm: 16, md: 20 },
            marginRight: 2,
          }}
        >
          Step 02:
        </Typography>
        <Typography variant="h4" sx={{ fontSize: { xs: 16, sm: 16, md: 20 } }}>
          Count Type
        </Typography>
      </Box>
      <Box
        sx={
          countType === inventoryObjects.stockCountTypes.inventoryAudit
            ? selectedStyle
            : defaultStyle
        }
        onClick={handleSetCountTypeToAudit}
      >
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item>
            <Radio
              checked={
                countType === inventoryObjects.stockCountTypes.inventoryAudit
              }
            />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: 12, sm: 12, md: 16 },
                color:
                  countType === inventoryObjects.stockCountTypes.inventoryAudit
                    ? (theme) => theme.palette.primary[800]
                    : (theme) => theme.palette.pure.black,
              }}
            >
              Inventory Audit
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            marginLeft: 10.5,
            marginRight: 1,
            marginTop: { xs: -2, sm: -2, md: 0 },
          }}
        >
          <Typography sx={{ fontSize: { xs: 10, sm: 10, md: 12 } }}>
            A Full Audit allows you to complete a full establishment product
            count.
          </Typography>
        </Grid>
      </Box>
      <Box
        sx={
          countType === inventoryObjects.stockCountTypes.partial
            ? selectedStyle
            : defaultStyle
        }
        onClick={handleSetCountTypeToPartial}
      >
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item>
            <Radio
              checked={countType === inventoryObjects.stockCountTypes.partial}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: 12, sm: 12, md: 16 },
                color:
                  countType === inventoryObjects.stockCountTypes.partial
                    ? (theme) => theme.palette.primary[800]
                    : (theme) => theme.palette.pure.black,
              }}
            >
              Partial Audit
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            marginLeft: 10.5,
            marginRight: 1,
            marginTop: { xs: -2, sm: -2, md: 0 },
          }}
        >
          <Typography sx={{ fontSize: { xs: 10, sm: 10, md: 12 } }}>
            A Partial Audit allows you to count a subset of Product Formats in a
            select number of Areas.
          </Typography>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <Button
          variant="contained"
          onClick={handleNextAction}
          sx={{
            marginLeft: "1rem",
            borderRadius: 2,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <Typography variant="smallButton">
            {countType === inventoryObjects.stockCountTypes.partial
              ? "Select Template"
              : "Begin New Count"}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default InitCountForm;
