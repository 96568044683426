import { SvgIcon } from "@mui/material";
import React from "react";

const MenuNavIcon = (props) => {
  return (
    <SvgIcon
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.87035 19.1667C2.31016 19.1667 1.83061 18.9672 1.43169 18.5683C1.03277 18.1694 0.833313 17.6898 0.833313 17.1296V2.87038C0.833313 2.3102 1.03277 1.83064 1.43169 1.43172C1.83061 1.0328 2.31016 0.833344 2.87035 0.833344H17.1296C17.6898 0.833344 18.1693 1.0328 18.5683 1.43172C18.9672 1.83064 19.1666 2.3102 19.1666 2.87038V17.1296C19.1666 17.6898 18.9672 18.1694 18.5683 18.5683C18.1693 18.9672 17.6898 19.1667 17.1296 19.1667H2.87035ZM7.96294 17.1296H17.1296V2.87038H7.96294V17.1296Z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default MenuNavIcon;
