import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { modalBoxStyle } from "../styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const InvoiceActionsModal = ({
  isOpen,
  toggleModalOpen,
  buttonText,
  bodyText,
  headerText,
  icon,
  handleActionTaken,
  isLoading,
}) => {
  const handleClose = () => {
    toggleModalOpen(false);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={[modalBoxStyle, { width: "400px !important" }]}>
        <Grid
          container
          sx={{
            marginBottom: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={10}>
            <Typography variant="h4">{headerText}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton onClick={handleClose} sx={{ marginRight: -4 }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            {bodyText}
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 6,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button variant="outlined" sx={{ width: "50%", marginRight: 1 }}>
            <Typography variant="largeButton">Cancel</Typography>
          </Button>
          <Button variant="contained" sx={{ width: "50%", marginLeft: 1 }} onClick={handleActionTaken} disabled={isLoading}>
            {isLoading ? <CircularProgress /> : <Typography variant="largeButton">{buttonText}</Typography>}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default InvoiceActionsModal;
