import React from "react";
import { Stepper, Step, StepLabel, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

function getSteps() {
  return [
    "Add Vendors",
    "Set Order Days",
    "Cut Off Times",
    "Delivery Arrival Days",
    "Review",
  ];
}

const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  color: theme.palette.terrain[500],
  width: 20,
  height: 20,
  display: "flex",
  borderRadius: "50%",
  border: "2px solid",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary[800],
    border: "none",
    color: theme.palette.pure.white,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.success.light,
    border: "1px solid #1BB55B",
    color: theme.palette.success.main,
  }),
}));

const CustomStepIcon = (props) => {
  const { active, completed, className } = props;

  const icons = {
    1: completed ? (
      <CheckOutlinedIcon sx={{ fontSize: 14 }} />
    ) : (
      <Typography>1</Typography>
    ),
    2: completed ? (
      <CheckOutlinedIcon sx={{ fontSize: 14 }} />
    ) : (
      <Typography>2</Typography>
    ),
    3: completed ? (
      <CheckOutlinedIcon sx={{ fontSize: 14 }} />
    ) : (
      <Typography>3</Typography>
    ),
    4: completed ? (
      <CheckOutlinedIcon sx={{ fontSize: 14 }} />
    ) : (
      <Typography>4</Typography>
    ),
    5: completed ? (
      <CheckOutlinedIcon sx={{ fontSize: 14 }} />
    ) : (
      <Typography>5</Typography>
    ),
  };

  return (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </CustomStepIconRoot>
  );
};

const DistributorStepper = ({ activeStep }) => {
  const steps = getSteps();

  return (
    <Grid item xs={12} md={12} lg={8} sx={{ marginTop: 4 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel StepIconComponent={CustomStepIcon} {...labelProps}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: { xs: 8, sm: 14, md: 16 } }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Grid>
  );
};

export default DistributorStepper;
