import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function usePastStockCounts(establishmentId, page, enabled) {
  const queryReturnValues = useQuery(
    ["past-stockcounts", { establishmentId, page }],
    inventoryClient.getPastStockCountsForEstablishment,
    {
      staleTime: 1000 * 60 * 5,
      enabled
    }
  );

  return queryReturnValues;
}

export default usePastStockCounts;
