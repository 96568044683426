import React, { useState } from "react";
import { Button, Typography, Menu, MenuItem } from "@mui/material";
import inventoryObjects from "../../clients/inventoryObjects";
import OrderStatusChip from "../OrderStatusChip";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const OrderStatusCTA = ({
  status,
  orderText,
  placeOrder,
  openEmail,
  setAlertSuccess,
}) => {
  const [markingOrderAsPlaced, toggleMarkingOrderAsPlaced] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePlaceOrder = async () => {
    toggleMarkingOrderAsPlaced(true);
    navigator.clipboard.writeText(orderText);
    setAnchorEl(null);
    setAlertSuccess("Order has been placed!");
    await placeOrder();
    toggleMarkingOrderAsPlaced(false);
  };

  const handleCopy = async () => {
    navigator.clipboard.writeText(orderText);
    setAnchorEl(null);
    setAlertSuccess("Order content copied to clipboard!");
  };

  const handleOpenEmail = () => {
    openEmail(orderText);
  };

  const handleEmailPlaceOrder = async () => {
    toggleMarkingOrderAsPlaced(true);
    openEmail(orderText);
    setAnchorEl(null);
    setAlertSuccess("Email created and order has been marked as placed!");
    await placeOrder();
    toggleMarkingOrderAsPlaced(false);
  };

  return (
    <>
      {status === inventoryObjects.OrderStatuses.preview ? (
        <>
          <Button
            variant="outlined"
            disabled={markingOrderAsPlaced}
            sx={[
              {
                height: "24px",
                width: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: (theme) => theme.palette.pure.white,
                borderRadius: 1,
                borderColor: (theme) => theme.palette.primary[800],
              },
              {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            ]}
            onClick={handleClick}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "10px", textTransform: "none" }}
            >
              Mark as Placed
            </Typography>
          </Button>
          <Menu
            sx={{
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: (theme) => theme.palette.primary[800],
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: (theme) => theme.palette.secondary[600],
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: (theme) => theme.palette.secondary[800],
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem sx={{ margin: "5px" }} onClick={handleOpenEmail}>
              <SendOutlinedIcon sx={{ marginRight: "5px" }} />
              Create Email
            </MenuItem>
            <MenuItem
              sx={{ margin: "5px" }}
              onClick={handleCopy}
            >
              <ContentCopyOutlinedIcon sx={{ marginRight: "5px" }} />
              Copy Content
            </MenuItem>
            <MenuItem sx={{ margin: "5px" }} onClick={handlePlaceOrder}>
              <CheckOutlinedIcon sx={{ marginRight: "5px" }} />
              Copy and Mark as Placed
            </MenuItem>
            <MenuItem sx={{ margin: "5px" }} onClick={handleEmailPlaceOrder}>
              <CheckOutlinedIcon sx={{ marginRight: "5px" }} />
              Email and Mark as Placed
            </MenuItem>
          </Menu>
        </>
      ) : (
        <OrderStatusChip status={status} />
      )}
    </>
  );
};

export default OrderStatusCTA;
