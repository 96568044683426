import { Box, Typography } from "@mui/material";
import React from "react";

const Description = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "fit-content",
          width: "70%",
          borderRadius: 2,
          backgroundColor: (theme) => theme.palette.terrain[200],
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
          padding: 2,
        }}
      >
        <Typography variant="body2">
          This report shows Expense Group and Expense Subgroup spending over
          time based on your invoices in the specified time range.
        </Typography>
      </Box>
    </Box>
  );
};

export default Description;
