import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  CircularProgress,
  Divider,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SpecBackButton from "../../../SpecBackButton";
import CTABlock from "../../../CTABlock";
import CreateNewTemplateFlow from "./CreateNewTemplateFlow";
import TemplateOption from "./TemplateOption";

const TemplateSelection = ({
  handleNextAction,
  templates,
  isLoadingTemplates,
  setCurrentStep,
  closeModal,
  selectedTemplateId,
  setSelectedTemplateId,
}) => {
  const [creatingNew, toggleCreatingNew] = useState(false);

  if (creatingNew) {
    return (
      <CreateNewTemplateFlow
        toggleCreatingNew={toggleCreatingNew}
        closeModal={closeModal}
      />
    );
  }

  if (isLoadingTemplates) {
    return <CircularProgress />;
  }

  const handleBack = () => {
    setCurrentStep(0);
  };

  const kickoffCreateNewFlow = () => {
    toggleCreatingNew(true);
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 6,
        }}
      >
        <Grid item>
          <SpecBackButton backText="Back" onClick={handleBack} />
        </Grid>
        <Grid item>
          <IconButton onClick={closeModal}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      {templates?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 6,
          }}
        >
          <Typography variant="h3">Select Template</Typography>
          <Button
            onClick={kickoffCreateNewFlow}
            variant="contained"
            sx={{ marginLeft: "auto" }}
          >
            <Typography variant="largeButton">+ Create New</Typography>
          </Button>
        </Box>
      ) : null}
      {templates?.length <= 0 ? (
        <Box sx={{ width: "100%" }}>
          <CTABlock
            buttonText="+ Create Template"
            header="No Templates Created"
            subheader="You have not created any partial audit templates. Create one to begin."
            handleCTA={kickoffCreateNewFlow}
          />
        </Box>
      ) : (
        <Grid
          item
          sx={{
            margintop: 4,
            height: "370px",
            overflow: "scroll",
          }}
        >
          {templates.map((x) => (
            <TemplateOption
              key={x.id}
              template={x}
              setSelectedTemplateId={setSelectedTemplateId}
              selectedTemplateId={selectedTemplateId}
            />
          ))}
        </Grid>
      )}
      <Divider sx={{ marginLeft: -8, marginRight: -8, marginTop: 2 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 4,
        }}
      >
        <Button
          variant="contained"
          onClick={handleNextAction}
          disabled={
            selectedTemplateId === null || selectedTemplateId === undefined
          }
          sx={{
            width: "90%",
            borderRadius: 2,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 6,
            paddingRight: 6,
          }}
        >
          <Typography variant="smallButton">Confirm Template</Typography>
        </Button>
      </Box>
    </>
  );
};

export default TemplateSelection;
