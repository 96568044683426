import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
// import DragHandleIcon from "@mui/icons-material/DragHandle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SliderInput from "./SliderInput";
import { useParams } from "react-router-dom";
import inventoryClient from "../../../clients/inventoryClient";
import utilFunctions from "../../../utilities/utilFunctions";

const ProductListRow = ({
  record,
  // index,
  mobileDisplay,
  updateBaseRecordQuantity,
  selectedSectionId,
  searchText,
  searching,
}) => {
  const [sliderInput, toggleSliderInput] = useState(false);
  const [productQuantityDisplay, setProductQuantityDisplay] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  let { establishmentid: establishmentId } = useParams();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    toggleSliderInput(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (record && record.quantityFound !== null) {
      setProductQuantityDisplay(Number(record?.quantityFound).toFixed(2));
    }
  }, []);

  const sliderSelectedStyle = sliderInput
    ? {
        backgroundColor: (theme) => theme.palette.info[50],
        color: (theme) => theme.palette.pure.black,
        border: "2px solid",
        borderColor: (theme) => theme.palette.info[600],
        height: "34px !important",
        width: "80px",
        borderRadius: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }
    : {
        backgroundColor: (theme) => theme.palette.pure.white,
        color: (theme) => theme.palette.pure.black,
        height: "34px !important",
        width: "80px",
        borderRadius: 1,
        border: "1px solid",
        borderColor: (theme) => theme.palette.info[600],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      };

  const handleSliderUpdateProductQuantity = async (newValue) => {
    await inventoryClient.updateCountRecord({
      establishmentId,
      stockCountRecordId: record.id,
      quantityFoundUpdate: { value: newValue },
    });
    setProductQuantityDisplay(newValue);
    updateBaseRecordQuantity(record.id, newValue);
  };

  const handleProductQuantityChange = (e) => {
    if (/^[0-9.]*$/.test(e.target.value)) {
      setProductQuantityDisplay(e.target.value);
    }
  };

  const handleBlurProductQuantity = async () => {
    if (
      typeof productQuantityDisplay === "string" &&
      !utilFunctions.isNumeric(productQuantityDisplay)
    ) {
      setProductQuantityDisplay(record.quantityFound);
      return;
    }

    const newQuantity = parseFloat(productQuantityDisplay).toFixed(2);

    await inventoryClient.updateCountRecord({
      establishmentId,
      stockCountRecordId: record.id,
      quantityFoundUpdate: { value: productQuantityDisplay },
    });
    setProductQuantityDisplay(newQuantity);
    updateBaseRecordQuantity(record.id, newQuantity);
  };

  if (
    selectedSectionId !== undefined &&
    selectedSectionId !== null &&
    record.sections.find((s) => s.id === selectedSectionId) === undefined
  ) {
    return null;
  }

  if (
    searching &&
    !record.recordName.toLowerCase()?.includes(searchText.toLowerCase())
  ) {
    return null;
  }

  return (
    <>
      <Card
        elevation={0}
        sx={{
          backgroundColor: "transparent",
          width: "100%",
          borderRadius: 0,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "52px",
            width: "100%",
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            // borderTop: index === 0 ? null : "1px solid",
            padding: 6,
          }}
        >
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: { xs: "60%", sm: "60%", md: "60%" },
              maxWidth: { xs: "60%", sm: "60%", md: "80%" },
            }}
          >
            {/* <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: 2,
                width: "20px",
              }}
            > */}
            {/* <DragHandleIcon
                sx={{
                  fontSize: 16,
                  color: (theme) => theme.palette.primary[800],
                }}
              /> */}
            {/* </Grid> */}
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "80%",
              }}
            >
              <Box sx={{ width: "90%" }}>
                <div
                  style={{
                    maxWidth: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography variant="body1" noWrap>
                    {record.recordName}
                  </Typography>
                </div>
              </Box>
              <Box>
                <Typography variant="overline">{record.recordSize}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              dispaly: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: { sm: "40%", md: "30%" },
              minWidth: { xs: "40%", sm: "40%", md: "20%" },
            }}
          >
            <Grid item>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  sx={{ display: "flex", alignItems: "center", marginRight: 2 }}
                >
                  {mobileDisplay ? (
                    <Box
                      sx={[
                        sliderSelectedStyle,
                        {
                          borderColor: (theme) =>
                            productQuantityDisplay === null
                              ? theme.palette.warning[600]
                              : theme.palette.info[600],
                          top: 0,
                        },
                      ]}
                      onClick={handleClick}
                    >
                      <Typography variant="body1">
                        {productQuantityDisplay}
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <TextField
                        size="small"
                        variant="outlined"
                        autoComplete="off"
                        value={productQuantityDisplay}
                        onChange={handleProductQuantityChange}
                        onBlur={handleBlurProductQuantity}
                        onKeyPress={utilFunctions.blurInput}
                        onFocus={utilFunctions.highlightInputOnFocus}
                        color="warning"
                        inputProps={{
                          min: 0,
                          style: {
                            textAlign: "center",
                            fontWeight: 500,
                            color: (theme) => theme.palette.pure.black,
                          },
                        }}
                        sx={{
                          backgroundColor: (theme) => theme.palette.pure.white,
                          width: "80px",
                          borderRadius: 1,
                          "& legend": { display: "none" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: (theme) =>
                                productQuantityDisplay === null
                                  ? theme.palette.warning[600]
                                  : theme.palette.info[600],
                              top: 0,
                            },
                          },
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ marginLeft: 4 }}>
              <IconButton variant="outlined">
                <MoreHorizIcon
                  sx={{
                    color: (theme) => theme.palette.primary[800],
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // anchorPosition={{ top: 0, left: 900 }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={3}
        sx={{
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[800],
        }}
        PaperProps={{ style: { borderRadius: "8px" } }}
      >
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Grid item>
              <Typography variant="h4">{record.recordName}</Typography>
              <Typography variant="body1">{record.recordSize}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleClose()}>
                <CloseOutlinedIcon
                  sx={{ color: (theme) => theme.palette.primary[800] }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ padding: 2 }}>
          <SliderInput
            recordValue={productQuantityDisplay}
            updateProductQuantity={handleSliderUpdateProductQuantity}
            onClose={handleClose}
          />
        </Box>
      </Popover>
    </>
  );
};

export default ProductListRow;
