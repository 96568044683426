import moment from "moment";

moment.updateLocale('en', {
  week: {
    dow: 1,  // Monday is the first day of the week
    doy: 4
  }
});

const dateRangeGenerator = {
  thisWeekToDate: (timezone) => {
    const currentDate = moment().tz(timezone);
    const startDate = currentDate.clone().startOf('week').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  thisMonthToDate: (timezone) => {
    const currentDate = moment().tz(timezone);
    const startDate = currentDate.clone().startOf('month').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  thisQuarterToDate: (timezone) => {
    const currentDate = moment().tz(timezone);
    const quarterStartMonth = Math.floor(currentDate.month() / 3) * 3; // Determine the starting month of the current quarter
    const startDate = currentDate.clone().month(quarterStartMonth).startOf('month').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  thisYearToDate: (timezone) => {
    const currentDate = moment().tz(timezone);
    const startDate = moment.tz(currentDate.clone().startOf('year'), timezone).format();
    const endDate = moment.tz(currentDate.clone().endOf('day'), timezone).format();

    return { start: startDate, end: endDate };
  },
  lastWeekToDate: (timezone) => {
    const currentDate = moment().tz(timezone);
    const startDate = currentDate.clone().subtract(1, 'week').startOf('week').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastMonthToDate: (timezone) => {
    const currentDate = moment().tz(timezone);
    const startDate = currentDate.clone().subtract(1, 'month').startOf('month').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastQuarterToDate: (timezone) => {
    const currentDate = moment().tz(timezone);
    
    // Determine the starting month of the current quarter
    const currentQuarterStartMonth = Math.floor(currentDate.month() / 3) * 3;
    
    // Determine the starting month of the previous quarter
    const previousQuarterStartMonth = currentQuarterStartMonth - 3;
    
    // Adjust if the previous quarter goes back into the previous year
    const startDate = currentDate.clone().month(previousQuarterStartMonth).startOf('month').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastYearToDate: (timezone) => {
    const currentDate = moment().tz(timezone);
    
    const startDate = currentDate.clone().subtract(1, 'year').startOf('year').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastYearBeginningToToday: (timezone) => {
    const currentDate = moment().tz(timezone);
    
    const startDate = currentDate.clone().subtract(1, 'year').startOf('year').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  since30DaysAgo: (timezone) => {
    const currentDate = moment().tz(timezone);
    
    const startDate = currentDate.clone().subtract(30, 'days').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  since60DaysAgo: (timezone) => {
    const currentDate = moment().tz(timezone);
    
    const startDate = currentDate.clone().subtract(60, 'days').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  since90DaysAgo: (timezone) => {
    const currentDate = moment().tz(timezone);
    
    const startDate = currentDate.clone().subtract(90, 'days').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  since365DaysAgo: (timezone) => {
    const currentDate = moment().tz(timezone);
    
    const startDate = currentDate.clone().subtract(365, 'days').startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  thisWeek: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().startOf('week').startOf('day').format();
    const endDate = currentDate.clone().endOf('week').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  thisMonth: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().startOf('month').startOf('day').format();
    const endDate = currentDate.clone().endOf('month').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  thisQuarter: (timezone) => {
    const currentDate = moment().tz(timezone);
    
    const quarterStartMonth = Math.floor(currentDate.month() / 3) * 3;
    
    const startDate = currentDate.clone().month(quarterStartMonth).startOf('month').startOf('day').format();
    const endDate = currentDate.clone().month(quarterStartMonth + 2).endOf('month').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  today: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().startOf('day').format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  thisYear: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().startOf('year').startOf('day').format();
    const endDate = currentDate.clone().endOf('year').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  thisYearToLastMonth: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().startOf('year').startOf('day').format();
    const endDate = currentDate.clone().subtract(1, 'month').endOf('month').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  yesterday: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().subtract(1, 'day').startOf('day').format();
    const endDate = currentDate.clone().subtract(1, 'day').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastWeek: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().subtract(1, 'week').startOf('week').startOf('day').format();
    const endDate = currentDate.clone().subtract(1, 'week').endOf('week').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastMonth: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().subtract(1, 'month').startOf('month').startOf('day').format();
    const endDate = currentDate.clone().subtract(1, 'month').endOf('month').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastYearThisMonth: (timezone) => {
    const currentDate = moment().tz(timezone);
    
    const startDate = currentDate.clone().subtract(1, 'year').startOf('month').startOf('day').format();
    const endDate = currentDate.clone().subtract(1, 'year').endOf('month').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastYearNextMonth: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().subtract(1, 'year').add(1, 'month').startOf('month').startOf('day').format();
    const endDate = currentDate.clone().subtract(1, 'year').add(1, 'month').endOf('month').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastYearLastMonth: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().subtract(1, 'year').subtract(1, 'month').startOf('month').startOf('day').format();
    const endDate = currentDate.clone().subtract(1, 'year').subtract(1, 'month').endOf('month').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastQuarter: (timezone) => {
    const currentDate = moment().tz(timezone);
    
    const currentQuarterStartMonth = Math.floor(currentDate.month() / 3) * 3;
    const lastQuarterStartMonth = currentQuarterStartMonth - 3;
  
    const startDate = currentDate.clone().month(lastQuarterStartMonth).startOf('month').startOf('day').format();
    const endDate = currentDate.clone().month(lastQuarterStartMonth + 2).endOf('month').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  lastYear: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = currentDate.clone().subtract(1, 'year').startOf('year').startOf('day').format();
    const endDate = currentDate.clone().subtract(1, 'year').endOf('year').endOf('day').format();
  
    return { start: startDate, end: endDate };
  },
  allTimeTilNow: (timezone) => {
    const currentDate = moment().tz(timezone);
  
    const startDate = moment.tz("1900-01-01 00:00:00", timezone).format();
    const endDate = currentDate.clone().endOf('day').format();
  
    return { start: startDate, end: endDate };
  }
}

const presetDateRangeOptions = [
  { key: "Today", value: 0, func: dateRangeGenerator.today },
  { key: "This Week", value: 1, func: dateRangeGenerator.thisWeek },
  {
    key: "This Week-to-date",
    value: 2,
    func: dateRangeGenerator.thisWeekToDate,
  },
  { key: "This Month", value: 3, func: dateRangeGenerator.thisMonth },
  {
    key: "This Month-to-date",
    value: 4,
    func: dateRangeGenerator.thisMonthToDate,
  },
  { key: "This Quarter", value: 5, func: dateRangeGenerator.thisQuarter },
  {
    key: "This Quarter-to-date",
    value: 6,
    func: dateRangeGenerator.thisQuarterToDate,
  },
  { key: "This Year", value: 7, func: dateRangeGenerator.thisYear },
  {
    key: "This Year-to-date",
    value: 8,
    func: dateRangeGenerator.thisYearToDate,
  },
  {
    key: "This Year-to-last-month",
    value: 9,
    func: dateRangeGenerator.thisYearToLastMonth,
  },
  { key: "Yesterday", value: 10, func: dateRangeGenerator.yesterday },
  { key: "Last Week", value: 11, func: dateRangeGenerator.lastWeek },
  {
    key: "Last Week-to-date",
    value: 12,
    func: dateRangeGenerator.lastWeekToDate,
  },
  { key: "Last Month", value: 13, func: dateRangeGenerator.lastMonth },
  {
    key: "Last Month-to-date",
    value: 14,
    func: dateRangeGenerator.lastMonthToDate,
  },
  {
    key: "Last Year - This Month",
    value: 15,
    func: dateRangeGenerator.lastYearThisMonth,
  },
  {
    key: "Last Year - Last Month",
    value: 16,
    func: dateRangeGenerator.lastYearLastMonth,
  },
  {
    key: "Last Year - Next Month",
    value: 17,
    func: dateRangeGenerator.lastYearNextMonth,
  },
  { key: "Last Quarter", value: 19, func: dateRangeGenerator.lastQuarter },
  {
    key: "Last Quarter-to-date",
    value: 20,
    func: dateRangeGenerator.lastQuarterToDate,
  },
  { key: "Last Year", value: 21, func: dateRangeGenerator.lastYear },
  {
    key: "Last Year-to-date",
    value: 22,
    func: dateRangeGenerator.lastYearToDate,
  },
  { key: "Last 30 Days", value: 23, func: dateRangeGenerator.since30DaysAgo },
  { key: "Last 60 Days", value: 24, func: dateRangeGenerator.since60DaysAgo },
  { key: "Last 90 Days", value: 25, func: dateRangeGenerator.since90DaysAgo },
  { key: "Last 365 Days", value: 26, func: dateRangeGenerator.since365DaysAgo },
];

export { dateRangeGenerator, presetDateRangeOptions};