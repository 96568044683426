import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../../clients/inventoryClient";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import Store from "../../../../Store/Store";
import utilFunctions from "../../../../utilities/utilFunctions";
import useEstablishmentSettings from "../../../../hooks/useEstablishmentSettings";

const kegExpenseOperators = {
  0: function (total, val) {
    return total - val;
  },
  1: function (total, val) {
    return total + val;
  },
};

const miscLineOperators = {
  0: function (total, val) {
    return total - val;
  },
  1: function (total, val) {
    return total + val;
  },
};

// Used for sub sections displaying total cost, such as misc item totals or keg transfer totals
const OrderTotalCostBadge = ({
  displayText,
  productLineItems,
  kegTransfers,
  miscLineItems,
  invoiceId,
  updateInvoiceReconciliationStatus,
  serverOrderTotal,
  boxStyle,
}) => {
  const [costDisplay, setCostDisplay] = useState(0);
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  const queryClient = useQueryClient();

  const invoiceReconciliationMutation = useMutation(
    inventoryClient.reconcileInvoice,
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries({
          queryKey: [
            "invoices",
            {
              establishmentId: store.currentEstablishment?.id,
            },
          ],
        });

        setCostDisplay(
          utilFunctions.convertCentsToLargerFormatCurrency(
            result.orderTotal?.amount,
            locale,
            currency
          )
        );
        updateInvoiceReconciliationStatus(result);
      },
    }
  );

  const attemptInvoiceReconciliation = () => {
    invoiceReconciliationMutation.mutate({
      invoiceId,
      establishmentId: store.currentEstablishment?.id,
    });
  };

  useEffect(() => {
    let total = 0;
    productLineItems?.forEach((x) => {
      total += x?.cost?.amount;
    });

    kegTransfers?.forEach((x) => {
      total = kegExpenseOperators[x.type](total, x?.cost?.amount);
    });

    miscLineItems?.forEach((x) => {
      total = miscLineOperators[x.type](total, x.cost.amount);
    });
    setCostDisplay(
      utilFunctions.convertCentsToLargerFormatCurrency(total, locale, currency)
    );

    attemptInvoiceReconciliation();
  }, [productLineItems, kegTransfers, miscLineItems]);

  useEffect(() => {
    setCostDisplay(
      utilFunctions.convertCentsToLargerFormatCurrency(
        serverOrderTotal,
        locale,
        currency
      )
    );
  }, [serverOrderTotal]);

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography variant="body1" sx={{ fontSize: { xs: 10, md: 12 } }}>
          {displayText}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={[boxStyle, { dispaly: "flex", justifyContent: "flex-end" }]}
      >
        <Typography variant="body2" sx={{ marginRight: 2 }}>
          {currencySymbol}
          {costDisplay}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OrderTotalCostBadge;
