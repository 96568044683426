import { SvgIcon } from "@mui/material";
import React from "react";

const OrderLinkIcon = (props) => {
  return (
    <SvgIcon
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5806 0.700492C11.3493 0.480022 10.9831 0.488793 10.7626 0.720082L8.00309 3.61499C7.16374 2.67506 5.94264 2.08332 4.58333 2.08332C2.05203 2.08332 0 4.13535 0 6.66665C0 9.19796 2.05203 11.25 4.58333 11.25C7.11464 11.25 9.16667 9.19796 9.16667 6.66665C9.16667 5.91968 8.98798 5.21445 8.67101 4.59136L11.6002 1.51847C11.8206 1.28719 11.8119 0.920962 11.5806 0.700492ZM5.44471 7.97542C5.44979 7.97047 5.45479 7.96541 5.45972 7.96023L7.98941 5.30641C8.15753 5.72701 8.25 6.18603 8.25 6.66665C8.25 8.6917 6.60838 10.3333 4.58333 10.3333C2.55829 10.3333 0.916667 8.6917 0.916667 6.66665C0.916667 4.64161 2.55829 2.99999 4.58333 2.99999C5.69748 2.99999 6.69556 3.49691 7.36804 4.28121L4.63719 7.14606L3.16396 5.5952C3.0717 5.49809 2.95469 5.44243 2.81948 5.44243C2.68427 5.44243 2.56725 5.49809 2.475 5.5952C2.38018 5.69502 2.33332 5.81898 2.33332 5.95663C2.33332 6.09428 2.38018 6.21823 2.475 6.31805L4.40258 8.3472C4.44885 8.39591 4.50267 8.43815 4.56524 8.46449C4.62328 8.48893 4.68461 8.49998 4.74706 8.49998C4.80951 8.49998 4.87084 8.48893 4.92889 8.46449C4.99145 8.43815 5.04527 8.39591 5.09154 8.3472L5.44471 7.97542Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.4697 12.0555C4.07544 12.014 3.69485 11.9266 3.33333 11.7988V15.6084H4.58333C4.58333 16.2474 4.80114 16.7918 5.23674 17.2418C5.67235 17.6917 6.19949 17.9167 6.81818 17.9167C7.43687 17.9167 7.96402 17.6917 8.39962 17.2418C8.83523 16.7918 9.05303 16.2474 9.05303 15.6084H14.1856C14.1856 16.2474 14.4034 16.7918 14.839 17.2418C15.2746 17.6917 15.8018 17.9167 16.4205 17.9167C17.0391 17.9167 17.5663 17.6917 18.0019 17.2418C18.4375 16.7918 18.6553 16.2474 18.6553 15.6084H20V12.2242L17.4242 8.68348H15.4356V5.41665H9.71549C9.8473 5.78957 9.93612 6.18279 9.97601 6.59036H14.2992V14.4346H8.73106C8.51641 14.0956 8.24179 13.8185 7.9072 13.6033C7.5726 13.3881 7.20328 13.2805 6.79924 13.2805C6.3952 13.2805 6.02904 13.3848 5.70076 13.5935C5.37247 13.8022 5.10101 14.0825 4.88636 14.4346H4.4697V12.0555ZM7.5947 16.4104C7.38005 16.6321 7.12121 16.7429 6.81818 16.7429C6.51515 16.7429 6.25631 16.6321 6.04167 16.4104C5.82702 16.1887 5.7197 15.9213 5.7197 15.6084C5.7197 15.2954 5.82702 15.028 6.04167 14.8063C6.25631 14.5846 6.51515 14.4738 6.81818 14.4738C7.12121 14.4738 7.38005 14.5846 7.5947 14.8063C7.80934 15.028 7.91667 15.2954 7.91667 15.6084C7.91667 15.9213 7.80934 16.1887 7.5947 16.4104ZM17.197 16.4104C16.9823 16.6321 16.7235 16.7429 16.4205 16.7429C16.1174 16.7429 15.8586 16.6321 15.6439 16.4104C15.4293 16.1887 15.322 15.9213 15.322 15.6084C15.322 15.2954 15.4293 15.028 15.6439 14.8063C15.8586 14.5846 16.1174 14.4738 16.4205 14.4738C16.7235 14.4738 16.9823 14.5846 17.197 14.8063C17.4116 15.028 17.5189 15.2954 17.5189 15.6084C17.5189 15.9213 17.4116 16.1887 17.197 16.4104ZM18.9583 12.7523H15.4356V9.85718H16.8561L18.9583 12.7523Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default OrderLinkIcon;
