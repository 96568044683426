import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import Store from "../../../../Store/Store";
import utilFunctions from "../../../../utilities/utilFunctions";
import ApprovedInvoiceProductRow from "./ApprovedProductRow";
import useEstablishmentSettings from "../../../../hooks/useEstablishmentSettings";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  lineHeight: 1.2,
  borderBottom: "none",
};

const ApprovedInvoiceTable = ({
  invoice,
  lineItems,
  orderColorMap,
  expenseGroups,
}) => {
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { currencySymbol, currency, locale } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  const calculateTotalUnitCost = () => {
    let total = 0;

    lineItems.forEach((x) => {
      if (x.amount === 0) {
        return;
      }

      if (x.cost?.amount === 0) {
        return;
      }

      total += x.cost?.amount / x.amount;
    });

    return total;
  };

  return (
    <>
      <TableContainer
        sx={{
          // display: "flex",
          // width: "100%",
          overflow: "scroll",
          borderRadius: 2,
          border: "1px solid",
          // height: "100%",
          borderColor: (theme) => theme.palette.terrain[400],
        }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead sx={{ height: "24px" }}>
            <TableRow sx={{ height: 24 }}>
              <TableCell
                sx={[tableHeadStyle, { width: "38px", maxWidth: "38px" }]}
              >
                Link
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "360px" }]}>
                Product Name
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
                Expense Group
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
                Expense SubGroup
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
                Purchase Unit
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
                Quantity
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
                Line Item Total
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
                Unit Cost
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((x) => (
              <ApprovedInvoiceProductRow
                key={x.id}
                lineItem={x}
                orderColorMap={orderColorMap}
                expenseGroups={expenseGroups}
              />
            ))}
            <TableRow
              sx={{
                borderTop: "1px solid",
                borderColor: (theme) => theme.palette.terrain[400],
              }}
            >
              <TableCell colSpan={6} sx={tableHeadStyle}>
                Total:
              </TableCell>
              <TableCell sx={tableHeadStyle}>
                <Typography variant="body1">
                  {currencySymbol}
                  {utilFunctions.convertCentsToLargerFormatCurrency(
                    invoice.finalLineItemTotal?.amount || 0,
                    locale,
                    currency
                  )}
                </Typography>
              </TableCell>
              <TableCell sx={tableHeadStyle}>
                <Typography variant="body1">
                  {currencySymbol}
                  {utilFunctions.convertCentsToLargerFormatCurrency(
                    calculateTotalUnitCost() || 0,
                    locale,
                    currency
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ApprovedInvoiceTable;
