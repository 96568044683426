import { Alert, Snackbar } from "@mui/material";
import React from "react";

const SnackBarSuccess = ({ alertSuccess, setAlertSuccess, durationLength }) => {
  return (
    <Snackbar
      open={alertSuccess ? true : false}
      onClose={() => setAlertSuccess(undefined)}
      autoHideDuration={durationLength}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity="success" variant="filled">
        {alertSuccess}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarSuccess;
