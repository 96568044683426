import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { modalBoxStyle } from "../styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TabularOrderItemList from "./components/TabularOrderItemList";
import CTABlock from "../../CTABlock";

const ManageLinkedInvoiceLineItemModal = ({
  isOpen,
  handleCloseModal,
  lineItem,
  invoiceLineItems,
  completeLink,
  currentLinkedOrders,
  openLinkOrdersModalCallback,
  isLoading,
  orderColorMap,
}) => {
  const [itemToLink, setItemToLink] = useState(undefined);
  const [unlinkedOrderItemsMap, setUnlinkedOrderItemsMap] = useState({});
  const [skipLineItemMapping, toggleSkipLineItemMapping] = useState(false);

  useEffect(() => {
    // we build a map of currently linked orders to their unlinked items. We start by mapping all
    // the linked order line items, and then below we iterate through each order and its line items
    // to check if it was seen in the first map. If not, we add it to an array that is passed to
    // the list below. This will allow creating tabular list data with only the unlinked items
    // being displayed
    let alreadyLinkedOrderLineItemsMap = {};

    invoiceLineItems.forEach((x) => {
      if (x.orderLineItemLinkId !== null) {
        alreadyLinkedOrderLineItemsMap[x.orderLineItemLinkId] = true;
      }
    });

    let tempUnlinkedOrderItemsMap = {};

    currentLinkedOrders.forEach((x) => {
      tempUnlinkedOrderItemsMap[x.id] = [];

      x.lineItems.forEach((l) => {
        if (alreadyLinkedOrderLineItemsMap[l.id] !== true) {
          tempUnlinkedOrderItemsMap[x.id].push(l);
        }
      });
    });
    setUnlinkedOrderItemsMap(tempUnlinkedOrderItemsMap);
  }, [currentLinkedOrders, lineItem]);

  const handleClose = () => {
    setUnlinkedOrderItemsMap({});
    setItemToLink(undefined);
    toggleSkipLineItemMapping(false);
    handleCloseModal(false);
  };

  const handleLinkItem = async () => {
    await completeLink(lineItem, itemToLink, skipLineItemMapping);
    handleClose();
  };

  const handleNoOrdersCallback = () => {
    openLinkOrdersModalCallback();
    handleClose();
  };

  const handleToggleSkipLineItemMapping = () => {
    toggleSkipLineItemMapping(!skipLineItemMapping);
  };

  if (currentLinkedOrders === undefined || currentLinkedOrders?.length === 0) {
    return (
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={[modalBoxStyle]}>
          <Grid
            container
            sx={{
              marginBottom: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h4"></Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton onClick={handleClose} sx={{ marginRight: -4 }}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <CTABlock
            handleCTA={handleNoOrdersCallback}
            buttonText="Link Orders"
            header={"No orders have been linked to this invoice"}
            subheader="Click below to being linking orders"
          />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={[modalBoxStyle, { maxHeight: "650px", width: "400px !important" }]}
      >
        <Grid
          container
          sx={{
            marginBottom: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="h4">Link Line Item to Order</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton onClick={handleClose} sx={{ marginRight: -4 }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        {lineItem?.orderLineItemLinkId !== null &&
          lineItem?.orderLineItemLinkId !== undefined && (
            <Box sx={{ backgroundColor: orderColorMap[lineItem.orderLinkId].primary, padding: 2, borderRadius: 2}}>
              <Box>
                <Box>
                  <Typography variant="body1">
                    Currently linked order line item:
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    {currentLinkedOrders
                      .find((x) => x.id === lineItem.orderLinkId)
                      ?.lineItems.find(
                        (x) => x.id === lineItem.orderLineItemLinkId
                      )?.orderLineItemText || ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        <TabularOrderItemList
          selectedOrders={currentLinkedOrders}
          unlinkedOrderItemsMap={unlinkedOrderItemsMap}
          setItemToLink={setItemToLink}
          itemToLink={itemToLink}
          skipLineItemMapping={skipLineItemMapping}
          toggleSkipLineItemMapping={handleToggleSkipLineItemMapping}
        />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={handleLinkItem}
            disabled={itemToLink === undefined || isLoading}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography variant="largeButton">Link Item</Typography>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ManageLinkedInvoiceLineItemModal;
