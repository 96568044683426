import { SvgIcon } from "@mui/material";
import React from "react";

const MobileAlertIcon = (props) => {
  return (
    <SvgIcon
      width="106"
      height="109"
      viewBox="0 0 106 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20.126 6.48349C23.3929 4.84764 27.42 4.78563 30.8652 5.25067C32.5514 5.47828 34.0154 5.8207 35.0548 6.10578C35.5729 6.24787 35.9812 6.37451 36.2539 6.46341C36.3901 6.50782 36.4922 6.5427 36.5568 6.56524C36.5891 6.5765 36.612 6.58467 36.6252 6.58939L36.6347 6.59283L36.6363 6.5934C37.9294 7.07039 39.3642 6.40794 39.8415 5.11316C40.3189 3.81786 39.6842 2.37972 38.3906 1.90163H38.3623L38.3607 1.90101L38.3566 1.89952L38.3456 1.89547L38.3118 1.88327C38.2842 1.87335 38.2464 1.85992 38.1989 1.84335C38.1039 1.81021 37.9697 1.76446 37.7997 1.70904C37.46 1.59828 36.9758 1.44847 36.3739 1.28339C35.1734 0.954139 33.4871 0.559353 31.5323 0.295493C27.6955 -0.222402 22.4777 -0.284413 17.8927 2.01149C13.6039 4.15903 9.26017 7.95163 6.08683 11.0615C4.47565 12.6404 3.11842 14.0912 2.1629 15.1484C1.6846 15.6776 1.30555 16.1098 1.04414 16.4119C0.913404 16.563 0.811989 16.6817 0.742195 16.7639C0.707295 16.805 0.680291 16.837 0.661471 16.8594L0.63941 16.8857L0.630339 16.8965L0.548431 17C-0.335666 18.0596 -0.11317 19.5333 0.944976 20.4185C2.003 21.3037 3.57756 21.1622 4.46172 20.103L4.46468 20.0994L4.47997 20.0812C4.49421 20.0643 4.51652 20.0379 4.54662 20.0024C4.6068 19.9315 4.69807 19.8247 4.81815 19.6859C5.05836 19.4082 5.41345 19.0033 5.86518 18.5035C6.76974 17.5027 8.05617 16.1276 9.57941 14.6349C12.6749 11.6014 16.5629 8.26768 20.126 6.48349Z" />
      <path d="M72.5582 63.4508C71.486 59.4925 68.3555 55.7921 63.9202 53.141C62.0848 56.4592 61 60.5607 61 65C61 76.0457 67.7157 85 76 85C84.2843 85 91 76.0457 91 65C91 53.9543 84.2843 45 76 45C72.9593 45 70.1298 46.2064 67.7663 48.2798C71.9045 51.8935 74.0783 56.7194 73.1372 61.4771C73.0023 62.1588 72.8078 62.8174 72.5582 63.4508Z" />
      <path d="M17.9202 53.141C22.3555 55.7921 25.486 59.4925 26.5582 63.4508C26.8078 62.8174 27.0023 62.1588 27.1372 61.4771C28.0783 56.7194 25.9045 51.8935 21.7663 48.2798C24.1298 46.2064 26.9592 45 30 45C38.2843 45 45 53.9543 45 65C45 76.0457 38.2843 85 30 85C21.7157 85 15 76.0457 15 65C15 60.5607 16.0848 56.4592 17.9202 53.141Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53 91.1041C47.4968 102.044 39.2358 109 30 109C13.4315 109 0 86.6143 0 59C0 31.3858 13.4315 9.00004 30 9.00004C39.2358 9.00004 47.4968 15.9559 53 26.8959C58.5032 15.9559 66.7642 9.00004 76 9.00004C92.5685 9.00004 106 31.3858 106 59C106 86.6143 92.5685 109 76 109C66.7642 109 58.5032 102.044 53 91.1041ZM55.6084 85.0592C60.3707 94.7703 67.735 101 76 101C90.3594 101 102 82.196 102 59C102 35.8041 90.3594 17 76 17C67.735 17 60.3707 23.2297 55.6084 32.9409C58.3943 40.5294 60 49.4522 60 59C60 68.5479 58.3943 77.4706 55.6084 85.0592ZM50.3916 85.0592C45.6293 94.7703 38.265 101 30 101C15.6406 101 4 82.196 4 59C4 35.8041 15.6406 17 30 17C38.265 17 45.6293 23.2297 50.3916 32.9409C50.4126 32.9838 50.4336 33.0268 50.4546 33.0698C51.4149 35.0438 52.2681 37.1604 53 39.3965C54.9154 45.2485 56 51.9197 56 59C56 66.0804 54.9154 72.7515 53 78.6036C52.9544 78.7428 52.9084 78.8815 52.8619 79.0197C52.1452 81.151 51.3176 83.1709 50.3916 85.0592Z"
      />
      <path d="M75.1348 5.25073C78.58 4.78568 82.6071 4.84769 85.874 6.48354C89.4371 8.26774 93.3251 11.6014 96.4206 14.635C97.9438 16.1277 99.2303 17.5028 100.135 18.5036C100.587 19.0034 100.942 19.4083 101.182 19.686C101.302 19.8247 101.393 19.9316 101.453 20.0025C101.483 20.038 101.506 20.0644 101.52 20.0813L101.535 20.0995L101.538 20.1031C102.422 21.1623 103.997 21.3038 105.055 20.4186C106.113 19.5333 106.336 18.0597 105.452 17.0001L105.37 16.8966L105.361 16.8857L105.339 16.8595C105.32 16.8371 105.293 16.8051 105.258 16.764C105.188 16.6818 105.087 16.5631 104.956 16.412C104.694 16.1098 104.315 15.6777 103.837 15.1485C102.882 14.0913 101.524 12.6404 99.9132 11.0615C96.7398 7.95169 92.3961 4.15908 88.1073 2.01153C83.5223 -0.284375 78.3045 -0.222365 74.4677 0.295532C72.5129 0.559392 70.8266 0.95418 69.6261 1.28343C69.0242 1.44851 68.54 1.59832 68.2003 1.70908C68.0303 1.7645 67.8961 1.81025 67.8011 1.84339C67.7536 1.85996 67.7158 1.87339 67.6882 1.88331L67.6544 1.89552L67.6434 1.89956L67.6376 1.90167H67.6094C66.3158 2.37977 65.6811 3.81791 66.1585 5.11321C66.6358 6.40799 68.0706 7.07045 69.3637 6.59345L69.3653 6.59288L69.3748 6.58944C69.388 6.58472 69.4109 6.57656 69.4432 6.56529C69.5078 6.54276 69.6099 6.50788 69.7461 6.46346C70.0188 6.37457 70.4271 6.24793 70.9452 6.10583C71.9846 5.82075 73.4486 5.47833 75.1348 5.25073Z" />
    </SvgIcon>
  );
};

export default MobileAlertIcon;
