import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { invoiceTotalsByVendorSortOption } from "../../../../../clients/inventoryObjects";

const toggleButtonStyle = {
  borderTopRightRadius: "8px !important",
  borderBottomRightRadius: "8px !important",
  borderTopLeftRadius: "8px !important",
  borderBottomLeftRadius: "8px !important",
  border: "none",
  backgroundColor: (theme) => theme.palette.terrain[200],
  "&.Mui-selected, &.Mui-selected:hover": {
    color: (theme) => theme.palette.pure.white,
    backgroundColor: (theme) => theme.palette.terrain[900],
  },
};

const SortByVendor = ({ vendorSortChoice, setVendorSortChoice }) => {
  const handleSortChoice = (_, v) => {
    setVendorSortChoice(v);
  };

  return (
    <Box>
      <Box>
        <Typography variant="h5">Sort By Vendor</Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <ToggleButtonGroup
          value={vendorSortChoice}
          onChange={handleSortChoice}
          exclusive
        >
          <ToggleButton
            value={invoiceTotalsByVendorSortOption.vendorsAtoZ}
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              A-Z
            </Typography>
          </ToggleButton>
          <ToggleButton
            value={invoiceTotalsByVendorSortOption.vendorsZotA}
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Z-A
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default SortByVendor;
