import { Box, Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import OrderStatusChip from "../../../OrderStatusChip";
var moment = require("moment");

const OrderCard = ({ order, selectOrderCallback, selectedOrders, currentLinkedOrders }) => {

  if (currentLinkedOrders.find(x => x.id === order.id)) {
    return null;
  }

  const handleSelectedChange = () => {
    selectOrderCallback(order);
  };

  const selected = selectedOrders.find((x) => x.id === order.id) !== undefined;
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "75px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[400],
        backgroundColor: (theme) =>
          selected ? theme.palette.secondary[900] : theme.palette.terrain[50],
        padding: 2,
      }}
    >
      <Box>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: 0,
          }}
        >
          <Grid item xs={7}>
            <Grid container>
              <Grid item>
                <Checkbox
                  sx={{
                    color: (theme) => theme.palette.primary[800],
                    marginLeft: -2,
                  }}
                  checked={selected}
                  onChange={handleSelectedChange}
                  disabled={
                    !selected && selectedOrders.length + currentLinkedOrders.length >= 3 ? true : false
                  }
                />
              </Grid>
              <Grid item>
                <Box>
                  <div
                    style={{
                      maxWidth: "140px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Typography
                      noWrap
                      variant="subtitle2"
                      sx={{
                        color: (theme) =>
                          selected
                            ? theme.palette.pure.white
                            : theme.palette.pure.black,
                      }}
                    >
                      {order?.vendor?.name || ""}
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) =>
                        selected
                          ? theme.palette.pure.white
                          : theme.palette.pure.black,
                    }}
                  >
                    {moment(order.createdDate).format("MM-DD-YYYY")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              justifyContent: "flex-end !important",
            }}
          >
            <OrderStatusChip status={order.orderStatus} />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <div
          style={{
            maxWidth: "320px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography
            noWrap
            variant="body1"
            sx={{
              color: (theme) =>
                selected ? theme.palette.pure.white : theme.palette.pure.black,
            }}
          >
            {order.orderText}
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default OrderCard;
