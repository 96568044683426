import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Alert, Menu, MenuItem, Snackbar } from "@mui/material";
import SpecBackButton from "../../../components/SpecBackButton";
import RecipeDetailDisplay from "../../../components/DrinkDetailDisplays/RecipeDetailDisplay";
import WineDetailDisplay from "../../../components/DrinkDetailDisplays/WineDetailDisplay";
import BeerDetailDisplay from "../../../components/DrinkDetailDisplays/BeerDetailDisplay";
import CopyItemModal from "../../../components/Modals/CopyItemModal";
import utilFunctions from "../../../utilities/utilFunctions";

const SpecClassicCocktailDetails = ({ item, goBack }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [copyItemModalOpen, toggleCopyItemModalOpen] = useState(false);
  const [itemToCopy, setItemToCopy] = useState(undefined);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageContent, setErrorMessageContent] = useState("");
  const [successMessageContent, setSuccessMessageContent] = useState("");

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAlertClose = () => {
    setAlertSuccess(false);
    setErrorMessage(false);
  };

  const handleCopyModal = () => {
    setItemToCopy(item);
    toggleCopyItemModalOpen(!copyItemModalOpen);
  };

  const copyCallback = () => {
    setAlertSuccess(true);
    setSuccessMessageContent("Success! Your item has been copied.");
    toggleCopyItemModalOpen(false);
    handleClose();
  };

  const errorCallback = (errorMessage) => {
    setErrorMessage(true);
    setErrorMessageContent(errorMessage);
  };

  const renderItemDisplayByType = () => {
    switch (item.type) {
      case "recipe":
        return <RecipeDetailDisplay recipe={item} />;
      case "wine":
        return <WineDetailDisplay wine={item} />;
      case "beer":
        return <BeerDetailDisplay beer={item} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ maxWidth: "2440px", padding: 2 }}
      >
        <Box>
          <SpecBackButton onClick={goBack} />
        </Box>
        <Grid
          container
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "2440px",
          }}
        >
          <Grid item sx={{ marginBottom: 8, marginTop: 6 }} md={10}>
            <Typography variant="h2">Drink Details</Typography>
          </Grid>
          <Grid
            item
            md={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {/* <IconButton
              onClick={handleClick}
              variant="outlined"
              sx={{
                height: 40,
                width: 40,
                color: (theme) => theme.palette.primary.main,
                border: "1px solid",
                borderRadius: 2,
              }}
            >
              <MoreHorizIcon
                sx={{ color: (theme) => theme.palette.pure.black }}
              />
            </IconButton> */}
            <Button
              sx={{
                marginLeft: 3,
                height: 40,
                width: 100,
                borderRadius: 2,
                backgroundColor: (theme) => theme.palette.terrain["03"],
                color: (theme) => theme.palette.pure.black,
                padding: 2,
              }}
              onClick={() => true}
              disabled
            >
              <Typography
                variant="smallButton"
                sx={{ color: (theme) => theme.palette.pure.black }}
              >
                Read Only
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ marginBottom: 4, maxWidth: "2440px" }}>
          <Alert
            icon={false}
            sx={{
              backgroundColor: (theme) => theme.palette.terrain["03"],
              color: (theme) => theme.palette.pure.black,
              marginBottom: 6,
            }}
          >
            This menu is a read only format. You are unable to edit this menu.
          </Alert>
          <Typography variant="h4">
            Drink Type: {utilFunctions.capitalizeString(item.type)}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: 4, marginTop: 4 }}>
          {renderItemDisplayByType()}
        </Box>
      </Grid>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleCopyModal} sx={{ margin: "5px" }}>
          <ContentCopyIcon sx={{ marginRight: "5px" }} />
          Copy
        </MenuItem>
      </Menu>
      <CopyItemModal
        isOpen={copyItemModalOpen}
        toggleModalOpen={handleCopyModal}
        item={itemToCopy}
        copyCallback={copyCallback}
        errorCallback={errorCallback}
      />
      <Box>
        <Snackbar
          open={alertSuccess}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {successMessageContent}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessageContent}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default SpecClassicCocktailDetails;
