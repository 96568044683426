/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useContext, memo } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Store from "../../../../Store/Store";
import inventoryClient from "../../../../clients/inventoryClient";
import CreateAutoComplete from "../../../../components/CreateAutoComplete";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import SubdirectoryArrowRightOutlinedIcon from "@mui/icons-material/SubdirectoryArrowRightOutlined";
import { cloneDeep } from "lodash";
import utilFunctions from "../../../../utilities/utilFunctions";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import { MoreHorizOutlined } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import useEstablishmentSettings from "../../../../hooks/useEstablishmentSettings";
import StarIcon from "@mui/icons-material/Star";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { productSizeTypes } from "../../../../clients/inventoryObjects";
import DeletePurchaseUnitModal from "../../../../components/Modals/DeletePurchaseUnitModal";

const tableCellStyle = {
  padding: 0,
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const purchaseUnitDefaultStyle = {
  backgroundColor: (theme) => theme.palette.success[500],
  color: (theme) => theme.palette.pure.white,
  fontWeight: 700,
  height: "24px",
  width: "110px",
  borderRadius: 100,
  borderBottom: "none",
  "& .MuiSvgIcon-root": {
    color: (theme) => theme.palette.pure.white,
  },
};

const PurchaseDetailsTableRow = memo(function PurchaseDetailsTableRow({
  purchaseDetails,
  productFormatId,
  updateProductCallback,
  vendors,
  triggerError,
  expenseGroups,
  openExpenseGroupEdit,
  openExpenseSubgroupEdit,
  deletePurchaseUnit,
  refetchCurrentPageProducts,
}) {
  const { establishmentid } = useParams();
  const [purchaseDetailsCopy, setPurchaseDetailsCopy] = useState({});
  const [priceDisabled, togglePriceDisabled] = useState(false);
  const [unitAmountDisabled, toggleUnitAmountDisabled] = useState(false);
  const [unitNameDisabled, toggleUnitNameDisabled] = useState(false);
  const [skuDisabled, toggleSkuDisabled] = useState(false);
  const [vendorDisabled, toggleVendorDisabled] = useState(false);
  const [statusDisabled, toggleStatusDisabled] = useState(false);
  const [deletePurchaseUnitModal, setDeletePurchaseUnitModal] = useState(false);
  // const [additionalInfoDisabled, toggleAdditionalInfoDisabled] =
  //   useState(false);
  const [priceDisplay, setPriceDisplay] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(establishmentid);
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );
  const queryClient = useQueryClient();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDeletePurchaseUnit = () => {
    setDeletePurchaseUnitModal(!deletePurchaseUnitModal);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deletePurchaseUnit(purchaseDetailsCopy.id, productFormatId);
  };

  useEffect(() => {
    setPurchaseDetailsCopy(purchaseDetails);
    const price = purchaseDetails.price?.amount
      ? utilFunctions.convertCentsToLargerFormatCurrency(
          purchaseDetails.price.amount,
          locale,
          currency
        )
      : "";
    setPriceDisplay(price);
  }, [purchaseDetails]);

  const purchaseUnitStatusStyle = (activeState) => {
    if (activeState === 1) {
      return {
        backgroundColor: (theme) => theme.palette.success[500],
        color: (theme) => theme.palette.pure.white,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.white,
        },
      };
    }
    if (activeState === 2) {
      return {
        backgroundColor: (theme) => theme.palette.error[900],
        color: (theme) => theme.palette.pure.white,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.white,
        },
      };
    }
    if (activeState === 4) {
      return {
        backgroundColor: (theme) => theme.palette.error[200],
        color: (theme) => theme.palette.pure.black,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.black,
        },
      };
    }
    if (activeState === 5) {
      return {
        backgroundColor: (theme) => theme.palette.terrain[400],
        color: (theme) => theme.palette.pure.black,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.black,
        },
      };
    }
  };

  const handleFormatUpdate = async (property) => {
    let response = {};
    switch (property) {
      case "name":
        toggleUnitNameDisabled(true);
        response = await inventoryClient.updatePurchaseDetail(
          {
            id: purchaseDetailsCopy.id,
            nameUpdate: { value: purchaseDetailsCopy[property] },
          },
          store.currentEstablishment?.id
        );

        if (response.error) {
          console.log(response.message);
          triggerError();
        }

        updateProductCallback(purchaseDetailsCopy);
        toggleUnitNameDisabled(false);
        break;
      case "unitAmount":
        toggleUnitAmountDisabled(true);
        response = await inventoryClient.updatePurchaseDetail(
          {
            id: purchaseDetailsCopy.id,
            unitAmountUpdate: { value: purchaseDetailsCopy[property] },
          },
          store.currentEstablishment?.id
        );

        if (response.error) {
          console.log(response.message);
          triggerError();
        }

        updateProductCallback(response.data?.purchaseUnit);
        toggleUnitAmountDisabled(false);
        break;
      case "price":
        togglePriceDisabled(true);
        if (purchaseDetailsCopy.price === null) {
          togglePriceDisabled(false);
          return;
        }
        response = await inventoryClient.updatePurchaseDetail(
          {
            id: purchaseDetailsCopy.id,
            priceUpdate: { value: purchaseDetailsCopy.price },
          },
          store.currentEstablishment?.id
        );

        if (response.error) {
          console.log(response.message);
          triggerError();
        }

        updateProductCallback(response.data?.purchaseUnit);
        togglePriceDisabled(false);
        break;
      case "sku":
        toggleSkuDisabled(true);
        response = await inventoryClient.updatePurchaseDetail(
          {
            id: purchaseDetailsCopy.id,
            skuUpdate: { value: purchaseDetailsCopy[property] },
          },
          store.currentEstablishment?.id
        );

        if (response.error) {
          console.log(response.message);
          triggerError();
        }

        updateProductCallback(purchaseDetailsCopy);
        toggleSkuDisabled(false);
        break;
      case "additionalInformation":
        // toggleAdditionalInfoDisabled(true);
        response = await inventoryClient.updatePurchaseDetail(
          {
            id: purchaseDetailsCopy.id,
            additionalInformationUpdate: {
              value: purchaseDetailsCopy[property],
            },
          },
          store.currentEstablishment?.id
        );

        if (response.error) {
          console.log(response.message);
          triggerError();
        }

        updateProductCallback(purchaseDetailsCopy);
        // toggleAdditionalInfoDisabled(false);
        break;
      default:
        console.log("default case hit");
    }
  };

  const purchaseUnitUpdateMutation = useMutation(
    inventoryClient.updatePurchaseUnit,
    {
      onSuccess: (result, variables) => {
        setPurchaseDetailsCopy(result.purchaseUnit);
        updateProductCallback(
          result.purchaseUnit,
          variables?.defaultUpdate?.value || false
        );

        if (variables?.statusUpdate) {
          toggleStatusDisabled(false);
        }
      },
      onError: (err, variables) => {
        console.log(err);
        if (variables?.statusUpdate) {
          toggleStatusDisabled(false);
        }
      }
    }
  );

  const handleSetDefault = async () => {
    purchaseUnitUpdateMutation.mutate({
      id: purchaseDetailsCopy.id,
      establishmentId: store.currentEstablishment?.id,
      defaultUpdate: {
        value: true,
      },
    });
  };
  const handleExpenseGroupUpdate = (e) => {
    if (e.target.id === -1) {
      return;
    }
    purchaseUnitUpdateMutation.mutate({
      establishmentId: establishmentid,
      expenseGroupIdUpdate: { value: e.target.value },
      id: purchaseDetails.id,
    });
  };

  const handleExpenseSubgroupUpdate = (e) => {
    if (e.target.id === -1) {
      return;
    }
    purchaseUnitUpdateMutation.mutate({
      establishmentId: establishmentid,
      expenseSubgroupIdUpdate: { value: e.target.value },
      id: purchaseDetails.id,
    });
  };

  const handleEditSubgroups = () => {
    openExpenseSubgroupEdit(purchaseDetails.expenseGroupId);
  };

  const handlePriceUpdate = async (e) => {
    const newPrice = e.target.value;
    const centsValue = utilFunctions.convertCurrencyToCents(newPrice);
    handleCopyUpdate("price", centsValue);
    setPriceDisplay(newPrice);
  };

  const handleSelectVendor = async (v) => {
    toggleVendorDisabled(true);
    const response = await inventoryClient.updatePurchaseDetail(
      {
        id: purchaseDetailsCopy.id,
        vendorUpdate: { value: v.id },
      },
      store.currentEstablishment?.id
    );

    if (response.error) {
      console.log(response.message);
      triggerError();
    }

    const newState = cloneDeep(purchaseDetailsCopy);
    newState.vendor = v;
    newState.vendorId = v.id;
    updateProductCallback(newState);
    toggleVendorDisabled(false);
  };

  const createNewVendorMutation = useMutation(inventoryClient.addVendors, {
    onSuccess: (result) => {
      handleSelectVendor(result.vendors[0]);

      queryClient.invalidateQueries({
        queryKey: [
          "vendors",
          {
            establishmentId: establishmentid,
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "vendors",
          {
            establishmentId: establishmentid,
          },
        ],
      });
    },
  });

  const handleCreateVendor = (v) => {
    const payload = {
      vendors: [{ name: v.name }],
      establishmentId: store.currentEstablishment?.id,
    };
    createNewVendorMutation.mutate(payload);
  };

  const handleClearVendor = async () => {
    toggleVendorDisabled(true);
    const response = await inventoryClient.updatePurchaseDetail(
      {
        id: purchaseDetailsCopy.id,
        vendorUpdate: { value: null },
      },
      store.currentEstablishment?.id
    );

    if (response.error) {
      console.log(response.message);
      triggerError();
    }

    const newState = cloneDeep(purchaseDetailsCopy);
    newState.vendor = null;
    newState.vendorId = null;
    updateProductCallback(newState);
    toggleVendorDisabled(false);
  };

  const handleCopyUpdate = (property, newValue) => {
    const newData = Object.assign({}, purchaseDetailsCopy);
    if (property === "price") {
      if (!newData.price) {
        newData.price = {};
      }
      newData.price.amount = newValue;
    } else {
      newData[property] = newValue;
    }
    setPurchaseDetailsCopy(newData);
    return newData;
  };

  const handleStatusUpdate = async (e) => {
    const newStatus = e.target.value;
    const newState = handleCopyUpdate("status", newStatus);
    toggleStatusDisabled(true);
    await purchaseUnitUpdateMutation.mutateAsync({
      establishmentId: establishmentid,
      statusUpdate: { value: e.target.value },
      id: purchaseDetails.id,
    });
    updateProductCallback(newState);
    refetchCurrentPageProducts();
  };

  const getCostPerDisplay = () => {
    if (!purchaseDetailsCopy?.costPer?.isValid) {
      return '';
    }
    
    if (purchaseDetailsCopy?.costPerUnitSizeType === productSizeTypes.Volumetric) {
      return 'cost per fl. oz';
    }

    if (purchaseDetailsCopy?.costPerUnitSizeType === productSizeTypes.Mass) {
      return 'cost per oz';
    }

    return 'cost per unit';
  }

  return (
    <>
    <TableRow>
      <TableCell
        sx={{
          borderLeft: "none",
          borderBottom: "none",
          borderColor: (theme) => theme.palette.terrain[400],
          backgroundColor: (theme) => theme.palette.pure.white,
          padding: 0,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {purchaseDetails?.isDefault ? (
            <StarIcon
              sx={{
                marginRight: 2,
                color: (theme) => theme.palette.warning[400],
              }}
            />
          ) : null}
          <SubdirectoryArrowRightOutlinedIcon
            sx={{
              color: (theme) => theme.palette.secondary[600],
              fontSize: 18,
              marginRight: 2,
            }}
          />
        </Box>
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <TextField
          size="small"
          fullWidth
          variant="filled"
          id="name"
          autoComplete="off"
          placeholder="Unit Name"
          onKeyDown={utilFunctions.blurInput}
          disabled={unitNameDisabled}
          onBlur={() => handleFormatUpdate("name")}
          InputProps={{ disableUnderline: true }}
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          value={purchaseDetailsCopy.name || ""}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />
      </TableCell>
      <TableCell sx={tableCellStyle}>
        {" "}
        <TextField
          size="small"
          fullWidth
          variant="filled"
          type="number"
          autoComplete="off"
          onKeyDown={utilFunctions.blurInput}
          id="unitAmount"
          placeholder="Unit Amount"
          disabled={unitAmountDisabled}
          onBlur={() => handleFormatUpdate("unitAmount")}
          InputProps={{ disableUnderline: true, inputMode: "numeric" }}
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          value={purchaseDetailsCopy.unitAmount || ""}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />{" "}
      </TableCell>
      <TableCell sx={tableCellStyle}>
        {" "}
        <TextField
          size="small"
          fullWidth
          variant="filled"
          id="price"
          autoComplete="off"
          type="text"
          placeholder="Price"
          onKeyDown={utilFunctions.blurInput}
          disabled={priceDisabled}
          onBlur={() => handleFormatUpdate("price")}
          InputProps={{
            disableUnderline: true,
            inputMode: "numeric",
            startAdornment: (
              <InputAdornment
                sx={{ marginTop: 4, marginLeft: 2 }}
                position="start"
              >
                {currencySymbol}
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          inputProps={{ max: 1000000 }}
          value={priceDisplay}
          onChange={handlePriceUpdate}
        />{" "}
      </TableCell>
      <TableCell sx={tableCellStyle}>
          <>
          <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "67px",
            height: "24px",
            borderRadius: 1,
            backgroundColor: (theme) => theme.palette.terrain[100],
            margin: "auto",
          }}
        >
          {purchaseDetailsCopy?.costPer?.isValid ? utilFunctions.convertCentsToLargerFormatCurrency(purchaseDetailsCopy?.costPer?.result.amount, locale, currency) : '-'}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 1,
          }}
        >
          <Typography variant="overline" sx={{ margin: "auto" }}>
          {getCostPerDisplay()}
          </Typography>
        </Box></>
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <CreateAutoComplete
          disabled={vendorDisabled || createNewVendorMutation.isLoading}
          options={vendors}
          getOptionLabel={(option) => option.name}
          value={purchaseDetailsCopy.vendor || { id: -1, name: "" }}
          handleSelect={handleSelectVendor}
          handleNew={handleCreateVendor}
          handleClear={handleClearVendor}
          isOptionEqualToFunc={(o) =>
            o.id === purchaseDetailsCopy.vendor?.id || o.id === -1
          }
          label="Vendor"
          tableView
          modalHeader="Create New Vendor"
          modalText="Are you sure you want to create a new Vendor? All vendors can be edited in the Distributors section."
          modalIcon={<LocalShippingOutlinedIcon sx={{ fontSize: "80px" }} />}
          useCreateModal
        />
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <TextField
          size="small"
          fullWidth
          variant="filled"
          id="sku"
          autoComplete="off"
          disabled={skuDisabled}
          placeholder="SKU"
          value={purchaseDetailsCopy.sku || ""}
          onBlur={() => handleFormatUpdate("sku")}
          InputProps={{ disableUnderline: true }}
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <Select
          fullWidth
          variant="standard"
          disableUnderline
          size="small"
          select
          label="Expense Group"
          autoComplete="off"
          InputLabelProps={{ shrink: "true" }}
          value={purchaseDetails.expenseGroupId || ""}
          sx={{ height: "34px", paddingLeft: 2 }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.primary[800],
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: (theme) => theme.palette.secondary[600],
                },
                "& .MuiMenuItem-root.Mui-selected:hover": {
                  backgroundColor: (theme) => theme.palette.secondary[800],
                },
              },
            },
          }}
          disabled={purchaseUnitUpdateMutation.isLoading}
          onChange={handleExpenseGroupUpdate}
        >
          {expenseGroups?.map((x) => (
            <MenuItem value={x.id} key={x.id}>
              {x.name}
            </MenuItem>
          ))}
          <MenuItem value={-1}>
            <Button variant="text" onClick={() => openExpenseGroupEdit()}>
              <Typography variant="smallButton">+ Create New</Typography>
            </Button>
          </MenuItem>
        </Select>
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <Select
          fullWidth
          variant="standard"
          disableUnderline
          size="small"
          select
          label="Subgroup"
          autoComplete="off"
          InputLabelProps={{ shrink: "true" }}
          value={purchaseDetails.expenseSubgroupId || ""}
          sx={{ height: "34px", paddingLeft: 2 }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.primary[800],
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: (theme) => theme.palette.secondary[600],
                },
                "& .MuiMenuItem-root.Mui-selected:hover": {
                  backgroundColor: (theme) => theme.palette.secondary[800],
                },
              },
            },
          }}
          disabled={
            !purchaseDetails?.expenseGroupId ||
            purchaseUnitUpdateMutation.isLoading
          }
          onChange={handleExpenseSubgroupUpdate}
        >
          {expenseGroups
            ?.find((x) => x.id === purchaseDetails.expenseGroupId)
            ?.expenseSubgroups?.map((x) => (
              <MenuItem value={x.id} key={x.id}>
                {x.name}
              </MenuItem>
            ))}
          <MenuItem value={-1}>
            <Button variant="text" onClick={handleEditSubgroups}>
              <Typography variant="smallButton">+ Create New</Typography>
            </Button>
          </MenuItem>
        </Select>
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            borderRight: "none",
            margin: "auto",
            height: "100%",
            width: "120px",
            paddingLeft: 2,
          },
        ]}
      >
        <Select
          id="status"
          label="Status"
          value={purchaseDetailsCopy.status || 1}
          onChange={handleStatusUpdate}
          disabled={statusDisabled}
          sx={
            purchaseDetailsCopy.status
              ? purchaseUnitStatusStyle(purchaseDetailsCopy.status)
              : purchaseUnitDefaultStyle
          }
        >
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={2}>Inactive</MenuItem>
        </Select>
      </TableCell>
      <TableCell sx={[tableCellStyle, { borderRight: "none" }]}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            sx={{ width: "32px", height: "32px" }}
            onClick={handleClick}
          >
            <MoreHorizOutlined
              sx={{
                color: (theme) => theme.palette.primary[800],
                fontSize: 20,
              }}
            />
          </IconButton>
        </Box>
      </TableCell>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* <MenuItem sx={{ margin: "5px" }}>
          <OpenInFullOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
          <Typography variant="body1">Expand</Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "5px" }}>
          <ContentCopyOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
          <Typography variant="body1">Duplicate</Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "5px" }}>
          <CallMergeOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
          <Typography variant="body1">Merge</Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "5px" }}>
          <ExitToAppOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
          <Typography variant="body1">Go To Product</Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "5px" }}>
          <ExitToAppOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
          <Typography variant="body1">Go To Purchase Units</Typography>
        </MenuItem>
        <MenuItem sx={{ margin: "5px" }} disabled={purchaseDetailsCopy.isDefault} onClick={handleDelete}>
        </MenuItem> */}
        {purchaseDetails.isDefault ? null : (
          <MenuItem
            sx={{ margin: "5px" }}
            onClick={handleSetDefault}
            disabled={purchaseUnitUpdateMutation?.isLoading}
          >
            <StarOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
            <Typography variant="body1">Make Default</Typography>
          </MenuItem>
        )}
        <MenuItem
          sx={{ margin: "5px" }}
          disabled={purchaseDetailsCopy.isDefault}
          onClick={toggleDeletePurchaseUnit}
        >
          <DeleteOutlineOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
          <Typography variant="body1">Delete</Typography>
        </MenuItem>
      </Menu>
    </TableRow>
    <DeletePurchaseUnitModal
    deleteCallback={handleDelete}
    isOpen={deletePurchaseUnitModal}
    toggleModalOpen={toggleDeletePurchaseUnit}
    />
    </>
  );
});

export default PurchaseDetailsTableRow;
