import { Box, Grid, Typography } from "@mui/material";
// import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import React from "react";

const MenuDataCard = ({
  headerText,
  dataText,
  prefixAdornment,
  suffixAdornment,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "186px",
        height: "66px",
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.pure.white,
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[300],
        padding: 2,
      }}
    >
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Box>
            <Typography variant="body1">{headerText}</Typography>
          </Box>
          <Box>
              <Typography variant="h5">
                {prefixAdornment || ""}
                {dataText}
                {suffixAdornment || ""}
              </Typography>
          </Box>
        </Grid>
        {/* <Grid item>
          <IconButton sx={{ width: "32px", height: "32px" }}>
            <ExpandMoreOutlinedIcon
              sx={{ color: (theme) => theme.palette.primary[800] }}
            />{" "}
          </IconButton>{" "}
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default MenuDataCard;
