import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Alert from "@mui/material/Alert";
import { IconButton, Snackbar, Stack, TextField, Chip } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import SpecBackButton from "../../components/SpecBackButton";
import { useNavigate, useParams } from "react-router-dom";
import CTABlock from "../../components/CTABlock";
import { useMutation, useQueryClient } from "react-query";
import SpecSpinner from "../../components/SpecSpinner";
import SectionListItem from "./components/SectionListItem";
import CreateAreaModal from "../../components/Modals/CreateAreaModal.js";
import Store, { activeRouteKeys } from "../../Store/Store";
import inventoryClient from "../../clients/inventoryClient";
import EditAreaModal from "../../components/Modals/EditAreaModal";
import ProductListModal from "../../components/Modals/ProductListModal";
import SectionPill from "./components/SectionPill";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CreateSectionModal from "../../components/Modals/CreateSectionModal";
import useProductLibrary from "../../hooks/useProductLibrary";
import useAreaDetails from "../../hooks/useAreaDetails";
import DeleteAreaItemModal from "../../components/Modals/DeleteAreaItemModal/index.js";

const AreaDetails = () => {
  const [creating, toggleCreating] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [createModalOpen, toggleCreateModalOpen] = useState(false);
  const [productListModalOpen, toggleProductListModalOpen] = useState(false);
  const [editAreaModalOpen, toggleEditAreaModal] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [addingSection, toggleAddingSection] = useState(false);
  const [areaItemToDelete, setAreaItemToDelete] = useState(undefined);
  const [isDeleteAreaItemModalOpen, toggleIsDeleteAreaItemModalOpen] = useState(false);
  const [isErrorAlert, toggleIsErrorAlert] = useState(false);
  const [searchText, setSearchText] = useState("");
  const store = useContext(Store);
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  let { establishmentid: establishmentId, areaid: areaId } = useParams();

  const {
    isLoading,
    isError,
    data,
    refetch: refetchArea,
  } = useAreaDetails(establishmentId, areaId);

  const [{ isLoading: isLoadingInventory }, productMap] = useProductLibrary(
    store.currentEstablishment?.id
  );

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.areas,
      `spec/${establishmentId}/areas/${areaId}`
    );
  }, []);

  const areaUpdateMutation = useMutation(inventoryClient.updateArea, {
    onSuccess: (result, variables) => {
      queryClient.setQueryData(
        ["area", { establishmentId, areaId }],
        (prev) => ({
          area: {
            ...prev.area,
            name: variables.payload.nameUpdate.value,
          },
        })
      );

      const existingAreas = queryClient.getQueryData([
        "areas",
        { establishmentId },
      ])?.areas;

      // this ensures the parent page gets updated if the data has been previously loaded and is in a cached state
      if (existingAreas) {
        queryClient.setQueryData(["areas", { establishmentId }], (prev) => {
          const newAreasData = prev.areas.map((x) => {
            if (x.id === areaId) {
              return { ...x, name: variables.payload.nameUpdate.value };
            }

            return x;
          });

          return { areas: newAreasData };
        });
      }

      setAlertSuccess(true);
      toggleEditAreaModal(false);
    },
  });

  const areaProductsMutation = useMutation(inventoryClient.addAreaProducts, {
    onSuccess: refetchArea,
  });

  const deleteAreaItemMutation = useMutation(inventoryClient.removeAreaProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "area", {establishmentId, areaId}
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "area", {establishmentId, areaId}
        ],
      });
      setAlertSuccess(true);
      handleCloseDeleteAreaItemModal();
    },
    onError: () => {
      toggleIsErrorAlert(true);
      handleCloseDeleteAreaItemModal();
    }
  })

  const handleAddProducts = async (selectedOptions) => {
    await areaProductsMutation.mutateAsync({
      payload: {
        establishmentId,
        productFormatIds: selectedOptions,
        areaId: areaId,
      },
    });
    toggleProductListModalOpen(false);
  };

  const handleSectionClick = (sectionId) => {
    if (selected == sectionId) {
      setSelected(undefined);
    } else {
      setSelected(sectionId);
    }
  };

  const handleAlertClose = () => {
    setAlertSuccess(false);
    toggleIsErrorAlert(false);
  };

  const handleSectionCreateToggle = () => {
    toggleCreateModalOpen(!createModalOpen);
  };

  const handleProductListModalToggle = () => {
    toggleProductListModalOpen(!productListModalOpen);
  };

  const handleEditAreaToggle = () => {
    toggleEditAreaModal(!editAreaModalOpen);
  };

  const handleAreaUpdate = async (newName) => {
    const payload = { id: areaId, nameUpdate: { value: newName } };
    areaUpdateMutation.mutate({ payload, establishmentId });
  };

  const toggleCreateSectionModal = () => {
    toggleAddingSection(!addingSection);
  };

  const handleCloseDeleteAreaItemModal = () => {
    setAreaItemToDelete(undefined);
    toggleIsDeleteAreaItemModalOpen(false);
  }

  const handleDeleteAreaItem = () => {
    deleteAreaItemMutation.mutate({areaId, establishmentId, productFormatId: areaItemToDelete?.id});
  }

  const handleOpenDeleteAreaItemModal = (areaItem) => {
    setAreaItemToDelete(areaItem);
    toggleIsDeleteAreaItemModalOpen(true);
  }

  const handleSectionCreate = () => {
    refetchArea();
    toggleAddingSection(false);
    toggleCreating(false);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  }

  if (isLoading || isLoadingInventory) {
    return <SpecSpinner open text={"Loading Area Details..."} />;
  }

  if (creating) {
    return (
      <Grid
        container
        sx={{
          width: "100%",
          maxWidth: "2440px",
          paddingBottom: 8,
          justifySelf: "flex-start",
        }}
      >
        <Grid item xs={12}></Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ maxWidth: "2440px", padding: 4 }}
      >
        <Grid item sx={{ marginLeft: -2 }}>
          <SpecBackButton
            onClick={() =>
              navigate(`/spec/${store.currentEstablishment?.id}/organization/areas`)
            }
            backText="Areas"
          />
        </Grid>
        <Grid item sx={{ marginTop: 6 }}>
          <Grid
            container
            spacing={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} sm={7} md={8}>
              <Grid item>
                <Grid container direction="column">
                  <Grid item sx={{ marginBottom: 4 }}></Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item>
                        <Typography
                          variant="h2"
                          sx={{ marginRight: 2, fontSize: { xs: 20, sm: 28 } }}
                        >
                          {data.area.name}
                        </Typography>
                        {isError && (
                          <Typography color="red">
                            Error while fetching area details.
                          </Typography>
                        )}
                      </Grid>
                      <Grid item sx={{ marginLeft: 2 }}>
                        <IconButton
                          sx={{
                            border: "1px solid",
                            color: (theme) => theme.palette.primary.main,
                            height: "28px",
                            width: "28px",
                            borderRadius: 2,
                          }}
                          variant="outlined"
                          onClick={handleEditAreaToggle}
                        >
                          <EditIcon
                            sx={{
                              width: "12px",
                              color: (theme) => theme.palette.pure.black,
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", sm: "flex-end" },
                }}
              >
                <Button
                  variant="contained"
                  sx={{ borderRadius: 2, padding: 2.5 }}
                  onClick={() => toggleProductListModalOpen(true)}
                >
                  <AddOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                  <Typography variant="smallButton">Add Products</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ marginTop: 6 }}>
            <TextField
              size="small"
              fullWidth
              id="productSearch"
              autoComplete="off"
              value={searchText}
              onChange={handleSearch}
              inputProps={{
                sx: {
                  marginBottom: 1,
                },
              }}
              sx={{
                backgroundColor: (theme) => theme.palette.pure.white,
                borderColor: (theme) => theme.palette.terrain[400],
                marginBottom: 4,
                borderRadius: 1,
                width: { xs: "300px", sm: "400px" },
              }}
              label={
                <>
                  <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                  Search for products...
                </>
              }
            />
          </Box>

          {/* SECTION PILLS */}

          <Box sx={{ marginTop: 4 }}>
            <Grid container>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: { xs: "300px", sm: "100%" },
                  flexWrap: "wrap",
                }}
              >
                <Stack direction="row" spacing={1}>
                  <Chip
                    label={
                      <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.pure.white }}
                      >
                        <AddOutlinedIcon
                          sx={{
                            fontSize: 18,
                            marginBottom: 0.5,
                            marginRight: 1,
                          }}
                        />
                        Create Section
                      </Typography>
                    }
                    clickable
                    onClick={() => toggleAddingSection(true)}
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary[800],
                    }}
                  />
                  {data.area?.sections?.map((s) => (
                    <SectionPill
                      key={s.id}
                      section={s}
                      selected={selected === s.id}
                      clickChip={() => handleSectionClick(s.id)}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              marginTop: 6,
              border: "1px solid",
              borderColor: (theme) => theme.palette.terrain[400],
              borderRadius: 2,
              padding: 0,
              backgroundColor: (theme) => theme.palette.pure.white,
            }}
          >
            {data.area?.productFormats?.length > 0 ? (
              <List
                sx={{
                  width: "100%",
                }}
              >
                {selected === undefined
                  ? searchText ? data.area.productFormats.filter(x => productMap[x.productId]?.name?.toLowerCase()?.includes(searchText?.toLowerCase())).map((p) => (
                      <SectionListItem
                        product={productMap[p.productId]}
                        productFormat={p}
                        key={p.id}
                        areaId={areaId}
                        tags={data?.area.sections}
                        areaName={data?.area.name}
                        establishmentId={establishmentId}
                        triggerAreaRefetch={refetchArea}
                        deleteAreaItem={handleOpenDeleteAreaItemModal}
                      />
                    )) : data.area.productFormats.map((p) => (
                      <SectionListItem
                        product={productMap[p.productId]}
                        productFormat={p}
                        key={p.id}
                        areaId={areaId}
                        tags={data?.area.sections}
                        areaName={data?.area.name}
                        establishmentId={establishmentId}
                        triggerAreaRefetch={refetchArea}
                        deleteAreaItem={handleOpenDeleteAreaItemModal}
                      />
                    )) 
                  : searchText ? data.area.productFormats
                      .filter(
                        (x) =>
                          x.tags.find((t) => t.id === selected) !== undefined
                          && productMap[x.productId]?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
                      )
                      .map((p) => (
                        <SectionListItem
                          product={productMap[p.productId]}
                          productFormat={p}
                          key={p.id}
                          areaId={areaId}
                          tags={data?.area.sections}
                          areaName={data?.area.name}
                          establishmentId={establishmentId}
                          triggerAreaRefetch={refetchArea}
                          deleteAreaItem={handleOpenDeleteAreaItemModal}
                        />
                      )) :
                      data.area.productFormats
                      .filter(
                        (x) =>
                          x.tags.find((t) => t.id === selected) !== undefined
                      )
                      .map((p) => (
                        <SectionListItem
                          product={productMap[p.productId]}
                          productFormat={p}
                          key={p.id}
                          areaId={areaId}
                          tags={data?.area.sections}
                          areaName={data?.area.name}
                          establishmentId={establishmentId}
                          triggerAreaRefetch={refetchArea}
                          deleteAreaItem={handleOpenDeleteAreaItemModal}
                        />
                      ))
                      }
              </List>
            ) : (
              <CTABlock
                header={`You don't have any products added to ${
                  data?.area?.name || "this area"
                }`}
                subheader="Add some products to this area to see it show up organized in your stock counts"
                buttonText="Add Products"
                ButtonImage={AddIcon}
                handleCTA={() => toggleProductListModalOpen(true)}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Snackbar
          open={alertSuccess}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            Success
          </Alert>
        </Snackbar>
        <Snackbar
          open={isErrorAlert}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            Error
          </Alert>
        </Snackbar>
      </Box>
      <CreateAreaModal
        buttonText="Create Section"
        headerText="Create New Section"
        isOpen={createModalOpen}
        toggleModalOpen={handleSectionCreateToggle}
      />
      <EditAreaModal
        buttonText="Confirm"
        headerText="Edit Area"
        isOpen={editAreaModalOpen}
        area={data.area}
        toggleModalOpen={handleEditAreaToggle}
        updateArea={handleAreaUpdate}
        loading={areaUpdateMutation.isLoading}
      />
      <ProductListModal
        isOpen={productListModalOpen}
        toggleModalOpen={handleProductListModalToggle}
        area={data.area}
        addProducts={handleAddProducts}
      />
      <CreateSectionModal
        areaId={data.area.id}
        establishmentId={establishmentId}
        isOpen={addingSection}
        productFormats={data.area.productFormats}
        productMap={productMap}
        toggleModalOpen={toggleCreateSectionModal}
        successCallback={handleSectionCreate}
      />
      <DeleteAreaItemModal
        isOpen={isDeleteAreaItemModalOpen}
        productFormat={areaItemToDelete}
        handleDelete={handleDeleteAreaItem}
        toggleModalOpen={handleCloseDeleteAreaItemModal}
        productMap={productMap}
        isLoading={deleteAreaItemMutation.isLoading}
      />
    </>
  );
};

export default AreaDetails;
