import React, { useContext } from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import OrderLinkIcon from "../../../components/CustomIcons/OrderLinkIcon";
import Store from "../../../Store/Store";
import utilFunctions from "../../../utilities/utilFunctions";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";
var moment = require("moment");

const OrderPill = ({ order, color, toggleOrderFilter, orderForFilter }) => {
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { currencySymbol, currency, locale } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  const handleSelect = () => {
    toggleOrderFilter(order);
  };

  const selected = orderForFilter === undefined ? false : true;

  return (
    <Chip
      label={
        <Box sx={{ padding: 1 }}>
          <Grid
            container
            direction="row"
            sx={{ display: "flex", alignItems: "center", padding: 2 }}
          >
            <Grid item>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.terrain[600],
                    fontSize: 10,
                  }}
                >
                  {moment(order.createdDate).format("MM-DD-YYYY")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 10,
                    color: (theme) => theme.palette.terrain[600],
                  }}
                >
                  {currencySymbol}
                  {utilFunctions.convertCentsToLargerFormatCurrency(
                    order.estimatedCost.amount,
                    locale,
                    currency
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
      avatar={
        <Box
          sx={{
            borderRadius: "100%",
            width: "32px",
            height: "32px",
            backgroundColor: color,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OrderLinkIcon sx={{ fontSize: 18 }} />
        </Box>
      }
      size="medium"
      clickable
      variant="outlined"
      sx={{
        backgroundColor: color,
        border: "none",
        marginRight: 2,
        borderBottomLeftRadius: selected && 0,
        borderBottomRightRadius: selected && 0,
        opacity:
          orderForFilter !== undefined && orderForFilter !== order.id && 0.5,
        padding: 1,
      }}
      onClick={() => handleSelect()}
    />
  );
};

export default OrderPill;
