import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import utilFunctions from "../../../utilities/utilFunctions";
import Store from "../../../Store/Store";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import { useMutation } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import inventoryObjects from "../../../clients/inventoryObjects";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";
import ReconciledIndicator from "../../../components/ReconciledIndicator";

const tableCellStyle = {
  height: "60px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const MiscItemRow = ({
  miscItem,
  updateCache,
  invoiceId,
  expenseGroups,
  openSubgoupEditModal,
  toggleIsExpenseGroupModalOpen,
  invalidLineItems,
}) => {
  const [miscItemCopy, setMiscItemCopy] = useState({});
  const [costDisplay, setCostDisplay] = useState(0);
  const store = useContext(Store);
  const [reconciled, setReconciled] = useState(false);
  const [validationFailures, setValidationFailures] = useState([]);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings.inventorySettings?.currency
  );

  useEffect(() => {
    setMiscItemCopy(miscItem);
    setCostDisplay(
      utilFunctions.convertCentsToLargerFormatCurrency(
        miscItem?.cost?.amount || 0,
        locale,
        currency
      )
    );
    
    if (invalidLineItems[miscItem?.id]) {
      setReconciled(false);
      setValidationFailures(invalidLineItems[miscItem?.id]?.reasons)
    } else {
      setReconciled(true);
      setValidationFailures([]);
    }
  }, [invalidLineItems]);

  const miscItemMutation = useMutation(inventoryClient.patchMiscLineItem, {
    onSuccess: () => {
      updateCache();
    },
  });

  const deleteMiscItemMutation = useMutation(
    inventoryClient.deleteInvoiceMiscItem,
    {
      onSuccess: () => {
        updateCache();
      },
    }
  );

  const handleUpdateMiscItem = (property, value) => {
    const payload = {
      establishmentId: miscItem.establishmentId,
      invoiceId,
      lineItemId: miscItem.id,
    };
    payload[`${property}Update`] = { value: value };
    miscItemMutation.mutate(payload);
  };

  const updateMiscItemCopy = (property, value) => {
    const miscItemUpdates = Object.assign({}, miscItemCopy);
    miscItemUpdates[property] = value;
    setMiscItemCopy(miscItemUpdates);
  };

  const handleOnBlur = (property) => {
    const payload = {
      establishmentId: miscItem.establishmentId,
      invoiceId,
      lineItemId: miscItem.id,
    };
    payload[`${property}Update`] = { value: miscItemCopy[property] };
    miscItemMutation.mutate(payload);
  };

  const handleExpenseGroupUpdate = (e) => {
    updateMiscItemCopy("expenseGroupId", e.target.value);
    handleUpdateMiscItem("expenseGroupId", e.target.value);
  };

  const handleExpenseSubgroupUpdate = (e) => {
    updateMiscItemCopy("expenseSubgroupId", e.target.value);
    handleUpdateMiscItem("expenseSubgroupId", e.target.value);
  };

  const handleEditSubgroup = () => {
    const expenseParent = expenseGroups?.find(
      (x) => x.id === miscItemCopy.expenseGroupId
    );
    openSubgoupEditModal(expenseParent);
  };

  return (
    <TableRow>
      <TableCell sx={[tableCellStyle, { width: "44px", borderLeft: "none" }]}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReconciledIndicator reconciled={reconciled} validationFailures={validationFailures} />
        </Box>
      </TableCell>
      <TableCell
        sx={[tableCellStyle, { width: "160px", maxWidth: "160px !important" }]}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ToggleButtonGroup
            sx={{ height: "34px", marginLeft: 2 }}
            value={miscItemCopy?.type}
            onChange={(e, newType) => {
              updateMiscItemCopy("type", newType);
              handleUpdateMiscItem("type", newType);
            }}
            exclusive
          >
            <ToggleButton value={inventoryObjects.miscLineItemTypes.expense}>
              <Typography
                variant="body2"
                sx={{
                  textTransform: "none",
                  fontSize: { xs: 6, md: 8, lg: 12 },
                }}
              >
                Expense
              </Typography>
            </ToggleButton>
            <ToggleButton value={inventoryObjects.miscLineItemTypes.credit}>
              {" "}
              <Typography
                variant="body2"
                sx={{
                  textTransform: "none",
                  fontSize: { xs: 6, md: 8, lg: 12 },
                }}
              >
                Credit
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "340px" }]}>
        <TextField
          size="small"
          variant="standard"
          fullWidth
          id="name"
          autoComplete="off"
          InputLabelProps={{ shrink: "true" }}
          InputProps={{ disableUnderline: "true" }}
          value={miscItemCopy?.name || ""}
          placeholder="Miscellaneous Name"
          onChange={(e) => updateMiscItemCopy("name", e.target.value)}
          onBlur={() => handleOnBlur("name")}
          onKeyDown={utilFunctions.blurInput}
        />
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "140px" }]}>
        <Select
          fullWidth
          variant="standard"
          disableUnderline
          size="small"
          select
          label="Expense Group"
          autoComplete="off"
          InputLabelProps={{ shrink: "true" }}
          value={miscItemCopy.expenseGroupId || ""}
          sx={{ height: "34px", width: "100%", marginLeft: 2 }}
          disabled={miscItemMutation.isLoading}
          onChange={handleExpenseGroupUpdate}
        >
          {expenseGroups?.map((x) => (
            <MenuItem value={x.id} key={x.id}>
              {x.name}
            </MenuItem>
          ))}
          <MenuItem>
            <Button
              variant="text"
              onClick={() => toggleIsExpenseGroupModalOpen(true)}
            >
              <Typography variant="smallButton">+ Create New</Typography>
            </Button>
          </MenuItem>
        </Select>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "140px" }]}>
        <Select
          fullWidth
          variant="standard"
          disableUnderline
          size="small"
          select
          label="Subgroup"
          autoComplete="off"
          InputLabelProps={{ shrink: "true" }}
          value={miscItemCopy.expenseSubgroupId || ""}
          sx={{ height: "34px", width: "100%", marginLeft: 2 }}
          disabled={!miscItemCopy?.expenseGroupId || miscItemMutation.isLoading}
          onChange={handleExpenseSubgroupUpdate}
        >
          {expenseGroups
            ?.find((x) => x.id === miscItemCopy.expenseGroupId)
            ?.expenseSubgroups?.map((x) => (
              <MenuItem value={x.id} key={x.id}>
                {x.name}
              </MenuItem>
            ))}
          <MenuItem>
            <Button variant="text" onClick={handleEditSubgroup}>
              <Typography variant="smallButton">+ Create New</Typography>
            </Button>
          </MenuItem>
        </Select>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "90px" }]}>
        <TextField
          size="small"
          variant="standard"
          fullWidth
          required
          id="name"
          autoComplete="off"
          InputProps={{
            disableUnderline: "true",
            inputMode: "numeric",
            startAdornment: (
              <InputAdornment sx={{ marginBottom: 1 }} position="start">
                {currencySymbol}
              </InputAdornment>
            ),
          }}
          value={costDisplay}
          onChange={(e) => {
            setCostDisplay(e.target.value);
            updateMiscItemCopy(
              "cost",
              Object.assign({}, miscItemCopy, {
                amount: utilFunctions.convertCurrencyToCents(e.target.value),
              })
            );
          }}
          onFocus={(e) => e.target.select()}
          onBlur={() => {
            setCostDisplay(
              utilFunctions.convertCentsToLargerFormatCurrency(
                miscItemCopy?.cost?.amount || 0,
                locale,
                currency
              )
            );
            handleOnBlur("cost");
          }}
          onKeyDown={utilFunctions.blurInput}
        />
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "56px", borderRight: "none" }]}>
        <IconButton
          variant="outlined"
          sx={{
            marginLeft: 2,
            height: "36px",
            width: "36px",
            border: "1px solid",
            borderColor: (theme) => theme.palette.error[500],
            borderRadius: 2,
          }}
          disabled={deleteMiscItemMutation.isLoading}
          onClick={() =>
            deleteMiscItemMutation.mutate({
              establishmentId: store.currentEstablishment?.id,
              invoiceId,
              miscLineItemId: miscItemCopy.id,
            })
          }
        >
          <DeleteOutlineIcon sx={{ color: "red" }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default MiscItemRow;
