import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import OrderPreviewRow from "./OrderPreviewRow";
import DescriptionToolTip from "../../../components/DescriptionToolTip";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const OrderTable = ({
  orders,
  refetchExistingOrders,
  totalPages,
  setPage,
  vendorMap,
  setAlertSuccess,
}) => {
  return (
    <>
      <TableContainer
        sx={{
          overflow: "scroll",
          borderRadius: 2,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
        }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead sx={{ height: "24px" }}>
            <TableRow sx={{ height: 24 }}>
              <TableCell
                sx={[tableHeadStyle, { width: "120px", maxWidth: "120px", borderLeft: "none" }]}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Order Date
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Order Date"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The date on which the order was created.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "220px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Vendor
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Vendor"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            Vendors are you who get your products from -- they
                            can be distributors, grocery stores, online stores,
                            etc.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Order Total
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Order Total"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            Total of the all products in the order.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Order Created
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Order Created"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            Time at which the order was created.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Status
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Status"
                      description={
                        <Box>
                          <Typography variant="body2">Mark as Sent</Typography>
                          <Typography variant="body1">
                            The order has been created, but has not been
                            manually marked as sent to the Vendor yet.
                          </Typography>
                          <Typography variant="body2" sx={{ marginTop: 1 }}>
                            Placed
                          </Typography>
                          <Typography variant="body1">
                            The order has been manually marked as sent to the
                            Vendor.
                          </Typography>
                          <Typography variant="body2" sx={{ marginTop: 1 }}>
                            Received
                          </Typography>
                          <Typography variant="body1">
                            The order has been fully received by you.
                          </Typography>
                          <Typography variant="body2" sx={{ marginTop: 1 }}>
                            Partially Received
                          </Typography>
                          <Typography variant="body1">
                            Part of the order has been received but at least one
                            of the products is missing, was not sent in full, or
                            was substituted.
                          </Typography>
                          <Typography variant="body2" sx={{ marginTop: 1 }}>
                            Backordered
                          </Typography>
                          <Typography variant="body1">
                            The order was marked as sent to the Vendor, but was
                            not received within X number of days (can be
                            adjusted in your settings).
                          </Typography>
                          <Typography variant="body2" sx={{ marginTop: 1 }}>
                            Cancelled
                          </Typography>
                          <Typography variant="body1">
                            The order has been manually cancelled.
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                sx={[tableHeadStyle, { width: "48px", borderRight: "none" }]}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((o) => (
              <OrderPreviewRow
                key={o.id}
                order={o}
                refetchExistingOrders={refetchExistingOrders}
                vendorMap={vendorMap}
                setAlertSuccess={setAlertSuccess}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{ marginTop: 6 }}
        count={totalPages}
        onChange={(_, page) => setPage(page)}
      />
    </>
  );
};

export default OrderTable;
