import React, { useContext, useState } from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import InvoiceStatusChip from "../../../components/InvoiceStatusChip";
import { useNavigate } from "react-router-dom";
import Store from "../../../Store/Store";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import utilFunctions from "../../../utilities/utilFunctions";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Menu, MenuItem } from "@mui/material";
import DeleteInvoiceModal from "../../../components/Modals/DeleteInvoiceModal";
var moment = require("moment");

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderTop: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderBottom: "none",
  backgroundColor: (theme) => theme.palette.pure.white,
  cursor: "pointer",
};

const InvoiceDetailRow = ({ invoice, vendorMap }) => {
  const navigate = useNavigate();
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [deleteDistributorModalOpen, toggleDeleteDistributorModalOpen] =
    useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteMenuClick = () => {
    handleClose();
    toggleDeleteDistributorModalOpen(true);
  };

  const handleDeleteModal = () => {
    toggleDeleteDistributorModalOpen(!deleteDistributorModalOpen);
  };

  return (
    <>
      <TableRow sx={{ height: 24, width: "100%" }}>
        <TableCell
          sx={[tableCellStyle, { width: "300px" }]}
          onClick={() => navigate(invoice.id)}
        >
          <Typography variant="body2">
            {invoice.invoiceDate &&
              moment(invoice.invoiceDate).format("MM/DD/YY")}
          </Typography>
        </TableCell>
        <TableCell
          sx={[tableCellStyle, { width: "120px" }]}
          onClick={() => navigate(invoice.id)}
        >
          <Typography variant="body1">#{invoice.invoiceNumber}</Typography>
        </TableCell>
        <TableCell
          sx={[tableCellStyle, { width: "320px" }]}
          onClick={() => navigate(invoice.id)}
        >
          <div
            style={{
              maxWidth: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Typography noWrap variant="body1">
              {vendorMap[invoice.vendorIds[0]]?.name || "-"}
            </Typography>
          </div>
        </TableCell>
        <TableCell
          sx={[tableCellStyle, { width: "120px" }]}
          onClick={() => navigate(invoice.id)}
        >
          <Typography variant="body1">
            {currencySymbol}
            {utilFunctions.convertCentsToLargerFormatCurrency(
              invoice?.invoiceTotal?.amount || 0,
              locale,
              currency
            )}
          </Typography>
        </TableCell>
        <TableCell
          sx={[tableCellStyle, { width: "120px" }]}
          onClick={() => navigate(invoice.id)}
        >
          <InvoiceStatusChip status={invoice.status} />
        </TableCell>
        <TableCell sx={[tableCellStyle, { width: "48px" }]}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleOptionClick}
          >
            <IconButton sx={{ margin: 0, padding: 0 }}>
              <MoreHorizOutlinedIcon
                sx={{ color: (theme) => theme.palette.primary[800] }}
              />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem sx={{ margin: "5px" }} onClick={handleDeleteMenuClick}>
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <DeleteInvoiceModal
        isOpen={deleteDistributorModalOpen}
        toggleModalOpen={handleDeleteModal}
        invoiceId={invoice.id}
      />
    </>
  );
};

export default InvoiceDetailRow;
