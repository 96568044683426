import React, { useEffect } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { Box, Checkbox, ListItemIcon, Typography } from "@mui/material";
import utilFunctions from "../../../utilities/utilFunctions";


const CurrencySettings = ({ data, checkedStyle, defaultStyle, currentCurrency, handleSelect, selectedCurrency, disabled }) => {
  
  useEffect(() => {
    handleSelect(currentCurrency);
  },[currentCurrency])
 
  return (
    <ListItem
      key={data.index}
      dense
      disableGutters
      sx={selectedCurrency === data.id ? checkedStyle : defaultStyle}
      disabled={disabled}
    >
      <ListItemButton disabled={disabled} onClick={() => handleSelect(data.id)}>
        <ListItemIcon>
          <Checkbox
            sx={{ color: (theme) => theme.palette.primary[800] }}
            checked={selectedCurrency === data.id}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="body2">
                {utilFunctions.capitalizeString(data.name)}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginLeft: 2,
                  color: (theme) => theme.palette.terrain[600],
                }}
              >
                {data.description}
              </Typography>
            </Box>
          }
          sx={{ marginLeft: 4 }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default CurrencySettings;
