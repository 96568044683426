import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

const ExistingProductsSuggestions = ({ suggestions, mapToSuggestionCallback }) => {
  if (suggestions?.length === 0) {
    return <Typography>No existing product suggestions</Typography>;
  }
  return (
    <>
      <List sx={{ width: "100%", marginTop: 4 }}>
        {suggestions?.map((x) => (
          <ListItem
            key={x.id}
            disableGutters
            sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
          >
            <ListItemButton onClick={() => mapToSuggestionCallback(x)}>
              <ListItemText
                primary={x.name}
                sx={{ marginLeft: 4 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ExistingProductsSuggestions;
