import React from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";

const CreateSectionForm = ({ submitFormCallback }) => {
  const { register, handleSubmit, reset } = useForm();

  const handleSubmitForm = async (data) => {
    await submitFormCallback(data.name);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid
        container
        direction="column"
        justifyContent="center" // Center the items horizontally
        alignItems="flex-start"
        sx={{ width: "100%" }} // Set width to 100%
      >
        <Typography variant="h2">Create Tag</Typography>
        <Box sx={{ marginTop: 2, width: "100%" }}>
          <Typography variant="body1">
            Tags are used to organize and filter Recipes.
          </Typography>
        </Box>
        <Box sx={{ marginTop: 6, width: "100%" }}>
          <TextField
            size="small"
            fullWidth
            label="Enter Tag Name"
            placeholder="Enter Tag Name"
            autoComplete="off"
            {...register("name", { required: true })}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "3rem" }}>
          <Button
            variant="contained"
            sx={{
              width: "300px",
              height: "40px",
              borderRadius: 2,
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 6,
              paddingRight: 6,
            }}
            type="submit"
          >
            <Typography variant="largeButton">Create Tag</Typography>
          </Button>
        </Box>
      </Grid>
    </form>
  );
};

export default CreateSectionForm;
