import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useMenuPaginatedSearch(establishmentId, searchTerm, page, filters) {
    const queryReturnValues = useQuery(
      [
        "paginated-menu-search",
        {
          establishmentId,
          page,
          searchTerm,
          filters
        },
      ],
      inventoryClient.searchMenusPaginated,
      {
        enabled: !!searchTerm,
        refetchOnWindowFocus: false,
      }
    );

    return queryReturnValues;
}

export default useMenuPaginatedSearch;