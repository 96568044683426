import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  // TextField,
  Typography,
} from "@mui/material";
//import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LiveEditErrorModal from "../../components/Modals/LiveEditErrorModal";
import SpecSpinner from "../../components/SpecSpinner";
import AddIcon from "@mui/icons-material/Add";
import StockCountTable from "./components/StockCountTable";
import ActiveCountCard from "./components/ActiveCountCard";
import StockCountModal from "../../components/Modals/StockCountModal";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import inventoryClient from "../../clients/inventoryClient";
import Store, { activeRouteKeys } from "../../Store/Store";
import CTABlock from "../../components/CTABlock";
import usePastStockCounts from "../../hooks/usePastStockCounts";
import useActiveStockCounts from "../../hooks/useActiveStockCounts";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import SubscriptionScreenLock from "../../components/SubscriptionScreenLock";

const StockCount = () => {
  const [errorModalOpen, toggleErrorModalOpen] = useState(false);
  const [addingStockCount, toggleAddingStockCount] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [page, setPage] = useState(1);
  const [stockCountModalOpen, toggleStockCountModalOpen] = useState(false);
  const store = useContext(Store);
  const { establishmentid: establishmentId } = useParams();
  const navigate = useNavigate();
  const [displayArrows, toggleDisplayArrows] = useState(false);
  const [ref, setRef] = useState(null);
  const onRefSet = useCallback((ref) => {
    setRef(ref);
    toggleDisplayArrows(ref?.scrollWidth > ref?.clientWidth);
  });

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.stockCount,
      `spec/${establishmentId}/stockcount`
    );
  }, []);

  const { data: subscriptionState, isLoading: isLoadingSubscriptionState } = useEstablishmentSubscription(establishmentId);
  const {
    isLoading: isLoadingPastStockCounts,
    isError: isErrorLoadingPastStockCounts,
    data: pastStockCountData,
  } = usePastStockCounts(establishmentId, page, subscriptionState?.isActive);

  const {
    isLoading: isLoadingActiveStockCounts,
    isError: isErrorLoadingActiveStockCounts,
    data: activeStockCountData,
    refetch: refetchActiveStockCounts,
  } = useActiveStockCounts(establishmentId, page, subscriptionState?.isActive);

  const addStockCountMutation = useMutation(
    inventoryClient.generateInventoryAudit,
    {
      onSuccess: (result) => {
        handleViewCountDetails(result.stockCount?.id);
      },
    }
  );

  const handleAddStockCount = async (stockCountType, countName, templateId) => {
    await addStockCountMutation.mutateAsync({
      establishmentId: store.currentEstablishment?.id,
      stockCountType,
      name: countName,
      templateId,
    });
    refetchActiveStockCounts();
    await toggleStockCountModalOpen(false);
    toggleAddingStockCount(true);
  };

  const handleViewCountDetails = (countId) => {
    navigate(countId);
  };

  const scroll = (scrollOffset) => {
    ref.scrollLeft += scrollOffset;
  };

  if (isLoadingPastStockCounts || isLoadingActiveStockCounts || isLoadingSubscriptionState) {
    return <SpecSpinner open text="Loading Stock Count Data..." />;
  }

  return (
    <Box
      sx={{
        maxWidth: "98%",
        minWidth: "98%",
      }}
    >
      <Grid
        container
        sx={{
          minWidth: "100%",
          maxWidth: "2440px",
          padding: 4,
          marginTop: 6,
          alignItems: "start",
        }}
      >
        {isErrorLoadingActiveStockCounts ||
          (isErrorLoadingPastStockCounts && (
            <Typography variant="h6" sx={{ color: "red" }}>
              There was an error fetching stock count data, please refresh
            </Typography>
          ))}
        {!addingStockCount && (
          <Grid
            container
            sx={{
              marginBottom: 6,
              display: "flex",
              justifyContent: "space-between",
            }}
            xs={12}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{ fontSize: { xs: 20, sm: 20, md: 28 } }}
              >
                Stock Count
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", md: "flex-end" },
                marginTop: { xs: 4, md: 0 },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 2,
                  padding: 4,
                  height: "40px",
                }}
                onClick={() => toggleStockCountModalOpen(true)}
              >
                <AddIcon sx={{ fontSize: { xs: 14, sm: 14, md: 20 } }} />
                <Typography
                  variant="largeButton"
                  sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
                >
                  New Count
                </Typography>
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
          }}
        >
          {activeStockCountData?.activeCounts?.length > 0 && (
            <Grid
              container
              sx={{
                marginBottom: 4,
                width: "100%",
              }}
            >
              <Grid item xs={12} sx={{ marginBottom: 4 }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6}>
                    <Typography
                      variant="h4"
                      sx={{ fontSize: { xs: 16, sm: 16, md: 20 } }}
                    >
                      Active Count
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: displayArrows ? "flex" : "none",
                      justifyContent: "flex-end",
                    }}
                  >
                    <IconButton
                      sx={{
                        marginRight: 2,
                        border: "2px solid",
                        borderColor: (theme) => theme.palette.primary["main"],
                      }}
                      onClick={() => scroll(-200)}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        marginLeft: 2,
                        border: "2px solid",
                        borderColor: (theme) => theme.palette.primary["main"],
                      }}
                      onClick={() => scroll(200)}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  justifyContent: "space-around",
                  marginBottom: 4,
                  // gridAutoFlow: "column",
                  // gridTemplateColumns:
                  //   "repeat(auto-fill,minmax(160px,1fr)) !important",
                  // gridAutoColumns: "minmax(160px, 1fr)",
                }}
              >
                <Grid
                  container
                  wrap="nowrap"
                  direction="row"
                  ref={onRefSet}
                  sx={{ overflow: "auto" }}
                >
                  {activeStockCountData?.activeCounts &&
                    activeStockCountData?.activeCounts?.map((x) => (
                      <ActiveCountCard
                        viewCountDetails={handleViewCountDetails}
                        key={x.id}
                        count={x}
                      />
                    ))}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            sx={{
              marginBottom: 6,
              display: "flex",
              justifyContent: "space-between",
              alignitems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                marginBottom: { xs: 2, sm: 2, md: 0 },
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontSize: { xs: 16, sm: 16, md: 20 } }}
              >
                Count History
              </Typography>
            </Grid>
            {/* Search Bar */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* <Button
                variant="outlined"
                sx={{
                  marginLeft: 2,
                  border: "2px solid",
                  borderColor: (theme) => theme.palette.primary.main,
                }}
              >
                <TuneOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                <Typography variant="largeButton">Filters</Typography>
              </Button> */}
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              marginBottom: 2,
              width: "100%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              {pastStockCountData?.pastCounts.length !== 0 ? (
                <StockCountTable
                  stockCounts={pastStockCountData?.pastCounts || []}
                  setPage={setPage}
                  totalPages={pastStockCountData?.totalPages}
                />
              ) : (
                <CTABlock
                  handleCTA={() => toggleStockCountModalOpen(true)}
                  ButtonImage={AddIcon}
                  buttonText="New Count"
                  header="You have not performed any stock counts yet."
                  subheader="Click the button below to begin your first stock count!"
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <LiveEditErrorModal
        isOpen={errorModalOpen}
        toggleErrorModalOpen={toggleErrorModalOpen}
        bodyText="Are you sure you want to navigate back? You will lose any unsaved information."
        headerText="Hold on..."
        buttonText="Go Back"
      />
      <StockCountModal
        addStockCount={handleAddStockCount}
        isOpen={stockCountModalOpen}
        toggleModalOpen={toggleStockCountModalOpen}
        isLoading={addStockCountMutation.isLoading}
      />
      <SubscriptionScreenLock show={!subscriptionState?.isActive} />
      <Box>
        <Snackbar
          open={alertSuccess ? true : false}
          onClose={() => setAlertSuccess(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {alertSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage ? true : false}
          onClose={() => setErrorMessage(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default StockCount;
