import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

import { Menu, MenuItem, Avatar, Box } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import utilFunctions from "../../../utilities/utilFunctions";

const SpecClassicListItem = ({ copyModalCallback, onItemSelect, menuItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyModal = () => {
    copyModalCallback(menuItem);
    handleClose();
  };

  return (
    <>
      <ListItem
        key={menuItem.id}
        disableGutters
        sx={{ backgroundColor: (theme) => theme.palette.pure.white }}
        // secondaryAction={
        //   <IconButton onClick={handleClick} color="secondary">
        //     <MoreHorizIcon />
        //   </IconButton>
        // }
      >
        <ListItemButton onClick={() => onItemSelect(menuItem)}>
          {menuItem.imageURL ? (
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                backgroundSize: "cover",
              }}
              src={menuItem.imageURL}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "32px",
                height: "32px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.terrain[400],
                borderRadius: "100%",
              }}
            >
              <AddPhotoAlternateOutlinedIcon sx={{ fontSize: "12px" }} />
            </Box>
          )}
          <ListItemText
            primary={utilFunctions.capitalizeString(menuItem.name)}
            sx={{ marginLeft: 4 }}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleCopyModal} sx={{ margin: "5px" }}>
          <ContentCopyIcon sx={{ marginRight: "5px" }} />
          Copy
        </MenuItem>
      </Menu>
    </>
  );
};

export default SpecClassicListItem;
