import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SpecBackButton = ({ onClick, backText }) => (
  <Button
    variant="text"
    sx={{
      color: (theme) => theme.palette.primary[800],
      marginLeft: -2,
      "&:hover": {
        background: "none",
      },
    }}
    onClick={onClick}
  >
    <ArrowBackIcon sx={{ paddingRight: "5px" }} />
    <Typography variant="largeButton">{backText || "Back"}</Typography>
  </Button>
);

export default SpecBackButton;
