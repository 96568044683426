import {
  Box,
  Button,
  CircularProgress,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TagList from "./components/TagList";

const TaggingMenu = ({
  allTags,
  preSelectedTags,
  header,
  subHeader,
  saveCallback,
  loading,
  submitText,
  cancel,
  anchorEl,
  open,
  emptyOptionsText,
  searchPromptText,
  helpText,
}) => {
  const [tags, setTags] = useState([]);
  const [preSelectedMap, setPreSelectedMap] = useState({});
  const [searching, toggleSearching] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const psMap = {}; // this gets used for effecient comparison when saving
    preSelectedTags.forEach((x) => {
      psMap[x.id] = x;
    });
    const tagOptions = allTags.map((t) => {
      return {
        ...t,
        checked: preSelectedTags.find((x) => x.id === t.id) !== undefined,
      };
    });
    setTags(tagOptions);
    setPreSelectedMap(psMap);
  }, [allTags, preSelectedTags]);

  const handleSearch = (searchEvent) => {
    const sValue = searchEvent.target.value;
    setSearchText(sValue);
    if (!sValue) {
      toggleSearching(false);
    } else {
      toggleSearching(true);
    }
  };

  const handleCheckboxChange = (e) => {
    setTags(
      tags.map((t) => {
        if (e.target.id == t.id) {
          return { ...t, checked: e.target.checked };
        }

        return t;
      })
    );
  };

  const handleSubmit = () => {
    const tagsToAdd = [];
    const tagsToRemove = [];
    tags.forEach((x) => {
      if (preSelectedMap[x.id] !== undefined) {
        if (!x.checked) {
          tagsToRemove.push(x.id);
          return;
        } else return;
      }

      if (x.checked) tagsToAdd.push(x.id);
    });

    saveCallback(tagsToAdd, tagsToRemove);
  };

  const handleClose = () => {
    const tagOptions = allTags.map((t) => {
      return {
        ...t,
        checked: preSelectedTags.find((x) => x.id === t.id) !== undefined,
      };
    });
    setTags(tagOptions);
    cancel();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorPosition={{ top: 200, right: 800 }}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 4,
        },
      }}
    >
      <Box
        sx={{
          width: "300px",
          height: "100%",
          padding: 2,
          backgroundColor: (theme) => theme.palette.terrain[50],
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.terrain[50],
            height: "100%",
          }}
        >
          <Box sx={{ marginLeft: 2 }}>
            <Typography variant="h3" sx={{ marginTop: 4 }}>
              {header}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: 2 }}>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              {subHeader}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: 2, marginTop: 2, marginBottom: 2 }}>
            <Typography variant="body1">{helpText}</Typography>
          </Box>
          <Box sx={{ margin: 2 }}>
            <TextField
              size="small"
              fullWidth
              id="productSearch"
              onChange={handleSearch}
              inputProps={{
                sx: {
                  backgroundColor: (theme) => theme.palette.pure.white,
                  marginBottom: 1,
                },
              }}
              sx={{
                backgroundColor: (theme) => theme.palette.pure.white,
                width: "100%",
              }}
              label={
                <>
                  <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                  {searchPromptText}
                </>
              }
            />
          </Box>
          <Box
            sx={{
              height: "250px",
              overflow: "scroll",
              border: "1px solid",
              borderRadius: 2,
              borderColor: (theme) => theme.palette.terrain[300],
              backgroundColor: (theme) => theme.palette.pure.white,
              marginTop: 4,
              marginLeft: 2,
              marginRight: 2,
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <TagList
                tags={
                  searching
                    ? tags.filter((x) =>
                        x.name.toUpperCase().includes(searchText.toUpperCase())
                      )
                    : tags
                }
                emptyOptionsCTAText={emptyOptionsText}
                handleOptionCheckUncheck={handleCheckboxChange}
                searching={searching}
              />
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <Button variant="contained" onClick={handleSubmit}>
            {submitText}
          </Button>
        </Box>
      </Box>
    </Menu>
  );
};

export default TaggingMenu;
