import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const TooltipContentArea = ({
  header,
  description,
  buttonText,
  props,
  buttonAction,
}) => {
  return (
    <Box
      {...props}
      sx={{ backgroundColor: (theme) => theme.palette.pure.white, padding: 1 }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.primary[800] }}
        >
          {header}
        </Typography>
      </Box>
      <Box component="div" sx={{ marginTop: 1 }}>
        <Typography component="div" variant="body1">
          {description}
        </Typography>
      </Box>
      {buttonText ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 1 }}>
          <Button
            variant="outlined"
            sx={{ width: "100%" }}
            onClick={() => buttonAction(true)}
          >
            {buttonText}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

const DescriptionToolTip = ({
  header,
  description,
  buttonText,
  buttonAction,
}) => {
  return (
    <Tooltip
      title={
        <TooltipContentArea
          header={header}
          description={description}
          buttonText={buttonText}
          buttonAction={buttonAction}
        />
      }
      componentsProps={{
        tooltip: {
          sx: {
            color: (theme) => theme.palette.pure.black,
            bgcolor: (theme) => theme.palette.pure.white,
            border: "2px solid",
            borderColor: (theme) => theme.palette.terrain[200],
          },
        },
      }}
    >
      <InfoOutlinedIcon
        sx={{
          color: (theme) => theme.palette.terrain[900],
          fontSize: 16,
        }}
      />
    </Tooltip>
  );
};

export default DescriptionToolTip;
