import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import CountProductListRow from "./CountProductListRow";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const CountProductListTable = ({
  records,
  openRecordCostSetModal,
  toggleParsModalOpen,
}) => {
  return (
    <TableContainer
      sx={{
        overflow: "scroll",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        border: "1px solid",
        borderBottom: "none",
        borderColor: (theme) => theme.palette.terrain[400],
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="customized table">
        <TableHead sx={{ height: "24px" }}>
          <TableRow sx={{ height: 24 }}>
            <TableCell
              sx={[tableHeadStyle, { width: "376px", borderLeft: "none" }]}
            >
              Product Name
            </TableCell>
            <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
              Value on Hands
            </TableCell>
            <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
              Count Total
            </TableCell>
            <TableCell
              sx={[tableHeadStyle, { width: "100px", textAlign: "center" }]}
            >
              Par Check
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((x) => (
            <CountProductListRow
              key={x.productName}
              record={x}
              openRecordCostSetModal={openRecordCostSetModal}
              toggleParsModalOpen={toggleParsModalOpen}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CountProductListTable;
