import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import inventoryClient from "../../../clients/inventoryClient";
import { useMutation } from "react-query";
import DeleteRecipeTagModal from "../DeleteRecipeTagModal";

const EditTagModal = ({
  establishmentId,
  handleEditingTagClick,
  tag,
  successCallback,
}) => {
  const [isError, toggleError] = useState(false);
  const [tagName, setTagName] = useState();
  const [deleteModalOpen, toggleDeleteModal] = useState(false);

  const patchTagMutation = useMutation(inventoryClient.patchRecipeTag, {
    onSuccess: () => {
      successCallback();
      handleEditingTagClick();
    },
    onError: () => {
      toggleError(true);
    },
  });

  const deleteTagMutation = useMutation(inventoryClient.deleteRecipeTag, {
    onSuccess: () => {
      successCallback();
      handleEditingTagClick();
    },
    onError: () => {
      toggleError(true);
    },
  });

  const handleSaveClick = async () => {
    const payload = {
      tagId: tag.id,
      establishmentId: establishmentId,
      nameUpdate: { value: tagName },
    };
    await patchTagMutation.mutateAsync(payload);
  };

  const handleDelete = async () => {
    const payload = {
      tagId: tag.id,
      establishmentId: establishmentId,
    };

    await deleteTagMutation.mutateAsync(payload);
  };

  const handleNameChange = (e) => {
    setTagName(e.target.value);
  };

  const handleDeleteModal = () => {
    toggleDeleteModal(!deleteModalOpen);
  };

  useEffect(() => {
    setTagName(tag?.name);
  }, [tag]);

  return (
    <>
      <Box>
        {patchTagMutation.isLoading || deleteTagMutation.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ width: "100%" }}
          >
            <Typography variant="h2">Edit Tag</Typography>
            <Box sx={{ marginTop: 6, width: "100%" }}>
              <TextField
                size="small"
                fullWidth
                label="Tag Name"
                value={tagName}
                onChange={handleNameChange}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "4px",
              }}
            >
              <Button
                variant="text"
                sx={{
                  color: (theme) => theme.palette.primary[800],
                  marginLeft: -2,
                  "&:hover": {
                    background: "none",
                  },
                }}
                onClick={handleDeleteModal}
              >
                <Typography variant="largeButton">Delete Tag</Typography>
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "8rem",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "300px",
                  height: "40px",
                  borderRadius: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 6,
                  paddingRight: 6,
                }}
                onClick={handleSaveClick}
                disabled={!tagName}
              >
                <Typography variant="largeButton">Save</Typography>
              </Button>
            </Box>
          </Grid>
        )}
        {isError && (
          <Typography>There was a problem editing your tag</Typography>
        )}
      </Box>
      <DeleteRecipeTagModal
        handleDelete={handleDelete}
        isOpen={deleteModalOpen}
        toggleModalOpen={handleDeleteModal}
      />
    </>
  );
};

export default EditTagModal;
