import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  // Checkbox,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Store from "../../../../Store/Store";
import inventoryClient from "../../../../clients/inventoryClient";
import { MoreHorizOutlined } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useMutation, useQueryClient } from "react-query";
import DeleteProductModal from "../../../../components/Modals/DeleteProductModal";
import utilFunctions from "../../../../utilities/utilFunctions";
import ReassignProductFamilyModal from "../../../../components/Modals/ReassignProductFamilyModal";
import { useForm } from "react-hook-form";
import useEstablishmentProductFamilyGroups from "../../../../hooks/useEstablishmentProductFamilyGroups";
import { useParams } from "react-router-dom";

const productStatusDefaultStyle = {
  backgroundColor: (theme) => theme.palette.success[500],
  color: (theme) => theme.palette.pure.white,
  fontWeight: 700,
  height: "24px",
  width: "110px",
  borderRadius: 100,
  borderBottom: "none",
  "& .MuiSvgIcon-root": {
    color: (theme) => theme.palette.pure.white,
  },
};

const ProductDetailRow = ({
  product,
  productFamilyName,
  categoryName,
  subcategoryName,
  tableCellStyle,
  updateProductCallback,
  deleteCallback,
  refetchCurrentPageProducts,
}) => {
  const [productCopy, setProductCopy] = useState({});
  const [abvDisabled, toggleAbvDisabled] = useState(false);
  const [regionDisabled, toggleRegionDisabled] = useState(false);
  const [producerDisabled, toggleProducerDisabled] = useState(false);
  const [distillateDisabled, toggleDistillateDisabled] = useState(false);
  const [vintageDisabled, toggleVintageDisabled] = useState(false);
  const [statusDisabled, toggleStatusDisabled] = useState(false);
  const [appellationDistabled, toggleAppellationDisabled] = useState(false);
  const [toggleDeleteProductModalOpen, setDeleteProductModalOpen] =
    useState(false);
  const [reassignProductFamilyModalOpen, toggleReassignProductFamilyModal] =
    useState(false);
  const store = useContext(Store);
  const { data: familyData } = useEstablishmentProductFamilyGroups(
    store.currentEstablishment?.id
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let { establishmentid } = useParams();
  const queryClient = useQueryClient();
  const { register, setValue, control } = useForm({ mode: "all" });

  useEffect(() => {
    setProductCopy(product);
  }, [product]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const productStatusStyle = (activeState) => {
    if (activeState === 1) {
      return {
        backgroundColor: (theme) => theme.palette.success[500],
        color: (theme) => theme.palette.pure.white,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.white,
        },
      };
    }
    if (activeState === 2) {
      return {
        backgroundColor: (theme) => theme.palette.error[900],
        color: (theme) => theme.palette.pure.white,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.white,
        },
      };
    }
    if (activeState === 4) {
      return {
        backgroundColor: (theme) => theme.palette.error[200],
        color: (theme) => theme.palette.pure.black,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.black,
        },
      };
    }
    if (activeState === 5) {
      return {
        backgroundColor: (theme) => theme.palette.terrain[400],
        color: (theme) => theme.palette.pure.black,
        fontWeight: 700,
        height: "24px",
        width: "110px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.black,
        },
      };
    }
  };

  const deleteProductMutation = useMutation(inventoryClient.deleteProduct, {
    onSuccess: () => {
      deleteCallback();
    },
  });

  const hanldleReassignProductFamilyClick = () => {
    toggleReassignProductFamilyModal(!reassignProductFamilyModalOpen);
  };

  const handleAddProductFamily = async (name) => {
    const response = await inventoryClient.addProductFamily(
      name,
      store.currentEstablishment?.id
    );
    const newFamily = response.data.productFamily;
    queryClient.invalidateQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
    });
    await queryClient.refetchQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
      type: "all",
    });
    return newFamily;
  };

  const handleAddCategory = async (name, productFamilyId) => {
    const response = await inventoryClient.addCategory(
      name,
      productFamilyId,
      store.currentEstablishment?.id
    );
    const newCategory = response.data.category;
    queryClient.invalidateQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
    });
    await queryClient.refetchQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
      type: "all",
    });
    return newCategory;
  };

  const handleAddSubcategory = async (name, categoryId) => {
    const response = await inventoryClient.addSubcategory(
      name,
      categoryId,
      store.currentEstablishment?.id
    );
    const newSubcategory = response.data.subcategory;

    queryClient.invalidateQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
    });
    await queryClient.refetchQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
      type: "all",
    });
    return newSubcategory;
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const payload = {
        establishmentId: store.currentEstablishment?.id,
        productId: productId,
      };

      await deleteProductMutation.mutateAsync(payload);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteModal = () => {
    setDeleteProductModalOpen(!toggleDeleteProductModalOpen);
  };

  const handleProductUpdate = async (property, editedProductObject) => {
    switch (property) {
      case "abv":
        toggleAbvDisabled(true);
        await inventoryClient.updateProduct(
          { id: productCopy.id, abvUpdate: { value: productCopy[property] } },
          store.currentEstablishment?.id
        );
        updateProductCallback(productCopy);
        toggleAbvDisabled(false);
        break;
      case "region":
        toggleRegionDisabled(true);
        await inventoryClient.updateProduct(
          {
            id: productCopy.id,
            regionUpdate: { value: productCopy[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(productCopy);
        toggleRegionDisabled(false);
        break;
      case "producer":
        toggleProducerDisabled(true);
        await inventoryClient.updateProduct(
          {
            id: productCopy.id,
            producerUpdate: { value: productCopy[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(productCopy);
        toggleProducerDisabled(false);
        break;
      case "distillate":
        toggleDistillateDisabled(true);
        await inventoryClient.updateProduct(
          {
            id: productCopy.id,
            distillateUpdate: { value: productCopy[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(productCopy);
        toggleDistillateDisabled(false);
        break;
      case "vintage":
        toggleVintageDisabled(true);
        await inventoryClient.updateProduct(
          {
            id: productCopy.id,
            vintageUpdate: { value: productCopy[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(productCopy);
        toggleVintageDisabled(false);
        break;
      case "appellation":
        toggleAppellationDisabled(true);
        await inventoryClient.updateProduct(
          {
            id: productCopy.id,
            appellationUpdate: { value: productCopy[property] },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(productCopy);
        toggleAppellationDisabled(false);
        break;
      case "name-family-multi":
        await inventoryClient.updateProduct(
          {
            id: editedProductObject.id,
            nameUpdate: { value: editedProductObject.name },
            productFamilyIdUpdate: {
              value: editedProductObject.productFamily.id,
            },
            categoryIdUpdate: {
              value: editedProductObject.category
                ? editedProductObject.category.id
                : null,
            },
            subcategoryIdUpdate: {
              value: editedProductObject.subcategory
                ? editedProductObject.subcategory.id
                : null,
            },
          },
          store.currentEstablishment?.id
        );
        updateProductCallback(editedProductObject);
        break;
      default:
        console.log("update product switch statement didn't match anything");
    }
  };

  const statusUpdateMutation = useMutation(
    inventoryClient.updateProductReactQuery,
    {
      onSuccess: () => {
        refetchCurrentPageProducts();
        toggleStatusDisabled(false);
      },
      onError: (err) => {
        console.log(err);
        toggleStatusDisabled(false);
      },
    }
  );

  const handleStatusUpdate = async (e) => {
    const newStatus = e.target.value;
    handleCopyUpdate("status", newStatus);
    toggleStatusDisabled(true);
    await statusUpdateMutation.mutateAsync({
      id: product.id,
      statusUpdate: { value: newStatus },
      establishmentId: establishmentid,
    });
  };

  const handleCopyUpdate = (property, newValue) => {
    const newData = Object.assign({}, productCopy);
    newData[property] = newValue;
    setProductCopy(newData);
  };

  return (
    <TableRow>
      {/* CHECKBOX */}
      {/* <TableCell
        sx={[
          tableCellStyle,
          {
            borderLeft: "none",
            borderBottom: "none",
            backgroundColor: (theme) => theme.palette.pure.white,
            padding: 0,
          },
        ]}
      >
        <Checkbox sx={{ color: (theme) => theme.palette.primary[800] }} />
      </TableCell> */}
      <TableCell
        sx={[tableCellStyle, { cursor: "pointer", borderLeft: " none" }]}
        onClick={hanldleReassignProductFamilyClick}
      >
        <Box sx={{ paddingLeft: 2 }}>
          <Typography variant="body2">{productCopy.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            {productFamilyName ||
              (productCopy.productFamily &&
                productCopy.productFamily.id !== -1 &&
                productCopy.productFamily.name)}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: (theme) => theme.palette.terrain[400],
              marginLeft: 1,
              marginRight: 1,
            }}
          >
            {(categoryName ||
              (productCopy.category && productCopy.category.id !== -1)) &&
              "/"}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            {categoryName ||
              (productCopy.category &&
                productCopy.category.id !== -1 &&
                productCopy.category.name)}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: (theme) => theme.palette.terrain[400],
              marginLeft: 1,
              marginRight: 1,
            }}
          >
            {(subcategoryName ||
              (productCopy.subcategory && productCopy.subcategory.id !== -1)) &&
              " / "}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            {subcategoryName ||
              (productCopy.subcategory &&
                productCopy.subcategory.id !== -1 &&
                productCopy.subcategory.name)}{" "}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <TextField
          size="small"
          fullWidth
          variant="filled"
          // type="number"
          id="abv"
          autoComplete="off"
          placeholder="ABV"
          disabled={abvDisabled}
          onBlur={() => handleProductUpdate("abv")}
          onKeyDown={utilFunctions.blurInput}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          InputProps={{
            disableUnderline: true,
            inputMode: "numeric",
            endAdornment: (
              <InputAdornment
                sx={{
                  marginTop: 4,
                  padding: 0,
                  marginRight: -2,
                  marginLeft: 0,
                }}
                position="end"
              >
                %
              </InputAdornment>
            ),
          }}
          sx={{
            "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                "-webkit-appearance": "none",
                display: "none !important",
              },
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
              "&:hover": {
                background: "rgba(0, 0, 0, 0.09)",
              },
            },
          }}
          value={productCopy.abv || ""}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <TextField
          size="small"
          variant="filled"
          id="region"
          placeholder="Region"
          autoComplete="off"
          onKeyDown={utilFunctions.blurInput}
          disabled={regionDisabled}
          value={productCopy.region || ""}
          fullWidth
          InputProps={{ disableUnderline: true }}
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          onBlur={() => handleProductUpdate("region")}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />{" "}
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <TextField
          size="small"
          variant="filled"
          id="producer"
          autoComplete="off"
          disabled={producerDisabled}
          placeholder="Producer"
          onKeyDown={utilFunctions.blurInput}
          fullWidth
          value={productCopy.producer || ""}
          InputProps={{ disableUnderline: true }}
          onBlur={() => handleProductUpdate("producer")}
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />{" "}
      </TableCell>
      <TableCell sx={tableCellStyle}>
        {" "}
        <TextField
          size="small"
          variant="filled"
          id="distillate"
          placeholder="Raw Materials"
          autoComplete="off"
          disabled={distillateDisabled}
          onKeyDown={utilFunctions.blurInput}
          InputProps={{ disableUnderline: true }}
          fullWidth
          onBlur={() => handleProductUpdate("distillate")}
          value={productCopy.distillate || ""}
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />{" "}
      </TableCell>
      <TableCell sx={tableCellStyle}>
        {" "}
        <TextField
          size="small"
          variant="filled"
          id="vintage"
          placeholder="Vintage"
          autoComplete="off"
          onKeyDown={utilFunctions.blurInput}
          onBlur={() => handleProductUpdate("vintage")}
          disabled={vintageDisabled}
          InputProps={{ disableUnderline: true }}
          value={productCopy.vintage || ""}
          fullWidth
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />
      </TableCell>
      <TableCell sx={tableCellStyle}>
        <TextField
          size="small"
          variant="filled"
          id="appellation"
          autoComplete="off"
          onKeyDown={utilFunctions.blurInput}
          placeholder="Appellation"
          onBlur={() => handleProductUpdate("appellation")}
          disabled={appellationDistabled}
          InputProps={{ disableUnderline: true }}
          fullWidth
          value={productCopy.appellation || ""}
          sx={{
            "& .MuiFilledInput-root": {
              background: (theme) => theme.palette.pure.white,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 4,
            },
          }}
          onChange={(e) => handleCopyUpdate(e.target.id, e.target.value)}
        />
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            borderRight: "none",
            margin: "auto",
            height: "100%",
            width: "120px",
            paddingLeft: 2,
          },
        ]}
      >
        <Select
          id="status"
          label="Status"
          value={productCopy.status || 1}
          onChange={handleStatusUpdate}
          disabled={statusDisabled}
          sx={
            productCopy.status
              ? productStatusStyle(productCopy.status)
              : productStatusDefaultStyle
          }
        >
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={2}>Inactive</MenuItem>
        </Select>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "40px", borderRight: "none" }]}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            sx={{ width: "32px", height: "32px" }}
            onClick={handleClick}
          >
            <MoreHorizOutlined
              sx={{
                color: (theme) => theme.palette.primary[800],
                fontSize: 20,
              }}
            />
          </IconButton>
        </Box>
      </TableCell>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem sx={{ margin: "5px" }} onClick={handleDeleteModal}>
          <DeleteOutlineOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
          <Typography variant="body1">Delete</Typography>
        </MenuItem>
      </Menu>
      <DeleteProductModal
        handleDeleteProduct={handleDeleteProduct}
        product={product}
        isOpen={toggleDeleteProductModalOpen}
        toggleModalOpen={handleDeleteModal}
      />
      <ReassignProductFamilyModal
        isOpen={reassignProductFamilyModalOpen}
        toggleModalOpen={hanldleReassignProductFamilyClick}
        product={productCopy}
        register={register}
        control={control}
        setValue={setValue}
        productName={productCopy?.name}
        key={productCopy.id}
        productFamilies={familyData?.productFamilies || []}
        createNewFamily={handleAddProductFamily}
        createNewCategory={handleAddCategory}
        createNewSubcategory={handleAddSubcategory}
        productFamilyMap={familyData?.familyMap || {}}
        categoryMap={familyData?.categoryMap || {}}
        subcategoryMap={familyData?.subcategoryMap || {}}
        handleProductUpdate={handleProductUpdate}
      />
    </TableRow>
  );
};

export default ProductDetailRow;
