import React from "react";
import { modalBoxStyle } from "./styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  Grid,
} from "@mui/material";
import RuleIcon from '@mui/icons-material/Rule';

const ForceStockCountCompletionModal = ({
  isOpen,
  cancelCallback,
  completeCallback,
  response
}) => {

  return (
    <Modal
      open={isOpen}
      onClose={cancelCallback}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
          <>
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item sx={{ marginBottom: 4 }}>
                <RuleIcon sx={{ fontSize: "80px" }} />
              </Grid>
              <Grid item sx={{ marginBottom: 8 }}>
                <Typography variant="h2">Heads up Boss</Typography>
              </Grid>
              <Grid item sx={{ marginBottom: 8 }}>
                <Typography variant="body1" sx={{ fontSize: "12px" }}>
                  {"The following areas are incomplete or have records that haven't been set:"}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "12px" }}>
                  <ul>
                  {response?.areaValidationReports.map((x) => <li key={x.id}>{x.name}</li>)}
                  </ul>
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "12px" }}>
                  {"Would you like us to mark untouched records in these areas as 0 and complete the count anyway?"}
                </Typography>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={cancelCallback}
                  sx={{
                    marginLeft: "1rem",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="smallButton">No, Cancel</Typography>
                </Button>
                <Button
                  variant="contained"
                  onClick={completeCallback}
                  sx={{
                    marginLeft: "1rem",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="smallButton">Yes, Complete</Typography>
                </Button>
              </Box>
            </Grid>
          </>
      </Box>
    </Modal>
  );
};

export default ForceStockCountCompletionModal;
