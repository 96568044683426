import React, { useState, useEffect, useMemo } from "react";
import { modalBoxStyle } from "../styles";
import {
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { debounce } from "lodash";
import inventoryClient from "../../../clients/inventoryClient";
import SearchResultsList from "./components/SearchResultsList";
import { useMutation } from "react-query";

const AddProductToCountModal = ({
  isOpen,
  toggleModalOpen,
  areaCountId,
  stockCountId,
  establishmentId,
  onSuccessCallback,
}) => {
  const [loading, toggleLoading] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    setSearchString("");
  }, [isOpen]);

  const getSearchResults = async () => {
    toggleLoading(true);
    const result = await inventoryClient.searchForCountNotAddedProducts({
      establishmentId,
      stockCountId,
      areaCountId,
      searchString,
    });
    setSearchResults(result.results);
    toggleLoading(false);
  };

  useEffect(() => {
    if (!searchString) {
      toggleLoading(false);
      setSearchResults([]);
    } else {
      getSearchResults();
    }

    return () => debouncedSearchHandler.cancel();
  }, [searchString]);

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  const debouncedSearchHandler = useMemo(() => debounce(handleSearch, 300));

  const addProductToAreaCountMutation = useMutation(
    inventoryClient.addProductsToAreaCount,
    {
      onSuccess: () => {
        onSuccessCallback();
      },
    }
  );

  const handleAddProduct = (formatId) => {
    addProductToAreaCountMutation.mutate({
      areaCountId,
      establishmentId,
      productFormatIds: [formatId],
    });
  };

  const closeModal = () => {
    toggleModalOpen(false);
    toggleLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {addProductToAreaCountMutation?.isLoading ? (
          <Grid
          container
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 6,
          }}
        >
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
          </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 6,
              }}
            >
              <Grid item>
                <Typography variant="h4">Add Product to Count</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  sx={{ backgroundColor: "transparent" }}
                  onClick={closeModal}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box
              sx={{ marginBottom: 2, display: "flex", flexDirection: "row" }}
            >
              <Typography
                variant="h4"
                sx={{ fontSize: { xs: 16, sm: 16, md: 20 } }}
              >
                Search by Product Name
              </Typography>
            </Box>
            <Box sx={{ marginBottom: 6, marginTop: 4 }}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                placeholder="Product Name"
                autoComplete="off"
                label="Name"
                InputLabelProps={{ shrink: true }}
                onChange={debouncedSearchHandler}
              />
            </Box>
            <Box>
              <SearchResultsList
                results={searchResults}
                isLoading={loading}
                addProduct={handleAddProduct}
              />
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AddProductToCountModal;
