import React from "react";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  ListItem,
  Typography,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CTABlock from "../../../CTABlock";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import DragHandleIcon from "@mui/icons-material/DragHandle";

const ProductOptionsList = ({
  options,
  searching,
  handleOptionCheckUncheck,
}) => {
  if (options.length === 0) {
    return searching ? (
      <CTABlock
        header="No Results Found"
        hideButton
        ButtonImage={AddOutlinedIcon}
      />
    ) : (
      <CTABlock
        header="There are no products missing from this area"
        subheader=""
        hideButton
        ButtonImage={AddOutlinedIcon}
      />
    );
  }

  return (
    <Box sx={{ width: "100%", marginTop: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          {options.map((o) => (
            <Box key={o.id}>
              <ListItem
                sx={{
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  height: "48px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 2,
                  borderBottom: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                }}
                // onClick={() => toggleSelected(!selected)}
              >
                <Grid container>
                  <Grid container direction="row">
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 2,
                      }}
                    >
                      {/* <DragHandleIcon
                        sx={{
                          fontSize: 16,
                          color: (theme) => theme.palette.primary[800],
                        }}
                      /> */}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "360px",
                      }}
                    >
                      <Box>
                        <div
                          style={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                            variant="body1"
                            noWrap
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {o.name}
                          </Typography>
                        </div>
                      </Box>
                      <Box>
                        <Typography variant="overline">
                          {o.unitSize ? o.unitSize : "(missing product size)"}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ dispaly: "flex", justifyContent: "flex-end" }}
                  xs={4}
                >
                  <Grid item>
                    <Checkbox id={o.id} onChange={handleOptionCheckUncheck} />
                  </Grid>
                  <Grid item>
                    <IconButton variant="outlined">
                      <MoreHorizIcon
                        sx={{
                          color: (theme) => theme.palette.primary[800],
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductOptionsList;
