import React from "react";
import { Box, Typography } from "@mui/material";

const menuCardStyle = {
  borderRadius: 4,
  padding: 2,
  width: {
    xs: "180px",
    md: "280px",
  },
  maxWidth: "280px",
  height: "60px",
  maxHeight: "60px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: "3px solid",
  borderColor: (theme) => theme.palette.terrain[300],
  backgroundColor: (theme) => theme.palette.pure.white,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: (theme) => theme.palette.terrain[300],
  },
};

const MenuOption = ({ data, handleClick }) => {

  return (
    <Box sx={menuCardStyle}>
      <Box
        component="div"
        onClick={handleClick}
        sx={{
          minWidth: { xs: "80px", md: "160px" },
          maxWidth: { xs: "80px", md: "160px" },
        }}
      >
        <Typography
          variant="largeButton"
          noWrap
          sx={{ fontSize: { xs: "12px", md: "16px" } }}
        >
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: "20px",
              textTransform: "none",
            }}
          >
            {data.name}
          </div>
        </Typography>
        {data.active === false && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "16px",
              backgroundColor: (theme) => theme.palette.terrain[100],
              borderRadius: 0.5,
              padding: 1,
            }}
          >
            <Typography
              variant="overline"
              sx={{
                fontWeight: 700,
                fontSize: 10,
                color: (theme) => theme.palette.pure.black,
              }}
            >
              Inactive
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MenuOption;
