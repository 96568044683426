import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import CTABlock from "../../../components/CTABlock";
import CountDetailRow from "./CountDetailRow";
import DescriptionToolTip from "../../../components/DescriptionToolTip";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const StockCountTable = ({ stockCounts, setPage, totalPages }) => {
  if (stockCounts.length > 0)
    return (
      <>
        <TableContainer
          sx={{
            overflow: "scroll",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            border: "1px solid",
            borderBottom: "none",
            borderColor: (theme) => theme.palette.terrain[400],
          }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead sx={{ height: "24px" }}>
              <TableRow sx={{ height: 24 }}>
                <TableCell
                  sx={[tableHeadStyle, { width: "420px", borderLeft: "none" }]}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Count Name
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Count Name"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The default count name is the date on which the
                              count was started.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Count Type
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Count Type"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              <Typography variant="body2">
                                Inventory Audit
                              </Typography>
                              <Typography variant="body1">
                                An Inventory Audit consists of a full or partial
                                count that allows you to check specific areas or
                                complete a full establishment product count.
                              </Typography>
                              <Typography variant="body2" sx={{ marginTop: 1 }}>
                                Par Check
                              </Typography>
                              <Typography variant="body1">
                                A Par Check, sometimes referred to as a line
                                count, allows you to count specific products,
                                check par levels, and plan for orders.
                              </Typography>
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Products Counted
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Products Counted"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The number of Product Formats that were counted in
                              this Stock Count.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Time
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Time"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The time at which the Stock Count was started.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={[tableHeadStyle, { width: "84px" }]}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Date
                    <Box component="div" sx={{ marginRight: -2 }}>
                      <DescriptionToolTip
                        header="Date"
                        description={
                          <Box component="div">
                            <Box sx={{ marginTop: 1 }}>
                              The date on which the Stock Count was started.
                            </Box>
                          </Box>
                        }
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={[tableHeadStyle, { width: "48px", borderRight: "none" }]}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stockCounts &&
                stockCounts.map((x) => (
                  <CountDetailRow
                    tableCellStyle={tableCellStyle}
                    key={x.id}
                    count={x}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {totalPages > 1 ? (
          <Pagination
            sx={{ marginTop: 6 }}
            count={totalPages}
            onChange={(_, page) => setPage(page)}
          />
        ) : null}
      </>
    );
  return (
    <CTABlock
      header="You have no past counts"
      subheader="As you complete stock counts they will appear here"
      hideButton
    />
  );
};

export default StockCountTable;
