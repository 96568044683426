import React from "react";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import LiquorOutlinedIcon from "@mui/icons-material/LiquorOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LogoStampWhite from "../../../assets/img/LogoStampWhite.png";
import FeatureCard from "./FeatureCard";

const iconStyle = {
  margin: { lg: 2, xl: 4 },
  fontSize: 24,
  color: (theme) => theme.palette.primary[800],
};

const UpgradeCard = ({ handleModalToggle }) => {
  const featureCardOptions = [
    {
      name: "Unlimited Recipes",
      icon: <LiquorOutlinedIcon sx={iconStyle} />,
      description: "Dynamic ingredient and labor costing",
    },
    {
      name: "Unlimited Menus",
      icon: <LocalLibraryOutlinedIcon sx={iconStyle} />,
      description: "Costing, markup calculations, price recommendations",
    },
    {
      name: "Organization",
      icon: <LocalShippingOutlinedIcon sx={iconStyle} />,
      description: "Create areas and sections to make stock counts faster",
    },
    {
      name: "Invoices",
      icon: <ReceiptLongOutlinedIcon sx={iconStyle} />,
      description:
        "Record invoices, link to orders and keep product prices up to date",
    },
    {
      name: "Stock Counts",
      icon: <InsertChartOutlinedIcon sx={iconStyle} />,
      description: "Count and analyze on-hand products",
    },
    {
      name: "Reporting",
      icon: <SummarizeIcon sx={iconStyle} />,
      description: "Create Reports for Stock Counts, Invoices, and Orders",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.pure.white,
        border: "2px solid",
        borderColor: (theme) => theme.palette.primary[800],
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "88px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          backgroundColor: (theme) => theme.palette.primary[800],
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Typography variant="smallButton">Upgrade to</Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="Spec Logo"
              src={LogoStampWhite}
              sx={{
                height: "28px",
                width: "28px",
                marginRight: 2,
              }}
            />
            <Typography
              variant="h2"
              sx={{
                color: (theme) => theme.palette.pure.white,
              }}
            >
              Spec Pro
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        direction="column"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid item sx={{ marginTop: 4 }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 700, fontSize: { xs: 12, md: 16 } }}
          >
            What does Spec Pro have to offer?
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: 2, padding: 4 }}>
          <Typography
            variant="subtitle2"
            sx={{ textAlign: "center", fontSize: { xs: 12, md: 16 } }}
          >
            Access a suite of tailored features that can help streamline your
            establishment{`'`}s operations, maximize cost savings, and reduce
            errors.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ width: "90%", display: "flex", justifyContent: "center" }}
          >
            {featureCardOptions.map((x) => (
              <Grid
                item
                key={x.name}
                xs={12}
                sm={4}
                md={3}
                lg={2}
                sx={{ width: "100%" }}
              >
                <FeatureCard featureCardOptions={x} />
              </Grid>
            ))}
          </Grid>
          <Box></Box>
        </Grid>
        <Grid item sx={{ marginTop: 4, marginBottom: 4 }}>
          <Button
            variant="contained"
            sx={{ width: "200px", height: "40px" }}
            onClick={() => handleModalToggle()}
          >
            <Typography
              variant="largeButton"
              sx={{
                color: (theme) => theme.palette.pure.white,
              }}
            >
              Upgrade Now
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpgradeCard;
