import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Badge,
  Tooltip,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useMutation, useQueryClient } from "react-query";
import Store from "../../../Store/Store";
import inventoryClient from "../../../clients/inventoryClient";
import useOrderCart from "../../../hooks/useOrderCart";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import utilFunctions from "../../../utilities/utilFunctions";
import AddMissingDetailsButton from "./AddMissingDetailsButton";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import CheckIcon from "@mui/icons-material/Check";

const selectPlaceholder = { id: -1, name: "" };
const NewOrderProductCard = ({
  product,
  setMissingDetailsData,
  vendorFilter,
}) => {
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { currency, locale, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );
  const [currentVendor, setCurrentVendor] = useState(selectPlaceholder);
  const [isInitialRender, toggleIsInitialRender] = useState(true);
  const [currentFormat, setCurrentFormat] = useState({});
  const [orderQuantity, setOrderQuantity] = useState(1);
  const [filteredFormatOptions, setFilteredFormatOptions] = useState([]);
  const [currentPurchaseUnit, setCurrentPurchaseUnit] =
    useState(selectPlaceholder);
  const queryClient = useQueryClient();
  const cartData = useOrderCart(store.currentEstablishment?.id);
  const productAdded = Boolean(
    cartData?.data?.cart?.items.find(
      (x) => x.purchaseUnitId === currentPurchaseUnit.id
    )
  );

  const handleMissingDetailsClick = () => {
    setMissingDetailsData({
      vendor: currentVendor,
      purchaseUnit: currentPurchaseUnit,
      product,
      currentFormat,
      orderQuantity,
    });
  };

  useEffect(() => {
    let defaultFormat = product?.productFormats?.find(
      (x) => x.isDefault === true
    );

    if (!defaultFormat) {
      defaultFormat = product?.productFormats[0];
    }
    setCurrentFormat(defaultFormat);
    let defaultPurchaseUnit = defaultFormat.purchaseDetails?.find(
      (x) => x.isDefault
    );

    if (defaultPurchaseUnit) {
      setCurrentVendor(defaultPurchaseUnit.vendor || selectPlaceholder);
      setCurrentPurchaseUnit(defaultPurchaseUnit);
    } else {
      defaultPurchaseUnit = defaultFormat.purchaseDetails[0];
      setCurrentPurchaseUnit(defaultPurchaseUnit || selectPlaceholder);
      setCurrentVendor(defaultPurchaseUnit?.vendor || selectPlaceholder);
    }
  }, [product]);

  useEffect(() => {
    if (vendorFilter) {
      // we find any formats the have a PU for the vendor that was selected
      // then we create a filtered set of options
      const matchingFormats = [];
      product.productFormats.forEach((x) => {
        let containsMatch = false;
        const validPUs = [];

        x.purchaseDetails.forEach((pu) => {
          if (vendorFilter?.id === pu.vendorId) {
            containsMatch = true;
            validPUs.push(pu);
          }
        });

        if (containsMatch) {
          const filteredFormat = Object.assign({}, x, {
            purchaseDetails: validPUs,
          });
          console.log(filteredFormat);
          matchingFormats.push(filteredFormat);
        }
      });
      setFilteredFormatOptions(matchingFormats);

      // we also need to update the current format and PU to be valid options based on the new filtered list
      if (matchingFormats.length > 0) {
        const defaultInList =
          matchingFormats.find((x) => x.isDefault === true) ||
          matchingFormats[0];
        setCurrentFormat(defaultInList);
        setCurrentPurchaseUnit(
          defaultInList.purchaseDetails.find((x) => x.isDefault === true) ||
            defaultInList.purchaseDetails[0]
        );
      }
    } else if (isInitialRender) {
      toggleIsInitialRender(false);
    } else {
      const unfilteredCurrentFormat = product.productFormats.find(
        (x) => x.id === currentFormat.id
      );
      setCurrentFormat(unfilteredCurrentFormat);
    }
  }, [vendorFilter]);

  const addCartItemsMutation = useMutation(inventoryClient.addCartItems, {
    onSuccess: (result) => {
      queryClient.setQueryData(
        ["ordercart", { establishmentId: store.currentEstablishment?.id }],
        (prev) => {
          const newCartData = prev.cart.items.map((x) => {
            return Object.assign({}, x);
          });

          return { cart: { items: [...newCartData, ...result.newCartItems] } };
        }
      );
    },
  });

  const handleAddItem = async () => {
    await addCartItemsMutation.mutateAsync({
      establishmentId: store.currentEstablishment?.id,
      items: [
        {
          purchaseUnitId: currentPurchaseUnit?.id,
          productSizeId: currentFormat.id,
          productId: product.id,
          vendorId: currentVendor.id,
          orderAmount: orderQuantity,
        },
      ],
    });
  };

  const vendorOptions = {};
  currentFormat.purchaseDetails?.forEach((d) => {
    if (d.vendorId !== null) {
      vendorOptions[d.vendorId] = d.vendor;
    }
  });

  const renderPurchaseUnitOptions = () => {
    if (currentFormat.purchaseDetails?.length === 0) {
      return (
        <AddMissingDetailsButton
          text={"+ Add Unit Type"}
          onClick={handleMissingDetailsClick}
        />
      );
    }

    if (!currentPurchaseUnit?.name || !currentPurchaseUnit?.unitAmount) {
      return (
        <AddMissingDetailsButton
          text={"+ Add Unit Type"}
          onClick={handleMissingDetailsClick}
        />
      );
    }

    return (
      <TextField
        select
        size="small"
        variant="outlined"
        label="Purchase Unit"
        fullWidth
        value={currentPurchaseUnit?.id || ""}
        onChange={handlePurchaseUnitChange}
        disableUnderline
        SelectProps={{
          MenuProps: {
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: (theme) => theme.palette.primary[800],
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: (theme) => theme.palette.secondary[600],
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: (theme) => theme.palette.secondary[800],
              },
            },
          },
        }}
      >
        {currentFormat?.purchaseDetails?.map((o) => (
          <MenuItem key={o.id} value={o.id}>{`${o.name} ${
            o.unitAmount === null ? "" : `(x${o.unitAmount})`
          }`}</MenuItem>
        ))}
      </TextField>
    );
  };

  const renderProductFormatOptions = () => {
    const formatsToRender = vendorFilter
      ? filteredFormatOptions
      : product.productFormats;
    return (
      <TextField
        select
        size="small"
        variant="outlined"
        label="Unit Size"
        InputLabelProps={{ shrink: true }}
        fullWidth
        value={currentFormat?.id || ""}
        onChange={handleProductFormatChange}
        disableUnderline
        SelectProps={{
          MenuProps: {
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: (theme) => theme.palette.primary[800],
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: (theme) => theme.palette.secondary[600],
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: (theme) => theme.palette.secondary[800],
              },
            },
          },
        }}
      >
        {formatsToRender.map((x) => (
          <MenuItem key={x.id} value={x.id}>
            {utilFunctions.extractFormatSizingName(x)}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  // const handleVendorChange = (e) => {
  //   setCurrentVendor(vendorOptions[e.target.value]);
  //   const defaultForVendor = currentFormat.purchaseDetails?.find(
  //     (d) => d.vendorId === e.target.value
  //   );
  //   setCurrentPurchaseUnit(defaultForVendor || selectPlaceholder);
  // };

  const handlePurchaseUnitChange = (e) => {
    setCurrentPurchaseUnit(
      currentFormat.purchaseDetails.find((x) => x.id === e.target.value)
    );
  };

  const handleProductFormatChange = (e) => {
    const formatsToUse = vendorFilter
      ? filteredFormatOptions
      : product.productFormats;
    const newCurrentFormat = formatsToUse?.find((x) => x.id === e.target.value);

    let defaultPurchaseUnit = newCurrentFormat.purchaseDetails?.find(
      (x) => x.isDefault
    );
    console.log(newCurrentFormat);
    if (defaultPurchaseUnit) {
      setCurrentVendor(defaultPurchaseUnit.vendor || selectPlaceholder);
      setCurrentPurchaseUnit(defaultPurchaseUnit);
    } else {
      defaultPurchaseUnit = newCurrentFormat.purchaseDetails[0];
      setCurrentPurchaseUnit(defaultPurchaseUnit || selectPlaceholder);
      setCurrentVendor(defaultPurchaseUnit?.vendor || selectPlaceholder);
    }

    setCurrentFormat(newCurrentFormat);
  };

  const handleOrderQuantityChange = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setOrderQuantity(e.target.value);
    }
  };

  if (vendorFilter && filteredFormatOptions?.length === 0) {
    return null;
  }

  return (
    <Grid
      item
      xs={12}
      sx={{
        marginTop: 2,
        height: { xs: "248px", md: "124px" },
        border: "1px solid",
        backgroundColor: (theme) => theme.palette.pure.white,
        borderColor: (theme) =>
          productAdded
            ? theme.palette.success[800]
            : theme.palette.terrain[400],
        borderRadius: 2,
      }}
    >
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} md={7}>
          <Grid container direction="row">
            {/* IMAGE */}
            <Grid item xs={8} md={10}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Badge
                  invisible={!productAdded}
                  overlap="circular"
                  badgeContent={
                    <CheckIcon sx={{ fontSize: 18, color: "#fff" }} />
                  }
                  sx={{
                    "& .MuiBadge-badge": {
                      minHeight: "32px",
                      minWidth: "32px",
                      borderRadius: "100%",
                    },
                  }}
                  color="success"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      maxWidth: "100px",
                      width: "100px",
                      height: "100px",
                      maxHeight: "100px",
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.terrain[400],
                      borderRadius: 1,
                      padding: 1,
                      aspectRatio: "1 / 1",
                      overflow: "hidden",
                    }}
                  >
                    {currentFormat?.imageUrl ? (
                      <Box
                        component="img"
                        sx={{
                          width: "110%",
                          objectFit: "scale-down",
                          aspectRatio: 1 / 1,
                        }}
                        src={currentFormat?.imageUrl}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          objectFit: "scale-down",
                          aspectRatio: 1 / 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <BrokenImageIcon
                          sx={{
                            fontSize: 36,
                            color: (theme) => theme.palette.terrain[300],
                          }}
                        />
                        <Typography
                          variant="overline"
                          sx={{
                            fontWeight: 700,
                            fontSize: 8,
                            color: (theme) => theme.palette.terrain[300],
                            textAlign: "center",
                          }}
                        >
                          IMAGE MISSING FROM PRODUCT FORMAT
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Badge>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "100%",
                    paddingLeft: 4,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      fontWeight: 700,
                      color: (theme) =>
                        productAdded
                          ? theme.palette.success[800]
                          : theme.palette.pure.blck,
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        minWidth: "100%",
                        maxWidth: "100%",
                        textTransform: "none",
                      }}
                    >
                      {product.name}
                    </div>
                  </Typography>
                {/* VENDOR */}
                <Box sx={{ padding: 0 }}>
                  <Grid item>
                    {currentPurchaseUnit?.vendor === null ||
                    currentPurchaseUnit?.vendor === undefined ? (
                      <Box sx={{ height: "21px", marginTop: 1 }}>
                        <AddMissingDetailsButton
                          text="+ Add Vendor"
                          onClick={handleMissingDetailsClick}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          height: "21px",
                          marginTop: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          size="small"
                          fullWidth
                          disableUnderline
                          label="Vendor"
                        >
                          Vendor:
                        </Typography>
                        <Typography
                          variant="body2"
                          noWrap
                          sx={{
                            color: (theme) => theme.palette.primary.main,
                            marginLeft: 2,
                          }}
                        >
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              minWidth: "100%",
                              maxWidth: "100%",
                              textTransform: "none",
                            }}
                          >
                            {currentPurchaseUnit?.vendor?.name || ""}
                          </div>
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Box>
                <Box sx={{ width: { xs: "100%", md: "67%" } }}>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 3,
                    }}
                  >
                    {/* UNIT SIZE */}
                    <Grid item xs={4}>
                      {renderProductFormatOptions()}
                    </Grid>
                    {/* Purchase Unit */}
                    <Grid item xs={4}>
                      {renderPurchaseUnitOptions()}
                    </Grid>
                    {/* Quantity */}
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        label="Quantity"
                        disabled={productAdded}
                        placeholder="# of Purchase Units"
                        value={orderQuantity}
                        InputLabelProps={{ shrink: true, required: true }}
                        onChange={handleOrderQuantityChange}
                        sx={{maxWidth: "150px"}}
                      />
                    </Grid>
                  </Grid>
                </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            marginTop: { xs: 4, md: 0 },
            height: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "space-between",
          }}
        >
          {/* PAR LEVELS */}
          <Box
            sx={{
              height: "40px",
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
              marginRight: 2,
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: { xs: "100%", md: "280px" },
                height: "100%",
              }}
            >
              <Grid
                item
                sx={{
                  backgroundColor: (theme) => theme.palette.error[50],
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 2,
                  width: "31%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      textAlign: "center",
                      fontWeight: 700,
                      color: (theme) => theme.palette.pure.black,
                    }}
                  >
                    Low Par:
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1">
                    {currentFormat.par?.low}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  backgroundColor: (theme) => theme.palette.info[50],
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 2,
                  width: "31%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      textAlign: "center",
                      fontWeight: 700,
                      color: (theme) => theme.palette.pure.black,
                    }}
                  >
                    High Par:
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1">
                    {currentFormat.par?.high}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  backgroundColor: (theme) => theme.palette.warning[50],
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 2,
                  width: "31%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      textAlign: "center",
                      fontWeight: 700,
                      color: (theme) => theme.palette.pure.black,
                    }}
                  >
                    On Hand:
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1">
                    {currentFormat.inventoryState
                      ? currentFormat.inventoryState.onHand
                      : "-"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
              marginTop: 4,
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: { xs: "100%", md: "280px" },
                height: "100%",
                marginRight: 2,
                padding: 0,
              }}
            >
              <Grid item sx={{ width: "45%" }}>
                <Tooltip
                  title={
                    currentPurchaseUnit?.price === null ||
                    currentPurchaseUnit?.price === undefined
                      ? ""
                      : "Price estimated based on the purchase unit's price in your Product Library."
                  }
                  arrow // Optional: adds an arrow to the tooltip
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      backgroundColor: (theme) => theme.palette.terrain[100],
                      borderRadius: 1,
                      height: "40px",
                    }}
                  >
                    {currentPurchaseUnit?.price === null ||
                    currentPurchaseUnit?.price === undefined ? (
                      <AddMissingDetailsButton
                        text="+ Add Price"
                        onClick={handleMissingDetailsClick}
                      />
                    ) : (
                      <Typography variant="h4" sx={{ marginRight: 2 }}>
                        {currentPurchaseUnit?.price?.amount !== null &&
                        currentPurchaseUnit?.price?.amount !== undefined
                          ? `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
                              currentPurchaseUnit?.price?.amount,
                              locale,
                              currency
                            )}`
                          : "N/A"}
                      </Typography>
                    )}
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item sx={{ width: "55%" }}>
                {/* BUTTON */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {addCartItemsMutation.isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Tooltip
                      title={
                        currentVendor.id === -1 || currentPurchaseUnit.id === -1
                          ? "This item is missing required properties, please check the details on the left. There should be indicators to add whats missing."
                          : ""
                      }
                      arrow // Optional: adds an arrow to the tooltip
                    >
                      <span>
                        {" "}
                        {/* Wrapping the Button in a span ensures Tooltip works even when Button is disabled */}
                        <Button
                          variant="text"
                          disabled={
                            addCartItemsMutation.isLoading ||
                            currentVendor.id === -1 ||
                            currentPurchaseUnit.id === -1 ||
                            productAdded
                          }
                          sx={{
                            width: "100%",
                            height: "40px",
                            backgroundColor: (theme) =>
                              productAdded
                                ? theme.palette.success[50]
                                : theme.palette.terrain[50],
                            marginLeft: 2,
                          }}
                          onClick={handleAddItem}
                        >
                          {productAdded ? (
                            <CheckOutlinedIcon
                              sx={{
                                fontSize: 20,
                                color: (theme) => theme.palette.success[800],
                              }}
                            />
                          ) : (
                            <AddOutlinedIcon
                              sx={{
                                fontSize: 20,
                              }}
                            />
                          )}
                          <Typography
                            variant="smallButton"
                            sx={{
                              marginLeft: 1,
                              color: (theme) =>
                                productAdded && theme.palette.success[800],
                            }}
                          >
                            {productAdded ? "Added to Cart" : "Add to Cart"}
                          </Typography>
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewOrderProductCard;
