import React, { useState, useContext } from "react";
import { Avatar, Box, Menu, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Store from "../../../Store/Store";
import { useNavigate } from "react-router-dom";

const EstablishmentDropdown = () => {
  const store = useContext(Store);
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleProfileView = () => {
    navigate('/profile-details');
  }

  return (
    <>
      {store.currentEstablishment.imageURL ? (
        <Avatar
          sx={{
            color: (theme) => theme.palette.terrain["800"],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: { xs: -6, sm: -4 },
            marginLeft: { xs: 1, sm: 2 },
            width: "34px",
            height: "34px",
            border: "3px solid",
            borderColor: (theme) => theme.palette.terrain[800],
            borderRadius: "100%",
            cursor: "pointer",
            overflow: "hidden",
            "&:hover": {
              borderColor: (theme) => theme.palette.primary.main,
            },
          }}
          src={store.currentEstablishment.imageURL}
          onClick={handleClick}
        />
      ) : (
        <Box
          onClick={handleClick}
          title="Establishment Options"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: { xs: -6, sm: -4 },
            marginLeft: { xs: 1, sm: 2 },
            width: "32px",
            height: "32px",
            border: "3px solid",
            borderColor: (theme) => theme.palette.terrain[800],
            borderRadius: 150,
            cursor: "pointer",
            overflow: "hidden",
            "&:hover": {
              borderColor: (theme) => theme.palette.primary.main,
            },
          }}
        >
          <HomeOutlinedIcon
            sx={{
              fontSize: { xs: 14, sm: 18 },
              color: (theme) => theme.palette.pure["white"],
            }}
          />
        </Box>
      )}

      <Menu
        id="basic-menu"
        elevation={0}
        anchorEl={anchor}
        open={open}
        onClose={() => setAnchor(null)}
        MenuListProps={{
          sx: {
            background: (theme) => theme.palette.terrain[50],
            // border: "1px solid black",
          },
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            left: "50%",
            transform: "translateX(0px) translateY(0px)",
            boxShadow: "0px 8px 40px 0px rgba(0, 0, 0, 0.25)",
            background: (theme) => theme.palette.terrain[50],
          },
        }}
      >
          <MenuItem
            onClick={handleProfileView}
            sx={{
              ":hover": {
                backgroundColor: (theme) => theme.palette.terrain["02"],
              },
            }}
          >
            {<AccountCircleIcon sx={{ marginRight: "10px" }} />}Profile
          </MenuItem>
          <MenuItem
            onClick={store.logout}
            sx={{
              ":hover": {
                backgroundColor: (theme) => theme.palette.terrain["02"],
              },
            }}
          >
            {<LogoutIcon sx={{ marginRight: "10px" }} />}Logout
          </MenuItem>
      </Menu>
    </>
  );
};

export default EstablishmentDropdown;
