import { Box, Grid } from "@mui/material";
import React from "react";
import EstablishmentCard from "./EstablishmentCard";
// import DataCard from "./DataCard";
import SummaryCard from "./SummaryCard";
import { useParams } from "react-router-dom";

const FullSummary = ({
  inventoryReportingOverview,
  staffMemberCount,
  establishment,
  accountStatus,
}) => {
  const { establishmentid: establishmentId } = useParams();
  return (
    <Box sx={{ marginTop: 6 }}>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          direction="row"
          spacing={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={6} md={6}>
            <EstablishmentCard
              establishment={establishment}
              accountStatus={accountStatus}
            />
          </Grid>
          {/* <Grid item xs={7}>
            <Grid container direction="row">
              <Grid item xs={11.5}>
                <DataCard />
              </Grid>
              <Grid item xs={0.5}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "132px",
                  }}
                >
                  <MobileStepper
                    steps={4}
                    position="static"
                    sx={{
                      transform: "rotate(90deg)",
                      width: "100px",
                      backgroundColor: "transparent",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={3} sm={3} md={3}>
            <SummaryCard
              header="Staff Members"
              amount={staffMemberCount}
              targetRoute={`/homebase/${establishmentId}/staffmanagement`}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <SummaryCard
              header="Recipes"
              amount={inventoryReportingOverview.recipeCount}
              targetRoute={`/spec/${establishmentId}/recipes`}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <SummaryCard
              header="Menus"
              amount={inventoryReportingOverview.menuCount}
              targetRoute={`/spec/${establishmentId}/menus`}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <SummaryCard
              header="Products"
              amount={inventoryReportingOverview.productCount}
              targetRoute={`/spec/${establishmentId}/productlibrary`}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <SummaryCard
              header="Vendors"
              amount={inventoryReportingOverview.vendorCount}
              targetRoute={`/spec/${establishmentId}/vendors`}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <SummaryCard
              header="Stock Counts"
              amount={inventoryReportingOverview.stockCounts}
              targetRoute={`/spec/${establishmentId}/stockcount`}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={2}>
            <SummaryCard
              header="Orders"
              amount={inventoryReportingOverview.orderCount}
              targetRoute={`/spec/${establishmentId}/ordering`}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <SummaryCard
              header="Invoices"
              amount={inventoryReportingOverview.invoiceCount}
              targetRoute={`/spec/${establishmentId}/invoices`}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Areas that Need Attention Section */}
      {/* <Box sx={{ marginTop: 6 }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={10}>
            <Typography variant="h4">Areas Needing Attention:</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{
                border: "2px solid",
                borderColor: (theme) => theme.palette.primary[800],
                marginRight: 1,
              }}
            >
              <ChevronLeftOutlinedIcon />
            </IconButton>
            <IconButton
              sx={{
                border: "2px solid",
                borderColor: (theme) => theme.palette.primary[800],
                marginLeft: 1,
              }}
            >
              <ChevronRightOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 4 }}>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {AttentionDummyData.map((x) => (
              <AttentionCard key={x.headerText} data={x} />
            ))}
          </Grid>
        </Box>
      </Box> */}
    </Box>
  );
};

export default FullSummary;
