import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box } from "@mui/system";
import { Typography, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const MessageSubjectStyle = {
  maxWidth: 600,
  padding: 0,
};

const MessageBodyStyle = {
  maxWidth: "100%",
  padding: 0,
  marginTop: 1,
};

const MessageCard = ({
  activeMessage,
  setActiveMessage,
  message,
  deleteModalCallback,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteModal = () => {
    deleteModalCallback(message.id);
    handleClose();
  };

  const selectedMessageIconButtonStyle =
    message.id === activeMessage
      ? {
          border: "none",
          color: (theme) => theme.palette.terrain[100],
          backgroundColor: (theme) => theme.palette.secondary[900],
          height: "28px",
          width: "28px",
          borderRadius: 2,
          "&:hover": {
            color: "#E78271",
            backgroundColor: (theme) => theme.palette.secondary[900],
          },
        }
      : {
          border: "none",
          color: (theme) => theme.palette.terrain[900],
          height: "28px",
          width: "28px",
          borderRadius: 2,
          "&:hover": {
            color: "#E78271",
            backgroundColor: (theme) => theme.palette.pure.white,
          },
        };

  const handleChange = (id) => {
    if (activeMessage === id) {
      setActiveMessage(undefined);
    } else {
      setActiveMessage(id);
    }
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          boxShadow: "none",
          border: "1px solid",
          borderRadius: 2,
          borderColor: (theme) => theme.palette.terrain[400],
          marginBottom: 2,
        }}
        key={message.id}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            padding: 4,
            backgroundColor: (theme) =>
              message?.id === activeMessage
                ? theme.palette.secondary[900]
                : theme.palette.pure.white,
            color: (theme) =>
              message.id === activeMessage
                ? theme.palette.pure.white
                : theme.palette.pure.black,
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={11}>
              <Grid
                container
                direction="row"
                onClick={() => {
                  handleChange(message.id);
                }}
                sx={{ padding: 0 }}
              >
                <Grid item xs={12} sx={{ margin: 0 }}>
                  <Box component="div" sx={MessageSubjectStyle}>
                    <Typography variant="body2">{message.title}</Typography>
                  </Box>
                </Grid>
                <Grid component="div" item xs={12}>
                  <Box component="div" sx={MessageBodyStyle}>
                    <div
                      style={{
                        maxWidth: "90%",
                        minHeight: "24px",
                        maxHeight: "24px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        variant="body1"
                        noWrap
                        sx={{
                          color: (theme) =>
                            message.id === activeMessage
                              ? theme.palette.pure.white
                              : theme.palette.pure.black,
                        }}
                      >
                        {message.body}
                      </Typography>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton
                onClick={handleClick}
                variant="outlined"
                sx={selectedMessageIconButtonStyle}
              >
                <MoreHorizIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleDeleteModal} sx={{ margin: "5px" }}>
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} /> Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default MessageCard;
