import { Box, Button, TableCell, TableRow, Typography } from "@mui/material";
import React, { useContext } from "react";
import useVendorCurrency from "../../hooks/useVendorCurrency";
import Store from "../../Store/Store";
import utilFunctions from "../../utilities/utilFunctions";
import ParDisplayBadge from "./ParDisplayBadge";

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const CountProductListRow = ({
  record,
  openRecordCostSetModal,
  toggleParsModalOpen,
}) => {
  const store = useContext(Store);
  const { currencySymbol, currency, locale } = useVendorCurrency(
    store.user?.country
  );

  const handleOpenRecordCostSetting = () => {
    openRecordCostSetModal(record);
  }

  return (
    <TableRow sx={{ height: 24 }}>
      <TableCell sx={[tableCellStyle, { width: "376px" }]}>
        <Box>
          <Typography variant="body1">
            {record.historicalProductName || "[Missing Product Name]"}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="overline"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            {record.historicalSizingDisplay || "[Missing Sizing Data]"}
          </Typography>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "100px" }]}>
        {record.failedValueCalculation && !record.isValueManuallySet ? (
          <Box
            sx={{
              // width: "100px",
              height: "24px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Button variant="text" onClick={handleOpenRecordCostSetting}>
              <Typography
                variant="smallButton"
                sx={{ textDecoration: "underline" }}
              >
                Set Cost
              </Typography>
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: (theme) => theme.palette.terrain[100],
              borderRadius: 1,
              padding: 2,
              height: "24px",
              width: "fit-content",
              marginLeft: 2,
            }}
          >
            <Typography variant="body1">
              {currencySymbol}
              {utilFunctions.convertCentsToLargerFormatCurrency(
                record.finalRecordValue?.amount || 0,
                locale,
                currency
              )}
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "100px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: (theme) => theme.palette.terrain[100],
            borderRadius: 1,
            padding: 2,
            height: "24px",
            minWidth: "60px",
            width: "fit-content",
          }}
        >
          <Typography variant="body1">{record?.quantityFound || 0}</Typography>
        </Box>
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            width: "100px",
          },
        ]}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ParDisplayBadge
            record={record}
            toggleParsModalOpen={toggleParsModalOpen}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default CountProductListRow;
