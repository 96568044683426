import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import DescriptionToolTip from "../../../../../components/DescriptionToolTip";

const AdditionalOptions = ({
  allowUnspecified,
  toggleAllowUnspecified,
  includeSumAll,
  toggleIncludeSumAll,
  hideVendorsWithNoGroups,
  toggleHideVendorsWithNoGroups,
  includeExpenseGroupSumRows,
  toggleIncludeExpenseGroupSumRows,
  includeExpenseSubgroupSumRows,
  toggleIncludeExpenseSubgroupSumRows,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox
          checked={allowUnspecified}
          onChange={() => toggleAllowUnspecified(!allowUnspecified)}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Allow Unspecified
        </Typography>
        <DescriptionToolTip
          header="Allow Products with Unspecified Expense Groups"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Whether or not to include a line item for products with an
                unspecified Expense Subgroup. This means if a product does not
                have an expense subgroup set for a purchase unit, it will get
                grouped under an &apos;Unspecified&apos; line item. This filter
                does not apply to expense groups and they are included for
                informational purposes, so expense groups will be shown for
                unspecified subgroup values. If &apos;Filter By&apos; is not set
                this setting has no effect.
              </Box>
            </Box>
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox checked={includeExpenseGroupSumRows} onChange={() => toggleIncludeExpenseGroupSumRows(!includeExpenseGroupSumRows)} />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Include Expense Group Sum Row
        </Typography>
        <DescriptionToolTip
          header="Include Expense Group Sum Row"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Include a row at the top of each expense group section showing
                the sum of all line items in that group.
              </Box>
            </Box>
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox checked={includeExpenseSubgroupSumRows} onChange={() => toggleIncludeExpenseSubgroupSumRows(!includeExpenseSubgroupSumRows)} />
        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
          Include Expense Subgroup Sum Row
        </Typography>
        <DescriptionToolTip
          header="Include Expense Subgroup Sum Row"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Include a row at the top of each expense subgroup section
                showing the sum of all line items in that subgroup.
              </Box>
            </Box>
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox
          checked={includeSumAll}
          onChange={() => toggleIncludeSumAll(!includeSumAll)}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Include All Sum Row
        </Typography>
        <DescriptionToolTip
          header="Include All Sum Row"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Include a row at the top of the entire report showing the sum of
                all invoice line items.
              </Box>
            </Box>
          }
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: -3,
        }}
      >
        <Checkbox
          checked={hideVendorsWithNoGroups}
          onChange={() =>
            toggleHideVendorsWithNoGroups(!hideVendorsWithNoGroups)
          }
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
          Hide Vendors with no line items in expense subgroup
        </Typography>
        <DescriptionToolTip
          header="Hide Vendors with no line items in Expense Subgroup"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Hides Vendors that have no line items for an expense subgroup in
                the report.
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default AdditionalOptions;
