import React from "react";
import { modalBoxStyle } from "../styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  CircularProgress,
} from "@mui/material";
import RuleIcon from '@mui/icons-material/Rule';

const ForceAreaCountCompletionModal = ({
  isOpen,
  cancelCallback,
  completeCallback,
  isLoading
}) => {

  return (
    <Modal
      open={isOpen}
      onClose={cancelCallback}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
          <>
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item sx={{ marginBottom: 4 }}>
                <RuleIcon sx={{ fontSize: "80px" }} />
              </Grid>
              <Grid item sx={{ marginBottom: 8 }}>
                <Typography variant="h2">Force Complete?</Typography>
              </Grid>
              <Grid item sx={{ marginBottom: 8 }}>
                <Typography variant="body1" sx={{ fontSize: "12px" }}>
                  {"This area still has some records that haven't been counted. Do you want to set all remaining records to 0 in this area and mark it as complete?"}
                </Typography>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={cancelCallback}
                  disabled={isLoading}
                  sx={{
                    marginLeft: "1rem",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="smallButton">No, Cancel</Typography>
                </Button>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={completeCallback}
                  sx={{
                    marginLeft: "1rem",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  {isLoading ? <CircularProgress /> : <Typography variant="smallButton">Yes, Complete</Typography>}
                </Button>
              </Box>
            </Grid>
          </>
      </Box>
    </Modal>
  );
};

export default ForceAreaCountCompletionModal;
