import { Box, Checkbox, TextField, Typography } from "@mui/material";
import React from "react";

const TopLimitControl = ({
  topLimit,
  setTopLimit,
  useTopLimit,
  toggleTopLimit,
}) => {
  const handleToggle = () => {
    toggleTopLimit(!useTopLimit);
  };

  const handleChangeLimit = (e) => {
    const val = e.target.value;

    if (/^[1-9]\d*$/.test(val) || val === "") {
      setTopLimit(val);
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox
          sx={{ marginLeft: -3 }}
          checked={useTopLimit}
          onChange={handleToggle}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Limit to Top {"'"}X{"'"} No. of Items
        </Typography>
      </Box>
      {useTopLimit ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "72px",
            width: "35%",
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.terrain[200],
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            padding: 2,
          }}
        >
          <TextField
            variant="outlined"
            autoComplete="off"
            fullWidth
            size="small"
            placeholder="Enter Limit"
            InputLabelProps={{ shrink: true }}
            sx={{ "& legend": { display: "none" } }}
            value={topLimit}
            onChange={handleChangeLimit}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default TopLimitControl;
