import React, { useContext, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Header from "../../components/Header/index";
import Footer from "../../components/Footer";
import SpecBackButton from "../../components/SpecBackButton";
import { useNavigate } from "react-router-dom";
import EditUserProfileModal from "../../components/Modals/EditUserProfileModal";
import CloseAccountModal from "../../components/Modals/CloseAccountModal";
import useUserProfile from "../../hooks/useUserProfile";
import SpecSpinner from "../../components/SpecSpinner";
import { useMutation, useQueryClient } from "react-query";
import { deleteUserAccount, updateProfile } from "../../clients/authClient";
import Store from "../../Store/Store";
import useAccountDeletableCheck from "../../hooks/useAccountDeletableCheck";
import moment from "moment";

const profileRowStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "52px",
  padding: 2,
  borderBottom: "1px solid",
  borderColor: (theme) => theme.palette.terrain[300],
};

const ProfileDetails = () => {
  const [editingUserProfile, toggleEditingUserProfile] = useState(false);
  const [closingAccount, toggleClosingAccount] = useState(false);
  const { isLoading: isLoadingProfile, data: profileData } = useUserProfile();
  const { isLoading: isLoadingAccountDeletableCheck, data: isAccountDeletableResponse } = useAccountDeletableCheck();
  const queryClient = useQueryClient();
  const store = useContext(Store);
  const navigate = useNavigate();

  const profileMutation = useMutation(updateProfile, {
    onSuccess: (data, variables) => {
      store.updateUserProfile(variables);
      queryClient.invalidateQueries({
        queryKey: [
          "userProfile"
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "userProfile"
        ],
      });
      toggleEditingUserProfile(false);
    },
  });

  const deleteAccountMutation = useMutation(deleteUserAccount, {
    onSuccess: () => {
      store.logout(true);
    }
  });

  const handleBack = () => {
    navigate("/establishments");
  };

  if (isLoadingProfile) {
    return <SpecSpinner open text={"Loading your profile..."} />
  }

  const handleSaveProfileUpdates = (data) => {
    const updatedUser = Object.assign({}, profileData?.profile, data);
    profileMutation.mutate(updatedUser);
  }

  const handleDeleteUserAccount = () => {
    deleteAccountMutation.mutate();
  }

  return (
    <>
    <Box sx={{ minHeight: "100%" }}>
      <Header isViewingProfile />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "600px",
          maxWidth: "2440px",
        }}
      >
        <Grid
          item
          sx={{
            maxWidth: "600px",
            width: "600px",
            height: "600px",
          }}
        >
          <Box sx={{ marginTop: 4 }}>
            <SpecBackButton onClick={handleBack} />
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <Grid container direction="row">
              <Grid item xs={6}>
                <Typography variant="h2">My Profile</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ borderWidth: 2 }}
                  onClick={() => toggleEditingUserProfile(true)}
                >
                  <Typography variant="largeButton">Edit Details</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: 4,
              border: "1px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              borderRadius: 2,
            }}
          >
            <Box sx={profileRowStyle}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                First Name
              </Typography>
              <Typography variant="subtitle2">{profileData?.profile?.firstName || ""}</Typography>
            </Box>
            <Box sx={profileRowStyle}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                Last Name
              </Typography>
              <Typography variant="subtitle2">{profileData?.profile?.lastName || ""}</Typography>
            </Box>
            <Box sx={profileRowStyle}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                Email Address
              </Typography>
              <Typography variant="subtitle2">{profileData?.profile?.email || ""}</Typography>
            </Box>
            <Box sx={profileRowStyle}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                Mobile Number
              </Typography>
              <Typography variant="subtitle2">{profileData?.profile?.phone || ""}</Typography>
            </Box>
            <Box sx={[profileRowStyle, { borderBottom: "none" }]}>
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                Birthday
              </Typography>
              <Typography variant="subtitle2">{profileData?.profile?.birthday ? moment(profileData.profile.birthday).format("MM/DD/YYYY") : ""}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button variant="text" onClick={() => toggleClosingAccount(true)}>
              <Typography variant="largeButton" sx={{ fontWeight: 700 }}>
                Close Your Account
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <EditUserProfileModal
        isOpen={editingUserProfile}
        toggleModalOpen={toggleEditingUserProfile}
        profile={profileData?.profile || {}}
        onSaveCallback={handleSaveProfileUpdates}
        loading={profileMutation.isLoading}
      />
      <CloseAccountModal
        isOpen={closingAccount}
        toggleModalOpen={toggleClosingAccount}
        isLoadingCheck={isLoadingAccountDeletableCheck}
        accountDeletableDetails={isAccountDeletableResponse}
        deleteUserAccount={handleDeleteUserAccount}
        loading={deleteAccountMutation.isLoading}
      />
    </Box>
    <Footer />
    </>
  );
};

export default ProfileDetails;
