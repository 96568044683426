import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box, FormControl, MenuItem, Radio, Typography } from "@mui/material";

const MassSizeCard = ({
  size,
  unitsOfMeasurement,
  updateSize,
  updateUoM,
  setDefault,
}) => {
  const FormInputLabelStyles = {
    shrink: true,
  };

  return (
    <Grid container direction="row" sx={{ padding: 2 }}>
      <Grid item xs={2}>
        <Typography>Primary</Typography>{" "}
        <Radio
          checked={size.isDefaultSize}
          onChange={() => setDefault(size.type)}
        />
      </Grid>
      <Grid item xs={10} sx={{ width: "100%", marginTop: 2, marginBottom: 2 }}>
        <Box style={{ width: "100%" }}>
          <Grid
            container
            spacing={3}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              paddingLeft: 2,
            }}
          >
            <Grid item sx={{ width: "50%" }}>
              <TextField
                required
                size="small"
                fullWidth
                id="size"
                label="Quantity"
                placeholder="Quantity"
                inputProps={{
                  autocomplete: 'off',
                }}
                InputLabelProps={FormInputLabelStyles}
                value={size?.size}
                onChange={(e) => updateSize(size.type, e.target.value)}
              />
            </Grid>
            <Grid item sx={{ width: "50%" }}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  select
                  label="Unit of Measurement"
                  sx={{ width: "100%" }}
                  size="small"
                  value={size?.unitOfMeasurement?.id}
                  onChange={(e) =>
                    updateUoM(
                      size?.type,
                      unitsOfMeasurement.find((x) => x.id === e.target.value)
                    )
                  }
                >
                  {unitsOfMeasurement?.map((x) => (
                    <MenuItem
                      value={x.id}
                      key={x.id}
                      sx={{
                        "&:hover": {
                          color: (theme) => theme.palette.pure.white,
                          backgroundColor: (theme) =>
                            theme.palette.terrain[500],
                        },
                      }}
                    >
                      {x.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MassSizeCard;
