import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useEstablishmentOrganizationCount(establishmentId) {
    const queryReturnValues = useQuery(
        ["menus", { establishmentId }],
        inventoryClient.getEstablishmentOrganizationCount,
        {
            staleTime: 1000 * 60 * 10
        }
    );

   return queryReturnValues;
}

export default useEstablishmentOrganizationCount;