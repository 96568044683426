import React, { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { Box, Collapse, Grid, IconButton, Typography } from "@mui/material";

import { cloneDeep } from "lodash";
import SizePurchaseDetailsTable from "./SizePurchaseDetailsTable";
import inventoryClient from "../../../../clients/inventoryClient";
import Store from "../../../../Store/Store";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

const PurchaseDetailsCard = ({
  product,
  vendors,
  updateProductCallback,
  triggerError,
  updateExpandedParent,
  expenseGroups,
  openExpenseGroupEdit,
  openExpenseSubgroupEdit,
  refetchCurrentPageProducts,
  subscriptionData,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [productCopy, setProductCopy] = useState({});
  const { establishmentid } = useParams();
  const store = useContext(Store);

  useEffect(() => {
    setProductCopy(product);
    if (product.expanded === undefined) {
      setExpanded(true);
    } else {
      setExpanded(product.expanded);
    }
  }, [product]);

  const handleExpandClick = () => {
    updateExpandedParent(product.id, !expanded);
    setExpanded(!expanded);
  };

  const handleProductUpdate = (format) => {
    const copyUpdate = cloneDeep(productCopy);

    copyUpdate.productFormats = copyUpdate.productFormats.map((x) => {
      if (x.id === format.id) {
        return format;
      }

      return x;
    });
    setProductCopy(copyUpdate);
    updateProductCallback(copyUpdate);
  };

  const deletePurchaseUnit = async (purchaseUnitId, formatId) => {
    const deleteResponse = await inventoryClient.deletePurchaseUnit({
      establishmentId: store.currentEstablishment?.id,
      purchaseUnitId: purchaseUnitId,
    });

    if (deleteResponse.error) {
      console.log(deleteResponse.message);
      triggerError();
      return;
    }

    const newState = cloneDeep(productCopy);

    newState.productFormats = newState.productFormats.map((x) => {
      if (x.id === formatId) {
        x.purchaseDetails = x.purchaseDetails.filter(
          (pu) => pu.id !== purchaseUnitId
        );
      }

      return x;
    });
    updateProductCallback(newState);
  };

  const addPurchaseDetailsMutation = useMutation(inventoryClient.addPurchaseDetails, {
    onSuccess: (response, variables) => {
      const newState = cloneDeep(productCopy);
      newState.productFormats = newState.productFormats.map((x) => {
      if (x.id === variables.productFormatId) {
        x.purchaseDetails = [
          ...x.purchaseDetails,
          response.purchaseDetails,
        ];
      }

      return x;
    });
    updateProductCallback(newState);
    },
    onError: (err) => {
      console.log(err);
      triggerError();
    }
  })

  const handleAddPurchaseDetails = async (formatId) => {
    addPurchaseDetailsMutation.mutate({
      productFormatId: formatId,
      establishmentId: establishmentid
  });
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          width: "100%",
          padding: 0,
          borderRadius: "8px",
          marginTop: 4,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
        }}
      >
        <CardContent sx={{ width: "100%", height: "60px", padding: 0 }}>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "60px",
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <Grid item xs={8}>
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "60px",
                    width: "44px",
                    borderColor: (theme) => theme.palette.terrain[400],
                  }}
                >
                  {!expanded ? (
                    <IconButton
                      variant="outlined"
                      sx={{
                        margin: 2,
                        width: "32px",
                        height: "32px",
                      }}
                      onClick={handleExpandClick}
                    >
                      <KeyboardArrowDownOutlinedIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      variant="outlined"
                      sx={{
                        margin: 2,
                        width: "32px",
                        height: "32px",
                      }}
                      onClick={handleExpandClick}
                    >
                      <ExpandLessOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
                <Grid item sx={{ marginLeft: 2 }}>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2,
                    }}
                  >
                    <Typography variant="body2" onClick={handleExpandClick}>
                      {product ? product.name : "Product Name"}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.primary[800] }}
                      >
                        {product.productFamily &&
                          product.productFamily.id !== -1 &&
                          product.productFamily.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: (theme) => theme.palette.terrain[400],
                          marginLeft: 1,
                          marginRight: 1,
                        }}
                      >
                        {product.category && product.category.id !== -1 && "/"}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.primary[800] }}
                      >
                        {product.category &&
                          product.category.id !== -1 &&
                          product.category.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: (theme) => theme.palette.terrain[400],
                          marginLeft: 1,
                          marginRight: 1,
                        }}
                      >
                        {product.subcategory &&
                          product.subcategory.id !== -1 &&
                          " / "}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.primary[800] }}
                      >
                        {product.subcategory &&
                          product.subcategory.id !== -1 &&
                          product.subcategory.name}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Collapse in={expanded} sx={{ marginBottom: expanded ? -6 : 0 }}>
          <CardContent
            sx={{
              backgroundColor: (theme) => theme.palette.terrain[50],
              padding: 0,
            }}
          >
            {productCopy.productFormats?.map((x) => (
              <SizePurchaseDetailsTable
                key={x.id}
                format={x}
                vendors={vendors}
                updateProductCallback={handleProductUpdate}
                addPurchaseDetails={handleAddPurchaseDetails}
                triggerError={triggerError}
                expenseGroups={expenseGroups}
                openExpenseGroupEdit={openExpenseGroupEdit}
                openExpenseSubgroupEdit={openExpenseSubgroupEdit}
                deletePurchaseUnit={deletePurchaseUnit}
                isAddingPurchaseUnit={addPurchaseDetailsMutation?.isLoading}
                refetchCurrentPageProducts={refetchCurrentPageProducts}
                subscriptionData={subscriptionData}
              />
            ))}
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default PurchaseDetailsCard;
