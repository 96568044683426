import React from "react";
import { useState } from "react";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";

const FormInputLabelStyles = {
  shrink: true,
};

const ComposeMessage = ({ establishmentId, handleSendMessage }) => {
  const [messageSubject, setMessageSubject] = useState("");
  const [messageBody, setMessageBody] = useState("");

  const clearTextField = () => {
    setMessageSubject("");
    setMessageBody("");
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const title = messageSubject;
        const body = messageBody;
        handleSendMessage({ title, body, establishmentId });
        clearTextField();
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          direction: "column",
        }}
      >
        <Grid item xs={12} sx={{ marginBottom: 6 }}>
          <Typography variant="h4"> Compose New Message</Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: 4 }}>
          <TextField
            id="subject"
            size="small"
            label="Subject"
            fullWidth
            placeholder="Add a Subject..."
            required
            variant="outlined"
            value={messageSubject}
            onChange={(e) => setMessageSubject(e.target.value)}
            InputLabelProps={FormInputLabelStyles}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="body"
            size="small"
            label="Message"
            fullWidth
            placeholder="Enter message here..."
            variant="outlined"
            required
            InputLabelProps={FormInputLabelStyles}
            value={messageBody}
            onChange={(e) => setMessageBody(e.target.value)}
            multiline
            minRows={24}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", marginTop: 8 }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              borderRadius: 2,
              padding: "8px 20px",
            }}
          >
            <SendIcon style={{ fontSize: "14px" }} />
            <Typography variant="smallButton" sx={{ marginLeft: "5px" }}>
              Send Message
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ComposeMessage;
