import React, { useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const DeleteExpenseSubgroupModal = ({
  handleDelete,
  isOpen,
  expenseSubgroupName,
  toggleModalOpen,
}) => {
  const [loading, toggleLoading] = useState(false);

  const closeModal = () => {
    toggleModalOpen(!isOpen);
    toggleLoading(false);
  };

  const handleDeleteCallback = async () => {
    toggleLoading(true);
    await handleDelete();
    toggleLoading(false);
    closeModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <DeleteOutlineOutlinedIcon sx={{ fontSize: "60px" }} />
              </Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h2">Delete Subgroup</Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  Are you sure you want to delete &quot;{expenseSubgroupName}&quot;?
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "center", marginTop: 1 }}
                >
                  By selecting yes, you will lose all information associated with this subgroup. Any products and invoices that reference it will have the reference removed.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{ borderRadius: 2 }}
                  variant="outlined"
                  onClick={() => {
                    toggleModalOpen();
                  }}
                >
                  <Typography variant="smallButton">No, Cancel</Typography>
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ borderRadius: 2, marginLeft: 2 }}
                  onClick={handleDeleteCallback}
                >
                  <Typography variant="smallButton">Yes, Delete</Typography>
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default DeleteExpenseSubgroupModal;
