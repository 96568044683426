import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { Box, Chip, Grid, Menu, MenuItem, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TaggingMenu from "../../../components/TaggingMenu";
import inventoryClient from "../../../clients/inventoryClient";
import utilFunctions from "../../../utilities/utilFunctions";

const SectionListItem = ({
  product,
  productFormat,
  areaId,
  tags,
  areaName,
  triggerAreaRefetch,
  establishmentId,
  deleteAreaItem,
}) => {
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [sectionAnchorEl, setSectionAnchorEl] = useState(null);
  const [chipSelected, setChipSelected] = useState(false);
  const optionsOpen = Boolean(optionsAnchorEl);
  const sectionOpen = Boolean(sectionAnchorEl);

  const handleOptionsClick = (event) => {
    setOptionsAnchorEl(event.currentTarget);
  };
  const handleCloseOptions = () => {
    setOptionsAnchorEl(null);
  };

  const handleSectionClick = (event) => {
    setSectionAnchorEl(event.currentTarget);
    setChipSelected(!chipSelected);
  };
  const handleCloseSection = () => {
    setSectionAnchorEl(null);
    setChipSelected(false);
  };

  const handleDeleteAreaItem = () => {
    handleCloseOptions();
    deleteAreaItem(productFormat);
  }

  const handleTaggingSubmit = async (tagsToAdd, tagsToRemove) => {
    await inventoryClient.multiEditProductSectionTags(
      establishmentId,
      tagsToAdd,
      tagsToRemove,
      productFormat.id
    );
    triggerAreaRefetch();
    handleCloseSection();
  };

  const sizeDisplay = utilFunctions.extractFormatSizingName(productFormat);

  return (
    <>
      <ListItem
        disableGutters
        sx={{
          backgroundColor: (theme) => theme.palette.pure.white,
          height: "48px",
          padding: 2,
        }}
        secondaryAction={
          <IconButton onClick={handleOptionsClick}>
            <MoreHorizIcon />
          </IconButton>
        }
      >
        {/* <Grid container sx={{ border: "1px solid blue" }}> */}
        <Grid
          container
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <Grid container direction="row">
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography variant="body1">{product.name}</Typography>
                </Box>
                <Box>
                  <Typography variant="overline">{sizeDisplay}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sx={{ marginRight: 12 }}>
            <Chip
              label={
                <Typography
                  variant="body2"
                  sx={{
                    color: chipSelected
                      ? (theme) => theme.palette.pure.white
                      : (theme) => theme.palette.primary[800],
                  }}
                >
                  {productFormat.tags?.filter((x) => x.areaId === areaId).length}{" "}
                  Sections
                </Typography>
              }
              clickable
              onClick={handleSectionClick}
              sx={{
                height: "24px",
                width: "87px",
                backgroundColor: chipSelected
                  ? (theme) => theme.palette.terrain[900]
                  : (theme) => theme.palette.terrain[300],
              }}
            />
          </Grid>
        </Grid>
        {/* </Grid> */}
      </ListItem>
      <Divider sx={{ color: (theme) => theme.palette.terrain[100] }} />
      <Menu
        anchorEl={optionsAnchorEl}
        open={optionsOpen}
        onClose={handleCloseOptions}
      >
        <MenuItem onClick={handleDeleteAreaItem} sx={{ margin: "5px" }}>
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <TaggingMenu
        anchorEl={sectionAnchorEl}
        open={sectionOpen}
        cancel={handleCloseSection}
        allTags={tags}
        emptyOptionsText={`No Sections have been added to ${areaName}`}
        header={"Product Sections"}
        subHeader={product.name}
        preSelectedTags={productFormat.tags}
        saveCallback={handleTaggingSubmit}
        submitText={"Save"}
        loading={false}
        searchPromptText={"Search for sections..."}
        helpText={
          "Assign specific sections to this product to help create subgroups for stock input."
        }
      />
    </>
  );
};

export default SectionListItem;
