import React, { useState, useRef } from "react";
import { Input, Box, Typography, CircularProgress } from "@mui/material";
import heic2any from "heic2any";
import ImageOptionsMenu from "./components/ImageOptionsMenu";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import DeleteInvoiceAttachmentModal from "../Modals/DeleteInvoiceAttachmentModal";

const verifyImageFileType = (file) => {
  if (!file) return;
  if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
    throw "Please select a valid image file type";
  }
};

const AttachmentControl = ({ invoice, attachmentControlCallback, deleteMutation, approvedInvoice }) => {
  const fileRef = useRef();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);
  const [deleteModalOpen, toggleDeleteModalOpen] = useState(false);

  const handleClick = () => {
    fileRef.current.click();
    setMenuAnchorEl(null);
    setError(false);
  };

  const handleDeleteClick = (attachment) => {
    setAttachmentToDelete(attachment);
    handleToggleDeleteModal();
  }

  const verifyAndConvertImage = async (file) => {
    verifyImageFileType(file);
    if (
      file.type.startsWith("image/heic") ||
      file.type.startsWith("image/heif")
    ) {
      return await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.5,
      });
    } else {
      return file;
    }
  };

  const handleChange = async (event) => {
    try {
      let chosenFile = event.target.files[0];
      setLoading(true);
      if (chosenFile) {
        chosenFile = await verifyAndConvertImage(chosenFile);
        await attachmentControlCallback(chosenFile);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  const handleMenuOpen = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleToggleDeleteModal = () => {
    toggleDeleteModalOpen(!deleteModalOpen);
  };

  const handleDeleteInvoiceAttachment = async () => {
    const payload = {
      establishmentId: attachmentToDelete.establishmentId,
      invoiceId: attachmentToDelete.invoiceId,
      invoiceAttachmentId: attachmentToDelete.id
    }
    await deleteMutation.mutateAsync(payload);
  };

  const handleView = (url) => {
    window.open(url, "_blank");
    setMenuAnchorEl(null);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
    <Box>
      <Input
        type="file"
        sx={{ display: "none" }}
        inputRef={fileRef}
        onChange={handleChange}
      />
      {invoice?.invoiceAttachments.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "170px",
            height: "40px",
            backgroundColor: (theme) => theme.palette.terrain[200],
            borderRadius: 2,
            boxShadow: "none",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.terrain[100],
            },
          }}
          aria-label="Upload"
          onClick={handleMenuOpen}
        >
          <CheckOutlinedIcon
            sx={{
              fontSize: 20,
              color: (theme) => theme.palette.success[600],
              marginRight: 1,
            }}
          />
          <Typography
            variant="largeButton"
            sx={{ color: (theme) => theme.palette.success[600] }}
          >
            {invoice?.invoiceAttachments?.length} Attachments
          </Typography>
          <ExpandMoreOutlinedIcon
            sx={{
              fontSize: 20,
              color: (theme) => theme.palette.success[600],
              marginLeft: 1,
            }}
          />
        </Box>
      ) : approvedInvoice ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "170px",
            height: "40px",
            backgroundColor: (theme) => theme.palette.terrain[200],
            borderRadius: 2,
            boxShadow: "none",
          }}
        >
          <Typography
            variant="largeButton"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            No Attachments
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "170px",
            height: "40px",
            backgroundColor: (theme) => theme.palette.terrain[200],
            borderRadius: 2,
            boxShadow: "none",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.terrain[100],
            },
          }}
          aria-label="Upload"
          onClick={handleClick}
        >
          <AddOutlinedIcon
            sx={{ fontSize: 20, color: (theme) => theme.palette.primary[800] }}
          />
          <Typography
            variant="largeButton"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            Add Attachment
          </Typography>
        </Box>
      )}
      {error && (
        <Typography
          variant="body1"
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          {" "}
          {error}{" "}
        </Typography>
      )}
      <ImageOptionsMenu
        anchorEl={menuAnchorEl}
        closeMenu={() => setMenuAnchorEl(null)}
        addCallback={handleClick}
        viewCallback={handleView}
        attachments={invoice?.invoiceAttachments}
        deleteCallback={handleDeleteClick}
        approvedInvoice={approvedInvoice}
      />
    </Box>
    <DeleteInvoiceAttachmentModal
    isOpen={deleteModalOpen}
    toggleModalOpen={handleToggleDeleteModal}
    handleDelete={handleDeleteInvoiceAttachment}
    />
    </>
  );
};

AttachmentControl.propTypes = {};

export default AttachmentControl;
