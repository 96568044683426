import React from "react";
import InteractiveDisplay from "./components/InteractiveDisplay";
import ImageControlModal from "../Modals/ImageControlModal";

const ImageControl = ({
  imageSrc,
  imageControlCallback,
  size,
  tableView,
  isImageControlOpen,
  toggleIsImageControlOpen,
  isLoading,
}) => {

  const handleOpenModal = () => {
    toggleIsImageControlOpen(true);
  }

  const handleCloseModal = () => {
    toggleIsImageControlOpen(false);
  }

  const handleCompleteUpdate = (result) => {
    imageControlCallback(result);
  }

  return (
    <>
      <InteractiveDisplay size={size} tableView={tableView} handleClick={handleOpenModal} imageSrc={imageSrc} />
      <ImageControlModal isOpen={isImageControlOpen} closeModal={handleCloseModal} handleUpdate={handleCompleteUpdate} imageSrc={imageSrc} isLoading={isLoading} />
    </>
  );
};

ImageControl.propTypes = {};

export default ImageControl;
