import React, { useEffect, useState } from "react";
import LiquorOutlinedIcon from "@mui/icons-material/LiquorOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Button,
  Typography,
  Modal,
  Grid,
  IconButton,
  TextField,
  Card,
  CardContent,
  Box,
  //CircularProgress,
} from "@mui/material";
import utilFunctions from "../../../utilities/utilFunctions";
import CreateAutoComplete from "../../CreateAutoComplete";
import { modalBoxStyle } from "../styles";
import { cloneDeep } from "lodash";
import SpecSpinner from "../../SpecSpinner";

const ReassignProductFamilyModal = ({
  isOpen,
  toggleModalOpen,
  product,
  productFamilies,
  createNewFamily,
  createNewCategory,
  createNewSubcategory,
  productFamilyMap,
  categoryMap,
  subcategoryMap,
  handleProductUpdate,
}) => {
  const [editingName, toggleEditingName] = useState(false);
  const [isLoading, toggleIsLoading] = useState(false);
  const [productCopy, setProductCopy] = useState({});
  const [isNameError, toggleIsNameError] = useState(false);
  const [isFamilyError, toggleIsFamilyError] = useState(false);

  const handleCreateNewFamily = async (newVal) => {
    toggleIsLoading(true);
    const newCat = await createNewFamily(newVal.name);
    handleSelectFamily(newCat);
    toggleIsLoading(false);
  };

  const handleCreateCategory = async (newVal) => {
    toggleIsLoading(true);
    const newCat = await createNewCategory(
      newVal.name,
      productCopy.productFamily.id
    );
    handleSelectFamily(
      productFamilies.find((f) => f.id === productCopy.productFamily.id)
    );
    handleSelectCategory(newCat);
    toggleIsLoading(false);
  };

  const handleCreateSubcategory = async (newVal) => {
    toggleIsLoading(true);
    const newCat = await createNewSubcategory(
      newVal.name,
      productCopy.category.id,
      productCopy.productFamilyId
    );
    handleSelectCategory(
      productFamilies
        .find((f) => f.id === productCopy.productFamily.id)
        .categories.find((c) => c.id === productCopy.category.id)
    );
    handleSelectSubcategory(newCat);
    toggleIsLoading(false);
  };

  const handleSelectFamily = (val) => {
    let newState = cloneDeep(productCopy);
    newState.productFamily = val;
    newState.category = null;
    newState.subcategory = null;

    setProductCopy(newState);
  };

  const handleSelectCategory = (val) => {
    let newState = cloneDeep(productCopy);
    newState.category = val;
    newState.subcategory = null;

    setProductCopy(newState);
  };

  const handleSelectSubcategory = (val) => {
    let newState = cloneDeep(productCopy);
    newState.subcategory = val;
    setProductCopy(newState);
  };

  const handleFamilyClear = () => {
    let newState = cloneDeep(productCopy);
    newState.productFamily = null;
    newState.subcategory = null;
    newState.category = null;

    setProductCopy(newState);
  };

  const handleCategoryClear = () => {
    let newState = cloneDeep(productCopy);
    newState.subcategory = null;
    newState.category = null;

    setProductCopy(newState);
  };

  const handleSubcategoryClear = () => {
    let newState = cloneDeep(productCopy);
    newState.subcategory = null;
    setProductCopy(newState);
  };

  const handleUpdateName = (e) => {
    let newState = cloneDeep(productCopy);
    newState.name = e.target.value;
    setProductCopy(newState);
  };

  useEffect(() => {
    setProductCopy(product);
  }, [product, toggleModalOpen]);

  const closeModal = () => {
    toggleIsNameError(false);
    toggleIsFamilyError(false);
    toggleEditingName(false);
    toggleModalOpen();
  };

  const handleSubmit = async () => {
    toggleIsFamilyError(false);
    toggleIsNameError(false);
    let isError = false;
    if (!productCopy.name) {
      isError = true;
      toggleIsNameError(true);
    }

    if (!productCopy?.productFamily) {
      isError = true;
      toggleIsFamilyError(true);
    }

    if (isError) {
      return;
    }

    toggleEditingName(false);
    toggleIsLoading(true);
    await handleProductUpdate("name-family-multi", productCopy);
    toggleIsLoading(false);
    closeModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        <Grid>
          <Typography variant="h4">Edit Product</Typography>
          <Card
            elevation={0}
            sx={{
              width: "100%",
              padding: 0,
              borderRadius: "8px",
              marginTop: 4,
              border: "1px solid",
              borderColor: (theme) => theme.palette.terrain["300"],
            }}
          >
            <CardContent sx={{ width: "100%", height: "108px", padding: 2 }}>
              <SpecSpinner open={isLoading} />
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: 2,
                }}
              >
                <Grid
                  item
                  xs={10}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: "100%",
                      }}
                    >
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {editingName ? (
                          <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            value={productCopy?.name}
                            onChange={handleUpdateName}
                            error={isNameError}
                            onKeyDown={utilFunctions.blurInput}
                            sx={{
                              width: "100%",
                              backgroundColor: (theme) =>
                                theme.palette.pure.white,
                              "& legend": { display: "none" },
                              borderRadius: 1,
                              marginBottom: 2,
                              marginTop: 1,
                              input: {
                                backgroundColor: (theme) =>
                                  theme.palette.pure.white,
                                borderRadius: 1,
                              },
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              maxWidth: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Typography
                              // sx={{ marginBottom: 4 }}
                              variant="h4"
                              noWrap
                            >
                              {productCopy.name
                                ? productCopy.name
                                : "Product Name"}
                            </Typography>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {!editingName && (
                    <Grid item sx={{ marginLeft: 2 }}>
                      <IconButton
                        sx={{ margin: 2, width: "40px", height: "30px" }}
                        onClick={() => toggleEditingName(true)}
                      >
                        <EditOutlinedIcon sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <Grid item xs={4}>
                    <CreateAutoComplete
                      options={productFamilies}
                      getOptionLabel={(option) => option.name}
                      value={
                        productFamilyMap[productCopy?.productFamily?.id] || {
                          id: -1,
                          name: "",
                        }
                      }
                      handleSelect={handleSelectFamily}
                      handleNew={handleCreateNewFamily}
                      handleClear={handleFamilyClear}
                      label="Product Family"
                      modalHeader="Create New Product Family"
                      modalText="Are you sure you want to create a new Product Family? All Product Families can be edited in the Product Library."
                      modalIcon={
                        <LiquorOutlinedIcon sx={{ fontSize: "80px" }} />
                      }
                      error={
                        !productFamilyMap[productCopy?.productFamily?.id] ||
                        isFamilyError
                      }
                      useCreateModal
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CreateAutoComplete
                      options={
                        productFamilies.find(
                          (x) => x.id === productCopy?.productFamily?.id
                        )?.categories || []
                      }
                      getOptionLabel={(option) => option.name}
                      value={
                        categoryMap[productCopy?.category?.id] || {
                          id: -1,
                          name: "",
                        }
                      }
                      handleSelect={handleSelectCategory}
                      handleNew={handleCreateCategory}
                      handleClear={handleCategoryClear}
                      label="Category"
                      modalHeader="Create New Product Category"
                      modalText="Are you sure you want to create a new Product Category? All Product Categories can be edited in the Product Library."
                      modalIcon={
                        <CategoryOutlinedIcon sx={{ fontSize: "80px" }} />
                      }
                      useCreateModal
                      disabled={!productCopy?.productFamily?.id}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CreateAutoComplete
                      options={
                        productFamilies
                          .find((x) => x.id === productCopy?.productFamily?.id)
                          ?.categories?.find(
                            (x) => x.id === productCopy?.category?.id
                          )?.subcategories || []
                      }
                      getOptionLabel={(option) => option.name}
                      value={
                        subcategoryMap[productCopy?.subcategory?.id] || {
                          id: -1,
                          name: "",
                        }
                      }
                      handleSelect={handleSelectSubcategory}
                      handleNew={handleCreateSubcategory}
                      handleClear={handleSubcategoryClear}
                      label="Subcategory"
                      modalHeader="Create New Product Subcategory"
                      modalText="Are you sure you want to create a new Product Subcategory? All Product Subcategories can be edited in the Product Library."
                      modalIcon={
                        <MenuOpenOutlinedIcon sx={{ fontSize: "80px" }} />
                      }
                      useCreateModal
                      disabled={!productCopy?.category?.id}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid container direction={"row"} sx={{ marginTop: 2 }}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={closeModal}
                sx={{
                  marginLeft: "1rem",
                  borderRadius: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 6,
                  paddingRight: 6,
                  width: "90%",
                }}
              >
                <Typography variant="smallButton">Cancel</Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                disabled={
                  (!productCopy?.productFamily || !productCopy?.name) ||
                  isLoading
                }
                onClick={handleSubmit}
                sx={{
                  marginLeft: "1rem",
                  borderRadius: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 6,
                  paddingRight: 6,
                  width: "90%",
                }}
              >
                <Typography variant="smallButton">Save</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ReassignProductFamilyModal;
