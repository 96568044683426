import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useEstablishmentRecipes(establishmentId) {
    const queryReturnValues = useQuery(
        ["recipes", { establishmentId }],
        inventoryClient.getEstablishmentRecipes,
        {
            staleTime: 1000 * 60 * 10
        }
    );

   return queryReturnValues;
}

export default useEstablishmentRecipes;