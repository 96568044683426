import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import DescriptionToolTip from "../../../../../components/DescriptionToolTip";

const FilterBy = ({
  expenseGroups,
  isUsingExpenseSubgroupFilter,
  toggleIsUsingExpenseSubgroupFilter,
  expenseSubgroupFilters,
  handleGroupAddClick,
  handleGroupRemoveClick,
  handleSubgroupAddClick,
  handleSubgroupRemoveClick,
  handleClearSubgroupFilters,
}) => {
  const handleToggleUsingFilter = () => {
    toggleIsUsingExpenseSubgroupFilter(!isUsingExpenseSubgroupFilter);
  };

  const handleSelectChange = (_, newValue, reason, details) => {
    if (details?.option?.type === "group") {
      reason === "removeOption"
        ? handleGroupRemoveClick(newValue, details.option)
        : handleGroupAddClick(newValue, details.option);
    } else if (reason === "clear") {
      handleClearSubgroupFilters();
    } else {
      reason === "removeOption"
        ? handleSubgroupRemoveClick(newValue, details.option)
        : handleSubgroupAddClick(newValue, details.option);
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox
          sx={{ marginLeft: -3 }}
          checked={isUsingExpenseSubgroupFilter}
          onChange={handleToggleUsingFilter}
        />
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, marginRight: 1 }}
        >
          Filter by Expense Subgroup
        </Typography>
        <DescriptionToolTip
          header="Filter by Expense Subgroup"
          description={
            <Box component="div">
              <Box sx={{ marginTop: 1 }}>
                Filter invoice line items by their expense subgroup.
              </Box>
            </Box>
          }
        />
      </Box>
      {isUsingExpenseSubgroupFilter ? (
        <Box sx={{ marginTop: 2 }}>
          {/* <Button variant="contained" sx={{ marginRight: 1, borderRadius: 2 }}>
          Product Library
        </Button> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "72px",
              width: "35%",
              borderRadius: 2,
              backgroundColor: (theme) => theme.palette.terrain[200],
              border: "1px solid",
              borderColor: (theme) => theme.palette.terrain[300],
              padding: 2,
              marginTop: 2,
            }}
          >
            <Autocomplete
              multiple
              fullWidth
              options={expenseGroups}
              getOptionLabel={(option) => option.id}
              disableCloseOnSelect
              renderTags={() => null}
              value={expenseSubgroupFilters}
              onChange={handleSelectChange}
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
                "& .MuiAutocomplete-option": {
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              }}
              renderOption={(props, option) => (
                <ListItem
                  key={option.id}
                  id={option.id}
                  {...props}
                  sx={
                    option.type === "group"
                      ? {
                          borderLeft: "6px solid",
                          borderLeftColor: "transparent",
                          "&:hover": {
                            color: (theme) => theme.palette.pure.white,
                          },
                        }
                      : {
                          backgroundColor: (theme) => theme.palette.terrain[50],
                          borderLeft: "6px solid",
                          borderLeftColor: (theme) =>
                            theme.palette.primary[300],
                          marginLeft: 3,
                          padding: 0,
                          "&:hover": {
                            color: (theme) => theme.palette.pure.white,
                          },
                        }
                  }
                >
                  <Checkbox
                    sx={{
                      color: (theme) => theme.palette.primary[800],
                      marginLeft: 0,
                    }}
                    checked={expenseSubgroupFilters.some(
                      (item) => item.id === option.id
                    )}
                    onChange={() => {}}
                  />
                  <ListItemText primary={option.name} />
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={
                    expenseSubgroupFilters?.length > 0
                      ? `${
                          expenseSubgroupFilters.filter(
                            (x) => x.type === "subgroup"
                          ).length
                        } Expense Subgroup(s) Selected`
                      : "No Expense Subgroups Selected"
                  }
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                    style: { cursor: "pointer", height: "8px" },
                  }} // Disable typing
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    width: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    cursor: "pointer",
                  }}
                />
              )}
            />
          </Box>
          {isUsingExpenseSubgroupFilter ? (
            <Box sx={{ marginTop: 4, marginBottom: 2 }}>
              {expenseSubgroupFilters
                .filter((x) => x.type === "subgroup")
                .map((group) => (
                  <Chip
                    key={group.id}
                    label={group.name}
                    onDelete={() => {
                      // we are sadly mimicing the autocomplete here, so we need to wrap the group in the option property
                      handleSelectChange(
                        null,
                        expenseSubgroupFilters.filter(
                          (item) => item.id !== group.id
                        ),
                        "removeOption",
                        { option: group }
                      );
                    }}
                    sx={{ marginRight: 1, marginBottom: 1 }}
                  />
                ))}
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default FilterBy;
