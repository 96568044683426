import { Grid, ListItem } from "@mui/material";
import React, { useState } from "react";

const KegMeasurementCard = ({ selectedStyle, defaultStyle, data }) => {
  const [selected, toggleSelected] = useState(false);

  return (
    <ListItem
      sx={selected ? selectedStyle : defaultStyle}
      onClick={() => toggleSelected(!selected)}
    >
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={10}>
          {data.name}
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {data.number}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default KegMeasurementCard;
