import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useMasterUnitOfMeasurements() {
  const queryReturnValues = useQuery(
    ["master-uoms"],
    inventoryClient.getMasterUnitOfMeasurements,
    {
      staleTime: 1000 * 60,
    }
  );

  return queryReturnValues;
}

export default useMasterUnitOfMeasurements;
