import { TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
  fontWeight: 400,
  borderBottom: "none",
};

const AdditionalInfoRow = ({ additionalInformation }) => {
  return (
    <TableRow sx={{ height: 24 }}>
      <TableCell sx={[tableCellStyle, { width: "400px" }]}>
        <Typography variant="body1">{additionalInformation}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default AdditionalInfoRow;
