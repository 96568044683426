import React, { useState } from "react";
import { modalBoxStyle } from "../styles";
import { CircularProgress, Box, Modal } from "@mui/material";
import inventoryClient from "../../../clients/inventoryClient";
import CreateSectionForm from "./components/CreateSectionForm";
import ProductFormatMultiSelect from "../../ProductFormatMultiSelect";

const CreateSectionModal = ({
  isOpen,
  toggleModalOpen,
  establishmentId,
  areaId,
  productFormats,
  productMap,
  successCallback,
}) => {
  const [newSection, setNewSection] = useState(undefined);
  const [loading, toggleLoading] = useState(false);

  const closeModal = () => {
    setNewSection(undefined);
    toggleModalOpen();
  };

  const handleCreateSection = async (name) => {
    toggleLoading(true);
    const response = await inventoryClient.createNewAreaSection(
      establishmentId,
      areaId,
      name
    );
    setNewSection(response.section);
    toggleLoading(false);
  };

  const handleAddToSection = async (selectedProducts) => {
    if (selectedProducts.length === 0) {
      setNewSection(undefined);
      successCallback();
    } else {
      toggleLoading(true);
      await inventoryClient.tagProductsWithSection(
        establishmentId,
        newSection.id,
        selectedProducts
      );
      toggleLoading(false);
      setNewSection(undefined);
      successCallback();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[modalBoxStyle]}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : newSection !== undefined ? (
          <ProductFormatMultiSelect
            productFormatsList={productFormats}
            productMap={productMap}
            completeText={"Add to Section"}
            emptyOptionsCTAText={"No products are in this Area"}
            shouldFilterOptions={false}
            loadingInventory={false}
            instructionText={"Choose Products in this Section"}
            bulkActionCallback={handleAddToSection}
          />
        ) : (
          <CreateSectionForm
            closeModal={closeModal}
            submitFormCallback={handleCreateSection}
          />
        )}
      </Box>
    </Modal>
  );
};

export default CreateSectionModal;
