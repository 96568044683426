import React from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import { useParams } from "react-router-dom";
import ProductCard from "./components/ProductCard";

const parsDummyData = [
  {
    productName: "Teremana",
    productFormat: "750 mL",
    purchaseUnit: "case",
    price: "$28.00",
  },
  {
    productName: "Buffalo Trace",
    productFormat: "750 mL",
    purchaseUnit: "case",
    price: "$32.00",
  },
  {
    productName: "Laphroig 10 Year really really really really long name",
    productFormat: "750 mL",
    purchaseUnit: "case",
    price: "$48.00",
  },
];

const MissingParLevelsModal = ({
  isOpen,
  toggleModalOpen,
  buttonText,
  headerText,
  loading,
}) => {
  const queryClient = useQueryClient();
  const { establishmentid } = useParams();

  // useEffect(() => {
  //   setGroupsCopy([...expenseGroups]);
  // }, [isOpen]);

  const updateExpenseGroupsMutation = useMutation(
    inventoryClient.batchUpdateExpenseGroups,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "expense-groups",
            {
              establishmentId: establishmentid,
            },
          ],
        });
        queryClient.refetchQueries({
          queryKey: [
            "expense-groups",
            {
              establishmentId: establishmentid,
            },
          ],
        });
        closeModal();
      },
    }
  );

  const handleSave = () => {
    const payload = {
      establishmentId: establishmentid,
    };

    updateExpenseGroupsMutation.mutate(payload);
  };

  // const updateExistingGroupItem = (id, name) => {
  //   const updatedExisting = groupsCopy.map((x) => {
  //     if (x.id === id) {
  //       return Object.assign({}, x, { name: name });
  //     } else {
  //       return x;
  //     }
  //   });

  //   setGroupsCopy(updatedExisting);
  // };

  // const updateNewGroupItem = (id, name) => {
  //   const updatedNew = newGroups.map((x) => {
  //     if (x.tempId === id) {
  //       return Object.assign({}, x, { name: name });
  //     } else {
  //       return x;
  //     }
  //   });

  //   setNewGroups(updatedNew);
  // };

  // const addNewGroup = () => {
  //   const newList = newGroups.map((x) => x);
  //   newList.push({ isNew: true, name: "", tempId: guidGenerator() });
  //   setNewGroups(newList);
  // };

  const closeModal = () => {
    toggleModalOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[modalBoxStyle, { maxHeight: "600px" }]}>
        {loading || updateExpenseGroupsMutation.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <form>
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="h2">{headerText}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={closeModal}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Box sx={{ marginTop: 4 }}>
                <Typography variant="body1">
                  The following products are missing par level inputs on the
                  Product Formats associated in this count.
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: 4,
                  width: "100%",
                  // border: "1px solid",
                  // borderColor: (theme) => theme.palette.terrain[300],
                  // borderRadius: 2,
                  maxHeight: "400px",
                  overflow: "scroll",
                }}
              >
                {parsDummyData.map((x) => (
                  <ProductCard
                    key={x.productName}
                    id={x.productName}
                    productName={x.productName}
                    productFormat={x.productFormat}
                    purchaseUnit={x.purchaseUnit}
                    price={x.price}
                    // handleUpdate={updateExistingGroupItem}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                  onClick={handleSave}
                >
                  <Typography variant="largeButton">{buttonText}</Typography>
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default MissingParLevelsModal;
