import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import AdditionalOptions from "./AdditionalOptions";
import SortBy from "./SortBy";
import ChooseStockCounts from "./ChooseStockCounts";
import GroupBy from "./GroupBy";
import ReportingFooter from "../../ReportingFooter";
import {
  basicInventoryReportOrderByOptions,
  basicInventoryReportTypes,
} from "../../../../../clients/inventoryObjects";
import { useMutation } from "react-query";
import inventoryClient from "../../../../../clients/inventoryClient";
import utilFunctions from "../../../../../utilities/utilFunctions";
import InsufficientReportDataModal from "../../../../../components/Modals/InsufficientReportDataModal";

const groupByOptions = [
  { key: "Product", value: basicInventoryReportTypes.product },
  { key: "Product Format", value: basicInventoryReportTypes.productFormat },
  { key: "Vendor", value: basicInventoryReportTypes.vendors },
  { key: "Family", value: basicInventoryReportTypes.families },
  { key: "Category", value: basicInventoryReportTypes.categories },
  { key: "Subcategory", value: basicInventoryReportTypes.subcategories },
  { key: "Expense Group", value: basicInventoryReportTypes.expenseGroups },
  {
    key: "Expense Subgroup",
    value: basicInventoryReportTypes.expenseSubgroups,
  },
  { key: "Area", value: basicInventoryReportTypes.areas },
  { key: "Product per Area", value: basicInventoryReportTypes.productPerArea },
  { key: "Family Per Area", value: basicInventoryReportTypes.familyPerArea },
];

const sortByOptions = [
  {
    key: "Value On Hands",
    value: basicInventoryReportOrderByOptions.valueOnHands,
  },
  { key: "Unit Count", value: basicInventoryReportOrderByOptions.unitAmount },
];

const BasicInventory = ({
  establishmentId,
  pastStockCounts,
  isLoadingPastStockCounts,
  setPage,
  setAlertSuccess,
  setErrorMessage,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage
}) => {
  const [countForReport, setCountForReport] = useState(null);
  const [groupByChoice, setGroupByChoice] = useState(0);
  const [selectedSortBy, setSelectedSortBy] = useState(
    basicInventoryReportOrderByOptions.valueOnHands
  );
  const [isEmptyResultModalOpen, toggleIsEmptyResultModalOpen] = useState(false);
  const [includeSumRow, toggleIncludeSumRow] = useState(false);

  const generateInventoryReportMutation = useMutation(
    inventoryClient.generateStockCountReport,
    {
      onSuccess: (response) => {
        if (response.status === 204) {
          toggleIsEmptyResultModalOpen(true);
        } else {
          utilFunctions.downloadFile(
            response?.data?.report?.stockCountReportMetadata?.s3PresignedUrl
          );
          setAlertSuccess(
            "Report generated successfully, your download should have started."
          );
        }
      },
      onError: () => {
        setErrorMessage("Report generation failed!");
      },
    }
  );

  const handleGenerateReport = () => {
    generateInventoryReportMutation.mutate({
      establishmentId,
      stockCountId: countForReport.id,
      reportType: 0,
      basicStockCountReportFilters: {
        orderBy: selectedSortBy,
        reportType: groupByChoice,
        includeSumRow: includeSumRow,
      },
    });
  };

  return (
    <>
      <Box>
        <Box sx={{ marginTop: 8 }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "fit-content",
                width: "70%",
                borderRadius: 2,
                backgroundColor: (theme) => theme.palette.terrain[200],
                border: "1px solid",
                borderColor: (theme) => theme.palette.terrain[300],
                padding: 2,
              }}
            >
              <Typography variant="body2">
                This report provides the unit amount and value on hands totals
                for a given stock count. These will be grouped in rows based on
                the grouping option selected.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <ChooseStockCounts
            setPage={setPage}
            isLoading={isLoadingPastStockCounts}
            pastStockCounts={pastStockCounts}
            setCountForReport={setCountForReport}
            countForReport={countForReport}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <GroupBy
            groupByChoice={groupByChoice}
            setGroupByChoice={setGroupByChoice}
            choices={groupByOptions || []}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <SortBy
            sortByOptions={sortByOptions}
            selectedSortBy={selectedSortBy}
            setSelectedSortBy={setSelectedSortBy}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <AdditionalOptions includeSumRow={includeSumRow} toggleIncludeSumRow={toggleIncludeSumRow} />
        </Box>
      </Box>
      <ReportingFooter
        buttonText={"Create Report"}
        disableButton={countForReport === null || countForReport === undefined}
        submit={handleGenerateReport}
        isLoading={generateInventoryReportMutation?.isLoading}
      />
      <InsufficientReportDataModal isOpen={isEmptyResultModalOpen} toggleModalOpen={toggleIsEmptyResultModalOpen} loading={false} />
    </>
  );
};

export default BasicInventory;
