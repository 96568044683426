const createTypography = (theme) => {
  return {
    fontFamily: "Inter, Quicksand, Arial",
    h1: {
      fontWeight: 700,
      fontFamily: "Quicksand",
      fontSize: "48px",
      lineHeight: "60px",
    },
    h2: {
      fontWeight: 700,
      fontFamily: "Quicksand",
      fontSize: "28px",
      lineHeight: "35px",
    },
    h3: {
      fontWeight: 700,
      fontFamily: "Quicksand",
      fontSize: "24px",
      lineHeight: "30px",
    },
    h4: {
      fontWeight: 700,
      fontFamily: "Quicksand",
      fontSize: "20px",
      lineHeight: "25px",
    },
    h5: {
      fontWeight: 700,
      fontFamily: "Quicksand",
      fontSize: "16px",
      lineHeight: "20px",
    },
    h6: {
      fontWeight: 700,
      fontFamily: "Quicksand",
      fontSize: "12px",
      lineHeight: "20px",
    },
    largeButton: {
      fontWeight: 700,
      fontFamily: "Quicksand",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "none",
    },
    smallButton: {
      fontWeight: 700,
      fontFamily: "Quicksand",
      fontSize: "12px",
      lineHeight: "20px",
      textTransform: "none",
    },

    subtitle1: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "21.78px",
    },
    subtitle2: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "21.78px",
    },
    body1: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "14.52px",
    },
    body2: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "14.52px",
    },
    overline: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "12.1px",
      color: theme.palette.terrain["02"],
      textTransform: "none",
    },
    caption: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "8px",
      lineHeight: "9.68px",
    },
  };
};

export default createTypography;
