import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { v4 as guidGenerator } from "uuid";
import { useTheme } from "@emotion/react";

const FormInputLabelStyles = {
  shrink: true,
};

const AddProductHeader = ({ addNewProduct }) => {
  const { register, handleSubmit, reset } = useForm({ mode: "all" });
  const theme = useTheme();
  const regularScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleAddNewProduct = ({ name }) => {
    addNewProduct({
      name,
      id: guidGenerator(),
      productFormats: [
        {
          id: guidGenerator(),
          sizes: [],
          vendors: [],
          productFormatStatus: 1,
          par: { high: null, low: null },
          purchaseDetails: [
            {
              currency: { currencyType: 0, currencyUnit: 0, amount: 0 },
              isDefault: true,
            },
          ],
        },
      ],
    });
    reset();
  };

  const renderHeader = () => {
    return (
      <Grid
        container
        direction={"column"}
        sx={{
          width: regularScreen ? "620px" : "380px",
          backgroundColor: (theme) => theme.palette.pure.white,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
          borderRadius: 2,
          padding: 4,
          marginTop: 4,
        }}
      >
        <Grid item sx={{ marginTop: 4 }}>
          <Typography>
            Enter a product name into the field below to begin:
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifySelf: "center",
            marginBottom: 6,
            marginTop: 3,
          }}
        >
          <form onSubmit={handleSubmit(handleAddNewProduct)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                label={null}
                placeholder="Enter a product name"
                autoComplete="off"
                InputLabelProps={FormInputLabelStyles}
                inputProps={{
                  sx: {
                    backgroundColor: (theme) => theme.palette.pure.white,
                    width: regularScreen ? "400px" : "200px",
                    marginTop: -1,
                  },
                }}
                sx={{
                  "& legend": { display: "none" },
                  backgroundColor: (theme) => theme.palette.pure.white,
                  width: regularScreen ? "400px" : "200px",
                }}
                {...register("name", { required: true })}
              />
              <Button
                sx={{
                  width: regularScreen ? "180px" : "140px",
                  height: "34px",
                  marginLeft: 2,
                  marginBottom: 1,
                }}
                type="submit"
                variant="outlined"
              >
                <Typography variant="largeButton">Add Product</Typography>
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {renderHeader()}
    </Box>
  );
};

export default AddProductHeader;
