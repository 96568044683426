import React, { useState, useRef, useEffect } from "react";
import {
  Input,
  Box,
  Typography,
  Button,
  Alert,
  AlertTitle,
} from "@mui/material";
//import AddIcon from "@mui/icons-material/Add";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import CropEasy from "./crop/CropEasy";
import heic2any from "heic2any";

const verifyImageFileType = (file) => {
  if (!file) return;
  if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
    throw "Please select a valid image file type";
  }
};

const UploadImage = ({
  imageSrc,
  isCropping,
  toggleIsCropping,
  uploadBlobURL,
  setUploadBlobURL,
  setUploadFileBlob,
}) => {
  const fileRef = useRef();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileTooLarge, toggleFileTooLarge] = useState(false);

  const handleClick = () => {
    fileRef.current.click();
    setError(false);
  };

  const handleCropConfirm = (img, croppedPhotoURL) => {
    setUploadBlobURL(croppedPhotoURL);
    toggleIsCropping(false);
  };

  const verifyAndConvertImage = async (file) => {
    verifyImageFileType(file);
    if (
      file.type.startsWith("image/heic") ||
      file.type.startsWith("image/heif")
    ) {
      return await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.5,
      });
    } else {
      return file;
    }
  };

  const handleClearImage = () => {
    setUploadBlobURL(null);
  };

  const handleChange = async (event) => {
    try {
      toggleFileTooLarge(false);
      let chosenFile = event.target.files[0];

      if (chosenFile && chosenFile.size > 3 * 1024 * 1024) { // 3 MB in bytes
        toggleFileTooLarge(true);
        return;
      }

      setLoading(true);
      if (chosenFile) {
        chosenFile = await verifyAndConvertImage(chosenFile);
        setUploadFileBlob(chosenFile);
        setUploadBlobURL(URL.createObjectURL(chosenFile));
        toggleIsCropping(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      imageSrc?.startsWith("https://ephemeral-public.s3") ||
      imageSrc?.startsWith("https://spec-hosted.s3")
    ) {
      setUploadBlobURL(imageSrc);
    }
  }, [imageSrc]);

  console.log(uploadBlobURL);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      {fileTooLarge && (
        <Alert severity="warning">
          <AlertTitle>File Size too Large</AlertTitle>
          Images are restricted to 3mb when uploading. Please resize your image and try again, or select a smaller size image.
        </Alert>
      )}
      <Box sx={{ display: 'flex' , alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      <Input
        type="file"
        sx={{ display: "none" }}
        inputRef={fileRef}
        onChange={handleChange}
      />
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          An error ocurred while trying to upload your image.
        </Alert>
      )}
      <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
        Upload Image
      </Typography>
      {!uploadBlobURL ? (
        <Box
          onClick={handleClick}
          sx={{
            marginTop: 2,
            width: 300,
            height: 250,
            border: "3px dashed",
            borderColor: (theme) => theme.palette.terrain[300],
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
            backgroundColor: (theme) => theme.palette.terrain[50],
          }}
        >
          <Box>
            <AddPhotoAlternateOutlinedIcon
              sx={{
                fontSize: 32,
                color: (theme) => theme.palette.primary[800],
              }}
            />
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{
                color: (theme) => theme.palette.primary[800],
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Select Image to Upload
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          {isCropping ? (
            <CropEasy
              {...{
                photoURL: uploadBlobURL,
                handleCropConfirm,
                handleChange,
                loading,
                setLoading,
              }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 2,
              }}
            >
              <img
                src={uploadBlobURL}
                alt="Upload Image"
                style={{ maxHeight: 250, maWidth: 300 }}
              />
              <Button onClick={handleClearImage} sx={{ marginTop: 2 }}>
                <Typography variant="largeButton">Clear Image</Typography>
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
    </Box>
  );
};

UploadImage.propTypes = {};

export default UploadImage;
