import React, { useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import inventoryClient from "../../../clients/inventoryClient";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

const CreateProductFamilyModal = ({
  isOpen,
  toggleModalOpen
}) => {
  let { establishmentid: establishmentId } = useParams();
  const [name, setName] = useState();
  const [createCallLoading, setCreateCallLoading] = useState(false);
  const queryClient = useQueryClient();

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  }

  const handleCreateProductFamily = async () => {
    setCreateCallLoading(true);
    const response = await inventoryClient.addProductFamily(
      name,
      establishmentId
    );

    if (!response.error) {
    queryClient.invalidateQueries({
      queryKey: [
        "productFamilyGroups",
        {
          establishmentId: establishmentId,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "productFamilyGroups",
        {
          establishmentId: establishmentId,
        },
      ],
    });
    setCreateCallLoading(false);
    closeModal();
    } else {
      console.log(response.error);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {createCallLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="h2">Create Product Family</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={closeModal}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    Product families are the top tier of the hierachal structure
                    used when grouping product (e.g. Spririts).
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ marginTop: 8, width: "100%" }}>
                <TextField
                  size="small"
                  fullWidth
                  label="Name"
                  placeholder="Enter Family Name"
                  onChange={handleNameChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  disabled={name ? false : true || createCallLoading}
                  onClick={handleCreateProductFamily}
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <Typography variant="largeButton">Create Family</Typography>
                </Button>
              </Box>
            </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default CreateProductFamilyModal;
