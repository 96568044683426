import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useMasterSubmissions() {
    const queryReturnValues = useQuery(
        ["master-submissions"],
        inventoryClient.adminGetMasterProductSubmissions,
    );

   return queryReturnValues;
}

export default useMasterSubmissions;