import {
  Box,
  Checkbox,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const TagItem = ({
  tag,
  isEditing,
  selectedTagFilters,
  handleSettingSelectedTagFilters,
  tagFiltersCopy,
  handleTagClick,
  handleEditTagClick,
}) => {
  const [isTagSelected, setIsTagSelected] = useState(false);

  const isSelected = () => {
    const currentTag = tagFiltersCopy.find((x) => x === tag.id);
    setIsTagSelected(currentTag !== undefined);
  };

  const handleCheckboxClick = () => {
    handleTagClick(tag);
  };

  const handleEditClick = () => {
    handleEditTagClick(tag);
  };

  useEffect(() => {
    handleSettingSelectedTagFilters(selectedTagFilters);
  }, [selectedTagFilters]);

  useEffect(() => {
    isSelected();
  }, [tag, tagFiltersCopy]);

  return (
    <>
      {isEditing ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "58px",
            width: "97%",
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.terrain[50],
            margin: 2,
            padding: 5,
          }}
        >
          <ListItemText sx={{ marginLeft: -2 }}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography variant="body2" fontSize="14px" noWrap>
                {tag.name}
              </Typography>
            </div>
          </ListItemText>
          <Box>
            <Button
              variant="text"
              sx={{
                color: (theme) => theme.palette.primary[800],
                marginLeft: -2,
                "&:hover": {
                  background: "none",
                },
              }}
              onClick={handleEditClick}
            >
              <Typography variant="largeButton" sx={{ fontSize: "13px" }}>
                Edit
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <ListItemButton
          disableRipple
          disableTouchRipple
          onClick={handleCheckboxClick}
          sx={{
            paddingLeft: 3,
            margin: 0,
            padding: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "58px",
              width: "100%",
              borderBottom: "1px solid",
              borderBottomColor: (theme) => theme.palette.terrain[300],
              padding: 1,
            }}
          >
            <ListItemIcon>
              <Checkbox
                id={tag.id}
                checked={isTagSelected}
                onChange={handleCheckboxClick}
                sx={{ color: (theme) => theme.palette.primary[800] }}
              />
            </ListItemIcon>
            <ListItemText sx={{ marginLeft: -2 }}>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography variant="body1" noWrap>
                  {tag.name}
                </Typography>
              </div>
            </ListItemText>
          </Box>
        </ListItemButton>
      )}
    </>
  );
};

export default TagItem;
