import React from "react";
import { Box } from "@mui/material";
import inventoryObjects from "../../clients/inventoryObjects";

const OrderStatusChip = ({ status }) => {
  if (!status) {
    return null;
  }
  const productStatusText = () => {
    if (status === inventoryObjects.OrderStatuses.ordered) {
      return "Placed";
    }
    if (status === inventoryObjects.OrderStatuses.preview) {
      return "Pending";
    }
    if (status === inventoryObjects.OrderStatuses.received) {
      return "Received";
    }
    if (status === inventoryObjects.OrderStatuses.partiallyReceived) {
      return "Partially";
    }
    if (status === inventoryObjects.OrderStatuses.cancelled) {
      return "Cancelled";
    }
  };

  const productStatusStyle = () => {
    if (status === inventoryObjects.OrderStatuses.ordered) {
      return {
        backgroundColor: (theme) => theme.palette.error[200],
        color: (theme) => theme.palette.pure.black,
        fontWeight: 700,
        height: "24px",
        width: "104px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.black,
        },
      };
    }
    if (status === inventoryObjects.OrderStatuses.preview) {
      return {
        backgroundColor: (theme) => theme.palette.terrain[400],
        color: (theme) => theme.palette.pure.black,
        fontWeight: 700,
        height: "24px",
        width: "104px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.black,
        },
      };
    }
    if (status === inventoryObjects.OrderStatuses.received) {
      return {
        backgroundColor: (theme) => theme.palette.success[600],
        color: (theme) => theme.palette.pure.white,
        fontWeight: 700,
        height: "24px",
        width: "104px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.white,
        },
      };
    }
    if (status === inventoryObjects.OrderStatuses.partiallyReceived) {
      return {
        backgroundColor: (theme) => theme.palette.success[200],
        color: (theme) => theme.palette.pure.black,
        fontWeight: 700,
        height: "24px",
        width: "104px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.black,
        },
      };
    }
    if (status === inventoryObjects.OrderStatuses.cancelled) {
      return {
        backgroundColor: (theme) => theme.palette.error[900],
        color: (theme) => theme.palette.pure.white,
        fontWeight: 700,
        height: "24px",
        width: "104px",
        borderRadius: 100,
        borderBottom: "none",
        "& .MuiSvgIcon-root": {
          color: (theme) => theme.palette.pure.white,
        },
      };
    }
  };

  return (
    <Box
      sx={[
        productStatusStyle,
        {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      ]}
    >
      {productStatusText()}
    </Box>
  );
};

export default OrderStatusChip;
