import React, { useContext, useEffect, useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
  Divider,
  List,
  MenuItem,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KegMeasurementCard from "./components/KegMeasurementCard";
import Store from "../../../Store/Store";
import inventoryClient from "../../../clients/inventoryClient";
import { useMutation, useQueryClient } from "react-query";
import useEstablishmentUoMs from "../../../hooks/useEstablishmentUoMs";
//import { useMutation } from "react-query";

const CreateKegSizeModal = ({
  isOpen,
  toggleModalOpen,
  buttonText,
  headerText,
  loading,
  keg
}) => {
  const [kegCopy, setKegCopy] = useState();
  const [uomOptions, setUomOptions] = useState();
  const store = useContext(Store);
  const queryClient = useQueryClient();
  const { data: uomData } = useEstablishmentUoMs(store.currentEstablishment?.id);
  const sizingData = [
    { name: "Gallons", number: kegCopy?.kegUnitQuantity && kegCopy?.name ? Math.max(kegCopy.toLiterConversionFactor * kegCopy.kegUnitQuantity / 3.78541).toFixed(2) : 0},
    { name: "Litres", number: kegCopy?.kegUnitQuantity && kegCopy?.name ? Math.max(kegCopy.toLiterConversionFactor * kegCopy.kegUnitQuantity / 1).toFixed(2) : 0 },
    { name: "Pints", number: kegCopy?.kegUnitQuantity && kegCopy?.name ? Math.max(kegCopy.toLiterConversionFactor * kegCopy.kegUnitQuantity / 0.473176).toFixed(2) : 0 },
    { name: "Fluid Ounces", number: kegCopy?.kegUnitQuantity && kegCopy?.name ? Math.max(kegCopy.toLiterConversionFactor * kegCopy.kegUnitQuantity / 0.0295735).toFixed(2) : 0 }
  ];

  const getVolumetricUoms = () => {
   if(uomData != null){
     const volumetricUOMS = uomData.unitsOfMeasurement?.filter((x) => !x.isKegSize && x.type === 0);
     setUomOptions(volumetricUOMS);
   }
  }

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const selectedStyle = {
    backgroundColor: (theme) => theme.palette.primary[50],
    height: "40px",
    borderRadius: 2,
    marginBottom: 2,
    cursor: "pointer",
  };

  const defaultStyle = {
    backgroundColor: (theme) => theme.palette.terrain[200],
    height: "40px",
    borderRadius: 2,
    marginBottom: 2,
    cursor: "pointer",
  };

  const handleNameChange = (e) => {
    const newData = {...kegCopy};
    if(e.target.id === "kegName"){
      newData.kegName = e.target.value
    }
    if(e.target.id === "kegAbbreviation"){
      newData.kegAbbreviation = e.target.value
    }
    setKegCopy(newData);
  }

  const handleQuantityChange = (e) => {
    const newData = {...kegCopy};
    newData.kegUnitQuantity = e.target.value
    setKegCopy(newData);
  }

  const handleUOMChange = (e) => {
    const newData = {...kegCopy};
    newData.abbreviation = e.target.value.abbreviation
    newData.name = e.target.value.name;
    newData.toLiterConversionFactor = e.target.value.toLiterConversionFactor;
    setKegCopy(newData);
  }

  const editKegMutation = useMutation(inventoryClient.patchKegSize, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "kegSizes",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "kegSizes",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
      closeModal();
  },
}); 

const addKegMutation = useMutation(inventoryClient.addKegSize, {
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: [
        "kegSizes",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "kegSizes",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
    closeModal();
},
});


  const handleEdit = (e) => {
    e.preventDefault();
    let payload = {
      establishmentId: store.currentEstablishment?.id,
      id: kegCopy.id
    };

    payload["AbbreviationUpdate"] = { value: kegCopy.abbreviation };
    payload["NameUpdate"] = { value: kegCopy.name };
    payload["KegNameUpdate"] = { value: kegCopy.kegName };
    payload["KegAbbreviationUpdate"] = { value: kegCopy.kegAbbreviation };
    payload["KegUnitQuantityUpdate"] = { value: kegCopy.kegUnitQuantity };
    
    editKegMutation.mutate(payload);
  } 

  const handleCreate = (e) => {
    e.preventDefault();
    let payload = {
      establishmentId: store.currentEstablishment?.id,
      abbreviation: kegCopy.abbreviation,
      name: kegCopy.name,
      kegName: kegCopy.kegName,
      kegAbbreviation: kegCopy.kegAbbreviation,
      kegUnitQuantity: kegCopy.kegUnitQuantity,
    };

    addKegMutation.mutate(payload);
  }

  useEffect(() => {
    getVolumetricUoms();
    if (keg !== null){
      setKegCopy(keg);
    }
  },[])

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <form>
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="h2">{headerText}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={closeModal}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Box
                sx={{
                  marginTop: 8,
                  width: "100%",
                }}
              >
                <Box>
                  <Typography variant="h5">Keg Name</Typography>
                </Box>
                <Box sx={{ marginTop: 4 }}>
                  <TextField
                    required
                    size="small"
                    fullWidth
                    id="kegName"
                    onChange={handleNameChange}
                    value={kegCopy && kegCopy.kegName}
                    placeholder="Name"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      "& .MuiOutlinedInput-root": {
                        background: "#fff",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                  <Typography variant="h5">Keg Abbreviation</Typography>
                  </Box>
                  <TextField
                    required
                    size="small"
                    fullWidth
                    id="kegAbbreviation"
                    onChange={handleNameChange}
                    value={kegCopy?.kegAbbreviation}
                    placeholder="Abbreviation"
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      "& .MuiOutlinedInput-root": {
                        background: "#fff",
                      },
                    }}
                  />
              </Box>
              <Divider
                sx={{
                  width: "100%",
                  bgcolor: (theme) => theme.palette.pure.black,
                  marginTop: 6,
                }}
              />
              <Box sx={{ marginTop: 6, width: "100%" }}>
                <Typography variant="h5">Sizing Details</Typography>
                <Grid container spacing={2} sx={{ marginTop: 4 }}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      size="small"
                      fullWidth
                      type="number"
                      id="amount"
                      onChange={handleQuantityChange}
                      label="Quantity"
                      InputLabelProps={{ shrink: true }}
                      placeholder="0"
                      value={kegCopy && kegCopy.kegUnitQuantity}
                    />
                  </Grid>
                  <Grid item xs={6}>
                  <TextField
                  select
                  required
                  InputLabelProps={{ shrink: true }}
                  label="Unit of Measurement"
                  onChange={handleUOMChange}
                  value={kegCopy && uomOptions ? uomOptions.find((x) => x.abbreviation === kegCopy?.name || x.name === kegCopy?.name) : null}
                  sx={{ width: "100%" }}
                  size="small"
                >
                  {uomOptions && uomOptions.map((x) => (
                    <MenuItem key={x.abbreviation} value={x}>
                      {x.abbreviation}
                    </MenuItem>
                  ))}
                </TextField>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ marginTop: 6, width: "100%" }}>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item xs={8}>
                  <Typography variant="h5">Calculate Conversions</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                </Grid>
              </Grid>
              <List>
                {sizingData.map((x) => (
                  <KegMeasurementCard
                    key={x.name}
                    selectedStyle={selectedStyle}
                    defaultStyle={defaultStyle}
                    data={x}
                  />
                ))}
              </List>
            </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                  type="submit"
                  onClick={buttonText === "Save Edits" ? handleEdit : handleCreate}
                >
                  <Typography variant="largeButton">{buttonText}</Typography>
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default CreateKegSizeModal;
