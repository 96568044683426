import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useMutation } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import utilFunctions from "../../../utilities/utilFunctions";
import Store from "../../../Store/Store";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import OrderLineItemLinkButton from "./OrderLineItemLinkButton";
import ReconciledIndicator from "../../../components/ReconciledIndicator";
import DeleteInvoiceLineItemModal from "../../../components/Modals/DeleteInvoiceLineItemModal";
import DescriptionToolTip from "../../../components/DescriptionToolTip";

const tableCellStyle = {
  height: "60px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const deletedTableCellStyle = {
  height: "60px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.terrain[400],
};

const ProductLineItemRow = ({
  lineItem,
  productOptions,
  updateLineItemCache,
  toggleExpenseGroupModalOpen,
  orderColorMap,
  orderForFilter,
  lineItemLinkSelectCallback,
  expenseGroups,
  openSubgoupEditModal,
  invalidLineItems,
}) => {
  const [lineItemCopy, setLineItemCopy] = useState({});
  const [costDisplay, setCostDisplay] = useState(0);
  const [deleteLineItemModal, setDeleteLineItemModal] = useState(false);
  const store = useContext(Store);
  const [reconciled, setReconciled] = useState(false);
  const [validationFailures, setValidationFailures] = useState([]);
  const { locale, currency, currencySymbol } = useVendorCurrency(
    store.user?.country
  );

  useEffect(() => {
    setLineItemCopy(lineItem);
    setCostDisplay(
      utilFunctions.convertCentsToLargerFormatCurrency(
        lineItem?.cost?.amount || 0,
        locale,
        currency
      )
    );

    if (invalidLineItems[lineItem?.id]) {
      setReconciled(false);
      setValidationFailures(invalidLineItems[lineItem?.id]?.reasons)
    } else {
      setReconciled(true);
      setValidationFailures([]);
    }
  }, [lineItem, invalidLineItems]);

  const toggleDeleteLineItemModal = () => {
    setDeleteLineItemModal(!deleteLineItemModal);
  };

  const lineItemMutation = useMutation(inventoryClient.patchInvoiceLineItem, {
    onSuccess: (result) => {
      setLineItemCopy(result.lineItem);
      updateLineItemCache();
      setCostDisplay(
        utilFunctions.convertCentsToLargerFormatCurrency(
          result.lineItem.cost.amount,
          locale,
          currency
        )
      );
    },
  });

  const deleteLineItemMutation = useMutation(
    inventoryClient.deleteInvoiceInvoiceLineItem,
    {
      onSuccess: () => {
        updateLineItemCache();
      },
    }
  );

  const handleDelete = () => {
    deleteLineItemMutation.mutate({
      establishmentId: store.currentEstablishment?.id,
      invoiceId: lineItem.invoiceId,
      lineItemId: lineItem.id,
    });
  };

  const handleUpdateLineItem = (property, value) => {
    if (
      property === "productFormatId" &&
      value === lineItemCopy.productFormatId
    )
      return;

    const payload = {
      establishmentId: lineItem.establishmentId,
      invoiceId: lineItem.invoiceId,
      lineItemId: lineItem.id,
    };
    payload[`${property}Update`] = { value: value };
    lineItemMutation.mutate(payload);
  };

  const getOptionLabel = (option) => {
    const sizeInfo = utilFunctions.extractFormatSizingName(option);
    return `${option.productName} ${sizeInfo} `;
  };

  const optionEqualityEval = (o, v) => {
    if (!o || !v) {
      return false;
    }

    return o.id === v.id;
  };

  const updateLineItemCopy = (property, value) => {
    const lineItemUpdates = Object.assign({}, lineItemCopy);
    lineItemUpdates[property] = value;
    setLineItemCopy(lineItemUpdates);
  };

  const handleOnBlur = (property) => {
    const payload = {
      establishmentId: lineItem.establishmentId,
      invoiceId: lineItem.invoiceId,
      lineItemId: lineItem.id,
    };
    payload[`${property}Update`] = { value: lineItemCopy[property] };
    lineItemMutation.mutate(payload);
  };

  const product = productOptions.find(
    (x) => x.id === lineItemCopy?.productFormatId
  );

  if (orderForFilter !== undefined) {
    if (orderForFilter === -1 && lineItem.orderLinkId !== null) {
      return null;
    }

    if (orderForFilter !== lineItem.orderLinkId) {
      return null;
    }
  }

  const calculateUnitCost = () => {
    if (lineItem.amount === 0) {
      return 0;
    }

    if (lineItem.cost?.amount === 0) {
      return 0;
    }

    return lineItem.cost?.amount / lineItem.amount;
  };

  const extractExpenseGroupName = () => {
    const group = expenseGroups.find((x) => x.id === lineItem.expenseGroupId);

    return group?.name || "-";
  };

  const extractExpenseSubgroupName = () => {
    const subgroup = expenseGroups
      .find((x) => x.id === lineItem.expenseGroupId)
      ?.expenseSubgroups?.find((x) => x.id === lineItem.expenseSubgroupId);

    return subgroup?.name || "-";
  };

  const handleExpenseGroupUpdate = (e) => {
    handleUpdateLineItem("expenseGroupId", e.target.value);
  };

  const handleExpenseSubgroupUpdate = (e) => {
    handleUpdateLineItem("expenseSubgroupId", e.target.value);
  };

  const handleEditSubgroup = () => {
    const expenseParent = expenseGroups?.find(
      (x) => x.id === lineItemCopy.expenseGroupId
    );
    openSubgoupEditModal(expenseParent);
  };

  return (
    <>
      <TableRow>
        {lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted ? (
          <TableCell
            sx={[deletedTableCellStyle, { width: "44px", borderLeft: "none" }]}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DescriptionToolTip
                header="Read Only"
                description={
                  <Box component="div">
                    <Box sx={{ marginTop: 1 }}>
                      This item is greyed out because either the product format
                      or purchase unit was deleted. You are only able to delete this line item.
                    </Box>
                  </Box>
                }
              />
            </Box>
          </TableCell>
        ) : (
          <TableCell
            sx={[
              lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted
                ? deletedTableCellStyle
                : tableCellStyle,
              { width: "44px", borderLeft: "none" },
            ]}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReconciledIndicator reconciled={reconciled} validationFailures={validationFailures}/>
            </Box>
          </TableCell>
        )}
        <TableCell
          sx={[
            lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted
              ? deletedTableCellStyle
              : tableCellStyle,
            { width: "56px", borderLeft: "none" },
          ]}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <OrderLineItemLinkButton
              lineItem={lineItem}
              orderColorMap={orderColorMap}
              lineItemLinkSelectCallback={
                lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted
                  ? null
                  : lineItemLinkSelectCallback
              }
            />
          </Box>
        </TableCell>
        {/* Need to fix Product Name rendering in Select component */}
        {lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted ? (
          <TableCell
            sx={[deletedTableCellStyle, { width: "324px", padding: 4 }]}
          >
            <Box>
              <Typography variant="body2">
                {lineItem.productName} {lineItem.unitSize}{" "}
                {lineItem.unitSizeAbbreviation || ""}
              </Typography>
            </Box>
          </TableCell>
        ) : (
          <TableCell sx={[tableCellStyle, { width: "324px", padding: 3 }]}>
            <Autocomplete
              size="small"
              getOptionLabel={getOptionLabel}
              onChange={(e, v) => handleUpdateLineItem("productFormatId", v.id)}
              options={productOptions}
              isOptionEqualToValue={optionEqualityEval}
              disabled={lineItemMutation.isLoading}
              renderInput={(params) => {
                return <TextField {...params} label={"Search for product"} />;
              }}
              value={
                lineItemCopy?.productFormatId
                  ? productOptions.find(
                      (x) => x.id === lineItemCopy?.productFormatId
                    )
                  : null
              }
            />
          </TableCell>
        )}
        {lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted ? (
          <TableCell
            sx={[deletedTableCellStyle, { width: "324px", padding: 3 }]}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {lineItem.expenseGroupId ? extractExpenseGroupName() : "-"}
            </Box>
          </TableCell>
        ) : (
          <TableCell sx={[tableCellStyle, { width: "140px", padding: 3 }]}>
            <Select
              fullWidth
              variant="standard"
              disableUnderline
              size="small"
              select
              label="Expense Group"
              autoComplete="off"
              InputLabelProps={{ shrink: "true" }}
              value={lineItemCopy.expenseGroupId || ""}
              sx={{ height: "34px" }}
              disabled={!lineItemCopy?.productId || lineItemMutation.isLoading}
              onChange={handleExpenseGroupUpdate}
            >
              {expenseGroups?.map((x) => (
                <MenuItem value={x.id} key={x.id}>
                  {x.name}
                </MenuItem>
              ))}
              <MenuItem>
                <Button
                  variant="text"
                  onClick={() => toggleExpenseGroupModalOpen(true)}
                >
                  <Typography variant="smallButton">+ Create New</Typography>
                </Button>
              </MenuItem>
            </Select>
          </TableCell>
        )}
        {lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted ? (
          <TableCell
            sx={[deletedTableCellStyle, { width: "140px", padding: 3 }]}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                {lineItem.expenseSubgroupId
                  ? extractExpenseSubgroupName()
                  : "-"}
              </Box>
            </Box>
          </TableCell>
        ) : (
          <TableCell sx={[tableCellStyle, { width: "140px", padding: 3 }]}>
            <Select
              fullWidth
              variant="standard"
              disableUnderline
              size="small"
              select
              label="Subgroup"
              autoComplete="off"
              InputLabelProps={{ shrink: "true" }}
              value={lineItemCopy.expenseSubgroupId || ""}
              sx={{ height: "34px" }}
              disabled={
                !lineItemCopy?.expenseGroupId || lineItemMutation.isLoading
              }
              onChange={handleExpenseSubgroupUpdate}
            >
              {expenseGroups
                ?.find((x) => x.id === lineItemCopy.expenseGroupId)
                ?.expenseSubgroups?.map((x) => (
                  <MenuItem value={x.id} key={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              <MenuItem>
                <Button variant="text" onClick={handleEditSubgroup}>
                  <Typography variant="smallButton">+ Create New</Typography>
                </Button>
              </MenuItem>
            </Select>
          </TableCell>
        )}

        {lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted ? (
          <TableCell
            sx={[deletedTableCellStyle, { width: "120px", padding: 3 }]}
          >
            <Box sx={{ display: "flex" }}>{lineItem.purchaseUnitName}</Box>
          </TableCell>
        ) : (
          <TableCell sx={[tableCellStyle, { width: "120px", padding: 3 }]}>
            <Select
              fullWidth
              variant="standard"
              disableUnderline
              size="small"
              select
              label="Purchase Unit"
              autoComplete="off"
              InputLabelProps={{ shrink: "true" }}
              value={lineItemCopy.purchaseUnitId || ""}
              sx={{ height: "34px" }}
              disabled={!lineItemCopy?.productId || lineItemMutation.isLoading}
              onChange={(e) =>
                handleUpdateLineItem("purchaseUnitId", e.target.value)
              }
            >
              {product?.purchaseDetails?.map((x) => (
                <MenuItem value={x.id} key={x.id}>
                  {x.name}
                </MenuItem>
              )) || <MenuItem />}
            </Select>
          </TableCell>
        )}
        {lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted ? (
          <TableCell
            sx={[deletedTableCellStyle, { width: "90px", paddingLeft: 2 }]}
          >
            <Box sx={{ display: "flex" }}>{lineItem.amount}</Box>
          </TableCell>
        ) : (
          <TableCell sx={[tableCellStyle, { width: "90px" }]}>
            <TextField
              size="small"
              variant="standard"
              fullWidth
              id="amount"
              InputLabelProps={{ shrink: "true" }}
              InputProps={{ disableUnderline: "true" }}
              placeholder="Amount"
              autoComplete="off"
              disabled={!lineItemCopy?.productId || lineItemMutation.isLoading}
              value={lineItemCopy.amount}
              onChange={(e) => {
                updateLineItemCopy("amount", e.target.value);
              }}
              onFocus={(e) => e.target.select()}
              onBlur={() => handleOnBlur("amount")}
              onKeyDown={utilFunctions.blurInput}
            />
          </TableCell>
        )}
        {lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted ? (
          <TableCell
            sx={[deletedTableCellStyle, { width: "90px", paddingLeft: 4 }]}
          >
            <Box sx={{ display: "flex" }}>
              {currencySymbol}
              {utilFunctions.convertCentsToLargerFormatCurrency(
                lineItem.cost?.amount,
                locale,
                currency
              )}
            </Box>
          </TableCell>
        ) : (
          <TableCell sx={[tableCellStyle, { width: "90px" }]}>
            <TextField
              size="small"
              variant="standard"
              fullWidth
              id="name"
              autoComplete="off"
              InputProps={{
                inputMode: "numeric",
                disableUnderline: "true",
                startAdornment: (
                  <InputAdornment sx={{ paddingBottom: 0.5 }} position="start">
                    {currencySymbol}
                  </InputAdornment>
                ),
              }}
              disabled={!lineItemCopy?.productId || lineItemMutation.isLoading}
              onChange={(e) => {
                setCostDisplay(e.target.value);
                updateLineItemCopy(
                  "cost",
                  Object.assign({}, lineItemCopy.cost, {
                    amount: utilFunctions.convertCurrencyToCents(
                      e.target.value
                    ),
                  })
                );
              }}
              onFocus={(e) => e.target.select()}
              onBlur={() => handleOnBlur("cost")}
              onKeyDown={utilFunctions.blurInput}
              value={costDisplay}
            />
          </TableCell>
        )}
        {lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted ? (
          <TableCell
            sx={[
              deletedTableCellStyle,
              { width: "90px", paddingLeft: 6, paddingRight: 1 },
            ]}
          >
            <Box sx={{ display: "flex" }}>
              {currencySymbol}
              {utilFunctions.convertCentsToLargerFormatCurrency(
                calculateUnitCost(),
                locale,
                currency
              )}
            </Box>
          </TableCell>
        ) : (
          <TableCell
            sx={[
              tableCellStyle,
              { width: "90px", paddingLeft: 1, paddingRight: 1 },
            ]}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                minHeight: "24px",
                backgroundColor: (theme) => theme.palette.terrain[100],
              }}
            >
              <Typography variant="body1">
                {currencySymbol}{" "}
                {utilFunctions.convertCentsToLargerFormatCurrency(
                  calculateUnitCost() || 0,
                  locale,
                  currency
                )}
              </Typography>
            </Box>
          </TableCell>
        )}

        <TableCell
          sx={[
            lineItem.purchaseUnitDeleted || lineItem.productFormatDeleted
              ? deletedTableCellStyle
              : tableCellStyle,
            { width: "56px", borderRight: "none" },
          ]}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              variant="outlined"
              sx={{
                height: "36px",
                width: "36px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.error[500],
                borderRadius: 2,
              }}
              disabled={deleteLineItemMutation.isLoading}
              onClick={toggleDeleteLineItemModal}
            >
              <DeleteOutlineIcon sx={{ color: "red" }} />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <DeleteInvoiceLineItemModal
        deleteCallback={handleDelete}
        isOpen={deleteLineItemModal}
        toggleModalOpen={toggleDeleteLineItemModal}
      />
    </>
  );
};

export default ProductLineItemRow;
