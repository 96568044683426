import axios from "axios";
import { getAccessToken } from "./identityClient";

export const inventoryConfig = {
  baseURL: "",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type",
    Accept: "application/json",
  },
};

const inventoryClient = {
  addDistributors: async (vendors, establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      inventoryConfig.headers["Content-Type"] = "application/json";
      const response = await axios.post(
        "/vendor/collection",
        { vendors, establishmentId },
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  addVendors: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    inventoryConfig.headers["Content-Type"] = "application/json";
    const response = await axios.post(
      "/vendor/collection",
      payload,
      inventoryConfig
    );
    return response.data;
  },
  getDistributors: async (establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      const response = await axios.get(
        `/vendor/establishment/${establishmentId}`,
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  getDistributorsReactQuery: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/vendor/establishment/${establishmentId}`,
      inventoryConfig
    );
    return response.data;
  },
  updateDistributor: async (vendor) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      inventoryConfig.headers["Content-Type"] = "application/json";
      const response = await axios.put("/vendor", vendor, inventoryConfig);
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  getProductFamilies: async (establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      const response = await axios.get(
        `/category/establishment/${establishmentId}`,
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  getProductFamiliesReactQuery: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/category/establishment/${establishmentId}`,
      inventoryConfig
    );
    return response.data;
  },
  addProductFamily: async (name, establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      inventoryConfig.headers["Content-Type"] = "application/json";
      const response = await axios.post(
        "/family",
        { name, establishmentId },
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  addCategory: async (name, productFamilyId, establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      inventoryConfig.headers["Content-Type"] = "application/json";
      const response = await axios.post(
        "/category",
        { name, productFamilyId, establishmentId },
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  addSubcategory: async (name, categoryId, establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      inventoryConfig.headers["Content-Type"] = "application/json";
      const response = await axios.post(
        "/category/subcategory",
        { name, categoryId, establishmentId },
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  getAllUnitOfMeasurements: async (establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      const response = await axios.get(
        `/product/unit-of-measurement/establishment/${establishmentId}`,
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  getAllUnitOfMeasurementsReactQuery: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/product/unit-of-measurement/establishment/${establishmentId}`,
      inventoryConfig
    );
    return response.data;
  },
  addProducts: async (products, establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      inventoryConfig.headers["Content-Type"] = "application/json";
      const response = await axios.post(
        "/product",
        { products, establishmentId },
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      const errObject = ex.response;
      return {
        error: true,
        messages: errObject.data?.messages,
        status: errObject.status,
        statusText: errObject.statusText,
      };
    }
  },
  getProductInventory: async (establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      const response = await axios.get(
        `/product/establishment/${establishmentId}`,
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  getProductInventoryReactQuery: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/product/establishment/${establishmentId}`,
      inventoryConfig
    );
    return response.data;
  },
  getProductInventoryPaginated: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, page, filters, sortAscending, propertyForSort }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/product/establishment/${establishmentId}/paginated?page=${page}`,
      {
        ...filters,
        sortProperty: propertyForSort,
        sortAscending
      },
      inventoryConfig
    );
    return response.data;
  },
  searchProductInventoryPaginated: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, page, searchString, filters, sortAscending, propertyForSort }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      '/product/paginated-search',
      {
        establishmentId,
        page,
        searchString,
        filters: {
          ...filters,
          sortProperty: propertyForSort,
          sortAscending
        },
      },
      inventoryConfig
    );
    return response.data;
  },
  updateProduct: async (payload, establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      const response = await axios.patch(
        `/product`,
        { ...payload, establishmentId },
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  updateProductReactQuery: async (payload) => {
    const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      const response = await axios.patch(
        `/product`,
        payload,
        inventoryConfig
      );
      return response.data;
  },
  updateProductFormat: async (payload, establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      const response = await axios.patch(
        `/product/format`,
        { ...payload, establishmentId },
        inventoryConfig
      );
      return response.data;
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  addPurchaseDetails: async (payload) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      inventoryConfig.headers["Content-Type"] = "application/json";
      const response = await axios.post(
        "/product/purchase-details",
        payload,
        inventoryConfig
      );
      return response.data;
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  addProductFormat: async (payload) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      inventoryConfig.headers["Content-Type"] = "application/json";
      const response = await axios.post(
        "/product/format",
        payload,
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  updatePurchaseDetail: async (payload, establishmentId) => {
    try {
      const token = getAccessToken();
      inventoryConfig.headers.Authorization = token;
      const response = await axios.patch(
        `/product/purchase-details`,
        { ...payload, establishmentId },
        inventoryConfig
      );
      return { error: false, data: response.data };
    } catch (ex) {
      console.log(ex);
      return { error: true, message: ex };
    }
  },
  updatePurchaseUnit: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/product/purchase-details`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  getAreasForEstablishment: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/area/establishment/${establishmentId}`,
      inventoryConfig
    );

    return response.data;
  },
  createArea: async ({ establishmentId, name }) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/area/`,
      {
        establishmentId,
        name,
      },
      inventoryConfig
    );

    return response.data;
  },
  getAreaById: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, areaId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/area/establishment/${establishmentId}/area/${areaId}`,
      inventoryConfig
    );

    return response.data;
  },
  updateArea: async ({ payload, establishmentId }) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/area`,
      { ...payload, establishmentId },
      inventoryConfig
    );
    return response.data;
  },
  addAreaProducts: async ({ payload }) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/area/product`,
      payload,
      inventoryConfig
    );
    return response.data;
  },
  seedInventory: async (establishmentId, inventorySettings) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/account/init/${establishmentId}`,
      inventorySettings,
      inventoryConfig
    );

    return response.data;
  },
  getRateLimitOverview: async ({queryKey}) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/account/limits-overview/${establishmentId}`,
      inventoryConfig
    );

    return response.data;
  },
  generateInventoryAudit: async ({ establishmentId, stockCountType, name, templateId }) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      "/stockcount/generate",
      { establishmentId, type: stockCountType, name, templateId },
      inventoryConfig
    );

    return response.data;
  },
  getPastStockCountsForEstablishment: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, page }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/stockcount/establishment/${establishmentId}/past?page=${page}`,
      inventoryConfig
    );

    return response.data;
  },
  getPastStockCountsForEstablishmentInfiniteScroll: async (establishmentId, page) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/stockcount/establishment/${establishmentId}/past?page=${page}`,
      inventoryConfig
    );

    return response.data;
  },
  getActiveStockCountsForEstablishment: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/stockcount/establishment/${establishmentId}/active`,
      inventoryConfig
    );

    return response.data;
  },
  getStockCountTemplates: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/stockcount/establishment/${establishmentId}/templates`,
      inventoryConfig
    );

    return response.data;
  },
  createNewStockCountTemplate: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/stockcount/templates`,
      payload,
      inventoryConfig,
    );

    return response.data;
  },
  getStockCountById: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, stockCountId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/stockcount/establishment/${establishmentId}/count/${stockCountId}`,
      inventoryConfig
    );

    return response.data;
  },
  getAreaCountById: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, stockCountId, areaCountId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/stockcount/establishment/${establishmentId}/count/${stockCountId}/area/${areaCountId}`,
      inventoryConfig
    );

    return response.data;
  },
  updateCountRecord: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/stockcount/countrecord`,
      payload,
      inventoryConfig
    );
    return response.data;
  },
  patchCompletedStockCountRecord: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/stockcount/completed-record`,
      payload,
      inventoryConfig
    );
    return response.data;
  },
  addProductsToAreaCount:  async ({ establishmentId, areaCountId, productFormatIds }) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      "/stockcount/areacount/add-record",
      { establishmentId, areaCountId, productFormatIds },
      inventoryConfig
    );

    return response.data;
  }, 
  completeAreaCount: async ({ establishmentId, stockCountId, areaId, forceComplete }) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      "/stockcount/areacount/complete",
      { establishmentId, stockCountId, areaId, forceComplete },
      inventoryConfig
    );

    return response.data;
  },
  completeInventoryAudit: async ({
    establishmentId,
    stockCountId,
    forceComplete,
  }) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      "/stockcount/audit/complete",
      {
        establishmentId,
        stockCountId,
        forceCompletion: forceComplete ? true : false,
      },
      inventoryConfig
    );

    return response.data;
  },
  createNewAreaSection: async (establishmentId, areaId, sectionName) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      "/area/section",
      { establishmentId, areaId, name: sectionName },
      inventoryConfig
    );

    return response.data;
  },
  tagProductsWithSection: async (
    establishmentId,
    sectionId,
    productFormatIds
  ) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      "/area/product-section",
      { establishmentId, sectionId, productFormatIds },
      inventoryConfig
    );

    return response.data;
  },
  multiEditProductSectionTags: async (
    establishmentId,
    sectionsToAdd,
    sectionsToRemove,
    productFormatId
  ) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      "/area/product-section-multi-edit",
      { establishmentId, sectionsToAdd, sectionsToRemove, productFormatId },
      inventoryConfig
    );

    return response.data;
  },
  getEstablishmentOrderCart: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/order/establishment/${establishmentId}`,
      inventoryConfig
    );

    return response.data;
  },
  addCartItems: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post("/order/cart", payload, inventoryConfig);

    return response.data;
  },
  patchCartItem: async (establishmentId, cartItemId, unitAmountUpdate) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      "/order/cart",
      {
        establishmentId,
        cartItemId,
        unitAmountUpdate: { value: unitAmountUpdate },
      },
      inventoryConfig
    );

    return response.data;
  },
  removeCartItem: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/order/cart/establishmentId/${payload.establishmentId}/item/${payload.cartItemId}`,
      inventoryConfig
    );

    return response.data;
  },
  getCartOrderPreview: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/order/establishment/${establishmentId}/cart/review`,
      inventoryConfig
    );

    return response.data;
  },
  confirmOrders: async (establishmentId) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/order/establishment/${establishmentId}/create-orders`,
      {},
      inventoryConfig
    );

    return response.data;
  },
  getExistingOrders: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, page, vendorId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const uri = vendorId
      ? `/order/establishment/${establishmentId}/existing?page=${
          page || 1
        }&vendorId=${vendorId}`
      : `/order/establishment/${establishmentId}/existing?page=${page || 1}`;
    const response = await axios.get(uri, inventoryConfig);

    return response.data;
  },
  getOderById: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, orderId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/order/establishment/${establishmentId}/order/${orderId}`,
      inventoryConfig
    );

    return response.data;
  },
  markOrderAsPlaced: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/order/mark-ordered`,
      { establishmentId: payload.establishmentId, orderId: payload.orderId },
      inventoryConfig
    );

    return response.data;
  },
  getEstablishmentInvoices: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, page }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/invoice/establishment/${establishmentId}?page=${page || 1}`,
      inventoryConfig
    );

    return response.data;
  },
  createInvoice: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/create`,
      {
        establishmentId: payload.establishmentId,
        invoiceDate: payload.invoiceDate,
        invoiceNumber: payload.invoiceNumber,
        invoiceTotal: payload.invoiceTotal,
        vendorId: payload.vendorId,
      },
      inventoryConfig
    );

    return response.data;
  },
  getInvoiceById: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, invoiceId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/invoice/establishment/${establishmentId}/invoice/${invoiceId}`,
      inventoryConfig
    );

    return response.data;
  },
  patchInvoice: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/invoice/update`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  linkOrdersToInvoice: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/link-orders`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  linkInvoiceLineItemToOrderLineItem: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/link-line-item`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  unlinkOrderFromInvoice: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/unlink-order`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  approveInvoice: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/approve`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  unapproveInvoice: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/unapprove`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  generateInvoiceApprovalPreview: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/preview-approve`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  addProductLineItem: async (payload) => {
    const { establishmentId, invoiceId } = payload;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/establishment/${establishmentId}/invoice/${invoiceId}/product`,
      {},
      inventoryConfig
    );

    return response.data;
  },
  patchInvoiceLineItem: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/invoice/product-line-item`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  addKegTransfer: async (payload) => {
    const { establishmentId, invoiceId } = payload;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/establishment/${establishmentId}/invoice/${invoiceId}/keg-transfer`,
      {},
      inventoryConfig
    );

    return response.data;
  },
  patchKegTransfer: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/invoice/keg-transfer-line-item`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  addMiscLineItem: async (payload) => {
    const { establishmentId, invoiceId } = payload;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/establishment/${establishmentId}/invoice/${invoiceId}/misc`,
      {},
      inventoryConfig
    );

    return response.data;
  },
  patchMiscLineItem: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/invoice/misc-line-item`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  deleteInvoiceKegTransfer: async (payload) => {
    const { establishmentId, invoiceId, kegTransferId } = payload;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/invoice/establishment/${establishmentId}/invoice/${invoiceId}/keg-transfer/${kegTransferId}`,
      inventoryConfig
    );

    return response;
  },
  deleteInvoiceInvoiceLineItem: async (payload) => {
    const { establishmentId, invoiceId, lineItemId } = payload;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/invoice/establishment/${establishmentId}/invoice/${invoiceId}/product/${lineItemId}`,
      inventoryConfig
    );

    return response;
  },
  deleteInvoiceMiscItem: async (payload) => {
    const { establishmentId, invoiceId, miscLineItemId } = payload;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/invoice/establishment/${establishmentId}/invoice/${invoiceId}/misc/${miscLineItemId}`,
      inventoryConfig
    );

    return response;
  },
  reconcileInvoice: async (payload) => {
    const { establishmentId, invoiceId } = payload;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/invoice/reconcile/establishment/${establishmentId}/invoice/${invoiceId}`,
      {},
      inventoryConfig
    );

    return response.data;
  },
  getEstablishmentRecipes: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/recipe/mgmt/establishment/${establishmentId}`,
      inventoryConfig
    );

    return response.data;
  },
  getRecipeDetails: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, recipeId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/recipe/mgmt/establishment/${establishmentId}/recipe/${recipeId}`,
      inventoryConfig
    );

    return response.data;
  },
  addRecipe: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/recipe/mgmt/add`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  patchRecipe: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/recipe/mgmt/update`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  addIngredient: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/recipe/mgmt/add/ingredient`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  patchIngredient: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/recipe/mgmt/update/ingredient`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  removeIngredient: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/recipe/mgmt/establishment/${payload.establishmentId}/remove/ingredient/${payload.ingredientId}`,
      inventoryConfig
    );

    return response;
  },
  getEstablishmentMenus: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/menu/mgmt/establishment/${establishmentId}`,
      inventoryConfig
    );

    return response.data;
  },
  getMenuDetails: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId, menuId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/menu/mgmt/establishment/${establishmentId}/menu/${menuId}`,
      inventoryConfig
    );

    return response.data;
  },
  addMenu: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/menu/mgmt/add-menu`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  addMenuItem: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/menu/mgmt/add-menu-item`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  addBulkMenuItems: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/menu/mgmt/add-bulk-menu-item`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  removeMenuItem: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/menu/mgmt/establishment/${payload.establishmentId}/menu-item/${payload.menuItemId}`,
      inventoryConfig
    );

    return response;
  },
  patchMenuItem: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/menu/mgmt/menu-item/update`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  patchMenu: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/menu/mgmt/update`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  getEstablishmentRecipeTags: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/recipe/mgmt/establishment/${establishmentId}/tags`,
      inventoryConfig
    );

    return response.data;
  },
  createTag: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/recipe/mgmt/create/recipe-tag`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  addTagToRecipe: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/recipe/mgmt/recipe/add-tag`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  removeTagFromRecipe: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/recipe/mgmt/recipe/remove-tag`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  getTaggedRecipes: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { tagIds, establishmentId }] = queryKey;

    if (tagIds?.length === 0) {
      return {};
    }

    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/recipe/mgmt/tagged-recipes`,
      { tagIds, establishmentId },
      inventoryConfig
    );

    return response.data;
  },
  multiEditRecipeTags: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/recipe/mgmt/multi-edit-tags`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  getEstablishmentExpenseGroups: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/expensegroup/establishment/${establishmentId}`,
      inventoryConfig
    );

    return response.data;
  },
  batchUpdateExpenseGroups: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/expensegroup/batch-add-expense-group`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  batchUpdateExpenseSubgroups: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/expensegroup/batch-add-expense-subgroup`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  getReportingOverview: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/reporting/${establishmentId}/overview`,
      inventoryConfig
    );

    return response.data;
  },
  getRecentUpdates: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/reporting/${establishmentId}/recent-changes`,
      inventoryConfig
    );

    return response.data;
  },
  getAllRecentUpdates: async ({ establishmentId, page, tagFilter }) => {
    // eslint-disable-next-line no-unused-vars
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/reporting/${establishmentId}/all-changes?page=${page || 1}&tagFilter=${
        tagFilter || ""
      }`,
      inventoryConfig
    );

    return response.data;
  },
  searchForCountNotAddedProducts: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/stockcount/areacount/search`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  getInventorySettings: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/inventorysettings/mgmt/establishment/${establishmentId}`,
      inventoryConfig
    );

    return response.data;
    
  },
  patchInventorySettings: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/inventorysettings/mgmt/update`,
      payload,
      inventoryConfig
    );

    return response.data;
  }, 
  getEstablishmentKegSizes: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/inventorysettings/keg-sizes/${establishmentId}`,
      inventoryConfig
    );

    return response.data;
  },
  addKegSize: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.post(
      `/inventorysettings/keg-sizes/add`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  patchKegSize: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.patch(
      `/inventorysettings/keg-sizes/update`,
      payload,
      inventoryConfig
    );

    return response.data;
  },
  deleteArea: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `area/delete-area/${payload.establishmentId}/${payload.areaId}`,
      inventoryConfig
    );

    return response;
  },
  deleteInvoice: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/invoice/delete-invoice/${payload.establishmentId}/${payload.invoiceId}`,
      inventoryConfig
    );

    return response;
  },
  deleteMenu: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/menu/${payload.establishmentId}/delete-menu/${payload.menuId}`,
      inventoryConfig
    );

    return response;
  },
  deleteOrder: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/order/delete-order/${payload.establishmentId}/${payload.orderId}`,
      inventoryConfig
    );

    return response;
  },
  deleteRecipe: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/recipe/delete-recipe/${payload.establishmentId}/${payload.recipeId}`,
      inventoryConfig
    );

    return response;
  },
  deleteStockCount: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `stockcount/delete/${payload.establishmentId}/${payload.stockCountId}`,
      inventoryConfig
    );

    return response;
  },
  archiveVendor: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `/vendor/${payload.establishmentId}/archive/${payload.vendorId}`,
      inventoryConfig
    );

    return response;
  },
  deleteVendorContact: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `vendor/${payload.vendorId}/establishment/${payload.establishmentId}/contact/${payload.contactId}`,
      inventoryConfig
    )

    return response;
  },
  deleteProduct: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `product/delete-product/${payload.establishmentId}/${payload.productId}`,
      inventoryConfig
    )

    return response;
  },
  deleteProductFormat: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `product/delete-product-format/${payload.establishmentId}/${payload.productFormatId}`,
      inventoryConfig
    )

    return response;
  },
  deletePurchaseUnit: async (payload) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.delete(
      `product/delete-purchase-unit/${payload.establishmentId}/${payload.purchaseUnitId}`,
      inventoryConfig
    )

    return response;
  },
  adminGetMasterProductSubmissions: async () => {
     const token = getAccessToken();
     inventoryConfig.headers.Authorization = token;
     const response = await axios.get(
       `/admin/submissions`,
       inventoryConfig
     );
 
     return response.data;
  },
  adminGetMasterProductSubmissionSuggestions: async(submissionId) => {
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/admin/submissions/suggestions/${submissionId}`,
      inventoryConfig
    );

    return response.data;
 },
  getEstablishmentProductFamilyGroups: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    inventoryConfig.headers.Authorization = token;
    const response = await axios.get(
      `/family/establishment/${establishmentId}`,
      inventoryConfig
    );

    return response.data;
 },
 searchSpecMasterProducts:  async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/admin/master-products/search`,
    payload,
    inventoryConfig
  );

  return response.data;
},
adminApproveMasterSubmission: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/admin/submissions/approve/${payload?.submissionId}`,
    payload,
    inventoryConfig
  );

  return response.data;
},
adminDenyMasterSubmission: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/admin/submissions/deny/${payload?.submissionId}`,
    payload,
    inventoryConfig
  );

  return response.data;
},
adminMapEntryToExisting: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/admin/submissions/map`,
    payload,
    inventoryConfig
  );

  return response.data;
},
getMasterProductFamilies: async () => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.get(
    `/admin/families`,
    inventoryConfig
  );

  return response.data;
},
getMasterUnitOfMeasurements: async () => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.get(
    `/admin/unit-of-measurements`,
    inventoryConfig
  );

  return response.data;
},
patchMasterProductEntry: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.patch(
    `/admin/submissions/product-entry-update`,
    payload,
    inventoryConfig
  );

  return response.data;
},
patchMasterFormatEntry: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.patch(
    `/admin/submissions/format-entry-update`,
    payload,
    inventoryConfig
  );

  return response.data;
},
getEstablishmentOrganizationCount: async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, { establishmentId }] = queryKey;
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.get(
    `/reporting/${establishmentId}/organization-count`,
    inventoryConfig
  );

  return response.data;
},
addExpenseGroup: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/expensegroup/add-expense-group`,
    payload,
    inventoryConfig
  );

  return response.data;
},
addExpenseSubgroup: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/expensegroup/add-expense-subgroup`,
    payload,
    inventoryConfig
  );

  return response.data;
},
patchExpenseGroup: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.patch(
    `/expensegroup/update-expense-group`,
    payload,
    inventoryConfig
  );

  return response.data;
},
patchExpenseSubgroup: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.patch(
    `/expensegroup/update-expense-subgroup`,
    payload,
    inventoryConfig
  );

  return response.data;
},
patchFamily: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.put(
    `/family`,
    payload,
    inventoryConfig
  );

  return response.data;
},
patchCategory: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.put(
    `/category`,
    payload,
    inventoryConfig
  );

  return response.data;
},
patchSubcategory: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.put(
    `/category/subcategory`,
    payload,
    inventoryConfig
  );

  return response.data;
},
getProductCountByFamilyId: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.get(
    `/reporting/establishment/${payload.establishmentId}/product-family/${payload.productFamilyId}`,
    inventoryConfig
  );

  return response.data;
},
deleteFamily: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.delete(
    `/family/${payload.productFamilyId}/establishment/${payload.establishmentId}`,
    inventoryConfig
  );

  return response.data;
},
deleteCategory: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.delete(
    `/category/establishment/${payload.establishmentId}/category/${payload.categoryId}`,
    inventoryConfig
  );

  return response.data;
},
deleteSubCategory: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.delete(
    `/category/establishment/${payload.establishmentId}/subcategory/${payload.subcategoryId}`,
    inventoryConfig
  );

  return response.data;
},
deleteExpenseGroup: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.delete(
    `/expensegroup/establishment/${payload.establishmentId}/expense-group/${payload.expensegroupId}`,
    inventoryConfig
  );

  return response.data;
},
deleteExpenseSubgroup: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.delete(
    `/expensegroup/establishment/${payload.establishmentId}/expense-subgroup/${payload.expenseSubgroupId}`,
    inventoryConfig
  );

  return response.data;
},
getRecipesPaginated: async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, { establishmentId, page, filters }] = queryKey;
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/recipe/establishment/${establishmentId}/paginated?page=${page}`,
    {
      ...filters
    },
    inventoryConfig
  );
  return response.data;
},
getMenusPaginated: async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, { establishmentId, page, filters }] = queryKey;
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/menu/establishment/${establishmentId}/paginated?page=${page}`,
    {
      ...filters
    },
    inventoryConfig
  );
  return response.data;
},
searchRecipesPaginated: async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, { establishmentId, page, searchTerm, filters }] = queryKey;
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    '/recipe/paginated-search',
    {
      establishmentId,
      page,
      searchTerm,
      filters: {
        ...filters
      },
    },
    inventoryConfig
  );
  return response.data;
},
searchMenusPaginated: async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_key, { establishmentId, page, searchTerm, filters }] = queryKey;
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    '/menu/paginated-search',
    {
      establishmentId,
      page,
      searchTerm,
      filters: {
        ...filters
      },
    },
    inventoryConfig
  );
  return response.data;
},
addInvoiceAttachment: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/invoice/add-invoice-attachment`,
    payload,
    inventoryConfig
  );

  return response.data;
},
deleteInvoiceAttachment: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.delete(
    `/invoice/delete-invoice-attachment/${payload.establishmentId}/${payload.invoiceId}/${payload.invoiceAttachmentId}`,
    inventoryConfig
  );

  return response.data;
},
patchRecipeTag: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.patch(
    `/recipe/mgmt/recipe-tag/edit/`,
    payload,
    inventoryConfig
  );

  return response.data;
},
deleteRecipeTag: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.delete(
    `/recipe/delete-recipe-tag/${payload.establishmentId}/${payload.tagId}`,
    inventoryConfig
  );

  return response.data;
},
removeAreaProduct: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.delete(
    `/area/product`,
    {
      ...inventoryConfig,
      data: payload
    }
  );

  return response.data;
},
generateStockCountReport: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/reporting/generate-stock-count-report`,
    payload,
    inventoryConfig
  );

  return response;
},
generateTopOrderReport:  async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/reporting/generate-top-order-report`,
    payload,
    inventoryConfig
  );

  return response;
},
generateInvoiceReport:  async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/reporting/generate-invoice-report`,
    payload,
    inventoryConfig
  );

  return response;
},
generateInvoiceTopSpendingReport: async (payload) => {
  const token = getAccessToken();
  inventoryConfig.headers.Authorization = token;
  const response = await axios.post(
    `/reporting/top-invoice-spending-report`,
    payload,
    inventoryConfig
  );

  return response;
},
};

export default inventoryClient;
