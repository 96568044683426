import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React, { useContext } from "react";
import inventoryObjects from "../../../../clients/inventoryObjects";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import Store from "../../../../Store/Store";
import utilFunctions from "../../../../utilities/utilFunctions";
import useEstablishmentSettings from "../../../../hooks/useEstablishmentSettings";

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
  fontWeight: 400,
};

const KegRow = ({ kegTransfer }) => {
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(store.currentEstablishment?.id);
  const { currencySymbol, currency, locale } = useVendorCurrency(settings?.inventorySettings?.currency);

  const calculateUnitCost = () => {
    if (kegTransfer.amount === 0) {
      return 0;
    }

    if (kegTransfer.cost?.amount === 0) {
      return 0;
    }

    return kegTransfer.cost?.amount / kegTransfer.amount;
  }

  return (
    <TableRow sx={{ height: 24 }}>
      <TableCell sx={[tableCellStyle, { width: "500px" }]}>
        <Box>
          <Typography variant="body2">{kegTransfer.name}</Typography>
        </Box>
        <Box>
          <Typography variant="overline">{kegTransfer.type === inventoryObjects.kegTransferTypes.credit ? "return" : "deposit"}</Typography>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {kegTransfer.amount}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>{currencySymbol}{utilFunctions.convertCentsToLargerFormatCurrency(kegTransfer.cost?.amount, locale, currency)}</Box>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex" }}>{currencySymbol}{utilFunctions.convertCentsToLargerFormatCurrency(calculateUnitCost(), locale, currency)}</Box>
      </TableCell>
    </TableRow>
  );
};

export default KegRow;
