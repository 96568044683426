import { Avatar, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const SpecHeaderButton = () => {
  const [hover, toggleHover] = useState(false);
  const navigate = useNavigate();
  const { establishmentid: establishmentId } = useParams();

  const handleNavigateDashboard = () => {
    navigate(`/spec/${establishmentId}/dashboard`);
  };

  return (
    <Button
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
      sx={{
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        "&:hover": {
          background: "none",
        },
      }}
      onClick={handleNavigateDashboard}
    >
      <Avatar
        sx={{
          width: "20px",
          height: "20px",
          marginRight: 1,
          marginBottom: 0.5,
        }}
        src={require("../../../assets/img/SpecLogoStamp.png")}
      />
      <Typography
        variant="h4"
        noWrap
        component="div"
        sx={{
          color: (theme) => (hover ? theme.palette.terrain[300] : "#fff"),
          flexGrow: 1,
          fontSize: { xs: 14, md: 20 },
          // "&:hover": {
          //   color: (theme) => theme.palette.terrain[500],
          // },
        }}
      >
        Spec
      </Typography>
      <Typography
        variant="h4"
        noWrap
        component="div"
        sx={{
          color: (theme) =>
            hover ? theme.palette.terrain[300] : theme.palette.primary["main"],
          marginLeft: 1,
          flexGrow: 1,
          fontSize: { xs: 14, md: 20 },
          // "&:hover": {
          //   color: (theme) => theme.palette.terrain[500],
          // },
        }}
      >
        Dashboard
      </Typography>
    </Button>
  );
};

export default SpecHeaderButton;
