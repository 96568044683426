import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SpecBackButton from "../../../components/SpecBackButton";
import UpdatedTablePill from "./UpdatedTablePill";
import RecentlyUpdatedTable from "./RecentlyUpdatedTable";
import inventoryClient from "../../../clients/inventoryClient";
import { useParams } from "react-router-dom";

const updatedTablePillData = [
  { tagName: "Products", tag: "Product" },
  { tagName: "Recipes", tag: "Recipe" },
  { tagName: "Menus", tag: "Menu" },
  { tagName: "Vendors", tag: "Vendor" },
  { tagName: "Orders", tag: "Order" },
  { tagName: "Invoices", tag: "Invoice" },
  { tagName: "Stock Counts", tag: "Stock Count" },
];

let nextPageToLoad = 1;

const RecentlyUpdatedIndex = ({ toggleRecentlyUpdatedAll, staffData }) => {
  const { establishmentid: establishmentId } = useParams();
  const [isLoadingChanges, toggleIsLoadingChanges] = useState(false);
  const [isLoadingChangesError, toggleIsLoadingChangesError] = useState(false);
  const [changeSets, setChangeSets] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [tagFilter, setTagFilter] = useState("");

  const fetchPaginatedChangeSets = async () => {
    try {
      toggleIsLoadingChangesError(false);
      toggleIsLoadingChanges(true);
      const response = await inventoryClient.getAllRecentUpdates({
        establishmentId: establishmentId,
        page: nextPageToLoad,
        tagFilter: tagFilter,
      });
      setChangeSets([...changeSets, ...response.changeSets]);
      toggleIsLoadingChanges(false);
      setTotalPages(response.totalPages);
    } catch (e) {
      console.log(e);
      toggleIsLoadingChangesError(true);
      toggleIsLoadingChanges(false);
    }
  };

  const resetLoadedData = async () => {
    setTotalPages(1);
    setChangeSets([]);
  };

  const handleTagSelection = async (tagObject) => {
    nextPageToLoad = 1;
    await resetLoadedData();
    setTagFilter(tagFilter === tagObject.tag ? "" : tagObject.tag);
  };

  const handleLoadMore = () => {
    nextPageToLoad = nextPageToLoad + 1;
    fetchPaginatedChangeSets();
  };

  useEffect(() => {
    fetchPaginatedChangeSets();

    return () => (nextPageToLoad = 1);
  }, [tagFilter]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "2440px",
        padding: 2,
        marginTop: 6,
      }}
    >
      <Box>
        <SpecBackButton
          backText="Dashboard"
          onClick={() => toggleRecentlyUpdatedAll(false)}
        />
      </Box>
      <Box sx={{ marginTop: 6 }}>
        <Typography variant="h3">Recently Updated</Typography>
        {isLoadingChangesError && (
          <Typography
            variant="body1"
            sx={{ color: (theme) => theme.palette.error[500] }}
          />
        )}
      </Box>
      {/* Chips Section */}
      <Box sx={{ marginTop: 6 }}>
        {updatedTablePillData.map((x) => (
          <UpdatedTablePill
            key={x.tagName}
            data={x}
            currentSelected={tagFilter}
            onSelected={handleTagSelection}
          />
        ))}
      </Box>
      <Box sx={{ marginTop: 6 }}>
        {isLoadingChanges ? (
          <CircularProgress />
        ) : (
          <RecentlyUpdatedTable
            recentUpdates={changeSets}
            staffData={staffData}
          />
        )}
      </Box>
      {nextPageToLoad < totalPages ? (
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleLoadMore}
        >
          <Button variant="outlined">Show More</Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default RecentlyUpdatedIndex;
