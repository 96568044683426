import { useQuery } from "react-query";
import establishmentsClient from "../clients/establishmentsClient";

function useStaffMembers(establishmentId) {
    const queryReturnValues = useQuery(
        ["staff-members", { establishmentId }],
        establishmentsClient.getEstablishmentStaffMembers,
        {
            staleTime: 1000 * 60 * 10
        }
    );

   return queryReturnValues;
}

export default useStaffMembers;