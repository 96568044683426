import { CircularProgress, List } from "@mui/material";
import React, { useContext } from "react";
import RecipeListItem from "./RecipeListItem";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import inventoryClient from "../../../clients/inventoryClient";
import Store from "../../../Store/Store";
import CTABlock from "../../../components/CTABlock";

const RecipeList = ({
  recipes,
  recipeTags,
  isLoadingPaginatedRecipes,
  isLoadingPaginatedSearchData,
}) => {
  const { establishmentid } = useParams();
  const queryClient = useQueryClient();
  const store = useContext(Store);

  const invalidateRecipesPaginated = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "recipes-paginated",
        {
          establishmentId: establishmentid,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "recipes-paginated",
        {
          establishmentId: establishmentid,
        },
      ],
      type: "all",
    });
  };

  const deleteRecipeMutation = useMutation(inventoryClient.deleteRecipe, {
    onSuccess: (_, variables) => {
      invalidateRecipesPaginated();

      queryClient.setQueryData(
        ["recipes", { establishmentId: establishmentid }],
        (prev) => {
          const newRecipes = prev?.recipes?.filter(
            (x) => x.id !== variables.recipeId
          );

          return { recipes: newRecipes };
        }
      );
    },
  });

  const handleDeleteRecipe = (recipeId) => {
    try {
      const archivePayload = {
        establishmentId: store.currentEstablishment?.id,
        recipeId: recipeId,
      };

      deleteRecipeMutation.mutate(archivePayload);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isLoadingPaginatedRecipes || isLoadingPaginatedSearchData ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {recipes?.length > 0 ? (
            <List sx={{ width: "100%", marginTop: 4 }}>
              {recipes.map((x) => (
                <RecipeListItem
                  recipe={x}
                  key={x.id}
                  allTags={recipeTags}
                  handleDeleteRecipe={handleDeleteRecipe}
                  invalidateParentQueries={invalidateRecipesPaginated}
                />
              ))}
            </List>
          ) : (
            <CTABlock header="No Results Found" hideButton />
          )}
        </>
      )}
    </>
  );
};

export default RecipeList;
