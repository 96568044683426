import React from "react";
import { Box, Grid } from "@mui/material";
import Header from "../../components/Header/index";
import ProfileForm from "./components/ProfileForm";
import Footer from "../../components/Footer";

const Profile = () => {
  return (
    <Grid sx={{height: '100%'}} container direction={'row'}>
      <Grid item xs={12}>
      <Header />
      </Grid>
      <Grid item xs={12}>
      <Box sx={{ marginTop: 10, marginBottom: 10 }}>
        <ProfileForm />
      </Box>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default Profile;
