import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const AddMenuItemSeachBar = ({ options, onSelect }) => {
  const [inputValue, setInputValue] = useState("");

  const renderOption = (props, option) => {
    return (
      <li {...props} key={option.id || option.name} style={{ padding: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            "&:hover": {
              color: (theme) => theme.palette.pure.white,
              backgroundColor: (theme) => theme.palette.terrain[500],
            },
            padding: 2,
          }}
        >
          <Box sx={{ width: "100%", marginBottom: 0.5 }}>
            <Typography variant="body1">{option.name}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 2,
              width: "fit-content",
              height: "12px",
              borderRadius: 0.5,
              backgroundColor: (theme) =>
                option.isProduct
                  ? theme.palette.secondary[800]
                  : theme.palette.info[900],
            }}
          >
            <Typography
              variant="overline"
              sx={{
                fontWeight: 700,
                color: (theme) => theme.palette.pure.white,
                "&:hover": {
                  color: (theme) => theme.palette.pure.white,
                },
              }}
            >
              {option.isProduct ? "Product" : "Recipe"}
            </Typography>
          </Box>
        </Box>
      </li>
    );
  };

  const handleOnChange = (_, item) => {
    setInputValue("");
    onSelect(item);
  };

  return (
    <Autocomplete
      fullWidth
      size="small"
      inputValue={inputValue}
      onInputChange={(_, v) => setInputValue(v)}
      getOptionLabel={(o) => o.name || ""}
      onChange={handleOnChange}
      options={options}
      value={null}
      isOptionEqualToValue={(o, v) => {
        return o.name?.toLowerCase()?.includes(v.name?.toLowerCase());
      }}
      // filterOptions={(opts, v) => {
      //   let newOptions = opts.filter((x) => {
      //     if (
      //       x.name?.toLowerCase()?.includes(v.inputValue?.toLowerCase()) &&
      //       !previouslyAddedMap[x.id]
      //     ) {
      //       return true;
      //     }

      //     return false;
      //   });
      //   return newOptions;
      // }}
      sx={{ height: "100%" }}
      renderOption={renderOption}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="small"
            fullWidth
            id="productSearch"
            sx={{
              backgroundColor: (theme) => theme.palette.pure.white,
              width: "360px",
              borderRadius: 1,
            }}
            label={
              <>
                <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                Enter item name here...
              </>
            }
          />
        );
      }}
    />
  );
};

export default AddMenuItemSeachBar;
