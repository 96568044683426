import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { unitSizeToUomSizeTypeMap } from "../../../clients/inventoryObjects";
import EditUnitSizeRow from "./EditUnitSizeRow";

const EditMasterFormatDetailsForm = ({ format, save, cancel, masterUoms, isLoading }) => {
  const [formatData, setFormatData] = useState({});

  useEffect(() => {
    if (format !== undefined) {
      setFormatData(Object.assign({}, format));
    }
  }, [format]);

  const handleSave = () => {
    save(formatData);
  };

  const handleCancel = () => {
    cancel();
  };

  const handleSizeUpdate = (e, type) => {
    console.log(e);
    const updatedFormat = Object.assign({}, formatData);
    updatedFormat.sizes = updatedFormat?.sizes?.map((x) => {
      if (type === x.type) {
        return Object.assign({}, x, { size: e.target.value });
      }

      return x;
    });

    setFormatData(updatedFormat);
  };

  const handleUomUpdate = (e, type) => {
    const updatedFormat = Object.assign({}, formatData);
    updatedFormat.sizes = updatedFormat?.sizes?.map((x) => {
      if (type === x.type) {
        return Object.assign({}, x, {
          unitOfMeasurement: masterUoms?.find(
            (uom) => uom.id === e?.target?.value
          ),
        });
      }

      return x;
    });

    setFormatData(updatedFormat);
  };

  const handleMarkAsDefault = (type) => {
    const updatedFormat = Object.assign({}, formatData);
    updatedFormat.sizes = updatedFormat?.sizes?.map((x) => {
      if (type === x.type) {
        return Object.assign({}, x, { isDefaultSize: true });
      } else {
        return Object.assign({}, x, { isDefaultSize: false });
      }
    });

    setFormatData(updatedFormat);
  };

  return (
    <Box
      sx={{
        marginTop: 4,
        marginBottom: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {formatData?.sizes?.map((x) => (
        <EditUnitSizeRow
          masterUoms={masterUoms?.filter(
            (uom) => uom.type === unitSizeToUomSizeTypeMap[x.type]
          )}
          handleUpdateUnitSize={handleSizeUpdate}
          handleUpdateUom={handleUomUpdate}
          unitSize={x}
          key={x?.id}
          markAsDefault={handleMarkAsDefault}
        />
      ))}
      <Box>
        <Button variant="contained" disabled={isLoading} onClick={handleSave}>
          Save
        </Button>
        <Button variant="outlined" disabled={isLoading} onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default EditMasterFormatDetailsForm;
