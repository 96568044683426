import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useEstablishmentSettings(establishmentId) {
    const queryReturnValues = useQuery(
        ["settings", {establishmentId}],
        inventoryClient.getInventorySettings,
        {
          refetchOnWindowFocus: false,
          staleTime: 120 * 60 * 1000,
          cacheTime: 120 * 60 * 1000,
        }
    );

   return queryReturnValues;
}

export default useEstablishmentSettings;