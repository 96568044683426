import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Store from "../../Store/Store";
import { useQueryClient } from "react-query";

// This component checks for when react router changes locations
// and when it does, it will check whether or not any react queries
// have been marked as needing their caches busted. This helps 
// ensure we only tell react query to refetch data that may be
// expensive when needed
const LocationChangeMonitor = ({children}) => {
  const location = useLocation();
  const store = useContext(Store);
  const queryClient = useQueryClient();

  useEffect(() => {
    const invalidateQueriesQueue = store.invalidateQueriesQueue;

    if (invalidateQueriesQueue?.length > 0) {
      invalidateQueriesQueue.forEach(x => {
        queryClient.invalidateQueries(x);
        queryClient.refetchQueries(x);
    })
      store.resetInvalidateQueriesQueue();
    }
  }, [location]);

  return children;
}

export default LocationChangeMonitor;