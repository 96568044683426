import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

const ProductCard = ({ lineItem, order, isExcluded, updateExcluded }) => {
  const handleAction = () => {
    updateExcluded(isExcluded, lineItem, order);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "58px",
        borderBottom: "1px solid",
        borderColor: (theme) => theme.palette.terrain[300],
        backgroundColor: (theme) => theme.palette.terrain[50],
        padding: 2,
        cursor: "pointer",
      }}
    >
      <Box>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: 0,
          }}
        >
          <Grid item xs={2} sx={{ opacity: isExcluded ? 0.5 : 1} }>
            <Grid item>
              {lineItem.productFormat?.imageUrl ? (
                <Box
                  component="img"
                  src={lineItem.productFormat.imageUrl}
                  sx={{
                    hieght: "40px",
                    width: "40px",
                    border: (theme) =>
                      `1px solid ${theme.palette.terrain[400]}`,
                  }}
                />
              ) : (
                <ImageNotSupportedIcon
                  sx={{
                    fontSize: 24,
                    color: (theme) =>
                      theme.palette.pure.black,
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={7} sx={{ opacity: isExcluded ? 0.5 : 1}}>
            <Box>
              <div
                style={{
                  maxWidth: "140px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography
                  noWrap
                  variant="subtitle2"
                  sx={{
                    color: (theme) =>
                      theme.palette.pure.black,
                  }}
                >
                  {"x"}
                  {lineItem.totalUnitsOrdered + " "}
                  {lineItem.productName}
                </Typography>
              </div>
            </Box>
            <Box>
              <div
                style={{
                  maxWidth: "320px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography
                  variant="overline"
                  noWrap
                  sx={{
                    fontWeight: 700,
                    color: (theme) =>
                      theme.palette.pure.black,
                  }}
                >
                  {`${lineItem.unitSize} ${
                    lineItem.unitSizeAbbreviation
                  } ${lineItem.sku ? ", SKU:" : ""} ${lineItem.sku || ""}`}
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            { isExcluded ? <Typography sx={{ cursor: "pointer", color: theme =>  theme.palette.primary[800]}} variant="body1" onClick={handleAction}>+ Include</Typography> : (
              <IconButton
              sx={{ backgroundColor: "transparent" }}
              onClick={handleAction}
            >
              <CloseOutlinedIcon
                sx={{
                  fontSize: 18,
                  color: (theme) => theme.palette.pure.black,
                }}
              />{" "}
            </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProductCard;
