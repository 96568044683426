import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Store from "../../../Store/Store";
import { useMutation } from "react-query";
import { updateProfile } from "../../../clients/authClient";

const UserPreferences = () => {
  const store = useContext(Store);

  const profileMutation = useMutation(updateProfile, {
    onSuccess: (data, variables) => {
      console.log(variables);
      store.updateUserProfile(variables);
    },
  });

  const handleToggleTheme = () => {
    if (store?.user === undefined || store?.user === null) {
      return; // if theres no loaded profile then we have problems
    }

    const updatedProfile = Object.assign({}, store.user);
    let profileMeta = JSON.parse(updatedProfile?.metaData);

    if (!profileMeta) {
      profileMeta = {};
    }

    profileMeta.webThemePreference =
      profileMeta?.webThemePreference === "dark" ? "light" : "dark";

    updatedProfile.metaData = JSON.stringify(profileMeta);
    profileMutation.mutate(updatedProfile);
  };

  return (
    <>
      <Box>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={10}>
            <Typography variant="h4">Preferences</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <InfoOutlinedIcon
              sx={{
                color: (theme) => theme.palette.primary[800],
                cursor: "pointer",
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 4 }}>
          <List>
            <ListItem
              dense
              disableGutters
              sx={{
                backgroundColor: (theme) => theme.palette.pure.white,
                borderBottom: "1px solid",
                borderColor: (theme) => theme.palette.terrain[400],
                height: "50px",
              }}
            >
              <ListItemButton
                disabled={profileMutation?.isLoading}
                onClick={handleToggleTheme}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography variant="body2">
                        Current Theme:{" "}
                        {store?.themePreference !== "dark"
                          ? "Light Mode"
                          : "Dark Mode"}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          marginLeft: 2,
                          color: (theme) => theme.palette.terrain[600],
                        }}
                      >
                        Swap to{" "}
                        {store?.themePreference === "dark"
                          ? "Light Mode"
                          : "Dark Mode"}
                      </Typography>
                    </Box>
                  }
                  sx={{ marginLeft: 4 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  );
};

export default UserPreferences;
