import React, { useEffect, useState } from "react";
import { Box, List, Button, Typography, IconButton } from "@mui/material";
import TagItem from "./TagItem";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CreateTagModal from "../../CreateTagModal";
import { useParams } from "react-router-dom";
import EditTagModal from "../../EditTagModal";

const TagsList = ({
  isEditing,
  toggleIsEditing,
  recipeTags,
  selectedTagFilters,
  handleSettingSelectedTagFilters,
  tagFiltersCopy,
  handleTagClick,
  handleResetClick,
  handleCreateTagClick,
  creatingTag,
  handleCreateTagCallback,
  editingTagModal,
  handleEditingTagClick,
}) => {
  const [tagsData, setTagsData] = useState([]);
  const [selectedTagToEdit, setSelectedTagToEdit] = useState();
  let { establishmentid } = useParams();

  const handleEditTagClick = (tag) => {
    handleEditingTagClick();
    setSelectedTagToEdit(tag);
  };

  useEffect(() => {
    setTagsData(recipeTags?.tags);
  }, [recipeTags?.tags]);

  if (creatingTag) {
    return (
      <CreateTagModal
        successCallback={handleCreateTagCallback}
        establishmentId={establishmentid}
        handleCreateTagClick={handleCreateTagClick}
      />
    );
  }

  if (editingTagModal) {
    return (
      <EditTagModal
        successCallback={handleCreateTagCallback}
        establishmentId={establishmentid}
        handleEditingTagClick={handleEditingTagClick}
        tag={selectedTagToEdit}
      />
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        {isEditing ? (
          <Typography variant="h4">Manage Tags</Typography>
        ) : (
          <Button variant="text" onClick={() => toggleIsEditing(true)}>
            <Typography variant="largeButton">Manage Tags</Typography>
          </Button>
        )}
        {isEditing ? (
          <IconButton
            variant="contained"
            sx={{
              width: "40px",
              backgroundColor: (theme) => theme.palette.primary[800],
            }}
          >
            <AddOutlinedIcon
              sx={{ color: "#fff" }}
              onClick={handleCreateTagClick}
            />
          </IconButton>
        ) : (
          <Button
            variant="text"
            sx={{ marginLeft: "auto" }}
            onClick={handleResetClick}
          >
            <Typography variant="largeButton">Reset</Typography>{" "}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          marginTop: 4,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      >
        <List
          disablePadding
          sx={{
            overflow: "scroll !important",
            maxHeight: { xs: "200px", md: "300px" },
            borderColor: (theme) => theme.palette.terrain[300],
          }}
        >
          {tagsData.length > 0 &&
            tagsData.map((x) => (
              <TagItem
                key={x.id}
                tag={x}
                isEditing={isEditing}
                selectedTagFilters={selectedTagFilters}
                handleSettingSelectedTagFilters={
                  handleSettingSelectedTagFilters
                }
                tagFiltersCopy={tagFiltersCopy}
                handleTagClick={handleTagClick}
                handleEditTagClick={handleEditTagClick}
              />
            ))}
        </List>
      </Box>
    </>
  );
};

export default TagsList;
