import React from "react";

import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

const OrderDaySection = ({ updateOrderDays, distributor, modalView }) => {
  const orderDayButtonStyle = {
    border: 2,
    width: { xs: 44, md: 80 },
  };

  const OrderDaySectionStyle = modalView
    ? {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[300],
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.terrain[50],
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 2,
        paddingRight: 2,
        marginBottom: 4,
        marginTop: 0,
      }
    : {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      };

  const handleUpdateOrderDays = (event, orderDays) => {
    updateOrderDays(orderDays);
  };

  return (
    <Grid container sx={OrderDaySectionStyle}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ marginBottom: 4, marginLeft: 2 }}>
          {distributor.name} Order Days:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ToggleButtonGroup
            size="small"
            // color="secondary"
            value={distributor.validOrderDays?.map((d) => d.day)}
            onChange={handleUpdateOrderDays}
            sx={{
              marginLeft: 2,
              marginRight: 2,
              color: (theme) => theme.palette.secondary[800],
            }}
          >
            <ToggleButton variant="outlined" sx={orderDayButtonStyle} value={0}>
              <Typography variant="smallButton">Mon</Typography>
            </ToggleButton>
            <ToggleButton
              variant="contained"
              sx={orderDayButtonStyle}
              value={1}
            >
              <Typography variant="smallButton">Tues</Typography>
            </ToggleButton>
            <ToggleButton sx={orderDayButtonStyle} value={2}>
              <Typography variant="smallButton">Wed</Typography>
            </ToggleButton>
            <ToggleButton sx={orderDayButtonStyle} value={3}>
              <Typography variant="smallButton">Thurs</Typography>
            </ToggleButton>
            <ToggleButton sx={orderDayButtonStyle} value={4}>
              <Typography variant="smallButton">Fri</Typography>
            </ToggleButton>
            <ToggleButton sx={orderDayButtonStyle} value={5}>
              <Typography variant="smallButton">Sat</Typography>
            </ToggleButton>
            <ToggleButton sx={orderDayButtonStyle} value={6}>
              <Typography variant="smallButton">Sun</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderDaySection;
