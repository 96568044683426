import React, { useState, useEffect, useContext } from "react";
import Store from "../../Store/Store";
import {
  Box,
  Button,
  Typography,
  Grid,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Alert from "@mui/material/Alert";
import EditEstablishmentModal from "../../components/Modals/EditEstablishmentModal";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import UpgradeAccountModal from "../../components/Modals/UpgradeAccountModal";
import { useParams } from "react-router-dom";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import useMyMemberships from "../../hooks/useMyMemberships";
import { createStripeCheckoutSession, createStripePortalSession } from "../../clients/establishmentsClient";
import SubscriptionSuccessModal from "../../components/Modals/SubscriptionSuccessModal";

const DetailsBoxStyle = {
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[300],
  backgroundColor: (theme) => theme.palette.pure.white,
  borderRadius: 2,
  width: "100%",
};

const EstablishmentDetails = () => {
  const [establishmentCopy, updateEstablishmentCopy] = useState({});
  const [addressData, setAddress] = useState({});
  const [isEditing, toggleIsEditing] = useState(false);
  const [upgradeModalOpen, toggleUpgradeModalOpen] = useState(false);
  const [isLoading, toggleIsLoading] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoadingSession, toggleLoadingSession] = useState(false);
  const [isSuccessModalOpen, toggleIsSuccessModalOpen] = useState(false);
  const { establishmentid } = useParams();
  const { data: memberships, isLoading: isLoadingMemberships } =
    useMyMemberships();
  const { data: subscriptionState, isLoading: isLoadingSubscriptionState } =
    useEstablishmentSubscription(establishmentid);
  const store = useContext(Store);

  const handleClose = () => {
    setAlertSuccess(false);
    setErrorMessage(false);
    toggleIsEditing(false);
  };

  const handleEditEstablishmentToggle = () => {
    toggleIsEditing(!isEditing);
  };

  const handleUpgradeModalToggle = () => {
    toggleUpgradeModalOpen(!upgradeModalOpen);
  };

  const handleToggleSuccesModal = () => {
    toggleIsSuccessModalOpen(!isSuccessModalOpen);
  }

  const handleCreateCheckout = async (interval) => {
    toggleLoadingSession(true);
    await createStripeCheckoutSession(establishmentid, interval);
    toggleLoadingSession(false);
  }

  const handleSubscriptionClick = async () => {
    if (subscriptionState?.isActive) {
      toggleLoadingSession(true);
      await createStripePortalSession(establishmentid);
      toggleLoadingSession(false);
    } else {
      toggleUpgradeModalOpen(true);
    }
  }

  useEffect(() => {
    updateEstablishmentCopy(store.currentEstablishment);
    setAddress(store.currentEstablishment?.address);
    const searchParams = new URLSearchParams(window.location.search);
    const checkoutStatus = searchParams.get('c_status');
    if (checkoutStatus === 'complete') {
      const currentPath = window.location.pathname;
      window.history.replaceState({}, '', currentPath);
      toggleIsSuccessModalOpen(true);
    }
  }, [store.currentEstablishment]);

  const isEstablishmentOwner = memberships?.establishmentMemberships?.find(
    (x) => x.establishmentId === establishmentid
  )?.isOwner === true
    ? true
    : false;
  return (
    <>
      <EditEstablishmentModal
        modalOpen={isEditing}
        toggleModalOpen={handleEditEstablishmentToggle}
        establishmentCopy={establishmentCopy}
        updateEstablishmentCopy={updateEstablishmentCopy}
        addressData={addressData}
        setAddress={setAddress}
        isLoading={isLoading}
        toggleIsLoading={toggleIsLoading}
        setAlertSuccess={setAlertSuccess}
        setErrorMessage={setErrorMessage}
      />
      <UpgradeAccountModal
        isOpen={upgradeModalOpen}
        handleModalToggle={handleUpgradeModalToggle}
        upgradeCallback={handleCreateCheckout}
        isLoadingSession={isLoadingSession}
        isOwner={isEstablishmentOwner}
      />
      <SubscriptionSuccessModal isOpen={isSuccessModalOpen} handleModalToggle={handleToggleSuccesModal} />
      <Box>
        <Snackbar
          open={alertSuccess}
          onClose={handleClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            Success! Establishment details updated.
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage}
          onClose={handleClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            Error! There was a problem editing your establishment details.
            Please refresh and try again.
          </Alert>
        </Snackbar>
      </Box>
      <Grid
        container
        direction="row"
        columns={12}
        sx={{
          display: "flex",
          maxWidth: "2440px",
          width: "100%",
          marginTop: 6,
        }}
      >
        <Grid
          container
          sx={{
            marginBottom: 8,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2" sx={{ fontSize: { xs: 20, sm: 28 } }}>
            Establishment Details
          </Typography>
          <Grid
            item
            sx={{
              justifySelf: "flex-end",
              alignSelf: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{ borderRadius: 2, padding: 2.5 }}
              onClick={() => toggleIsEditing(true)}
            >
              <EditOutlinedIcon sx={{ fontSize: "20px", marginRight: 1 }} />
              <Typography variant="smallButton">Edit</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={[
            DetailsBoxStyle,
            {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "68px",
              marginBottom: 6,
              padding: 6,
            },
          ]}
        >
          {isLoadingSubscriptionState || isLoadingMemberships ? (
            <Box sx={{ display: "flex" }}>
              <Typography>Checking subscription state:</Typography>
              <CircularProgress size={20} sx={{ marginLeft: 4 }} />
            </Box>
          ) : (
            <Grid container sx={{ width: "100%" }}>
              <Grid item sx={{ width: "50%" }}>
                <Box>
                  <Typography variant="h5">Current Subscription</Typography>
                </Box>
                <Box>
                  <Typography variant="body1">
                    {subscriptionState?.isActive
                      ? "Spec Pro"
                      : "Spec Free Plan"}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {isEstablishmentOwner ? (
                  <Button
                  variant="contained"
                  disabled={!isEstablishmentOwner || isLoadingSession}
                  onClick={handleSubscriptionClick}
                >
                  <Typography
                    variant="largeButton"
                  >
                    {subscriptionState?.isActive ? "Manage" : "Upgrade Now"}
                  </Typography>
                </Button>
                ) : (
                  <Alert icon={false} severity="warning" variant="outlined">Only the Establishment Owner can manage billing</Alert>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Box
            sx={[
              DetailsBoxStyle,
              {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 6,
              },
            ]}
          >
            <Grid item>
              <Typography variant="h2">{establishmentCopy.name}</Typography>
            </Grid>
            <Grid item>
              {store.currentEstablishment.imageURL ? (
                <Box
                  sx={{
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100px",
                    height: "100px",
                    borderRadius: "100%",
                    overflow: "hidden",
                    padding: 0,
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "auto",
                      verticalAlign: "middle",
                      display: "block",
                      backgroundColor: "#7e7e7e",
                    }}
                    src={store.currentEstablishment.imageURL}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100px",
                    height: "100px",
                    border: "2px solid",
                    borderColor: (theme) => theme.palette.terrain[400],
                    borderRadius: "100%",
                    // overflow: "hidden",
                    padding: 0,
                  }}
                >
                  <HomeOutlinedIcon
                    sx={{
                      fontSize: 48,
                      color: (theme) => theme.palette.terrain["800"],
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Box>
          <Box
            sx={[
              DetailsBoxStyle,
              { display: "flex", flexDirection: "column", marginTop: 6 },
            ]}
          >
            <Grid
              item
              sx={{
                width: "100%",
                height: "52px",
                borderBottom: "1px solid",
                borderColor: (theme) => theme.palette.terrain[300],
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingLeft: 6,
                  paddingRight: 6,
                  paddingTop: 4,
                }}
              >
                <Grid item>
                  <Typography variant="subtitle1">Website URL</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">
                    {addressData.addressLine2}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                width: "100%",
                height: "110px",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  // alignItems: "center",
                  justifyContent: "space-between",
                  padding: 6,
                }}
              >
                <Grid item>
                  <Typography variant="subtitle1">Address</Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography variant="subtitle2">
                    {addressData.addressLine1}
                  </Typography>
                  <Typography variant="subtitle2">
                    {addressData.city}
                    {", "}
                    {addressData.stateProvince}
                  </Typography>
                  <Typography variant="subtitle2">
                    {addressData.postalCode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default EstablishmentDetails;
