import React, { useContext, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import SubdirectoryArrowRightOutlinedIcon from "@mui/icons-material/SubdirectoryArrowRightOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import EditExpenseGroupModal from "../../../components/Modals/EditExpenseGroupModal";
import CreateExpenseGroupOrganizationModal from "../../../components/Modals/CreateExpenseGroupOrganizationModal";
import Store from "../../../Store/Store";
import { useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import DeleteExpenseGroupModal from "../../../components/Modals/DeleteExpenseGroupModal";
import DeleteExpenseSubgroupModal from "../../../components/Modals/DeleteExpenseSubgroupModal";

const ExpenseGroupCard = ({ expenseGroup }) => {
  const [expanded, setExpanded] = useState(false);
  const [deleteFamilyModalOpen, toggleDeleteFamilyModalOpen] = useState(false);
  const [deleteCategoryModalOpen, toggleDeleteCategoryModalOpen] =
    useState(false);
  const [editExpenseGroupModalOpen, toggleEditExpenseGroupModalOpen] =
    useState(false);
  const [addingExpenseSubgroup, toggleAddingExpenseSubgroup] = useState(false);
  const [editSubgroupModalOpen, toggleEditSubgroupModalOpen] = useState(false);
  const [expenseGroupAnchorEl, setExpenseGroupAnchorEl] = useState(null);
  const [subgroupAnchorEl, setSubgroupAnchorEl] = useState(null);
  const [selectedSubgroup, setSelectedSubgroup] = useState();
  const expenseGroupMenuOpen = Boolean(expenseGroupAnchorEl);
  const categoryMenuOpen = Boolean(subgroupAnchorEl);
  const store = useContext(Store);
  const queryClient = useQueryClient();

  const handleExpenseGroupMenuClick = (event) => {
    setExpenseGroupAnchorEl(event.currentTarget);
  };

  const handleSubgroupClick = (event, subgroup) => {
    setSubgroupAnchorEl(event.currentTarget);
    setSelectedSubgroup(subgroup);
  };

  const handleExpenseGroupMenuClose = () => {
    setExpenseGroupAnchorEl(null);
  };

  const handleSubgroupMenuClose = () => {
    setSubgroupAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAddExpenseSubgroup = async (expenseSubgroupName) => {
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      expenseGroupId: expenseGroup.id,
      name: expenseSubgroupName,
    };

    await inventoryClient.addExpenseSubgroup(payload);

    queryClient.invalidateQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
  };

  const handlePatchExpenseGroup = async (expenseGroupName) => {
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      expenseGroupId: expenseGroup.id,
      name: expenseGroupName,
    };

    await inventoryClient.patchExpenseGroup(payload);

    queryClient.invalidateQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
    setExpenseGroupAnchorEl(null);
    queryClient.refetchQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
  };

  const handlePatchExpenseSubgroup = async (subgroup) => {
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      expenseSubgroupId: subgroup.id,
      name: subgroup.name,
    };

    await inventoryClient.patchExpenseSubgroup(payload);

    queryClient.invalidateQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
    setSubgroupAnchorEl(null);
    queryClient.refetchQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
  };

  const handleDeleteExpenseGroup = async () => {
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      expensegroupId: expenseGroup.id,
    };

    await inventoryClient.deleteExpenseGroup(payload);

    queryClient.invalidateQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
    setExpenseGroupAnchorEl(null);
    queryClient.refetchQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
  };

  const handleDeleteExpenseSubgroup = async () => {
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      expenseSubgroupId: selectedSubgroup.id,
    };

    await inventoryClient.deleteExpenseSubgroup(payload);

    queryClient.invalidateQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
    setSubgroupAnchorEl(null);
    queryClient.refetchQueries({
      queryKey: [
        "expense-groups",
        {
          establishmentId: store.currentEstablishment?.id,
        },
      ],
    });
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain["300"],
          marginBottom: 4,
        }}
      >
        <CardContent
          sx={{ height: "56px", width: "100%", margin: 0, padding: 0 }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "56px",
              backgroundColor: (theme) => theme.palette.pure.white,
            }}
          >
            <Box>
              <Typography
                variant="h5"
                sx={{
                  marginLeft: 4,
                  color: (theme) => theme.palette.pure.black,
                }}
              >
                {expenseGroup.name}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box>
                <IconButton
                  onClick={handleExpenseGroupMenuClick}
                  sx={{
                    height: "32px",
                    width: "32px",
                    marginRight: 2,
                    color: (theme) => theme.palette.primary[800],
                    "&:hover": {
                      background: "none",
                      color: (theme) => theme.palette.terrain[100],
                    },
                  }}
                >
                  <MoreHorizOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  onClick={() => toggleAddingExpenseSubgroup(true)}
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary[800],
                    height: "32px",
                    width: "32px",
                    marginRight: 2,
                  }}
                >
                  <AddOutlinedIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "36px",
                }}
              >
                {!expanded ? (
                  <IconButton
                    sx={{
                      color: (theme) => theme.palette.terrain[900],
                      height: "36px",
                      background: "none",
                      "&:hover": {
                        background: "none",
                        color: (theme) => theme.palette.terrain[100],
                      },
                    }}
                    onClick={handleExpandClick}
                  >
                    <KeyboardArrowDownOutlinedIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      color: (theme) => theme.palette.terrain[900],
                      height: "36px",
                      background: "none",
                      "&:hover": {
                        background: "none",
                        color: (theme) => theme.palette.terrain[100],
                      },
                    }}
                    onClick={handleExpandClick}
                  >
                    <ExpandLessOutlinedIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Grid>
        </CardContent>
        {expenseGroup.expenseSubgroups?.map((x) => (
          <Collapse in={expanded} timeout="auto" unmountOnExit key={x.id}>
            <CardContent
              sx={{
                backgroundColor: (theme) => theme.palette.terrain[50],
                borderTop: "1px solid",
                borderColor: (theme) => theme.palette.terrain[300],
                height: "56px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  marginTop: 6,
                }}
              >
                <Box>
                  <Grid
                    container
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <SubdirectoryArrowRightOutlinedIcon
                      sx={{
                        color: (theme) => theme.palette.info[600],
                        fontSize: 24,
                        marginLeft: 4,
                      }}
                    />
                    <Grid item>
                      <Box>
                        <Box>
                          <Typography
                            variant="h5"
                            sx={{
                              color: (theme) => theme.palette.pure.black,
                              marginLeft: 2,
                            }}
                          >
                            {x.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box>
                    <IconButton
                      onClick={(e) => handleSubgroupClick(e, x)}
                      sx={{
                        height: "32px",
                        width: "32px",
                        marginRight: 2,
                        color: (theme) => theme.palette.primary[800],
                        "&:hover": {
                          background: "none",
                          color: (theme) => theme.palette.terrain[100],
                        },
                      }}
                    >
                      <MoreHorizOutlinedIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </CardContent>
          </Collapse>
        ))}
      </Card>
      <Menu
        anchorEl={expenseGroupAnchorEl}
        open={expenseGroupMenuOpen}
        onClose={handleExpenseGroupMenuClose}
      >
        <MenuItem
          onClick={() => toggleEditExpenseGroupModalOpen(true)}
          sx={{ margin: "5px" }}
        >
          <EditIcon sx={{ marginRight: "5px" }} />
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => toggleDeleteFamilyModalOpen(true)}
          sx={{ margin: "5px" }}
        >
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={subgroupAnchorEl}
        open={categoryMenuOpen}
        onClose={handleSubgroupMenuClose}
      >
        <MenuItem
          onClick={() => toggleEditSubgroupModalOpen(true)}
          sx={{ margin: "5px" }}
        >
          <EditIcon sx={{ marginRight: "5px" }} />
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => toggleDeleteCategoryModalOpen(true)}
          sx={{ margin: "5px" }}
        >
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
      <DeleteExpenseGroupModal
        handleDelete={handleDeleteExpenseGroup}
        expenseGroupName={expenseGroup.name}
        isOpen={deleteFamilyModalOpen}
        toggleModalOpen={toggleDeleteFamilyModalOpen}
      />
      <DeleteExpenseSubgroupModal
        handleDelete={handleDeleteExpenseSubgroup}
        expenseSubgroupName={selectedSubgroup?.name}
        isOpen={deleteCategoryModalOpen}
        toggleModalOpen={toggleDeleteCategoryModalOpen}
      />
      <EditExpenseGroupModal
        isOpen={editExpenseGroupModalOpen}
        toggleModalOpen={toggleEditExpenseGroupModalOpen}
        headerText={"Edit Expense Group Name"}
        item={expenseGroup}
        editExpenseGroup={handlePatchExpenseGroup}
      />
      <EditExpenseGroupModal
        isOpen={editSubgroupModalOpen}
        toggleModalOpen={toggleEditSubgroupModalOpen}
        headerText={"Edit Subgroup Name"}
        item={selectedSubgroup}
        editExpenseSubgroup={handlePatchExpenseSubgroup}
      />
      <CreateExpenseGroupOrganizationModal
        isOpen={addingExpenseSubgroup}
        toggleModalOpen={toggleAddingExpenseSubgroup}
        modalHeader="Create Subgroup"
        modalText="Subgroups allow you to categorize spendings and compare costings efficiently (Bar Spends > Spirits)"
        placeholderText="Enter Subgroup Name"
        buttonText="Create Subgroup"
        createExpenseSubgroup={handleAddExpenseSubgroup}
      />
    </>
  );
};

export default ExpenseGroupCard;
