import React, { useEffect, useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Alert,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import { useParams } from "react-router-dom";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import utilFunctions from "../../../utilities/utilFunctions";
import { cloneDeep } from "lodash";

const CreateMissingCostsModal = ({
  isOpen,
  closeModal,
  record,
  buttonText,
  headerText,
  loading,
}) => {
  const [priceDisplay, setPriceDisplay] = useState(null);
  const queryClient = useQueryClient();
  const { establishmentid } = useParams();
  const { data: settings } = useEstablishmentSettings(
    establishmentid
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  useEffect(() => {
    setPriceDisplay( utilFunctions.convertCentsToLargerFormatCurrency(
      record?.finalRecordValue?.amount,
      locale,
      currency
    ))
  }, [isOpen]);

  const patchStockCountRecordMutation = useMutation(
    inventoryClient.patchCompletedStockCountRecord,
    {
      onSuccess: (response) => {
        const state = queryClient.getQueryState({
          queryKey:  ["stock-count", { establishmentId: establishmentid, stockCountId: record.stockCountId }],
        });
    
        const newState = state.data;
        newState.stockCount = cloneDeep(newState.stockCount);
        newState.stockCount.finalTotalValue = response.updatedStockCountFinalTotal;
        newState.stockCount.stockCountRecords = newState.stockCount?.stockCountRecords.map(x => {
          if (x.id === response.stockCountRecord?.id) {
            return response.stockCountRecord;
          } else {
            return x;
          }
        })
    
        queryClient.setQueryData(
          ["stock-count", { establishmentId: establishmentid, stockCountId: record.stockCountId }],
          newState
        );
        closeModal();
      },
      onError: (err) => {
        console.log(err);
      }
    }
  );

  const handleFormatDisplay = () => {
    const centsValue = utilFunctions.convertCurrencyToCents(priceDisplay);
    setPriceDisplay(utilFunctions.convertCentsToLargerFormatCurrency(
      centsValue,
      locale,
      currency
    ));
  };

  const handleSave = () => {
    const centsValue = utilFunctions.convertCurrencyToCents(priceDisplay);
    const payload = {
      establishmentId: establishmentid,
      stockCountId: record?.stockCountId,
      recordId: record?.id,
      finalRecordValueUpdate: { value: { amount: centsValue, type: record?.finalRecordValue?.type, unit: record?.finalRecordValue?.unit }}
    };

    patchStockCountRecordMutation.mutate(payload);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[modalBoxStyle, { maxHeight: "600px" }]}>
        {patchStockCountRecordMutation?.isError ? <Alert severity="error">There was an error trying to update the record count.</Alert> : null}
        {loading || patchStockCountRecordMutation.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <form>
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="h2">{headerText}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={closeModal}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Box sx={{ marginTop: 4 }}>
                <Typography variant="body1">
                  Required properties to determine the value of the below record
                  were missing when the count was completed. Manually set the
                  value for the below record.
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: 4,
                  width: "100%",
                  // border: "1px solid",
                  // borderColor: (theme) => theme.palette.terrain[300],
                  // borderRadius: 2,
                  maxHeight: "400px",
                  overflow: "scroll",
                }}
              >
                <Box
                  sx={{
                    height: "60px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: (theme) => theme.palette.terrain[50],
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.terrain[300],
                    padding: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "160px",
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        maxWidth: "160px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography noWrap variant="body1">
                        {record?.historicalProductName}
                      </Typography>
                    </div>
                    <Typography variant="overline">{record?.historicalSizingDisplay || "(Missing Sizing Data)"}</Typography>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="number"
                    value={priceDisplay}
                    autoComplete="off"
                    onBlur={handleFormatDisplay}
                    onKeyDown={utilFunctions.blurInput}
                    onFocus={utilFunctions.highlightInputOnFocus}
                    onChange={(e) => setPriceDisplay(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                        inputMode: "numeric",
                        startAdornment: (
                          <InputAdornment position="start">
                            {currencySymbol}
                          </InputAdornment>
                        ),
                        pattern: "[0-9]*",
                      background: (theme) => theme.palette.pure.white,
                    }}
                    sx={{
                      width: "100px",
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      "& .MuiOutlinedInput-root": {
                        background: (theme) => theme.palette.pure.white,
                      },
                      "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                  onClick={handleSave}
                >
                  <Typography variant="largeButton">{buttonText}</Typography>
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default CreateMissingCostsModal;
