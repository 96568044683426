import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { productSizeTypes } from "../../../clients/inventoryObjects";

const displayMap = {
  1: "Volumetric",
  2: "Mass",
  3: "Arbitrary",
};

const EditUnitSizeRow = ({
  unitSize,
  masterUoms,
  handleUpdateUnitSize,
  handleUpdateUom,
  markAsDefault,
}) => {
  const localHandleSizeUpdate = (e) => {
    handleUpdateUnitSize(e, unitSize?.type);
  }

  const localHandleUomUpdate = (e) => {
    handleUpdateUom(e, unitSize?.type);
  }

  const localHandleMarkAsDefault = () => {
    markAsDefault(unitSize?.type);
  };
  return (
    <>
      <TextField
        value={unitSize?.size}
        onChange={localHandleSizeUpdate}
        label={`${displayMap[unitSize?.type]} Size`}
      />
      {unitSize?.type === productSizeTypes.Arbitrary ? null : (
        <>
          <InputLabel id="family-label" sx={{ marginTop: 2 }}>
            {displayMap[unitSize?.type]} UOM
          </InputLabel>
          <Select
            labelId="family-label"
            sx={{ marginTop: 2, marginBottom: 4 }}
            value={unitSize?.unitOfMeasurement?.id || ""}
            onChange={localHandleUomUpdate}
          >
            {masterUoms?.map((x) => (
              <MenuItem key={x.id} id={unitSize?.type} value={x.id}>
                {x.isKegSize ? x.kegName : x.name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {unitSize?.isDefaultSize ? null : <Button onClick={localHandleMarkAsDefault}>Make {displayMap[unitSize?.type]} Default</Button>}
    </>
  );
};

export default EditUnitSizeRow;
