import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { modalBoxStyle } from "../styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PurchaseUnitCard from "./components/PurchaseUnitCard";
import CTABlock from "../../CTABlock";

const ApproveInvoiceModal = ({
  isOpen,
  toggleModalOpen,
  approvalPreview,
  completeInvoiceApproval,
  isInvoiceApprovalLoading,
}) => {
  const [excludeFromUpdatesMap, setExcludeFromUpdatesMap] = useState({});

  useEffect(() => {
    setExcludeFromUpdatesMap({});
  }, [isOpen]);

  const handleClose = () => {
    setExcludeFromUpdatesMap({});
    toggleModalOpen(false);
  };

  const handleExclusionUpdate = (formatId) => {
    const newMap = Object.assign({}, excludeFromUpdatesMap);
    if (excludeFromUpdatesMap[formatId] === true) {
      newMap[formatId] = false;
    } else {
      newMap[formatId] = true;
    }

    setExcludeFromUpdatesMap(newMap);
  };

  const handleCompleteInvoiceApproval = () => {
    let purchaseUnitsToSkip = [];

    Object.keys(excludeFromUpdatesMap).forEach((x) => {
      if (excludeFromUpdatesMap[x]) {
        purchaseUnitsToSkip.push(x);
      }
    });

    completeInvoiceApproval(purchaseUnitsToSkip);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={[modalBoxStyle, { height: "600px", width: "400px !important" }]}>
        <Grid
          container
          sx={{
            marginBottom: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="h4">Approve Invoice</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton onClick={handleClose} sx={{ marginRight: -4 }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box>
          <Typography variant="body1">
            Ready to approve this invoice? When you do, all details in this
            invoice will be seen in the Reporting section, and any selected
            price updates will be pushed to the Product Library.
          </Typography>
        </Box>
        <Box sx={{ marginTop: 6 }}>
          <Box sx={{ marginTop: 2, height: "340px", overflow: "scroll" }}>
            {approvalPreview?.purchaseUnitUpdates?.length === 0 ? (
              <CTABlock
                hideButton
                header="Purchase Unit Prices Match!"
                subheader="No updates were detected between your invoiced prices and existing purchase units' price. You're all good!"
              />
            ) : (
              <>
                <Box>
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    Update prices of purchase units:
                  </Typography>
                </Box>
                {approvalPreview?.purchaseUnitUpdates?.map((x, i) => (
                  <PurchaseUnitCard
                    key={i}
                    updatePreview={x}
                    excludeFromUpdatesMap={excludeFromUpdatesMap}
                    handleSelect={handleExclusionUpdate}
                  />
                ))}
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: 6,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          disabled={isInvoiceApprovalLoading}
          onClick={handleCompleteInvoiceApproval}
        >
          <Button variant="contained">
            {isInvoiceApprovalLoading ? (
              <CircularProgress />
            ) : (
              <Typography variant="largeButton">Approve Invoice</Typography>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApproveInvoiceModal;
