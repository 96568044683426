import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import Store from "../../../../Store/Store";
import utilFunctions from "../../../../utilities/utilFunctions";
import MiscRow from "./MiscRow";
import useEstablishmentSettings from "../../../../hooks/useEstablishmentSettings";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  lineHeight: 1.2,
  borderBottom: "none",
};

const MiscTable = ({ miscLineItems, invoice, expenseGroups }) => {
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { currencySymbol, currency, locale } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  return (
    <TableContainer
      sx={{
        overflow: "scroll !important",
        borderRadius: 2,
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[400],
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="customized table">
        <TableHead sx={{ height: "24px" }}>
          <TableRow sx={{ height: 24 }}>
            <TableCell sx={[tableHeadStyle, { width: "400px" }]}>
              Miscellaneous Name
            </TableCell>
            <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
              Expense Group
            </TableCell>
            <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
              Expense Subgroup
            </TableCell>
            <TableCell sx={[tableHeadStyle, { width: "118px" }]}></TableCell>
            <TableCell sx={[tableHeadStyle, { width: "118px" }]}>
              Line Item Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {miscLineItems?.map((x) => (
            <MiscRow key={x.id} lineItem={x} expenseGroups={expenseGroups} />
          ))}
          <TableRow
            sx={{
              borderTop: "1px solid",
              borderColor: (theme) => theme.palette.terrain[400],
            }}
          >
            <TableCell colSpan={4} sx={tableHeadStyle}>
              Total:
            </TableCell>
            <TableCell sx={tableHeadStyle}>
              <Typography variant="body1">
                {currencySymbol}
                {utilFunctions.convertCentsToLargerFormatCurrency(
                  invoice.finalMiscellaneousLineItemTotal?.amount,
                  locale,
                  currency
                )}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MiscTable;
