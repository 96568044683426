import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useAreaDetails(establishmentId, areaId) {
  const queryReturnValues = useQuery(
    ["area", { establishmentId, areaId }],
    inventoryClient.getAreaById,
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  if (!areaId) {
    return { data: null, isLoading: false };
  }

  return queryReturnValues;
}

export default useAreaDetails;
