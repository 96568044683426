import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const MenuItemCard = ({item, removeItem}) => {

  const handleRemove = () => {
    removeItem(item.uuidForList);
  }

  return (
    <>
      <Box
        sx={{
          height: "64px",
          width: "100%",
          backgroundColor: (theme) => theme.palette.pure.white,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 2,
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography variant="h4" sx={{ marginRight: 2 }}>
            {item.name}
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            sx={{
              border: "none",
              width: "32px",
              height: "32px",
              "&:hover": {
                border: "none",
              },
            }}
            onClick={handleRemove}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </Box>
    </>
  );
};

export default MenuItemCard;
