import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

const TooltipContentArea = ({ missingValues, prefixLines, suffixLines }) => {
  return (
    <Box>
      {prefixLines !== undefined ? (
        prefixLines?.map((x, i) => {
          <Typography key={i} variant="body2">
            {x}
          </Typography>;
        })
      ) : (
        <>
          <Typography variant="body2">Item not included in costing.</Typography>
          <Typography variant="body2">
            The following data points are missing:
          </Typography>
        </>
      )}
      <br />
      {missingValues?.map((x) => (
        <Typography variant="body2" key={x}>
          {x?.message}
        </Typography>
      ))}
      <br />
      {suffixLines !== undefined ? (
        suffixLines?.map((x, i) => {
          <Typography key={i} variant="body2">
            {x}
          </Typography>;
        })
      ) : (
        <Typography variant="body2">
          Add these to this item to enable cost analysis.
        </Typography>
      )}
    </Box>
  );
};

const MissingDataPointsTooltip = ({ missingValues, prefixLines, suffixLines }) => {
  return (
    <Tooltip title={<TooltipContentArea missingValues={missingValues} prefixLines={prefixLines} suffixLines={suffixLines} />}>
      <WarningIcon
        sx={{
          color: (theme) => theme.palette.warning[300],
          marginRight: 1,
          paddingBottom: 0.5,
        }}
        fontSize="small"
      />
    </Tooltip>
  );
};

export default MissingDataPointsTooltip;
