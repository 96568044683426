import { SvgIcon } from "@mui/material";
import React from "react";

const SubstitutionIcon = (props) => {
  const bgColor = () => {
    if (props.color === "blue") {
      return "#E0F4F9";
    } else if (props.color === "yellow") {
      return "#FEF2DF";
    } else if (props.color === "red") {
      return "#FDE8E7";
    } else if (props.color === "purple") {
      return "#080E40";
    }
  };

  const circleColor = () => {
    if (props.color === "blue") {
      return "#81D1E8";
    } else if (props.color === "yellow") {
      return "#FAC87E";
    } else if (props.color === "red") {
      return "#FFA790";
    } else if (props.color === "purple") {
      return "#242E6C";
    }
  };

  const outlineColor = () => {
    if (props.color === "blue") {
      return "#B2E3F1";
    } else if (props.color === "yellow") {
      return "#FCDDB1";
    } else if (props.color === "red") {
      return "#FFCABB";
    } else if (props.color === "purple") {
      return "#475086";
    }
  };

  const textColor = () => {
    if (props.color === "purple") {
      return "#ffffff";
    } else {
      return "#282828";
    }
  };

  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="40" height="40" rx="8" fill={bgColor()} />
      <circle cx="20" cy="20" r="16" fill={circleColor()} />
      <rect x="2" y="10" width="36" height="20" rx="4" fill={outlineColor()} />
      <rect x="4" y="12" width="32" height="16" rx="2" fill={bgColor()} />
      <path
        d="M11.8578 25.12C11.2978 25.12 10.7938 25.052 10.3458 24.916C9.89779 24.772 9.46979 24.524 9.06179 24.172C8.95779 24.084 8.87779 23.984 8.82179 23.872C8.76579 23.76 8.73779 23.648 8.73779 23.536C8.73779 23.352 8.80179 23.192 8.92979 23.056C9.06579 22.912 9.23379 22.84 9.43379 22.84C9.58579 22.84 9.72179 22.888 9.84179 22.984C10.1458 23.232 10.4458 23.42 10.7418 23.548C11.0458 23.676 11.4178 23.74 11.8578 23.74C12.1538 23.74 12.4258 23.696 12.6738 23.608C12.9218 23.512 13.1218 23.388 13.2738 23.236C13.4258 23.076 13.5018 22.896 13.5018 22.696C13.5018 22.456 13.4298 22.252 13.2858 22.084C13.1418 21.916 12.9218 21.776 12.6258 21.664C12.3298 21.544 11.9538 21.452 11.4978 21.388C11.0658 21.324 10.6858 21.228 10.3578 21.1C10.0298 20.964 9.75379 20.796 9.52979 20.596C9.31379 20.388 9.14979 20.148 9.03779 19.876C8.92579 19.596 8.86979 19.284 8.86979 18.94C8.86979 18.42 9.00179 17.976 9.26579 17.608C9.53779 17.24 9.90179 16.96 10.3578 16.768C10.8138 16.576 11.3178 16.48 11.8698 16.48C12.3898 16.48 12.8698 16.56 13.3098 16.72C13.7578 16.872 14.1218 17.068 14.4018 17.308C14.6338 17.492 14.7498 17.704 14.7498 17.944C14.7498 18.12 14.6818 18.28 14.5458 18.424C14.4098 18.568 14.2498 18.64 14.0658 18.64C13.9458 18.64 13.8378 18.604 13.7418 18.532C13.6138 18.42 13.4418 18.316 13.2258 18.22C13.0098 18.116 12.7818 18.032 12.5418 17.968C12.3018 17.896 12.0778 17.86 11.8698 17.86C11.5258 17.86 11.2338 17.904 10.9938 17.992C10.7618 18.08 10.5858 18.2 10.4658 18.352C10.3458 18.504 10.2858 18.68 10.2858 18.88C10.2858 19.12 10.3538 19.32 10.4898 19.48C10.6338 19.632 10.8378 19.756 11.1018 19.852C11.3658 19.94 11.6818 20.02 12.0498 20.092C12.5298 20.18 12.9498 20.284 13.3098 20.404C13.6778 20.524 13.9818 20.68 14.2218 20.872C14.4618 21.056 14.6418 21.292 14.7618 21.58C14.8818 21.86 14.9418 22.204 14.9418 22.612C14.9418 23.132 14.7978 23.58 14.5098 23.956C14.2218 24.332 13.8418 24.62 13.3698 24.82C12.9058 25.02 12.4018 25.12 11.8578 25.12Z"
        fill={textColor()}
      />
      <path
        d="M22.2819 16.588C22.49 16.588 22.6579 16.66 22.7859 16.804C22.9139 16.94 22.9779 17.112 22.9779 17.32V21.664C22.9779 22.328 22.834 22.916 22.5459 23.428C22.2659 23.94 21.8779 24.34 21.3819 24.628C20.8859 24.916 20.3099 25.06 19.6539 25.06C18.9979 25.06 18.4179 24.916 17.9139 24.628C17.4179 24.34 17.0259 23.94 16.7379 23.428C16.4579 22.916 16.3179 22.328 16.3179 21.664V17.32C16.3179 17.112 16.3899 16.94 16.5339 16.804C16.6779 16.66 16.8619 16.588 17.0859 16.588C17.2699 16.588 17.4339 16.66 17.5779 16.804C17.7299 16.94 17.806 17.112 17.806 17.32V21.664C17.806 22.064 17.8899 22.412 18.0579 22.708C18.2339 22.996 18.4619 23.22 18.7419 23.38C19.0299 23.54 19.334 23.62 19.6539 23.62C19.9979 23.62 20.3179 23.54 20.6139 23.38C20.9099 23.22 21.15 22.996 21.334 22.708C21.518 22.412 21.6099 22.064 21.6099 21.664V17.32C21.6099 17.112 21.6699 16.94 21.7899 16.804C21.9099 16.66 22.0739 16.588 22.2819 16.588Z"
        fill={textColor()}
      />
      <path
        d="M28.5349 16.6C29.3029 16.6 29.8749 16.78 30.2509 17.14C30.6269 17.5 30.8149 18.032 30.8149 18.736C30.8149 19.112 30.7229 19.444 30.5389 19.732C30.3549 20.012 30.0829 20.232 29.7229 20.392C29.3629 20.544 28.9189 20.62 28.3909 20.62L28.4389 20.08C28.6869 20.08 28.9709 20.116 29.2909 20.188C29.6109 20.252 29.9189 20.372 30.2149 20.548C30.5189 20.716 30.7669 20.956 30.9589 21.268C31.1589 21.572 31.2589 21.964 31.2589 22.444C31.2589 22.972 31.1709 23.404 30.9949 23.74C30.8269 24.076 30.6029 24.336 30.3229 24.52C30.0429 24.704 29.7429 24.832 29.4229 24.904C29.1029 24.968 28.7949 25 28.4989 25H25.5109C25.3029 25 25.1269 24.932 24.9829 24.796C24.8469 24.652 24.7789 24.476 24.7789 24.268V17.332C24.7789 17.124 24.8469 16.952 24.9829 16.816C25.1269 16.672 25.3029 16.6 25.5109 16.6H28.5349ZM28.3189 18.016H26.1829L26.3389 17.824V19.996L26.1949 19.888H28.3549C28.5869 19.888 28.7949 19.812 28.9789 19.66C29.1629 19.508 29.2549 19.288 29.2549 19C29.2549 18.656 29.1669 18.408 28.9909 18.256C28.8229 18.096 28.5989 18.016 28.3189 18.016ZM28.4149 21.304H26.2309L26.3389 21.208V23.74L26.2189 23.62H28.4989C28.8669 23.62 29.1589 23.524 29.3749 23.332C29.5909 23.132 29.6989 22.836 29.6989 22.444C29.6989 22.084 29.6269 21.824 29.4829 21.664C29.3389 21.504 29.1669 21.404 28.9669 21.364C28.7669 21.324 28.5829 21.304 28.4149 21.304Z"
        fill={textColor()}
      />
    </SvgIcon>
  );
};

export default SubstitutionIcon;
