import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CTABlock from "../../components/CTABlock";
import AddIcon from "@mui/icons-material/Add";
import CreateAreaModal from "../../components/Modals/CreateAreaModal.js";
import Store, { activeRouteKeys } from "../../Store/Store";
import { useQueryClient, useMutation } from "react-query";
import inventoryClient from "../../clients/inventoryClient";
import SpecSpinner from "../../components/SpecSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { red } from "@mui/material/colors";
import useEstablishmentAreas from "../../hooks/useEstablishmentAreas";
import SpecBackButton from "../../components/SpecBackButton";
import DeleteAreaModal from "../../components/Modals/DeleteAreaModal.js";
import AreaCard from "./components/AreaCard.js";

const AreasDisplay = () => {
  const [creatingArea, toggleCreatingArea] = useState(false);
  const { establishmentid } = useParams();
  const store = useContext(Store);
  const navigate = useNavigate();
  const { isLoading, isError, data } = useEstablishmentAreas(establishmentid);
  const queryClient = useQueryClient();
  const [deleteAreaModalOpen, setDeleteAreaModalOpen] = useState(false);
  const [selectedAreaId, setSelectedAreaId] = useState(null);

  const toggleDeleteAreaModal = (id) => {
    setSelectedAreaId(id);
    setDeleteAreaModalOpen(!deleteAreaModalOpen);
  };

  const deleteAreaMutation = useMutation(inventoryClient.deleteArea, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "areas",
          {
            establishmentId: store?.currentEstablishment.id,
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "areas",
          {
            establishmentId: store?.currentEstablishment.id,
          },
        ],
      });
    },
  });

  const handleDeleteArea = async (areaId) => {
    try {
      const archivePayload = {
        establishmentId: store.currentEstablishment?.id,
        areaId: areaId,
      };

      deleteAreaMutation.mutate(archivePayload);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.areas,
      `spec/${establishmentid}/areas`
    );
  }, []);

  const mutation = useMutation(inventoryClient.createArea, {
    onSuccess: (result) => {
      const existingAreas = queryClient.getQueryData([
        "areas",
        { establishmentId: establishmentid },
      ]).areas;
      queryClient.setQueryData(
        ["areas", { establishmentId: establishmentid }],
        { areas: [...existingAreas, result.area] }
      );
      toggleCreatingArea(false);
    },
  });

  const handleAreaCreateToggle = () => {
    toggleCreatingArea(!creatingArea);
  };

  const handleCreateArea = async (areaName) => {
    mutation.mutate({
      establishmentId: establishmentid,
      name: areaName,
    });
  };

  if (isLoading) {
    return <SpecSpinner open text="Loading you areas..." />;
  }

  return (
    <Grid
      container
      direction="column"
      sx={{
        maxWidth: "2440px",
        minHeight: "800px",
        padding: 4,
        marginTop: 6,
      }}
    >
      <Grid item xs={12}>
        <SpecBackButton
          onClick={() =>
            navigate(`/spec/${store.currentEstablishment?.id}/organization`)
          }
          backText="Organization"
        />
      </Grid>
      {data && data.areas.length > 0 ? (
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            sx={{
              marginBottom: 8,
              display: "flex",
            }}
          >
            <Grid item xs={5} sm={4} md={3} xl={2} lg={2}>
              <Typography variant="h2">Areas</Typography>
            </Grid>
            <Grid
              item
              xs={7}
              sm={8}
              md={9}
              xl={10}
              lg={10}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={handleAreaCreateToggle}
                sx={{ borderRadius: 2, padding: 2.5 }}
              >
                <AddOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                <Typography variant="smallButton">Create New Area</Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {isError && (
                <Typography color={red}>
                  There was an error retrieving your areas. Please try again.
                </Typography>
              )}
              {data.areas.map((x) => (
                <AreaCard
                  area={x}
                  key={x.id}
                  onDelete={toggleDeleteAreaModal}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid sx={{ padding: "20px" }}>
          <Box sx={{ paddingLeft: 5, marginBottom: 5 }}>
            <Typography variant="h2">Areas</Typography>
          </Box>
          <CTABlock
            header="Your areas are empty"
            subheader="You have not added any areas yet. Get started now..."
            buttonText="Create New Area"
            ButtonImage={AddIcon}
            handleCTA={() => toggleCreatingArea(true)}
          />
        </Grid>
      )}
      <CreateAreaModal
        isOpen={creatingArea}
        toggleModalOpen={handleAreaCreateToggle}
        headerText="Create New Area"
        buttonText="Create Area"
        createArea={handleCreateArea}
        loading={mutation.isLoading}
      />
      <DeleteAreaModal
        deleteCallback={handleDeleteArea}
        isOpen={deleteAreaModalOpen}
        toggleModalOpen={toggleDeleteAreaModal}
        areaId={selectedAreaId}
      />
    </Grid>
  );
};

export default AreasDisplay;
