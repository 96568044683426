import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useEstablishmentProductFamilyGroups(establishmentId) {
  const queryReturnValues = useQuery(
    ["productFamilyGroups", {establishmentId}],
    inventoryClient.getEstablishmentProductFamilyGroups,
    { refetchOnWindowFocus: false, staleTime: 1000 * 60 * 10 }
  );

  return queryReturnValues;
}

export default useEstablishmentProductFamilyGroups;