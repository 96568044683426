import React from "react";

import { Box, Typography } from "@mui/material";

const orderDayMap = {
  0: "Mon",
  1: "Tues",
  2: "Wed",
  3: "Thurs",
  4: "Fri",
  5: "Sat",
  6: "Sun",
};

const CutOffTimeBadge = ({ orderDay }) => {
  return (
    <Box
      sx={{
        width: "110px",
        height: "48px",
        backgroundColor: (theme) => theme.palette.terrain[800],
        borderRadius: "4px",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 4,
      }}
    >
      <Box
        sx={{
          marginBottom: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.pure.white,
            fontWeight: 700,
            marginLeft: 1,
          }}
        >
          {orderDayMap[orderDay.day]}
          {orderDay.noCutOffTime ? "" : `, ${orderDay.orderCutOffTime}`}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body1"
          sx={{ color: (theme) => theme.palette.terrain[400] }}
        >
          {orderDay.noCutOffTime ? "No Cut Off Time" : "Cut Off Time"}
        </Typography>
      </Box>
    </Box>
  );
};

export default CutOffTimeBadge;
