import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useRecipesPaginated(establishmentId, page, filters, disable) {
    const queryReturnValues = useQuery(
        ["recipes-paginated", { establishmentId, page, filters }],
        inventoryClient.getRecipesPaginated,
        {
            staleTime: 1000 * 60 * 10,
            keepPreviousData: true,
            enabled: disable ? false : true
        }
    );

    return queryReturnValues;
}

export default useRecipesPaginated;