import axios from "axios";
import { getAccessToken } from "./identityClient";

export const authConfig = {
  baseURL: "",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type",
    Accept: "application/json",
  },
};

const authClient = {
  getUserProfile: async function getUserProfile() {
    const token = getAccessToken();
    authConfig.headers.Authorization = token;
    authConfig.headers["Content-Type"] = "application/x-www-form-urlencoded";
    const response = await axios.get("/Profile/Me", authConfig);
    return response.data;
  },
};

export async function getUserProfile() {
  try {
    const token = getAccessToken();
    authConfig.headers.Authorization = token;
    authConfig.headers["Content-Type"] = "application/x-www-form-urlencoded";
    const response = await axios.get("/Profile/Me", authConfig);
    return response.data;
  } catch (ex) {
    console.log(ex);
    throw ex;
  }
}

export async function createProfile(profile) {
  try {
    const token = getAccessToken();
    authConfig.headers.Authorization = token;
    authConfig.headers["Content-Type"] = "application/json";
    const response = await axios.post("/Profile/Create", profile, authConfig);

    return response.data;
  } catch (ex) {
    console.log(ex);

    return { error: ex };
  }
}

export async function updateProfile(profile) {
  const token = getAccessToken();
  authConfig.headers.Authorization = token;
  authConfig.headers["Content-Type"] = "application/json";
  const response = await axios.post("/Profile/Update", profile, authConfig);

  return response.data;
}

export async function deleteUserAccount() {
  const token = getAccessToken();
  authConfig.headers.Authorization = token;
  const response = await axios.delete("/users/close", authConfig);

  return response.data;
}

export default authClient;
