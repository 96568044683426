const homebaseConstants = {
    editorDisplayTypes: {
        category: 'category',
        recipe: 'recipe',
        beer: 'beer',
        wine: 'wine',
    },
    categoryTypes: {
        menu: "menu",
        winelist: "winelist",
        beerlist: "beerlist",
        coffeemenu: "coffeemenu"
    },
    SpecEstId: "00000000-0000-0000-0000-000000000001"
}

export default homebaseConstants;