import {
  Box,
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import NestedListExpandIcon from "./NestedListExpandIcon";

const FamilyNestedListItem = ({
  family,
  productFamilyTargetIds,
  categoryTargetIds,
  subcategoryTargetIds,
  toggleFamilyTarget,
  toggleCategoryTarget,
  toggleSubcategoryTarget,
}) => {
  const [isFamilyExpanded, toggleIsFamilyExpanded] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});

  const handleFamilyExpanded = () => {
    toggleIsFamilyExpanded(!isFamilyExpanded);
  };

  const handleExpandCategory = (id) => {
    const newExpanded = Object.assign({}, expandedCategories);
    newExpanded[id] = expandedCategories[id] ? false : true;
    setExpandedCategories(newExpanded);
  };

  const handleFamilyCheckClick = () => {
    toggleFamilyTarget(family);
  };

  const handleCategoryCheckClick = (e) => {
    const category = family.categories.find((x) => x.id === e.target.id);
    toggleCategoryTarget(category, family.id);
  };

  const handleSubcategoryCheckClick = (e) => {
    const idChain = e.target.id.split(",");
    toggleSubcategoryTarget(idChain[0], idChain[1], idChain[2]);
  };

  return (
    <>
      <ListItemButton
        disableRipple
        disableTouchRipple
        sx={{
          paddingLeft: 3,
          borderLeft: (theme) => `2px solid ${theme.palette.primary[100]}`,
          margin: 0,
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            borderBottom: "1px solid",
            borderBottomColor: (theme) => theme.palette.terrain[300],
            padding: 1,
          }}
        >
          <ListItemIcon>
            <Checkbox
              id={family.id}
              checked={productFamilyTargetIds[family.id] || false}
              onChange={handleFamilyCheckClick}
              sx={{ color: (theme) => theme.palette.primary[800] }}
            />
          </ListItemIcon>
          <ListItemText>{family.name}</ListItemText>
          {family.categories?.length > 0 ? (
            <NestedListExpandIcon
              isOpen={isFamilyExpanded}
              toggleOpen={handleFamilyExpanded}
            />
          ) : null}
        </Box>
      </ListItemButton>
      {family.categories?.length > 0 ? (
        <Collapse in={isFamilyExpanded} timeout="auto">
          <List component="div" disablePadding>
            {family.categories?.map((x) => (
              <>
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  key={x.id}
                  sx={{
                    backgroundColor: (theme) => theme.palette.terrain[50],
                    borderLeft: (theme) =>
                      `4px solid ${theme.palette.primary[200]}`,
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      borderBottom: "1px solid",
                      borderBottomColor: (theme) => theme.palette.terrain[300],
                      padding: 1,
                      paddingLeft: 6,
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        sx={{ color: (theme) => theme.palette.primary[800] }}
                        id={x.id}
                        checked={categoryTargetIds[x.id] || false}
                        onChange={handleCategoryCheckClick}
                      />
                    </ListItemIcon>
                    <ListItemText>{x.name}</ListItemText>
                    {x.subcategories?.length > 0 ? (
                      <NestedListExpandIcon
                        isOpen={expandedCategories[x.id] || false}
                        toggleOpen={() => handleExpandCategory(x.id)}
                      />
                    ) : null}
                  </Box>
                </ListItemButton>
                {x.subcategories?.length > 0 ? (
                  <Collapse
                    in={isFamilyExpanded && expandedCategories[x.id]}
                    timeout="auto"
                  >
                    <List component="div" disablePadding>
                      {x.subcategories?.map((sub) => (
                        <ListItemButton
                          disableRipple
                          disableTouchRipple
                          key={sub.id}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.terrain[50],
                            borderLeft: "6px solid",
                            borderLeftColor: (theme) =>
                              theme.palette.primary[300],
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100%",
                              borderBottom: "1px solid",
                              borderBottomColor: (theme) =>
                                theme.palette.terrain[300],
                              padding: 1,
                              paddingLeft: 9,
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                sx={{
                                  color: (theme) => theme.palette.primary[800],
                                }}
                                id={`${family.id},${x.id},${sub.id}`}
                                checked={subcategoryTargetIds[sub.id] || false}
                                onChange={handleSubcategoryCheckClick}
                              />
                            </ListItemIcon>
                            <ListItemText>{sub.name}</ListItemText>
                          </Box>
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                ) : null}
              </>
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
};

export default FamilyNestedListItem;
