import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";
import OrderCard from "./OrderCard";

const OrderSelectionList = ({
  isLoadingOrders,
  orders,
  nextOrderPageToLoad,
  totalOrderPages,
  errorLoadingOrders,
  loadMoreCallback,
  selectOrderCallback,
  selectedOrders,
  currentLinkedOrders,
}) => {
  if (orders?.length === 0) {
    return (
      <Box>
        <Typography variant="body1">
          No orders found for this vendor.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="body1">
          Search for the order you wish to link
        </Typography>
        {errorLoadingOrders && (
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.error[100] }}
          >
            There was a problem trying to load orders.
          </Typography>
        )}
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Box sx={{ marginTop: 2, maxHeight: "400px", overflow: "scroll" }}>
          {orders.map((x) => (
            <OrderCard key={x.index} order={x} selectedOrders={selectedOrders} selectOrderCallback={selectOrderCallback} currentLinkedOrders={currentLinkedOrders} />
          ))}
          {nextOrderPageToLoad <= totalOrderPages ? (
            <Box
              sx={{
                marginTop: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoadingOrders ? (
                <CircularProgress />
              ) : (
                <Button variant="outlined" onClick={loadMoreCallback}>
                  <Typography variant="smallButton">Load More</Typography>{" "}
                </Button>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSelectionList;
