import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";

const toggleButtonStyle = {
  borderTopRightRadius: "8px !important",
  borderBottomRightRadius: "8px !important",
  borderTopLeftRadius: "8px !important",
  borderBottomLeftRadius: "8px !important",
  border: "none",
  backgroundColor: (theme) => theme.palette.terrain[200],
  "&.Mui-selected, &.Mui-selected:hover": {
    color: (theme) => theme.palette.pure.white,
    backgroundColor: (theme) => theme.palette.terrain[900],
  },
};

const SortBy = ({ orderByAscending, toggleOrderByAscending }) => {
  const handleSelect = (_, v) => {
    if (v === null) {
      return;
    }

    if (v === 0) {
      toggleOrderByAscending(true);
    } else {
      toggleOrderByAscending(false);
    }
  };

  return (
    <Box>
      <Box>
        <Typography variant="h5">Sort By Date</Typography>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <ToggleButtonGroup
          value={orderByAscending ? 0 : 1}
          onChange={handleSelect}
          exclusive
        >
          <ToggleButton
            value={1}
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Newest to Oldest
            </Typography>
          </ToggleButton>
          <ToggleButton
            value={0}
            sx={[
              toggleButtonStyle,
              {
                marginRight: 2,
              },
            ]}
          >
            <Typography sx={{ textTransform: "none" }} variant="subtitle1">
              Oldest to Newest
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default SortBy;
