import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useActiveStockCounts(establishmentId, page, enabled) {
  const queryReturnValues = useQuery(
    ["active-stockcounts", { establishmentId, page }],
    inventoryClient.getActiveStockCountsForEstablishment,
    {
      staleTime: 1000 * 60 * 5,
      enabled
    }
  );

  return queryReturnValues;
}

export default useActiveStockCounts;
