import { Grid, Radio, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const selectedStyle = {
  cursor: "pointer",
  width: "100%",
  height: "90px",
  borderRadius: "4px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.primary[800],
  backgroundColor: (theme) => theme.palette.primary[50],
  marginBottom: 2,
};

const defaultStyle = {
  cursor: "pointer",
  width: "100%",
  height: "90px",
  border: "1px solid gray",
  borderRadius: "4px",
  marginBottom: 2,
};

const TemplateOption = ({
  template,
  selectedTemplateId,
  setSelectedTemplateId,
}) => {
  const handleSetSelectedTemplate = () => {
    setSelectedTemplateId(template.id);
  };

  return (
    <Grid item>
      <Box
        sx={template.id === selectedTemplateId ? selectedStyle : defaultStyle}
        onClick={handleSetSelectedTemplate}
      >
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item>
            <Radio checked={template.id === selectedTemplateId} />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                fontSize: { xs: 12, sm: 12, md: 16 },
                color:
                  template.id === selectedTemplateId
                    ? (theme) => theme.palette.primary[800]
                    : (theme) => theme.palette.pure.black,
              }}
            >
              {template.name}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid
          item
          sx={{
            marginLeft: 3,
            marginRight: 1,
            marginTop: { xs: -2, sm: -2, md: 0 },
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: { xs: 10, sm: 10, md: 12 }, marginRight: 1 }}
            >
              Areas:
            </Typography>
            <div
              style={{
                maxWidth: "260px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography noWrap variant="body1" sx={{ marginLeft: 1 }}>
                Main Bar, Spirit Cupboard
              </Typography>
            </div>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: { xs: 10, sm: 10, md: 12 }, marginRight: 1 }}
            >
              Products:
            </Typography>
            <div
              style={{
                maxWidth: "260px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography noWrap variant="body1" sx={{ marginLeft: 1 }}>
                Spirits/Gin/London Dry, Spirits/Gin/Flavored,
                Sprits/Gin/Botanical
              </Typography>
            </div>
          </Box>
        </Grid> */}
      </Box>
    </Grid>
  );
};

export default TemplateOption;
