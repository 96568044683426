import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  // Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";

import { cloneDeep } from "lodash";
import PurchaseDetailsTableRow from "./PurchaseDetailsTableRow";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SubdirectoryArrowRightOutlinedIcon from "@mui/icons-material/SubdirectoryArrowRightOutlined";
import DescriptionToolTip from "../../../../components/DescriptionToolTip";
import ProductSetupIllustrationModal from "../../../../components/Modals/ProductSetupIllustrationModal";
import utilFunctions from "../../../../utilities/utilFunctions";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const SizePurchaseDetailsTable = ({
  format,
  vendors,
  updateProductCallback,
  addPurchaseDetails,
  triggerError,
  expenseGroups,
  openExpenseGroupEdit,
  openExpenseSubgroupEdit,
  deletePurchaseUnit,
  isAddingPurchaseUnit,
  refetchCurrentPageProducts,
  subscriptionData,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [formatCopy, setFormatCopy] = useState({});
  const [productSetupModalOpen, toggleProductSetupModalOpen] = useState(false);

  useEffect(() => {
    setFormatCopy(format);
  }, [format]);

  const handleToggleProductSetupModal = () => {
    toggleProductSetupModalOpen(true);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const updateCallback = (purchaseDetail, defaultUpdated) => {
    const copyUpdate = cloneDeep(formatCopy);

    copyUpdate.purchaseDetails = copyUpdate.purchaseDetails.map((x) => {
      if (x.id === purchaseDetail.id) {
        return purchaseDetail;
      }

      if (defaultUpdated && x.isDefault) {
        return Object.assign({}, x, { isDefault: false });
      }

      return x;
    });
    setFormatCopy(copyUpdate);
    updateProductCallback(copyUpdate);
  };

  const handleAddProductDetails = () => {
    addPurchaseDetails(format.id);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "48px",
          marginBottom: 0,
          paddingBottom: 0,
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "48px",
              backgroundColor: (theme) => theme.palette.terrain[200],
              borderTop: "1px solid",
              borderColor: (theme) => theme.palette.terrain[400],
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              {/* CHECKBOX */}
              {/* <Grid
                item
                sx={{
                  width: "32px",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRight: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[400],
                  backgroundColor: (theme) => theme.palette.terrain[200],
                }}
              >
                <Checkbox
                  sx={{ color: (theme) => theme.palette.primary[800] }}
                />
              </Grid> */}
              <Grid
                item
                sx={{ backgroundColor: (theme) => theme.palette.terrain[200] }}
              >
                <SubdirectoryArrowRightOutlinedIcon
                  sx={{
                    color: (theme) => theme.palette.primary[800],
                    fontSize: 18,
                    marginLeft: 4,
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  marginLeft: 4,
                  backgroundColor: (theme) => theme.palette.terrain[200],
                }}
              >
                <Typography variant="body2" onClick={handleExpandClick}>
                  {utilFunctions.extractFormatSizingName(format)}{" "}
                  {format?.packagingType || ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Box>
                <Button
                  variant="text"
                  sx={{
                    marginRight: 2,
                  }}
                  onClick={handleAddProductDetails}
                  disabled={isAddingPurchaseUnit}
                >
                  <AddOutlinedIcon
                    sx={{
                      fontSize: "16px",
                      color: (theme) => theme.palette.primary[800],
                    }}
                  />
                  {isAddingPurchaseUnit ? (
                    <CircularProgress size={14} />
                  ) : (
                    <Typography
                      variant="smallButton"
                      sx={{ color: (theme) => theme.palette.primary[800] }}
                    >
                      Purchase Unit
                    </Typography>
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer>
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead sx={{ height: "24px" }}>
            <TableRow sx={{ height: 24 }}>
              <TableCell
                sx={{
                  borderLeft: "none",
                  borderTop: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[400],
                  background: (theme) => theme.palette.terrain[300],
                  padding: 0,
                  width: "80px",
                }}
              ></TableCell>
              <TableCell sx={[tableHeadStyle, { width: "287px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Purchase Unit Name
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Purchase Unit Name"
                      buttonText="Show Illustration"
                      buttonAction={handleToggleProductSetupModal}
                      description={
                        <Box>
                          The name of the quantity of Product Formats purchased.
                          Most establishments will only have two distinct
                          purchase units: <q>unit</q> or <q>bottle</q> to
                          indicate a purchase amount of 1, and <q>case</q> or{" "}
                          <q>pack</q> to indicate a purchase amount of 6 or 12,
                          depending on how the producer ships the product.
                          <br />
                          <br />
                          Purchase unit name is sent to reps when ordering, so
                          make sure the name is clear.
                          <br />
                          <br />
                          Purchase Unit Names must be distinct among Purchase
                          Units from the same vendor.
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Unit Amount
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Unit Amount"
                      buttonText="Show Illustration"
                      buttonAction={handleToggleProductSetupModal}
                      description={
                        <Box>
                          The number of units that get sent when the Purchase
                          Unit is ordered. For example, a Purchase Unit with a
                          name of <q>bottle,</q> <q>unit,</q> or <q>single</q>{" "}
                          should have a 1 in this field. If the Purchase Unit
                          Name is <q>case</q> or <q>package</q>, the unit amount
                          should be however many units are included.
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Price
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Price"
                      buttonText="Show Illustration"
                      buttonAction={handleToggleProductSetupModal}
                      description={
                        <Box>
                          The price paid for this purchase unit. If the Purchase
                          Unit is <q>bottle,</q> put the single bottle price. If
                          the Purchase Unit is <q>3 case deal,</q> put the price
                          of 3 cases.
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Cost Per
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Cost Per"
                      buttonText="Show Illustration"
                      buttonAction={handleToggleProductSetupModal}
                      description={
                        <Box>
                          This is basic costing info for the default unit of
                          measurement for each Product Format.
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Vendor
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Vendor"
                      description={
                        <Box>
                          Vendors are who you get your products from -- they can
                          be distributors, grocery stores, online stores, etc.
                          Note: vendor information is required in order to place
                          orders.
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  SKU
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="SKU"
                      description={
                        <Box>
                          The Stock Keeping Unit, or SKU, is the vendor&apos;s
                          internal identifying code for the product. This can be
                          found on most invoices. Purchase Units for the same
                          vendor often have the same SKU.
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "150px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Expense Group
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Expense Group"
                      description={
                        <Box>
                          Expense Groups are how purchases are categorized in
                          Reporting.
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "150px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Expense Subgroup
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Expense Subgroup"
                      description={
                        <Box>
                          Expense Subgroups are an additional categorization
                          layer beneath Expense Groups.
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "150px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Status
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Status"
                      description={<Box>Status of the Purchase Unit</Box>}
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                sx={[tableHeadStyle, { width: "40px", borderRight: "none" }]}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formatCopy.purchaseDetails?.map((x) => (
              <PurchaseDetailsTableRow
                key={x.id}
                productFormatId={formatCopy.id}
                purchaseDetails={x}
                updateProductCallback={updateCallback}
                vendors={vendors}
                triggerError={triggerError}
                expenseGroups={expenseGroups}
                openExpenseGroupEdit={openExpenseGroupEdit}
                openExpenseSubgroupEdit={openExpenseSubgroupEdit}
                deletePurchaseUnit={deletePurchaseUnit}
                refetchCurrentPageProducts={refetchCurrentPageProducts}
                subscriptionData={subscriptionData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ProductSetupIllustrationModal
        isOpen={productSetupModalOpen}
        toggleModalOpen={toggleProductSetupModalOpen}
      />
    </>
  );
};

export default SizePurchaseDetailsTable;
