import React, { useState } from "react";
import { modalBoxStyle } from "./styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const CreateExpenseGroupOrganizationModal = ({
  isOpen,
  toggleModalOpen,
  loading,
  modalHeader,
  modalText,
  placeholderText,
  buttonText,
  createExpenseGroup,
  createExpenseSubgroup
}) => {
  const [name, setName] = useState("");

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleSubmit = async () => {
    if (buttonText === "Create Expense Group") {
        await createExpenseGroup(name);
        closeModal();
    }
    if (buttonText === "Create Subgroup") {
        await createExpenseSubgroup(name);
        closeModal();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="h2">{modalHeader}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={closeModal}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Grid>
                <Grid item sx={{ marginTop: 4 }}>
                  <Typography variant="body1">{modalText}</Typography>
                </Grid>
              </Grid>
              <Box sx={{ marginTop: 8, width: "100%" }}>
                <TextField
                  size="small"
                  required
                  fullWidth
                  label="Name"
                  autoComplete="off"
                  onChange={handleNameChange}
                  placeholder={placeholderText}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                  onClick={handleSubmit}
                >
                  <Typography variant="largeButton">{buttonText}</Typography>
                </Button>
              </Box>
            </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default CreateExpenseGroupOrganizationModal;
