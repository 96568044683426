import {
  Box,
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SpecBackButton from "../../../../components/SpecBackButton";
import { useNavigate, useParams } from "react-router-dom";
import InvoiceActionsModal from "../../../../components/Modals/InvoiceActionsModal";
import { cloneDeep } from "lodash";
import ApprovedProductTable from "./ApprovedProductTable";
import KegTable from "./KegTable";
import MiscTable from "./MiscTable";
import AdditionalInfoTable from "./AdditionalInfoTable";
import { MoreHorizOutlined } from "@mui/icons-material";
import utilFunctions from "../../../../utilities/utilFunctions";
import Store from "../../../../Store/Store";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../../clients/inventoryClient";
import useEstablishmentSettings from "../../../../hooks/useEstablishmentSettings";
import AttachmentControl from "../../../../components/AttachmentControl";

var moment = require("moment");

const colorIndexMap = {
  0: { primary: "#B2E3F1", secondary: "#E0F4F9" },
  1: { primary: "#FCDDB1", secondary: "#FEF2DF" },
  2: { primary: "#FFCABB", secondary: "#FDE8E7" },
};

const invoiceActions = {
  unapprove: 1,
  delete: 2,
};

const invoiceActionContent = {
  1: {
    header: "Unapprove Invoice",
    body: "Are you sure you want to unapprove this invoice? Doing so will hide any invoice data from Reporting and suspend any links to your Orders until the invoice is re-approved.",
    buttonText: "Unapprove",
    icon: <UndoOutlinedIcon sx={{ fontSize: 64 }} />,
  },
  2: {
    header: "Delete Invoice",
    body: "Are you sure you want to delete this invoice?",
    buttonText: "Delete",
    icon: <DeleteOutlineOutlinedIcon sx={{ fontSize: 64 }} />,
  },
};

const ApprovedInvoice = ({ invoice, vendorMap, expenseGroups }) => {
  const [invoiceCopy, setInvoiceCopy] = useState({});
  const [orderColorMap, setOrderColorMap] = useState({});
  const [invoiceAction, setInvoiceAction] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isActionModalOpen, toggleIsActionModalOpen] = useState(false);
  const open = Boolean(anchorEl);
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { currencySymbol, currency, locale } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  let { establishmentid } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setInvoiceCopy(cloneDeep(invoice));

    const newColorMap = {};
    invoice?.orders?.forEach((x, i) => {
      newColorMap[x.id] = colorIndexMap[i];
    });
    setOrderColorMap(newColorMap);
  }, [invoice]);

  const calculateOrderCostDisplay = (order) => {
    let total = 0;

    invoiceCopy.lineItems?.forEach((x) => {
      if (x.orderLinkId === order.id) {
        total += x.cost.amount;
      }
    });

    return `${currencySymbol}${utilFunctions.convertCentsToLargerFormatCurrency(
      total,
      locale,
      currency
    )}`;
  };

  const handleOpenUnapprove = () => {
    setInvoiceAction(invoiceActions.unapprove);
    toggleIsActionModalOpen(true);
  };

  const handleOpenDelete = () => {
    setInvoiceAction(invoiceActions.delete);
    toggleIsActionModalOpen(true);
  };

  const unapproveInvoiceMutation = useMutation(
    inventoryClient.unapproveInvoice,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "invoice",
            {
              establishmentId: establishmentid,
              invoiceId: invoice.id,
            },
          ],
        });
        queryClient.refetchQueries({
          queryKey: [
            "invoice",
            {
              establishmentId: establishmentid,
              invoiceId: invoice.id,
            },
          ],
        });
      },
    }
  );

  const handleActionTaken = () => {
    if (invoiceAction === invoiceActions.unapprove) {
      unapproveInvoiceMutation.mutate({
        establishmentId: establishmentid,
        invoiceId: invoice.id,
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container direction="column" sx={{ width: "100%", marginTop: 4 }}>
        <Box>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid container direction="column">
              <Box>
                <SpecBackButton
                  backText="Invoices"
                  onClick={() => navigate(`/spec/${establishmentid}/invoices`)}
                />
              </Box>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 6,
                  width: "100%",
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={7}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography variant="h3" sx={{ marginLeft: 2 }}>
                      {moment(invoiceCopy.invoiceDate).format("MMMM DD, YYYY")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                      marginLeft: "auto",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: { xs: "space-between", md: "flex-end" },
                      alignItems: "center",
                      marginTop: { xs: 2, md: 0 },
                    }}
                  >
                    <AttachmentControl
                      invoice={invoice}
                      approvedInvoice={true}
                      size="small"
                      disableCrop
                    />
                    <IconButton
                      sx={{
                        marginLeft: 2,
                        width: "32px",
                        height: "32px",
                        border: "2px solid",
                        borderColor: (theme) => theme.palette.primary[800],
                      }}
                      onClick={handleClick}
                    >
                      <MoreHorizOutlined sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 1,
                    padding: 2,
                    height: "32px",
                    width: "fit-content",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.terrain[300],
                    backgroundColor: (theme) => theme.palette.pure.white,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ marginRight: 1, fontSize: { xs: 8, md: 12 } }}
                  >
                    Vendor:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.secondary[800],
                      fontSize: { xs: 8, md: 12 },
                    }}
                  >
                    {vendorMap[invoice.vendorIds[0]]?.name || "-"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 1,
                    padding: 2,
                    height: "32px",
                    width: "fit-content",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.terrain[300],
                    backgroundColor: (theme) => theme.palette.pure.white,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ marginRight: 1, fontSize: { xs: 8, md: 12 } }}
                  >
                    Invoice No:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.secondary[800],
                      fontSize: { xs: 8, md: 12 },
                    }}
                  >
                    {invoiceCopy.invoiceNumber}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 1,
                    padding: 2,
                    height: "32px",
                    width: "fit-content",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.terrain[300],
                    backgroundColor: (theme) => theme.palette.pure.white,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ marginRight: 1, fontSize: { xs: 8, md: 12 } }}
                  >
                    Invoice Total:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.secondary[800],
                      fontSize: { xs: 8, md: 12 },
                    }}
                  >
                    {currencySymbol}
                    {invoiceCopy.invoiceTotal &&
                      utilFunctions.convertCentsToLargerFormatCurrency(
                        invoiceCopy.invoiceTotal.amount,
                        locale,
                        currency
                      )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: 4,
            padding: 2,
            width: "100%",
            height: "fit-content",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            border: "2px solid",
            borderColor: (theme) => theme.palette.info[800],
            borderRadius: 2,
            backgroundColor: (theme) => theme.palette.info[100],
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: (theme) => theme.palette.info[800] }}
          >
            This invoice is currently Approved and protected from modification.
            To make changes, click the {"'"}more{"'"} button in the top right
            corner and Unapprove the invoice. This will allow you to make
            changes, then you can re-approve the invoice.
          </Typography>
        </Box>
        {/* Invoice Line Item Section */}
        <Box sx={{ marginTop: 6, width: "100%" }}>
          <Box>
            <Typography variant="h3">Invoice Line Items</Typography>
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Grid item sx={{ marginRight: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  Linked Orders:
                </Typography>
              </Grid>
              {invoiceCopy.orders?.map((x) => (
                <Grid key={x.id} item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "fit-content",
                      height: "24px",
                      borderRadius: 0.5,
                      padding: 2,
                      marginRight: 2,
                      backgroundColor: orderColorMap[x.id]?.primary,
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "#000" }}>
                      {moment(x.createdDate).format("MM/DD/YYYY")},{" "}
                      {calculateOrderCostDisplay(x)}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ marginTop: 4, width: "100%" }}>
              <ApprovedProductTable
                lineItems={invoiceCopy.lineItems || []}
                orderColorMap={orderColorMap}
                invoice={invoiceCopy}
                expenseGroups={expenseGroups}
              />
            </Box>
          </Box>
        </Box>

        {/* Keg Section */}
        {invoiceCopy.kegTransfers?.length > 0 ? (
          <Box sx={{ marginTop: 6 }}>
            <Box>
              <Typography variant="h3">Keg Transfers</Typography>
            </Box>
            <Box sx={{ marginTop: 4 }}>
              <KegTable
                invoice={invoiceCopy}
                kegTransfers={invoiceCopy.kegTransfers || []}
              />
            </Box>
          </Box>
        ) : null}

        {/* Misc Section */}
        {invoiceCopy.miscellaneousLineItems?.length > 0 ? (
          <Box sx={{ marginTop: 6 }}>
            <Box>
              <Typography variant="h3">Miscellaneous</Typography>
            </Box>
            <Box sx={{ marginTop: 4 }}>
              <MiscTable
                miscLineItems={invoiceCopy.miscellaneousLineItems || []}
                invoice={invoiceCopy}
                expenseGroups={expenseGroups}
              />
            </Box>
          </Box>
        ) : null}

        {/* Additional Info Section */}
        {invoiceCopy.additionalInformation?.length > 0 ? (
          <Box sx={{ marginTop: 6 }}>
            <Box>
              <Typography variant="h3">Additional Info</Typography>
            </Box>
            <Box sx={{ marginTop: 4 }}>
              <AdditionalInfoTable
                additionalInformation={invoiceCopy.additionalInformation}
              />
            </Box>
          </Box>
        ) : null}

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem sx={{ margin: "5px" }} onClick={handleOpenUnapprove}>
            <UndoOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
            <Typography variant="body1">Unapprove Invoice</Typography>
          </MenuItem>
          <MenuItem sx={{ margin: "5px" }} onClick={handleOpenDelete}>
            <DeleteOutlineOutlinedIcon sx={{ fontSize: 20, marginRight: 4 }} />
            <Typography variant="body1">Delete Invoice</Typography>
          </MenuItem>
        </Menu>
        <InvoiceActionsModal
          isOpen={isActionModalOpen}
          toggleModalOpen={toggleIsActionModalOpen}
          headerText={invoiceActionContent[invoiceAction]?.header}
          buttonText={invoiceActionContent[invoiceAction]?.buttonText}
          bodyText={invoiceActionContent[invoiceAction]?.body}
          icon={invoiceActionContent[invoiceAction]?.icon}
          handleActionTaken={handleActionTaken}
          isLoading={unapproveInvoiceMutation.isLoading}
        />
      </Grid>
    </Box>
  );
};

export default ApprovedInvoice;
