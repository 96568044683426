import React, { useEffect, useState, useContext } from "react";
import { modalBoxStyle } from "./styles";
import {
  CircularProgress,
  TextField,
  Button,
  Typography,
  Box,
  Modal,
  MenuItem,
  Divider,
} from "@mui/material";
import {
  getFlattenedEstablishmentCategories,
  updateBeer,
  updateRecipe,
  updateWine,
} from "../../clients/establishmentsClient";
import Store from "../../Store/Store";
import utilFunctions from "../../utilities/utilFunctions";

const FormInputLabelStyles = {
  shrink: true,
};

const MoveItemModal = ({
  titleType,
  item,
  moveCallback,
  isOpen,
  toggleModalOpen,
  errorCallback,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, toggleLoading] = useState(true);
  const [catDest, setCatDest] = useState("");
  const store = useContext(Store);

  useEffect(() => {
    const fetchFlatCategories = async () => {
      try {
        if (item) {
          const response = await getFlattenedEstablishmentCategories(
            store.currentEstablishment.id
          );
          setOptions(response.filter((x) => x.id != item.categoryId));
          toggleLoading(false);
        }
      } catch (err) {
        toggleLoading(false);
        console.log(err);
      }
    };

    fetchFlatCategories();
  }, [item]);

  const disabledButtonBackgroundColor = !catDest ? "#c6c6c6" : "";

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const moveItemByType = async (newItem) => {
    switch (newItem.type) {
      case "recipe":
        await updateRecipe(newItem);
        break;
      case "wine":
        await updateWine(newItem);
        break;
      case "beer":
        await updateBeer(newItem);
        break;
      default:
        throw "error";
    }
  };

  const setCategoryDestination = async (categoryId) => {
    if (categoryId === "Select Destination") {
      setCatDest(null);
    } else {
      setCatDest(categoryId);
    }
  };

  const handleMoveCallback = async () => {
    try {
      toggleLoading(true);
      item.categoryId = catDest;
      await moveItemByType(item);
      await moveCallback(item, catDest);
      toggleLoading(false);
    } catch (err) {
      toggleLoading(false);
      console.log(err);
      errorCallback("There was an error moving your item.");
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box>
              <Typography variant="h4" sx={{ marginBottom: 4 }}>
                Move Item {utilFunctions.capitalizeString(titleType)}
              </Typography>
              <Box sx={{ marginBottom: 8 }}>
                <Divider />
              </Box>
              <Box sx={{ marginBottom: 8 }}>
                <Typography variant="subtitle2">
                  Where would you like to copy {`"${item?.name}"`} to?
                </Typography>
              </Box>
              <Box sx={{ marginBottom: 8 }}>
                <TextField
                  size="small"
                  fullWidth
                  select
                  InputLabelProps={FormInputLabelStyles}
                  value={catDest}
                  label="Select Destination"
                  id="selectCat"
                  onChange={(event) =>
                    setCategoryDestination(event.target.value)
                  }
                >
                  {options.map((o) => (
                    <MenuItem key={o.id} value={o.id}>
                      {o.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 4,
                    paddingRight: 4,
                  }}
                  onClick={closeModal}
                >
                  <Typography variant="smallButton">Cancel</Typography>
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    background: `${disabledButtonBackgroundColor}`,
                    marginLeft: "1rem",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                  onClick={handleMoveCallback}
                  disabled={!catDest}
                >
                  <Typography variant="smallButton">Submit</Typography>
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default MoveItemModal;
