import React from "react";
import { Button, Typography } from "@mui/material";

const AddMissingDetailsButton = ({ text, onClick }) => {
  return (
    <Button
      variant="text"
      onClick={onClick}
      sx={{
        color: (theme) => theme.palette.primary[800],
        "&:hover": {
          color: (theme) => theme.palette.primary[100],
          background: "none",
        },
      }}
    >
      <Typography
        variant="body2"
        sx={{
          textTransform: "none",
          fontSize: "12px",
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};

export default AddMissingDetailsButton;
