import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { modalBoxStyle } from "../styles";
import {
  CircularProgress,
  Button,
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import utilFunctions from "../../../utilities/utilFunctions";
import MuiPhoneNumber from "material-ui-phone-number";
import moment from 'moment';
import { isValidPhoneNumber } from 'libphonenumber-js';

const EditUserProfileModal = ({
  isOpen,
  toggleModalOpen,
  loading,
  profile,
  onSaveCallback
}) => {
  // const store = useContext(Store);
  const [dateValue, setDateValue] = useState(null);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  useEffect(() => {
    setDateValue(profile.birthday);
    reset();
  }, [isOpen]);

  const closeModal = () => {
    toggleModalOpen(false);
  };

  const FormInputLabelStyles = {
    shrink: true,
  };

  const onSubmit = (data) => {
    let err = false;
    if (!moment(data.birthday).isValid()) {
      setError("birthday", {
        type: "manual",
        message: "Please set a valid birthday"
      });
      err = true;
    }

    if (!isValidPhoneNumber(data.phone, 'US') === true && !isValidPhoneNumber(data.phone, 'GB')) {
      setError("phone", {
        type: "manual",
        message: "Please set a valid phone number"
      });
      err = true;
    }

    if (err) {
      return;
    }

    onSaveCallback({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      birthday: moment(data.birthday).format("MM/DD/YYYY")
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="h2">Edit Account Details</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={closeModal}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  marginTop: 6,
                  marginBottom: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                  Personal Details
                </Typography>
              </Box>
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 4,
                }}
              >
                <Grid
                  item
                  sx={{
                    width: "48%",
                  }}
                >
                  <TextField
                    size="small"
                    label="First Name"
                    fullWidth
                    placeholder="Enter First Name"
                    defaultValue={profile.firstName}
                    autoComplete="off"
                    InputLabelProps={FormInputLabelStyles}
                    onFocus={utilFunctions.highlightInputOnFocus}
                    sx={{ height: "40px" }}
                    {...register("firstName", {
                      required: true,
                      message: "Please enter a first name",
                    })}
                  />
                  {errors.firstName ? (
                    <Box
                      sx={{
                        color: "red",
                        marginTop: "0px",
                        height: 10,
                      }}
                    >
                      First name required
                    </Box>
                  ) : null}
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "48%",
                  }}
                >
                  <TextField
                    {...register("lastName", {
                      required: true,
                      message: "Last name required",
                    })}
                    size="small"
                    label="Last Name"
                    fullWidth
                    placeholder="Enter Last Name"
                    defaultValue={profile.lastName}
                    autoComplete="off"
                    InputLabelProps={FormInputLabelStyles}
                    onFocus={utilFunctions.highlightInputOnFocus}
                    sx={{ height: "40px" }}
                  />
                  {errors.lastName ? (
                    <Box
                      sx={{
                        color: "red",
                        marginTop: "0px",
                        height: 10,
                      }}
                    >
                      Last name required
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
              {/* EMAIL */}
              <Box sx={{ marginTop: 4, width: "100%" }}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Email"
                  label="Email"
                  autoComplete="off"
                  defaultValue={profile?.email}
                  InputLabelProps={FormInputLabelStyles}
                  onFocus={utilFunctions.highlightInputOnFocus}
                  sx={{ height: "40px" }}
                  {...register("email", {
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email address",
                    },
                  })}
                />
                {errors.email ? (
                  <Box
                    sx={{
                      color: "red",
                      marginTop: "0px",
                      height: 10,
                    }}
                  >
                    Please enter a valid email address
                  </Box>
                ) : (
                  <Box style={{ height: 0, marginTop: "0px" }}>{""}</Box>
                )}
              </Box>
              {/* DOB */}
              <Box sx={{ marginTop: 4, width: "100%" }}>
                <Controller
                  valueName="selected"
                  control={control}
                  name="birthday"
                  rules={{
                    required: true
                  }}
                  defaultValue={profile.birthday}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                      {...field}
                        dateFormat="mm/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        label="Date of Birth"
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            variant="outlined"
                            InputLabelProps={FormInputLabelStyles}
                            label="Date of Birth"
                            autoComplete="off"
                            onFocus={utilFunctions.highlightInputOnFocus}
                            fullWidth
                            {...params}
                            sx={{
                              height: "40px",
                            }}
                            value={dateValue}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                {errors.birthday ? (
                  <Box
                    sx={{
                      color: "red",
                      marginTop: "0px",
                      height: 10,
                    }}
                  >
                    Please enter a valid birthday
                  </Box>
                ) : (
                  null
                )}
              </Box>
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 4,
                }}
              >
                <Controller
                name="phone"
                control={control}
                defaultValue={profile.phone || ''}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <MuiPhoneNumber
                  {...field}
                    size="small"
                    sx={{width: "100%"}}
                    defaultCountry={profile?.country || 'us'}
                    disableAreaCodes
                    label="Mobile Number"
                    placeholder="Enter remaining digits"
                    InputLabelProps={FormInputLabelStyles}
                    variant="outlined"
                  />
                )}
              />
              {errors.phone ? (
                  <Box
                    sx={{
                      color: "red",
                      marginTop: "0px",
                      height: 10,
                    }}
                  >
                    Please enter a valid phone number
                  </Box>
                ) : (
                  null
                )}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "300px",
                    height: "40px",
                    borderRadius: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                  type="submit"
                >
                  <Typography variant="largeButton">Save Details</Typography>
                </Button>
              </Box>
            </Grid>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default EditUserProfileModal;
