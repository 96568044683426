import React from "react";
import { modalBoxStyle } from "../styles";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ProductSetupIllustration from "../../CustomIcons/ProductSetupIllustration";

const ProductSetupIllustrationModal = ({ isOpen, toggleModalOpen }) => {
  const closeModal = () => {
    toggleModalOpen(!isOpen);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[modalBoxStyle, { width: { xs: "340px", md: "400px" } }]}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={closeModal}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
        <Box>
          <ProductSetupIllustration sx={{ fontSize: 340 }} />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <Typography variant="h3">Product Setup</Typography>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            If there is anything you are still unsure of, please visit our
            glossary or feel free to reach out and ask us any questions you may
            have.
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button component={"a"} href="https://www.specapp.com/contact-us" variant="outlined" sx={{ width: "50%", marginRight: 1 }}>
            Contact Us
          </Button>
          <Button component={"a"} href="https://www.specapp.com/az-glossary" variant="contained" sx={{ width: "50%", marginLeft: 1 }}>
            Go to Glossary
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductSetupIllustrationModal;
