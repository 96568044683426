import React, { useState, useEffect } from "react";
import { modalBoxStyle } from "../styles";
import { CircularProgress, Box, Modal } from "@mui/material";
import inventoryObjects from "../../../clients/inventoryObjects";
import InitCountForm from "./components/InitCountForm";
import useStockCountTemplates from "../../../hooks/useStockCountTemplates";
import { useParams } from "react-router-dom";
import TemplateSelection from "./components/TemplateSelection";
var moment = require("moment");

const StockCountModal = ({ isOpen, toggleModalOpen, addStockCount, isLoading }) => {
  const { establishmentid: establishmentId } = useParams();
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [countName, setCountName] = useState(
    `${moment().format("MM/DD/YYYY")} - Count`
  );
  const [countType, setCountType] = useState(
    inventoryObjects.stockCountTypes.inventoryAudit
  );
  const [currentStep, setCurrentStep] = useState(0);
  const { data: templateData, isLoading: isLoadingTemplates } =
    useStockCountTemplates(establishmentId);

  const handleNameChange = (e) => {
    setCountName(e.target.value);
  };

  useEffect(() => {
    setCountName(`${moment().format("MM/DD/YYYY")} - Count`);
  }, [isOpen]);

  const closeModal = () => {
    setCurrentStep(0);
    setCountType(inventoryObjects.stockCountTypes.inventoryAudit);
    toggleModalOpen(false);
  };

  const handleAction = () => {
    if (countType === inventoryObjects.stockCountTypes.inventoryAudit) {
      addStockCount(countType, countName);
    } else if (currentStep === 0) {
      setCurrentStep(1);
    } else {
      addStockCount(countType, countName, selectedTemplateId);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={[modalBoxStyle, { maxWidth: "420px", maxHeight: { xs: "500px", md: "600px"}}]}>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {(() => {
              switch (currentStep) {
                case 0:
                  return (
                    <InitCountForm
                      closeModal={closeModal}
                      countName={countName}
                      countType={countType}
                      handleNameChange={handleNameChange}
                      handleNextAction={handleAction}
                      setCountType={setCountType}
                    />
                  );
                case 1:
                  return (
                    <TemplateSelection
                      templates={templateData?.countTemplates}
                      closeModal={closeModal}
                      handleNextAction={handleAction}
                      isLoadingTemplates={isLoadingTemplates}
                      setCurrentStep={setCurrentStep}
                      selectedTemplateId={selectedTemplateId}
                      setSelectedTemplateId={setSelectedTemplateId}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default StockCountModal;
