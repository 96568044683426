import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MessageCard from "./MessageCard";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const MessagesList = ({
  error,
  loading,
  messages,
  setActiveMessage,
  activeMessage,
  currentPaginationToken,
  loadMoreMessages,
  deleteModalCallback,
}) => {
  const [loadingMore, setLoadingMore] = useState(false);

  const handleShowMoreMessages = async () => {
    setLoadingMore(true);
    await loadMoreMessages();
    setLoadingMore(false);
  };

  // useEffect(() => {}, messages);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    );
  } else if (error) {
    return (
      <Alert color="danger">
        Something went wrong with loading your messages. Please refresh the
        page.
      </Alert>
    );
  } else if (messages.length === 0) {
    return (
      <Grid direction="column" container justifyContent="center">
        <Box sx={{ margin: "auto", marginTop: 20 }}>
          <MailOutlineIcon style={{ fontSize: "72px" }} />
        </Box>
        <Typography variant="h4" sx={{ margin: "auto", marginTop: 4 }}>
          No Messages Sent Yet
        </Typography>
        <Box sx={{ padding: 8, marginTop: 1 }}>
          <Typography
            variant="body1"
            sx={{ margin: "auto", textAlign: "center" }}
          >
            You have not sent any messages to your team yet. This could be
            something new, something old, or something for sharing&apos;s sake.
            It&apos;s good to stay updated.
          </Typography>
        </Box>
      </Grid>
    );
  }

  return (
    <Box>
      {messages.map((m) => (
        <MessageCard
          message={m}
          activeMessage={activeMessage}
          setActiveMessage={setActiveMessage}
          key={m.id}
          deleteModalCallback={deleteModalCallback}
        />
      ))}

      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {currentPaginationToken && (
          <Button
            variant="contained"
            sx={{ borderRadius: 2, marginTop: 2, marginBottom: 2 }}
            onClick={handleShowMoreMessages}
          >
            <Typography variant="smallButton">
              {loadingMore ? <CircularProgress color="inherit" /> : "Show More"}
            </Typography>
          </Button>
        )}
      </span>
    </Box>
  );
};

export default MessagesList;
