import React, { useContext, useEffect, useState } from "react";
// import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Alert, Typography } from "@mui/material";
// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CTABlock from "../../components/CTABlock";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useMutation, useQuery, useQueryClient } from "react-query";
import inventoryClient from "../../clients/inventoryClient";
import SpecSpinner from "../../components/SpecSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { red } from "@mui/material/colors";
import OptionCard from "../../components/OptionCard";
import AreaCountDetails from "./components/AreaCountDetails";
import SpecBackButton from "../../components/SpecBackButton";
import Store, { activeRouteKeys } from "../../Store/Store";
import StockCountFooter from "./components/StockCountFooter";
import inventoryObjects from "../../clients/inventoryObjects";
import ForceStockCountCompletionModal from "../../components/Modals/ForceStockCountCompletionModal";
import CreateAreaModal from "../../components/Modals/CreateAreaModal.js";
import UnorganizedAreaCountDetails from "./components/UnorganizedAreaCountDetails";

const StockCountDetails = () => {
  const [creatingArea, toggleCreatingArea] = useState(false);
  const [activeArea, setActiveArea] = useState(undefined);
  const [areaCountId, setAreaCountId] = useState(undefined);
  const [unorganizedAreaCount, setUnorganizedAreaCount] = useState([]);
  const [organizedAreaCounts, setOrganizedAreaCounts] = useState([]);
  const [completeInventoryAuditResponse, setResponse] = useState(null);
  const [shouldPromptForForceCompletion, togglePromptForceCompletion] =
    useState(false);
  const [isCompletionError, toggleIsCompletionError] = useState(false);
  const store = useContext(Store);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  let { establishmentid, stockcountid } = useParams();
  const { isLoading, isError, data, refetch } = useQuery(
    ["areas", { establishmentId: establishmentid, stockCountId: stockcountid }],
    inventoryClient.getStockCountById,
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  const stockCount = data?.stockCount;

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.stockCount,
      `spec/${establishmentid}/stockcount/${stockcountid}`
    );
  }, []);

  useEffect(() => {
    const getOrganizedStockCounts = stockCount?.areaCounts?.filter(
      (x) => x.areaId !== null
    );
    const getUnorganizedAreaCount = stockCount?.areaCounts?.filter(
      (x) => x.areaId === null
    );
    setOrganizedAreaCounts(getOrganizedStockCounts);
    if (getUnorganizedAreaCount) {
      setUnorganizedAreaCount(getUnorganizedAreaCount[0]);
    }
  }, [data]);

  const completeInventoryAuditMutation = useMutation(
    inventoryClient.completeInventoryAudit,
    {
      onSuccess: (res) => {
        toggleIsCompletionError(false);
        if (res.isValid === false) {
          setResponse(res.validationResult);
          togglePromptForceCompletion(true);
        } else {
          queryClient.invalidateQueries({
            queryKey: ["past-stockcounts"],
          });
          queryClient.refetchQueries({
            queryKey: ["past-stockcounts"],
          });
          queryClient.invalidateQueries({
            queryKey: ["active-stockcounts"],
          });
          queryClient.refetchQueries({
            queryKey: ["active-stockcounts"],
          });
          navigate(
            `/spec/${store.currentEstablishment?.id}/count-review/${stockcountid}?congrats`
          );
        }
      },
      onError: (error) => {
        if (error.response?.status === 422) {
          setResponse(error?.response?.data?.validationResult);
          togglePromptForceCompletion(true);
        } else {
          console.log(error);
          toggleIsCompletionError(true);
        }
      },
    }
  );

  const createAreaMutation = useMutation(inventoryClient.createArea, {
    onSuccess: (result) => {
      const existingAreas = queryClient.getQueryData([
        "areas",
        { establishmentId: store.currentEstablishment?.id },
      ]).areas;
      queryClient.setQueryData(
        ["areas", { establishmentId: store.currentEstablishment?.id }],
        { areas: [...existingAreas, result.area] }
      );
      toggleCreatingArea(false);
    },
  });

  const handleCreateArea = async (areaName) => {
    createAreaMutation.mutate({
      establishmentId: store.currentEstablishment?.id,
      name: areaName,
    });
  };

  const handleAreaCreateToggle = () => {
    toggleCreatingArea(!creatingArea);
  };

  const handleComplete = (forceCompletion) => {
    toggleIsCompletionError(false);
    completeInventoryAuditMutation.mutate({
      establishmentId: establishmentid,
      stockCountId: stockcountid,
      forceComplete: forceCompletion ? true : false,
    });
  };

  const completeAreaCallback = () => {
    refetch();
    setActiveArea(undefined);
    setAreaCountId(undefined);
  };

  const handleAreaSelect = (countId, areaId) => {
    setAreaCountId(countId);
    setActiveArea(areaId || -1);
  };

  const handleGoBackFromAreaDetails = () => {
    setAreaCountId(undefined);
    setActiveArea(undefined);
  };

  if (isLoading) {
    return <SpecSpinner open text="Loading count details..." />;
  }

  if (completeInventoryAuditMutation.isLoading) {
    return <SpecSpinner open text="Wrapping up your stock count" />;
  }

  if (activeArea || areaCountId) {
    return activeArea !== -1 ? (
      <AreaCountDetails
        areaId={activeArea}
        areaCountId={areaCountId}
        goBackToAreaList={handleGoBackFromAreaDetails}
        completeCallback={completeAreaCallback}
        stockCountId={stockcountid}
        stockCountName={data?.stockCount.name}
        stockCountType={data?.stockCount.type}
      />
    ) : (
      ""
    );
  }

  const MainIconSx = (status) => {
    if (status === inventoryObjects.StockCountStatuses.Complete) {
      return {
        color: (theme) => theme.palette.success[600],
        fontSize: { xs: 22, md: 30 },
      };
    } else if (status === inventoryObjects.StockCountStatuses.inProgress) {
      return {
        color: (theme) => theme.palette.info[600],
        fontSize: { xs: 22, md: 30 },
      };
    } else {
      return {
        color: (theme) => theme.palette.terrain[900],
        fontSize: { xs: 22, md: 30 },
      };
    }
  };

  const AreaCompleteSx = (status) => {
    if (status === inventoryObjects.StockCountStatuses.Complete) {
      return {
        borderColor: (theme) => theme.palette.success[600],
      };
    } else if (status === inventoryObjects.StockCountStatuses.inProgress) {
      return {
        borderColor: (theme) => theme.palette.info[600],
      };
    } else {
      return {
        borderColor: (theme) => theme.palette.terrain[300],
      };
    }
  };

  const DescriptionCompleteSx = (status) => {
    if (status === inventoryObjects.StockCountStatuses.Complete) {
      return {
        borderColor: (theme) => theme.palette.success[600],
        color: (theme) => theme.palette.success[600],
        backgroundColor: (theme) => theme.palette.success[50],
      };
    } else if (status === inventoryObjects.StockCountStatuses.inProgress) {
      return {
        borderColor: (theme) => theme.palette.info[600],
        color: (theme) => theme.palette.info[600],
        backgroundColor: (theme) => theme.palette.info[50],
      };
    } else {
      return {
        borderColor: (theme) => theme.palette.terrain[300],
      };
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          maxWidth: "2440px",
          width: "100%",
          height: `calc(100% - 70px)`,
          overflow: "scroll",
          padding: 4,
          paddingBottom: "100px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginTop: 6,
        }}
      >
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <SpecBackButton
            backText={"Stock Counts"}
            onClick={() =>
              navigate(`/spec/${store.currentEstablishment?.id}/stockcount`)
            }
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100px",
              height: "20px",
              padding: "2px",
              backgroundColor: (theme) => theme.palette.info[800],
              borderRadius: "2px",
              marginTop: 2,
            }}
          >
            <Typography
              variant="overline"
              sx={{
                color: (theme) => theme.palette.pure.white,
                fontWeight: 500,
              }}
            >
              Inventory Audit
            </Typography>
          </Box>
        </Grid>
        {isCompletionError ? (
          <Alert variant="outlined" severity="error">
            Error completing your Stock Count
          </Alert>
        ) : null}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "flex-start", height: "100%" }}
        >
          {stockCount ? (
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 2,
              }}
            >
              <Grid
                container
                sx={{
                  marginBottom: 8,
                  paddingRight: 2,
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="h2">
                    {stockCount.name || "[No Name]"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "flex-start", md: "flex-end" },
                    marginTop: { xs: 4, md: 0 },
                  }}
                >
                  {/* <Button
                    variant="contained"
                    onClick={handleAreaCreateToggle}
                    sx={{ borderRadius: 2, padding: 2.5 }}
                  >
                    <AddOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                    <Typography variant="smallButton">
                      Create New Area
                    </Typography>
                  </Button> */}
                </Grid>
              </Grid>

              <Box>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {isError && (
                    <Typography color={red}>
                      There was an error retrieving your stock count. Please try
                      again.
                    </Typography>
                  )}
                  {organizedAreaCounts &&
                    organizedAreaCounts.map((x) => (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={4}
                        lg={3}
                        xl={3}
                        key={x.id}
                        sx={{ marginBottom: { xs: 6, sm: 6, md: 1 } }}
                      >
                        <OptionCard
                          displayText={x.name}
                          handleClick={() => handleAreaSelect(x.id, x.areaId)}
                          MainIcon={
                            x.status ===
                            inventoryObjects.StockCountStatuses.Complete
                              ? CheckOutlinedIcon
                              : LocationOnOutlinedIcon
                          }
                          mainIconSx={() => MainIconSx(x.status)}
                          areaCompleteSx={() => AreaCompleteSx(x.status)}
                          descriptionCompleteSx={() =>
                            DescriptionCompleteSx(x.status)
                          }
                          status={x.status}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
              {unorganizedAreaCount && (
                <UnorganizedAreaCountDetails
                  areaCountId={unorganizedAreaCount.id}
                  completeCallback={completeAreaCallback}
                  stockCountName={data?.stockCount.name}
                  stockCountType={data?.stockCount.type}
                />
              )}
            </Grid>
          ) : (
            <Grid sx={{ padding: "20px" }}>
              <Box sx={{ paddingLeft: 5, marginBottom: 5 }}>
                <Typography variant="h2">
                  {stockCount.name || "[No Name]"}
                </Typography>
              </Box>
              <CTABlock
                header="You have not organized any areas with your products."
                subheader="Set up your areas in order to perform a inventory audit"
                hideButton
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <StockCountFooter
        buttonText="Complete"
        submit={() => handleComplete()}
        handleBack={() =>
          navigate(`/spec/${store.currentEstablishment?.id}/stockcount`)
        }
      />
      <ForceStockCountCompletionModal
        isOpen={shouldPromptForForceCompletion}
        cancelCallback={() => togglePromptForceCompletion(false)}
        completeCallback={() => handleComplete(true)}
        response={completeInventoryAuditResponse}
      />
      <CreateAreaModal
        isOpen={creatingArea}
        toggleModalOpen={handleAreaCreateToggle}
        headerText="Create New Area"
        buttonText="Create Area"
        createArea={handleCreateArea}
        loading={createAreaMutation.isLoading}
      />
    </>
  );
};

export default StockCountDetails;
