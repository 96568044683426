import React, { useContext, useEffect, useState } from "react";
import Store from "../../Store/Store";
import {
  getEstablishmentCategories,
  getEstablishment,
} from "../../clients/establishmentsClient";
import ReactGA from "react-ga";
import utilFunctions from "../../utilities/utilFunctions";
import { Typography } from "@mui/material";
import MenusDisplay from "./components/MenusDisplay";
import { useNavigate, useParams } from "react-router-dom";
import SpecSpinner from "../../components/SpecSpinner";

const Homebase = () => {
  const store = useContext(Store);
  const [establishment, updateEstablishment] = useState(undefined);
  const [loading, toggleLoading] = useState(true);
  const [isError, toggleError] = useState(false);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  let { establishmentid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (!establishmentid || !store.currentEstablishment) {
      navigate("/establishments");
    }

    if (!store.currentEstablishment?.isLegacyStructure) {
      navigate(`/spec/${store.currentEstablishment?.id}/dashboard`);
    }

    const fetchEstablishmentData = async () => {
      try {
        toggleLoading(true);
        setSpinnerOpen(true);
        const establishmentCategories = await getEstablishmentCategories(
          establishmentid
        );

        let currentEstablishment;

        if (
          !store.currentEstablishment ||
          store.currentEstablishment.id !== establishmentid
        ) {
          currentEstablishment = await getEstablishment(establishmentid);
          store.updateCurrentEstablishment(currentEstablishment);
        } else {
          currentEstablishment = store.currentEstablishment;
        }

        const cats = utilFunctions.sortByNameAlphabetically(
          establishmentCategories
        );
        const establishmentData = Object.assign({}, currentEstablishment, {
          categories: cats,
        });
        updateEstablishment(establishmentData);
        toggleLoading(false);
        setSpinnerOpen(false);
      } catch (err) {
        console.log(err);
        toggleError(true);
        toggleLoading(false);
        setSpinnerOpen(false);
      }
    };

    fetchEstablishmentData();
  }, [location.pathname, store.currentEstablishment]);

  const selectCategory = (id) => {
    const categoryNode = establishment.categories.find((x) => x.id === id);
    navigate(id, { state: { menu: categoryNode } });
  };

  if (loading) {
    return (
      <SpecSpinner
        open={spinnerOpen}
        text="Loading establishment information..."
      />
    );
  }

  if (isError) {
    return (
      <Typography
        variant="h2"
        sx={{ color: (theme) => theme.palette.error.main }}
      >
        There was an error fetching your establishments. Please try refreshing.
      </Typography>
    );
  }
  return (
    <>
      <MenusDisplay
        selectCategory={selectCategory}
        menus={establishment.categories}
      />
    </>
  );
};

export default Homebase;
