import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const RecipeRowItem = ({
  ingredient,
  editIngredient,
  removeIngredient,
  isInvalidIngredient,
}) => {
  const handleEditIngredient = (e) => {
    editIngredient(ingredient.id, e.target.id, e.target.value);
  };

  return (
    <Grid item>
      <Grid
        container
        direction="row"
        spacing={4}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 4,
        }}
      >
        <Grid item xs={2}>
          <TextField
            size="small"
            fullWidth
            id="amount"
            label="optional"
            value={ingredient.amount}
            onChange={handleEditIngredient}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            fullWidth
            id="unit"
            label="optional"
            value={ingredient.unit}
            onChange={handleEditIngredient}
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            size="small"
            fullWidth
            required
            id="name"
            label="Required"
            value={ingredient.name}
            error={isInvalidIngredient}
            onChange={handleEditIngredient}
          />
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="outlined"
            sx={{
              height: "100%",
              borderColor: "rgba(0,0,0,0.23) !important",
              borderRadius: 2,
            }}
            onClick={() => removeIngredient(ingredient.id)}
          >
            <DeleteOutlineIcon sx={{ color: "red" }} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RecipeRowItem;
