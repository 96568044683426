import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { useForm } from "react-hook-form";
import { createProfile } from "../../../clients/authClient";
import LocationDetails from "./LocationDetails";
import PersonalDetails from "./PersonalDetails";
import AddressDetails from "./AddressDetails";
import { useQueryClient } from "react-query";

function getSteps() {
  return ["Location-specific Setup", "Personal Details", "Address Details"];
}

const StepHeadingStyle = {
  alignSelf: "left",
  marginBottom: 2,
};

const CreateHeadingStepStyle = {
  marginBottom: "16px",
};

const body1Style = {
  marginBottom: "14px",
};

const h2Style = {
  marginBottom: "24px",
};

const subtitle1Style = {
  marginBottom: "24px",
};

const ButtonBox = {
  display: "flex",
  justifyContent: "center",
  marginTop: "24px",
};

const BackButtonStyle = {
  background: "#ffffff",
  border: "1px solid #000000",
  borderRadius: "8px",
  marginRight: "16px",
  padding: "8px 64px",
  color: "#000000",
  boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25)",
};

const ProfileForm = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [loading, toggleLoading] = useState(false);
  const [error, toggleError] = useState(false);
  const steps = getSteps();
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    render,
    control,
    formState: { isValid, errors },
  } = useForm({ mode: "all" });

  const disabledLocationBackgroundButtonColor = !selectedCountry
    ? "#c6c6c6"
    : "";

  const LocationNextButtonStyle = {
    background: `${disabledLocationBackgroundButtonColor}`,
    padding: "8px 48px",
    boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    marginLeft: "16px",
  };

  const disabledButtonBackgroundColor = !isValid ? "#c6c6c6" : "";

  const NextButtonStyle = {
    background: `${disabledButtonBackgroundColor}`,
    padding: "8px 48px",
    boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    marginLeft: "16px",
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmitForm = async (data) => {
    data.country = selectedCountry;
    try {
      toggleError(false);
      toggleLoading(true);
      await createProfile(data);
      queryClient.invalidateQueries({
        queryKey: ["userProfile"],
      });
      queryClient.refetchQueries({
        queryKey: ["userProfile"],
      });
      toggleLoading(false);
      navigate("/establishments");
    } catch (err) {
      toggleError(true);
      console.log(error);
    }
    toggleLoading(false);
  };

  const renderButton = () => {
    if (activeStep > 2) {
      return undefined;
    } else if (activeStep === 2) {
      return (
        <Box sx={ButtonBox}>
          <Button variant="contained" sx={BackButtonStyle} onClick={handleBack}>
            <Typography variant="smallButton">Back</Typography>
          </Button>
          <Button
            variant="contained"
            sx={NextButtonStyle}
            type="submit"
            disabled={!isValid}
          >
            <Typography variant="smallButton">
              {loading ? <CircularProgress color="inherit" /> : "Complete"}
            </Typography>
          </Button>
        </Box>
      );
    } else if (activeStep === 1) {
      return (
        <Box sx={ButtonBox}>
          <Button variant="contained" sx={BackButtonStyle} onClick={handleBack}>
            <Typography variant="smallButton">Back</Typography>
          </Button>
          <Button
            type="button"
            variant="contained"
            disabled={!isValid}
            sx={NextButtonStyle}
            onClick={handleNext}
          >
            <Typography variant="smallButton">Next Step</Typography>
          </Button>
        </Box>
      );
    } else {
      return (
        <Box sx={ButtonBox}>
          <Button
            variant="contained"
            disabled={!selectedCountry}
            sx={LocationNextButtonStyle}
            onClick={handleNext}
          >
            <Typography variant="smallButton">Next Step</Typography>{" "}
          </Button>
        </Box>
      );
    }
  };

  const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "rgb(8, 14, 64)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundColor: theme.palette.success.light,
      border: "1px solid #1BB55B",
      color: theme.palette.success.main,
    }),
  }));

  function CustomStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: completed ? <CheckOutlinedIcon /> : <EditLocationAltIcon />,
      2: completed ? <CheckOutlinedIcon /> : <PermIdentityOutlinedIcon />,
      3: completed ? <CheckOutlinedIcon /> : <HomeWorkOutlinedIcon />,
    };

    return (
      <CustomStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </CustomStepIconRoot>
    );
  }

  return (
    <Box sx={{ marginTop: 14, marginBottom: 14 }}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Paper
          sx={{
            padding: 6,
            borderRadius: 4,
            maxWidth: { xs: "360px", md: "690px" },
            margin: "auto",
          }}
          elevation={4}
        >
          <Box
            sx={{
              width: { xs: "300px", md: "450px" },
              alignSelf: "center",
              margin: "auto",
              marginBottom: 6,
            }}
          >
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      StepIconComponent={CustomStepIcon}
                      {...labelProps}
                    ></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>

          {activeStep === 0 && (
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={StepHeadingStyle}>
                  <Typography variant="overline" sx={body1Style}>
                    Create Your Profile:{" "}
                    <Typography
                      variant="overline"
                      sx={{ color: (theme) => theme.palette.info.main }}
                    >
                      Step 01
                    </Typography>
                  </Typography>
                  <Typography variant="h2" sx={h2Style}>
                    Location-specific Setup
                  </Typography>
                  <Typography variant="body1" sx={subtitle1Style}>
                    Please select the country of which you are based.
                  </Typography>
                </Box>
                <Box sx={{ margin: "auto" }}>
                  <LocationDetails
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    register={register}
                    control={control}
                    setValue={setValue}
                  />
                </Box>
              </Box>
            </div>
          )}
          {activeStep === 1 && (
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={StepHeadingStyle}>
                  <Typography variant="overline" sx={body1Style}>
                    Create Your Profile:{" "}
                    <Typography
                      variant="overline"
                      sx={{ color: (theme) => theme.palette.info.main }}
                    >
                      Step 02
                    </Typography>
                  </Typography>
                  <Typography variant="h2" sx={h2Style}>
                    Personal Details
                  </Typography>
                  <Typography variant="body1" sx={subtitle1Style}>
                    Please enter your personal details, this does NOT have to be
                    associated with the establishment.
                  </Typography>
                </Box>
                <Stack sx={{ alignSelf: "center", marginBottom: "24px" }}>
                  <PersonalDetails
                    selectedCountry={selectedCountry}
                    register={register}
                    control={control}
                    name
                    render={render}
                    errors={errors}
                  />
                </Stack>
                <Box sx={ButtonBox}></Box>
              </Box>
            </div>
          )}
          {activeStep === 2 && (
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={StepHeadingStyle}>
                  <Box sx={CreateHeadingStepStyle}>
                    <Typography variant="overline" sx={body1Style}>
                      Create Your Profile:{" "}
                      <Typography
                        variant="overline"
                        sx={{ color: (theme) => theme.palette.info.main }}
                      >
                        Step 03
                      </Typography>
                    </Typography>
                  </Box>

                  <Typography variant="h2" sx={h2Style}>
                    Address Details
                  </Typography>
                  <Typography variant="body1" sx={subtitle1Style}>
                    Please enter your personal Address Details, this does NOT
                    have to be associated with the establishment.
                  </Typography>
                </Box>
                <Stack
                  sx={{
                    alignSelf: "center",
                    width: "100%",
                    marginBottom: "24px",
                  }}
                >
                  <AddressDetails register={register} />
                </Stack>
              </Box>
            </div>
          )}
          {renderButton()}
        </Paper>
      </form>
    </Box>
  );
};

export default ProfileForm;
