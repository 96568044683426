import {
  Box,
  Typography,
} from "@mui/material";
import React from "react";

const displayMap = {
  1: 'Volumetric',
  2: 'Mass',
  3: 'Arbitrary'
}

const UnitSizeDisplayRow = ({ unitSize }) => {
  return (
    <Box sx={{ borderWidth: 1, borderColor: 'purple'}}>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2}}>
        <Typography>Size: {unitSize.size || 'NOT SET'}{" "}</Typography>
        <Typography>{unitSize.unitOfMeasurement?.name}</Typography>
      </Box>
      <Box sx={{ marginBottom: 2}}>
      <Typography>Is Keg Size: {unitSize.isKegSize?.toString()}</Typography>
        <Typography>Type: {displayMap[unitSize.type]}</Typography>
      </Box>
    </Box>
  );
};

export default UnitSizeDisplayRow;
