import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import InvoiceDetailRow from "./InvoiceDetailRow";
import DescriptionToolTip from "../../../components/DescriptionToolTip";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  borderBottom: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const InvoiceHistoryTable = ({ invoices, vendorMap, totalPages, setPage }) => {
  return (
    <>
      <TableContainer
        sx={{
          overflow: "scroll",
          borderRadius: 2,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
        }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead sx={{ height: "24px" }}>
            <TableRow sx={{ height: 24 }}>
              <TableCell
                sx={[tableHeadStyle, { width: "300px", borderLeft: "none" }]}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Invoice Date
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Invoice Date"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The date on which the invoice was created.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Invoice No.
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Invoice No."
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            Every invoice has a unique identifying number.
                            Transaction ID, receipt ID, receipt number, etc. can
                            also be put here.
                            <br />
                            <br />
                            Invoices with the same Vendor must have unique
                            invoice numbers.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "320px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Vendor
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Vendor"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            Vendor associated with the invoice.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Invoice Total
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Invoice Total"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            Net Total of all items in the invoice.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Status
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Status"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            <Typography variant="body2">
                              Unreconciled
                            </Typography>
                            <Typography variant="body1">
                              The sum of all line items does not match the
                              invoice total.
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: 1 }}>
                              Reconciled
                            </Typography>
                            <Typography variant="body1">
                              The sum of all line items matches the invoice
                              total, but the invoice has not yet been marked as
                              Approved.
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: 1 }}>
                              Approved
                            </Typography>
                            <Typography variant="body1">
                              The invoice has been approved and Purchase Unit
                              prices have been updated.{" "}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                sx={[tableHeadStyle, { width: "48px", borderRight: "none" }]}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((x) => (
              <InvoiceDetailRow key={x.id} invoice={x} vendorMap={vendorMap} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{ marginTop: 6 }}
        count={totalPages}
        onChange={(_, page) => setPage(page)}
      />
    </>
  );
};

export default InvoiceHistoryTable;
