import { useQuery } from "react-query";
import inventoryClient from "../clients/inventoryClient";

function useEstablishmentKegSizes(establishmentId) {
  const queryReturnValues = useQuery(
    ["kegSizes", {establishmentId}],
    inventoryClient.getEstablishmentKegSizes,
    { refetchOnWindowFocus: false, staleTime: 1000 * 60 * 10 }
  );

  return queryReturnValues;
}

export default useEstablishmentKegSizes;