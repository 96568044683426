/* eslint-disable react/display-name */
import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TrainingDetailsRow from "./TrainingDetailsRow";
import CTABlock from "../../../../components/CTABlock";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DescriptionToolTip from "../../../../components/DescriptionToolTip";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderBottom: "none",
  borderTop: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const TrainingDetailsTab = ({
  inventory,
  updateProductCallback,
  toggleAddingProduct,
  searching,
  deleteCallback
}) => {
  if (inventory.length > 0) {
    return (
      <TableContainer
        sx={{
          display: "flex",
          width: "100%",
          overflow: "scroll !important",
          borderRadius: 2,
          border: "1px solid",
          height: "100%",
          borderColor: (theme) => theme.palette.terrain[400],
        }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead sx={{ height: "24px" }}>
            <TableRow sx={{ height: 24 }}>
              {/* CHECKBOX HEADER */}
              {/* <TableCell
                sx={{
                  borderLeft: "none",
                  borderBottom: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[400],
                  background: (theme) => theme.palette.terrain[300],
                  padding: 0,
                  width: "32px",
                }}
              ></TableCell> */}
              <TableCell
                sx={[tableHeadStyle, { width: "423px", borderLeft: "none" }]}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Product Name
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Product Name"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            Product Name is the official and commonly used name
                            of a product.
                          </Box>
                          <Box sx={{ marginTop: 1 }}>
                            <Typography variant="body2">
                              Best practices:
                            </Typography>
                          </Box>
                          <ul
                            style={{
                              listStylePosition: "outside",
                              marginTop: 1,
                            }}
                          >
                            <li style={{ marginTop: 1 }}>
                              Names should be specific: <q>Absolut Vodka</q> and
                              <q>Absolut Citron</q> are distinct and have
                              separate uses, so they should be divided into
                              separate products with unique names.
                            </li>
                            <li>
                              Names can contain the name of the producer, if
                              that is how the product is colloquially referred
                              to. <q>Makers Mark Bourbon</q> contains the name
                              of the Producer, wheras{" "}
                              <q>Elijah Craig Small Batch Bourbon</q>
                              does not include the name of the producer,{" "}
                              <q>Heaven Hill</q>.
                            </li>
                            <li>
                              Names should be free of reference to size, like
                              <q>750 mL</q>, as this is defined at the Product
                              Format level. Names should also be free of
                              reference to packaging type, like <q>bottle</q>,
                              as this is defined at the Product Format level.
                            </li>
                            <li>
                              If the product is a wine, include the vintage.
                            </li>
                          </ul>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "326px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Description
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Description"
                      description={
                        <Box>
                          An informative overview of the product&apos;s
                          background info. Things like the production methods,
                          history, and story of the Product live here. This
                          information is shown to staff users in the Mobile App.{" "}
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "326px" }]}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Tasting Notes
                  <Box sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Tasting Notes"
                      description={
                        <Box>
                          Any notes that relate to how the product tastes. This
                          information is shown to staff users in the Mobile App.
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                sx={[tableHeadStyle, { width: "40px", borderRight: "none" }]}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventory.map((x) => (
              <TrainingDetailsRow
                key={x.id}
                product={x}
                updateProductCallback={updateProductCallback}
                deleteCallback={deleteCallback}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return searching ? (
    <CTABlock
      header="No Results Found"
      hideButton
      ButtonImage={AddOutlinedIcon}
    />
  ) : (
    <CTABlock
      header="Your Product List is Empty..."
      subheader="You have not added ay products yet. Get started now..."
      buttonText="Add Products"
      handleCTA={toggleAddingProduct}
      ButtonImage={AddOutlinedIcon}
    />
  );
};

export default TrainingDetailsTab;
