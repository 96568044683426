import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import DescriptionToolTip from "../../../../../components/DescriptionToolTip";

const AdditionalOptions = ({includeSumeRow, toggleIncludeSumRow}) => {
  return (
    <Box>
      <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: -3,
          }}
        >
          <Checkbox checked={includeSumeRow} onChange={() => toggleIncludeSumRow(!includeSumeRow)} />
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 700, marginRight: 1 }}
          >
            Include All Sum Row
          </Typography>
          <DescriptionToolTip
            header="Include All Sum Row"
            description={
              <Box component="div">
                <Box sx={{ marginTop: 1 }}>
                  Include a row at the top of the entire report showing the sum
                  of all values.
                </Box>
              </Box>
            }
          />
        </Box>
    </Box>
  );
};

export default AdditionalOptions;
