import { Box } from "@mui/material";
import React, { useState } from "react";
import Description from "./Description";
import DateRange from "./DateRange";
import GroupBy from "./GroupBy";
import TopLimitControl from "./TopLimitControl";
import SortBy from "./SortBy";
import { presetDateRangeOptions } from "../../../../../utilities/dateRangeUtils";
import {
  invoiceReportTypes,
  orderReportOrderByTypes,
} from "../../../../../clients/inventoryObjects";
import { useMutation } from "react-query";
import inventoryClient from "../../../../../clients/inventoryClient";
import utilFunctions from "../../../../../utilities/utilFunctions";
import ReportingFooter from "../../ReportingFooter";
import InsufficientReportDataModal from "../../../../../components/Modals/InsufficientReportDataModal";

const topReportTypes = [
  { key: "Product", value: invoiceReportTypes.topSpendingProduct },
  { key: "Product Format", value: invoiceReportTypes.topSpendingProductFormat },
  { key: "Family", value: invoiceReportTypes.topSpendingFamilies },
  { key: "Category", value: invoiceReportTypes.topSpendingCategories },
  { key: "Subcategory", value: invoiceReportTypes.topSpendingSubcategories },
  { key: "Expense Group", value: invoiceReportTypes.topSpendingExpenseGroups },
  {
    key: "Expense Subgroup",
    value: invoiceReportTypes.topSpendingExpenseSubgroups,
  },
  { key: "Vendor", value: invoiceReportTypes.topSpendingVendors },
];

const dateSelectionFormats = {
  presetOption: 0,
  customRange: 1,
};

const TopSpendingReport = ({
  establishmentId,
  establishmentTimezone,
  setAlertSuccess,
  setErrorMessage,
}) => {
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customStartDateDisplay, setCustomStartDateDisplay] = useState(null);
  const [customEndDateDisplay, setCustomEndDateDisplay] = useState(null);
  const [selectedPresetDateRange, setSelectedPresetDateRange] = useState(
    presetDateRangeOptions[0].value
  );
  const [dateSelectionFormat, setDateSelectionFormat] = useState(
    dateSelectionFormats.presetOption
  );
  const [topLimit, setTopLimit] = useState(null);
  const [useTopLimit, toggleUseTopLimit] = useState(false);
  const [selectedTopReportType, setSelectedTopReportType] = useState(
    topReportTypes[0].value
  );
  const [sortBy, setSortBy] = useState(orderReportOrderByTypes.orderTotal);
  const [isEmptyResultModalOpen, toggleIsEmptyResultModalOpen] = useState(false);

  const generateTopInvoiceMutation = useMutation(
    inventoryClient.generateInvoiceTopSpendingReport,
    {
      onSuccess: (response) => {
        if (response?.status === 204) {
          toggleIsEmptyResultModalOpen(true);
        } else {
          utilFunctions.downloadFile(
            response?.data?.report?.invoiceReportMetadata?.s3PresignedUrl
          );
          setAlertSuccess(
            "Report generated successfully, your download should have started."
          );
        }
      },
      onError: () => {
        setErrorMessage("Report generation failed!");
      },
    }
  );

  const handleGenerateReport = () => {
    const payload = {
      establishmentId: establishmentId,
      topLimit: useTopLimit ? topLimit : -1,
      reportType: selectedTopReportType,
      orderBy: sortBy,
    };

    if (dateSelectionFormat == dateSelectionFormats.presetOption) {
      const dateRange = presetDateRangeOptions
        .find((x) => x.value === selectedPresetDateRange)
        .func(establishmentTimezone);
      payload.startDate = dateRange.start;
      payload.endDate = dateRange.end;
    } else {
      payload.startDate = customStartDate;
      payload.endDate = customEndDate;
    }

    generateTopInvoiceMutation.mutate(payload);
  };

  return (
    <>
      <Box>
        <Box sx={{ marginTop: 8 }}>
          <Description />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <DateRange
            customStartDate={customStartDate}
            setCustomStartDate={setCustomStartDate}
            customEndDate={customEndDate}
            setCustomEndDate={setCustomEndDate}
            selectedPresetDateRange={selectedPresetDateRange}
            setSelectedPresetDateRange={setSelectedPresetDateRange}
            dateSelectionFormat={dateSelectionFormat}
            setDateSelectionFormat={setDateSelectionFormat}
            dateSelectionFormats={dateSelectionFormats}
            presetDateRangeOptions={presetDateRangeOptions}
            establishmentTimezone={establishmentTimezone}
            customStartDateDisplay={customStartDateDisplay}
            setCustomStartDateDisplay={setCustomStartDateDisplay}
            customEndDateDisplay={customEndDateDisplay}
            setCustomEndDateDisplay={setCustomEndDateDisplay}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <GroupBy
            options={topReportTypes}
            selectedReportType={selectedTopReportType}
            setReportType={setSelectedTopReportType}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <TopLimitControl
            topLimit={topLimit}
            setTopLimit={setTopLimit}
            useTopLimit={useTopLimit}
            toggleTopLimit={toggleUseTopLimit}
          />
        </Box>
        <Box sx={{ marginTop: 8 }}>
          <SortBy sortByChoice={sortBy} setSortBy={setSortBy} />
        </Box>
      </Box>
      <ReportingFooter
        buttonText={"Create Report"}
        disableButton={
          useTopLimit && topLimit <= 0 // if we haven't set a top limit but checked the box
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange && // if we selected custom range but didn't choose dates
              (!customEndDate || !customStartDate)
            ? true
            : dateSelectionFormat == dateSelectionFormats.customRange &&
              customEndDate < customStartDate // if the custom end date is before the start date
            ? true
            : false
        }
        submit={handleGenerateReport}
        isLoading={generateTopInvoiceMutation?.isLoading}
      />
      <InsufficientReportDataModal isOpen={isEmptyResultModalOpen} toggleModalOpen={toggleIsEmptyResultModalOpen} loading={false} />
    </>
  );
};

export default TopSpendingReport;
