import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const AddProductOptions = ({ toggleAddingFromLibrary, toggleAddingNew }) => {
  return (
    <Box>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Box
            sx={{
              width: "164px",
              height: "170px",
              border: "1px solid",
              borderRadius: 4,
              borderColor: (theme) => theme.palette.terrain[400],
              margin: 4,
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 4,
                }}
              >
                <Typography variant="h4">From Library</Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: 4,
                }}
              >
                <Typography variant="overline">
                  Add a single or multiple products that are already created in
                  your library.
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: 4 }}>
                <Button
                  variant="contained"
                  sx={{ width: "132px", height: "48px" }}
                  onClick={() => toggleAddingFromLibrary(true)}
                >
                  <Typography variant="largeButton">Add</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              width: "164px",
              height: "170px",
              border: "1px solid",
              borderRadius: 4,
              borderColor: (theme) => theme.palette.terrain[400],
              margin: 4,
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 4,
                }}
              >
                <Typography variant="h4">Create New</Typography>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: 4,
                }}
              >
                <Typography variant="overline">
                  Create a product from scratch, inputting only the info needed
                  for a count.
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: 4 }}>
                <Button
                  variant="contained"
                  sx={{ width: "132px", height: "48px" }}
                  onClick={() => toggleAddingNew(true)}
                >
                  <Typography variant="largeButton">Create</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddProductOptions;
