import React, { useEffect, useState } from "react";
import { modalBoxStyle } from "../styles";
import {
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TagsList from "./components/TagsList";
import SpecBackButton from "../../SpecBackButton";
import { cloneDeep } from "lodash";

const TagsModal = ({
  isOpen,
  toggleModalOpen,
  recipeTags,
  tagFilters,
  saveTagFilters,
  handleCreateTagCallback
}) => {
  const [isEditing, toggleIsEditing] = useState(false);
  const [selectedTagFilters, setSelectedTagFilters] = useState([]);
  const [selectedTagFiltersCopy, setSelectedTagFiltersCopy] = useState([]);
  const [creatingTag, toggleCreatingTag] = useState(false);
  const [editingTagModal, toggleEditingTagModal] = useState(false);

  const handleCreateTagClick = () => {
    toggleCreatingTag(!creatingTag);
  };

  const handleEditingTagClick = () => {
    toggleEditingTagModal(!editingTagModal);
  };

  const closeModal = () => {
    toggleIsEditing(false);
    toggleCreatingTag(false);
    toggleEditingTagModal(false);
    toggleModalOpen(false);
  };

  const handleSave = () => {
    saveTagFilters(selectedTagFiltersCopy);
    closeModal();
  };

  const handleSettingSelectedTagFilters = (tagList) => {
    setSelectedTagFiltersCopy(tagList);
  };

  const handleBackClick = () => {
    if ((isEditing && !creatingTag) || (isEditing && !editingTagModal)) {
      toggleIsEditing(false);
    }

    if (creatingTag) {
      toggleCreatingTag(false);
      toggleIsEditing(true);
    }

    if (editingTagModal) {
      toggleEditingTagModal(false);
      toggleIsEditing(true);
    }
  };

  const handleTagClick = (tag) => {
    let sortObj = [...selectedTagFiltersCopy];
    if (sortObj.find((x) => x === tag.id)) {
      sortObj = sortObj.filter((x) => x !== tag.id);
    } else {
      sortObj.push(tag.id);
    }
    setSelectedTagFiltersCopy(sortObj);
  };

  const handleResetClick = () => {
    setSelectedTagFiltersCopy([]);
  };

  useEffect(() => {
    const filterCopy = cloneDeep(tagFilters);
    setSelectedTagFilters(filterCopy);
  }, [tagFilters]);

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 0,
          }}
        >
          <Grid item>
            {isEditing ? (
              <SpecBackButton backText="Back" onClick={handleBackClick} />
            ) : (
              <Typography variant="h4">Set Recipe Tag Filters</Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton
              sx={{ backgroundColor: "transparent" }}
              onClick={closeModal}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box>
          <TagsList
            isEditing={isEditing}
            toggleIsEditing={toggleIsEditing}
            recipeTags={recipeTags}
            selectedTagFilters={selectedTagFilters}
            handleSettingSelectedTagFilters={handleSettingSelectedTagFilters}
            tagFiltersCopy={selectedTagFiltersCopy}
            handleTagClick={handleTagClick}
            handleResetClick={handleResetClick}
            handleCreateTagClick={handleCreateTagClick}
            creatingTag={creatingTag}
            handleCreateTagCallback={handleCreateTagCallback}
            editingTagModal={editingTagModal}
            handleEditingTagClick={handleEditingTagClick}
          />
        </Box>
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!isEditing && (
            <Button
              variant="contained"
              disabled={recipeTags?.tags.length <= 0}
              onClick={handleSave}
            >
              <Typography variant="largeButton">Set Tag Filters</Typography>{" "}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default TagsModal;
