import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import LiveEditErrorModal from "../../components/Modals/LiveEditErrorModal";
import AddIcon from "@mui/icons-material/Add";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CTABlock from "../../components/CTABlock";
import CreateInvoiceModal from "../../components/Modals/CreateInvoiceModal";
import InvoiceHistoryTable from "./components/InvoiceHistoryTable";
import Store, { activeRouteKeys } from "../../Store/Store";
import SpecSpinner from "../../components/SpecSpinner";
import useVendors from "../../hooks/useVendors";
import inventoryClient from "../../clients/inventoryClient";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import SnackBarSuccess from "../../components/SnackBarSuccess";
import SnackBarError from "../../components/SnackBarError";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import SubscriptionScreenLock from "../../components/SubscriptionScreenLock";

const Invoices = () => {
  const [errorModalOpen, toggleErrorModalOpen] = useState(false);
  const [invoiceModalOpen, toggleInvoiceModalOpen] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const store = useContext(Store);
  const { establishmentid } = useParams();
  const queryClient = useQueryClient();
  const [{ isLoading: isLoadingVendorData, data: vendorData }, vendorMap] =
    useVendors(establishmentid);
  const { data: subscriptionState, isLoading: isLoadingSubscriptionState } = useEstablishmentSubscription(establishmentid);

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.invoices,
      `spec/${establishmentid}/invoices`
    );
  }, []);

  const { data: invoiceData, isLoading: isLoadingInvoices } = useQuery({
    queryKey: ["invoices", { establishmentId: establishmentid, page: page }],
    queryFn: inventoryClient.getEstablishmentInvoices,
    keepPreviousData: true,
    enabled: !!subscriptionState?.isActive
  });

  const createInvoiceMutation = useMutation(inventoryClient.createInvoice, {
    onSuccess: (result) => {
      setPage(1);
      queryClient.invalidateQueries([
        "invoices",
        { establishmentId: establishmentid, page: 1 },
      ]);
      toggleInvoiceModalOpen(false);
      navigate(result.invoice.id);
    },
  });

  const handleCreateInvoice = async (payload) => {
    payload.establishmentId = establishmentid;
    await createInvoiceMutation.mutateAsync(payload);
  };

  if (isLoadingInvoices || isLoadingVendorData || isLoadingSubscriptionState) {
    return <SpecSpinner open text="Loading Invoices..." />;
  }

  return (
    <>
      <Grid
        container
        sx={{
          width: "100%",
          maxWidth: "2440px",
          padding: 4,
          marginTop: 6,
        }}
      >
        {/* {isErrorLoadingStockCounts && (
          <Typography variant="h6" sx={{ color: "red" }}>
            There was an error fetching stock count data, please refresh
          </Typography>
        )} */}
        <>
          <Grid
            container
            sx={{
              maxWidth: "2440px",
              marginBottom: 6,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{ fontSize: { xs: 20, sm: 20, md: 28 } }}
              >
                Invoices
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", md: "flex-end" },
                marginTop: { xs: 4, md: 0 },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 2,
                  padding: 4,
                  height: "40px",
                }}
                onClick={() => toggleInvoiceModalOpen(true)}
              >
                <AddIcon sx={{ fontSize: { xs: 14, sm: 14, md: 20 } }} />
                <Typography
                  variant="smallButton"
                  sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
                >
                  Create New
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%" }}>
            <Grid
              container
              sx={{
                marginBottom: 6,
                marginLeft: 0,
                display: "flex",
                justifyContent: "space-between",
                alignitems: "center",
                maxWidth: "2440px",
              }}
            >
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: { xs: 2, sm: 2, md: 0 },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontSize: { xs: 16, sm: 16, md: 20 } }}
                >
                  All Invoices
                </Typography>
              </Grid>
              {/* Search Bar */}
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/* <Button
                  variant="outlined"
                  sx={{
                    marginLeft: 2,
                    border: "2px solid",
                    borderColor: (theme) => theme.palette.primary.main,
                  }}
                >
                  <TuneOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                  <Typography variant="largeButton">Filters</Typography>
                </Button> */}
              </Grid>
            </Grid>
            {subscriptionState?.isActive ? (
              <Grid container sx={{ marginBottom: 2, width: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "2440px",
                }}
              >
                {invoiceData?.invoices.length !== 0 ? (
                  <InvoiceHistoryTable
                    vendorMap={vendorMap}
                    invoices={invoiceData.invoices}
                    totalPages={invoiceData.totalPages}
                    setPage={setPage}
                  />
                ) : (
                  <CTABlock
                    handleCTA={() => toggleInvoiceModalOpen(true)}
                    ButtonImage={AddIcon}
                    buttonText="New Invoice"
                    header="You have not created any invoices yet."
                    subheader="You have no invoices available for this time period."
                  />
                )}
              </Box>
            </Grid>
            ) : null}
          </Box>
        </>
      </Grid>
      <CreateInvoiceModal
        isOpen={invoiceModalOpen}
        toggleModalOpen={toggleInvoiceModalOpen}
        buttonText="Create Invoice"
        headerText="Create New Invoice"
        createInvoice={handleCreateInvoice}
        loading={createInvoiceMutation.isLoading}
        vendors={vendorData?.vendors}
      />
      <LiveEditErrorModal
        isOpen={errorModalOpen}
        toggleErrorModalOpen={toggleErrorModalOpen}
        bodyText="Are you sure you want to navigate back? You will lose any unsaved information."
        headerText="Hold on..."
        buttonText="Go Back"
      />
      <Box>
        <SnackBarSuccess
          alertSuccess={alertSuccess}
          setAlertSuccess={setAlertSuccess}
        />
        <SnackBarError
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      </Box>
      <SubscriptionScreenLock show={!subscriptionState?.isActive} />
    </>
  );
};

export default Invoices;
