import React from "react";
import { Box, Typography } from "@mui/material";

const MenuTableCellBadge = ({
  prefixAdornment,
  suffixAdornment,
  displayValue,
}) => {
    const displayFormatted = displayValue === null ? '-' : `${prefixAdornment || ""}${displayValue}${suffixAdornment || ""}`
  return (
    <Box
      sx={{
        height: "24px",
        backgroundColor: (theme) => theme.palette.terrain[100],
        borderRadius: 1,
        padding: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "fit-content",
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: (theme) => theme.palette.terrain[900],
          fontWeight: 400,
        }}
      >
        {displayFormatted}
      </Typography>
    </Box>
  );
};

export default MenuTableCellBadge;
