import { Alert, Box, Grid, List, Snackbar, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CurrencySettings from "./CurrencySettings";
import Store from "../../../Store/Store";
import { Spinner } from "reactstrap";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";

const CostAnalysisSettings = ({ checkedStyle, defaultStyle }) => {
  const store = useContext(Store);
  const { data: currentCurrency, isLoading: loading } =
    useEstablishmentSettings(store.currentEstablishment?.id);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const queryClient = useQueryClient();
  const [alertSuccess, setAlertSuccess] = useState(false);

  const handleCloseAlert = () => {
    setAlertSuccess(false);
  };
  const currencyData = [
    {
      id: 0,
      name: "American Dollar",
      description: "($)",
    },
    {
      id: 2,
      name: "English Sterling",
      description: "(£)",
    },
  ];

  useEffect(() => {
    if (currentCurrency) {
      setSelectedCurrency(currentCurrency?.inventorySettings?.currency);
    }
  }, []);

  const settingsMutation = useMutation(inventoryClient.patchInventorySettings, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "settings",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "settings",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
      });
      setAlertSuccess(true);
    },
  });

  const handleCurrencyChange = (newCurrency) => {
    if (newCurrency !== currentCurrency) {
      let payload = {
        establishmentId: store.currentEstablishment?.id,
      };

      payload["CurrencyUpdate"] = { value: newCurrency };
      settingsMutation.mutate(payload);
      setSelectedCurrency(newCurrency);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Snackbar
        open={alertSuccess}
        onClose={handleCloseAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" variant="filled">
          Success! Establishment settings updated.
        </Alert>
      </Snackbar>
      <Box>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={10}>
            <Typography variant="h4">Currency</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <InfoOutlinedIcon
              sx={{
                color: (theme) => theme.palette.primary[800],
                cursor: "pointer",
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 4 }}>
          <List>
            {currencyData.map((x) => (
              <CurrencySettings
                key={x.name}
                checkedStyle={checkedStyle}
                defaultStyle={defaultStyle}
                data={x}
                currentCurrency={currentCurrency}
                handleSelect={handleCurrencyChange}
                selectedCurrency={selectedCurrency}
                disabled={settingsMutation?.isLoading}
              />
            ))}
          </List>
        </Box>
      </Box>
    </>
  );
};

export default CostAnalysisSettings;
